import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name UserListingService
 * @description
 * # userListingService
 * Service in the heliApp.
 */

@Injectable()

export class UserListingService {

  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  /**
* @ngdoc method
* @name getAmbassords list
*
* @description
* To get the list of Ambassadors
**/
  getUserList(data, token) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'users/getList?' + 'keyword=' + data.keyword + '&page=' + data.page + '&back=' + data.back + '&limit=' + data.limit,
      headers: {
        'token': token,
        'Content-Type': 'application/json'
      }
    };
    return this.webService.httpReq(req);
  }
}

angular.module('heliApp').service('userListingService', downgradeInjectable(UserListingService))
