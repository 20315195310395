import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name UserOrdersListService
 * @description
 * # userOrdersListService
 * Service in the heliApp.
 */

@Injectable()

export class UserOrdersListService {

  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  sendReq(method) {
    return new Promise((resolve, reject) => {
      method.then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }

  postReq(url, data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + url,
      data: data.params,
      headers: {
        'token': data.token
      }
    };
    return req;
  }

  getReq(url, token) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + url,
      headers: {
        'token': token
      }
    };
    return req;
  }

  putReq(url, data, token) {
    let req = {
      method: 'PUT',
      url: this.REQUEST_URL + url,
      data: data,
      headers: {
        'token': token
      }
    };
    return req;
  }
  /**
     * @ngdoc method
     * @name cancelOrderReq
     *
     * @description
     * This method call API to cancel order and make a refund
     **/
  cancelOrderReq(data) {
    let req = this.postReq('payments/refundPayment', data);
    return this.webService.httpReq(req);
  }

  /**
     * @ngdoc method
     * @name getOrderList
     *
     * @description
     * This method call API to get all orders list
     **/
  getOrderListReq(order_id, token) {
    let url = order_id ? 'carts/consumerOrderList?order_id=' + order_id : 'carts/consumerOrderList';
    let req = this.getReq(url, token);
    return this.webService.httpReq(req);
  }

  getOrderList(token, order_id) { // fetches users past orders
    return this.sendReq(this.getOrderListReq(order_id, token));
  }

  cancelOrder(data) {
    return this.sendReq(this.cancelOrderReq(data));
  }

  getCancellationPolicy(package_id, token) {
    let url = 'carts/getPolicy?package_id=' + package_id;
    let req = this.getReq(url, token);
    return this.webService.httpReq(req);
  }

  /**
     * @ngdoc method
     * @name updateGuestConsumerDetails
     *
     * @description
     * To update guest details by consumer
     **/
  updateGuestConsumerDetails(data, token) {
    let req = this.putReq('Guests/editGuestConsumer', data, token);
    return this.webService.httpReq(req);
  }
}

angular.module('heliApp').service('userOrdersListService', downgradeInjectable(UserOrdersListService));
