/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:openResponseController
 * @description
 * # openResponseController
 * Controller of the heliApp
 */

angular.module('heliApp').controller('openResponseController', ['operatorProfileService', '$stateParams', '$scope', '$timeout', '$rootScope', 'CONST_HELPER', '$state', '$location', function (operatorProfileService, $stateParams, $scope, $timeout, $rootScope, CONST_HELPER, $state, $location) {
  $scope.responseId = $stateParams.id;
  $scope.allQuestions = [];
  $scope.formData = [];
  $scope.temp = null;
  $scope.fetchFormAnswers = function () {
    /* get all the sports list along with categories */
    operatorProfileService.getOperatorResponseFormDetail($scope.responseId, $scope.user.token).then(function (response) { // fetch from the service
      console.log(response)
      if (response && response.data.code == CONST_HELPER.API_RESPONSE.UNAUTHERIZED) {
        $location.path('/');
        return;
      }
      if (response) {
        var data = response.data.data;
        $scope.formData.name = data.title;
        $scope.formData.packageName = data.package ? data.package.name : '';
        $scope.formData.guestName = data.guest ? data.guest.name : '';
        $scope.mapQuestionAnswer(data.guest_form_detail);
      }
    }, function () { // check for error
      $scope.showPopup('#serverErrModal');
    });
  };
  $scope.responseId ? $scope.fetchFormAnswers() : null;
  $scope.goToPrevious = function (e) {
    e.preventDefault();
    $state.go($state.previous.name, $state.previous.params);
  };

  // parse question and answer data
  $scope.mapQuestionAnswer = function (questionsReq) {
    if (questionsReq) {
      $scope.allQuestions = [];
      var allAnswer = questionsReq.answer;
      var requestData = questionsReq.request.fields;
      requestData.forEach(function (obj, index) {
        var field = obj;
        var tempObj = { };
        if (field && field.ref) {
          var answerData = allAnswer.find(function (answer) { return answer.field.ref == field.ref; });
          if (answerData) {
            tempObj = {
              type: CONST_HELPER.QUESTOIN_TYPE[field.type],
              questionText: field.title,
              optional: field.validations ? field.validations.required : false,
              maxlength: field.validations ? field.validations.max_length : CONST_HELPER.ZERO,
              options: field.properties.choices,
              temp_id: $scope.getTempId()
            };

            // MAX LENGTH IS ONLY FOR SHORT TEXT & LONG TEXT
            if (tempObj.type <= CONST_HELPER.TWO) {
              tempObj.answer = answerData.text;
            }

            if (tempObj.type == CONST_HELPER.FOUR) {
              tempObj.options.map(function (option) {
                if (answerData.choices.labels && answerData.choices.labels.indexOf(option.label) != CONST_HELPER.MINUS_ONE) {
                  tempObj.answer = option.label;
                }
              });
            }

            if (tempObj.type == CONST_HELPER.FIVE) {
              tempObj.answer = answerData.choice.label;
            }

            // MAX LENGTH IS ONLY FOR SHORT TEXT & LONG TEXT
            if (tempObj.type > CONST_HELPER.TWO) {
              delete tempObj.max_length;
            }

            if (tempObj.type <= CONST_HELPER.THREE) {
              delete tempObj.options;
            }

            $scope.allQuestions.push(tempObj);
          } else if (!answerData && CONST_HELPER.QUESTOIN_TYPE[field.type] == CONST_HELPER.THREE) {
            tempObj = {
              type: CONST_HELPER.QUESTOIN_TYPE[field.type],
              questionText: field.title,
              temp_id: $scope.getTempId()
            };

            $scope.allQuestions.push(tempObj);
          }
        }
      });
    }
    console.log($scope.allQuestions);

  };
}]);
