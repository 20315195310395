/* eslint-disable no-useless-escape */
/* eslint-disable handle-callback-err */
/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:UserRegisterationCtrl
 * @description
 * # UserRegisterationCtrl
 * Controller of the heliApp
 */
angular.module('heliApp').controller(
  'UserLoginCtrl', ['$scope', '$rootScope', 'userLoginService', '$window', '$state', '$location', '$cookies',
  function ($scope, $rootScope, userLoginService, $window, $state, $location, $cookies) {
    // $scope.user = {};//user scope
    // $scope.user.email = $scope.$parent.vm ? $scope.$parent.vm.guestEmail:'';
    $scope.emailErr = false;// login error messages
    $scope.passwordErr = false;
    $scope.loginErr = false;
    $scope.forgotErr = false;// forgot password page error
    $scope.forgotEmailErr = false;
    $scope.forgot = {};
    $scope.forgotPassSubmit = false;
    $scope.isAgent = $rootScope.isAgent;
    $scope.hidePopup('#heli-share');
    $scope.userLogin = function (form) { // user login to heli platform
      $scope.loginErr = false;// set to false initial
      if ((form.email.$viewValue).trim() === '') { // check for required validaiton of the email
        $scope.emailErr = true;
      } else {
        if ($scope.userNameValidation((form.email.$viewValue).trim()) || validateEmail((form.email.$viewValue).trim())) {
          $scope.emailErr = false;
        } else {
          $scope.emailErr = true;
        }
      }
      if (form.password.$pristine || (form.password.$viewValue).trim() === '') { // check for required validaiton of the password
        $scope.passwordErr = true;
      } else {
        $scope.passwordErr = false;
      }

      if (!$scope.emailErr && !$scope.passwordErr && form.$valid) { // check if form is valid or not
        var data = { 'email': ($scope.user.email).trim(), 'password': $scope.user.password };// set data field
        $scope.showLoader();// show loader
        userLoginService.loginUser(data).then(function (response) { // post to the server the form data
          if (response.data) {
            if (response.data.code !== '200') { // some error exits
              $scope.loginErr = true;// set error status
              for (var key in response.data.message[Object.keys(response.data.message)]) {
                $scope.loginErrMessage = response.data.message[Object.keys(response.data.message)][key];
              }
              var errType = Object.keys(response.data.message[Object.keys(response.data.message)[0]])[0];
              if (errType === 'email') { // its email error
                $scope.emailErr = true;
              } else if (errType === 'password') { // its password error
                $scope.passwordErr = true;
              }
              $scope.hideLoader();// hide loader
            } else { // if  registeration is successful
              $scope.loginErr = false;// set error status
              $scope.passwordErr = false;
              $scope.emailErr = false;
              $rootScope.manualLogout();
              $scope.setUserDetails(response.data.data, 'second');// set user details in the rootScope;
              $scope.user = response.data.data;// set user data into local scope;
              $scope.emailVerificationAlert(); // show email verification alert
              $scope.hideLoader();// hide loader
              $rootScope.userLocalCountry = response.data.data.country_id;
              $cookies.put('user_country_id', JSON.stringify(response.data.data.country_id));

              if (response.data.data.role_id === 3 || response.data.data.role_id === 5) {
                $scope.homeRedirection();// redirect to operator page
              } else { // for consumer
                // $rootScope.$broadcast('user.signin');
                if ($cookies.get('goToRadar') === 'true') {
                  $state.go('consumer-radar');
                  $cookies.put('goToRadar', null);
                } else if ($rootScope.previousState && $rootScope.previousState.name !== '' && $rootScope.previousState.name !== 'forgotPassword' && $rootScope.previousState.name !== 'forgot-success' && $rootScope.previousState.name !== 'mergeAccountSuccess') {
                  $window.history.back();
                } else {
                  $state.go('home');
                }
              }
            }
          }
        }, function (error) { // check for error
          $scope.hideLoader();// hide loader
          $scope.showPopup('#serverErrModal');
        });
      }
    };

    function validateEmail (email) { // email validaitons
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }

    $scope.getUserStatus = function () { // check user's initial logged in status
      if ($scope.hasProfile) { // if already logged in user
        $state.go('home');// redirect to home page
      }
    };

    $rootScope.$on('social-log-in', function (event, userDetails, response) { // new user social sign in
      $rootScope.fromSocial = true;
      $rootScope.userDetailsSocial = userDetails;
      $rootScope.responseSocial = response;
      $state.go('signup');
    });

    $scope.userForgotPassword = function (form) { // submit request for forgot password email request
      $scope.forgotEmailErr = false;
      if (form.forgotEmail.$pristine || (form.forgotEmail.$viewValue).trim() === '') { // check for required validation of the email
        $scope.forgotEmailErr = true;
      } else {
        $scope.forgotEmailErr = false;
      }
      if (!$scope.forgotEmailErr && form.$valid) {
        var data = { 'email': ($scope.forgot.forgotEmail).trim() };// set data field
        $scope.showLoader();// show loader
        userLoginService.forgotPassword(data).then(function (response) { // post to the server the form data
          if (response.data) {
            if (response.data.code !== '200') { // some error exits
              $scope.forgotErr = true;// set error status
              for (var key in response.data.message[Object.keys(response.data.message)]) {
                $scope.forgotErrMessage = response.data.message[Object.keys(response.data.message)][key];
              }
              var errType = Object.keys(response.data.message)[0];
              if (errType === 'email') { // its email error
                $scope.forgotEmailErr = true;
              }
              $scope.hideLoader();// hide loader
            } else { // if  registeration is successful
              $scope.forgotErr = false;// set error status
              $scope.forgotEmailErr = false;
              $scope.forgotPassSubmit = true;
              $scope.hideLoader();// hide loader
            }
          }
        }, function (error) { // check for error
          $scope.hideLoader();// hide loader
          $scope.showPopup('#serverErrModal');
        });
      }
    };

    $scope.resetForgotPasswordForm = function () {
      $scope.forgotErr = false;// set error status
      $scope.forgotEmailErr = false;
      $scope.forgotPassSubmit = false;
      $scope.forgot.forgotEmail = '';
      $scope.hidePopup('#forgot-modal');
    };
  }]);
