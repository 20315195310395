/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc filter
 * @name heliApp.trustUrl
 * @description
 * # trusted is a filter which uses the $sce angular service to get certificate for external urls..
 * Filter in the heliApp.
 */
angular.module('heliApp')
  .filter('trusted', ['$sce', function ($sce) {
    return function (url) {
      return $sce.trustAsResourceUrl(url);
    };
  }])
  .filter('embededVideoUrl', function () {
    return function (url) {
      String.prototype.splice = function (idx, rem, str) {
        return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
      };

      var isYoutube = url.search('youtube');
      var isShareYoutube = url.search('youtu.be');
      if (isYoutube !== -1) {
        url = url.split('&')[0].replace('watch?v=', 'embed/');
      } else if (isShareYoutube !== -1) {
        var id = url.split('/')[url.split('/').length - 1];
        url = 'https://www.youtube.com/embed/' + id;
      } else {
        var i = url.search('//');
        var j = url.search('com');
        url = url.splice(i + 2, 0, 'player.');
        url = url.splice(j + 10, 0, '/video');
      }
      return url;
    };
  });
