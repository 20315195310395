/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc directive
 * @name heliApp.directive:commonFeatures
 * @description
 * # commonFeatures
 */
angular.module('heliApp')
  .directive('guestForm',['$timeout', function ($timeout) { // directive for guest form
    return {
      restrict: 'A',
      templateUrl: 'views/operator-guest-form-directive.html',
      link: function (scope, element, attrs) {
        // element.on('keyup', function () {
        //   if (scope.checkForAdult) { // id same as adult option is selected
        //     var price = scope.addOns[attrs.index].adult_price;
        //     scope.addOns[attrs.index].child_price = price;// keep the models in sync
        //     $timeout(angular.noop);
        //   }
        // });
      }
    };
  }]);
