/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:operatorCalendarCtrl
 * @description
 * # operatorCalendarCtrl for opeartor calendar dashboard.
 * Controller of the heliApp
 */
angular.module('heliApp')
  .controller('operatorCalendarCtrl', ['$scope', '$rootScope', '$compile', '$stateParams', '$timeout', 'uiCalendarConfig', 'operatorCalendarService', '$window', '$state', 'operatorPackageListService', '$filter', 'CONST_HELPER', 'userAdventureDetailService', 'agentService',
    function ($scope, $rootScope, $compile, $stateParams, $timeout, uiCalendarConfig, operatorCalendarService, $window, $state, operatorPackageListService, $filter, CONST_HELPER, userAdventureDetailService, agentService) {
      $scope.homeRedirection(); // check for authorised access


      /* event source that will contains custom events on the scope */
      $scope.events = [];
      $scope.eventLimit = CONST_HELPER.CALENDAR_EVENT_LIMIT;
      $scope.eventDetail = {};
      $scope.mobileEvents = [];
      $scope.packageList = [];
      $scope.showeventDetail = false;
      $scope.ifCalendarLoading = false; // calendar loader
      $scope.dateEventIsBloked = false;
      var packageIds = [];
      $scope.locationList = [];
      $rootScope.currentMonthDate = $rootScope.currentMonthDate || new Date();
      var locationIds = [];
      $stateParams.locations_id ? locationIds = $stateParams.locations_id.split(',').map(Number) : locationIds = [];
      $stateParams.packages_id ? packageIds = $stateParams.packages_id.split(',').map(Number) : packageIds = [];
      if ($stateParams.calendar_date) {
        var transformDate = $stateParams.calendar_date.split('.').map(Number);
        $rootScope.currentMonthDate = new Date(transformDate[0], transformDate[1] - 1, transformDate[2]);
      }
      $scope.calendarFilters = {
        expired_slot: $stateParams.unavailable ? JSON.parse($stateParams.unavailable.toLowerCase()) : true,
        all: $stateParams.select_all ? JSON.parse($stateParams.select_all.toLowerCase()) : true,
        empty_slot: $stateParams.empty ? JSON.parse($stateParams.empty.toLowerCase()) : true,
        occupancy_below74: $stateParams.occupancy_below74 ? JSON.parse($stateParams.occupancy_below74.toLowerCase()) : true,
        occupancy_above74: $stateParams.occupancy_above74 ? JSON.parse($stateParams.occupancy_above74.toLowerCase()) : true,
        occupancy_full: $stateParams.occupancy_full ? JSON.parse($stateParams.occupancy_full.toLowerCase()) : true,
        nightly_booking: $stateParams.nightly_booking ? JSON.parse($stateParams.nightly_booking.toLowerCase()) : true
      };

      $scope.createBooking = {
        start: '',
        end: ''
      };
      if ($scope.user) {
        var params = {
          status: 1,
          page: 1,
          back: CONST_HELPER.ZERO,
          limit: 1000,
          token: $scope.user.token
        };
      }
      $scope.max = CONST_HELPER.ZERO;
      var total = CONST_HELPER.ZERO;
      var disableDates = [];
      $scope.selectedSlots = [];
      $scope.adventure = {
        isAllSelected: true
      };
      $scope.location = {
        isAllSelectedLocations: true
      };
      $scope.nightly_selected_dates = {
        start: '',
        end: ''
      };
      $scope.consumerPrice = CONST_HELPER.ZERO;
      $scope.availableSeats = CONST_HELPER.ZERO;
      $scope.limitAdventureNo = CONST_HELPER.SCROLL_FILTER_LIMIT;
      $scope.limitLocationNo = CONST_HELPER.SCROLL_FILTER_LIMIT;
      $rootScope.errorAlert = 'You can not select unavailable dates.';
      $scope.selectedPackage = {};
      /* alert on eventClick */
      $scope.alertOnEventClick = function (data, jsEvent, view) {
        $scope.alertMessage = (data.title + ' was clicked ');
        $scope.eventDetail = data.slot || data;
        if ($window.innerWidth <= 767 && $rootScope.calendarViewType === 'basicWeek') {
          $state.go('operator-edit-slots', {'slot_id': data.slot.id});
        } else {
          $timeout(function () {
            $scope.showeventDetail = true;
            if (data.slot.is_block && data.slot.is_block == true) {
              $scope.dateEventIsBloked = true;
            } else {
              $scope.dateEventIsBloked = false;
            }
            if ($rootScope.isAgent) {
              $scope.initializeOperatorPackageShare($scope.eventDetail);
            }
          }, 200);
        }
      };
      var calendarHeaderObject;
      if ($window.innerWidth <= 767) {
        calendarHeaderObject = {
          'monthHeader': 'ddd',
          'weekHeader': 'ddd D'
        };
      } else {
        calendarHeaderObject = {
          'monthHeader': 'dddd',
          'weekHeader': 'dddd D'
        };
      }
      $scope.tempId = $scope.getTempId();
      $scope.increaseLimit = function (type) {
        if (type == 'adventure') {
          if ($scope.limitAdventureNo == CONST_HELPER.SCROLL_FILTER_LIMIT) {
            $scope.limitAdventureNo = $scope.packageList.length;
          } else {
            $scope.limitAdventureNo = CONST_HELPER.SCROLL_FILTER_LIMIT;
          }
        } else if (type == 'location') {
          if ($scope.limitLocationNo == CONST_HELPER.SCROLL_FILTER_LIMIT) {
            $scope.limitLocationNo = $scope.packageList.length;
          } else {
            $scope.limitLocationNo = CONST_HELPER.SCROLL_FILTER_LIMIT;
          }
        }
      };

      function getLocationNames() {
        var data = {
          token: $scope.user.token
        };
        operatorCalendarService.getLocationNames(data).then(function (response) {
          if (response.data) {
            if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
              $scope.locationList = response.data.data;
              $scope.locationList.push({id: 0, name: 'Custom Location'});
              if (!$stateParams.locations_id) {
                $scope.location.isAllSelectedLocations = true;
                $scope.locationList.map(function (location) {
                  location.selected = true;
                  locationIds.push(location.id);
                });
              } else {
                locationIds = $stateParams.locations_id.split(',').map(Number);
                $scope.location.isAllSelectedLocations = false;
                if ($scope.locationList.length == locationIds.length) {
                  $scope.location.isAllSelectedLocations = true;
                }
                $scope.locationList.map(function (location) {
                  if (locationIds.indexOf(location.id) != -1) {
                    location.selected = true;
                  } else if (locationIds.indexOf(location.id) == -1) {
                    location.selected = false;
                  }
                });
              }
            }
          }
        }, function () {

        });
      }

      $scope.getMaxCapacityByLodgingId = function (id) {
        if ($scope.adventureDetails.lodgings && $scope.adventureDetails.lodgings.length) {
          return $scope.adventureDetails.lodgings.find(function (o) {
            return o.id === id;
          }).max_guests;
        } else {
          return CONST_HELPER.ZERO;
        }
      }
      // Open confirm box on drag slot date
      $scope.confirmSlotDateChange = function (event, delta, revertFunc) {
        $scope.event = event;
        $scope.delta = delta;
        $scope.revertFunc = revertFunc;
        $scope.changedSlotStartDate = new Date(event.start);
        var slotStartDate = new Date(event.slot.from_date);
        var slotEndDate = new Date(event.slot.to_date);

        var timeDiff = slotEndDate.getTime() - slotStartDate.getTime();
        var adventureDays = timeDiff / (1000 * 3600 * 24); // get the adventure no of days

        // update new slot end date
        $scope.changedSlotEndDate = new Date($scope.changedSlotStartDate.getTime() + adventureDays * 24 * 60 * 60 * 1000);

        var currentDate = new Date();
        var diffDays = Math.floor((Date.UTC($scope.changedSlotStartDate.getFullYear(), $scope.changedSlotStartDate.getMonth(), $scope.changedSlotStartDate.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));

        if (diffDays < 0) { // if slot expired
          revertFunc();
        } else {
          // CHECK FOR SAME DATE SLOT
          var sameDateSlot = $scope.events.filter(function (obj) {
            return moment(obj.slot.from_date).format('YYYY-MM-DD') === moment($scope.changedSlotStartDate).format('YYYY-MM-DD');
          });
          var alreadyExist = [];

          if (sameDateSlot.length) {
            alreadyExist = sameDateSlot.filter(function (obj) {
              if (obj.slot.package_id === event.slot.package_id) {
                return true;
              }
            });
          }
          if (alreadyExist.length) {
            revertFunc();
            $scope.showPopup('#slotErrorModal');
            return;
          }

          $scope.showPopup('#confirmSlotDateChange');
        }
      };
      $scope.changeSlotDate = function () {
        $scope.alertOnDrop($scope.event, $scope.delta, $scope.revertFunc);
      };
      Date.prototype.yyyymmdd = function () {
        var mm = this.getMonth() + CONST_HELPER.ONE; // getMonth() is zero-based
        var dd = this.getDate();

        return [this.getFullYear(),
          (mm > CONST_HELPER.NINE ? '' : '0') + mm,
          (dd > CONST_HELPER.NINE ? '' : '0') + dd
        ].join('');
      };
      $scope.checkForEmptyObjectAndArray = function (val) {
        if (val) {
          if ($.isArray(val)) {
            return val.length > CONST_HELPER.ZERO;
          } else {
            return !angular.equals(val, {});
          }
        } else {
          return false;
        }
      };

      function getAdventureDetails(adventurePackage) {
        userAdventureDetailService.getAdventureDetails(adventurePackage).then(function (res) {
          // $rootScope.closeMyNav()
          if (res.data && res.data.code === CONST_HELPER.API_RESPONSE.OK) {
            if (res.data.package.operator_status === CONST_HELPER.ZERO) {
              $state.go('link-expire');
              return;
            }
            if ($scope.user.role_id === CONST_HELPER.USER_ROLE.OPERATOR) {
              $rootScope.localCurrencyCode = res.data.package.currency.code;
            }
            // if user redirect to signin by clicking on add to radar button after login add that package to user radar automatically..
            $scope.adventureDetails = res.data.package;
            $scope.booking_type = $scope.adventureDetails.booking_type;

            if ($scope.booking_type == CONST_HELPER.BOOKING_TYPE.NIGHTLY_SLOTS && $scope.checkForEmptyObjectAndArray($scope.adventureDetails.nightly_slots)) {
              $scope.calendar = $scope.getCalendar($scope.adventureDetails.nightly_slots.nightly_slot_lodgings);
            }

            if ($scope.booking_type == CONST_HELPER.BOOKING_TYPE.NIGHTLY_SLOTS && $scope.checkForEmptyObjectAndArray($scope.adventureDetails.nightly_slots)) {
              setTimeout(function () {
                if ($scope.adventureDetails.single_day_activity != CONST_HELPER.ONE) { // rendering respective calendar in case of single day booking or range booking
                  $scope.destroyDateRangePicker();
                  $scope.destroySingleDateRangePicker();
                  $scope.initializeKendoDatePicker();
                } else {
                  $scope.destroyDateRangePicker();
                  $scope.destroySingleDateRangePicker();
                  $scope.initializeKendoSingleDatePicker();
                }
              }, CONST_HELPER.ZERO);
              $scope.consumerPrice = $scope.adventureDetails.nightly_slots.min_price;
              $scope.hideLoader();
            }
          }
        }, function (err) {
          if (String(err.data.code) === CONST_HELPER.API_RESPONSE.NOT_FOUND) {
            // $rootScope.closeMyNav()
          } else {
            $scope.showPopup('#serverErrModal');
          }
          $scope.hideLoader();
        });
      }

      $scope.getCalendar = function (array) {
        $scope.max = CONST_HELPER.ZERO;
        var dateObj = {totalLodgings: array.length};
        array.forEach(function (val, index) {
          if (val.calendar && val.calendar.length > CONST_HELPER.ZERO) {
            if (typeof val.calendar === 'string') {
              val.calendar = JSON.parse(val.calendar);
            }
            val.calendar.forEach(function (date) {
              if ($scope.adventureDetails.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
                var capacity = date.available_room;
                $scope.max = $scope.max < capacity ? capacity : $scope.max;
              } else {
                var room_capacity = val.max_capacity;
                $scope.max = $scope.max < room_capacity ? room_capacity : $scope.max;
              }
              if (date.date.split('-').length > CONST_HELPER.ONE) {
                date.date = +date.date.split('-')[CONST_HELPER.ZERO] + '/' + (+date.date.split('-')[CONST_HELPER.ONE] - CONST_HELPER.ONE) + '/' + (+date.date.split('-')[CONST_HELPER.TWO]);
              }
              if (dateObj[date.date]) {
                dateObj[date.date].available_room = date.available_room;
                dateObj[date.date].available_seat = date.available_seat;
                dateObj[date.date].is_block += date.is_block;
                if (date.price <= dateObj[date.date].price) {
                  dateObj[date.date].price = date.price;
                }
              } else {
                dateObj[date.date] = {
                  maxCapacity: $scope.getMaxCapacityByLodgingId(val.lodging_id),
                  price: date.price,
                  available_seat: date.available_seat,
                  is_block: date.is_block,
                  available_room: date.available_room
                };
              }
            });
          }
        });
        return dateObj;
      };

      // function for rendering seats in calendar cells
      function setAvailableSeatsInCalendar() {
        disableDates = [];
        $('div.k-calendar-view.k-calendar-monthview > table > tbody > tr > td > a').each(function (idx, elem) {
          var cellDate = $(this).attr('data-value');
          if ($scope.calendar.hasOwnProperty(cellDate)) {
            var cellDateObj = new Date(cellDate);
            cellDateObj.setHours(CONST_HELPER.ZERO, CONST_HELPER.ZERO, CONST_HELPER.ZERO);
            cellDateObj.setMonth(cellDateObj.getMonth() + CONST_HELPER.ONE);
            var diffInDays = Math.floor((cellDateObj - new Date()) / (1000 * 60 * 60 * 24)); // get no of days
            if ($scope.calendar[cellDate].available_seat || $scope.calendar[cellDate].available_room) {
              if ($scope.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
                $(this).append('<span>' + $scope.calendar[cellDate].available_room + ' Units</span>');
              } else {
                $(this).append('<span>' + $scope.calendar[cellDate].available_seat + ' Seats</span>');
              }
            } else {
              $(this).append('<span>Sold Out</span>');
            }
            var splitDate = cellDate.split('/');
            var tempdate = new Date(splitDate[CONST_HELPER.ZERO], splitDate[CONST_HELPER.ONE], splitDate[CONST_HELPER.TWO]).yyyymmdd();
            if ($scope.calendar.totalLodgings == $scope.calendar[cellDate].is_block || diffInDays < $scope.adventureDetails.nightly_slots.advance_notice_days || !($scope.calendar[cellDate].available_seat || $scope.calendar[cellDate].available_room)) {
              disableDates.push(tempdate);
            } else {
              if ($scope.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION && $scope.calendar[cellDate].available_room * $scope.adventureDetails.nightly_slots.per_day_available_seat < +$scope.adult) {
                disableDates.push(tempdate);
              } else if ($scope.calendar[cellDate].available_seat < +$scope.adult) {
                disableDates.push(tempdate);
              } else {
                $(this).parent().removeClass('k-state-disabled');
              }
            }
          }
        });
      }

      function setAvailableSeatsInSingleCalendar() {
        disableDates = [];

        $('div.k-calendar-view > table > tbody > tr > td > a').each(function (idx, elem) {
          var cellDate = $(this).attr('data-value');

          if ($scope.calendar.hasOwnProperty(cellDate)) {
            var cellDateObj = new Date(cellDate);
            cellDateObj.setHours(CONST_HELPER.ZERO, CONST_HELPER.ZERO, CONST_HELPER.ZERO);
            cellDateObj.setMonth(cellDateObj.getMonth() + CONST_HELPER.ONE);
            var diffInDays = Math.floor((cellDateObj - new Date()) / (1000 * 60 * 60 * 24)); // get no of days
            if ($scope.calendar[cellDate].available_seat || $scope.calendar[cellDate].available_room) {
              if ($scope.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
                $(this).append('<span>' + $scope.calendar[cellDate].available_room + ' Units</span>');
              } else {
                $(this).append('<span>' + $scope.calendar[cellDate].available_seat + ' Seats</span>');
              }
            } else {
              $(this).append('<span>Sold Out</span>');
            }
            var splitDate = cellDate.split('/');
            var tempdate = new Date(splitDate[CONST_HELPER.ZERO], splitDate[CONST_HELPER.ONE], splitDate[CONST_HELPER.TWO]).yyyymmdd();
            if ($scope.calendar.totalLodgings == $scope.calendar[cellDate].is_block || diffInDays < $scope.adventureDetails.nightly_slots.advance_notice_days || !($scope.calendar[cellDate].available_seat || $scope.calendar[cellDate].available_room)) {
              disableDates.push(tempdate);
            } else {
              if ($scope.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION && $scope.calendar[cellDate].available_room * $scope.adventureDetails.nightly_slots.per_day_available_seat < $scope.adult) {
                disableDates.push(tempdate);
              } else if ($scope.calendar[cellDate].available_seat < $scope.adult) {
                disableDates.push(tempdate);
              } else {
                $(this).parent().removeClass('k-state-disabled');
              }
            }
          }
        });
      }

      // render calendar title function
      $scope.renderCalendarTitle = function () {
        var text = '';
        var tempDate = $('div.k-calendar-view.k-calendar-monthview > table > tbody > tr > td > a:eq(15)')[CONST_HELPER.ZERO].dataset.value.split('/');
        var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        text = months[tempDate[CONST_HELPER.ONE]] + ' ' + tempDate[CONST_HELPER.ZERO];
        $('div.k-calendar-header > a').html(text);
        $('div.k-calendar-header > a').css('display', 'block');
      };

      // function for intializing datepicker methods and attaching event handlers
      $scope.initializeKendoDatePicker = function () {
        var storeRange = {}; // storing range handling manual null trigger
        var isValid = false;
        var start = new Date((+$scope.adventureDetails.nightly_slots.from_date.split('-')[CONST_HELPER.ZERO]), (+$scope.adventureDetails.nightly_slots.from_date.split('-')[CONST_HELPER.ONE] - CONST_HELPER.ONE), CONST_HELPER.ONE);
        var end = new Date((+$scope.adventureDetails.nightly_slots.to_date.split('-')[CONST_HELPER.ZERO]), (+$scope.adventureDetails.nightly_slots.to_date.split('-')[CONST_HELPER.ONE] + CONST_HELPER.ONE), CONST_HELPER.ZERO);

        function onOpen() {
          $scope.renderCalendarTitle();
          $('.k-button.k-title').click(false);
          $('div.k-calendar-view.k-calendar-monthview > table > tbody > tr > td > a').each(function (idx, elem) {
            $(this).children().remove();
          });
          $('.k-calendar-nav').click(function (e) {
            $('div.k-calendar-header > a').css('display', 'none');
            $timeout(function () {
              $scope.renderCalendarTitle();
              $('div.k-calendar-view.k-calendar-monthview > table > tbody > tr > td > a').each(function (idx, elem) {
                $(this).children().remove();
              });
              setAvailableSeatsInCalendar();
            }, CONST_HELPER.ZERO);
          });
          setAvailableSeatsInCalendar();

          // $('.k-animation-container').hide();
          $timeout(function () {
            $('div.k-calendar-header i').remove();
            $('.k-calendar-header').append('<i class="icon-white-close menu-icon"></i>');
            $('div.k-calendar-header > i.icon-white-close.menu-icon').click(function () {
              $('.k-animation-container').hide();
              $('body').removeClass('modal-open');
            });
            $('.k-animation-container').addClass('k-booking-calendar');
            $('.k-animation-container').show();
          }, 300);
          $('.k-calendar-view').find('.k-today').removeClass('k-today');
        }

        function onClose(e) {
          // $('div.k-calendar-header > a').css('display', 'none');
        }

        function onChange(e) {
          var range = this.range();
          if (range.start && range.end) {
            storeRange.start = range.start;
            storeRange.end = range.end;
            angular.copy(range, $scope.nightly_selected_dates);
            $scope.nightly_selected_dates.start = new Date(range.start);
            $scope.nightly_selected_dates.end = new Date(range.end);
            $scope.$apply();
            isValid = true;
          } else if (range.start === null && range.end === null) {
            if (isValid) {
              var flag = true;
              var allSelectedDates = $scope.getAllInBetweenDays(storeRange.start, storeRange.end);
              var formattedDates = $scope.convertToYYYYMD(allSelectedDates);
              disableDates.forEach(function (date) {
                if (allSelectedDates.includes(date)) {
                  $scope.showPopup('#AlertModal');
                  flag = false;
                  isValid = false;
                  var inputs = $('#daterangepicker span span input');
                  inputs[CONST_HELPER.ZERO].value = '8 Nov 2018';
                  inputs[CONST_HELPER.ONE].value = '8 Nov 2018';
                }
              });
              if (flag) {
                this.range(storeRange);
                angular.copy(storeRange, $scope.nightly_selected_dates);
                total = CONST_HELPER.ZERO;
                for (var prop in formattedDates) {
                  // object[prop]
                  prop = formattedDates[prop];
                  if ($scope.calendar[prop]) {
                    if ($scope.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
                      $scope.availableSeats = $scope.calendar[prop].available_room;
                    } else {
                      $scope.availableSeats = $scope.calendar[prop].available_seat;
                    }
                  }
                  break;
                }

                if ($scope.adventureDetails.booking_type == 2 && $scope.adventureDetails.single_day_activity == 0 && $scope.adventureDetails.daily_rate_activity == 0) {
                  formattedDates.splice(-1 , 1);
                }

                formattedDates.forEach(function (val) {
                  if ($scope.calendar[val]) {
                    total = total + +$scope.calendar[val].price;
                    if ($scope.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
                      $scope.availableSeats = $scope.availableSeats > +$scope.calendar[val].available_room ? +$scope.calendar[val].available_room : $scope.availableSeats;
                    } else {
                      $scope.availableSeats = $scope.availableSeats > +$scope.calendar[val].available_seat ? +$scope.calendar[val].available_room : $scope.availableSeats;
                    }
                  }
                });
                $scope.consumerPrice = total;
                $scope.$apply();
                storeRange = {start: null, end: null};
              }
            } else {
              var inputs = $('#daterangepicker span span input');
              inputs[CONST_HELPER.ZERO].value = '8 Nov 2018';
              inputs[CONST_HELPER.ONE].value = '8 Nov 2018';
            }
          }
          var inputs = $('#daterangepicker span span input');
          if (inputs[CONST_HELPER.ZERO].value == 'day of the week day month' && inputs[CONST_HELPER.ONE].value == 'day of the week day month') {
            inputs[CONST_HELPER.ZERO].value = '8 Nov 2018';
            inputs[CONST_HELPER.ONE].value = '8 Nov 2018';
          }
        }

        $('#daterangepicker').kendoDateRangePicker({
          change: onChange,
          close: onClose,
          open: onOpen,
          format: 'MMM dd yyyy',
          range: [start, end],
          min: start,
          depth: 'month',
          start: 'month',
          max: end,
          dateInput: true,
          // animation: false,
          disableDates: function (date) {
            return true;
          }
        });
        var datePickerOptions = $('#daterangepicker').data('kendoDateRangePicker').dateView.popup.options;
        datePickerOptions.position = 'center left';
        var inputs = $('#daterangepicker span span input');
        inputs[CONST_HELPER.ZERO].value = '8 Nov 2018';
        inputs[CONST_HELPER.ONE].value = '8 Nov 2018';
        $('#daterangepicker span span input').eq(CONST_HELPER.ZERO).attr('readonly', 'readonly');
        $('#daterangepicker span span input').eq(CONST_HELPER.ONE).attr('readonly', 'readonly');
        var daterangepicker = $('#daterangepicker').data('kendoDateRangePicker');
        daterangepicker.range({start: new Date($scope.createBooking.start), end: new Date($scope.createBooking.end)});
        var allSelectedDates = $scope.getAllInBetweenDays($scope.createBooking.start, $scope.createBooking.end);
        var formattedDates = $scope.convertToYYYYMD(allSelectedDates);
        for (var prop in formattedDates) {
          // object[prop]
          prop = formattedDates[prop];
          if ($scope.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
            $scope.availableSeats = $scope.calendar[prop].available_room;
          } else {
            $scope.availableSeats = $scope.calendar[prop].available_seat;
          }

          break;
        }
        total = CONST_HELPER.ZERO;

        if ($scope.adventureDetails.booking_type == 2 && $scope.adventureDetails.single_day_activity == 0 && $scope.adventureDetails.daily_rate_activity == 0) {
          formattedDates.splice(-1 , 1);
        }

        formattedDates.forEach(function (val) {
          if ($scope.calendar[val]) {
            total = total + +$scope.calendar[val].price;
            if ($scope.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
              $scope.availableSeats = $scope.availableSeats > +$scope.calendar[val].available_room ? +$scope.calendar[val].available_room : $scope.availableSeats;
            } else {
              $scope.availableSeats = $scope.availableSeats > +$scope.calendar[val].available_seat ? +$scope.calendar[val].available_room : $scope.availableSeats;
            }
          }
        });
        $scope.consumerPrice = total;
        $scope.$apply();
      };

      $scope.convertToYYYYMD = function (array) {
        return array.map(function (val) {
          return val.substr(0, 4) + '/' + (+val.substr(4, 2) - CONST_HELPER.ONE) + '/' + +val.substr(6, 2);
        });
      };
      $scope.addEventHandlerForCells = function () {
        $(' div.k-calendar-view > table > tbody > tr > td > a').click(function () {
          $('.k-animation-container').hide();
          var text = '';
          $scope.consumerPrice = +$scope.calendar[$(this)[CONST_HELPER.ZERO].dataset.value].price;
          var tempDate = $(this)[CONST_HELPER.ZERO].dataset.value.split('/');
          var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          text = tempDate[CONST_HELPER.TWO] + ' ' + months[tempDate[CONST_HELPER.ONE]] + ' ' + tempDate[CONST_HELPER.ZERO];
          $('#datepicker').val(text);
          $scope.nightly_selected_dates.start = new Date(text);
          $scope.nightly_selected_dates.end = new Date(text);
          $timeout(function () {
            $(' div.k-calendar-view > table > tbody > tr > td > a').each(function (idx, elem) {
              $(this).children().remove();
            });
          }, CONST_HELPER.ZERO);
        });
      };
      $scope.initializeKendoSingleDatePicker = function () {
        var start = new Date((+$scope.adventureDetails.nightly_slots.from_date.split('-')[CONST_HELPER.ZERO]), (+$scope.adventureDetails.nightly_slots.from_date.split('-')[CONST_HELPER.ONE] - CONST_HELPER.ONE), CONST_HELPER.ONE);
        var end = new Date((+$scope.adventureDetails.nightly_slots.to_date.split('-')[CONST_HELPER.ZERO]), (+$scope.adventureDetails.nightly_slots.to_date.split('-')[CONST_HELPER.ONE] + CONST_HELPER.ONE), CONST_HELPER.ZERO);

        function onOpen() {
          $(' div.k-calendar-view > table > tbody > tr > td > a').each(function (idx, elem) {
            $(this).children().remove();
          });
          $('.k-widget.k-calendar').addClass('k-calendar-range k-calendar-single');
          $scope.addEventHandlerForCells();
          $('a.k-link.k-nav-prev').click(function (e) {
            $timeout(function () {
              $('div.k-calendar-view > table > tbody > tr > td > a').each(function (idx, elem) {
                $(this).children().remove();
              });
              setAvailableSeatsInSingleCalendar();
              $scope.addEventHandlerForCells();
            }, 800);
          });
          $('a.k-link.k-nav-next').click(function (e) {
            $timeout(function () {
              $('div.k-calendar-view > table > tbody > tr > td > a').each(function (idx, elem) {
                $(this).children().remove();
              });
              setAvailableSeatsInSingleCalendar();
              $scope.addEventHandlerForCells();
            }, 800);
          });
          setAvailableSeatsInSingleCalendar();
          $('.k-animation-container').hide();
          $timeout(function () {
            $('div.k-header i').remove();
            $('.k-header').append('<i class="icon-white-close menu-icon"></i>');
            $('div.k-header > i.icon-white-close.menu-icon').click(function () {
              $('.k-animation-container').hide();
              $('body').removeClass('modal-open');
            });
            $('.k-animation-container').addClass('k-booking-calendar');
            $('.k-animation-container').show();
          }, 300);
          $('.k-calendar-view').find('.k-today').removeClass('k-today');
        }

        function onChange() {

        }

        function onClose(e) {

        }

        $timeout(function () {
          $('#datepicker').kendoDatePicker({
            change: onChange,
            close: onClose,
            open: onOpen,
            format: 'MMM dd yyyy',
            range: [start, end],
            min: start,
            depth: 'month',
            start: 'month',
            max: end,
            dateInput: true,
            disableDates: function (date) {
              return true;
            }
          });
          var datePickerOptions = $('#datepicker').data('kendoDatePicker').dateView.popup.options;
          datePickerOptions.position = 'center left';
          $('#datepicker').val('Select your date');
          $('#datepicker').attr('readonly', 'readonly');

          $('#datepicker').click(function () {
            $('.k-animation-container').show();
          });
          var datepicker = $('#datepicker');
          var text = moment($scope.createBooking.start._d).utcOffset(0, true).format('DD MMM YYYY');
          datepicker.val(text);
          var allSelectedDates = $scope.getAllInBetweenDays($scope.createBooking.start, $scope.createBooking.start);
          var formattedDates = $scope.convertToYYYYMD(allSelectedDates);
          for (var prop in formattedDates) {
            // object[prop]
            prop = formattedDates[prop];
            if ($scope.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
              $scope.availableSeats = $scope.calendar[prop].available_room;
            } else {
              $scope.availableSeats = $scope.calendar[prop].available_seat;
            }

            break;
          }
          total = CONST_HELPER.ZERO;

          if ($scope.adventureDetails.booking_type == 2 && $scope.adventureDetails.single_day_activity == 0 && $scope.adventureDetails.daily_rate_activity == 0) {
            formattedDates.splice(-1 , 1);
          }

          formattedDates.forEach(function (val) {
            if ($scope.calendar[val]) {
              total = total + +$scope.calendar[val].price;
              if ($scope.adventureDetails.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
                $scope.availableSeats = $scope.availableSeats > +$scope.calendar[val].available_room ? +$scope.calendar[val].available_room : $scope.availableSeats;
              } else {
                $scope.availableSeats = $scope.availableSeats > +$scope.calendar[val].available_seat ? +$scope.calendar[val].available_room : $scope.availableSeats;
              }
            }
          });
          $scope.consumerPrice = total;
          $scope.$apply();
        }, 100);
      };
      $scope.destroySingleDateRangePicker = function () {
        $('.k-animation-container').remove();
        if ($('#datepicker').data('kendoDatePicker')) {
          $('#datepicker').data('kendoDatePicker').destroy();
          $('#demo > div.package-booking-bar > div.package-side-advent > div.package-check-links > a').empty();
          $('#demo > div.package-booking-bar > div.package-side-advent > div.package-check-links > a').html('<input ng-if="$scope.adventureDetails.single_day_activity == 1" id="datepicker" />');
          $('.k-animation-container').empty();
        }
      };

      // function for destroying datepicker
      $scope.destroyDateRangePicker = function () {
        $('div#daterangepicker').empty();
        $('.k-animation-container').remove();
        if ($('#daterangepicker').data('kendoDateRangePicker')) {
          $('#daterangepicker').data('kendoDateRangePicker').destroy();
          $('div#daterangepicker').empty();
          $('.k-animation-container').empty();
        }
      };
      // function for getting all dates between date range
      $scope.getAllInBetweenDays = function (start, end) {
        start = new Date(start).getTime();
        end = new Date(end).getTime();
        var oneDay = 24 * 3600 * 1000;
        for (var d = [], incrementOp = start * CONST_HELPER.ONE, end = end * CONST_HELPER.ONE; incrementOp <= end; incrementOp += oneDay) {
          d.push(new Date(incrementOp).yyyymmdd());
        }
        return d;
      };

      /* alert on Drop */
      $scope.alertOnDrop = function (event, delta, revertFunc) {
        $scope.alertMessage = ('Event Dropped to make dayDelta ' + delta);

        var requestObject = {
          'token': $scope.user.token,
          'package_id': event.slot.package_id,
          'package_slot_id': event.slot.id,
          'adult_price': event.slot.adult_price,
          'max_capacity': event.slot.max_capacity,
          'available_seat': event.slot.available_seat,
          'from_date': moment($scope.changedSlotStartDate).format('YYYY-MM-DD'),
          'to_date': moment($scope.changedSlotEndDate).format('YYYY-MM-DD')
        };

        $scope.showLoader(); // show loader
        operatorPackageListService.updatePackageSlot(requestObject).then(function (response) {
          if (response.data) {
            if (response.data.code === '200') {
              event.slot.from_date = $scope.changedSlotStartDate;
              event.slot.to_date = $scope.changedSlotEndDate;
              $scope.event = {};
              $scope.delta = {};
              $scope.revertFunc = {};
              $scope.hideLoader(); // show loader
            } else {
              logoutCheck(response.data.code);
            }
          }
        }, function () {
          $scope.hideLoader(); // hide loader
          $scope.showPopup('#serverErrModal');
        });
      };

      function logoutCheck(code) {
        if (code === '401') {
          $scope.logout();
        }
      }

      /* alert on Resize */
      $scope.alertOnResize = function (event, delta, revertFunc, jsEvent, ui, view) {
        $scope.alertMessage = ('Event Resized to make dayDelta ' + delta);
        // alert($scope.alertMessage);
      };

      /* Change View */
      $scope.changeView = function (view, calendar) {
        uiCalendarConfig.calendars[calendar].fullCalendar('changeView', view);
      };

      $scope.closeBookingPopup = function () {
        $scope.hidePopup('#myPublishModal');
      };

      $scope.openManualBooking = function () {
        if ($scope.eventDetail.hasOwnProperty('booking_type') && $scope.eventDetail.booking_type == CONST_HELPER.ONE) {
          $state.go('operator-manual-booking', {
            'slot_id': $scope.eventDetail.id,
            'op_id': $rootScope.calendarAgentOperator.operator_profile ? $rootScope.calendarAgentOperator.operator_profile.user_id : ''
          });
          return;
        }
        var start_date = $scope.nightly_selected_dates.start ? $scope.nightly_selected_dates.start : $scope.createBooking.start._d;
        var end_date = $scope.nightly_selected_dates.end ? $scope.nightly_selected_dates.end : $scope.createBooking.end._d;
        $state.go('operator-manual-booking', {
          'nightly_slot_id': $scope.selectedPackage.nightly_slot_id || $scope.eventDetail.nightly_slot_id,
          package_id: $scope.selectedPackage.package_id || $scope.eventDetail.package_id,
          start_date: start_date ? start_date.getTime() : new Date($scope.eventDetail.from_date).getTime(),
          end_date: end_date ? end_date.getTime() : new Date($scope.eventDetail.to_date).getTime(),
          booking_type: $scope.eventDetail.hasOwnProperty('booking_type') ? $scope.eventDetail.booking_type : CONST_HELPER.TWO,
          op_id: $rootScope.calendarAgentOperator.operator_profile ? $rootScope.calendarAgentOperator.operator_profile.user_id : ''
        });
      };
      $scope.openManualGuestBooking = function () {
        $state.go('manual-guest-booking', {
          nightly_slot_id: $scope.selectedPackage.nightly_slot_id,
          start_date: $scope.nightly_selected_dates.start ? $scope.nightly_selected_dates.start.getTime() : $scope.createBooking.start._d.getTime(),
          end_date: $scope.nightly_selected_dates.end ? $scope.nightly_selected_dates.end.getTime() : $scope.createBooking.end._d.getTime()
        });
      };
      $scope.openEditSlotDetails = function (eventDetail) {
        eventDetail.from_date = getConvertedDate(eventDetail.from_date);
        eventDetail.to_date = getConvertedDate(eventDetail.to_date);
        if ($rootScope.isAgent) {
          $state.go('operator-edit-slots', {
            nightly_slot_id: eventDetail.nightly_slot_id,
            start_date: new Date(eventDetail.from_date).getTime(),
            end_date: new Date(eventDetail.to_date).getTime(),
            op_id: $rootScope.calendarAgentOperator.operator_profile ? $rootScope.calendarAgentOperator.operator_profile.user_id : ''
          });
        } else {
          if (eventDetail.nightly_slot_id) {
            $state.go('operator-edit-slots', {
              nightly_slot_id: eventDetail.nightly_slot_id,
              start_date: new Date(eventDetail.from_date).getTime(),
              end_date: new Date(eventDetail.to_date).getTime()
            });
          } else {
            $state.go('operator-edit-slots', {
              slot_id: eventDetail.id,
              start_date: new Date(eventDetail.from_date).getTime(),
              end_date: new Date(eventDetail.to_date).getTime()
            });
          }
        }
      };
      $scope.checkForEmptyObjectAndArray = function (val) {
        if (val) {
          if ($.isArray(val)) {
            return val.length > CONST_HELPER.ZERO;
          } else {
            return !angular.equals(val, {});
          }
        } else {
          return false;
        }
      };
      $scope.setAdventure = function (selectedPackage, $select) {
        $scope.showLoader();
        $scope.selectedPackage = selectedPackage;
        var adventurePackage = {
          package_id: $scope.selectedPackage.package_id,
          userToken: $scope.user.token
        };
        getAdventureDetails(adventurePackage);
      };
      Array.prototype.groupBy = function (prop) {
        return this.reduce(function (groups, item) {
          var val = item[prop];
          groups[val] = groups[val] || [];
          groups[val].push(item);
          return groups;
        });
      };

      function daysBetween(one, another) {
        return Math.round(Math.abs((+one) - (+another)) / 8.64e7);
      }

      /* calendar config object */
      $scope.uiConfig = {
        calendar: {
          editable: false,
          eventLimit: true,
          selectable: true,
          unselectAuto: true,
          defaultView: $rootScope.calendarViewType,
          defaultDate: $rootScope.currentMonthDate,
          ignoreTimezone: false,
          timezone: 'Asia/Kolkata',
          header: {
            left: 'prev,next today',
            center: 'title',
            right: 'basicWeek month'
          },
          views: {
            month: {
              eventLimit: 3, // adjust to 5 only for month
              columnFormat: calendarHeaderObject.monthHeader
            },
            agenda: {
              eventLimit: 3 // adjust to 25 only for Week/Day
            },
            week: {
              columnFormat: calendarHeaderObject.weekHeader,
              eventLimit: 3 // adjust to 25 only for Week/Day
            }
          },
          eventLimitClick: function (event, jsEvent) {
            $scope.showCustomPopover = false;
            $scope.popoverDate = getConvertedDate(event.date);
            $scope.getEvents($scope.popoverDate, $scope.popoverDate, function (events, clearEvent) {
              $scope.showCustomPopover = true;
              $scope.moreCalendarEvent = events;
              $('body').addClass('modal-open');
            }, '', '', 'singleDayEvents');
          },
          eventClick: $scope.alertOnEventClick,
          eventDrop: $scope.confirmSlotDateChange,
          eventResize: $scope.alertOnResize,
          dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
          dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          viewRender: viewRender,
          eventRender: function (event, element, view) {
            // element.bind('dblclick', function () {
            //   $state.go('operator-edit-slots', { 'slot_id': event.slot.id });
            // });
            // var htmlTitle = getHtmlTitle(event.slot)

            if (view.type === 'basicWeek') {
              element.find('.fc-title').html(getHtmlTitle(event.slot));
              if (event.slot.is_block == true) {
                element.find('.fc-title')[0].innerHTML = element.find('.fc-title')[0].innerHTML + " <span class='expired-slot-text'>&nbsp;&nbsp; Blocked</span>";
              }
              if (event.slot.expired && event.slot.is_block != true) {
                element.find('.fc-title')[0].innerHTML = element.find('.fc-title')[0].innerHTML + " <span class='expired-slot-text'>&nbsp;&nbsp; Unavailable</span>";
              }
            } else {
              element.find('.fc-title').text(event.title);
              if (event.slot.is_block == true) {
                element.find('.fc-title')[0].innerHTML = "<span class='expired-slot-text'>Blocked &nbsp;</span>" + element.find('.fc-title')[0].innerHTML;
              }
              if (event.slot.expired && event.slot.is_block != true) {
                element.find('.fc-title')[0].innerHTML = "<span class='expired-slot-text'>Unavailable &nbsp;</span>" + element.find('.fc-title')[0].innerHTML;
              }
            }

            if (!event.slot.expired && event.slot.available_percentage >= 100) {
              event.editable = true;
            }
          },
          eventAfterAllRender: function (view) {
            var element = view.el;
            $(element).find('.fc-more').text('More...');
          },
          windowResize: function () {
            $('.fc-more').text('More...');
          },
          select: function (start, end) {
            end = end.subtract(CONST_HELPER.ONE, 'days');
            $scope.createBooking.start = getConvertedDate(start);
            $scope.createBooking.end = getConvertedDate(end);
            $scope.noOfDays = daysBetween($scope.createBooking.start, $scope.createBooking.end);
            $scope.selectedSlots = [];
            $scope.getEvents($scope.createBooking.start, $scope.createBooking.end, function (events, clearEvent) {
              $scope.CalendarEvents = events;
              $scope.CalendarEvents.forEach(function (slot) {
                slot.from_date = getConvertedDate(new Date(slot.from_date));
                if (slot.booking_type == CONST_HELPER.TWO && new Date(slot.from_date).setHours(0, 0, 0, 0) >= $scope.createBooking.start._d.setHours(0, 0, 0, 0) && new Date(slot.from_date).setHours(0, 0, 0, 0) <= $scope.createBooking.end._d.setHours(0, 0, 0, 0)) {
                  $scope.selectedSlots.push({
                    package_id: slot.package_id,
                    package_name: slot.package_name,
                    nightly_slot_id: slot.nightly_slot_id
                  });
                }
              });

              var tempObj = $scope.selectedSlots.reduce(function (groups, item) {
                groups[item.package_id] = (groups[item.package_id] || []).concat(item);
                return groups;
              }, {});
              $scope.selectedSlots = [];
              ++$scope.noOfDays;
              for (var key in tempObj) {
                if (tempObj.hasOwnProperty(key)) {
                  if (tempObj[key].length == $scope.noOfDays) {
                    $scope.selectedSlots.push({
                      package_id: tempObj[key][CONST_HELPER.ZERO].package_id,
                      package_name: tempObj[key][CONST_HELPER.ZERO].package_name,
                      nightly_slot_id: tempObj[key][CONST_HELPER.ZERO].nightly_slot_id
                    });
                  }
                  ;
                }
              }
              if ($scope.selectedSlots.length > CONST_HELPER.ZERO && $scope.createBooking.end._d.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)) {
                var adventurePackage = {};
                if ($scope.selectedPackage.package_id) {
                  adventurePackage = {
                    package_id: $scope.selectedPackage.package_id,
                    userToken: $scope.user.token
                  };
                } else {
                  $scope.selectedPackage = {
                    package_id: $scope.selectedSlots[CONST_HELPER.ZERO].package_id,
                    package_name: $scope.selectedSlots[CONST_HELPER.ZERO].package_name,
                    nightly_slot_id: $scope.selectedSlots[CONST_HELPER.ZERO].nightly_slot_id
                  };
                  adventurePackage = {
                    package_id: $scope.selectedSlots[CONST_HELPER.ZERO].package_id,
                    userToken: $scope.user.token
                  };
                }
                getAdventureDetails(adventurePackage);
                $timeout(function () {
                  $scope.showPopup('#myPublishModal');
                  $scope.showLoader();
                }, CONST_HELPER.ZERO);
              } else {
                uiCalendarConfig.calendars.operatorCalendar.fullCalendar('unselect');
              }
            }, '', true);
          },
          dayClick: function (date, jsEvent, view) {
            if (jsEvent.target.classList.contains('fc-day-number')) {
              if ($window.innerWidth <= 767 && view.type === 'month') { // if mobile
                $scope.selectedDate = getConvertedDate(date);
                $scope.selectedDateObj = new Date($scope.selectedDate);

                // getEvents is a custom function that fetch events...
                $scope.getEvents($scope.selectedDate, $scope.selectedDate, function (events) {
                  $scope.mobileEvents.splice(0); // remove previous events...
                  $scope.mobileEvents = events;
                  $scope.ifCalendarLoading = false;
                });

                if ($('.selected-date').length) {
                  $('.selected-date').closest('.fc-day-number')[0].innerHTML = $('.selected-date').text();
                  $('.selected-date').remove();
                }
                $('.today').removeClass('today');
                jsEvent.target.innerHTML = "<span class='selected-date'>" + jsEvent.target.innerHTML + '</span>';
              }
            }
          }
        }
      };

      $scope.closePopover = function () {
        $scope.popoverDate = '';
        $scope.showCustomPopover = false;
        $('body').removeClass('modal-open');
      };

      $scope.getEventClassName = function (e) {
        var slotStartDate = new Date(e.from_date);
        var currentDate = new Date();
        var diffDays = Math.floor((Date.UTC(slotStartDate.getFullYear(), slotStartDate.getMonth(), slotStartDate.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
        if (diffDays < 0) { // if slot expired
          e.expired = true;
        } else {
          e.expired = false;
        }
        var occupancy = CONST_HELPER.HUNDRED - e.available_percentage;
        var className = addClass(occupancy, e.expired, e.booking_type, e.is_block);
        return className;
      };

      function viewRender(view, element) {
        /* add a bootstrap datepicker inside the full calendar headers */

        $scope.viewObject = view;
        $scope.viewObject.start = getConvertedDate($scope.viewObject.start);
        $scope.viewObject.end = getConvertedDate($scope.viewObject.end);
        $('.fc-toolbar').find('.fc-center').html('<div id="sandbox-container"><input type="text" class="form-control fc-datepicker" value="' + view.title + '" readonly><span class="fc-span-caret caret"></span></div>');
        $('#sandbox-container input').datepicker({
          autoclose: true,
          format: 'MM  yyyy',
          viewMode: 'months',
          minViewMode: 'months'
        }).on('changeMonth', function (e) {
          /* go to the selected month */
          $('#sandbox-container input').datepicker('setDate', e.date);
          uiCalendarConfig.calendars.operatorCalendar.fullCalendar('gotoDate', e.date);
          $scope.previousSelectedDate = e.date;
          var currentMonthDate = new Date(view.calendar.getDate()._d);
          $rootScope.currentMonthDate = new Date(currentMonthDate.setDate(currentMonthDate.getDate() + 1)); // increase the date by one day to fix timezone issue...
          $state.go('operator-calendar', {
            locations_id: locationIds.join(),
            packages_id: packageIds.join(),
            select_all: $scope.calendarFilters.all,
            unavailable: $scope.calendarFilters.expired_slot,
            empty: $scope.calendarFilters.empty_slot,
            occupancy_below74: $scope.calendarFilters.occupancy_below74,
            occupancy_above74: $scope.calendarFilters.occupancy_above74,
            occupancy_full: $scope.calendarFilters.occupancy_full,
            nightly_booking: $scope.calendarFilters.nightly_booking,
            calendar_date: moment($rootScope.currentMonthDate).format('YYYY.MM.DD')
          });
        }).on('hide', function (e) {
          $scope.previousSelectedDate = $scope.previousSelectedDate ? $scope.previousSelectedDate : e.date;
          // $('#sandbox-container input').datepicker('setDate',$scope.previousSelectedDate);
          $('.fc-datepicker').val($scope.currentCalendarView.title);
        }).on('show', function (e) {
          // if($scope.previousSelectedDate)
          // $('#sandbox-container input').datepicker('setDate',$scope.previousSelectedDate);

          $('.fc-datepicker').val($scope.currentCalendarView.title);
        });

        $('#sandbox-container').on('click', function () {
          $('#sandbox-container input').datepicker('show');
        });

        $rootScope.calendarViewType = view.type;
        $scope.currentCalendarView = view;
        $scope.currentCalendarElement = element;

        // customize column headers
        if (view.type === 'basicWeek') {
          var columnHeaderElement = $('.fc-day-header');
          angular.forEach(columnHeaderElement, function (ele) {
            var title = ele.innerHTML.split(' ');
            ele.innerHTML = '<div>' + title[0] + "<br><span class='week-header'>" + title[1] + '</span></div>';
          });
        }

        // getEvents is a custom function that fetch events...
        var sdate = getConvertedDate(view.start);
        var edate = getConvertedDate(view.end);
        if ($window.innerWidth > 767 || view.type === 'basicWeek') { // if Desktop
          if ($rootScope.isAgent && !$rootScope.calendarAgentOperator) {
            return;
          } else if ($rootScope.isAgent && $rootScope.calendarAgentOperator && !$scope.packageList.length) {
            $scope.changeOperatorData($rootScope.calendarAgentOperator);
            return;
          }
          $scope.getEvents(sdate, edate, function (events, clearEvent) {
            renderEvents(events, clearEvent); // it will render all events again on view change...
          }, $scope.eventLimit);
        } else {
          if ($rootScope.isAgent && !$rootScope.calendarAgentOperator) {
            return;
          } else if ($rootScope.isAgent && $rootScope.calendarAgentOperator && !$scope.packageList.length) {
            $scope.changeOperatorData($rootScope.calendarAgentOperator);
            return;
          }
          $scope.selectedDate = getConvertedDate(view.calendar.getDate());
          $scope.selectedDateObj = new Date($scope.selectedDate);
          renderEvents([]); // remove events on mobile view change...

          var today = moment(new Date());
          if (today.format('M') === $scope.selectedDate.format('M') && $scope.selectedDate.format('YYYY-MM-DD') !== today.format('YYYY-MM-DD')) {
            // view.calendar.today();
            $scope.selectedDate = today;
          }

          $scope.getEvents($scope.selectedDate, $scope.selectedDate, function (events) {
            $scope.mobileEvents.splice(0); // remove previous events...
            $scope.mobileEvents = events;
            $scope.ifCalendarLoading = false;
          }, $scope.eventLimit);
        }

        var todayElement = $(element).find('.fc-today');
        var dateElements = $(element).find('.fc-day-top');

        /* add a span tag for todays date... */
        if (todayElement.length > 1 && $rootScope.calendarViewType === 'month') {
          todayElement[1].firstChild.innerHTML = "<span class='today'>" + todayElement[1].firstChild.innerHTML + '</span>';
        }

        if (dateElements.length > 0 && todayElement.length === 0) {
          angular.forEach(dateElements, function (e) {
            if ($scope.selectedDate && e.attributes['data-date'].value === $scope.selectedDate.format('YYYY-MM-DD')) {
              e.firstChild.innerHTML = "<span class='selected-date'>" + e.firstChild.innerHTML + '</span>';
            }
          });
        }
      }

      function getConvertedDate(date) {
        var edate = new Date(date);
        var stzDifference = new Date(edate).getTimezoneOffset(); // this gives me timezone difference of local and UTC time in minutes
        var offsetTime;
        // if the minutes difference is > zero then add add the minutes into it...
        if (stzDifference > CONST_HELPER.ZERO) {
          offsetTime = moment(new Date(edate.getTime() + stzDifference * 60 * 1000)); // this will calculate time in point of view local time and set date
        } else {
          offsetTime = moment(edate); // return the date as it is
        }
        return offsetTime;
      }

      function renderEvents(events, saveEvent) {
        if (!saveEvent) {
          $scope.events.splice(0); // remove previous events...
        }
        angular.forEach(events, function (e) {
          /* check if slot event is expired... */
          var slotStartDate = new Date(e.from_date);
          var currentDate = new Date();
          var diffDays = Math.floor((Date.UTC(slotStartDate.getFullYear(), slotStartDate.getMonth(), slotStartDate.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
          if (diffDays < 0) { // if slot expired
            e.expired = true;
          } else {
            e.expired = false;
          }
          var occupancy = CONST_HELPER.HUNDRED - e.available_percentage;
          /* add one day bcoz full calendar exclude end date... */
          var endDate = new Date(e.to_date);
          endDate = endDate.setDate(endDate.getDate() + 1);
          $scope.events.push({
            title: getTitle(e),
            start: $filter('changeDateObject')(e.from_date),
            end: e.booking_type == 2 ? $filter('changeDateObject')(e.from_date) : endDate,
            className: addClass(occupancy, e.expired, e.booking_type, e.is_block),
            slot: e
          });
          // push slot event data...
        });
      }

      /* event sources array */
      $scope.eventSources = [$scope.events];

      /* FETCH ALL THE SLOT EVENT DATA  */
      $scope.getEvents = function (start, end, callback, limit, selectedRange, ifSingleDay) {
        $scope.ifCalendarLoading = true;
        $scope.showLoader();
        if ($scope.packageList.length === 0) {
          getLocationNames();
          getOperatorPackages(start, end, callback, limit); // IF PACKAGE LIST IS EMPTY THEN GET OPERATOR PACKAGES FIRST.
        } else {
          if (selectedRange) {
            var s = new Date(start);
            var e = new Date(end);
            getOperatorslots(s, e, function (data) {
              $scope.hideLoader();
              $scope.ifCalendarLoading = false;
              callback(data);
            }, limit); // GET OPERATOR SLOT EVENT DATA.
          } else {
            var monthDateRange = getMonthDateRange(start, end, selectedRange);
            weekEvents = [];
            var responseLen = 0;
            monthDateRange.map(function (val) {
              var s = val.startDate;
              var e = val.endDate;
              var dateObj = {};
              getOperatorslots(s, e, function (data) {
                responseLen = responseLen + CONST_HELPER.ONE;
                var saveEvent = true;
                if (responseLen === CONST_HELPER.ONE) { // when first response came, clear previous events...
                  saveEvent = false;
                }
                $scope.hideLoader();
                if (responseLen === monthDateRange.length) { // when last response came, clear previous events...
                  $scope.ifCalendarLoading = false;
                }
                // get the maximum required data to print on calendar each date...
                var dateObjData = !ifSingleDay ? manipulateCalendarData(data, dateObj) : data;
                callback(dateObjData, saveEvent);
              }, limit); // GET OPERATOR SLOT EVENT DATA.
            });
            // callback(weekEvents);
            //
          }
        }

      };

      // function to divide month start and end dates into week date ranges
      function getMonthDateRange(start, end, selectedRange) {
        start = new Date(new Date(start).setHours(0));
        end = new Date(new Date(end).setHours(0));
        if (!selectedRange && start.getDate() != end.getDate()) {
          end.setDate(end.getDate() - 1);
        }
        var sDate;
        var eDate;
        var dateArr = [];

        while (start <= end) {
          if (start.getDay() == 0 || (dateArr.length == 0 && !sDate)) {
            sDate = new Date(start.getTime());
          }

          if ((sDate && start.getDay() == 6) || start.getTime() == end.getTime()) {
            eDate = new Date(start.getTime());
          }

          if (sDate && eDate) {
            dateArr.push({'startDate': sDate, 'endDate': eDate});
            sDate = undefined;
            eDate = undefined;
          }

          start.setDate(start.getDate() + 1);
        }
        return dateArr;
      }

      /* function to get operator packages list */
      function getOperatorPackages(start, end, callback, limit) {
        var data = {
          'status': 1,
          'token': $scope.user.token
        };

        operatorCalendarService.getOperatorPackages(data).then(function (res) {
          if (res && res.data.response) {
            packageIds = [];
            $scope.packageList = res.data.response.docs;
            if (!$stateParams.packages_id) {
              $scope.adventure.isAllSelected = true;
              $scope.packageList.map(function (packageObj) {
                packageIds.push(packageObj.package_id);
                packageObj.selected = true;
              });
            }
            var monthDateRange = getMonthDateRange(start, end);
            weekEvents = [];
            var responseLen = 0;
            monthDateRange.map(function (val, i) {
              var s = val.startDate;
              var e = val.endDate;
              var dateObj = {};
              getOperatorslots(s, e, function (data) {
                responseLen = responseLen + 1;
                var saveEvent = true;
                if (responseLen === CONST_HELPER.ONE) { // when first response came, clear previous events...
                  saveEvent = false;
                }
                $scope.hideLoader();
                if (responseLen === monthDateRange.length) { // when last response came, hideLoader...
                  if ($stateParams.packages_id) {
                    locationIds = $stateParams.locations_id.split(',').map(Number);
                    $scope.adventure.isAllSelected = false;
                    packageIds && packageIds.length == $scope.packageList.length ? $scope.adventure.isAllSelected = true : $scope.adventure.isAllSelected = false;
                    $scope.packageList.map(function (packageObj) {
                      if (packageObj.op_location_id) {
                        if (locationIds.indexOf(packageObj.op_location_id[0]) != -1) {
                          packageObj.selected = true;
                        } else if (locationIds.indexOf(packageObj.op_location_id[0]) == -1) {
                          packageObj.selected = false;
                        }
                      } else if (!packageObj.op_location_id && locationIds.indexOf(0) != -1) {
                        packageObj.selected = true;
                      }
                    });
                  }
                  $scope.ifCalendarLoading = false;
                }
                // get the maximum required data to print on calendar each date...
                var dateObjData = manipulateCalendarData(data, dateObj);
                callback(dateObjData, saveEvent);
              }, limit); // GET OPERATOR SLOT EVENT DATA.
            });
          } else {
            logoutCheck(res.data.code);
          }
        });
      }

      function manipulateCalendarData(data, dateObj) {
        var tempData = [];
        for (var i = 0; i < data.length; i++) {
          var e = data[i];
          if (!dateObj[e.from_date]) {
            dateObj[e.from_date] = [];
          }
          if (dateObj[e.from_date].length < 4) {
            dateObj[e.from_date].push(e);
            tempData.push(e);
          }
          if (Object.keys(dateObj).length > 6 && dateObj[e.from_date].length > 4) {
            dateObj = {};
            break;
          }
        }
        return tempData;
      }

      /* function to get operator package slot evets */
      var weekEvents = [];

      function getOperatorslots(start, end, cb, limit) {
        $stateParams.locations_id ? locationIds = $stateParams.locations_id.split(',').map(Number) : locationIds;
        $stateParams.packages_id ? packageIds = $stateParams.packages_id.split(',').map(Number) : packageIds;
        var slotObj = {
          'from_date': moment(start).format('YYYY-MM-DD'),
          'to_date': moment(end).format('YYYY-MM-DD'),
          'package_ids': packageIds,
          'location_id': locationIds,
          'limit': limit || '',
          'calendarFilters': $scope.calendarFilters
        };
        if ($scope.userRole == 'agent') {
          slotObj['operator_id'] = $rootScope.calendarAgentOperator.operator_profile ? $rootScope.calendarAgentOperator.operator_profile.user_id : [];
        }
        operatorCalendarService.getOperatorSlots(slotObj, $scope.user.token).then(function (res) {
          if (res.data.code === '200') {
            weekEvents = weekEvents.concat(res.data.data);
            cb(res.data.data);
            // $scope.hideLoader();
          } else {
            logoutCheck(res.data.code);
          }
        });
      }

      /* function that returns classname according to seats occupancy... */
      function addClass(availablePercentage, isExpired, bookingType, is_block) {
        var calssName = '';
        if (isExpired) {
          calssName = 'expired-slot';
        } else {
          availablePercentage = +(availablePercentage);
          if (bookingType && bookingType == CONST_HELPER.TWO) {
            switch (true) {
              case availablePercentage === 0 && is_block != true: // When no bookings...
                calssName = 'grey-empty';
                break;
              case is_block == true : // When no package is nightly/daily/single...
                calssName = 'expired-slot';
                break;
              case availablePercentage === 100: // When all bookings are done...
                calssName = 'red-full';
                break;
              default: // When all bookings are done...
                calssName = 'nightly';
                break;
            }
          } else {
            switch (true) {
              case availablePercentage === 0: // When no bookings...
                calssName = 'grey-empty';
                break;

              case availablePercentage <= 74 && availablePercentage >= 1: // When bookings 1-74% done...
                calssName = 'blue-less-occupancy';
                break;
              case availablePercentage < 100 && availablePercentage >= 75: // When bookings 75-99% done...
                calssName = 'yellow-high-occupancy';
                break;
              case availablePercentage === 100: // When all bookings are done...
                calssName = 'red-full';
                break;
            }
          }
        }
        return [calssName];
      }

      /* function that returs consolidated title of every slot event */
      function getTitle(slot) {
        var title = $filter('currency')(slot.adult_price, $scope.currencySymbol[slot.currency_code], CONST_HELPER.ZERO) + ' \xa0\xa0 ' + slot.available_seat + '/' + (slot.total_available_seat || slot.max_capacity) + ' available  \xa0\xa0' + slot.package_name + '';
        return title;
      }

      /* function that returs consolidated html title of every slot event */

      // eslint-disable-next-line no-unused-vars
      function getHtmlTitle(slot) {
        var htmltitle = '<span><b>' + $scope.currencySymbol[slot.currency_code] + '' + slot.adult_price + '</b><br/><p class="calendar-event-name">' + slot.package_name + '</p>' + slot.available_seat + '/' + (slot.total_available_seat || slot.max_capacity) + ' available</span>';
        return htmltitle;
      }

      function filterEventsAndGetEvents() {
        /* filter slot data according to packages selected */
        // getEvents is a custom function that fetch events...
        // $state.go('.', {locations_id: locationIds.join(),packages_id: packageIds.join()});
        $state.go('operator-calendar', {
          locations_id: locationIds.join(),
          packages_id: packageIds.join(),
          select_all: $scope.calendarFilters.all,
          unavailable: $scope.calendarFilters.expired_slot,
          empty: $scope.calendarFilters.empty_slot,
          occupancy_below74: $scope.calendarFilters.occupancy_below74,
          occupancy_above74: $scope.calendarFilters.occupancy_above74,
          occupancy_full: $scope.calendarFilters.occupancy_full,
          nightly_booking: $scope.calendarFilters.nightly_booking,
          calendar_date: moment($rootScope.currentMonthDate).format('YYYY.MM.DD')
        });
        if ($window.innerWidth > 767 || $rootScope.calendarViewType === 'basicWeek') { // if Desktop
          $scope.getEvents($scope.viewObject.start, $scope.viewObject.end, function (events, clearEvent) {
            renderEvents(events, clearEvent); // it will render all events again on view change...
          }, $scope.eventLimit);
        } else {
          $scope.selectedDate = $scope.selectedDate ? getConvertedDate($scope.selectedDate) : getConvertedDate($scope.viewObject.calendar.getDate());
          $scope.selectedDateObj = new Date($scope.selectedDate);
          renderEvents([]); // remove events on mobile view change...

          $scope.getEvents($scope.selectedDate, $scope.selectedDate, function (events) {
            $scope.mobileEvents.splice(0); // remove previous events...
            $scope.mobileEvents = events;
            $scope.ifCalendarLoading = false;
          }, $scope.eventLimit);
        }
      }

      function toggleCheckAllLocations(filteredArray) {
        var toggleStatus = $scope.location.isAllSelectedLocations;
        locationIds = [];
        filteredArray.forEach(function (itm) {
          itm.selected = toggleStatus;
          if (toggleStatus) {
            locationIds.push(itm.id);
          }
        });
      }

      function toggleCheckAllAdventures(filteredArray, toggleForLocation) {
        $timeout(function () {
        }, CONST_HELPER.ZERO);
        var toggleStatus = toggleForLocation;
        packageIds = [];
        angular.forEach(filteredArray, function (itm) {
          itm.selected = toggleStatus;
          if (toggleStatus) {
            packageIds.push(itm.package_id);
          }
        });
      }

      // select all functionality
      $scope.toggleAll = function (filteredArray) {
        toggleCheckAllAdventures(filteredArray, $scope.adventure.isAllSelected);
        filterEventsAndGetEvents();
      };

      $scope.checkAllSelected = function () {
        $scope.adventure.isAllSelected = $scope.packageList.every(function (itm) {
          return itm.selected;
        });
      };

      $scope.resetFilter = function (event) {
        event.stopPropagation();
        $scope.packageSearch = '';
        $timeout(function () {
          $('#package-filter').focus();
        });
        $scope.checkAllSelected();
      };

      $scope.optionToggled = function (obj) {
        $scope.adventure.isAllSelected = $scope.packageList.every(function (itm) {
          return itm.selected;
        });
        if (obj.selected) {
          packageIds.push(obj.package_id);
        } else {
          packageIds.splice(packageIds.indexOf(obj.package_id), 1);
        }
        filterEventsAndGetEvents();
      };

      $scope.toggleAllLocations = function (filteredArray) {
        toggleCheckAllAdventures($scope.packageList, $scope.location.isAllSelectedLocations)
        $scope.adventure.isAllSelected = $scope.location.isAllSelectedLocations;
        if ($scope.location.isAllSelectedLocations) {
          toggleCheckAllLocations(filteredArray)
        } else {
          var toggleStatus = $scope.location.isAllSelectedLocations;
          angular.forEach(filteredArray, function (itm) {
            itm.selected = toggleStatus;
          });
        }
        filterEventsAndGetEvents();
      };

      $scope.checkAllSelectedLocations = function () {
        $scope.location.isAllSelectedLocations = $scope.locationList.every(function (itm) {
          return itm.selected;
        });
      };

      $scope.resetFilterLocations = function (event) {
        event.stopPropagation();
        $scope.checkAllSelectedLocations();
      };

      $scope.optionToggledLocations = function (obj) {
        $scope.location.isAllSelectedLocations = $scope.locationList.every(function (itm) {
          return itm.selected;
        });
        var selectedLocationsIds = $stateParams.locations_id ? $stateParams.locations_id.split(',').map(Number) : [];
        $scope.packageList.forEach(function (packageObject) {
          if (packageObject.op_location_id == obj.id && packageIds.indexOf(packageObject.package_id) != -1) {
            packageIds.splice(packageIds.indexOf(packageObject.package_id), 1);
            packageObject.selected = false;
          } else if (packageObject.op_location_id == obj.id && packageIds.indexOf(packageObject.package_id) == -1) {
            packageIds.push(packageObject.package_id);
            packageObject.selected = true;
          } else if (!packageObject.op_location_id && obj.selected && obj.id == 0) {
            packageIds.push(packageObject.package_id);
            packageObject.selected = true;
          } else if (!packageObject.op_location_id && !obj.selected && obj.id == 0) {
            packageIds.splice(packageIds.indexOf(packageObject.package_id), 1);
            packageObject.selected = false;
          }
        });
        if (obj.selected) {
          locationIds.push(obj.id);
        } else {
          locationIds.splice(locationIds.indexOf(obj.id), 1);
        }
        packageIds && packageIds.length == $scope.packageList.length ? $scope.adventure.isAllSelected = true : $scope.adventure.isAllSelected = false;
        filterEventsAndGetEvents();
      };

      $scope.$watch('calendarFilters.all', function () {
        $scope.count = !isNaN($scope.count) ? ++$scope.count : 0;
      }, true);
      $scope.optionFilterToggled = function (key) {
        if (key === 'all') {
          if ($scope.calendarFilters['all']) {
            $scope.calendarFilters = {
              expired_slot: true,
              all: true,
              empty_slot: true,
              occupancy_below74: true,
              occupancy_above74: true,
              occupancy_full: true,
              nightly_booking: true
            };
          } else {
            $scope.calendarFilters = {
              expired_slot: false,
              all: false,
              empty_slot: false,
              occupancy_below74: false,
              occupancy_above74: false,
              occupancy_full: false,
              nightly_booking: false
            };
          }
        } else {
          if ($scope.calendarFilters.expired_slot && $scope.calendarFilters.empty_slot &&
            $scope.calendarFilters.occupancy_below74 && $scope.calendarFilters.occupancy_above74 &&
            $scope.calendarFilters.occupancy_full && $scope.calendarFilters.nightly_booking
          ) {
            $scope.calendarFilters.all = true;
          } else {
            $scope.calendarFilters.all = false;
          }
        }
        filterEventsAndGetEvents()
      };
      $scope.popoverClick = function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
      };

      $scope.editpackageSlots = function (packageDetails) {
        // $state.go('operatorCreateAdventure',{'package_id' : packageDetails.package_id, 'package_type' : '1', 'package_case' : '2'}).hash("duration");
        // var url = $state.href('operatorCreateAdventure',{'package_id':packageDetails.package_id,'package_type':1,'package_case':1});
        // url += "#duration"
        // window.open(url,'_blank');
      };

      // ================ Agent Changes =============

      $scope.changeOperator = function (operator) {
        $rootScope.calendarAgentOperator = operator;
      };

      $scope.changeOperatorData = function (operator) {
        $scope.showLoader();
        // $scope.adventure.isAllSelected = true;
        // get the operator active packages...
        params.page = 1;
        params.type = 0;
        params.location_id = 0;
        params.operator_id = operator.operator_profile ? operator.operator_profile.user_id : '';
        if ($rootScope.isAgent) {
          $scope.packageList = [];
          agentService.getAgentOperatorPackages(params).then(function (response) { // fetch from the service
            if (response) {
              if (response.data.code === '401') { // unauthorised user
                $scope.logout();// unauthorised user
                $state.go('signin');
                $scope.hideLoader();
              } else if (response.data.code === '404') { // no adventure found reset calendar event
                $scope.packageList = [];// add to scope
                packageIds = [];
                renderEvents([], false); // it will render all events again on view change...
                $scope.hideLoader();
              } else {
                $scope.packageList = response.data.response.docs;// add to scope
                packageIds = [];
                angular.forEach($scope.packageList, function (item) {
                  item.selected = true;
                  packageIds.push(item.package_id);
                });
                filterEventsAndGetEvents()
              }
            }
          }, function () {
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });// check for error
        }
      };

      $scope.$watch('calendarAgentOperator', function (newv, oldv) {
        if (newv == oldv) {
          return;
        }
        $scope.changeOperatorData(newv);
      });

      // function to initialize the share content data...
      $scope.initializeOperatorPackageShare = function (packageData) {
        $scope.contentOperatorPackageShare = { // initialize the content which is to be share on different sharing options
          description: packageData.description || '',
          short_url: (packageData.short_url) ? packageData.short_url : '',
          profile_pic: ($scope.user.profile_pic) ? $scope.user.profile_pic : '',
          package_name: packageData.package_name || '',
          package_id: packageData.package_id,
          location_name: packageData.location_name || '',
          duration: packageData.duration || '',
          id: packageData.package_id,
          media: packageData.medias || '',
          min_price: packageData.adult_price,
          currency_code: packageData.currency_code,
          slot: {},
          package_type: packageData.package_type || '',
          status: packageData.status ? packageData.status : ''
        };
      };
    }]).filter('unique', function () {
  // we will return a function which will take in a collection
  // and a keyname
  return function (collection, keyname) {
    // we define our output and keys array;
    var output = [];
    var keys = [];

    // we utilize angular's foreach function
    // this takes in our original collection and an iterator function
    angular.forEach(collection, function (item) {
      // we check to see whether our object exists
      var key = item[keyname];
      // if it's not already part of our keys array
      if (keys.indexOf(key) === -1) {
        // add it to our keys array
        keys.push(key);
        // push this item to our final output array
        output.push(item);
      }
    });
    // return our array which should be devoid of
    // any duplicates
    return output;
  };
});
