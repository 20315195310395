  /**
 * @ngdoc overview
 * @name heliApp
 * @description
 * # heliApp
 *
 * Constant Declaration of the application.
 */

//  angular.module("heliApp").constant("REQUEST_URL", "http://heli-qa.kiwireader.com/api/v1/")
// .constant("GOOGLE_KEY", "726840132427-bn1enbsemj7sj05qc311dgbhlvt61rsi.apps.googleusercontent.com")
// .constant("LINKEDIN_KEY", "81abcxrgp4kfgz")
// .constant("FB_KEY", {"appId":"1372016712858740","apiVersion":"v2.6"})
// .constant("STRIPE_KEY", "pk_test_xNJBPuQOiaIjPh06VV4VaPo2")
// .constant("GOOGLE_API_KEY","AIzaSyD_atMDLfEzxynR5iPmV0hXrSD3_0MIrE4")

// angular.module("heliApp").constant("REQUEST_URL", "http://heli-dev.kiwireader.com/api/v1/")
// .constant("GOOGLE_KEY", "726840132427-bn1enbsemj7sj05qc311dgbhlvt61rsi.apps.googleusercontent.com")
// .constant("LINKEDIN_KEY", "81abcxrgp4kfgz")
// .constant("FB_KEY", {"appId":"318457688551602","apiVersion":"v2.6"})
// .constant("STRIPE_KEY", "pk_test_xNJBPuQOiaIjPh06VV4VaPo2")
// .constant("GOOGLE_API_KEY","AIzaSyD_atMDLfEzxynR5iPmV0hXrSD3_0MIrE4")

  /*
angular.module("heliApp").constant("REQUEST_URL", "http://localhost/v1/")
//.constant("GOOGLE_KEY", "100533175660-mopisjsofsdq78al86tbgle5qma7fkeb.apps.googleusercontent.com")
.constant("LINKEDIN_KEY", "81abcxrgp4kfgz")
.constant("FB_KEY", {"appId":"1872679253017591","apiVersion":"v2.8"})
.constant("STRIPE_KEY", "pk_live_ElACFnH51M3GTfZXIATOyMtO")
//.constant("GOOGLE_API_KEY","AIzaSyD_atMDLfEzxynR5iPmV0hXrSD3_0MIrE4")
//.constant("GOOGLE_API_KEY","AIzaSyA1Rdmk0r2sUSkHapybt_jkrqKjPJvhVIA")
.constant("AIRPORT_KEY","")
.constant("GOOGLE_API_KEY","AIzaSyCAGrhi2wPonHsrr-51_5Ac3rl183wpiXE")
.constant("GOOGLE_KEY", "227548180488-0qkhnrbealoknhk69v6hhpi8jmal29ks.apps.googleusercontent.com");
*/
  angular.module('heliApp')
    .constant('REQUEST_URL', 'https://api.heli.life/api/v1/')
.constant("LINKEDIN_KEY", "81abcxrgp4kfgz")
.constant("FB_KEY", {"appId":"1872679253017591","apiVersion":"v2.8"})
.constant("STRIPE_KEY", "pk_live_ElACFnH51M3GTfZXIATOyMtO")
//.constant("GOOGLE_API_KEY","AIzaSyD_atMDLfEzxynR5iPmV0hXrSD3_0MIrE4")
//.constant("GOOGLE_API_KEY","AIzaSyA1Rdmk0r2sUSkHapybt_jkrqKjPJvhVIA")
//.constant("GOOGLE_API_KEY","AIzaSyCAGrhi2wPonHsrr-51_5Ac3rl183wpiXE")
//.constant("GOOGLE_API_KEY","AIzaSyCAGrhi2wPonHsrr-51_5Ac3rl183wpiXE")
.constant("GOOGLE_API_KEY","AIzaSyCcbbUs23CR4gRe6fa7Daol8qK66rYs24c")
.constant("GOOGLE_KEY", "227548180488-0qkhnrbealoknhk69v6hhpi8jmal29ks.apps.googleusercontent.com")
.constant('AIRPORT_KEY', 'b0f2ddb9famsha591d10030027e4p1c801cjsna9619216aca2')
.constant("GOOGLE_API_KEY_WEB_FONTS", "AIzaSyDJmOfFa1xHQo6gkANxycq2tgM35I05JV8")
// Flywire constants
.constant('FLYWIRE_PORTAL_CODE', 'HFE') // The 3 letter code of Flywire payment portal
.constant('FLYWIRE_ENVIRONMENT', 'prod') // Environment: 'demo' or 'prod'
.constant('FLYWIRE_SHARED_SECRET', '4fb2bfffce4d0548cb6a074f7b8ea633c2a55edd') // The 32 letter shared secret for v$
// Plaid constants
    .constant('PLAID_ENVIRONMENT', 'development')
    .constant('PLAID_PUBLIC_KEY', '04e183d46e744c6d08532f0c0fe12c')
    .constant('PLAID_SECRET', 'e94aee3fe7b847eb830bd4d7cc82cd')
    .constant('PLAID_CLIENT_ID', '5bcf5d02e188cf0013527cba')
    .constant('PLAID_WEBHOOK_URL', 'https://heli.life/')
.constant('IPAPI_KEY', 'zfVMebP7jOwXZyZZx48HbHypzyu2RG4hftitld0CGonCNfQn4o')
.constant('CURRENT_HOST', ['heli.life','www.heli.life'])
.constant('ENABLE_CREDITS', true)
;
