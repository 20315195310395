  /**
 * @ngdoc overview
 * @name heliApp
 * @description
 * # heliApp
 *
 * CONST_HELPER Declaration of the application.
 */

export const helper = {
    'INFLUENCER_COUNT_PER_PAGE': 24,
    'page_limit': 24,
    'COPY_RIGHT_YEAR': 2020,
    'NOT_FOUND': -1,
    'default_location': {
      latitude: 40.7068758,
      longitude: -74.01126479999999,
      address: 'New York Stock Exchange, New York, NY 10005, USA'
    },
    // "COPY_RIGHT_YEAR":new Date().getFullYear(),
    'twitterWidgetUrl': 'https://platform.twitter.com/widgets.js',
    'airportApiUrl': 'https://cometari-airportsfinder-v1.p.rapidapi.com/api/airports/by-radius',
    'googleMapApiUrl': 'https://maps.googleapis.com/maps/api/js?key=AIzaSyD_atMDLfEzxynR5iPmV0hXrSD3_0MIrE4&libraries=places',
    'share_on_heli': {
      'PACKAGE_SHARE': 1,
      'RADAR_SHARE': 2,
      'CART_SHARE': 3,
      'ORDER_SHARE_SUCCESS': 4,
      'OPERATOR_SELF_PACKAGE_SHARE': 5,
      'PACKAGE_SLOT_SHARE': 6
    },
    'page_name': {
      'FEED': 'feeds',
      'REFERRAL': 'referral'
    },
    'DEFAULT_HEAD_FAMILY': 'OSWALD',
    'DEFAULT_BODY_FAMILY': 'ROBOTO',
    'follow_user': {
      'REQUESTED_FOR_FOLLOW': 'requested',
      'FOLLOW_REQUEST_ACCEPTED': 'Accepted',
      'FOLLOW_REQUEST_DECLINED': 'Declined'
    },
    'analytics_filter': {
      'DATE_FORMAT': 'YYYY-MM-DD',
      'DATE_FORMAT_PAYOUT': 'YYYY/MM/DD',
      'DATE_FORMAT_DISPLAY': 'MMM D, YYYY'
    },
    'ambassador_referral': {
      'INVITE': 7,
      'PACKAGE': 8
    },
    'payment_const': {
      'full_payment': 1,
      'partial_payment': 2,
      'is_partial': 1,
      'is_full': 0,
      'default_currency': 'USD'
    },
    'PACKAGE_TYPE': {
      'PUBLIC': 1,
      'PRIVATE': 2
    },
    'PACKAGE_CASE': {
      'PER_SEAT': 1,
      'PER_ACCOMMODATION': 2,
      'PER_ENTIRE_PACKAGE': 3
    },
    'PACKAGE_CASE_NAME': {
      1: 'Seat Pricing',
      2: 'Accommodation Pricing',
      3: 'Flat Rate Pricing'
    },
    'EVENT_LODGING': {
      'YES': 1,
      'NO': 0
    },
    'USER_ROLE': {
      'CONSUMER': 1,
      'AMBASSADOR': 2,
      'OPERATOR': 3,
      'INFLUENCER': 4
    },
    'ON_HELI': 1,
    'OPERATOR_SITE': 2,
    'MANUAL': 3,
    'MANUAL_PAID': 4,
    'TEXTLIMIT': 1000,
    'SIXTY': 60,
    'TWENTYFOUR': 24,
    'TWENTYSIXTEEN': 2016,
    'SCROLL_FILTER_LIMIT': 5,
    'CALENDAR_EVENT_LIMIT': 5,
    'SCREEN': {
      'DESKTOP_WIDTH': 1280,
      'PACKAGE_NAME_LIMIT': 100,
      'MOBILE_WIDTH': 600,
      'SCROLL_HEIGHT': 200,
      'ACTIVITY_OFFSET': 575,
      'MOBILE': 568
    },
    'GUEST_TYPE': {
      'HELI_USER': 0,
      'OFFLINE': 1,
      'MANUAL_USER': 2
    },
    'EDITOR_CONFIG': {
      'toolbarGroups': [
        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
        { name: 'links', groups: [ 'links' ] }
      ],
      'removeButtons': 'Subscript,Superscript,Paste,PasteText,PasteFromWord,Copy,Cut,Styles,Font,Strike,RemoveFormat,Blockquote',
      'removePlugins': 'magicline,elementspath'
    },
    'DEFAULT_LATITUDE': 40.7068758,
    'DEFAULT_LONGITUDE': -74.01126479999999,
    'DEFAULT_LOCATION_NAME': 'New York Stock Exchange, New York, NY 10005, USA',
    'DEFAULT_ZOOM': 14,
    'API_RESPONSE': {
      'OK': '200',
      'CREATED': '201',
      'NO_CONTENT': '204',
      'UNAUTHERIZED': '401',
      'NOT_FOUND': '404',
      'UNPROCESSABLE': '422',
      'BAD_REQUEST': '400',
      'NO_DATA': '204',
      'NON_AUTHORITATIVE': '203',
      'MOVED_PERMANENTLY': '301'
    },
    'LOCATION': {
      'PRE_DEFINED': 1,
      'CUSTOM': 0
    },
    'MEDIA': {
      'VIDEO': 1,
      'IMAGE': 0,
      'NORMAL_VIDEO_URL': 5,
      'FEATURED_IMAGE': 1,
      'MAX_SIZE': 5000000, // IN BYTES
      'MIN_SIZE': 50000
    },
    'DEPOSIT_TYPE': {
      'PERCENTAGE': '1',
      'AMOUNT': '2'
    },
    'SEARCH_TIMEOUT': 300,
    'LOGO_MAX_SIZE': 2000000,
    'ZERO': 0,
    'MINUS_ONE': -1,
    'ONE': 1,
    'TWO': 2,
    'THREE': 3,
    'FOUR': 4,
    'SIX': 6,
    'FIVE': 5,
    'SEVEN': 7,
    'NINE': 9,
    'TEN': 10,
    'TWELVE': 12,
    'HUNDRED': 100,
    'TWOHUNDRED': 200,
    'FIVETHOUSAND': 5000,
    'KEYCODE': 110,
    'TEXTCHARLIMIT': 85,
    'EVENTLIMIT': 25,
    'LIST': {
      'SHOWN': 1,
      'SIZE': 12,
      'LIMIT': 24
    },
    'PACKAGE': {
      'MAX_GALLERY_IMAGES': 30,
      'MIN_SLOT_DAYS': 1,
      'MAX_SLOT_DAYS': 51,
      'MIN_CANCELLATION_DAYS': 1,
      'MAX_LODGING_IMAGES': 5,
      'DEACTIVATED': 2 // PACKAGE STATUS
    },
    is_save_exit: {
      'profile': 3,
      'gallery': 4,
      'policies': 5,
      'location': 6
    },
    'PROCESS_FEE': {
      'Ffixed': 0.30,
      'Fpercent': 0.035
    },
    'BOOKING_TYPE': {
      'PACKAGE_SLOTS': 1,
      'NIGHTLY_SLOTS': 2,
      'DAILY_RATE': 3,
      'SINGLE_DAY': 4
    },
    'BOOKING_TYPE_NAME': {
      1: 'Multiday package',
      2: 'Nightly rate',
      3: 'Daily rate',
      4: 'Single day',
    },
    'DISCOUNT_TYPE': {
      'IN_CURRENCY': 1,
      'IN_PERCENTAGE': 2
    },
    'QUESTOIN_TYPE': {
      'short_text': 1,
      'long_text': 2,
      'statement': 3,
      'multiple_choice': 4,
      'dropdown': 5
    },
    'QUESTION_ID_TEXT': {
      1: 'short_text',
      2: 'long_text',
      3: 'statement',
      4: 'multiple_choice',
      5: 'dropdown'
    },
    'AGENT_REQUEST_STATUS': {
      'ACCEPT': 1,
      'DEACTIVATE': 2,
      'REJECT': 3,
      'PENDING': 4,
      'INVITE': 5,
      'CANCEL': 6
    },
    'ROLLBAR_TOKEN': 'be3614eccc364341a060e53bcee48466',
    'FEATURED_ACTIVITY': [
      {
        'id': 1,
        'slug': 'ski',
        'title': 'Ski & Ride Adventures',
        'description': 'Heli curates the best packages for Heli Skiing, Cat Skiing, Ski Touring and more. From Alaska to British Columbia, we are your destination for ski and ride packages. No mountain range is too remote.',
        'image_url': 'https://s3.amazonaws.com/heli-staging/category_images/Ski%2BRide.jpg',
        'custom_trip_image' : 'https://heli-staging.s3.amazonaws.com/images/sport_categories/ski-custom-trip-image.jpeg'
      },
      {
        'id': 2,
        'slug': 'Kite',
        'title': 'Kite Trips & Lessons',
        'description': `Kite trips, downwinders, lodges and boat based kite surfing packages from around the world. We've hand picked the best kiteboarding and kitesurfing experiences.`,
        'image_url': 'https://s3.amazonaws.com/heli-staging/category_images/Kite.jpg',
        'custom_trip_image' : 'https://heli-staging.s3.amazonaws.com/images/sport_categories/kite-custom-trip-image.jpeg'
      },
      {
        'id': 3,
        'slug': 'Surf',
        'title': 'Surf Vacations & Camps',
        'description': 'Surf hotels right by a break, boat based multiday trips and eco lodges in the most remote corners of the globe. Find the perfect surf trip for you and your crew.',
        'image_url': 'https://s3.amazonaws.com/heli-staging/category_images/Surf.jpg',
        'custom_trip_image' : 'https://heli-staging.s3.amazonaws.com/images/sport_categories/surf-custom-trip-image.jpeg'
      },
      {
        'id': 4,
        'slug': 'Fish',
        'title': 'Fishing Trips & Lodges',
        'description': 'From the wildest and most remote rivers, with luxury lodges, charters and guides ready to take you there. We offer a wide selection of fly fishing guides and fishing lodges from the remote backcountry of Alaska to the mangroves in the Caribbean. Find the perfect fishing escape.',
        'image_url': 'https://heli-staging.s3.amazonaws.com/images/destinations/Fly-Fishing.jpg',
        'custom_trip_image' : 'https://heli-staging.s3.amazonaws.com/images/sport_categories/fish-custom-trip-image.jpeg'
      },
      {
        'id': 6,
        'slug': 'Bike',
        'title': 'Bike Tours & Trips',
        'description': `Bike tours from around the globe, whether you are looking for a multi stage road ride or bermy, flowy downhill with gravity assistance, we've got it. We'll help you find the perfect mountain bike vacation in Central America, guided gravel bike trip on the west coast, road bike tour in Europe, or Heli biking experience in British Columbia.`,
        'image_url': 'https://heli-staging.s3.amazonaws.com/category_images/Mountain-Biking.jpg',
        'custom_trip_image' : 'https://heli-staging.s3.amazonaws.com/category_images/Bike.jpg'
      },
      {
        'id': 5,
        'slug': 'Dive',
        'title': 'Dive Resorts & Packages',
        'description': `Dive resorts and packages from every ocean and reef. We've picked the best dive retreats from around the globe. From Scuba and open ocean diving off the coast of South Africa to freediving with Orcas and snorkeling in Raja Ampat, we've got the perfect diving vacation.`,
        'image_url': 'https://heli-staging.s3.amazonaws.com/images/sport_categories/dive-custom-trip-image.jpeg',
        'custom_trip_image' : 'https://s3.amazonaws.com/heli-staging/category_images/Dive.jpg'
      },
      {
        'id': 9,
        'slug': 'Safari',
        'title': 'Safaris & Expeditions',
        'description': `Wildlife and wild landscapes are the focus here in our Safaris and Expeditions. From horseback riding, to artic yachts, African game drives, and everything in between. We've searched the globe for the most interesting luxury safaris and remote expeditions. Grab your camera and high powered lens. You're going to need it.`,
        'image_url': 'https://s3.amazonaws.com/heli-staging/category_images/Safari.jpg',
        'custom_trip_image' : 'https://heli-staging.s3.amazonaws.com/images/sport_categories/safari-custom-trip-image.jpeg'
      }
    ],
    'STRIPE': {
      'ACCOUNT_VERIFICATION_STATUS': {
        'INITIALIZED': 0,
        'PENDING': 1,
        'VERIFIED': 2,
        'REJECTED': 3
      },
      // https://stripe.com/docs/api/external_account_bank_accounts/object#account_bank_account_object-status
      'BANK_ACCOUNT_VERIFICATION_STATUS': {
        'NEW': 'new',
        'VALIDATED': 'validated',
        'VERIFIED': 'verified',
        'VERIFICATION_FAILED': 'verification_failed',
        'ERRORED': 'errored'
      }
    },
    OPERATOR_TAXES: {
      TYPE: {
        INTERNATIONAL: {
          ID: 1,
          NAME: 'international'
        },
        DOMESTIC: {
          ID: 2,
          NAME: 'domestic'
        }
      },
      CALCULATION_TYPE: {
        PERCENTAGE: {
          ID: 1,
          NAME: 'percentage'
        },
        AMOUNT: {
          ID: 2,
          NAME: 'amount'
        }
      }
    }
  };
