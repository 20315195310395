/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:operatorManualPaymentCtrl
 * @description
 * # operatorManualPaymentCtrl for opeartor manual payment.
 * Controller of the heliApp
 */
angular.module('heliApp')
  .controller('operatorManualPaymentCtrl', ['$scope', '$rootScope', '$stateParams', '$state', '$timeout', '$location', 'operatorCalendarService', '$window', '$filter', 'operatorPackageListService', 'userAdventureDetailService', 'CONST_HELPER', '$cookies', 'STRIPE_KEY', 'userOrdersListService', 'userSocialProfileService', 'operatorProfileService', 'operatorManualPaymentService', 'binaryService',
    function(
      $scope,
      $rootScope,
      $stateParams,
      $state,
      $timeout,
      $location,
      operatorCalendarService,
      $window,
      $filter,
      operatorPackageListService,
      userAdventureDetailService,
      CONST_HELPER,
      $cookies,
      STRIPE_KEY,
      userOrdersListService,
      userSocialProfileService,
      operatorProfileService,
      operatorManualPaymentService,
      binaryService) {
      // var vm = this
      $('#widget').draggable();
      $scope.screenWidth = screen.width;
      $scope.homeRedirection(); // check for authorised access
      var token = $scope.user.token;
      $scope.textLimit = CONST_HELPER.TEXTCHARLIMIT;
      $scope.operatorManualBooking = false;
      $scope.manualBooking = {};
      $scope.paymentList = {};
      $scope.paymentList.currentPage = CONST_HELPER.ONE;
      $scope.keyword = $state.params.keyword ? $state.params.keyword : '';
      $scope.manualGuest = {};
      $scope.suggestedRetailPrice = CONST_HELPER.ZERO;
      // set default guest and currency in case of no pakcage selection...
      $scope.manualBooking.local_currency_code = CONST_HELPER.payment_const.default_currency;
      $scope.manualGuest.total_guest = CONST_HELPER.ZERO;
      $scope.manualBooking.subTotal = CONST_HELPER.ZERO;
      $scope.addguestFormIsNotValid = false;
      $scope.selectedGuestError = false;
      $scope.groupFormValid = false;
      $scope.deleteManualGuestObj = {};
      $scope.emailPattern = /^([a-zA-Z0-9_.-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
      $scope.groupHasGuest = false;
      $scope.depositOptions = [{
        'id': '0',
        'value': 'Full Payment'
      }, {
        'id': '1',
        'value': 'Deposit'
      }]; // Add Guest Popup Deposit options
      $scope.sortBy = 'order_id';
      $scope.sortOrder = 'DESC';
      /**
       * @ngdoc method
       * @name getOperatorProfileTaxPercent
       *
       * @description
       * This method call API to get the tax percentage of the operator
       **/

      $scope.getOperatorProfileTaxPercent = function() { // fetch prefilled profile of the operator
        $scope.showLoader();
        $scope.homeRedirection(); // check for authorised access
        operatorProfileService.getOperatorProfile($scope.user.token).then(function(response) {
          $scope.hideLoader();
          if (response.data) {
            var code = response.data.code;
            if (code !== '200') { // if error return
              if (code === '401') { // unauthorised user
                $scope.logout();
              }
            } else { // valid results
              $scope.operatorPolicy = response.data.data.operator_policy; // set profile data for policy
            }
          }
        }, function() {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };

      $scope.changeOrderDirection = function(sortBy, orderDirection) {
        if ( sortBy == $scope.sortBy ) {
          (orderDirection == 'asc') ?  $scope.sortOrder = 'desc' : $scope.sortOrder = 'asc';
        } else {
          $scope.sortOrder = 'desc'
        }
        $scope.sortBy = sortBy;
        $scope.getManualPaymentList();
      }

      /**
       * @ngdoc method
       * @name getManualPaymentList
       *
       * @description
       * This method call to get the all payment list of the operator
       **/

      $scope.getManualPaymentList = function(type) { // get all view booking detail
        $scope.homeRedirection(); // check for authorised access
        if ((!$scope.user) || ($scope.user && !$scope.user.token)) { // check for user logged in status
          $state.go('signin');
          return false;
        }
        getCurrencyList(); // call to get currencies list
        $scope.showLoader();

        if (type === 'firstLoad') {
          $scope.noManualPaymetList = false; // Setting false for the booking source load
          $scope.paymentList.currentPage = $state.params.page ? $state.params.page : 1;

        }

        var requestObject = {
          token: $scope.user.token,
          keyword: $scope.keyword ? $scope.keyword : '',
          page: $scope.paymentList.currentPage ? $scope.paymentList.currentPage : $state.params.page,
          order_by: $scope.sortBy ? $scope.sortBy : 'order_id',
          order_direction: $scope.sortOrder ? $scope.sortOrder : 'desc'
        };

        operatorManualPaymentService.getManualPaymentList(requestObject).then(function(response) { // fetch from the service
          if (response.data.code === '200') {
            $scope.allManualPaymentList = response.data.data;
            $scope.getCurrencySymbol();
            $scope.totalViewItems = response.data.numFound; // set pagination count
            $scope.hideLoader();
          } else if (response.data.code === '404') {
            $scope.noManualPaymetList = true;
            $scope.allManualPaymentList = '';
            $scope.totalViewItems = ''; // set pagination count
            $scope.hideLoader();
          } else if (response.data.code === '401') {
            $scope.logout();
          }
        }, function() { // check for error
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };

      /**
       * @ngdoc method
       * @name showPaymentDetails
       *
       * @description
       * This method to show the selected payment details
       **/

      $scope.showPaymentDetails = function(payemtnDetails) {
        $scope.currentSelectedPayment = payemtnDetails;
      };

      /**
       * @ngdoc method
       * @name pageChange
       *
       * @description
       * This method for the pagination of the payment list view
       **/

      $scope.pageChange = function(page, type) { // set pagination
        if (type === 'viewPaymentList') {
          $state.go('manual-payment-list', ({
            page: page,
            keyword: $scope.keyword,
            order_by: $scope.sortBy ? $scope.sortBy : 'order_id',
            order_direction: $scope.sortOrder ? $scope.sortOrder : 'desc'
          }));
        }
      };

      /**
       * @ngdoc method
       * @name filterPaymentList
       *
       * @description
       * This method for the search on list using keywords
       **/

      $scope.filterPaymentList = function(paymentSearchForm) {
        if (!paymentSearchForm.$valid || $scope.keyword === '') {
          return;
        }

        // var count = 0;
        var requestObject = {
          token: $scope.user.token,
          keyword: $scope.keyword,
          page: 1,
          order_by: $scope.sortBy ? $scope.sortBy : 'order_id',
          order_direction: $scope.sortOrder ? $scope.sortOrder : 'desc'
        };

        operatorManualPaymentService.getManualPaymentList(requestObject).then(function(response) { // fetch from the service
          if (response.data.code === '200') {
            $scope.allManualPaymentList = response.data.data;
            $scope.totalViewItems = response.data.numFound; // set pagination count
            $scope.getCurrencySymbol();
            $scope.pageChange(1, 'viewPaymentList');
            $scope.hideLoader();
          } else if (response.data.code === '404') {
            $scope.noManualPaymetList = true;
            $scope.allManualPaymentList = '';
            $scope.totalViewItems = ''; // set pagination count
            $scope.hideLoader();
          } else if (response.data.code === '401') {
            $scope.logout();
          }
        }, function() { // check for error
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };

      /**
       * @ngdoc method
       * @name clearSearch
       *
       * @description
       * This method is to clear the search and fetch list.
       **/

      $scope.clearSearch = function() {
        $scope.keyword = '';
        $scope.pageChange(1, 'viewPaymentList'); // reset to page one on search clear
      };

      /**
       * @ngdoc method
       * @name getCurrencySymbol
       *
       * @description
       * This method is to get the symbol regarding the currency on payment list view.
       **/

      $scope.getCurrencySymbol = function() {
        $scope.allManualPaymentList.forEach(function(paymentRecord) {
          $scope.Currencies.filter(function(currency) {
            if (paymentRecord.currency_id === currency.id) {
              paymentRecord.currency_code = currency.code;
            }
          });
        });
      };

      $('.slot-start-date').datepicker({
        minDate: new Date()
      });

      // Refresh selectpicker values on dynamic change
      $scope.refreshSelectpicker = function() {
        $timeout(function() {
          $('.selectpicker').selectpicker('refresh');
        }, 0);
      };

      function getCurrencyList(argument) {
        // get total currency list...
        userAdventureDetailService.getCurrency().then(function(res) {
          if (res.data.code === '200') {
            $scope.Currencies = res.data.data;
            $scope.Currencies.map(function(val) {
              val.symbol = ' (' + $scope.currencySymbol[val.code] + ')';
            });
          }
        }, function() {

        });
      }

      // if operator is doing mannual bookings...
      if ($rootScope.currentState.name === 'operator-manual-booking' || $rootScope.currentState.name === 'operator-manual-booking-noslot' || $rootScope.currentState.name === 'manual-payment' || $rootScope.currentState.name === 'manual-payment-list') {
        getCurrencyList();
        $scope.operatorManualBooking = true;

        // load stripe libraries...
        if (typeof Stripe === 'undefined') {
          $scope.loadScript('https://js.stripe.com/v2/', function() {
            $scope.loadScript('https://js.stripe.com/v3/', function() {
              window.Stripe.setPublishableKey(STRIPE_KEY);
              Stripe(STRIPE_KEY);
              var existingModule = angular.module('heliApp');
              existingModule.requires.push('angular-stripe');
              $rootScope.$broadcast('stripe-loaded');
            });
          });
        }
      }

      // function to take action  on slot selection changed...
      $scope.changeSelectedSlot = function() {
        if ($stateParams.slot_id !== $scope.manualBooking.selectedSlot.id) {
          $state.go('operator-manual-booking', {
            'slot_id': $scope.manualBooking.selectedSlot.id
          });
        }
      };

      // selectAddons ...
      $scope.selecteAddons = function() {
        CalculateSuggestedRetailPrice();
        $scope.refreshSelectpicker();
      };
      $scope.addonGuestChange = function() {
        CalculateSuggestedRetailPrice();
      };

      $scope.parseValue = function(price, event) {
        if (event.key !== '.') {
          $scope.manualGuest.price = parseFloat($scope.manualGuest.price);
        }
      };

      // set is_private key with lodging in case of private price changes
      $scope.setIsprivateKey = function(lodging) {
        lodging.is_private = true;
      };

      $scope.numberKeyDown = function(e) {
        if ([69, 187, 188, 189, 190].includes(e.keyCode)) {
          e.preventDefault();
        }
      };

      // update lodging available seats...
      $scope.updateTotalBedsSeats = function(lodge, type) {
        if (type === 'manual') {
          // eslint-disable-next-line no-self-assign
          lodge.total_beds = lodge.total_beds;
          if (lodge.total_beds > (lodge.available_units ? lodge.available_units : 0) * lodge.lodging.max_guests) {
            lodge.total_beds = (lodge.available_units ? lodge.available_units : 0) * lodge.lodging.max_guests;
          }
        } else {
          lodge.total_beds = (lodge.available_units ? lodge.available_units : 0) * lodge.lodging.max_guests;
        }

        getTotalBedsAndRoom();
        $scope.checkAvailableSeats();
      };

      function getTotalBedsAndRoom() {
        // update the available seats...
        $scope.total_beds = 0;
        $scope.total_available_rooms = 0;
        $scope.slotDetail.slot_lodgings.map(function(val) {
          $scope.total_beds += parseInt(val.total_beds || 0);
          $scope.total_available_rooms += parseInt(val.available_units);
        });
      }

      // check if available seats is less than the total beds
      $scope.validation = {};
      $scope.checkAvailableSeats = function() {
        $scope.total_beds = $scope.total_beds || 0;
        if ($scope.slotDetail.available_seat > $scope.total_beds) {
          $scope.maxAvailableSeatErr = true;
        } else {
          $scope.maxAvailableSeatErr = false;
        }
        if ($scope.total_available_rooms) {
          $scope.validation.available_rooms = false;
        }
      };

      // Drag and drop Guest functionality
      $scope.sortableOptions = {
        placeholder: '.drag',
        connectWith: '.drag-container',
        update: callbackForDragAndDrop,
        axis: 'y'
      };

      function callbackForDragAndDrop(e, ui) {
        if (!ui.item.sortable.received || ui.item.sortable.source[0] === ui.item.sortable.droptarget[0]) {
          ui.item.sortable.cancel();
        } else {
          ui.item.sortable.droptargetModel = ui.item.sortable.droptargetModel || [];
          $scope.model = ui.item.sortable.model;
          $scope.source = ui.item.sortable.sourceModel;
          $scope.target = ui.item.sortable.droptargetModel;
          $scope.groupId = ui.item.sortable.droptargetList['0'].id;
          $scope.guestId = ui.item.sortable.model.id.toString();
          var groupObj = {
            'guest_id': $scope.guestId
          };
          if ($scope.groupId) {
            groupObj['group_id'] = $scope.groupId;
            addGuest(operatorCalendarService.addGuestToGroup, groupObj);
          } else {
            addGuest(operatorCalendarService.deleteGuestFromGroup, groupObj);
          }
        }
      }

      function addGuest(fn, groupObj) {
        $scope.showLoader();
        fn.apply(this, [groupObj, token]).then(function(res) {
          if (res.data.code === '200') {
            $scope.hideLoader();
            // getSlotData();

            changeGuestGroupUI();
            checkCollapse();
          } else if (res.data.code === '203') {
            $scope.logout();
            $scope.hideLoader();
          }
          resetDragAndDropData();
        }, function(error) {
          console.log(error);
        });
      }
      // Move guest div from source group to destination
      function changeGuestGroupUI() {
        $scope.target.push($scope.model);
        $scope.source.splice($scope.source.indexOf($scope.model), 1);
      }
      // open collapse group on dragging
      function checkCollapse() {
        var self = $('#' + $scope.groupId);
        var $nextEle = self.children('.mb-footer-inner');
        if ($nextEle.length === 0) {
          return;
        }

        if (!($nextEle.is(':visible'))) {
          $nextEle.slideDown('slow');

          self.find('.plus-icon').removeClass('plus-icon');
          self.find('div').addClass('minus-icon');
        }
      }
      // End Drag and drop Guest functionality

      //* ****** manual booking operator ******//

      // step1 : create a guest user if not exist and add slot in cart.
      // step2 : proceed to checkout just as it is happening for normal users...
      // step3 : proeed to payment without any saved cards...

      $rootScope.CartDetails = [];
      $scope.userWantToProceed = false;
      $scope.canEditGuestDetails = true;
      $scope.finalOperatorAmount = CONST_HELPER.ZERO;
      $scope.operatorTotalCommission = CONST_HELPER.ZERO;

      // watch the booking price and recalculate the taxes and processing fee...
      $scope.$watch('manualGuest.price', function(newTotal, oldTotal) {
        $scope.manualGuest.price = $scope.manualGuest.price ? parseFloat($scope.manualGuest.price) : '';
        if (newTotal !== oldTotal) {
          calculateTax();
        }
      });

      // calculate suggestedRetailPrice total
      function CalculateSuggestedRetailPrice() {
        $scope.suggestedRetailPrice = CONST_HELPER.ZERO;
        if ($scope.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) {
          // case C...
          $scope.suggestedRetailPrice = $scope.suggestedRetailPrice + $scope.selectedSlotDetails.adult_price;
        } else if ($scope.selectedSlotDetails.package.lodging_status === CONST_HELPER.EVENT_LODGING.NO || !$scope.selectedSlotDetails.slot_lodgings.length) {
          // case A without lodging...
          $scope.suggestedRetailPrice = $scope.suggestedRetailPrice + parseInt($scope.manualGuest.total_guest) * $scope.selectedSlotDetails.adult_price;
        } else {
          // case B && case A with lodging...
          $scope.selectedSlotDetails.slot_lodgings.forEach(function(lodging) {
            if (parseInt(lodging.selected_guests) > 0) {
              $scope.suggestedRetailPrice = $scope.suggestedRetailPrice + (lodging.selected_guests ? parseInt(lodging.selected_guests) : 1) * lodging.price;
            }
          });
        }

        if ($scope.selectedSlotDetails.package.package_addons && $scope.selectedSlotDetails.package.package_addons.length) {
          $scope.selectedSlotDetails.package.package_addons.map(function(addon, index) {
            if (addon.isSelected) {
              $scope.suggestedRetailPrice = $scope.suggestedRetailPrice + (addon.addonAdults ? addon.addonAdults : CONST_HELPER.ZERO) * addon.adult_price;
            }
          });
        }
      }

      // calculate the Tax amount ...
      function calculateTax() {
        var taxPercent = $scope.operatorPolicy ? $scope.operatorPolicy.domestic_percent : CONST_HELPER.ZERO;
        $scope.manualBooking.taxes = toFixed(($scope.manualGuest.price * taxPercent / CONST_HELPER.HUNDRED), 2);
        $scope.manualBooking.processingFee = $scope.manualGuest.price > CONST_HELPER.ZERO ? calculateProcessingFee('total') : CONST_HELPER.ZERO;
        $scope.manualBooking.grandTotal = $scope.manualGuest.price + $scope.manualBooking.taxes + $scope.manualBooking.processingFee;
      }

      // calculate processing fee
      function calculateProcessingFee(argument) {
        var processingFee = CONST_HELPER.ZERO;
        var total;
        var price = argument === 'total' ? $scope.manualGuest.price : $scope.manualBooking.subTotal;
        var taxes = argument === 'total' ? $scope.manualBooking.taxes : $scope.manualBooking.taxes;

        total = (price + taxes + CONST_HELPER.PROCESS_FEE.Ffixed) / (CONST_HELPER.ONE - CONST_HELPER.PROCESS_FEE.Fpercent);
        processingFee = total - (price + taxes);
        return toFixed(processingFee, 2);
      }

      // save the bookings into cart and proceed to checkout...
      $scope.addManualPayment = function(addguestForm) {
        $scope.selectedGuestError = false;
        if (!addguestForm.$valid || $scope.manualGuest.price <= 0) {
          $scope.addguestFormIsNotValid = true;
          return;
        }
        if ($scope.manualGuest.price > ($scope.suggestedRetailPrice * $scope.packagePrevCurrencyRate * $scope.packageCurrencyCodeRate)) {
          return;
        }

        var selectedCurrency = $scope.Currencies.filter(function(item) {
          if ($scope.manualBooking.local_currency_code === item.code) {
            return item.id;
          }
        });

        var data = {
          name: $scope.manualGuest.name,
          email: $scope.manualGuest.email,
          notes: $scope.manualGuest.notes,
          amount: $scope.manualBooking.grandTotal,
          currency_id: selectedCurrency[0].id,
          description: $scope.manualGuest.paydescription
        };
        var token = $scope.user.token ? $scope.user.token : '';
        if ($scope.currentguestId) {
          data.id = $scope.currentguestId;
        }
        $scope.showLoader();
        operatorManualPaymentService.saveOperatorGuestDetails(data, token).then(function(res) {
          $scope.hideLoader();
          if (res.data && res.data.message === 'Success') {
            $scope.canEditGuestDetails = false;
            $scope.currentguestId = res.data && res.data.data ? res.data.data.id : '';
            $rootScope.$broadcast('initialize-payment', res.data.data.id);
          }
        });
      };

      // get the usd rate against to user selected currency.
      $scope.convertCurrency = function(localCurrencyCode, packageCurrencyCode) {
        $scope.showLoader();
        userAdventureDetailService.convertCurrency(1, packageCurrencyCode, localCurrencyCode).then(function(res) {
          $scope.hideLoader();
          $scope.packageCurrencyCodeRate = res.data.usd_rate;
          if ($scope.order_id) {
            return;
          }
          $scope.manualBooking.subTotal = toFixed($scope.manualBooking.subTotal * $scope.packageCurrencyCodeRate);
          $scope.manualGuest.price = toFixed($scope.manualGuest.price * $scope.packageCurrencyCodeRate, 2);
          $scope.manualGuest.deposit_price = $scope.manualGuest.is_deposit === '1' && $scope.manualGuest.deposit_price ? $scope.manualBooking.subTotal : '';
        });
      };

      function setUpPaymentObjects(argument) {
        $scope.userWantToProceed = true;
        $scope.canEditGuestDetails = false;
        $scope.hasSingleOperator = CONST_HELPER.ONE;
        $scope.operatorTotalCommission = $scope.manualBooking.subTotal > 0 ? $scope.manualBooking.subTotal * $scope.selectedSlotDetails.commission / CONST_HELPER.HUNDRED : 0;
        $scope.finalOperatorAmount = $scope.manualBooking.grandTotal - $scope.manualBooking.processingFee - $scope.operatorTotalCommission;
        $scope.GrandTotal = $scope.manualBooking.grandTotal;
      }

      // return number value with round off value...
      function toFixed(num, len) {
        return Number(num.toFixed(len));
      }

      // enable all fields so operator can edit guest details...
      $scope.enableGuestDetails = function() {
        $scope.canEditGuestDetails = true;
      };

      // FUCNTION TO PROCEED THE REMAINING MANUAL PAYMENT;
      $scope.proceedToRemainingPayment = function(argument) {
        setUpPaymentObjects();
      };

      $scope.exportCsv = function () {
        $scope.showLoader();
        operatorManualPaymentService
          .getList($scope.user.token, {
            order_by: $scope.sortBy,
            order_direction: $scope.sortOrder
          }, 'csv')
          .then(function (response) {
            $scope.hideLoader();
            binaryService.downloadFileFromBlob({
              file: {
                name: binaryService.getFilenameFromResponse(response)
              },
              blob: {
                parts: response.data,
                options: { type: response.headers.get('Content-Type') }
              }
            });
          }, function () {
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
      };
    }]);
