import { Component, Input, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';

@Component({
  selector: 'app-adventures-slider',
  templateUrl: '/ng-app/src/app/components/home/adventures-slider/adventures-slider.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/home/adventures-slider/adventures-slider.component.css']
})
export class AdventuresSliderComponent implements OnInit {

  @Input() adventuresList = [];
  @Input() featuredAmbassadors = [];

  slickOptions = {
    autoplay: true,
    slidesToShow: 1,
    centerMode: true,
    centerPadding: '300px',
    infinite: true,
    accessibility: true,
    adaptiveHeight: false,
    arrows: true,
    dots: false,
    draggable: true,
    edgeFriction: 0.30,
    swipe: true,
    prevArrow: '<div class="adventures_container--slick-arrow adventures_container--slick-arrow-left"></div>',
    nextArrow: '<div class="adventures_container--slick-arrow adventures_container--slick-arrow-right"></div>',
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          centerPadding: '120px',
        }
      },
    ]
  }

  isAmbassadorActive: boolean;

  constructor(public router: UIRouter) { }

  ngOnInit(): void {
  }

  replaceSymbols(str) { 
    return str.replace(/\W+(?!$)/g, '-').toLowerCase();
  }

  featuredClick = (active) => {
    this.isAmbassadorActive = active;
  };

  setlocationlen = (locationName, isradar) => {
    if (isradar === 'filter') {
      let location = locationName.split(',');
      return location[location.length - 1];
    }
    let len = locationName.length;
    let canLen = isradar === 'radar' ? 30 : 50;
    if (len >= canLen) {
      return locationName.slice(0, canLen - 3) + '...';
    } else {
      return locationName;
    }
  };
  
}
