/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:expiredSlotsController
 * @description
 * # expiredSlotsController
 * Controller of the heliApp
 */

angular.module('heliApp').controller('expiredSlotsController',['operatorProfileService', '$stateParams', '$scope', '$timeout', '$rootScope', function (operatorProfileService, $stateParams, $scope, $timeout, $rootScope) {
  $scope.showLoader();
  $timeout(function () {
    $rootScope.isUserLoggedIn();// check id user is logged in
    var data = {
      id: $stateParams.package_id,
      token: $scope.user.token
    };
    operatorProfileService.getPackageExpiredSlots(data).then(function (res) {
      $scope.packageExpiredSlots = res.data.package[0].package_slots;
      $scope.hideLoader();
    }, function () {
      $scope.hideLoader();
    });
  }, 1000);
}]);
