import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name QperatorProfileService
 * @description
 * # operatorProfileService
 * Service in the heliApp.
 */

@Injectable()

export class OperatorProfileService {

  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  sendReq(method) {
    return new Promise((resolve, reject) => {
      method.then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    }) /* asynchronously complete request */
  }

  verifyAddressReq(data) { // check for address verification
    var req = {
      method: 'POST',
      url: this.REQUEST_URL + 'taxes/verifyAddress',
      data: data.id,
      headers: {
        'token': data.token
      }
    };
    return this.webService.httpReq(req);
  }
  getStatesReq(value) {
    var req = {
      method: 'GET',
      url: this.REQUEST_URL + 'masters/state?country_id=' + value
    };
    return this.webService.httpReq(req);
  }
  getOperatorProfile(data) { // get method for operator profile
    return this.sendReq(this.webService.getOperatorProfile(data));
  }
  getOperatorProfileById(data) { // get method for operator profile
    return this.sendReq(this.webService.getOperatorProfileById(data));
  }
  checkValidLocation(name, data) { // get method for operator profile
    return this.sendReq(this.webService.checkValidLocation(name, data));
  }
  getTimeZones() { // get method for world timezones
    return this.sendReq(this.webService.getTimeZones());
  }
  saveOperatorProfile(data, file) { // save operator profile
    return this.sendReq(this.webService.saveOperatorProfile(data, file));
  }
  saveOperatorLocation(data, file) { // save operator profile
    return this.sendReq(this.webService.saveOperatorLocation(data, file));
  }
  saveLocationLodging(data, file) {
    return this.sendReq(this.webService.saveLocationLodging(data, file));
  }
  checkDeleteLodging(data) {
    return this.sendReq(this.webService.checkDeleteLodging(data));
  }
  getAdventureCount(token) { // get operator package count
    return this.sendReq(this.webService.getAdventureCount(token));
  }
  checkEmailExistence(email, token) { // check for email existence
    return this.sendReq(this.webService.checkEmailExistence(email, token));
  }
  verifyAddress(data) { // check for valid tax credentials with address provided
    return this.sendReq(this.verifyAddressReq(data));
  }
  getStates(data) { // get states depending on country selectes
    return this.sendReq(this.getStatesReq(data));
  }
  getOperatorForms(token) { // service to get all available sports
    return this.sendReq(this.webService.getOperatorForms(token));
  }
  getOperatorResponse(filter, token) { // service to get all available sports
    return this.sendReq(this.webService.getOperatorResponse({ params: filter, token: token }));
  }
  getOperatorFormDetail(id, token) { // service to get all available sports
    return this.sendReq(this.webService.getOperatorFormDetail({ id: id, token: token }));
  }
  getOperatorResponseFormDetail(id, token) {
    // service to get all available sports
    return this.sendReq(this.webService.getOperatorResponseFormDetail({ id: id, token: token }));
  }
  deleteOperatorTypeForm(id, token) {
    return this.sendReq(this.webService.deleteOperatorForms({ id: id, token: token }));
  }
  addEditOperatorForms(param) {
    return this.sendReq(this.webService.addEditOperatorForms(param));
  }
  deleteLocation(id, token) {
    return this.sendReq(this.webService.deleteLocation(id, token));
  }
  deleteTax(id, token) {
    return this.sendReq(this.webService.deleteTax(id, token));
  }

  // This method is used to get operator stripe connect account details
  getPaymentDetails(token) {
    var req = {
      method: 'GET',
      url: this.REQUEST_URL + 'connects/paymentDetails',
      headers: {
        'token': token
      }
    };
    return this.webService.httpReq(req);
  }
  getCurrencies() {
    var req = {
      method: 'GET',
      url: this.REQUEST_URL + 'masters/currencies'
    };
    return this.webService.httpReq(req);
  }

  getOperatorLocations(data) {
    var req = {
      method: 'GET',
      url: this.REQUEST_URL + 'locations/details',
      headers: {
        'token': data.token
      }
    };
    return this.webService.httpReq(req);
  }

  updateOperatorProfileOnAllPackages(data) {
    var req = {
      method: 'GET',
      url: this.REQUEST_URL + 'operators/updateOperatorProfileOnAllPackages',
      headers: {
        'token': data.token
      }
    };
    return this.webService.httpReq(req);
  }

  stripeConnect(data) { // check for address verification
    var req = {
      method: 'POST',
      url: this.REQUEST_URL + 'connects/stripeConnect',
      data: data.id,
      headers: {
        'token': data.token
      }
    };
    return this.webService.httpReq(req);
  }
  stripeUpdateDetail(data) { // update account details
    var req = {
      method: 'POST',
      url: this.REQUEST_URL + 'connects/updateBank',
      data: data.id,
      headers: {
        'token': data.token
      }
    };
    return this.webService.httpReq(req);
  }
  getTaxableCountry() {
    var req = {
      method: 'GET',
      url: this.REQUEST_URL + 'masters/operator-country'
    };
    return this.webService.httpReq(req);
  }
  getPaymentCountry() {
    var req = {
      method: 'GET',
      url: this.REQUEST_URL + 'masters/operatorBankCountry'
    };
    return this.webService.httpReq(req);
  }
  addPaymentDetails(data) {
    var req = {
      method: 'POST',
      url: this.REQUEST_URL + 'operators/add-bank',
      data: data.id,
      headers: {
        'token': data.token
      }
    };
    return this.webService.httpReq(req);
  }
  // get the expired slots
  getPackageExpiredSlots(data) {
    var req = {
      method: 'GET',
      url: this.REQUEST_URL + 'packages/getUserPackageExpiredSlot?package_id=' + data.id,
      headers: {
        'token': data.token
      }
    };
    return this.webService.httpReq(req);
  }
  /**
     * @ngdoc method
     * @name getCurrencyCode
     *
     * @description
     * To get currency code using country code
     **/
  getCurrencyCode(data) {
    var req = {
      method: 'GET',
      url: this.REQUEST_URL + 'Currencies/getCurrencyCode?country_code=' + data.country_code + '&is_multiple=' + data.isMultiple,
      headers: {
        'token': data.token
      }
    };
    return this.webService.httpReq(req);
  }

  getStripeAccountLinks(data) {
    return this.webService.httpReq({
      method: 'POST',
      url: this.REQUEST_URL + 'connects/getStripeAccountLinks',
      data: {
        countryCode: data.countryCode,
        currencyCode: data.currencyCode,
        businessType: data.businessType
      },
      headers: {
        token: data.token
      }
    });
  }

  updateFlywireAccount(data) {
    return this.webService.httpReq({
      method: 'POST',
      url: this.REQUEST_URL + 'connects/flywireAccount',
      data: {
        portalCode: data.portalCode,
        portalCurrency: data.portalCurrency,
        sharedSecret: data.sharedSecret
      },
      headers: {
        token: data.token
      }
    });
  }

  saveWaiverDocument(data, token) {
    return this.webService.httpReq({
      url: this.REQUEST_URL + 'waiver/save',
      method: 'POST',
      data: data,
      headers: {
        'token': token
      }
    });
  }

  getWaiverDocuments(token) {
    return this.webService.httpReq({
      method: 'GET',
      url: this.REQUEST_URL + 'waiver',
      headers: {
        'token': token
      }
    });
  }

  deleteWaiverDocuments(token, id) {
    return this.webService.httpReq({
      method: 'DELETE',
      url: this.REQUEST_URL + 'waiver/delete/' + id,
      headers: {
        'token': token
      }
    });
  }

  resendGuestForm(data, token) {
    return this.webService.httpReq({
      url: this.REQUEST_URL + 'guest-forms/resend-guest-form',
      method: 'POST',
      data: data,
      headers: {
        'token': token
      }
    });
  }

  sendFormsGuest(data) {
    return this.webService.httpReq({
      url: this.REQUEST_URL + 'guest-forms/sendToGuest',
      method: 'POST',
      data: data,
      headers: {
        'token': data.token
      }
    });
  }

  saveNewTaxAdventure(data, token) {
    return this.webService.httpReq({
      url: this.REQUEST_URL + 'taxes',
      method: 'POST',
      data: data,
      headers: {
        'token': token
      }
    });
  }
}

angular.module('heliApp').service('operatorProfileService', downgradeInjectable(OperatorProfileService));
