/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:operatorViewBookingCtrl
 * @description
 * # operatorViewBookingCtrl
 * Controller of the heliApp
 */
angular
  .module('heliApp')
  .controller(
    'operatorViewBookingCtrl',
    [
      '$q',
      '$scope',
      '$rootScope',
      '$state',
      '$stateParams',
      '$cookies',
      'operatorViewBookingService',
      'CONST_HELPER',
      'userOrdersListService',
      'operatorCalendarService',
      'userAdventureDetailService',
      '$timeout',
      '$filter',
      'binaryService',
      function (
        $q,
        $scope,
        $rootScope,
        $state,
        $stateParams,
        $cookies,
        operatorViewBookingService,
        CONST_HELPER,
        userOrdersListService,
        operatorCalendarService,
        userAdventureDetailService,
        $timeout,
        $filter,
        binaryService
      ) {
        var cancelRequestId;
        var packageId = $stateParams.package_id; // set package_id in variable
        var slotId = $stateParams.slot_id; // set slot_id  in variable
        var bookingSlotId = $stateParams.booking_slot_id; // set booking_slot_id in variable

      $scope.maxSize = 5;
      $scope.bookList = {};
      $scope.noBooking = false;
      $scope.predicate = '';// for filter
      $scope.slotDropdown = {};
      $scope.noBookingList = false;
      $scope.getSlotCsvList = [];
      $scope.getFullCsvList = [];
      $scope.getPackageDetail = {};
      $scope.viewPackageDetail = {};
      $scope.packageSlotDropdown = [];
      $scope.bookList.currentPage = 1;
      $scope.allPackageBookingList = [];
      $scope.singlePackageBookingList = [];
      $scope.operatorCurrencySelected = true;
      $scope.bookingsNotFound = false;
      $scope.packageFilter = {};
      $scope.packageFilter.isAllSelected = false;
      var packageIds = $stateParams.packageIds || [];
      $scope.viewAllAgentBookingPage = false;
      $scope.filter_date_type = $stateParams.filter_date_type ? $stateParams.filter_date_type : 0;
      console.log('view all bookings', $state.current.name);
      if ($state.current.name == 'operator-agent-booking-list') {
        $scope.viewAllAgentBookingPage = true;
      }
      if (!Array.isArray(packageIds)) {
        packageIds = [packageIds];
      }
      var op_id = $stateParams.op_id;

        $scope.$on('setSelectedOperator', function () {
          var selectedOperator = $scope.agentAllOperators.filter(function (operator) {
            return (operator.operator_profile && operator.operator_profile.user_id == op_id);
          });
          if (selectedOperator.length) {
            $scope.agentOperator = selectedOperator[0];
          } else {
            $scope.agentOperator = {
              name: 'All Operators',
              id: ''
            };
          }
        });

        $scope.toDate = moment().format('YYYY-MM-DD');
        $scope.fromDate = moment().subtract(365, 'days').format('YYYY-MM-DD');
        $scope.displaySpecificTo = moment().format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
        $scope.displaySpecificFrom = moment().subtract(29, 'days').format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
        // Booking Source filter
        $scope.bookingSource = $state.params.booking_source ? $state.params.booking_source : 0;
        $scope.locationSourceId = $state.params.location_id ? $state.params.location_id : 0;
        $scope.bookingFilter = ['All Booking Sources', 'Heli', 'My Site', 'Offline', 'Manual'];
        // location filters array
        $scope.locationFilter = [];
        $scope.paymentStatus = $state.params.booking_status ? $state.params.booking_status : 0;
        $scope.paymentStatusId = $state.params.booking_status ? $state.params.booking_status : 0;
        $scope.paymentStatusFilters = [{
          id: 0,
          name: 'All Payment Status'
        }, {
          id: 1,
          name: 'Fully Paid'
        }, {
          id: 2,
          name: 'Received Deposit'
        }, {
          id: 3,
          name: 'Payment Pending'
        }, {
          id: 4,
          name: 'Cancelled'
        }];
        $scope.getPaymentStatusById = function (id) {
          return $scope.paymentStatusFilters.find(function (val) {
            return val.id == parseInt(id);
          });
        };
        $scope.paymentStatus = $scope.getPaymentStatusById($scope.paymentStatusId);
        $scope.tempId = $scope.getTempId();
        // scope variables for sorting key
        $scope.sortValue = $state.params.sort_value ? $state.params.sort_value : 'booking_date';
        $scope.reverse = $state.params.reverse ? $state.params.reverse : true;
        $scope.getLocationNames = function () {
          var data = {
            token: $scope.user.token
          };
          operatorCalendarService.getLocationNames(data).then(function (response) {
            if (response.data) {
              if (
                (response.data.code == CONST_HELPER.API_RESPONSE.OK)
                ||
                (response.data.code == CONST_HELPER.API_RESPONSE.NO_CONTENT)
              ) {
                $scope.locationFilter = response.data.data;
                $scope.locationFilter.unshift({
                  id: CONST_HELPER.ZERO,
                  name: 'All locations'
                });
                $scope.locationSource = $scope.getLocationById($scope.locationSourceId);
              }
            }
          });
        };
        $scope.addLocation = function () {
          $rootScope.redirectToAddLocation = true;
          $state.go('operator-edit-profile');
        };

        function getOperatorPackages() {
          var data = {
            'status': 1,
            'token': $scope.user.token
          };

          operatorCalendarService.getOperatorPackages(data).then(function (res) {
            if (res && res.data.response) {
              $scope.packageList = res.data.response.docs;

              $scope.packageList.map(function (pkgobj) {
                if (packageIds.includes(String(pkgobj.package_id))) {
                  pkgobj.selected = true;
                } else {
                  pkgobj.selected = false;
                }
              });
              $scope.packageFilter.isAllSelected = $scope.packageList.every(function (itm) {
                return itm.selected;
              });
            } else {
              logoutCheck(res.data.code);
            }
          });
        }

        function logoutCheck(code) {
          if (code == '401') {
            $scope.logout();
          }
        }

        $scope.toggleAll = function (filteredArray) {
          var toggleStatus = $scope.packageFilter.isAllSelected;
          packageIds = [];
          angular.forEach(filteredArray, function (itm) {
            itm.selected = toggleStatus;
            if (toggleStatus) {
              packageIds.push(itm.package_id);
            }
          });
          $scope.getViewBookingDetail();
        };
        $scope.checkAllSelected = function () {
          $scope.packageFilter.isAllSelected = $scope.packageList.every(function (itm) {
            return itm.selected;
          });
        };

        $scope.applySort = function (sortKey, sortOrder) {
          $scope.sortValue = sortKey;
          $scope.reverse = sortOrder;
          if ($scope.viewAllAgentBookingPage) {
            $state.go('operator-agent-booking-list', ({
              booking_status: $scope.paymentStatusId,
              packageIds: packageIds,
              reverse: $scope.reverse,
              sort_value: $scope.sortValue,
              page: $scope.bookList.currentPage,
              booking_source: $scope.bookingSource,
              location_id: $scope.locationSourceId,
              to_date: $scope.toDate,
              from_date: $scope.fromDate,
              op_id: op_id
            }));
          } else {
            if (!$rootScope.isAgent) {
              $state.go('operator-booking-list', ({
                booking_status: $scope.paymentStatusId,
                packageIds: packageIds,
                reverse: $scope.reverse,
                sort_value: $scope.sortValue,
                page: $scope.bookList.currentPage,
                booking_source: $scope.bookingSource,
                location_id: $scope.locationSourceId,
                to_date: $scope.toDate,
                from_date: $scope.fromDate,
               filter_date_type: $scope.filter_date_type
              }));
            } else {
              $state.go('agent-booking-list', ({
                booking_status: $scope.paymentStatusId,
                packageIds: packageIds,
                reverse: $scope.reverse,
                sort_value: $scope.sortValue,
                page: $scope.bookList.currentPage,
                booking_source: $scope.bookingSource,
                location_id: $scope.locationSourceId,
                to_date: $scope.toDate,
                from_date: $scope.fromDate,
                op_id: op_id
              }));
            }
          }
        };

        $scope.resetFilter = function (event) {
          event.stopPropagation();
          $scope.packageSearch = '';
          $timeout(function () {
            $('#package-filter').focus();
          });
          $scope.checkAllSelected();
        };

        $scope.optionToggled = function (obj) {
          $scope.packageFilter.isAllSelected = $scope.packageList.every(function (itm) {
            return itm.selected;
          });
          if (obj.selected) {
            packageIds.push(obj.package_id);
          } else {
            packageIds.splice(packageIds.indexOf(obj.package_id), 1);
          }
          $scope.getViewBookingDetail();
          /* filter slot data according to packages selected */
          // getEvents is a custom function that fetch events...
        };
        $scope.getLocationNames();
        getOperatorPackages();
        $scope.setLocationSource = function (location, select) {
          $scope.locationSourceId = location.id;
          $state.params.page = CONST_HELPER.ONE; // reset page on booking source change...
          $scope.bookList.currentPage = CONST_HELPER.ONE;
          if ($scope.locationSourceId == $scope.tempId) {
            $scope.locationSource = $scope.locationFilter[0];
            select.selected = $scope.locationFilter[0]; // update select value.
          } else {
            $state.go('operator-booking-list', ({
              booking_status: $scope.paymentStatusId,
              packageIds: packageIds,
              reverse: $scope.reverse,
              sort_value: $scope.sortValue,
              page: $scope.bookList.currentPage,
              booking_source: $scope.bookingSource,
              location_id: $scope.locationSourceId,
              to_date: $scope.toDate,
              from_date: $scope.fromDate,
              filter_date_type: $scope.filter_date_type
            }));
          }
        };
        $scope.getLocationById = function (id) {
          return $scope.locationFilter.find(function (val) {
            return val.id == parseInt(id);
          });
        };
        $scope.setPaymentSource = function (payment, select) {
          $scope.paymentStatusId = payment.id;
          $state.params.page = CONST_HELPER.ONE; // reset page on booking source change...
          $scope.bookList.currentPage = CONST_HELPER.ONE;
          if ($scope.paymentStatusId == $scope.tempId) {
            $scope.paymentStatus = $scope.paymentStatusFilters[0];
            select.selected = $scope.paymentStatusFilters[0]; // update select value.
          } else {
            $state.go('operator-booking-list', ({
              booking_status: $scope.paymentStatusId,
              packageIds: packageIds,
              reverse: $scope.reverse,
              sort_value: $scope.sortValue,
              page: $scope.bookList.currentPage,
              booking_source: $scope.bookingSource,
              location_id: $scope.locationSourceId,
              to_date: $scope.toDate,
              from_date: $scope.fromDate,
              filter_date_type: $scope.filter_date_type
            }));
          }
        };
        $scope.showCustomFilter = function (isShow) {
          if (isShow) {
            angular.element(document.querySelector('.analytics-dropdown')).toggle();
          } else {
            angular.element(document.querySelector('.analytics-dropdown')).css('display', 'none');
          }
          $rootScope.isCustomCalendarClick = true; // to handle document click to close the custom filter dropdown
        };
        $scope.clickEvntSpecificDates = function () { // prevent the close of dropdown on click of From and To textbox
          $rootScope.isCustomCalendarClick = true; // to handle document click to close the custom filter dropdown
        };
        $scope.changeFilterDateType = function (value) {
          $scope.filter_date_type = value;
          $scope.showCustomFilter(true);
        }
        $timeout(function () {
          angular.element(document.querySelector('#sandbox-container div')).datepicker({
            maxViewMode: 0
          }).on('changeDate', function (e) {
            setSpecificFromToValues(e);
          });
          $scope.showCustomFilter(false); // call show custom filter
        }, 1000);

        function setSpecificFromToValues (e) {
          var specificToDate = angular.element(document.querySelector('#specific-to-date'));
          var specificFromDate = angular.element(document.querySelector('#specific-from-date'));
          if ($scope.isSpecificFromSelected) {
            // $scope.isSpecificFromSelected is true means From specific textbox value is been selected from the emebeded calendar
            $scope.toDate = moment(e.date).format('YYYY-MM-DD');
            // Specific from Date is lesser than Specific To Date
            if (moment($scope.fromDate, CONST_HELPER.analytics_filter.DATE_FORMAT_PAYOUT).isBefore(moment($scope.toDate))) {
              specificToDate.val(moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY));
            } else {
              // Selected Specific To Date is lesser than From.So, Selected date will become Specific From date and already Selected From date will be To date
              $scope.toDate = $scope.fromDate;
              specificToDate.val(specificFromDate.val());
              specificFromDate.val(moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY));
              $scope.fromDate = moment(e.date).format('YYYY-MM-DD');
            }
            $scope.displaySpecificTo = moment($scope.toDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
            $scope.displaySpecificFrom = moment($scope.fromDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
            $scope.isSpecificFromSelected = false; // now again if calendar will be clicked then Specific From date textbox will be selected
            $scope.showCustomFilter(false);
            $scope.$apply();
            $state.params.page = CONST_HELPER.ONE; // reset page on booking source change...
            $scope.bookList.currentPage = CONST_HELPER.ONE;
            $state.go('operator-booking-list', ({
              booking_status: $scope.paymentStatusId,
              packageIds: packageIds,
              reverse: $scope.reverse,
              sort_value: $scope.sortValue,
              page: $scope.bookList.currentPage,
              booking_source: $scope.bookingSource,
              location_id: $scope.locationSourceId,
              to_date: $scope.toDate,
              from_date: $scope.fromDate,
              filter_date_type: $scope.filter_date_type
            }));
          } else {
            specificFromDate.val(moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY));
            $scope.fromDate = moment(e.date).format('YYYY-MM-DD');
            specificToDate.val('');
            $scope.isSpecificFromSelected = true;
          }
        }

        $scope.getViewBookingPackageDetail = function (requestObject, type) {
          $scope.homeRedirection();// check for authorised access
          if ((!$scope.user) || ($scope.user && !$scope.user.token)) { // check for user logged in status
            $state.go('signin');
            return false;
          }
          $scope.showLoader();
          requestObject.package_id = requestObject.package_ids[0];
          operatorViewBookingService.getBookingPackageDetail(requestObject).then(function (response) { // fetch from the service
            if (response.data.code == '200') {
              $scope.singlePackageBookingList = response.data.booking_list;
              $scope.singlePackageDetail = response.data.package;
              if (type === 'getPackageDetailFun') {
                $scope.slotDropdown = slotId ? response.data.package_slot.find(function (slot) {
                  return slot.id == slotId;
                }) : response.data.package_slot[0];
                $scope.packageSlotDropdown = response.data.package_slot;
                $scope.package_currency_code = response.data.booking_list.length ? response.data.booking_list[0].package_currency_code : 'USD';
              }
              $scope.totalItems = response.data.count; // set pagination count
              $scope.hideLoader();
            } else if (response.data.code == '401') {
              $scope.logout();
            } else {
              $scope.noBooking = true;
              $scope.hideLoader();
            }
          }, function () { // check for error
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
        };

        $scope.valueChange = function (value) { // change value from dropdown
          $scope.slotDropdown = value;
          slotId = value.id;
          $scope.bookList.currentPage = $state.params.page ? $state.params.page : 1;
          $scope.getSlotCsvList = []; // set csv file  blank
          $state.go('operator-view-booking', {
            page: CONST_HELPER.ONE,
            package_id: packageId,
            slot_id: slotId
          });
        };

        $scope.getHeaderForSlotBooking = function () { // set header for csv file
          return [
            'No.',
            'Booking ID',
            'Booking Date',
            'User Name',
            'No Of Party',
            'Slots',
            'Amount'
          ];
        };

        $scope.getHeaderForFullBooking = function () { // set header for csv file
          return [
            'Order ID',
            'Booking Date',
            'Booked By',
            'Email',
            'No. in Party',
            'Adventure Name',
            'Location',
            'Trip Start Date',
            'Trip End Date',
            'Number of Days',
            'Source',
            'Payment Status',
            'Currency',
            'Unit Price',
            'Number of Add Ons',
            'Add Ons Total',
            'Subtotal',
            'Tax',
            'Total',
            'Operator Price',
            'Processing Fee',
            'Heli Total Collected',
            'Deposit Amount',
            'Heli Commission',
            'TOTAL OPERATOR EARNINGS'
          ];
        };

        $scope.getPackageDetailFun = function () { // get package detail based on package ID
          $scope.bookList.currentPage = $state.params.page ? $state.params.page : 1;
          var requestObject = {
            package_ids: [packageId],
            slot_id: slotId || CONST_HELPER.ZERO,
            token: $scope.user.token,
            page: $scope.bookList.currentPage
          };
          $scope.getViewBookingPackageDetail(requestObject, 'getPackageDetailFun');// call api for  get View Booking Package Detail
        };

        function convertDateToCorrectTimeZone (date) {
          return new Date(date).setMinutes(new Date(date).getMinutes() + new Date().getTimezoneOffset());
        }

        $scope.getPackageViewDetail = function () { // get package View detail based on Order ID
          $scope.homeRedirection();// check for authorised access
          if (!$scope.user || ($scope.user && !$scope.user.token)) { // check for user logged in status
            $state.go('signin');
            return false;
          }
          $scope.showLoader();
          var requestObject = {
            package_ids: [packageId],
            booking_slot_id: bookingSlotId,
            token: $scope.user.token
          };

          operatorViewBookingService.getPackageViewDetail(requestObject).then(function (response) { // fetch from the service
            if (response.data.code == '200') {
              $scope.viewPackageDetail = response.data.data;
              calculateTotalGuests();
              if ($scope.viewPackageDetail.agent_commission) {
                $scope.paidAgentCommission = commissionPaidToAgent();
                $scope.agentCommssionBalance = $scope.viewPackageDetail.agent_commission - $scope.paidAgentCommission;
              }
              if ($scope.viewPackageDetail.booking_source !== CONST_HELPER.MANUAL && $scope.viewPackageDetail.booking_source !== CONST_HELPER.MANUAL_PAID) {
                $scope.viewPackageDetail.promo_code_discount = $scope.viewPackageDetail.promo_code_discount;// original USD / $scope.viewPackageDetail.cc_rate;
                $scope.viewPackageDetail.nightly_discount = $scope.viewPackageDetail.nightly_discount;// original USD / $scope.viewPackageDetail.cc_rate;
                $scope.viewPackageDetail.promo_code_discount = $scope.viewPackageDetail.promo_code_discount + $scope.viewPackageDetail.nightly_discount;
                $scope.selectedCurrency = $scope.viewPackageDetail.operator_currency_code ? $scope.viewPackageDetail.operator_currency_code : $scope.viewPackageDetail.package_currency_code; // default selected currency is package currency.
                $scope.selectedCurrencyRate = $scope.viewPackageDetail.package_cc_rate;
                if (!$scope.viewPackageDetail.is_partial && $scope.viewPackageDetail.payment && $scope.viewPackageDetail.payment[0] && $scope.viewPackageDetail.payment[0].history && $scope.viewPackageDetail.payment[0].history.length) {
                  $scope.viewPackageDetail.total_processing_fee = 0;
                } else {
                  $scope.viewPackageDetail.total_processing_fee = 0;
                  $scope.viewPackageDetail.payment.forEach(function(eachPayment) {
                    $scope.viewPackageDetail.total_processing_fee += eachPayment.proc_fee_amount;
                  });
                }
                if ($scope.viewPackageDetail.payment_status == 0 && $scope.viewPackageDetail.payment && $scope.viewPackageDetail.payment.length == 1) {
                  $scope.viewPackageDetail.payment_status = 2;
                } else if ($scope.viewPackageDetail.payment_status == 0 && $scope.viewPackageDetail.payment && $scope.viewPackageDetail.payment.length == 2) {
                  $scope.viewPackageDetail.payment_status = 1;
                }
                if ($scope.viewPackageDetail.local_currency_code == $scope.viewPackageDetail.operator_currency_code) {
                  $scope.viewPackageDetail.taxes.items.forEach(function (eachTax) {
                    eachTax.price.amount = eachTax.price.amount / $scope.viewPackageDetail.package_cc_rate;
                  });
                  $scope.viewPackageDetail.taxes.total.amount = $scope.viewPackageDetail.taxes.total.amount / $scope.viewPackageDetail.package_cc_rate;
                }

                if ($scope.viewPackageDetail.local_currency_code == 'USD' && $scope.selectedCurrency == 'USD') {
                  $scope.selectedCurrencyRate = 1;
                }
                $scope.partialFullPayAmount = Math.round($scope.viewPackageDetail.total_amount + ($scope.viewPackageDetail.taxes.total.amount / $scope.viewPackageDetail.package_cc_rate));
                $scope.partialPayAmount = Math.round($scope.viewPackageDetail.payment[0].amount + $scope.viewPackageDetail.payment[0].tax_amount);
                !$scope.viewPackageDetail.taxes.total.currency && ($scope.viewPackageDetail.taxes.total.currency = {cc_rate_usd: 1, code: "USD", id: 1,name: "US Dollar", symbol: "$"});
                $scope.grandTotal = ($scope.viewPackageDetail.sub_total + (($scope.viewPackageDetail.taxes.total.amount / $scope.viewPackageDetail.taxes.total.currency.cc_rate_usd)) + $scope.viewPackageDetail.total_processing_fee) - $scope.viewPackageDetail.promo_code_discount || 0;
                $scope.fullAndPartialPay = Math.round($scope.grandTotal - $scope.viewPackageDetail.total_processing_fee);
                $scope.partialFullPayAmountRefundZero = Math.round($scope.viewPackageDetail.payment[0].amount + ($scope.grandTotal - ($scope.viewPackageDetail.payment[0].paid_amount + $scope.viewPackageDetail.payment[0].proc_fee_amount + $scope.viewPackageDetail.heli_commission)));
                $scope.viewPackageDetail.order_date = convertDateToCorrectTimeZone($scope.viewPackageDetail.order_date);
              } else {
                if ($scope.viewPackageDetail.booking_source == CONST_HELPER.MANUAL_PAID) {
                  if ($scope.viewPackageDetail.guest_details) {
                    $scope.viewPackageDetail.guest_details.deposit_price = $scope.viewPackageDetail.guest_details.deposit_price / $scope.viewPackageDetail.cc_rate;
                    $scope.viewPackageDetail.guest_details.price = $scope.viewPackageDetail.guest_details.price / $scope.viewPackageDetail.cc_rate;
                  }

                  $scope.grandTotal = $scope.viewPackageDetail.guest_details ? $scope.viewPackageDetail.guest_details.price : 0;
                  $scope.selectedCurrencyRate = $scope.viewPackageDetail.package_cc_rate ? $scope.viewPackageDetail.package_cc_rate : 1;
                } else {
                  $scope.grandTotal = $scope.viewPackageDetail.guest_details ? $scope.viewPackageDetail.guest_details.price : 0;
                  $scope.selectedCurrencyRate = 1;
                  if (!$scope.viewPackageDetail.payment.length && $scope.viewPackageDetail.payment_status != 1) {
                    $scope.viewPackageDetail.sub_total = $scope.viewPackageDetail.guest_details.price;
                    $scope.viewPackageDetail.payment.push({
                      paid_amount: $scope.viewPackageDetail.guest_details.deposit_price,
                    });
                    $scope.viewPackageDetail.total_amount = $scope.viewPackageDetail.guest_details.price;
                    $scope.viewPackageDetail.tax = 0;
                  }
                }
                $scope.selectedCurrency = ($scope.viewPackageDetail.operator_currency_code && $scope.viewPackageDetail.booking_source != 3) ? $scope.viewPackageDetail.operator_currency_code : $scope.viewPackageDetail.package_currency_code; // default selected currency is package currency.
                $scope.viewPackageDetail.mark_as_recieved = !(($scope.viewPackageDetail.guest_details && $scope.viewPackageDetail.guest_details.is_deposit));
                $scope.viewPackageDetail.guest_details.created = convertDateToCorrectTimeZone($scope.viewPackageDetail.guest_details.created);
              }
              if ($scope.viewPackageDetail.nightly_slot_id) {
                $scope.days = moment($scope.viewPackageDetail.to_date).diff(moment($scope.viewPackageDetail.from_date), 'days') + CONST_HELPER.ONE;
              }
              $scope.hideLoader();
              // initiate the tooltip for operator price
              $timeout(function () {
                $('[data-toggle="tooltip"]').tooltip();
              }, 0);
            } else if (response.data.code == '401') {
              $scope.logout();
            } else {
              $scope.hideLoader();
            }
          }, function () { // check for error
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
        };

        if (bookingSlotId) {
          $scope.getPackageViewDetail();
        }
        // mark as recieved on remaining amount...
        $scope.updateDeposit = function () {
          var data = {
            guest_id: $scope.viewPackageDetail.guest_details.id,
            token: $scope.user.token
          };
          $scope.showLoader();
          operatorViewBookingService.updateIsDeposit(data).then(function (res) {
            if (res.data.code == '200') {
              $scope.viewPackageDetail.guest_details.is_deposit = $scope.viewPackageDetail.mark_as_recieved ? 0 : 1;
            }
            $scope.hideLoader();
          });
        };

        function calculateTotalGuests(bookingDetail) {
          // convert into array if slot lodging is in json object;
          if ($scope.viewPackageDetail.booking_slot_lodging_details && !$scope.viewPackageDetail.booking_slot_lodging_details.length) {
            var obj = angular.copy($scope.viewPackageDetail.booking_slot_lodging_details);
            var lodgingArrayObject = [];

            for (var key in obj) {
              if (obj.hasOwnProperty(key)) {
                var val = obj[key];
                lodgingArrayObject.push(val);
              }
            }
            $scope.viewPackageDetail.booking_slot_lodging_details = lodgingArrayObject;
          }

          // if booked by guest user...
          if (!$scope.viewPackageDetail.guest_details && (!$scope.viewPackageDetail.booked_by || $scope.viewPackageDetail.booked_by === ' ')) {
            $scope.viewPackageDetail.booked_by = 'Guest User';
          }
          var calculateGuestCountOrNot = !$scope.viewPackageDetail.slot_adult_quantity; // if guest count is already their then don't calculate guests count.
          if ($scope.viewPackageDetail.booking_slot_lodging_details && $scope.viewPackageDetail.booking_slot_lodging_details.length) {
            calculateGuestCountOrNot ? $scope.viewPackageDetail.slot_adult_quantity = 0 : '';
            $scope.viewPackageDetail.sub_total = 0;
            $scope.viewPackageDetail.booking_slot_lodging_details.map(function (bsl) {
              if ($scope.viewPackageDetail.booking_source == 3) {
                $scope.viewPackageDetail.slot_adult_quantity = $scope.viewPackageDetail.guest_details.number_of_guest;
              } else {
                calculateGuestCountOrNot ? $scope.viewPackageDetail.slot_adult_quantity += bsl.quantity : '';
              }
              $scope.viewPackageDetail.sub_total += !bsl.is_private ? bsl.usd_price : bsl.usd_private_price;
              if ($scope.viewPackageDetail.nightly_slot_id) {
                bsl.slot_lodging = bsl.nightly_slot ? bsl.nightly_slot : {};
                // !bsl.is_private ? bsl.slot_lodging.price = bsl.usd_price / bsl.quantity : bsl.slot_lodging.private_price = bsl.usd_private_price / bsl.quantity;
              }
            });
            $scope.viewPackageDetail.booking_addon_details.map(function (addon) {
              $scope.viewPackageDetail.sub_total += addon.package_addon_usd_adult_price;
            });
            console.log('SubTotal: ', $scope.viewPackageDetail.sub_total);
          } else if ($scope.viewPackageDetail.booking_source == 4) {
            // $scope.viewPackageDetail.sub_total =
          }
        }

        $scope.calculateTotalBookingGuests = function (bookings) {
          bookings.slot_adult_quantity = 0;
          if (bookings.booking_slot_lodging_details.length) {
            bookings.booking_slot_lodging_details.map(function (bsl) {
              bookings.slot_adult_quantity += bsl.quantity;
            });
          }
        };

        // on currency code change...
        $scope.changeCurrencyCode = function (code, whichCode, isOperator) {
          if($scope.selectedCurrency !== code){
            if(code === 'USD') {
              $scope.selectedCurrencyRate = 1;
            } else {
              $scope.selectedCurrencyRate = $scope.viewPackageDetail[whichCode];
            }
          }
          $scope.selectedCurrency = code;
          $scope.operatorCurrencySelected = isOperator == 1;
        };
        $scope.setBookingSource = function (bookingType) {
          $scope.bookingSource = bookingType;
          $state.params.page = 1; // reset page on booking source change...
          $scope.bookList.currentPage = 1;
          $state.go('operator-booking-list', ({
            booking_status: $scope.paymentStatusId,
            packageIds: packageIds,
            reverse: $scope.reverse,
            sort_value: $scope.sortValue,
            page: $scope.bookList.currentPage,
            booking_source: $scope.bookingSource,
            location_id: $scope.locationSourceId,
            to_date: $scope.toDate,
            from_date: $scope.fromDate,
            filter_date_type: $scope.filter_date_type
          }));
        };
        $scope.getViewBookingDetail = function (type) { // get all view booking detail
          $scope.homeRedirection();// check for authorised access
          if ((!$scope.user) || ($scope.user && !$scope.user.token)) { // check for user logged in status
            $state.go('signin');
            return false;
          }
          $scope.showLoader();

          if (type === 'firstLoad') {
            $scope.noBookingList = false; // Setting false for the booking source load
            $scope.bookList.currentPage = $state.params.page ? $state.params.page : 1;
            $scope.toDate = $state.params.to_date ? $state.params.to_date : moment().format('YYYY-MM-DD');
            $scope.fromDate = $state.params.from_date ? $state.params.from_date : moment().subtract(365, 'days').format('YYYY-MM-DD');
            $scope.displaySpecificTo = moment($scope.toDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
            $scope.displaySpecificFrom = moment($scope.fromDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
            $scope.sortValue = $state.params.sort_value ? $state.params.sort_value : 'booking_date';
            $scope.reverse = $state.params.reverse ? $state.params.reverse : 'true';
          }

          var requestObject = {
            token: $scope.user.token,
            page: $scope.bookList.currentPage ? $scope.bookList.currentPage : $state.params.page,
            booking_source: $scope.bookingSource, // Setting Booking Source Data
            location_id: $scope.locationSourceId,
            from_date: $scope.fromDate,
            to_date: $scope.toDate,
            package_ids: packageIds,
            booking_status: $scope.paymentStatusId,
            sort: $scope.sortValue,
            sort_type: $scope.reverse == 'false' ? 'ASC' : 'DESC',
            filter_date_type: $scope.filter_date_type
          };
          if ($scope.viewAllAgentBookingPage) {
            requestObject['all_agents'] = true;
          } else {
            if ($rootScope.isAgent) {
              requestObject['op_id'] = op_id || '';
            }
          }

          operatorViewBookingService.getViewBookingDetail(requestObject).then(function (response) { // fetch from the service
            if (response.data.code == '200') {
              $scope.allPackageBookingList = response.data.booking_list;
              angular.forEach($scope.allPackageBookingList, function (list) {
                $scope.calculateTotalBookingGuests(list);
              });
              $scope.totalViewItems = response.data.count; // set pagination count
              $scope.noBookingList = false;
              $scope.hideLoader();
            } else if (response.data.code == '404') {
              if($stateParams){
                $scope.bookingsNotFound = true;
              } else {
                $scope.bookingsNotFound = false;
              }
              $scope.noBookingList = true;
              $scope.allPackageBookingList = '';
              $scope.totalViewItems = ''; // set pagination count
              $scope.hideLoader();
            } else if (response.data.code == '401') {
              $scope.logout();
            }
          }, function () { // check for error
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
        };

        $scope.pageChange = function (page, type) { // set pagination
          if ($scope.viewAllAgentBookingPage) {
            $state.go('operator-agent-booking-list', {
              packageIds: [],
              reverse: $scope.reverse,
              sort_value: $scope.sortValue,
              page: page
            });
          } else {
            if (type === 'singlePackage') {
              $state.go('operator-view-booking', {
                page: page,
                package_id: packageId,
                slot_id: slotId
              });
            }

            if (type === 'viewPackageList') {
              $state.go('operator-booking-list', {
                booking_status: $scope.paymentStatusId,
                packageIds: packageIds,
                reverse: $scope.reverse,
                sort_value: $scope.sortValue,
                page: page,
                booking_source: $scope.bookingSource,
                location_id: $scope.locationSourceId,
                to_date: $scope.toDate,
                from_date: $scope.fromDate,
                filter_date_type: $scope.filter_date_type
              });
            }
          }
        };

        $scope.downloadSingleBookingListCsv = function () { // download single package record in csv
          $scope.showLoader();

          var count = 0;
          var requestObject = {
            token: $scope.user.token,
            page: 0,
            package_id: packageId,
            booking_source: $scope.bookingSource, // Setting Booking Source Data
            location_id: $scope.locationSourceId,
            from_date: $scope.fromDate,
            to_date: $scope.toDate,
            package_ids: packageIds,
            booking_status: $scope.paymentStatusId,
            sort: $scope.sortValue,
            sort_type: $scope.reverse == 'false' ? 'ASC' : 'DESC'
          };

          var deferred = $q.defer();

          operatorViewBookingService.getViewBookingDetail(requestObject).then(function (response) { // fetch from the service
            if (response.data.code == '200') {
              angular.forEach(response.data.booking_list, function (list) {
                $scope.calculateTotalBookingGuests(list);
                var dateFormat = new Date(list.booking_date);
                $scope.getSlotCsvList.push({
                  No: ++count,
                  booking_id: list.booking_id,
                  booking_date: dateFormat,
                  user_name: list.full_name,
                  no_of_party: list.slot_adult_quantity || list.no_of_party,
                  slot: list.slots,
                  amount: list.total_amount.toFixed(2)
                });
                deferred.resolve($scope.getSlotCsvList);
              });
              $scope.hideLoader();
            } else if (response.data.code == '404') {
              $scope.noBookingList = true;
              $scope.hideLoader();
            } else if (response.data.code == '401') {
              $scope.logout();
            }
          }, function () { // check for error
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
          return deferred.promise;
        };

        $scope.downloadBookingListCsv = function () { // download all package record in csv
          $scope.showLoader();

          var count = 0;
          var requestObject = {
            token: $scope.user.token,
            page: 0,
            booking_source: $scope.bookingSource, // Setting Booking Source Data
            location_id: $scope.locationSourceId,
            from_date: $scope.fromDate,
            to_date: $scope.toDate,
            package_ids: packageIds,
            sort: $scope.sortValue,
            booking_status: $scope.paymentStatusId,
            sort_type: $scope.reverse == 'false' ? 'ASC' : 'DESC',
            exportMode: true
          };

          var deferred = $q.defer();

          operatorViewBookingService.getViewBookingDetail(requestObject).then(function (response) { // fetch from the service
            if (response.data.code == '200') {
              $scope.hideLoader();
              angular.forEach(response.data.booking_list, function (list) {
                $scope.calculateTotalBookingGuests(list);

                $scope.getFullCsvList.push({
                  orderId: list.booking_id,
                  bookingDate: $filter('date')(new Date(list.booking_date), 'shortDate'),
                  bookedBy: list.full_name,
                  email: list.email,
                  noInParty: list.no_of_party ? list.no_of_party : list.slot_adult_quantity + 'Guest',
                  adventureName: list.adventure_name,
                  location: list.location,
                  tripStartDate: $filter('date')(new Date(list.trip_start_date), 'shortDate'),
                  tripEndDate: $filter('date')(new Date(list.trip_end_date), 'shortDate'),
                  numberOfDays: list.number_of_days,
                  source: $scope.bookingFilter[list.source],
                  paymentStatus: $scope.getPaymentStatusById(list.payment_status).name,
                  currency: list.package_currency_code,
                  unitPrice: list.unit_price,
                  numberOfAddOns: list.number_of_add_ons,
                  addOnsTotal: list.add_ons_total,
                  subtotal: list.sub_total,
                  tax: list.tax,
                  total: list.total,
                  operatorPrice: list.operator_price,
                  processingFee: list.processing_fee,
                  heliTotalCollected: list.heli_total_collected,
                  depositAmount: list.deposit_amount,
                  heliCommission: list.heli_commission,
                  totalOperatorEarnings: list.total_operator_earnings,
                });
                deferred.resolve($scope.getFullCsvList);
              });
            } else if (response.data.code == '404') {
              $scope.noBookingList = true;
              $scope.hideLoader();
            } else if (response.data.code == '401') {
              $scope.logout();
            }
          }, function () { // check for error
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
          return deferred.promise;
        };

        $scope.exportCsv = function () {
          $scope.showLoader();

          var params = {
            orderBy: $scope.sortValue,
            orderDirection: $scope.reverse == 'true' ? 'desc' : 'asc',
            filterDateType: $scope.filter_date_type,
            fromDate: $scope.fromDate,
            toDate: $scope.toDate
          };

          var paymentStatusId = parseInt($scope.paymentStatusId);
          if (paymentStatusId > 0) {
            params.paymentStatusId = paymentStatusId;
          }

          var locationId = parseInt($scope.locationSourceId);
          if (locationId > 0) {
            params.locationId = locationId;
          }

          var bookingSourceId = parseInt($scope.bookingSource);
          if (bookingSourceId > 0) {
            params.bookingSourceId = bookingSourceId;
          }

          if (!$scope.packageFilter.isAllSelected) {
            var selectedPackagesIds = $scope.packageList.filter(function (packageObject) {
              return packageObject.selected;
            }).map(function (packageObject) {
              return packageObject.package_id;
            });

            if (selectedPackagesIds.length > 0) {
              params.packageIds = selectedPackagesIds.join(',');
            }
          }

          operatorViewBookingService
            .exportCsv($scope.user.token, params)
            .then(function (response) {
              $scope.hideLoader();

              binaryService.downloadFileFromBlob({
                file: {
                  name: binaryService.getFilenameFromResponse(response)
                },
                blob: {
                  parts: response.data,
                  options: { type: response.headers.get('Content-Type') }
                }
              });
            }, function () {
              $scope.hideLoader();
              $scope.showPopup('#serverErrModal');
            });
        };

        $scope.getrequestToCancelID = function (orderNumber) { // function for request To Cancel package slot
          cancelRequestId = orderNumber;
          $scope.showPopup('#cancelRequest');
        };

        $scope.requestToCancel = function () {
          var requestObject = {
            order_number: cancelRequestId,
            token: $scope.user.token
          };
          $scope.showLoader();
          operatorViewBookingService.ReqToCancelBookingSlot(requestObject).then(function (response) { // fetch from the service
            if (response.data.code == '200') {
              $scope.hideLoader();
              $scope.hidePopup('#cancelRequest');
            } else if (response.data.code == '401') {
              $scope.logout();
            } else {
              $scope.hideLoader();
              $scope.hidePopup('#cancelRequest');
            }
          }, function () { // check for error
            $scope.hideLoader();
            $scope.hidePopup('#cancelRequest');
            $scope.showPopup('#serverErrModal');
          });
        };

        // function to edit resend email with link to complete the partial payment...
        $scope.resendCompletePaymentMail = function () {
          $scope.showLoader();
          var obj = {order_id: $scope.viewPackageDetail.order_number, token: $scope.user.token};

          // Api request /v1/payments/sendReminderEmail
          operatorViewBookingService.sendReminderEmail(obj).then(function (response) {
            if (response.data.code == CONST_HELPER.API_RESPONSE.OK) {
              $scope.hideLoader();
              $scope.showPopup('#emailSent');
            }
          }, function () {
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
        };

        // FUCNTION TO PROCEED THE REMAINING MANUAL PAYMENT;
        $rootScope.CartDetails = [];
        $scope.completeRemainingPayment = function (orderId) {
          userOrdersListService.getOrderList($scope.user.token, orderId).then(function (response) { // send req for current booking order
            if (response.data.message === 'Success') {
              var item = response.data.bookings[0].cart_slot_details[0];
              if (item.package && item.package.booking_type == 2) {
                var bookingStartDate = new Date(item.package_slots.from_date);
                var bookingEndDate = new Date(item.package_slots.to_date);
                $state.go('operator-manual-booking', {
                  nightly_slot_id: item.nightly_slot_id,
                  order_id: orderId,
                  package_id: item.package.id,
                  start_date: bookingStartDate.setMinutes(bookingStartDate.getMinutes() + new Date().getTimezoneOffset()),
                  end_date: bookingEndDate.setMinutes(bookingEndDate.getMinutes() + new Date().getTimezoneOffset()),
                  booking_type: CONST_HELPER.TWO,
                  op_id: item.operator_profile.user_id
                });
              } else {
                $state.go('operator-manual-booking', {slot_id: item.package_slot_id, order_id: orderId});
              }
            }
          }, function () {
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
        };

        $scope.changePaymentStatus = function () {
          $scope.showLoader();
          var obj = {
            booking_slot_id: bookingSlotId,
            token: $scope.user.token,
            guest_id: $scope.viewPackageDetail.guest_details ? $scope.viewPackageDetail.guest_details.id : ''
          };
          operatorViewBookingService.changePaymentStatus(obj).then(function () {
            $scope.getPackageViewDetail();
          });
        };

        // filter agent booking via operator
        $scope.changeOperatorBooking = function (operator) {
          op_id = operator.operator_profile ? operator.operator_profile.user_id : 0;
          $state.go('agent-booking-list', {
            booking_status: $scope.paymentStatusId,
            packageIds: packageIds,
            reverse: $scope.reverse,
            sort_value: $scope.sortValue,
            page: $scope.bookList.currentPage,
            booking_source: $scope.bookingSource,
            location_id: $scope.locationSourceId,
            to_date: $scope.toDate,
            from_date: $scope.fromDate,
            op_id: op_id
          });
        };

        // agent manual payout...

        function commissionPaidToAgent() {
          var paidCommission = 0;
          var agent_manual_payouts = $scope.viewPackageDetail.agent_manual_payouts || [];

          agent_manual_payouts.map(function (payout) {
            return paidCommission += +payout.amount;
          });
          return paidCommission;
        }

        $scope.checkCommissionAmount = function (amount) {
          $scope.paidAgentCommission = commissionPaidToAgent();
          var remainingAgentCommission = $scope.viewPackageDetail.agent_commission - $scope.paidAgentCommission;
          userAdventureDetailService
            .convertCurrency(
              1,
              CONST_HELPER.payment_const.default_currency,
              $scope.currencySymbol[$scope.viewPackageDetail.package_currency_code]
            ).then(function (data) {
              remainingAgentCommission = remainingAgentCommission * data.data.usd_rate;
              if (amount && amount > remainingAgentCommission) {
                $scope.agentCommssionError = true;
              } else if (remainingAgentCommission < 0) {
                $scope.agentCommssionErrorEdit = true;
              } else {
                $scope.agentCommssionError = false;
                $scope.agentCommssionErrorEdit = false;
              }
            });
        };

        $scope.agentPayout = {};
        $scope.addManualPayoutAgent = function (form, agentPayoutObj) {
          if (form.$invalid || $scope.agentCommssionError || $scope.agentCommssionErrorEdit) {
            return;
          }
          $scope.showLoader();
          var reqObj = {
            booking_slot_detail_id: bookingSlotId,
            booking_id: $scope.viewPackageDetail.order_number,
            paid_date: agentPayoutObj.paid_date,
            amount: agentPayoutObj.amount,
            notes: agentPayoutObj.notes,
            agent_id: $scope.viewPackageDetail.agent_id || $scope.viewPackageDetail.referral_id
          };
          if (agentPayoutObj.id) {
            reqObj.id = agentPayoutObj.id;
          }

          operatorViewBookingService.addManualPayoutAgent(reqObj, $scope.user.token).then(function (response) {
            if (response.data.code == '200') {
              agentPayoutObj.editing = false;
              form.$setPristine();
              $scope.agentPayout = {};
              $scope.getPackageViewDetail();
            }
          });
        };

        // edit agent payout ...
        $scope.editAgentPayout = function (agentPayoutObj) {
          agentPayoutObj.paid_date = moment(agentPayoutObj.paid_date).format('YYYY-MM-DD');
          agentPayoutObj.editing = true;
        };

        // delete agent payout ...
        $scope.deleteAgentPayout = function (agentPayoutObj) {
          $scope.showLoader();
          var reqObj = {
            id: agentPayoutObj.id
          };

          operatorViewBookingService.deletePayoutAgent(reqObj, $scope.user.token).then(function (response) {
            if (response.data.code == '200') {
              agentPayoutObj.editing = false;
              $scope.agentPayout = {};
              $scope.getPackageViewDetail();
            }
          });
        };

      // return number value with round off value...
      // eslint-disable-next-line no-unused-vars
      function toFixed (num, len) {
        return Number(num.toFixed(len));
      }

      $scope.copyGuestFormLink = function (id) {
        var hrefInput = 'guest-form-link-' + id;
        var copyText = document.getElementById(hrefInput);
        copyText.select();
        document.execCommand('copy');
        showToast('Copied to clipboard.');
      };

      // function to show toast after link is coppied...
      var showToast = function (msg) {
        var x = document.getElementById('snackbar');
        // Add the "show" class to DIV
        $('#snackbar').html(msg);
        x.className = 'show';
        // After 3 seconds, remove the show class from DIV
        setTimeout(function () {
          x.className = x.className.replace('show', '');
        }, 2000);
      };

    }
  ]
);
