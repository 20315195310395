/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc filter
 * @name heliApp.trustUrl
 * @description
 * # trusted is a filter which uses the $sce angular service to get certificate for external urls..
 * Filter in the heliApp.
 */
angular.module('heliApp')
  .filter('seoFriendlyURL', function () {
    return function (url) {
      return url.toString() // Convert to string
        .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
        .replace(/\s+/g, '-') // Change whitespace to dashes
        .toLowerCase() // Change to lowercase
        .replace(/&/g, '-and-') // Replace ampersand
        .replace(/[^a-z0-9\-]/g, '') // Remove anything that is not a letter, number or dash
        .replace(/-+/g, '-') // Remove duplicate dashes
        .replace(/^-*/, '') // Remove starting dashes
        .replace(/-*$/, ''); // Remove trailing dashes
    };
  });
