import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name OnMySiteService
 * @description
 * # onMySiteService
 * Service in the heliApp.
 */

@Injectable()

export class OnMySiteService {

  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService,
    @Inject(HttpClient) private httpClient: HttpClient
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  sendReq(method, param, token) {
    return new Promise((resolve, reject) => {
      method(param, token).then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    }) /* asynchronously complete request */
  }

  postReq(url, data, token) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + url,
      data: data,
      headers: {
        'token': token
      }
    };
    return req;
  }
  getReq(url, token) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + url,
      headers: {
        'token': token
      }
    };
    return req;
  }
  deleteReq(url, data, token) {
    let req = {
      method: 'DELETE',
      url: this.REQUEST_URL + url,
      data: data,
      headers: {
        'token': token
      }
    };
    return req;
  }
  /**
   * @ngdoc method
   * @name getOperatorPackage
   *
   * @description
   * This method call API to get operator package data
   **/
  getWhiteLabelPackages(data) {
    let req = this.getReq('operators/packageList?status=1&type=&limit=1000&page=1&back=0', data);
    return this.webService.httpReq(req);
  }
  /**
  * @ngdoc method
  * @name getOperatorPackage
  *
  * @description
  * This method call API to get operator package data
  **/
  getWhiteLabelPackagesDomain(data) {
    let req = this.getReq('operators/packageList?status=1&limit=1000&page=1&back=0', data);
    return this.webService.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name getOperatorGeneratedCodeList
   *
   * @description
   * This method call API to get operator package generated code list for White Label
   **/
  getGeneratedCodeList(data) {
    let req = this.getReq('onMySites/getList', data);
    return this.webService.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name saveGeneratedCode
   *
   * @description
   * This method call API to get operator package generated code list for White Label
   **/
  saveGeneratedCode(data, token) {
    let req = this.postReq('onMySites/saveCode', data, token);
    return this.webService.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name deleteGeneratedCode
   *
   * @description
   * This method call API to get operator package generated code list for White Label
   **/
  deleteGeneratedCode(data, token) {
    let req = this.deleteReq('onMySites/deleteCode', data, token);
    return this.webService.httpReq(req);
  }
  /**
    * @ngdoc method
    * @name saveFullPageData
    *
    * @description
    * SAVE FULL PAGE DATA
    **/
  saveFullPageData(data, file, token) {
    let req: any = {};
    if (data.id) {
      req.url = 'operator-sites/edit/' + data.id;
    } else {
      req.url = 'operator-sites/add';
    }

    if (file) {
      const config = {
        url: this.REQUEST_URL + req.url,
        fields: data,
        file: file,
        fileFormDataName: 'page_logo',
        headers: {
          'token': token
        }
      }
      return this.webService.angularUploadFile(config);
      // file.upload = this.$upload.upload({
      // 	/* using angualr file upload service */
      // 	url: this.REQUEST_URL + req.url,
      // 	method: 'POST',
      // 	fields: data,
      // 	file: file,
      // 	fileFormDataName: 'page_logo',
      // 	headers: {
      // 		'Content-Type': 'application/x-www-form-urlencoded',
      // 		'token': token
      // 	}
      // });
      // let promise = file.upload.progress((evt) => { }).success((result) => {
      // 	return result;
      // }).error((error) => {
      // 	/* If service is not successfull then show error */
      // 	return error;
      // });
      // return promise;
    } else {
      req = this.postReq(req.url, data, token)
      return this.webService.httpReq(req);
    }
  }

  /**
   * @ngdoc method
   * @name getFullPageSiteData
   *
   * @description
   * get Page Setup Data
   **/
  getFullPageSiteData(token) {
    let req = this.getReq('operator-sites/view', token);
    return this.webService.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name getOperatorSiteList
   *
   * @description
   * get Sites List
   **/
  getOperatorSiteList(token) {
    let req = this.getReq('operator-sites/get-adventure-site', token);
    return this.webService.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name switchViewType
   *
   * @description
   * get Sites List
   **/
  switchViewType(token) {
    let req = this.deleteReq('operator-sites/switch-site-type', null, token);
    return this.webService.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name addPackageToFullPage
   *
   * @description
   * save package to full page
   **/
  addPackageToFullPage(data, token) {
    let req = this.postReq('operator-sites/add-adventure-site', data, token);
    return this.webService.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name deletePackageFromFullPage
   *
   * @description
   * delete a package from full page
   **/
  deletePackageFromFullPage(packageId, token) {
    let req = this.deleteReq('operator-sites/delete-adventure-site/' + packageId, null, token);
    return this.webService.httpReq(req);
  }
  /**
  * @ngdoc method
  * @name deletePackageFromFullPage
  *
  * @description
  * delete a package from full page
  **/
  apiToUpdateDomain(data, token) {
    let req = this.postReq('operator-sites/add-adventure-site', data, token);
    return this.webService.httpReq(req);
  }

  sendEmailVerification(data, token) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'operator-sites/verify-operator-email',
      data: data,
      headers: {
        'token': token
      }
    };
    return this.webService.httpReq(req);
  }
}

angular.module('heliApp').service('onMySiteService', downgradeInjectable(OnMySiteService));