'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:AgentOperatorCtrl
 * @description
 * Controller of the heliApp for managing the operator reqiest and inventaries
 */
// eslint-disable-next-line no-undef
angular.module('heliApp').controller('agentOperatorsCtrl',[
  '$scope', 
  '$rootScope', 
  '$timeout', 
  'agentService', 
  'CONST_HELPER', 
  function ($scope, $rootScope, $timeout, agentService, CONST_HELPER) {
  $scope.activeAgentTab = '1';
  $scope.requestCount = 0;
  getAgentOperators($scope.activeAgentTab);
  // For Requests Data
  getAgentOperators('4');
  $scope.changeTab = function (tabNumber) {
    $scope.activeAgentTab = tabNumber;
    $scope.sortValue = '';
    $scope.reverse = '';
    getAgentOperators($scope.activeAgentTab);
    // For Requests Data
    getAgentOperators('4');
  };
  $scope.searchText = {};
  $scope.searchText.text = '';
  $scope.operatorList = [];
  $scope.noRecordFound = false;
  $scope.isSearchTextFocused = false;
  $scope.page = 1;
  var listLimit = 24;
  $scope.selectedOperator = {};
  $scope.addguestFormIsNotValid = false;
  $scope.operatorIds = [];
  $scope.sortValue = '';
  $scope.reverse = '';
  getOperatorList();
  $scope.loadMore = function () {
    $scope.page = Number($scope.page) + 1;
    console.log('tag', $scope.selectedOperator);
    // eslint-disable-next-line no-self-assign
    getOperatorList();
  };
  $scope.searchOperator = function (query) {
    if (query.length >= 2 || query.length == 0) {
      $scope.operatorList = [];
      $scope.page = 1;
      $scope.showLoader();
      getOperatorList();
    }
  };
  $scope.applySort = function (key, value) {
    $scope.sortValue = key;
    $scope.reverse = value;
  }
  $scope.onChange = function () {
    $scope.operatorIds = Object.keys($scope.selectedOperator).filter(function (key) { return $scope.selectedOperator[key] == true; });
  };
  function getAgentOperators (status) {
    var requestObject = {
      status: status
    };
    agentService.getAgentOperators(requestObject, $scope.user.token).then(function (response) {
      console.log('tag', response);
      if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
        $scope.operators = response.data.data;
        $scope.agentAllOperators = response.data.data;
        if (status == 4) {
          $scope.requestCount = $scope.operators.length;
        }

      } else if (response.data.code === CONST_HELPER.API_RESPONSE.NOT_FOUND) {
        $scope.operators = [];
      } else {
        $scope.showPopup('#serverErrModal');
      }
    });
  }
  $scope.acceptAgentRequestFunc = function (agentRequest) {
    $scope.modalData = agentRequest;
    $scope.showPopup('#accept-request-modal');
  };
  $scope.acceptAgentRequest = function () {
    var requestObject = {
      'id': $scope.modalData.id,
      'status_id': CONST_HELPER.AGENT_REQUEST_STATUS.ACCEPT
    };
    agentService.changeOperatorRequestStatus(requestObject, $scope.user.token).then(function (response) {
      console.log('tag', response);
      if (response.data.code == CONST_HELPER.API_RESPONSE.UNAUTHERIZED) {
        $scope.modalData = {};
        $scope.showPopup('#serverErrModal');
        $scope.hidePopup('#accept-request-modal');
      } else if (response.data.code == CONST_HELPER.API_RESPONSE.OK) {
        $scope.modalData = {};
        $scope.hidePopup('#accept-request-modal');
        getAgentOperators($scope.activeAgentTab);
      } else {
        $scope.showPopup('#serverErrModal');
      }
    });
  };
  $scope.rejectAgentRequestFunc = function (agentRequest) {
    $scope.modalData = agentRequest;
    $scope.showPopup('#reject-request-modal');
  };
  $scope.rejectAgentRequest = function () {
    var requestObject = {
      'id': $scope.modalData.id,
      'status_id': CONST_HELPER.AGENT_REQUEST_STATUS.REJECT
    };
    agentService.changeOperatorRequestStatus(requestObject, $scope.user.token).then(function (response) {
      console.log('tag', response);
      if (response.data.code == CONST_HELPER.API_RESPONSE.UNAUTHERIZED) {
        $scope.modalData = {};
        $scope.showPopup('#serverErrModal');
        $scope.hidePopup('#reject-request-modal');
      } else if (response.data.code == CONST_HELPER.API_RESPONSE.OK) {
        $scope.modalData = {};
        $scope.hidePopup('#reject-request-modal');
        getAgentOperators($scope.activeAgentTab);
      } else {
        $scope.showPopup('#serverErrModal');
      }
    });
  };
  $scope.closeAgentRequestRejectPopup = function () {
    $scope.hidePopup('#reject-request-modal');
  };

  $scope.cancelAgentRequestFunc = function (agentRequest) {
    $scope.modalData = agentRequest;
    $scope.showPopup('#cancel-request-modal');
  };
  $scope.cancelAgentRequest = function () {
    var requestObject = {
      'id': $scope.modalData.id,
      'status_id': CONST_HELPER.AGENT_REQUEST_STATUS.CANCEL
    };
    agentService.changeOperatorRequestStatus(requestObject, $scope.user.token).then(function (response) {
      console.log('tag', response);
      if (response.data.code == CONST_HELPER.API_RESPONSE.UNAUTHERIZED) {
        $scope.modalData = {};
        $scope.showPopup('#serverErrModal');
        $scope.hidePopup('#cancel-request-modal');
      } else {
        $scope.modalData = {};
        $scope.hidePopup('#cancel-request-modal');
        getAgentOperators($scope.activeAgentTab);
      }
    });
  };
  $scope.closeAgentRequestCancelPopup = function () {
    $scope.hidePopup('#cancel-request-modal');
  };
  $scope.closeAgentRequestAcceptPopup = function () {
    $scope.hidePopup('#accept-request-modal');
  };
  $scope.closeAgentRequestRejectPopup = function () {
    $scope.hidePopup('#reject-request-modal');
  };
  $scope.onChange = function () {
    $scope.operatorIds = Object.keys($scope.selectedOperator).filter(function (key) { return $scope.selectedOperator[key] == true; });
  };
  $scope.sendAgentRequest = function () {
    if ($scope.operatorIds.length < 1) {
      $scope.addguestFormIsNotValid = true;
      return;
    }
    $scope.showLoader();
    $scope.operatorIds = Object.keys($scope.selectedOperator).filter(function (key) { return $scope.selectedOperator[key] == true; });
    var requestObject = {
      email: $scope.user.email,
      operator_ids: $scope.operatorIds
    };
    agentService.agentSignUpRequest(requestObject, $scope.user.token).then(function (response) {
      console.log('tag', response);
      if (response.data.code == '200') {
        $scope.selectedOperator = {};
        $scope.operatorIds = [];
        $scope.operatorList = [];
        getOperatorList();
        $scope.hideLoader();
      } else if (response.data.code == '409') {
        $scope.hideLoader();
        $rootScope.errorAlert = response.data.message.email.email;
        $scope.showPopup('#AlertModal');
      } else {
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      }
      $scope.addguestFormIsNotValid = false;
    });
  };
  function getOperatorList () {
    $scope.showLoader();
    var requestObject = {

      'page': $scope.page,
      'search': $scope.searchText.text,
      'limit': listLimit
      // "search":  tempSearchValue ?  tempSearchValue : query || '',
    };
    agentService.allOperators(requestObject, $scope.user.token).then(function (response) { // call opearot listing api
      if (response.data.code === '200') {
        $scope.noRecordFound = false;
        $scope.operatorList = $scope.operatorList.concat(response.data.data);
        $scope.totalOperators = response.data.count;
        // more operator Shown on operator list Page
        $scope.isLoadMoreButton = ((response.data.count - $scope.operatorList.length) > 0);
        $scope.resCount = response.data.count;
        $timeout(function () { // scroll to previous position.
          if ($rootScope.operatorOffset > 500 && $rootScope.previousState && ($rootScope.previousState.name === 'myprofile' || $rootScope.previousState.name === 'signin')) {
            angular.element('html, body').animate({ scrollTop: $rootScope.operatorOffset }, 1000);
          }
        }, 10);
        $scope.hideLoader();
      } else if (response.data.code === '404') {
        $scope.isLoadMoreButton = false;
        $scope.operatorList = [];
        $scope.totalOperators = 0;
        $scope.noRecordFound = true;
        $scope.hideLoader();
      }
    }, function () {
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
  };
}]);
