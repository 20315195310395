import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonials',
  templateUrl: '/ng-app/src/app/components/home/testimonials/testimonials.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/home/testimonials/testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {

  playHomeVideo = false;

  constructor() { }

  ngOnInit(): void {
  }

  playVideo = () => {
    this.playHomeVideo = true;
  };

}
