import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name OperatorViewBookingService
 * @description
 * # operatorViewBookingService
 * Service in the heliApp.
 */

@Injectable()

export class OperatorViewBookingService {

  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  sendReq(method) {
    return new Promise((resolve, reject) => {
      method.then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })/* asynchronously complete request */
  }

  /**
    * @ngdoc method
    * @name get booking package detaiil
    *
    * @description
    * This method call API to fetch booking package detail
**/
  getBookingPackageDetailReq(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'bookings/view?package_id=' + data.package_id + '&slot_id=' + data.slot_id + '&page=' + data.page,
      headers: {
        'token': data.token
      },
      data: data
    };
    return this.webService.httpReq(req);
  }

  /**
    * @ngdoc method
    * @name post booking package detaiil
    *
    * @description
    * This method call API to fetch booking package detail
**/
  getViewBookingDetailReq(data) {
    if (data.package_ids.length == 0) { data.package_ids = ''; }
    console.log('data', data);
    let Url;
    if (data.package_id) {
      Url = this.REQUEST_URL + 'bookings/view?page=' + data.page + '&package_id=' + data.package_id + '&booking_source=' + data.booking_source + '&booking_status=' + data.booking_status + '&operator_id=' + data.op_id + '&agent_id=' + data.agent_id + '&all_agents=' + data.all_agents;
    } else {
      Url = this.REQUEST_URL + 'bookings/view?page=' + data.page + '&booking_source=' + data.booking_source + '&location_id=' + data.location_id + '&booking_status=' + data.booking_status + '&operator_id=' + data.op_id + '&agent_id=' + data.agent_id + '&all_agents=' + data.all_agents;
    }
    let req = {
      method: 'POST',
      url: Url,
      headers: {
        'token': data.token,
        'Content-Type': 'application/json'
      },
      data: data
    };
    return this.webService.httpReq(req);
  }

  /**
    * @ngdoc method
    * @name get view booking package slot detail
    *
    * @description
    * This method call API to fetch view booking package slot detail
**/
  getBookingPackageSlotDetailReq(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'bookings/view?package_id=' + data.package_id,
      headers: {
        'token': data.token
      },
      data: data
    };
    return this.webService.httpReq(req);
  }

  /**
    * @ngdoc method
    * @name get view booking package slot detail
    *
    * @description
    * This method call API to fetch view booking package slot detail
**/
  getPackageViewDetailReq(data) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'bookings/details?booking_slot_id=' + data.booking_slot_id,
      headers: {
        'token': data.token,
        'Content-Type': 'application/json'
      }
    };
    return this.webService.httpReq(req);
  }

  /**
    * @ngdoc method
    * @name request-to-cancel
    *
    * @description
    * This method call API to request-to-cancel package
**/
  ReqToCancelPackageSlot(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'bookings/request-to-cancel',
      headers: {
        'token': data.token
      },
      data: data
    };
    return this.webService.httpReq(req);
  }

  /**
    * @ngdoc method
    * @name updateIsDepositReq
    *
    * @description
    * This method call API to update the deposit state of booking
**/
  updateIsDepositReq(data) {
    let req = {
      method: 'PUT',
      url: this.REQUEST_URL + 'Guests/toggleDeposit',
      headers: {
        'token': data.token
      },
      data: data
    };
    return this.webService.httpReq(req);
  }

  /**
    * @ngdoc method
    * @name sendReminderEmailReq
    *
    * @description
    * This method call API to send user an reminder email to deposite the remaining payment
**/
  sendReminderEmailReq(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'payments/sendReminderEmail',
      headers: {
        'token': data.token
      },
      data: data
    };
    return this.webService.httpReq(req);
  }
  /**
    * @ngdoc method
    * @name sendReminderEmailReq
    *
    * @description
    * This method call API to send user an reminder email to deposite the remaining payment
**/
  changePaymentStatusReq(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'bookings/make-payment-received',
      headers: {
        'token': data.token
      },
      data: data
    };
    return this.webService.httpReq(req);
  }
  /**
    * @ngdoc method
    * @name sendReminderEmailReq
    *
    * @description
    * This method call API to add and edit agent payout.
**/
  addManualPayoutAgentReq(data, token) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'bookings/manualPayouts',
      headers: {
        'token': token
      },
      data: data
    };
    return this.webService.httpReq(req);
  }
  /**
    * @ngdoc method
    * @name deletePayoutAgentReq
    *
    * @description
    * This method call API to delete agent payout.
**/
  deletePayoutAgentReq(data, token) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'bookings/deleteManualPayouts',
      headers: {
        'token': token
      },
      data: data
    };
    return this.webService.httpReq(req);
  }
  getBookingPackageDetail(data) { // get Booking Package Detail
    return this.sendReq(this.getBookingPackageDetailReq(data));
  }
  getViewBookingDetail(data) { // get View Booking Detail
    return this.sendReq(this.getViewBookingDetailReq(data));
  }

  getBookingPackageSlotDetail(data) { // get Booking Package Slot Detail
    return this.sendReq(this.getBookingPackageSlotDetailReq(data));
  }

  getPackageViewDetail(data) { // get Package View Detail
    return this.sendReq(this.getPackageViewDetailReq(data));
  }

  ReqToCancelBookingSlot(data) { // Req To Cancel Booking Slot
    return this.sendReq(this.ReqToCancelPackageSlot(data));
  }
  updateIsDeposit(data) { // mark as recieved deposit amount...
    return this.sendReq(this.updateIsDepositReq(data));
  }
  sendReminderEmail(data) { // send user an reminder email to deposite the remaining payment
    return this.sendReq(this.sendReminderEmailReq(data));
  }
  changePaymentStatus(data) { // changed the payment status detail if operator collect due amount offline...
    return this.sendReq(this.changePaymentStatusReq(data));
  }
  addManualPayoutAgent(data, token) { // changed the payment status detail if operator collect due amount offline...
    return this.sendReq(this.addManualPayoutAgentReq(data, token));
  }
  deletePayoutAgent(data, token) { // changed the payment status detail if operator collect due amount offline...
    return this.sendReq(this.deletePayoutAgentReq(data, token));
  }

  getAllCountry(token) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'masters/all-country',
      headers: {
        'token': token,
        'Content-Type': 'application/json'
      }
    };
    return this.webService.httpReq(req);
  }

  exportCsv(token, params) {
    return this.webService.httpReq({
      method: 'GET',
      url: this.REQUEST_URL + 'bookings/view',
      params: params,
      responseType: 'blob',
      headers: {
        token: token,
        Accept: 'text/csv'
      }
    });
  }
}

angular.module('heliApp').service('operatorViewBookingService', downgradeInjectable(OperatorViewBookingService));
