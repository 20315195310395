import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';

/**
 * @ngdoc service
 * @name ScriptLoaderService
 * @description
 * # scriptLoaderService
 * Service in the heliApp.
 */

@Injectable()

export class ScriptLoaderService {
  
  constructor() { }

  /**
   * @param src {string}
   * @param forceLoading {boolean}
   * @returns {Promise}
   */
  load = (src, forceLoading) => {
    return new Promise((resolve, reject) => {
      let loadedScript;
      if (!forceLoading) {
        loadedScript = this.find(src);
      }

      if (forceLoading || !loadedScript) {
        let script = document.createElement('script');
        script.src = src;
        script.onload = function () {
          resolve(script);
        };

        script.onerror = function () {
          reject('Error occurred while loading script ' + src);
        };

        document.body.append(script);
      } else {
        resolve(loadedScript);
      }
    })
  };

  /**
   * @param src {string}
   * @returns {boolean}
   */
  find = (src) => {
    return (angular.element(document).find('script[src="' + src + '"]') as any).get(0);
  };

}

angular.module('heliApp').service('scriptLoaderService', downgradeInjectable(ScriptLoaderService));
