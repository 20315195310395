//Modules - Jquery
import '../bower_components/jquery/dist/jquery.min.js';
import '../bower_components/jquery-ui/jquery-ui.min.js';

//Modules - Angular 
import 'angular';
import 'angular-mocks';
import 'angular-resource';
import 'angular-cookies';
import 'angular-sanitize';
import 'angular-route';

//Modules - Custom scripts
import './scripts/js/ng-infinite-scroll.js';
import './scripts/js/ng-tags-input.js';
import './scripts/js/angular-file-upload.js';
import './scripts/js/circularProgress.js';
import './scripts/js/angularjs-social-login.js';
import './scripts/js/text-truncate.js';
import './scripts/js/ui.calendar.js';

//Modules - Bower-modules
import '../bower_components/ngWYSIWYG/dist/wysiwyg.min.js';
// import '../bower_components/angular-ui-router/release/angular-ui-router.min.js';
import '@uirouter/angular-hybrid';
import '../bower_components/bootstrap/dist/js/bootstrap.min.js';
import '../bower_components/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import '../bower_components/vs-google-autocomplete/dist/vs-google-autocomplete.js';
// import '../bower_components/ngmap/build/scripts/ng-map.min.js';
import 'ngmap';
import '../bower_components/angular-messages/angular-messages.min.js';
import '../bower_components/angular-ui-select/dist/select.min.js';
import '../bower_components/ng-simple-parallax/src/ngParallax.min.js';
import '../bower_components/angular-img-cropper/dist/angular-img-cropper.min.js';
import '../bower_components/angular-cookies/angular-cookies.min.js';
import '../bower_components/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min.js';
import '../bower_components/ng-scrollbars/dist/scrollbars.min.js';
import '../bower_components/angular-payments/lib/angular-payments.min.js';
import '../bower_components/angular-scroll/angular-scroll.min.js';
import '../bower_components/ng-parallax/angular-parallax.min.js';
import '../bower_components/ng-csv/build/ng-csv.min.js';
import '../bower_components/angular-bootstrap/ui-bootstrap-tpls.min.js';
import '../bower_components/ui-cropper/compile/minified/ui-cropper.js';
import '../bower_components/slick-carousel/slick/slick.min.js';
import '../bower_components/angular-slick-carousel/dist/angular-slick.min.js';
import '../bower_components/angular-stripe/release/angular-stripe.js';
import '../bower_components/chart.js/dist/Chart.min.js';
import '../bower_components/angular-chart.js/dist/angular-chart.min.js';
import '../bower_components/angular-ui-sortable/sortable.js';
import '../bower_components/jqueryui-touch-punch/jquery.ui.touch-punch.min.js';
import '../bower_components/angular-bootstrap-colorpicker/js/bootstrap-colorpicker-module.min.js';
// import '../bower_components/ckeditor/ckeditor.js';
import 'ckeditor';
// import '../bower_components/ng-ckeditor/dist/ng-ckeditor.min.js';
import 'ng-ckeditor';
import '../bower_components/bootstrap-daterangepicker/daterangepicker.js';
import '../bower_components/angular-daterangepicker/js/angular-daterangepicker.min.js';
// import '../bower_components/fullcalendar/dist/fullcalendar.js';
// import '../bower_components/fullcalendar/dist/gcal.js';
import 'fullcalendar';
import '../bower_components/ng-rollbar/ng-rollbar.min.js';
import '../bower_components/angularjs-dropdown-multiselect/dist/angularjs-dropdown-multiselect.min.js';
import '@uirouter/angularjs/lib/legacy/stateEvents.js';

//APP
import './scripts/app.js';
import './scripts/app.constant';
import './scripts/app.messageConstant';

//Main modules
import './scripts/controllers';
import './scripts/directives';
import './scripts/filters';
import './scripts/services';
import './polyfills';

//Routes
import './scripts/app.route';

//Bootstraping AngularJs on Angular
import { NgZone } from '@angular/core';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UrlService } from '@uirouter/core';
import { getUIRouter } from '@uirouter/angular-hybrid';
import { HeliAppModuleAngular } from "./app.module.upgrade";

platformBrowserDynamic().bootstrapModule(HeliAppModuleAngular).then(platformRef => {

  // Initialize the Angular Module
  // get() the UIRouter instance from DI to initialize the router
  const url: UrlService = getUIRouter(platformRef.injector).urlService;

  // Instruct UIRouter to listen to URL changes
  function startUIRouter() {
    url.listen();
    url.sync();
  }

  platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);
});