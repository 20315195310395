import { Component } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { UIRouter } from '@uirouter/core';
import * as angular from 'angular';
import { RootScopeService } from '../../services/root-scope/root-scope.service';

@Component({
    selector: 'left-nav',
    templateUrl: '/ng-app/src/app/components/left-nav/left-nav.component.html',
})

export class LeftNavComponent {

    constructor(
        public router: UIRouter,
        public rootScopeService: RootScopeService
    ) {
    }

    newPublicFeeds = () => { // broadcast to fetch new feeds
        this.rootScopeService.rootScope.feedsCount = 0; // reset feed count
    };
}

angular.module('heliApp').directive('leftNav', downgradeComponent({component: LeftNavComponent}));