/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc filter
 * @name heliApp.commonFilters
 * @description
 * # list all common customs filtes
 * Filter in the heliApp.
 */
angular.module('heliApp')
  .filter('convertCurrency', function () {
    return function (amount, currencyRate) {
      return amount * currencyRate || 0;
    };
  })
  .filter('toMinSec', function () {
    return function (input) {
      var minutes = parseInt(input / 60, 10);
      var seconds = input % 60;

      return minutes + ':' + (seconds > 9 ? seconds : '0' + seconds);
    };
  })
  .filter('uppercaseFirstLetter', function () {
    return function (input) {
      return input.charAt(0).toUpperCase() + input.slice(1);
    };
  });
