/* eslint-disable no-undef */
/* eslint-disable camelcase */
'use strict';
/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:operatorCalendarCtrl
 * @description
 * # operatorCalendarCtrl for opeartor calendar dashboard.
 * Controller of the heliApp
 */
angular.module('heliApp')
  .controller('operatorEditSlotCtrl', [
    '$scope',
    '$rootScope',
    '$stateParams',
    '$state',
    '$timeout',
    '$location',
    'operatorCalendarService',
    '$window',
    '$filter',
    'operatorPackageListService',
    'userAdventureDetailService',
    'CONST_HELPER',
    '$cookies',
    'STRIPE_KEY',
    'userOrdersListService',
    'userSocialProfileService',
    'agentService',
    'paymentService',
    'createAdventureService',
    'taxesService',
    function (
      $scope,
      $rootScope,
      $stateParams,
      $state,
      $timeout,
      $location,
      operatorCalendarService,
      $window,
      $filter,
      operatorPackageListService,
      userAdventureDetailService,
      CONST_HELPER,
      $cookies,
      STRIPE_KEY,
      userOrdersListService,
      userSocialProfileService,
      agentService,
      paymentService,
      createAdventureService,
      taxesService
    ) {
      var vm = this;
      var slotObj = {};
      if ($stateParams.slot_id) {
        slotObj = {
          'slot_id': $stateParams.slot_id,
          'token': $scope.user.token
        };
      } else if ($stateParams.nightly_slot_id) {
        slotObj = {
          'slot_id': $stateParams.nightly_slot_id,
          'from_date': moment(new Date(parseInt($stateParams.start_date))).format('YYYY-MM-DD'),
          'to_date': moment(new Date(parseInt($stateParams.end_date))).format('YYYY-MM-DD'),
          'token': $scope.user.token
        };
      }
      $scope.booking_type = $stateParams.booking_type ? $stateParams.booking_type : CONST_HELPER.ONE;
      $('#widget').draggable();
      $scope.screenWidth = screen.width;
      $scope.homeRedirection(); // check for authorised access
      var package_slot_id = $stateParams.slot_id;
      var nightly_slot_id = $stateParams.nightly_slot_id;

      var token = $scope.user.token;
      $scope.textLimit = 85;
      $scope.operatorManualBooking = false;
      $scope.manualBooking = {};
      $scope.suggestedRetailPrice = CONST_HELPER.ZERO;
      $scope.bookingWindowRange = {};
      $scope.bookingWindowRange.start = new Date(+$state.params.start_date);
      $scope.bookingWindowRange.end = new Date(+$state.params.end_date);

      $scope.package_id = null;
      $scope.countries = [];

      $scope.showLoader();
      createAdventureService
        .getAllListCountry()
        .then(function (response) { // fetch from the service
          if (response && response.data.code === CONST_HELPER.API_RESPONSE.OK) {
            $scope.countries = response.data.data;
          }
        }, function () { // check for error
          $scope.showPopup('#serverErrModal');
        })
        .finally(function () {
          $scope.hideLoader();
        });

      function getDurationInDays(config) {
        var diffInDays = (+config.to - +config.from) / (1000 * 3600 * 24);
        if (diffInDays === 0) {
          // Duration of any adventure couldn't be 0, at least 1 day or night
          diffInDays = 1;
        } else if (!config.isNightly) {
          // For not-nightly slots we should calculate last day too
          diffInDays += 1;
        }

        return diffInDays;
      }

      function calculateTaxesSummaryPrice(config) {
        if (
          ($scope.manualGuest.price > 0)
          &&
          $scope.manualBooking.selectedSlotDuration
        ) {
          config = angular.extend({}, {
            taxes: $scope.operatorSlotData.package.operator_taxes,
            packagePrice: $scope.manualGuest.price,
            isDeposit: !!parseInt($scope.manualGuest.is_deposit),
            personsCount: parseInt($scope.manualGuest.total_guest),
            duration: getDurationInDays({
              from: $scope.manualBooking.selectedSlotDuration.from,
              to: $scope.manualBooking.selectedSlotDuration.to,
              isNightly: $stateParams.nightly_slot_id > 0,
            }),
            userCountryId: parseInt($scope.manualGuest.country_id)
          }, config);

          return taxesService.calculateTaxesSummaryPrice(config);
        }

        return 0.0;
      };

      $scope.changeCountry = function() {
        calculateTax();
      };

      /**
   * @ngdoc function
   * @name resetManualGuest
   * @description
   * # reset manual guest object on loading, save ,create and edit guest object...
   */
      function resetManualGuest () {
        $scope.manualGuest = {
          'package_slot_id': package_slot_id,
          'nightly_slot_id': nightly_slot_id,
          country_id: null,
          'name': '',
          'email': '',
          'notes': '',
          'is_deposit': '0', // Added below for saving lodging for add guest details
          'totalPrice': '',
          'deposit_price': '',
          'number_of_guest': '1',
          'total_guest': '1',
          'selectForEveryone': true
        };
        checkAndUpdateLodging();
        switch ($scope.operatorSlotData.package.package_case) {
          case 1 : $scope.updateLodgingWithGuestsCaseA();
            break;
          case 2 : $scope.updateLodgingWithGuestsCaseB();
            break;
        }
      }

      function setManualOperatorCommission () {
        if ($rootScope.currentState.name === 'operator-manual-booking' || $rootScope.currentState.name === 'operator-manual-booking-noslot' || $rootScope.currentState.name === 'manual-payment') {
          if($scope.operatorSlotData && $scope.operatorSlotData.operatorCommission.is_on_site_enabled == 1){
            $scope.operatorSlotData.commission = $scope.operatorSlotData.operatorCommission.on_site_commission;
          }else if($scope.operatorSlotData && $scope.operatorSlotData.operatorCommission.is_on_site_enabled == 0){
            $scope.operatorSlotData.commission = $scope.operatorSlotData.operatorCommission.amount;
          }
        }
      }

      $scope.addguestFormIsNotValid = false;
      $scope.selectedGuestError = false;
      $scope.groupFormValid = false;
      $scope.deleteManualGuestObj = {};
      $scope.emailPattern = /^([a-zA-Z0-9_.-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
      $scope.groupHasGuest = false;
      $scope.depositOptions = [{ 'id': '0', 'value': 'Full Payment' }, { 'id': '1', 'value': 'Deposit' }, { 'id': '2', 'value': 'Payment Pending' }]; // Add Guest Popup Deposit options
      $scope.depositOptionsManualPayment = [{ 'id': '0', 'value': 'Full Payment' }, { 'id': '1', 'value': 'Deposit' }]; // Add Guest Popup Deposit options
      $scope.depositOptionsvalue = ['Full Payment', 'Deposit', 'Payment Pending'];
      $scope.changeDepositType = function () {
        $scope.manualGuest.deposit_price = '';
        $scope.manualBooking.subTotal = $scope.manualGuest.is_deposit === '1' ? CONST_HELPER.ZERO : $scope.manualGuest.price;
      };
      $scope.updateGroupHasGuest = function () {
        $scope.groupHasGuest = true;
      };

      $('.slot-start-date').datepicker({ minDate: new Date() });
      $('.datepicker.start').datepicker('update', $scope.bookingWindowRange.start).prop('readonly', true);
      $('.datepicker.end').datepicker('update', $scope.bookingWindowRange.end).prop('readonly', true);

      // Refresh selectpicker values on dynamic change
      $scope.refreshSelectpicker = function () {
        $timeout(function () {
          $('.selectpicker').selectpicker('refresh');
        }, 0);
      };

      function convertDateToCorrectTimeZone (date) {
        return moment(new Date(date).setMinutes(new Date(date).getMinutes() + new Date().getTimezoneOffset())).format('MM-DD-YYYY');
      }

      function checkForFlywirePayment(operatorSlotData) {
        for (var i = 0; i < operatorSlotData.guests.length; ++i) {
          if (operatorSlotData.guests[i].booking_slot_detail && operatorSlotData.guests[i].booking_slot_detail.payment_detail && operatorSlotData.guests[i].booking_slot_detail.payment_detail.payment && !!operatorSlotData.guests[i].booking_slot_detail.payment_detail.payment.flywire_reference) {
            var flywire_payment_status = 'Payment Pending';
            if (operatorSlotData.guests[i].booking_slot_detail.payment_detail.payment_status != 0) {
              if (operatorSlotData.guests[i].booking_slot_detail.payment_detail.payment_status == 3) {
                flywire_payment_status = 'Cancelled';
              } else {
                if (operatorSlotData.guests[i].hasOwnProperty('is_deposit') && operatorSlotData.guests[i].is_deposit) {
                  flywire_payment_status = 'Deposit';
                } else {
                  flywire_payment_status = 'Full Payment';
                }
              }
            }
            operatorSlotData.guests[i].booking_slot_detail.payment_detail.flywire_payment_status = flywire_payment_status;
          }
        }
      }

      function checkDepartureOrArrivingStatus (operatorSlotData) {
        if (
          (
            operatorSlotData.package.daily_rate_activity == 1
            ||
            (
              operatorSlotData.package.booking_type == 2
              &&
              operatorSlotData.package.daily_rate_activity == 0
              &&
              operatorSlotData.package.single_day_activity == 0
            )
          )
          &&
          moment(new Date(parseInt($stateParams.start_date))).format('MM-DD-YYYY') == moment(new Date(parseInt($stateParams.end_date))).format('MM-DD-YYYY')
        ) {
          if (operatorSlotData.guests && operatorSlotData.guests.length) {
            operatorSlotData.guests.forEach(function (guest) {
              guest.from_date = convertDateToCorrectTimeZone(guest.from_date);
              guest.to_date = convertDateToCorrectTimeZone(guest.to_date);
              guest = returnStatusArriveOrDeparture(guest);
            });
          }

          if (operatorSlotData.groups && operatorSlotData.groups.length) {
            operatorSlotData.groups.forEach(function (group) {
              if (group.guests && group.guests.length) {
                group.guests.forEach(function(guest){
                  guest.from_date = convertDateToCorrectTimeZone(guest.from_date);
                  guest.to_date = convertDateToCorrectTimeZone(guest.to_date);
                  guest = returnStatusArriveOrDeparture(guest);
                });
              }
            });
          }
        }
      }

      function returnStatusArriveOrDeparture (guest) {
        if (moment(new Date(parseInt($stateParams.start_date))).format('MM-DD-YYYY') == moment(guest.from_date).format('MM-DD-YYYY')) {
          guest.is_arriving = true;
        } else if (moment(new Date(parseInt($stateParams.start_date))).format('MM-DD-YYYY') == moment(guest.to_date).format('MM-DD-YYYY')) {
          guest.is_departure = true;
        }

        return guest;
      }

      /**
   * @ngdoc function
   * @name getSlotData
   * @description
   * # fetch the details group/guests and lodging of the package slot...
   */
      function getSlotData () {
        $scope.showLoader();
        operatorCalendarService.getSlotGuestData(slotObj).then(function (res) {
          if (res.data.code === '200') {
            // $rootScope.closeMyNav();  // initial hide side panel...
            $scope.operatorSlotData = res.data.data;
            checkForFlywirePayment($scope.operatorSlotData);
            setManualOperatorCommission();
            updateTotalBeds($scope.operatorSlotData.slot_lodgings); // update the total_beds count based on availabel units
            resetManualGuest(); // Initializing manual guest object for offline booking
            $scope.slotNote = angular.copy($scope.operatorSlotData.note);
            // checkAndUpdateLodging();//For price calculation in add guest popup
            /* check if slot is expired... */
            var slotStartDate = new Date($scope.operatorSlotData.from_date);
            var currentDate = new Date();
            var diffDays = Math.floor((Date.UTC(slotStartDate.getFullYear(), slotStartDate.getMonth(), slotStartDate.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
            if (diffDays < 0) { // if slot expired
              $scope.operatorSlotData.expired = true;
            } else {
              $scope.operatorSlotData.expired = false;
            }

            /* store local currrency code and usd rate... */
            $scope.manualBooking.local_currency_code = angular.copy($scope.operatorSlotData.package.currency.code);
            $rootScope.selectedCurrencyCode = $scope.manualBooking.local_currency_code;
            $scope.cc_rate_usd = $scope.operatorSlotData.package.currency.cc_rate_usd;

            // MANUAL BOOKING INITIALIZATIONS
            if ($scope.operatorManualBooking) {
              if ($stateParams.order_id) {
                setupRemainingPayment(function () {
                  CalculateSuggestedRetailPrice();
                });
              } else {
                $scope.hideLoader();
                $scope.addonsForEveryone();
                $scope.manualBooking.subTotal = CONST_HELPER.ZERO;
              }
              $scope.packageCurrencyCodeRate = CONST_HELPER.ONE;
              $scope.packagePrevCurrencyRate = CONST_HELPER.ONE;
              $scope.refreshSelectpicker();
            } else {
              $scope.hideLoader();
            }
          } else if (res.data.code === '203') {
            $scope.logout();
            $scope.hideLoader();
          }
        }, function (error) {
          console.log(error);
        });
      }
      // function for getting nightly slot guest data
      function getNightlySlotData () {
        $scope.showLoader();
        operatorCalendarService.getNightlySlotGuestData(slotObj).then(function (res) {
          if (res.data.code === '200') {
            // $rootScope.closeMyNav();  // initial hide side panel...
            $scope.operatorSlotData = res.data.data;
            $scope.operatorSlotData.from_date = convertDateToCorrectTimeZone(parseInt($stateParams.start_date));
            $scope.operatorSlotData.to_date = convertDateToCorrectTimeZone(parseInt($stateParams.end_date));
            checkForFlywirePayment($scope.operatorSlotData);
            checkDepartureOrArrivingStatus($scope.operatorSlotData);
            setManualOperatorCommission();
            formatNightlySlotLodging($scope.operatorSlotData);
            resetManualGuest(); // Initializing manual guest object for offline booking
            $scope.slotNote = angular.copy($scope.operatorSlotData.note);
            // checkAndUpdateLodging();//For price calculation in add guest popup
            /* check if slot is expired... */
            var slotStartDate = new Date($scope.operatorSlotData.from_date);
            var currentDate = new Date();
            var diffDays = Math.floor((Date.UTC(slotStartDate.getFullYear(), slotStartDate.getMonth(), slotStartDate.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
            if (diffDays < 0) { // if slot expired
              $scope.operatorSlotData.expired = true;
            } else {
              $scope.operatorSlotData.expired = false;
            }

            /* store local currrency code and usd rate... */
            $scope.manualBooking.local_currency_code = angular.copy($scope.operatorSlotData.package.currency.code);
            $rootScope.selectedCurrencyCode = $scope.manualBooking.local_currency_code;
            $scope.cc_rate_usd = $scope.operatorSlotData.package.currency.cc_rate_usd;

            // MANUAL BOOKING INITIALIZATIONS
            if ($scope.operatorManualBooking) {
              if ($stateParams.order_id) {
                setupRemainingPayment(function () {
                  CalculateSuggestedRetailPrice();
                });
              } else {
                $scope.hideLoader();
                $scope.addonsForEveryone();
                $scope.manualBooking.subTotal = CONST_HELPER.ZERO;
              }
              $scope.packageCurrencyCodeRate = CONST_HELPER.ONE;
              $scope.packagePrevCurrencyRate = CONST_HELPER.ONE;
              $scope.refreshSelectpicker();
            } else {
              $scope.hideLoader();
            }
          } else if (res.data.code === '203') {
            $scope.logout();
            $scope.hideLoader();
          }
        }, function (error) {
          console.log(error);
        });
      }

      /**
 * @ngdoc function
 * @name formatNightlySlotLodging
 * @description
 * # format nightly lodging data as it is in slot lodging...
 */
      var minSeatAvailable = 0; var max_capacity_caseB = 0; var available_room_caseB = 0;
      function formatNightlySlotLodging (slotDetails) {
        var slotNightlyLodging = [];
        max_capacity_caseB = 0;
        available_room_caseB = 0;
        minSeatAvailable = 0;
        if (slotDetails.lodging_id && slotDetails.package.package_case !== CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) { // if lodging is added...
          slotDetails.nightly_slots.map(function (nSlot) {
            // var nSlot = slotDetails;
            nSlot.calendar = JSON.parse(nSlot.calendar);
            // nSlot.calendar = [nSlot.calendar['43']];
            setAvailableBeds(nSlot);
            var obj = {
              available_units: getMinimumCount('available_room', nSlot.calendar),
              calendar: nSlot.calendar,
              id: nSlot.id,
              lodging: nSlot.lodging,
              lodging_id: nSlot.lodging_id,
              price: nSlot.price,
              private_price: nSlot.private_price,
              total_beds: getMinimumCount('total_bed', nSlot.calendar, nSlot.lodging),
              usd_price: nSlot.usd_price,
              usd_private_price: nSlot.usd_private_price
            };
            vm.adultRange = nSlot.minSeatAvailable;
            max_capacity_caseB += nSlot.max_capacity;
            available_room_caseB += +getMinimumCount('available_room', nSlot.calendar);
            slotNightlyLodging.push(obj);
          });
          slotDetails.slot_lodgings = slotNightlyLodging;
          if ($scope.operatorSlotData.package.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
            $scope.operatorSlotData.max_capacity = max_capacity_caseB;
            $scope.operatorSlotData.available_room = available_room_caseB;
          }
        } else { // if no lodging
          var minimumSeatAvailable = 0; var price = 0;
          slotDetails.nightly_slots.map(function (nSlot) {
            nSlot.calendar = JSON.parse(nSlot.calendar);
            nSlot.calendar.map(function (slotCalendar) {
              minimumSeatAvailable = minimumSeatAvailable ? Math.min(minimumSeatAvailable, slotCalendar.available_seat) : slotCalendar.available_seat;
              price += parseInt(slotCalendar.price);
            });
          });
          vm.adultRange = minimumSeatAvailable;
          slotDetails.slot_lodgings = [];
          slotDetails.adult_price = price;
          slotDetails.usd_adult_price = price;
        }
        slotDetails.available_seat = vm.adultRange;
      }

      function getMinimumCount (prop, obj, lodging) {
        var min = 0;
        obj.map(function (val) {
          if (prop == 'total_bed') {
            val[prop] = val.available_room * lodging.max_guests;
          }
          if (!min || min > val[prop]) {
            min = val[prop];
          }
        });
        return min;
      }

      function setAvailableBeds (nightly_slot) {
        nightly_slot.calendar.map(function (slotCalendar) {
          slotCalendar.total_bed = slotCalendar.total_bed || (slotCalendar.available_room * nightly_slot.lodging.max_guests);
          if ($scope.operatorSlotData.package.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
            minSeatAvailable = minSeatAvailable + +slotCalendar.available_room;
          } else {
            minSeatAvailable = minSeatAvailable ? Math.min(minSeatAvailable, slotCalendar.available_seat) : slotCalendar.available_seat;
          }
        });
        nightly_slot.minSeatAvailable = minSeatAvailable;
      }

      function updateTotalBeds (slot_lodging) {
        if (slot_lodging && slot_lodging.length) {
          slot_lodging.map(function (sl) {
            if (sl.total_beds <= 0) {
              sl.available_units = 0;
              sl.total_beds = 0;
            } else {
              sl.available_units = Math.ceil(sl.total_beds / sl.lodging.max_guests);
            }
          });
        }
      }

      function getCurrencyList (argument) {
        // get total currency list...
        userAdventureDetailService.getCurrency().then(function (res) {
          if (res.data.code === '200') {
            $scope.Currencies = res.data.data;
            $scope.Currencies.map(function (val) {
              val.symbol = ' (' + $scope.currencySymbol[val.code] + ')';
            });
          }
        }, function () {

        });
      }

      $scope.openManualBooking = function () {
        $state.go('operator-manual-booking', { 'nightly_slot_id': $scope.operatorSlotData.id,
          package_id: $scope.operatorSlotData.package.id,
          start_date: $stateParams.start_date,
          end_date: $stateParams.end_date,
          booking_type: CONST_HELPER.TWO,
          op_id: $stateParams.op_id ? $stateParams.op_id : ''
        });
      };

      // initial Methods calls
      $scope.order_id = $stateParams.order_id;
      if ($stateParams.slot_id) { getSlotData(); } else if ($stateParams.nightly_slot_id) { getNightlySlotData(); }

      // if operator is doing mannual bookings...
      if ($rootScope.currentState.name === 'operator-manual-booking' || $rootScope.currentState.name === 'operator-manual-booking-noslot' || $rootScope.currentState.name === 'manual-payment') {
        getCurrencyList();
        $scope.operatorManualBooking = true;

        // load stripe libraries...
        if (typeof Stripe === 'undefined') {
          $scope.loadScript('https://js.stripe.com/v2/', function () {
            $scope.loadScript('https://js.stripe.com/v3/', function () {
              window.Stripe.setPublishableKey(STRIPE_KEY);
              // Storing stripe object to global context for accessing it
              // outside of this callback
              window.stripe = Stripe(STRIPE_KEY, { locale: 'en' });
              var existingModule = angular.module('heliApp');
              existingModule.requires.push('angular-stripe');
              $rootScope.$broadcast('stripe-loaded');
            });
          });
        }
        // get the operator active package list...
        var requestObject = {
          'user_id': $scope.user.id,
          'page': CONST_HELPER.ONE,
          'limit': CONST_HELPER.HUNDRED,
          'back': CONST_HELPER.ZERO,
          'privateIncluded': CONST_HELPER.ONE
        };
        if (!$rootScope.isAgent) { // if not agent doing manual booking...
          userSocialProfileService.operatorPackageList(requestObject).then(function (response) { // call active package list api
            modifyPackages(response);
          }, function () {
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
        } else { // else agent doing manual booking...
          requestObject.status = 1;
          requestObject.type = 0;
          requestObject.location_id = 0;
          requestObject.operator_id = $stateParams.op_id ? $stateParams.op_id : '';
          requestObject.token = $scope.user.token;
          agentService.getAgentOperatorPackages(requestObject).then(function (response) { // call active package list api
            modifyPackages(response);
          }, function () {
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
        }

        $scope.$watch('manualGuest.total_guest', function (newv, oldv) {
          if (newv >= 1 && newv !== oldv && $scope.selectedSlotDetails.package) {
            $scope.manualGuest.number_of_guest = String($scope.manualGuest.total_guest);

            switch ($scope.selectedSlotDetails.package.package_case) {
              case CONST_HELPER.PACKAGE_CASE.PER_SEAT:
                newv = parseInt(newv);
                oldv = oldv ? parseInt(oldv) : CONST_HELPER.ZERO;
                var diff = newv - oldv;
                var alreadySelectedRooms = vm.maximumRooms - vm.remainingRooms;
                vm.maximumRooms = angular.copy(newv);
                if (diff < CONST_HELPER.ZERO) {
                  vm.remainingRooms = angular.copy(newv);
                } else {
                  vm.remainingRooms = newv - alreadySelectedRooms;
                }
                $scope.updateLodgingWithGuestsCaseA();
                break;
              case CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION:
                $scope.updateLodgingWithGuestsCaseB();
                break;
              case CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE:
                CalculateSuggestedRetailPrice();
                break;
            }
          }
        });
      } else {
        $scope.$watch('manualGuest.number_of_guest', function (newv, oldv) {
          if (parseInt(newv) >= 0 && newv !== oldv && $scope.selectedSlotDetails.package) {
            $scope.manualGuest.total_guest = String(parseInt($scope.manualGuest.number_of_guest));

            switch ($scope.selectedSlotDetails.package.package_case) {
              case CONST_HELPER.PACKAGE_CASE.PER_SEAT:
                newv = parseInt(newv);
                oldv = oldv ? parseInt(oldv) : CONST_HELPER.ZERO;
                var diff = newv - oldv;
                var alreadySelectedRooms = vm.maximumRooms - vm.remainingRooms;
                vm.maximumRooms = newv;
                if (diff < CONST_HELPER.ZERO) {
                  vm.remainingRooms = newv;
                } else {
                  vm.remainingRooms = newv - alreadySelectedRooms;
                }
                $scope.updateLodgingWithGuestsCaseA();
                break;
              case CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION:
                $scope.updateLodgingWithGuestsCaseB();
                break;
              case CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE:
                break;
            }
          }
        });
      }
      // function to update the operator packages...
      function modifyPackages (response) {
        if (response) {
          var packageData = [];
          if ($stateParams.booking_type == CONST_HELPER.TWO) {
            // filter the packages which has booking type 2...
            packageData = response.data.response.docs.filter(function (pl) {
              return pl.booking_type ? pl.booking_type === CONST_HELPER.TWO || pl.booking_type[0] === CONST_HELPER.TWO : false;
            });
          } else {
            // filter the packages which has available seats...
            packageData = response.data.response.docs.filter(function (pl) {
              return pl.package_slots && !pl.single_day_activity && pl.package_slots.find(function (ps) {
                return ps.available_seat > CONST_HELPER.ZERO;
              });
            });
          }

          $scope.packageList = packageData;
          $scope.checkExpiredSlots($scope.packageList);
          if ($stateParams.package_id) {
            getSelectedPackage('', '', $stateParams.package_id);
            return;
          }
          getSelectedPackage($stateParams.slot_id);
        }
      }

      // function that returns the selected slot pakcage name...
      function getSelectedPackage (slot_id, package_data, params_package) {
        if (slot_id) {
          slot_id = parseInt(slot_id);
          // find the selected package if slot is selected...
          $scope.manualBooking.selectedPackage = $scope.packageList.find(function (pl) {
            return pl.package_slots.find(function (ps) {
              return ps.id === slot_id;
            });
          });
          // remove expired slot...
          $scope.manualBooking.selectedPackage.package_slots = $scope.manualBooking.selectedPackage.package_slots.filter(function (ps) { return !ps.expired; });
          $scope.manualBooking.selectedSlot = $scope.manualBooking.selectedPackage.package_slots.find(function (ps) { return ps.id === slot_id; });
          $scope.manualBooking.selectedSlotDuration = {
            from: new Date($scope.manualBooking.selectedSlot.from_date),
            to: new Date($scope.manualBooking.selectedSlot.to_date),
          };
        } else if (package_data) {
          // if operator select package;
          $scope.manualBooking.selectedPackage = $scope.packageList.find(function (p) { return p.id === package_data.id; });
          // remove expired slot ...
          $scope.manualBooking.selectedPackage.package_slots = $scope.manualBooking.selectedPackage.package_slots.filter(function (ps) { return !ps.expired; });
          $scope.manualBooking.selectedSlot = $scope.manualBooking.selectedPackage.package_slots[0]; // default
          $scope.manualBooking.selectedSlotDuration = {
            from: new Date($scope.manualBooking.selectedSlot.from_date),
            to: new Date($scope.manualBooking.selectedSlot.to_date),
          };
        } else if (params_package) {
          $scope.manualBooking.selectedPackage = $scope.packageList.find(function (p) { return p.id == params_package || p.package_id == params_package; });
          // $scope.manualBooking.selectedSlot = $stateParams.start_date; // default
          if ($stateParams.booking_type == CONST_HELPER.TWO) {
            var fromDate = new Date(+$stateParams.start_date);
            var toDate = new Date(+$stateParams.end_date);
            var date = $filter('date')(fromDate, 'MMM d') + ' - ' + $filter('date')(toDate, 'MMM d yyyy');
            // var date = moment(+$stateParams.start_date).format('MMMM DD YYYY') + ' - ' + moment(+$stateParams.end_date).format('MMMM DD YYYY');
            $scope.manualBooking.selectedSlot = date;
            $scope.manualBooking.selectedSlotDuration = {
              from: fromDate,
              to: toDate,
            };
          } else {
            // remove expired slot ...
            $scope.manualBooking.selectedPackage.package_slots = $scope.manualBooking.selectedPackage.package_slots.filter(function (ps) { return !ps.expired; });
            $scope.manualBooking.selectedSlot = $scope.manualBooking.selectedPackage.package_slots[0]; // default
            $scope.manualBooking.selectedSlotDuration = {
              from: new Date($scope.manualBooking.selectedSlot.from_date),
              to: new Date($scope.manualBooking.selectedSlot.to_date),
            };
          }
        } else {
          // set default guest and currency in case of no pakcage selection...
          $scope.manualBooking.local_currency_code = $scope.manualBooking.length ? $scope.manualBooking.selectedPackage.currency.code : CONST_HELPER.payment_const.default_currency;
          $scope.manualGuest = {};
          $scope.manualGuest.total_guest = '0';
          $scope.manualBooking.subTotal = 0;
          $scope.refreshSelectpicker();
        }

        if (!$scope.order_id && $scope.manualBooking.selectedPackage && $scope.manualBooking.selectedPackage.package_slots && $scope.manualBooking.selectedPackage.package_slots.length) {
          $scope.manualBooking.selectedPackage.package_slots = $scope.manualBooking.selectedPackage.package_slots.filter(function (ps) { return ps.available_seat > 0; });
        }
        // add name for every active slots
        if ($scope.manualBooking.selectedPackage && $scope.manualBooking.selectedPackage.package_slots && $scope.manualBooking.selectedPackage.package_slots.length) {
          $scope.manualBooking.selectedPackage.package_slots.map(function (ps) {
            ps.name = $filter('date')(new Date($filter('changeDateObject')(ps.from_date)), 'MMM d') + ' - ' + $filter('date')(new Date($filter('changeDateObject')(ps.to_date)), 'MMM d, y');
          });
          // update the params if conflict...
          if ($stateParams.slot_id !== $scope.manualBooking.selectedSlot.id) {
            $state.go('operator-manual-booking', { 'slot_id': $scope.manualBooking.selectedSlot.id });
          }
        }

        if ($stateParams.order_id) {
          // Calculate taxes after loading deposit payment info from server
          calculateTax();
        }
      }

      // function to take action  on package selection changed...
      $scope.changeSelectedPackage = function (package_data) {
        getSelectedPackage('', package_data);
      };
      // function to take action  on slot selection changed...
      $scope.changeSelectedSlot = function () {
        if ($stateParams.slot_id !== $scope.manualBooking.selectedSlot.id) {
          $state.go('operator-manual-booking', { 'slot_id': $scope.manualBooking.selectedSlot.id });
        }
      };

      function setupRemainingPayment (callback) {
        $rootScope.CartDetails = [];
        userOrdersListService.getOrderList($scope.user.token, $stateParams.order_id).then(function (response) { // send req for current booking order
          if (response.data.message === 'Success') {
            var payment_detail = response.data.bookings[0].cart;
            var item = response.data.bookings[0].cart_slot_details[0];

            // set currency code and currency symbol...
            $rootScope.selectedCurrencyCode = item.local_currency_code;
            $rootScope.selectedCurrencySymbol = $scope.currencySymbol[$rootScope.selectedCurrencyCode];
            $rootScope.usdRate = item.payment_detail.cc_rate;

            var remainingTax = toFixed(item.payment_detail.tax - item.payment[0].tax_amount, 2);
            var localremainingTax = toFixed(item.payment_detail.local_tax - item.payment[0].local_tax_amount, 2);
            item.payment_detail.local_due_amount = item.payment_detail.local_due_amount ? item.payment_detail.local_due_amount : toFixed(item.payment_detail.due_amount * $scope.usdRate, 2);

            var obj = {
              'slot_id': item.id,
              'package_id': item.package.id,
              'operator_id': item.package.user_id,
              'tax_amount': remainingTax,
              'local_tax_amount': localremainingTax,
              'is_partial': CONST_HELPER.ONE,
              'processing_fee': calculateRemainingProcessingFee(item.payment_detail.due_amount, remainingTax),
              'local_processing_fee': calculateRemainingProcessingFee(item.payment_detail.local_due_amount, localremainingTax),
              'due_amount': CONST_HELPER.ZERO,
              'local_due_amount': CONST_HELPER.ZERO,
              'slot_amount': toFixed(item.payment_detail.due_amount, 2),
              'local_slot_amount': item.payment_detail.local_due_amount,
              'total_slot_amount': toFixed((payment_detail.base_amount + item.payment_detail.local_due_amount) / $rootScope.usdRate, 2),
              'local_total_slot_amount': toFixed(payment_detail.base_amount + item.payment_detail.local_due_amount, 2),
              'is_final_payment': CONST_HELPER.ONE,
              'total_tax': toFixed(item.payment_detail.tax, 2),
              'local_total_tax': toFixed(item.payment_detail.local_tax, 2),
              'opr_percentage': $scope.operatorSlotData.commission,
              'due_date': null,
              'payment_detail_id': item.payment_detail.id
            };
            $rootScope.CartDetails.push(obj);
            $cookies.put('cart_id', item.cart_id);
            $scope.manualBooking.local_currency_code = $rootScope.selectedCurrencyCode;
            $scope.manualBooking.subTotal = item.payment_detail.local_due_amount;
            var orderGuest = item.guests.find(function (g) {
              return g.user_type === 1;
            });
            var manualGuest = $scope.selectedSlotDetails.guests.find(function (guest) {
              return guest.id === orderGuest.id;
            });
            $scope.manualGuest = manualGuest;
            $scope.manualGuest.price = payment_detail.base_amount + item.payment_detail.local_due_amount;
            $scope.manualGuest.is_deposit = '1';
            $scope.manualGuest.deposit_price = item.payment_detail.local_due_amount;
            $scope.manualGuest.number_of_guest = String(item.guests.length);
            $scope.manualGuest.total_guest = String(item.guests.length);

            if ($scope.operatorSlotData.package.package_addons.length) { setManualBookingAddons(item.addons_details || []); }
            if ($scope.selectedSlotDetails.slot_lodgings && $scope.selectedSlotDetails.slot_lodgings.length) {
              $scope.setUpSelectedLodging($scope.manualGuest);
            }
            // $scope.editManualGuest($scope.manualGuest, 'no-popup');

            $scope.hideLoader();
            $scope.refreshSelectpicker();
            // $scope.proceedToRemainingPayment();
            // setUpPaymentObjects()
            if (callback) { callback(); }
          }
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      }
      // setup the guest lodging...
      $scope.setUpSelectedLodging = function (bookingSlotLodging, callback) {
        $scope.selectedSlotDetails.slot_lodgings.map(function (c_s_l) {
          // get private lodging...
          if (!(bookingSlotLodging.booking && bookingSlotLodging.booking.booking_slot_lodging_details && bookingSlotLodging.booking.booking_slot_lodging_details.length)) {
            return;
          }

          var slot_lodging_arr = bookingSlotLodging.booking.booking_slot_lodging_details.filter(function (obj) { return obj.slot_lodging_id === c_s_l.id; });
          if (slot_lodging_arr && slot_lodging_arr.length) {
            var rooms = 0; var guests = 0;
            c_s_l.booking_lodging_rooms = [];
            slot_lodging_arr.map(function (sl) {
              rooms += sl.units;
              guests += sl.quantity;
              c_s_l.booking_lodging_rooms = c_s_l.booking_lodging_rooms.concat(sl.booking_lodging_rooms);
            });
            c_s_l.selected_rooms = c_s_l.booking_lodging_rooms.length ? String(c_s_l.booking_lodging_rooms.length) : '0';
            c_s_l.previousSelectedRooms = c_s_l.selected_rooms;
            c_s_l.previousSelectedGuests = guests;

            privateCaseCheck(c_s_l);
            $scope.calculateLodgingTotal(c_s_l);
          }
        });
        if (callback) { callback(); }
        $timeout(function () {
          updateOptionsForLodging();
        });
      };

      // update values according to slot case private or public
      function privateCaseCheck (slot_lodging_obj) {
        slot_lodging_obj.booking_lodging_rooms.map(function (r) {
          var rooms_obj = JSON.parse(r.rooms_guest);
          r.selected = rooms_obj.guest;
          r.selectedGuestType = rooms_obj.type;
          if (rooms_obj.guest) { // private room
            slot_lodging_obj.remaining_beds = slot_lodging_obj.total_beds - slot_lodging_obj.lodging.max_guests >= 0 ? slot_lodging_obj.total_beds - slot_lodging_obj.lodging.max_guests : slot_lodging_obj.total_beds;
          } else { // shared room
            slot_lodging_obj.remaining_beds = slot_lodging_obj.total_beds - rooms_obj >= 0 ? slot_lodging_obj.total_beds - rooms_obj : slot_lodging_obj.total_beds;
          }
        });
      }

      // select addons for every one checkbox clicked...
      $scope.addonsForEveryone = function () {
        if ($scope.selectedSlotDetails.package.package_addons && $scope.selectedSlotDetails.package.package_addons.length) {
          $scope.selectedSlotDetails.package.package_addons.map(function (addon, index) {
            if ($scope.manualGuest.selectForEveryone) {
              addon.addonAdults = String($scope.manualGuest.total_guest);
            }
            $scope.refreshSelectpicker();
          });
          CalculateSuggestedRetailPrice();
        }
      };

      // selectAddons ...
      $scope.selecteAddons = function () {
        CalculateSuggestedRetailPrice();
        $scope.refreshSelectpicker();
      };
      $scope.addonGuestChange = function () {
        CalculateSuggestedRetailPrice();
      };

      // function to set the booked addons details for remainig payment...
      function setManualBookingAddons (bookedAddons) {
        $scope.selectedSlotDetails.package.package_addons.map(function (pa) {
          $scope.manualGuest.selectForEveryone = true;
          var addonObj = bookedAddons.find(function (ba) { return ba.package_addon.id === pa.id; });
          if (addonObj && addonObj.id) {
            pa.addonAdults = String(addonObj.package_addon_adult_quantity);
            pa.isSelected = true;
            if ($scope.manualGuest.total_guest !== pa.addonAdults) {
              $scope.manualGuest.selectForEveryone = false;
            }
          } else {
            pa.addonAdults = '0';
          }
        });
        // $scope.selectedSlotDetails = angular.copy($scope.operatorSlotData);
      }

      /**
   * @ngdoc function
   * @name updateSlotLodgingForEditGuest
   * @description
   * # check and update slot lodging details in slot lodging object for edit guest details popup...
   */
      function updateSlotLodgingForEditGuest (guest) {
        if (!guest.booking) { return; }
        var slot_lodging_details = guest.booking ? guest.booking.booking_slot_lodging_details : guest.parent_booking.booking_slot_lodging_details;
        $scope.selectedSlotDetails.available_seat += parseInt($scope.total_selected_guests);
        $scope.selectedSlotDetails.slot_lodgings.map(function (slot_lodging) {
          slot_lodging_details.forEach(function (obj) {
            if (obj.slot_lodging_id === slot_lodging.id) {
              slot_lodging.total_beds = obj.is_private ? (slot_lodging.total_beds + (obj.units * slot_lodging.lodging.max_guests)) : slot_lodging.total_beds + obj.quantity;
              // slot_lodging.is_private = obj.is_private;
              slot_lodging.selected_guests = obj.is_private ? slot_lodging.selected_guests : obj.quantity.toString();
              slot_lodging.private_guests = obj.is_private ? obj.quantity.toString() : '0';
              slot_lodging.selected_rooms = slot_lodging.booking_lodging_rooms.length ? slot_lodging.booking_lodging_rooms.length.toString() : '0';
              slot_lodging.booking_slot_lodging_details_id = obj.is_private ? slot_lodging.booking_slot_lodging_details_id ? slot_lodging.booking_slot_lodging_details_id : 0 : obj.id; // Saving booking slot lodging details id for edit guest
              slot_lodging.booking_slot_lodging_details_private_id = obj.is_private ? slot_lodging.booking_slot_lodging_details_private_id ? slot_lodging.booking_slot_lodging_details_private_id : obj.id : 0;
            }
          });
        });
      }
      /* Add Guest popup price calculation */
      /**
   * @ngdoc function
   * @name checkAndUpdateLodging
   * @description
   * # check if private option is available in lodging for package-charge by seats (case A)...
   */
      function checkAndUpdateLodging (guest, resetLodging) {
        $scope.selectedSlotDetails = angular.copy($scope.operatorSlotData);
        if ($scope.selectedSlotDetails.package.package_case === 2) {
          updateSlotLodgingEditGuestCaseB(guest);
          return;
        }
        if (guest && $scope.selectedSlotDetails.slot_lodgings.length && (guest.type === 1 || guest.type === 2)) {
          updateSlotLodgingForEditGuest(guest);
        } else if (guest && !$scope.selectedSlotDetails.slot_lodgings.length && (guest.type === 1 || guest.type === 2)) {
          $scope.selectedSlotDetails.available_seat += parseInt(guest.number_of_guest);
        }
        // update edit case lodging bookings
        if ($scope.selectedSlotDetails.slot_lodgings) {
          $scope.selectedSlotDetails.total_available_beds = 0;
          $scope.total_selected_guests = 0;
          $scope.selectedSlotDetails.slot_lodgings.map(function (lodging) {
            if (!lodging.canBookedPrivate) {
              $scope.selectedSlotDetails.total_available_beds += lodging.total_beds;
            }
          });
          var privateLodging = [];
          $scope.selectedSlotDetails.slot_lodgings.map(function (slot_lodging, slot_index) {
            if (slot_lodging.private_price) {
              var lodgingObj = angular.copy(slot_lodging);
              slot_lodging.remaining_beds = lodgingObj.total_beds;
              slot_lodging.canBookedPrivate = true;
              slot_lodging.privateRoom = true;
              slot_lodging.selected_guests = guest && slot_lodging.selected_guests && !resetLodging ? slot_lodging.selected_guests : '0';
              slot_lodging.selected_rooms = '0';
            } else {
              slot_lodging.canBookedPrivate = false;
              slot_lodging.privateRoom = false;
              slot_lodging.remaining_beds = slot_lodging.total_beds;
              slot_lodging.selected_guests = guest && slot_lodging.selected_guests && !resetLodging ? slot_lodging.selected_guests : '0';
              slot_lodging.selected_rooms = '0';
            }
          });
          if (privateLodging.length) {
            privateLodging.map(function (pl) {
              var pl_index = $scope.selectedSlotDetails.slot_lodgings.map(function (sl) { return sl.id; }).indexOf(pl.id);
              $scope.selectedSlotDetails.slot_lodgings.splice(pl_index + 1, 0, pl);
            });
          }
        }
      }
      /**
   * @ngdoc function
   * @name updateLodgingWithGuestsCaseB
   * @description
   * # change the total selected guests when user selects guests from guests drop down for booking (case B)...
   */
      $scope.updateLodgingWithGuestsCaseB = function (slot_lodging) {
        $scope.total_selected_guests = 0;
        if (nightly_slot_id && slot_lodging) {
          var calendarTotal = calculateDayWisePriceCaseB(slot_lodging);
          slot_lodging.price = calendarTotal;
        }
        $scope.selectedSlotDetails.slot_lodgings.map(function (slot) {
          $scope.total_selected_guests += parseInt(slot.selected_guests) * slot.lodging.max_guests;
        });
        CalculateSuggestedRetailPrice();
        $scope.refreshSelectpicker();
      };
      function updateSlotLodgingEditGuestCaseB (guest) {
        $scope.total_selected_guests = 0;
        $scope.selectedSlotDetails.total_available_beds = 0;
        if (guest && guest.type === 1) {
          var slot_lodging_details = guest.booking ? guest.booking.booking_slot_lodging_details : guest.parent_booking.booking_slot_lodging_details;
          $scope.selectedSlotDetails.available_seat += parseInt(guest.number_of_guest);
          $scope.selectedSlotDetails.slot_lodgings.map(function (slot_lodging) {
            slot_lodging_details.forEach(function (obj) {
              if (obj.slot_lodging_id === slot_lodging.id) {
                slot_lodging.available_units += obj.quantity;
                slot_lodging.selected_guests = obj.quantity.toString();
                slot_lodging.booking_slot_lodging_details_id = obj.id; // Saving booking slot lodging details id for edit guest
              }
            });
          });
        }
        $scope.selectedSlotDetails.slot_lodgings.map(function (slot) {
          slot.selected_guests = slot.selected_guests ? slot.selected_guests : '0';
          slot.total_beds = slot.available_units * slot.lodging.max_guests;
          $scope.total_selected_guests += parseInt(slot.selected_guests) * slot.lodging.max_guests;
          $scope.selectedSlotDetails.total_available_beds += slot.total_beds;
        });
        CalculateSuggestedRetailPrice();
      }
      // Update Lodging after guests/room selection
      /**
   * @ngdoc function
   * @name updateLodgingWithRooms
   * @description
   * # change the options of corresponding guests drop down when user selects rooms for private booking (case A)...
   */
      $scope.updateLodgingWithRooms = function (slot) {
        $scope.total_selected_guests = 0;
        $scope.selectedSlotDetails.slot_lodgings.map(function (slot) {
          $scope.total_selected_guests += parseInt(slot.selected_guests);
        });
        var guest_remaining = parseInt($scope.manualGuest.number_of_guest) - $scope.total_selected_guests;
        if (guest_remaining >= parseInt(slot.selected_rooms) * slot.lodging.max_guests) {
          slot.guest_options = parseInt(slot.selected_rooms) * slot.lodging.max_guests;
        } else {
          slot.guest_options = guest_remaining;
        }
        slot.selected_guests = slot.selected_rooms; // Minimum number of guest for private lodging
        $scope.updateLodgingWithGuestsCaseA();
        CalculateSuggestedRetailPrice();
      };
      /**
   * @ngdoc function
   * @name updateLodgingWithGuestsCaseA
   * @description
   * # change the options of guests/rooms when user selects guests from guests drop down for booking (case A)...
   */
      vm.remainingRooms = CONST_HELPER.ZERO;
      vm.maximumRooms = CONST_HELPER.ZERO;
      $scope.updateLodgingWithGuestsCaseA = function (slot, index) {
        $scope.addonsForEveryone();
        if (slot) { // when user changes the selected rooms value...
          slot.previousSelectedRooms = slot.previousSelectedRooms ? slot.previousSelectedRooms : CONST_HELPER.ZERO;
          var room_diff = Math.abs(slot.previousSelectedRooms - slot.selected_rooms);

          // CHECK LODGING ROOM IS INCREASED OR DECREASED...
          if (slot.previousSelectedRooms && parseInt(slot.previousSelectedRooms) >= parseInt(slot.selected_rooms)) {			// if seats goes up
            vm.roomsInreased = false;
            vm.remainingRooms = vm.remainingRooms + room_diff;
            getRemainingGuest(index);
          } else {
            vm.roomsInreased = true;
            vm.remainingRooms = vm.remainingRooms - room_diff;
          }
          initiateSlotLodgingRooms(slot);
          slot.previousSelectedRooms = slot.selected_rooms;
        }
        if ($scope.selectedSlotDetails.package.package_case === 3 || !$scope.selectedSlotDetails.slot_lodgings.length) {
          CalculateSuggestedRetailPrice();
          return;
        }
        var guest_accompaning = parseInt($scope.manualGuest.number_of_guest);
        if (!guest_accompaning) {
          return;
        }
        if (guest_accompaning < $scope.total_selected_guests) {
          slot ? checkAndUpdateLodging($scope.manualGuest, 'resetSelectedLodging') : checkAndUpdateLodging();
        }
        $scope.total_selected_guests = 0;
        $scope.selectedSlotDetails.slot_lodgings.map(function (slot) {
          if (slot.booking_lodging_rooms && slot.booking_lodging_rooms.length) {

            slot.booking_lodging_rooms.map(function (b_l_r) {
              var room_guest_obj = JSON.parse(b_l_r.rooms_guest);
              var rooms_guest = 0;
              if (room_guest_obj.guest) {
                rooms_guest = room_guest_obj.guest;
              } else {
                rooms_guest = room_guest_obj;
              }
              $scope.total_selected_guests += parseInt(rooms_guest);
            });
          } else {
            $scope.total_selected_guests += parseInt(slot.selected_guests);
          }
        });
        guest_accompaning = guest_accompaning - $scope.total_selected_guests;
        vm.remainingSelectedGuest = guest_accompaning >= 0 ? guest_accompaning : 0;
        $scope.selectedSlotDetails.slot_lodgings.map(function (slot_lodging) {
          var units_for_booking = slot_lodging.canBookedPrivate ? parseInt(slot_lodging.remaining_beds / slot_lodging.lodging.max_guests) : slot_lodging.remaining_beds;
          var selected_rooms = slot_lodging.selected_rooms ? angular.copy(slot_lodging.selected_rooms) : '0';
          slot_lodging.total_rooms = units_for_booking;
          slot_lodging.options = Math.min(vm.remainingRooms, units_for_booking, vm.remainingSelectedGuest) + parseInt(selected_rooms);
        });
        updateOptionsForLodging();
        CalculateSuggestedRetailPrice();
        $scope.refreshSelectpicker();
      };
      if ($rootScope.currentState.name === 'manual-guest-booking') {
        $timeout(function () {
          $('.selectpicker').selectpicker('refresh');
        }, 1000);
      }
      // function to update the slot lodging objects on guest selection changes...
      vm.SelectGuestForLodging = function (rooms, slot_lodging, index) {
        var room_guest_obj = JSON.parse(rooms.rooms_guest); var guest_type;
        var rooms_guest = 0;
        if (room_guest_obj.guest) {
          rooms_guest = room_guest_obj.guest;
          guest_type = room_guest_obj.type;
        } else {
          rooms_guest = room_guest_obj;
          guest_type = 'shared';
        }
        rooms.selected = rooms_guest;
        slot_lodging.previousSelectedGuests = +rooms_guest;
        rooms.selectedGuestType = guest_type;
        rooms.previousSelectedGuests = rooms.previousSelectedGuests ? rooms.previousSelectedGuests : CONST_HELPER.ZERO;
        var guest_diff = Math.abs(rooms.previousSelectedGuests - rooms_guest);

        $scope.total_selected_guests = 0;
        $scope.selectedSlotDetails.slot_lodgings.map(function (slot) {
          if (slot.booking_lodging_rooms && slot.booking_lodging_rooms.length) {
            slot.booking_lodging_rooms.map(function (b_l_r) {
              var room_guest_obj = JSON.parse(b_l_r.rooms_guest);
              var rooms_guest = 0;
              if (room_guest_obj.guest) {
                rooms_guest = room_guest_obj.guest;
              } else {
                rooms_guest = room_guest_obj;
              }
              $scope.total_selected_guests += parseInt(rooms_guest);
            });
          } else {
            $scope.total_selected_guests += parseInt(slot.selected_guests);
          }
        });

        // CHECK LODGING IS INCREASED OR DECREASED...
        if (rooms.previousSelectedGuests && parseInt(rooms.previousSelectedGuests) >= parseInt(rooms_guest)) {			// if seats goes up
          vm.guestsInreased = false;
          vm.remainingSelectedGuest = vm.remainingSelectedGuest + guest_diff;
        } else {
          vm.guestsInreased = true;
          vm.remainingSelectedGuest = vm.remainingSelectedGuest - guest_diff;
        }

        slot_lodging.booking_lodging_rooms.map(function (rooms, i) {
          if (i !== index) {	// find the other rooms of that lodging...
            if (slot_lodging.canBookedPrivate) {
              rooms.guestOption = $filter('guestRange')([], Math.min(vm.remainingSelectedGuest + getSelectedRoomGuest(rooms), slot_lodging.lodging.max_guests, slot_lodging.total_beds), slot_lodging.lodging.max_guests, slot_lodging.lodging.min_private_guests);
            } else {
              rooms.guestOption = $filter('range')([], Math.min(vm.remainingSelectedGuest + getSelectedRoomGuest(rooms), slot_lodging.lodging.max_guests, slot_lodging.total_beds));
            }
          }
          rooms.previousSelectedGuests = getSelectedRoomGuest(rooms); 	// store the previously selected guest count...
        });

        // update the slot lodging rooms data of other lodging...
        updateOptionsForLodging(slot_lodging);
        $scope.calculateLodgingTotal(slot_lodging);
        // rooms.rooms_guest = JSON.stringify(rooms.rooms_guest);
        // slot_lodging.previousSelectedGuests = rooms_guest; 	// store the previously selected guest count...
        $scope.refreshSelectpicker();
      };

      // function to initiate the rooms array objects
      function initiateSlotLodgingRooms (slot_lodgings) {
        var selected_rooms = vm.roomsInreased ? parseInt(slot_lodgings.selected_rooms) - parseInt(slot_lodgings.previousSelectedRooms) : parseInt(slot_lodgings.selected_rooms);
        $scope.booking_slot_lodging_id = $scope.manualGuest.id && slot_lodgings.booking_lodging_rooms && slot_lodgings.booking_lodging_rooms.length ? slot_lodgings.booking_lodging_rooms[0].booking_slot_lodging_detail_id : $scope.booking_slot_lodging_id || '';
        slot_lodgings.booking_lodging_rooms = vm.roomsInreased ? slot_lodgings.booking_lodging_rooms || [] : [];
        for (var i = 0; i < parseInt(selected_rooms); i++) {
          var roomsObjects = { id: i, rooms_guest: '0', slot_lodging_id: slot_lodgings.id, booking_slot_lodging_detail_id: $scope.booking_slot_lodging_id };
          if (slot_lodgings.canBookedPrivate) {
            roomsObjects.guestOption = $filter('guestRange')([], Math.min(vm.remainingSelectedGuest, slot_lodgings.lodging.max_guests, slot_lodgings.total_beds), slot_lodgings.lodging.max_guests, slot_lodgings.lodging.min_private_guests);
          } else {
            roomsObjects.guestOption = $filter('range')([], Math.min(vm.remainingSelectedGuest, slot_lodgings.lodging.max_guests, slot_lodgings.total_beds + +slot_lodgings.previousSelectedGuests));
          }
          slot_lodgings.booking_lodging_rooms.push(roomsObjects);
        }
      }
      // function to return remaining guest to be selected...
      function getRemainingGuest (index) {
        if ($scope.selectedSlotDetails.slot_lodgings && $scope.selectedSlotDetails.slot_lodgings.length) {
          var lodging_slelected_guest = 0;
          $scope.selectedSlotDetails.slot_lodgings.map(function (sl, i) {
            if (i !== index) { return; } // return the other lodging object
            sl.booking_lodging_rooms.map(function (r) {
              var guest = JSON.parse(r.rooms_guest);
              var selected_guest = guest.hasOwnProperty('guest') ? guest.guest : guest;

              lodging_slelected_guest = lodging_slelected_guest + selected_guest;
            });
          });
          vm.remainingSelectedGuest = vm.remainingSelectedGuest + lodging_slelected_guest;
        }
      }

      // function to return the selected guests in rooms
      function getSelectedRoomGuest (rooms) {
        var selected_room_guest = 0;
        var rooms_guest_obj = JSON.parse(rooms.rooms_guest);

        if (rooms_guest_obj.hasOwnProperty('guest')) {
          selected_room_guest = rooms_guest_obj.guest;
        } else {
          selected_room_guest = rooms.rooms_guest;
        }
        return parseInt(selected_room_guest);
      }

      // function to get the previous selected beds
      function getSelectedBeds (sl) {
        return sl.previousSelectedGuests ? +sl.previousSelectedGuests : 0;
      }

      function updateOptionsForLodging (slot_lodging) {
        $scope.selectedSlotDetails.slot_lodgings.map(function (sl, i) {
          if (!slot_lodging || sl.id !== slot_lodging.id) {	// update the other lodging options...
            var selected_rooms = sl.selected_rooms ? sl.selected_rooms : '0';
            var total_rooms = Math.ceil((sl.total_beds - (parseInt(selected_rooms) * sl.lodging.max_guests)) / sl.lodging.max_guests);
            total_rooms = total_rooms < 0 ? 0 : total_rooms;
            sl.total_rooms = total_rooms;
            sl.options = Math.min(vm.remainingRooms, total_rooms, vm.remainingSelectedGuest) + parseInt(selected_rooms);

            if (parseInt(selected_rooms) >= CONST_HELPER.ONE && sl.booking_lodging_rooms && sl.booking_lodging_rooms.length) {
              sl.booking_lodging_rooms.map(function (rooms, i) {
                if (sl.canBookedPrivate) {
                  rooms.guestOption = $filter('guestRange')([], Math.min(vm.remainingSelectedGuest + getSelectedRoomGuest(rooms), sl.lodging.max_guests, sl.total_beds + getSelectedBeds(sl)), sl.lodging.max_guests, sl.lodging.min_private_guests);
                } else {
                  rooms.guestOption = $filter('range')([], Math.min(vm.remainingSelectedGuest + getSelectedRoomGuest(rooms), sl.lodging.max_guests, sl.total_beds + getSelectedBeds(sl)));
                }
                rooms.previousSelectedGuests = getSelectedRoomGuest(rooms); 	// store the previously selected guest count...

              });
            }
          }
        });
      }
      // calculate the loadging amounts...
      $scope.calculateLodgingTotal = function (slot_lodging) {
        var total = 0;
        if (!nightly_slot_id) {
          slot_lodging.booking_lodging_rooms.map(function (r) {
            r.is_private_charged = false;
            var rooms_guest = JSON.parse(r.rooms_guest);
            var guest_selected = rooms_guest.guest ? rooms_guest.guest : rooms_guest;
            // total = total + guest_selected * slot_lodging.price;
            // check if fewer people book the room privatly... charge extra.
            if (rooms_guest.type === 'private' && rooms_guest.guest <= slot_lodging.lodging.min_private_guests) {
              r.is_private_charged = true;
              r.total_price = guest_selected * slot_lodging.price + slot_lodging.private_price;	// total price including private price...
              r.total_price_usd = (guest_selected * slot_lodging.price) / $scope.cc_rate_usd + (slot_lodging.private_price / $scope.cc_rate_usd);	// total price including private price...

              total = total + guest_selected * slot_lodging.private_price;
            } else {
              total = total + guest_selected * slot_lodging.price;
            }
          });
          slot_lodging.sub_total = total;
        } else {
          total = calculateDayWisePrice(slot_lodging);
          slot_lodging.sub_total = total;
        }
        CalculateSuggestedRetailPrice();	// update the total price...
        return total;
      };

      // for case A
      function calculateDayWisePrice (slot_lodging) {
        var total = CONST_HELPER.ZERO;
        slot_lodging.booking_lodging_rooms.map(function (r) {
          r.is_private_charged = false;
          var rooms_guest = JSON.parse(r.rooms_guest);
          var guest_selected = rooms_guest.guest ? rooms_guest.guest : rooms_guest;

          slot_lodging.calendar.map(function (eachDates) {
            // total = total + guest_selected * eachDates.price;
            // check if fewer people book the room privatly... charge extra.
            if (rooms_guest.type === 'private' && rooms_guest.guest <= slot_lodging.lodging.min_private_guests) {
              r.is_private_charged = true;
              r.total_price = guest_selected * slot_lodging.private_price;	// total price including private price...
              r.total_price_usd = guest_selected * slot_lodging.usd_private_price;	// total price including private price...

              total = total + guest_selected * eachDates.private_price;
            } else {
              total = total + guest_selected * eachDates.price;
            }
          });
        });
        return total;
      }

      // calendar day wise price for case B
      function calculateDayWisePriceCaseB (slot_lodging) {
        var total = 0;
        slot_lodging.calendar.map(function (eachDates, key) {
          // for case B count nights between check in and checkout dates...
          if (slot_lodging.calendar && slot_lodging.calendar.length && key < slot_lodging.calendar.length - 1 || slot_lodging.calendar.length == 1) {
            total += eachDates.price;
          }
        });
        return total;
      }

      /* Add Guest popup price calculation */
      $scope.AddManualGuest = function (addguestForm) {
        //  if(addguestForm.number_of_guest.$viewValue === '0'){
        //   addguestForm.number_of_guest.$invalid =true;
        //   addguestForm.$valid = false;
        // }
        $scope.selectedGuestError = false;
        if ($scope.selectedSlotDetails.slot_lodgings.length && vm.remainingSelectedGuest > CONST_HELPER.ZERO) {
          $scope.selectedGuestError = true;
          return;
        }
        if (!addguestForm.$valid) {
          $scope.addguestFormIsNotValid = true;
          return;
        }


        $scope.showLoader();
        if ($scope.selectedSlotDetails.slot_lodgings && $scope.selectedSlotDetails.slot_lodgings.length && $scope.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_SEAT) {
          // for case A with lodging
          $scope.manualGuest.package_slot_lodgings = getSlotLodgingDetails(); // store the individual selected lodging objects
        } else { // for case B
          if ($scope.selectedSlotDetails.slot_lodgings && $scope.selectedSlotDetails.slot_lodgings.length) {
            $scope.manualGuest.package_slot_lodgings = [];
            $scope.selectedSlotDetails.slot_lodgings.forEach(function (lodging) {
              if (parseInt(lodging.selected_guests) > 0) {
                var lodging_instance = { // Adding lodging object to save the individual lodging objects
                  'is_private': lodging.canBookedPrivate ? CONST_HELPER.ONE : CONST_HELPER.ZERO,
                  'price': lodging.price,
                  'usd_price': $scope.manualGuest.price ? toFixed(($scope.manualGuest.price / $scope.cc_rate_usd), 4) : '',
                  'private_price': lodging.private_price ? lodging.private_price : CONST_HELPER.ZERO,
                  'quantity': lodging.selected_guests,
                  'slot_lodging_id': lodging.id,
                  'units': lodging.selected_rooms ? lodging.selected_rooms : CONST_HELPER.ZERO,
                  'id': lodging.canBookedPrivate ? lodging.booking_slot_lodging_details_private_id : lodging.booking_slot_lodging_details_id,
                  'local_currency_code': $scope.manualBooking.local_currency_code
                };
                $scope.manualGuest.package_slot_lodgings.push(lodging_instance);
              }
            });
          }
        }
        if ($scope.manualGuest.id) {
          var editGuestObj = {
            'package_slot_id': package_slot_id,
            'nightly_slot_id': nightly_slot_id,
            'guest_id': $scope.manualGuest.id,
            'name': $scope.manualGuest.name,
            'email': $scope.manualGuest.email,
            'operator_notes': $scope.manualGuest.operator_notes,
            'price': $scope.manualGuest.price,
            'package_slot_lodgings': $scope.manualGuest.package_slot_lodgings,
            'is_deposit': $scope.manualGuest.is_deposit, // Added below for saving lodging for add guest details
            'deposit_price': $scope.manualGuest.deposit_price,
            'number_of_guest': $scope.manualGuest.number_of_guest,
            'from_date': $stateParams.start_date ? moment(new Date(parseInt($stateParams.start_date))).format('YYYY-MM-DD') : undefined,
            'to_date': $stateParams.end_date ? moment(new Date(parseInt($stateParams.end_date))).format('YYYY-MM-DD') : undefined
          };
          operatorCalendarService.editManualGuest(editGuestObj, token).then(function (res) {
            if (res.data.code === '200') {
              $scope.hidePopup('#addGuestModal');
              if ($stateParams.slot_id) { getSlotData(); } else if ($stateParams.nightly_slot_id) { getNightlySlotData(); }
              resetForm(addguestForm);
            } else if (res.data.code === '203') {
              $scope.logout();
              $scope.hideLoader();
            }
          });
        } else {
          delete $scope.manualGuest.selectForEveryone;
          $scope.manualGuest.from_date = $stateParams.start_date ? moment(new Date(parseInt($stateParams.start_date))).format('YYYY-MM-DD') : undefined;
          $scope.manualGuest.to_date = $stateParams.end_date ? moment(new Date(parseInt($stateParams.end_date))).format('YYYY-MM-DD') : undefined;
          operatorCalendarService.addManualGuest($scope.manualGuest, token).then(function (res) {
            if (res.data.code === '200') {
              $scope.hidePopup('#addGuestModal');
              if ($rootScope.currentState.name === 'manual-guest-booking') {
                $state.go('operator-edit-slots', { nightly_slot_id: $stateParams.nightly_slot_id, start_date: new Date(parseInt($stateParams.start_date)).getTime(), end_date: new Date(parseInt($stateParams.end_date)).getTime() });
              } else {
                if ($stateParams.slot_id) { getSlotData(); } else if ($stateParams.nightly_slot_id) { getNightlySlotData(); }
                resetForm(addguestForm);
              }
              var requestObject = {};
              var data = {};
              data.guest_id = res.data.data.guests_ids;
              data.package_id = res.data.data.package_id;
              requestObject.id = [data];
              requestObject.token = $scope.user.token;
              paymentService.sendGuestForms(requestObject).then(function (response) {
                console.log('response==>', response);
              });
            } else if (res.data.code === '203') {
              $scope.logout();
              $scope.hideLoader();
            }
          });
        }
      };

      $scope.openOperatorEditSlot = function () {
        $state.go('operator-edit-slots', { nightly_slot_id: $stateParams.nightly_slot_id, start_date: new Date(parseInt($stateParams.start_date)).getTime(), end_date: new Date(parseInt($stateParams.end_date)).getTime() });
      };

      function getSlotLodgingDetails () {
        var arr = [];
        if ($scope.selectedSlotDetails.slot_lodgings && $scope.selectedSlotDetails.slot_lodgings.length !== CONST_HELPER.ZERO) {
          $scope.selectedSlotDetails.slot_lodgings.map(function (lodge) {
            var sharedGuestRoom = []; var privateGuestRoom = []; var slotLodgingSharedRoom = []; var slotLodgingPrivateRoom = [];
            // if no selection happend for any lodging then return...
            if (!lodge.booking_lodging_rooms || !lodge.booking_lodging_rooms.length) {
              return;
            }
            if (lodge.canBookedPrivate) {
              // get the selected slot lodging rooms containing shared guest...
              sharedGuestRoom = lodge.booking_lodging_rooms.filter(function (r) {
                var rooms_guest_obj = JSON.parse(r.rooms_guest);
                return rooms_guest_obj.type === 'shared';
              });

              if (sharedGuestRoom && sharedGuestRoom.length) {
                var quantity = 0;
                sharedGuestRoom.map(function (sr) {
                  var rooms_guest_obj = JSON.parse(sr.rooms_guest);
                  quantity += rooms_guest_obj.guest;
                  var sharedRoomObj = {
                    slot_lodging_id: lodge.id,
                    rooms_guest: sr.rooms_guest
                  };
                  slotLodgingSharedRoom.push(sharedRoomObj);
                });
                var obj = {
                  slot_lodging_id: lodge.id,
                  quantity: quantity,
                  price: quantity * lodge.price || CONST_HELPER.ZERO,
                  usd_price: (quantity * lodge.price) / $scope.cc_rate_usd || CONST_HELPER.ZERO,
                  private_price: CONST_HELPER.ZERO,
                  usd_private_price: CONST_HELPER.ZERO,
                  is_private: CONST_HELPER.ZERO,
                  has_single_guest: CONST_HELPER.ZERO,
                  units: sharedGuestRoom.length || CONST_HELPER.ZERO,
                  booking_lodging_rooms: slotLodgingSharedRoom,
                  id: $scope.manualGuest.id ? lodge.booking_lodging_rooms[0].booking_slot_lodging_detail_id : ''
                };
                arr.push(obj);
              }

              // get the selected slot lodging rooms containing private guest...
              privateGuestRoom = lodge.booking_lodging_rooms.filter(function (r) {
                var rooms_guest_obj = JSON.parse(r.rooms_guest);
                return rooms_guest_obj.type === 'private';
              });

              if (privateGuestRoom && privateGuestRoom.length) {
                var quantity = 0; var private_price = 0; var usd_private_price = 0;
                privateGuestRoom.map(function (sr) {
                  var rooms_guest_obj = JSON.parse(sr.rooms_guest);

                  quantity += rooms_guest_obj.guest;
                  private_price += sr.is_private_charged ? sr.total_price : rooms_guest_obj.guest * lodge.price;
                  usd_private_price += sr.is_private_charged ? sr.total_price_usd : (rooms_guest_obj.guest * lodge.price) / $scope.cc_rate_usd;

                  var privateRoomObj = {
                    slot_lodging_id: lodge.id,
                    rooms_guest: sr.rooms_guest
                  };
                  slotLodgingPrivateRoom.push(privateRoomObj);
                });
                var obj = {
                  slot_lodging_id: lodge.id,
                  quantity: quantity,
                  price: CONST_HELPER.ZERO,
                  usd_price: CONST_HELPER.ZERO,
                  private_price: private_price || CONST_HELPER.ZERO,
                  usd_private_price: usd_private_price || CONST_HELPER.ZERO,
                  is_private: CONST_HELPER.ONE,
                  has_single_guest: CONST_HELPER.ZERO,
                  units: privateGuestRoom.length || CONST_HELPER.ZERO,
                  booking_lodging_rooms: slotLodgingPrivateRoom,
                  id: $scope.manualGuest.id ? lodge.booking_lodging_rooms[0].booking_slot_lodging_detail_id : ''
                };
                arr.push(obj);
              }
            }

            if (!lodge.canBookedPrivate) {
              // eslint-disable-next-line no-unused-expressions
              sharedGuestRoom = [];
              slotLodgingSharedRoom = [];
              // get the selected slot lodging rooms containing shared guest...
              sharedGuestRoom = lodge.booking_lodging_rooms.filter(function (r) {
                var rooms_guest_obj = JSON.parse(r.rooms_guest);
                return rooms_guest_obj > 0;
              });
              var quantity = 0;
              sharedGuestRoom.map(function (sr) {
                var guest = JSON.parse(sr.rooms_guest);
                quantity += guest;
                var sharedRoomObj = {
                  slot_lodging_id: lodge.id,
                  rooms_guest: sr.rooms_guest
                };
                slotLodgingSharedRoom.push(sharedRoomObj);
              });

              var obj = {
                slot_lodging_id: lodge.id,
                quantity: quantity,
                price: quantity * lodge.price || CONST_HELPER.ZERO,
                usd_price: (quantity * lodge.price) / $scope.cc_rate_usd || CONST_HELPER.ZERO,
                private_price: CONST_HELPER.ZERO,
                usd_private_price: CONST_HELPER.ZERO,
                is_private: CONST_HELPER.ZERO,
                has_single_guest: CONST_HELPER.ZERO,
                units: slotLodgingSharedRoom.length || CONST_HELPER.ZERO,
                booking_lodging_rooms: slotLodgingSharedRoom,
                id: $scope.manualGuest.id ? lodge.booking_lodging_rooms[0].booking_slot_lodging_detail_id : ''
              };
              arr.push(obj);
            }
          });
        }
        return arr;
      }

      $scope.deleteManualGuest = function (guest) {
        $scope.deleteManualGuestObj = guest;
        $scope.showPopup('#deletePostAlert');
      };
      $scope.proceedDeleteManualGuest = function () {
        $scope.showLoader();
        var guestObj = {
          'guest_id': $scope.deleteManualGuestObj.id
        };
        operatorCalendarService.deleteManualGuest(guestObj, token).then(function (res) {
          $scope.hidePopup('#deletePostAlert');
          if (res.data.code === '200') {
            if ($stateParams.slot_id) { getSlotData(); } else if ($stateParams.nightly_slot_id) { getNightlySlotData(); }
            $scope.deleteManualGuestObj = {};
          } else if (res.data.code === '203') {
            $scope.hideLoader();
            $scope.logout();
          }
        });
      };
      $scope.parseValue = function (price, event) {
        if (event.key !== '.') {
          if ($scope.manualGuest.is_deposit === '1') {
            $scope.manualBooking.subTotal = $scope.manualGuest.deposit_price ? $scope.manualGuest.deposit_price : CONST_HELPER.ZERO;
          } else {
            $scope.manualBooking.subTotal = $scope.manualGuest.price;
          }
        }
      };

      $scope.editManualGuest = function (guest, noPopup) {
        $scope.manualGuest = angular.copy(guest);
        var package_cc_rate = $scope.manualGuest.booking_slot_detail ? $scope.manualGuest.booking_slot_detail.package_cc_rate : CONST_HELPER.ONE;
        var usd_cc_rate = $scope.manualGuest.booking_slot_detail ? $scope.manualGuest.booking_slot_detail.cc_rate : CONST_HELPER.ONE;
        $scope.manualGuest.deposit_price = Math.round($scope.manualGuest.deposit_price * (package_cc_rate / usd_cc_rate));
        $scope.total_selected_guests = guest.number_of_guest;
        $scope.manualGuest.is_deposit = guest.is_deposit.toString();
        $scope.manualGuest.number_of_guest = guest.number_of_guest.toString();
        if (!noPopup) { $scope.showPopup('#addGuestModal'); }
        if ($scope.selectedSlotDetails.slot_lodgings && $scope.selectedSlotDetails.slot_lodgings.length && $scope.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_SEAT) {
          if ($scope.manualGuest.user_type === 2) {
            $scope.manualGuest.booking = $scope.manualGuest.parent_booking;
          }
          $scope.setUpSelectedLodging($scope.manualGuest, $scope.updateLodgingWithGuestsCaseA());
        } else {
          checkAndUpdateLodging(guest);
        }
        $scope.refreshSelectpicker();
      };

      $('#addGuestModal').on('hidden.bs.modal', function (event) {
        $scope.$apply(function () {
          resetForm(vm.addguestForm);
          $scope.manualGuest.number_of_guest = '0';
        });
      });
      $('#addGuestModal').on('shown.bs.modal', function (event) {
        $scope.$apply(function () {
          // $('.ng-valid-parse').hide();
          $scope.manualGuest.number_of_guest = $scope.manualGuest.number_of_guest == '0' ? '1' : $scope.manualGuest.number_of_guest;
          $scope.refreshSelectpicker();
        });
      });
      $('#addNoteModal').on('hidden.bs.modal', function (event) {
        $scope.slotNote = '';
      });
      $('#addGroupModal').on('hidden.bs.modal', function (event) {
        $scope.currentGroup = {};
        $scope.groupName = '';
        $scope.$apply(function () {
          resetForm(vm.addGroupForm);
        });
      });

      $scope.openNote = function (flag) {
        $scope.editNote = flag;
        $scope.slotNote = angular.copy($scope.operatorSlotData.note);
      };
      function resetForm (form) {
        resetManualGuest();
        form.$setPristine();
        $scope.groupFormValid = false;
        $scope.addguestFormIsNotValid = false;
        $scope.selectedGuestError = false;
        $scope.refreshSelectpicker();
      }
      // reset scope level variables for drag and drop
      function resetDragAndDropData () {
        $scope.model = '';
        $scope.source = '';
        $scope.target = '';
        $scope.groupId = '';
        $scope.guestId = '';
      }
      $scope.showMore = function (limit, guest, fieldLimit) {
        fieldLimit = fieldLimit || 'textLimit';
        guest[fieldLimit] = limit;
      };
      $scope.showLess = function (limit, guest, fieldLimit) {
        fieldLimit = fieldLimit || 'textLimit';
        guest[fieldLimit] = limit;
      };
      $scope.goBack = function () {
        if ($rootScope.isAgent) {
          $state.go('agent-calendar');
        } else {
          window.history.back();
        }
      };

      $scope.saveSlotNote = function () {
        $scope.showLoader();
        var slotNoteObj = {
          'slot_id': package_slot_id,
          'notes': $scope.slotNote
        };
        operatorCalendarService.saveSlotNotes(slotNoteObj, token).then(function (res) {
          $scope.hidePopup('#addNoteModal');
          if (res.data.code === '200') {
            if ($stateParams.slot_id) { getSlotData(); } else if ($stateParams.nightly_slot_id) { getNightlySlotData(); }
          } else if (res.data.code === '203') {
            $scope.hideLoader();
            $scope.logout();
          }
        });
      };

      $scope.editSlot = function () {
        $scope.slotDetail = angular.copy($scope.operatorSlotData);
        $scope.slotDetail.from_date = $filter('changeDateObject')($scope.slotDetail.from_date);
        $scope.slotDetail.to_date = $filter('changeDateObject')($scope.slotDetail.to_date);
        $scope.slotDetail.from_date = moment($scope.slotDetail.from_date).format('MM/DD/YYYY');
        $scope.slotDetail.to_date = moment($scope.slotDetail.to_date).format('MM/DD/YYYY');
        // update the total rooms and available beds
        if ($scope.slotDetail.package.package_case !== 3 && $scope.slotDetail.package.lodging_status === 1) {
          getTotalBedsAndRoom();
        }
      };

      $scope.updateSlot = function (form) {
        if (form.$invalid) {
          return false;
        }
        var requestObject = {
          'token': $scope.user.token,
          'package_id': $scope.slotDetail.package_id,
          'package_slot_id': $scope.slotDetail.id,
          'adult_price': $scope.slotDetail.adult_price,
          'available_seat': returnAvailableSeats(), // $scope.slotDetail.available_seat,
          'max_capacity': returnMaxSeats(), // $scope.slotDetail.max_capacity?$scope.slotDetail.max_capacity:$scope.slotDetail.available_seat,
          'from_date': moment($scope.slotDetail.from_date).format('YYYY-MM-DD'),
          'to_date': moment($scope.slotDetail.to_date).format('YYYY-MM-DD'),
          'slot_lodgings': returnSlotLodging()
        };
        $scope.showLoader(); // show loader
        operatorPackageListService.updatePackageSlot(requestObject).then(function (response) {
          if (response.data) {
            if (response.data.code === '200') {
              $scope.slotDetail = {}; // Set slotDetail object blank
              $scope.hidePopup('#editSlotModal'); // Hide add slot popup
              if ($stateParams.slot_id) { getSlotData(); } else if ($stateParams.nightly_slot_id) { getNightlySlotData(); }
            }
          }
        }, function () {
          $scope.hideLoader(); // hide loader
          $scope.showPopup('#serverErrModal');
        });
      };
      // return the slot loding if package of case A and case B
      function returnSlotLodging () {
        var slot_lodgings = '';
        if ($scope.slotDetail.package.package_case !== 3 && $scope.slotDetail.package.lodging_status === 1) {
          slot_lodgings = angular.copy($scope.slotDetail.slot_lodgings);
          slot_lodgings.map(function (slot) {
            delete slot.lodging;
            delete slot.created;
            delete slot.modified;
            delete slot.is_private;
          });
        }
        return slot_lodgings;
      }
      // return available seats basis of package case
      function returnAvailableSeats () {
        if ($scope.slotDetail.package.package_case === 2) {
          $scope.slotDetail.available_seat = $scope.total_available_rooms;
        } else if ($scope.slotDetail.package.package_case === 3) {
          $scope.slotDetail.available_seat = $scope.slotDetail.max_capacity;
        }
        return $scope.slotDetail.available_seat;
      }
      // return max seats basis of package case
      function returnMaxSeats () {
        if ($scope.slotDetail.package.package_case === 2) {
          $scope.slotDetail.max_capacity = $scope.total_available_rooms;
        } else if ($scope.slotDetail.package.package_case === 1 && $scope.slotDetail.package.lodging_status === 1) {
          // $scope.slotDetail.max_capacity=$scope.slotDetail.available_seat;
        }
        return $scope.slotDetail.max_capacity;
      }

      // set is_private key with lodging in case of private price changes
      $scope.setIsprivateKey = function (lodging) {
        lodging.is_private = true;
      };

      // update the to_date if from date changed...
      $scope.slotDateChange = function (value) { // change date function for slot
        var myDate = new Date(value); // get start date value
        var newDate = setEndDate(myDate, $scope.operatorSlotData.package.duration - 1); // call enddate function
        $scope.slotDetail.to_date = $filter('date')(new Date(newDate), 'MM/dd/yyyy'); // convert end date in mm-dd-yyyy format
        $('.datepicker').datepicker('destroy'); // destroy datepicker
      };
      function setEndDate (myDate, days) { // calculate end date for package slot
        return new Date(myDate.getTime() + days * 24 * 60 * 60 * 1000);
      }

      $scope.numberKeyDown = function (e) {
        if ([69, 187, 188, 189, 190].includes(e.keyCode)) {
          e.preventDefault();
        }
      };

      $scope.createGroup = function (form) {
        if (form.$invalid) {
          $scope.groupFormValid = true;
          return false;
        }
        $scope.showLoader();
        if ($scope.currentGroup && $scope.currentGroup.id) {
          var editGroupObj = {
            'group_id': $scope.currentGroup.id,
            'name': $scope.groupName
          };
          operatorCalendarService.editGroup(editGroupObj, token).then(function (res) {
            if (res.data.code === '200') {
              $scope.hidePopup('#addGroupModal');
              $scope.currentGroup = {};
              $scope.groupName = '';
              if ($stateParams.slot_id) { getSlotData(); } else if ($stateParams.nightly_slot_id) { getNightlySlotData(); }
              resetForm(vm.addGroupForm);
            } else if (res.data.code === '203') {
              $scope.logout();
              $scope.hideLoader();
            }
          });
        } else {
          var createGroupObj = {};
          if ($stateParams.nightly_slot_id) {
            createGroupObj = {
              'name': $scope.groupName,
              'nightly_slot_id': $stateParams.nightly_slot_id,
              'from_date': moment(new Date(parseInt($stateParams.start_date))).format('YYYY-MM-DD'),
              'to_date': moment(new Date(parseInt($stateParams.end_date))).format('YYYY-MM-DD')
            };
          } else {
            createGroupObj = {
              'package_slot_id': package_slot_id,
              'name': $scope.groupName
            };
          }

          operatorCalendarService.createGroup(createGroupObj, token).then(function (res) {
            $scope.hidePopup('#addGroupModal');
            if (res.data.code === '200') {
              if ($stateParams.slot_id) { getSlotData(); } else if ($stateParams.nightly_slot_id) { getNightlySlotData(); }
              resetForm(vm.addGroupForm);
            } else if (res.data.code === '203') {
              $scope.logout();
              $scope.hideLoader();
            }
          });
        }
      };
      $scope.editGroup = function (group) {
        $scope.currentGroup = group;
        $scope.groupName = group.name;
        $scope.showPopup('#addGroupModal');
      };
      $scope.deleteGroup = function (group) {
        $scope.deleteGroupObj = group;
        $scope.showPopup('#deleteGroupAlert');
      };
      $scope.proceedDeleteGroup = function () {
        $scope.showLoader();
        var groupObj = {
          'group_id': $scope.deleteGroupObj.id
        };
        operatorCalendarService.deleteGroup(groupObj, token).then(function (res) {
          $scope.hidePopup('#deleteGroupAlert');
          if (res.data.code === '200') {
            if ($stateParams.slot_id) { getSlotData(); } else if ($stateParams.nightly_slot_id) { getNightlySlotData(); }
            $scope.deleteGroupObj = {};
          } else if (res.data.code === '203') {
            $scope.hideLoader();
            $scope.logout();
          }
        });
      };

      // update lodging available seats...
      $scope.updateTotalBedsSeats = function (lodge, type) {
        if (type === 'manual') {
          // eslint-disable-next-line no-self-assign
          lodge.total_beds = lodge.total_beds;
          if (lodge.total_beds > (lodge.available_units ? lodge.available_units : 0) * lodge.lodging.max_guests) {
            lodge.total_beds = (lodge.available_units ? lodge.available_units : 0) * lodge.lodging.max_guests;
          }
        } else {
          lodge.total_beds = (lodge.available_units ? lodge.available_units : 0) * lodge.lodging.max_guests;
        }

        getTotalBedsAndRoom();
        $scope.checkAvailableSeats();
      };

      function getTotalBedsAndRoom () {
        // update the available seats...
        $scope.total_beds = 0;
        $scope.total_available_rooms = 0;
        $scope.slotDetail.slot_lodgings.map(function (val) {
          $scope.total_beds += parseInt(val.total_beds || 0);
          $scope.total_available_rooms += parseInt(val.available_units);
        });
      }

      // check if available seats is less than the total beds
      $scope.validation = {};
      $scope.checkAvailableSeats = function () {
        // if(!$scope.total_beds)
        //     return;
        $scope.total_beds = $scope.total_beds || 0;
        if ($scope.slotDetail.available_seat > $scope.total_beds) {
          $scope.maxAvailableSeatErr = true;
        } else {
          $scope.maxAvailableSeatErr = false;
        }
        if ($scope.total_available_rooms) { $scope.validation.available_rooms = false; }
      };
      $scope.groupSortableOptions = {
        placeholder: '.group-drag',
        connectWith: '.group-drag-container',
        update: callbackForGroupDragAndDrop,
        axis: 'y'
      };
      function callbackForGroupDragAndDrop (e, ui) {
        // saving target after drag drop
        $scope.model = ui.item.sortable.model;
        $scope.source = ui.item.sortable.sourceModel;
        $scope.target = ui.item.sortable.droptargetModel;
        var groupObject = [];
        $timeout(function () {
          // creating request data in api format
          $scope.target.forEach(function (val, i) {
            groupObject.push({
              id: val.id,
              display_order: i + CONST_HELPER.ONE
            });
          });
          changeGroupDisplayOrder(groupObject); // api call for saving order
          resetDragAndDropData(); // reset other data
        }, CONST_HELPER.ZERO);
      }
      function changeGroupDisplayOrder (groupObject) {
        $scope.showLoader();
        operatorCalendarService.changeGroupOrder(groupObject, $scope.user.token).then(function (success) {
          $scope.hideLoader();
        });
      }
      // Drag and drop Guest functionality
      $scope.sortableOptions = {
        placeholder: '.drag',
        connectWith: '.drag-container',
        update: callbackForDragAndDrop,
        axis: 'y'
      };
      function callbackForDragAndDrop (e, ui) {
        if (!ui.item.sortable.received || ui.item.sortable.source[0] === ui.item.sortable.droptarget[0]) {
          ui.item.sortable.cancel();
        } else {
          ui.item.sortable.droptargetModel = ui.item.sortable.droptargetModel || [];
          $scope.model = ui.item.sortable.model;
          $scope.source = ui.item.sortable.sourceModel;
          $scope.target = ui.item.sortable.droptargetModel;
          $scope.groupId = ui.item.sortable.droptargetList['0'].id;
          $scope.guestId = ui.item.sortable.model.id.toString();
          var groupObj = {
            'guest_id': $scope.guestId
          };
          if ($scope.groupId) {
            groupObj['group_id'] = $scope.groupId;
            addGuest(operatorCalendarService.addGuestToGroup, groupObj);
          } else {
            addGuest(operatorCalendarService.deleteGuestFromGroup, groupObj);
          }
        }
      }

      function addGuest (fn, groupObj) {
        $scope.showLoader();
        fn.apply(this, [groupObj, token]).then(function (res) {
          if (res.data.code === '200') {
            $scope.hideLoader();
            // getSlotData();

            changeGuestGroupUI();
            checkCollapse();
          } else if (res.data.code === '203') {
            $scope.logout();
            $scope.hideLoader();
          }
          resetDragAndDropData();
        }, function (error) {
          console.log(error);
        });
      }
      // Move guest div from source group to destination
      function changeGuestGroupUI () {
        $scope.target.push($scope.model);
        $scope.source.splice($scope.source.indexOf($scope.model), 1);
      }
      // open collapse group on dragging
      function checkCollapse () {
        var self = $('#' + $scope.groupId);
        var $nextEle = self.children('.mb-footer-inner');
        if ($nextEle.length === 0) { return; }

        if (!($nextEle.is(':visible'))) {
          $nextEle.slideDown('slow');

          self.find('.plus-icon').removeClass('plus-icon');
          self.find('div').addClass('minus-icon');
        }
      }
      // End Drag and drop Guest functionality

      //* ****** manual booking operator ******//

      // step1 : create a guest user if not exist and add slot in cart.
      // step2 : proceed to checkout just as it is happening for normal users...
      // step3 : proeed to payment without any saved cards...

      $rootScope.CartDetails = [];
      $scope.userWantToProceed = false;
      $scope.canEditGuestDetails = true;
      $scope.finalOperatorAmount = CONST_HELPER.ZERO;
      $scope.operatorTotalCommission = CONST_HELPER.ZERO;

      // watch the booking subtotal and recalculate the taxes and processing fee...
      $scope.$watch('manualBooking.subTotal', function (newTotal, oldTotal) {
        if (newTotal !== oldTotal) {
          calculateTax();
        }
      });

      // watch the booking subtotal and recalculate the taxes and processing fee...
      $scope.$watch('manualBooking.local_currency_code', function (newCurrency, oldCurrency) {
        if (newCurrency && oldCurrency && newCurrency !== oldCurrency) {
          $scope.convertCurrency(newCurrency, oldCurrency);
          $rootScope.selectedCurrencyCode = newCurrency;
        }
      });

      // calculate suggestedRetailPrice total
      function CalculateSuggestedRetailPrice () {
        $scope.suggestedRetailPrice = CONST_HELPER.ZERO;
        if ($scope.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) {
          // case C...
          $scope.suggestedRetailPrice = $scope.suggestedRetailPrice + $scope.selectedSlotDetails.adult_price;
        } else if ($scope.selectedSlotDetails.package.lodging_status === CONST_HELPER.EVENT_LODGING.NO || !$scope.selectedSlotDetails.slot_lodgings.length) {
          // case A without lodging...
          $scope.suggestedRetailPrice = $scope.suggestedRetailPrice + parseInt($scope.manualGuest.total_guest) * $scope.selectedSlotDetails.adult_price;
        } else {
          // case B && case A with lodging...
          if ($scope.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_SEAT) {
            $scope.selectedSlotDetails.slot_lodgings.forEach(function (lodging) {
              if (parseInt(lodging.selected_rooms) > 0) {
                $scope.suggestedRetailPrice = $scope.suggestedRetailPrice + lodging.sub_total;
              }
            });
          } else {
            $scope.selectedSlotDetails.slot_lodgings.forEach(function (lodging) {
              if (parseInt(lodging.selected_guests) > 0) {
                $scope.suggestedRetailPrice = $scope.suggestedRetailPrice + (lodging.selected_guests ? parseInt(lodging.selected_guests) : 1) * lodging.price;
              }
            });
          }
        }

        if ($scope.selectedSlotDetails.package.package_addons && $scope.selectedSlotDetails.package.package_addons.length) {
          $scope.selectedSlotDetails.package.package_addons.map(function (addon, index) {
            if (addon.isSelected) {
              $scope.suggestedRetailPrice = $scope.suggestedRetailPrice + (addon.addonAdults ? addon.addonAdults : CONST_HELPER.ZERO) * addon.adult_price;
            }
          });
        }
      }

      // calculate the Tax amount ...
      function calculateTax () {
        $scope.manualBooking.subTotal = $scope.manualBooking.subTotal ? $scope.manualBooking.subTotal : CONST_HELPER.ZERO;
        var taxPercent = $scope.operatorSlotData && $scope.operatorSlotData.operator_tax_cloud ? $scope.operatorSlotData.operator_tax_cloud.domestic_percent : CONST_HELPER.ZERO;
        $scope.manualBooking.taxes = calculateTaxesSummaryPrice({
          packagePrice: $scope.manualBooking.subTotal
        });
        $scope.manualBooking.total_taxes = calculateTaxesSummaryPrice({
          packagePrice: $scope.manualGuest.price
        });
        $scope.manualBooking.processingFee = $scope.manualBooking.subTotal > CONST_HELPER.ZERO ? calculateProcessingFee() : CONST_HELPER.ZERO;
        $scope.manualBooking.totalProcessingFee = $scope.manualBooking.subTotal > CONST_HELPER.ZERO ? calculateProcessingFee('total') : CONST_HELPER.ZERO;
        $scope.manualBooking.grandTotal = $scope.manualBooking.subTotal + $scope.manualBooking.taxes + $scope.manualBooking.processingFee;
      }

      // calculate processing fee
      function calculateProcessingFee (argument) {
        var processingFee = CONST_HELPER.ZERO; var total;
        var subTotal = argument === 'total' ? $scope.manualGuest.price : $scope.manualBooking.subTotal;
        var taxes = argument === 'total' ? $scope.manualBooking.total_taxes : $scope.manualBooking.taxes;

        total = (subTotal + taxes + CONST_HELPER.PROCESS_FEE.Ffixed) / (CONST_HELPER.ONE - CONST_HELPER.PROCESS_FEE.Fpercent);
        processingFee = total - (subTotal + taxes);
        return toFixed(processingFee, 2);
      }

      // get Addons details
      function getAddonsDetails () {
        var arr = [];
        if ($scope.selectedSlotDetails.package.package_addons && $scope.selectedSlotDetails.package.package_addons.length) {
          $scope.selectedSlotDetails.package.package_addons.map(function (addon) {
            if (addon.isSelected) {
              var obj = {
                package_addon_id: addon.id,
                package_addon_adult_quantity: addon.addonAdults,
                package_addon_adult_price: parseInt(addon.addonAdults) * addon.adult_price,
                package_addon_usd_adult_price: parseInt(addon.addonAdults) * addon.adult_price * $scope.packagePrevCurrencyRate * $scope.packageCurrencyCodeRate
              };
              arr.push(obj);
            }
          });
        }
        return arr;
      }

      // save the bookings into cart and proceed to checkout...
      $scope.addManualBookings = function (addguestForm) {
        console.debug('operatorEditSlot.addManualBookings: addguestForm: %O', addguestForm);

        $scope.selectedGuestError = false;
        if (!addguestForm.$valid || $scope.manualGuest.price <= 0 || (+$scope.manualGuest.is_deposit == 1 && $scope.manualGuest.deposit_price <= 0)) {
          $scope.addguestFormIsNotValid = true;
          return;
        }
        if ($scope.selectedSlotDetails.slot_lodgings.length && (!$scope.total_selected_guests || $scope.total_selected_guests < parseInt($scope.manualGuest.number_of_guest))) {
          $scope.selectedGuestError = true;
          return;
        }
        // if entered price is greater than retail price...
        // if($scope.manualGuest.price > ($scope.suggestedRetailPrice * $scope.packagePrevCurrencyRate * $scope.packageCurrencyCodeRate))
        //   return;

        var data = {
          user_name: $scope.manualGuest.name,
          user_email: $scope.manualGuest.email,
          user_notes: $scope.manualGuest.notes,
          package_id: $scope.selectedSlotDetails.package.id,
          cart_session_id: userAdventureDetailService.generateCartSessionId(),
          total_amount: $scope.manualBooking.grandTotal,
          local_currency_code: $scope.manualBooking.selectedPackage.currency.code,
          referral_id: '',
          package_addons: getAddonsDetails(),
          package_slot_id: $scope.selectedSlotDetails.id,
          booking_source: CONST_HELPER.MANUAL_PAID,
          is_nightly: nightly_slot_id ? CONST_HELPER.ONE : CONST_HELPER.ZERO
        };
        var token = $scope.user.token ? $scope.user.token : '';
        // if nightly then send selected dates...
        if ($stateParams.package_id) {
          data.from_date = $stateParams.start_date ? moment(new Date(+$stateParams.start_date)).format('YYYY/MM/DD') : undefined;
          data.to_date = $stateParams.end_date ? moment(new Date(+$stateParams.end_date)).format('YYYY/MM/DD') : undefined;
        }
        // for case C and case A without lodging...
        if ($scope.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE || $scope.selectedSlotDetails.package.lodging_status === CONST_HELPER.ZERO || !$scope.selectedSlotDetails.slot_lodgings.length) {
          data.package_slot = {
            package_slot_id: $scope.selectedSlotDetails.id,
            slot_adult_quantity: parseInt($scope.manualGuest.number_of_guest),
            slot_adult_price: $scope.selectedSlotDetails.adult_price,
            slot_usd_adult_price: $scope.selectedSlotDetails.adult_price / $scope.cc_rate_usd
          };
        } else if ($scope.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_SEAT && $scope.selectedSlotDetails.package.lodging_status === CONST_HELPER.EVENT_LODGING.YES && $scope.selectedSlotDetails.slot_lodgings.length) {
        // for case A with lodging...
          data.package_slot = {
            package_slot_id: $scope.selectedSlotDetails.id,
            slot_adult_quantity: CONST_HELPER.ZERO,
            slot_adult_price: CONST_HELPER.ZERO,
            slot_usd_adult_price: CONST_HELPER.ZERO
          };
          data.package_slot_lodgings = getSlotLodgingDetails(); // package case A with Lodging get slot lodging details
        } else { // for case B ...
          data.package_slot = {
            package_slot_id: $scope.selectedSlotDetails.id,
            slot_adult_quantity: parseInt($scope.manualGuest.number_of_guest),
            slot_adult_price: CONST_HELPER.ZERO,
            slot_usd_adult_price: CONST_HELPER.ZERO
          };
          data.package_slot_lodgings = getAccommodationDetails(); // package case  B get accomodation details...
        }

        console.debug('operatorEditSlot.addManualBookings: $scope: %O, $rootScope: %O, $cookies: %O', data, $rootScope, $cookies.getAll());
        console.debug('operatorEditSlot.addManualBookings: userAdventureDetailService.addToCart: data: %O, token', data, token);

        $scope.showLoader();
        userAdventureDetailService.addToCart(data, token).then(function (res) {
          if (res.data && res.data.message === 'Success') {
            $scope.cart = res.data;
            $cookies.put('cart_id', $scope.cart.cart_id);

            let gtagData = {
              currency: 'USD',
              value: res.data.cart.usd_total_amount,
              items: [
                {
                  item_id: res.data.cart.booking_id,
                  item_name: res.data.cart.package_name,
                  affiliation: res.data.cart.operator_name,
                  price: res.data.cart.usd_total_amount,
                  quantity: 1
                }
              ]
            };
            $rootScope.$broadcast('googleTagManagerAddToCart', gtagData);

            proceedTocheckout();
          }
        });
      };

      // get the usd rate against to user selected currency.
      $scope.convertCurrency = function (local_currency_code, package_currency_code) {
        $scope.showLoader();
        getPackageCurrencyRate(package_currency_code);
        userAdventureDetailService.convertCurrency(1, package_currency_code, local_currency_code).then(function (res) {
          $scope.hideLoader();
          $scope.packageCurrencyCodeRate = res.data.usd_rate;
        });
      };

      // get the usd rate against to PACKAGE currency.
      function getPackageCurrencyRate (prev_currency_code) {
        if(!$scope.manualBooking.selectedPackage){
          return;
        }
        userAdventureDetailService.convertCurrency(1, $scope.selectedSlotDetails.package.currency.code, prev_currency_code).then(function (res) {
          $scope.packagePrevCurrencyRate = res.data.usd_rate;
        });
      }

      // function to proceed for checkout...

      function proceedTocheckout () {
        var slot = $scope.selectedSlotDetails;
        var manualBooking = $scope.manualBooking;
        var manualGuest = $scope.manualGuest;
        // get the usd rate against to user selected currency.
        userAdventureDetailService.convertCurrency(1, CONST_HELPER.payment_const.default_currency, manualBooking.selectedPackage.currency.code).then(function (res) {
          if (res.data && parseInt(res.data.code) === 200) {
            $rootScope.CartDetails = [];
            var usdRate = res.data.usd_rate;
            $rootScope.usdRate = usdRate;
            var obj = {
              'slot_id': $scope.cart.cart_slot_id,
              'package_id': slot.package.id,
              'operator_id': slot.package.user_id,
              'tax_amount': toFixed((manualBooking.taxes / usdRate), 2),
              'local_tax_amount': manualBooking.taxes,
              'is_partial': manualGuest.is_deposit === '1' ? CONST_HELPER.payment_const.is_partial : CONST_HELPER.payment_const.is_full,
              'due_date': moment(slot.from_date).format('YYYY-MM-DD') || moment($scope.operatorSlotData.from_date).format('YYYY-MM-DD'),
              'processing_fee': manualBooking.processingFee / usdRate,
              'local_processing_fee': manualBooking.processingFee,
              'due_amount': manualGuest.is_deposit === '1' ? (manualGuest.price - manualGuest.deposit_price) / usdRate : 0,
              'local_due_amount': manualGuest.is_deposit === '1' ? (manualGuest.price - manualGuest.deposit_price) : 0,
              'slot_amount': manualGuest.is_deposit === '1' ? toFixed((manualGuest.deposit_price / usdRate), 2) : manualGuest.price / usdRate,
              'local_slot_amount': manualGuest.is_deposit === '1' ? (manualGuest.deposit_price) : manualGuest.price,
              'is_final_payment': manualGuest.is_deposit === '1' ? CONST_HELPER.payment_const.partial_payment : CONST_HELPER.payment_const.full_payment,
              'opr_percentage': slot.commission,
              'total_tax': manualBooking.total_taxes / usdRate,
              'local_total_tax': manualBooking.total_taxes,
              'total_slot_amount': toFixed((manualGuest.price / usdRate), 2),
              'local_total_slot_amount': manualGuest.price,
              'total_processing_fee': manualBooking.totalProcessingFee / usdRate,
              'local_total_processing_fee': manualBooking.totalProcessingFee,
              'cc_rate': usdRate,
              'slot_end_date': $filter('date')(slot.to_date, 'yyyy-MM-dd') || $scope.operatorSlotData.to_date,
              'referral_id': ''
            };
            $rootScope.CartDetails.push(obj);

            var cartData = {
              'promo_code_id': '',
              'cart_id': $scope.cart.cart_id,
              'total_amount': manualBooking.grandTotal,
              'base_amount': manualBooking.grandTotal - manualBooking.taxes - manualBooking.processingFee,
              'user_email': manualGuest.email
            };

            if (parseInt(manualGuest.is_deposit) === 1) {
              cartData.deposits = [{
                booking_slot_id: $scope.cart.cart_slot_id,
                is_deposit: true
              }];
            }

            // saving final cart value and proceed to checkout.
            userAdventureDetailService.proceedToCheckout(cartData, token).then(function (res) {
              if (res.data.message === 'Success') {
                $scope.hideLoader();
                setUpPaymentObjects();

                if (
                  !$scope.$parent.myCartItems
                  ||
                  !$scope.$parent.myCartItems.cart_slot_details
                ) {
                  // We need to fill this variable for Google Analitycs
                  $scope.showLoader();
                  userAdventureDetailService
                    .getCartItemslist(cartData.cart_id, $rootScope.user.token)
                    .then(function(cartItemsListResponse) {
                      if (cartItemsListResponse.data.code === CONST_HELPER.API_RESPONSE.OK) {
                        if (!$scope.$parent.myCartItems) {
                          $scope.$parent.myCartItems = {
                            cart_slot_details: []
                          };
                        }

                        $scope.$parent.myCartItems.cart_slot_details = cartItemsListResponse.data.cart_slot_details;

                        // TODO: Remove in future saving cartID to cookie
                        if (cartItemsListResponse.data.cart_details && cartItemsListResponse.data.cart_details.id) {
                          var now = new Date();
                          var exp = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
                          $cookies.put('cart_id', cartItemsListResponse.data.cart_details.id, { expires: exp });
                        }
                      } else {
                        $scope.showPopup('#serverErrModal');
                      }
                    }, function() {
                      $scope.showPopup('#serverErrModal');
                    })
                    .finally(function () {
                      $scope.hideLoader();
                    });
                }
              }
            });
          }
        });
      }

      function setUpPaymentObjects (argument) {
        $scope.userWantToProceed = true;
        $scope.canEditGuestDetails = false;
        $scope.hasSingleOperator = CONST_HELPER.ONE;
        $scope.operatorTotalCommission = $scope.manualBooking.subTotal > 0 ? $scope.manualBooking.subTotal * ($scope.selectedSlotDetails.commission || 0) / 100 : 0;
        $scope.finalConnectOperatorAmount = $scope.manualBooking.grandTotal - $scope.manualBooking.processingFee - $scope.operatorTotalCommission;
        var operatorBankAccountCurrencyCode = $scope.selectedSlotDetails.operator_profile ? $scope.selectedSlotDetails.operator_profile.default_account_currency_code : null;
        if (operatorBankAccountCurrencyCode) {
          $scope.getSlotUsdRate(operatorBankAccountCurrencyCode, $rootScope.selectedCurrencyCode, function (currencyRate) {
            $scope.finalOperatorAmount = $scope.finalConnectOperatorAmount && currencyRate ? $scope.finalConnectOperatorAmount / $scope.usdRate / currencyRate : 0;
          });
        }
        $scope.GrandTotal = $scope.manualBooking.grandTotal;
      }

      // return the slot accomodation object for adding slot in cart
      function getAccommodationDetails () {
        var arr = [];
        if ($scope.selectedSlotDetails.slot_lodgings && $scope.selectedSlotDetails.slot_lodgings.length !== CONST_HELPER.ZERO) {
          $scope.selectedSlotDetails.slot_lodgings.forEach(function (lodging) {
            if (parseInt(lodging.selected_guests) > 0) {
              lodging.usd_price = toFixed((lodging.price / $scope.cc_rate_usd), 2);
              var obj = {
                slot_lodging_id: lodging.id,
                quantity: lodging.selected_guests,
                price: lodging.selected_guests * lodging.price,
                usd_price: lodging.selected_guests * lodging.usd_price,
                private_price: CONST_HELPER.ZERO,
                usd_private_price: CONST_HELPER.ZERO
              };
              arr.push(obj);
            }
          });
        }
        return arr;
      }

      // return the processing fee...
      function calculateRemainingProcessingFee (remainingAmount, remainingTax) {
        var remainingProcessingFee = CONST_HELPER.ZERO; var total;
        total = (remainingAmount + remainingTax + CONST_HELPER.PROCESS_FEE.Ffixed) / (CONST_HELPER.ONE - CONST_HELPER.PROCESS_FEE.Fpercent);
        remainingProcessingFee = total - (remainingAmount + remainingTax);
        return toFixed(remainingProcessingFee, 2);
      }
      // return number value with round off value...
      function toFixed (num, len) {
        return Number(num.toFixed(len));
      }

      // enable all fields so operator can edit guest details...
      $scope.enableGuestDetails = function () {
        $scope.showLoader();
        $scope.canEditGuestDetails = true;
        $scope.userWantToProceed = false;
        var cartData = {
          'promo_code_id': '',
          'cart_id': $scope.cart.cart_id
        };
        userAdventureDetailService.releaseSeats(cartData, $scope.user.token).then(function (res) {
          if (res.data && res.data.message === 'Success') {
            $scope.hideLoader();
          }
        }, function () {

        });
      };

      $rootScope.$on('$stateChangeSuccess', function (to, from) {
        if ($rootScope.currentState.name !== 'operator-manual-booking' && $rootScope.previousState.name === 'operator-manual-booking' && $scope.cart && $scope.cart.cart_id) {
          $scope.enableGuestDetails();
        }
      });

      // FUCNTION TO PROCEED THE REMAINING MANUAL PAYMENT;
      $scope.proceedToRemainingPayment = function (argument) {
        setUpPaymentObjects();
      };
    }]);
