/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:onMySiteCtrl
 * @description
 * # onMySiteCtrl for opeartor to white label the packages.
 * Controller of the heliApp
 */
angular.module('heliApp')
  .controller('onMySiteCtrl', ['$scope', '$rootScope', '$compile', '$timeout', '$window', '$state', '$stateParams', '$sce', 'GOOGLE_API_KEY','GOOGLE_API_KEY_WEB_FONTS', 'onMySiteService', 'userAdventureDetailService', 'CONST_HELPER', '$location',
    function ($scope, $rootScope, $compile, $timeout, $window, $state, $stateParams, $sce, GOOGLE_API_KEY,GOOGLE_API_KEY_WEB_FONTS, onMySiteService, userAdventureDetailService, CONST_HELPER, $location) {
      $scope.isShowTutorial = true; // Show tutorial on the first time for the operator
      $scope.ishideTutorial = false; // Show get code for the operator when no data exist
      $scope.packageList = ''; // Store the list of all active and public packages of the operator
      $scope.package = {};
      $scope.deleteingPackageId = null;
      $scope.invalidHeaderText = false;
      $scope.invalidFooterText = false;
      $scope.FullViewPackage = {};
      $scope.package.selected_package_id = ''; // Store package Id of the selected package from the dropdown
      $scope.adventureDetails = ''; // Store package details of the selected package from the dropdown
      $scope.generatedCodeList = []; // Store the list of package code generated by operator
      $scope.googleApiKey = GOOGLE_API_KEY;
      $scope.isFullPageView = undefined;
      $scope.buttonColor = '#FF0000';
      $scope.pageLogo = null;
      $scope.editingDomain = false;
      $scope.textColor = '#FF0000';
      $scope.domainAdded = false;
      $scope.domainSubmitted = false;
      $scope.isVerifiedDomain = false;
      $scope.fontOptions = [];
      $scope.fullPageFormSubmitted = false;
      $scope.invalidCallbackUrl = false;
      $scope.invalidSubDomainUrl = false;
      $scope.invalidDomainUrl = false;
      $scope.showAdventureList = true;
      $scope.FullPageData = {
        id: null,
        'domain_name': '',
        'sub_domain_name': '',
        'page_title': '',
        'page_desc': '',
        'head_font': '',
        'body_font': '',
        'button_color': '#000000',
        'button_text_color': '#FFFFFF',
        'header_tracking': '',
        'footer_tracking': '',
        'callback_url': '',
        'is_verified': CONST_HELPER.ZERO,
        'page_logo': '',
        'operator_default_site': CONST_HELPER.ZERO
      };
      setDefaultFontFamily();
      $scope.dbDomainName = '';
      $scope.dbSubDomainName = '';
      $scope.hosturl = $window.origin ? $window.origin : $window.location.origin;
      $scope.white_label_head = "<script src='" + $scope.hosturl + '/api/webroot/white-label/heliwhitelablescript.js' + "'></script>";
      var featuredImageElement = angular.element(document.getElementById('banner-image'));
      if (!$scope.isFullPageView) {
        getWhiteLabelPackages();
      } else {
        getWhiteLabelPackagesDomain();
      }
      $scope.selectPackageError = false; // Check whether the package is selected or not from the dropdown
      $scope.hideTutorial = function () {
        $scope.isShowTutorial = false;
        $scope.ishideTutorial = true;
      };
      var copiedSlots; // copy of all slots available
      // Check whether the package is selected from drop down
      $scope.checkSelectedPackage = function (packageId) {
        if (packageId) {
          $scope.package.selected_package_id = packageId;
          $scope.selectPackageError = false;
        }
      };

      $scope.checkSelectedPackageFullView = function (packageId) {
        if (packageId) {
          $scope.FullViewPackage.selected_package = packageId;
          $scope.selectPackageError = false;
        }
      };

      function getStatusPackage (status) {
         return status == 1 ? 'active' : 'expired';
      }

      // Generate Code which is to be used for white lable to third party website
      function getSourceCode (modalId) {
        var source = '';
        if ($scope.adventureDetails.package_type[0] == CONST_HELPER.PACKAGE_TYPE.PRIVATE || $scope.adventureDetails.package_type == CONST_HELPER.PACKAGE_TYPE.PRIVATE) {
          source = $scope.hosturl + '/adventure-detail/private/' + $scope.getSeoFriendlyURL($scope.adventureDetails.operator_details.business_name) +
          '/' + $scope.getSeoFriendlyURL($scope.adventureDetails.name) + '/' + $scope.adventureDetails.id + '/' + getStatusPackage($scope.adventureDetails.status);
        } else {
          source = $scope.hosturl + '/adventure-detail/' + $scope.getSeoFriendlyURL($scope.adventureDetails.operator_details.business_name) +
          '/' + $scope.getSeoFriendlyURL($scope.adventureDetails.name) + '/' + $scope.adventureDetails.id + '/' + getStatusPackage($scope.adventureDetails.status);
        }

        $scope.packageCode = "<a href='#' data-source='" + source + "' onclick='startIframe(this);'>Book Now</a>";
        if (modalId) {
          if (modalId === 'save-code') {
            saveOperatorGeneratedCode($scope.adventureDetails.id);
          } else {
            $('#' + modalId).modal('show');
            $scope.hideLoader();
          }
        }
      }

      $.getJSON('https://www.googleapis.com/webfonts/v1/webfonts?key=' + GOOGLE_API_KEY_WEB_FONTS, function(data) {
        $scope.showLoader();
        $scope.fontOptions = [];
        $scope.familyNames = [];
        if(data){
          $scope.hideLoader();
          $scope.fontOptions = data.items;
          $scope.fontOptions.forEach(function (family) {
            $scope.familyNames.push(family.family);
          });
          // Set fonts initally
          $scope.FullPageData.head_font = data.items[CONST_HELPER.ZERO];
          $scope.FullPageData.body_font = data.items[CONST_HELPER.ZERO];
          setDefaultFontFamily();
        } else {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        };
      });


      function loadWebFonts (familyNames) {
        // LOAD ALL FONT FAMILIES
        var allNames = familyNames;
        while (allNames && allNames.length > CONST_HELPER.ZERO) {
          familyNames = allNames.splice(CONST_HELPER.ZERO, 10);
          WebFont.load({
            google: {
              families: familyNames
            },
            classes: false
          });
        }
      }

      // fetch the package details and show the the modal box
      $scope.showCodeAndPreview = function (packageId, modalId) {
        $scope.selectPackageError = false;
        if (!packageId) {
          $scope.selectPackageError = true;
          return;
        }
        $scope.getAdventureDetails(packageId, modalId);
      };

      $scope.saveFullPageData = function () {
        $scope.fullPageFormSubmitted = true;
        var hexRegexp = /^#[0-9A-F]{6}$/i;
        var pattern = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
        var invaidForm = false;
        if (!$scope.FullPageData.page_desc || !$scope.FullPageData.page_title || ($scope.FullPageData.page_title && $scope.FullPageData.page_title.length > 200) || ($scope.FullPageData.page_desc && $scope.FullPageData.page_desc.length > 1000)) {
          // INVALID button HEX COLOR
          invaidForm = true;
        } else {
          invaidForm = false;
        }

        if (!$scope.FullPageData.button_color || !hexRegexp.test($scope.FullPageData.button_color)) {
          // INVALID button HEX COLOR
          $scope.invalidHexButton = true;
        } else {
          $scope.invalidHexButton = false;
        }

        if (!$scope.FullPageData.button_text_color || !hexRegexp.test($scope.FullPageData.button_text_color)) {
          // INVALID button_text_color HEX COLOR
          $scope.invalidHexButtonText = true;
        } else {
          $scope.invalidHexButtonText = false;
        }

        if ($scope.FullPageData.header_tracking && $scope.FullPageData.header_tracking != '') {
          var text = $scope.FullPageData.header_tracking.trim();
          if (text && (!text.startsWith('<script') && !text.endsWith('</script>'))) {
            $scope.FullPageData.header_tracking = '<script>' + text + '</script>'
            text = $scope.FullPageData.header_tracking.trim();
            $scope.invalidHeaderText = false;
          }
          if (text && (!text.startsWith('<script') || !text.endsWith('</script>'))) {
            // INVALID button_text_color HEX COLOR
            $scope.invalidHeaderText = true;
          } else {
            $scope.invalidHeaderText = false;
          }
        } else {
          $scope.invalidHeaderText = false;
        }

        if ($scope.FullPageData.footer_tracking && $scope.FullPageData.footer_tracking != '') {
          var text = $scope.FullPageData.footer_tracking.trim();
          if (text && (!text.startsWith('<script') && !text.endsWith('</script>'))) {
            $scope.FullPageData.footer_tracking = '<script>' + text + '</script>'
            text = $scope.FullPageData.footer_tracking.trim();
            $scope.invalidHeaderText = false;
          }
          if (text && (!text.startsWith('<script') || !text.endsWith('</script>'))) {
            // INVALID button_text_color HEX COLOR
            $scope.invalidFooterText = true;
          } else {
            $scope.invalidFooterText = false;
          }
        } else {
          $scope.invalidFooterText = false;
        }

        if (!$scope.FullPageData.callback_url || !pattern.test($scope.FullPageData.callback_url)) {
          // INVALID CALBACK URL
          $scope.invalidCallbackUrl = true;
        } else {
          $scope.invalidCallbackUrl = false;
        }

        if (invaidForm || $scope.invalidHexButton || $scope.invalidHexButtonText || $scope.invalidCallbackUrl || $scope.invalidHeaderText || $scope.invalidFooterText) {
          // INVALID FORM DATA
          return;
        }

        // Call api
        saveFormApi(createFullPageRequestData());
      };

      $scope.openEditPage = function () {
        loadWebFonts($scope.familyNames);
        $scope.showAdventureList = false;
        $scope.initiateFileSelect();
      };

      function getFullPageSiteData () {
        $scope.showLoader();
        onMySiteService.getFullPageSiteData($scope.user.token).then(function (resp) {
          // $scope.hideLoader();
          $scope.FullPageData = resp.data.data;
          $scope.FullPageData.domain_name = $scope.removeHttps($scope.FullPageData.domain_name);
          $scope.dbDomainName = $scope.FullPageData.domain_name;
          $scope.FullPageData.sub_domain_name = $scope.removeHttps($scope.FullPageData.sub_domain_name);
          $scope.dbSubDomainName = $scope.FullPageData.sub_domain_name;
          $scope.FullPageData.callback_url = $scope.removeHttps($scope.FullPageData.callback_url);
          $scope.FullPageData.head_font = $scope.fontOptions.find(function (fonts) {
            return fonts.family == $scope.FullPageData.head_font;
          });
          $scope.FullPageData.body_font = $scope.fontOptions.find(function (fonts) {
            return fonts.family == $scope.FullPageData.body_font;
          });
          $scope.FullPageData.page_logo = resp.data.data.page_logo_url;
          if ($scope.FullPageData && ($scope.FullPageData.domain_name || $scope.FullPageData.domain_name != '') && ($scope.FullPageData.sub_domain_name || $scope.FullPageData.sub_domain_name != '')) {
            $scope.domainAdded = true;
          } else {
            $scope.domainAdded = false;
          }
        }, function () {
          // $scope.hideLoader();
        });
      }

      $scope.callInitialApi = function () {
        // TODO: CALL DETAILS API HERE
        //  SET DOMAIN NAME AND SUB DOMAIN TEXT FROM DB.
        $scope.showLoader();
        userAdventureDetailService.getSiteInfo($scope.user.token)
          .then(function (responce) {
            $scope.hideLoader();
            if (responce.data.code == CONST_HELPER.API_RESPONSE.OK) {
              $scope.ishideTutorial = true;
              $scope.isShowTutorial = false;
              $scope.isFullPageView = responce.data.data.type == 'full-screen';
              if ($scope.isFullPageView) {
                $scope.showAdventureList = true;
                getFullPageSiteData();
                getOperatorSitesList();
                getWhiteLabelPackagesDomain();
              };
            } else {
              $scope.showAdventureList = false;
              $scope.isFullPageView = undefined;
              $scope.ishideTutorial = false;
              $scope.isShowTutorial = true;
            }
            $scope.initiateFileSelect();
          }, function () {
            $scope.hideLoader();
          });
        // $scope.dbDomainName = '';
        // $scope.dbSubDomainName = '';
      };

      function getOperatorSitesList () {
        onMySiteService.getOperatorSiteList($scope.user.token).then(function (data) {
          $scope.hideLoader();
          $scope.adventuresList = [];
          if (data.data.code == CONST_HELPER.API_RESPONSE.OK) {
            $scope.adventuresList = data.data.data;
            var generatedPackageIds = $scope.adventuresList.map(function (code) {
              return code.package_id;
            });
            $scope.filteredPackageList = $scope.packageList && $scope.packageList.filter(function (packageData) {
              return !generatedPackageIds.includes(packageData.package_id);
            });
          }
        }, function () {
          $scope.hideLoader();
        });
      }

      $scope.addPackageToWebsite = function () {
        if (!$scope.FullViewPackage.selected_package) {
          $scope.selectPackageError = true;
          return;
        }
        var packName = $scope.getSeoFriendlyURL($scope.FullViewPackage.selected_package.name);
        var requestObj = {
          package_id: $scope.FullViewPackage.selected_package.package_id
        };
        $scope.showLoader();
        onMySiteService.addPackageToFullPage(requestObj, $scope.user.token).then(function (resp) {
          $scope.hideLoader();
          getOperatorSitesList();
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };

      $scope.confirmDeletePackage = function (id) {
        $scope.deleteingPackageId = id;
        $scope.showPopup('#deleteAdventureFullPage');
      };

      $scope.deletePackageFromFullPage = function (package_id) {
        if (package_id) {
          $scope.showLoader();
          onMySiteService.deletePackageFromFullPage(package_id, $scope.user.token).then(function (resp) {
            $scope.hideLoader();
            // remove package from selected list
            $scope.adventuresList = $scope.adventuresList.filter(function (pack) {
              return pack.id != package_id;
            });
            getOperatorSitesList();
            filterPackages();
          }, function () {
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
        }
      };

      $scope.sendEmailVerification = function(emailDomain) {
        var emailPattern = /^[a-z0-9]+([\.]{1}[a-z0-9]+)?$/;
        if(emailDomain && emailPattern.test(emailDomain)){
          $scope.showEmailDomainError = false;
          var data = {
            email: emailDomain
          }
          $scope.showLoader();
          onMySiteService.sendEmailVerification(data, $scope.user.token).then(function (resp) {
            $scope.hideLoader();
            console.log(resp)
            if(resp.data.code == CONST_HELPER.API_RESPONSE.OK){
              $scope.editDomainEmailVerification = false;
              $scope.FullPageData.email = emailDomain;
              $scope.FullPageData.email_status = resp.data.data.email_status;
            }
          }, function () {
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
        }else{
          $scope.showEmailDomainError = true;
          return;
        }
      }

      $scope.addDomain = function (isUpdate) {
        $scope.domainSubmitted = true;
        var pattern = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/; // fragment locator

        if (!$scope.FullPageData.domain_name || !pattern.test($scope.FullPageData.domain_name)) {
          $scope.invalidDomainUrl = true;
        } else {
          $scope.invalidDomainUrl = false;
        }
        if (!$scope.FullPageData.sub_domain_name || !pattern.test($scope.FullPageData.sub_domain_name) || ($scope.FullPageData.sub_domain_name.includes('www.') && ($scope.FullPageData.sub_domain_name.match(new RegExp(/\./g)) || []).length <= 2)) {
          $scope.invalidSubDomainUrl = true;
        } else {
          $scope.invalidSubDomainUrl = false;
        }

        if ($scope.invalidSubDomainUrl || $scope.invalidDomainUrl) {
          return;
        }

        // Call api
        saveFormApi(createFullPageRequestData(true, isUpdate), true);
      };

      function createFullPageRequestData (onlyDomainData, isDomainUpdate) {
        var requestObj = {};
        // Domain Data

        if ($scope.FullPageData.domain_name && $scope.FullPageData.domain_name != '' && $scope.FullPageData.sub_domain_name && $scope.FullPageData.sub_domain_name != '') {
          requestObj.domain_name = 'https://' + $scope.FullPageData.domain_name;
          requestObj.sub_domain_name = 'https://' + $scope.FullPageData.sub_domain_name;
          // IF DOMAIN DATA IS SET WE SEND CUSTOM DOMAIN AS 0
          requestObj.operator_default_site = CONST_HELPER.ZERO;
        } else {
          requestObj.operator_default_site = CONST_HELPER.ONE;
          requestObj.domain_name = 'https://' + $scope.user.username + '.com';
          requestObj.sub_domain_name = 'https://' + $scope.user.username + '.' + $location.host();
        }

        // IN CASE OF UPDATE VERIFIED FALSE
        if (isDomainUpdate) {
          $scope.FullPageData.is_verified = false;
        }

        requestObj.is_verified = $scope.FullPageData.is_verified ? CONST_HELPER.ONE : CONST_HELPER.ZERO;
        // Domain Data END

        // FORM DATA
        if (!onlyDomainData) {
          requestObj.page_title = $scope.FullPageData.page_title;
          requestObj.page_desc = $scope.FullPageData.page_desc;
          requestObj.head_font = $scope.FullPageData.head_font.family;
          requestObj.body_font = $scope.FullPageData.body_font.family;
          requestObj.button_color = $scope.FullPageData.button_color;
          requestObj.button_text_color = $scope.FullPageData.button_text_color;
          requestObj.header_tracking = $scope.FullPageData.header_tracking;
          requestObj.footer_tracking = $scope.FullPageData.footer_tracking;
          requestObj.callback_url = $scope.FullPageData.callback_url && $scope.FullPageData.callback_url != '' ? 'https://' + $scope.FullPageData.callback_url : $scope.FullPageData.callback_url;
        }
        // FORM DATA END

        // IF ADD ID = NULL AND EDIT ID IS MUST
        if ($scope.FullPageData.id) {
          requestObj.id = $scope.FullPageData.id;
        }

        return requestObj;
      }

      function setDefaultFontFamily () {
        if ($scope.fontOptions && $scope.fontOptions.length) {
          var _head_font = $scope.fontOptions.find(function (font) {
            return (font.family).toLowerCase() == (CONST_HELPER.DEFAULT_HEAD_FAMILY).toLowerCase();
          });
          _head_font ? $scope.FullPageData.head_font = _head_font : null;
          var _body_font = $scope.fontOptions.find(function (font) {
            return (font.family).toLowerCase() == (CONST_HELPER.DEFAULT_BODY_FAMILY).toLowerCase();
          });
          _body_font ? $scope.FullPageData.body_font = _body_font : null;
        }
      }

      function saveFormApi (requestObj, addDomain) {
        $scope.showLoader();
        onMySiteService.saveFullPageData(requestObj, $scope.pageLogo, $scope.user.token).then(function (data) {
          $scope.hideLoader();
          if (data.data.code == CONST_HELPER.API_RESPONSE.OK || data.data.code == CONST_HELPER.API_RESPONSE.CREATED) {
            $scope.FullPageData.id = data.data.data.id;
            if (addDomain) {
              $scope.domainAdded = true;
              $scope.dbDomainName = $scope.FullPageData.domain_name;
              $scope.FullPageData.domain_name = $scope.removeHttps($scope.FullPageData.domain_name);
              $scope.dbSubDomainName = $scope.FullPageData.sub_domain_name;
              $scope.FullPageData.sub_domain_name = $scope.removeHttps($scope.FullPageData.sub_domain_name);
              $scope.FullPageData.callback_url = $scope.removeHttps($scope.FullPageData.callback_url);
              $scope.domainSubmitted = false;
            } else {
              $scope.fullPageFormSubmitted = false;
              $state.reload();
            }
          } else if (data.data.code == CONST_HELPER.API_RESPONSE.UNPROCESSABLE) {
            if (addDomain) {
              // this is case when domain already exists in the system.
              $scope.showPopup('#addDomainErrModal');
            } else {
              $scope.showPopup('#serverErrModal');
            }
          }
        }, function () {

        });
      }

      $scope.removeHttps = function (str) {
        return str && str.indexOf('https://') != CONST_HELPER.MINUS_ONE ? str.replace('https://', '') : str;
      };

      $scope.editCurrentDomain = function () {
        $scope.domainAdded = false;
        $scope.editingDomain = true;
      };

      $scope.editEmailVerification = function () {
        $scope.editDomainEmailVerificationAdded = false;
        $scope.editDomainEmailVerification = true;
      };

      $scope.cancelEditingEmail = function () {
        $scope.editDomainEmailVerification = false;
      }

      $scope.cancelEditing = function () {
        $scope.domainAdded = true;
        $scope.editingDomain = true;
        // $scope.dbDomainName = '';
        // $scope.dbSubDomainName = '';
        // RESET TO DB VALUES OF DOMAIN AND SUB DOMAIN
        $scope.FullPageData.sub_domain_name = $scope.dbSubDomainName;
        $scope.FullPageData.domain_name = $scope.dbDomainName;
      };

      $scope.removeDomainConnection = function () {
        $scope.showPopup('#removeCustomDomainModal');
      };

      $scope.showChangeWidget = function () {
        $scope.showPopup('#changeWidgetType');
      };

      $scope.removeDomain = function () {
        // TODO CALL API FOR REMOVE DOMAIN
        $scope.showLoader();
        onMySiteService.switchViewType($scope.user.token).then(function (responce) {
          $scope.hideLoader();
          if (responce.data && (responce.data.code == CONST_HELPER.API_RESPONSE.OK || responce.data.code == CONST_HELPER.API_RESPONSE.NO_CONTENT)) {
            if ($scope.isFullPageView) {
              $scope.showAdventureList = false;
            }
            $scope.dbDomainName = '';
            $scope.FullPageData.id =  null;
            $scope.FullPageData.domain_name =  "";
            $scope.FullPageData.sub_domain_name = "";
            $scope.FullPageData.is_verified = CONST_HELPER.ZERO;
            // setDefaultFontFamily();
            $scope.showAdventureList = false;
            $scope.domainAdded = false;
            $scope.editingDomain = false;
          }
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };

      $scope.saveEmptyDomain = function (params) {
        // $scope.FullPageData.id =  null;
        $scope.FullPageData.domain_name =  "";
        $scope.FullPageData.sub_domain_name = "";
        $scope.FullPageData.is_verified = CONST_HELPER.ZERO;
        var obj = createFullPageRequestData(true, true)
        saveFormApi(createFullPageRequestData(true, true));
        $scope.FullPageData.domain_name =  obj.domain_name;
        $scope.FullPageData.sub_domain_name = obj.sub_domain_name;
      }

      $scope.changeWidgetType = function () {
        $scope.selectPackageError = false;
        $scope.showLoader();
        onMySiteService.switchViewType($scope.user.token).then(function (responce) {
          $scope.hideLoader();
          if (responce.data && (responce.data.code == CONST_HELPER.API_RESPONSE.OK || responce.data.code == CONST_HELPER.API_RESPONSE.NO_CONTENT)) {
            $scope.isFullPageView = !$scope.isFullPageView;
            // if ($scope.isFullPageView) {
            //   $scope.showAdventureList = false;
            // }
            $scope.showAdventureList = false;
            $scope.hideTutorial = true;
            $scope.showTutorial = true;
            $scope.domainAdded = false;
            $scope.isFullPageView = undefined;
            $scope.domainSubmitted = false;
            $scope.FullPageData = {
              id: null,
              'domain_name': '',
              'sub_domain_name': '',
              'page_title': '',
              'page_desc': '',
              'head_font': $scope.fontOptions[CONST_HELPER.ZERO],
              'body_font': $scope.fontOptions[CONST_HELPER.ZERO],
              'button_color': '#000000',
              'button_text_color': '#FFFFFF',
              'header_tracking': '',
              'footer_tracking': '',
              'callback_url': '',
              'is_verified': CONST_HELPER.ZERO,
              'page_logo': '',
              'operator_default_site': CONST_HELPER.ZERO
            };
            setDefaultFontFamily();
          }
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };

      $scope.verifyDomainConnectionFinal = function () {
        $scope.FullPageData.is_verified = CONST_HELPER.ONE;
        // TODO CALL API BEFORE VERIFYING DOMAIN
        // onMySiteService.apiToUpdateDomain(data, $scope.user.token).then(function (data){
        //   console.log(data);
        //  },function (err){ })
        saveFormApi(createFullPageRequestData(true), true);
      };

      $scope.verifyDomainConnection = function () {
        $scope.showPopup('#verify_connection');
      };

      $scope.logoUpload = function (evt, imageType) { // logo upload method
        var file = evt.currentTarget.files[CONST_HELPER.ZERO];
        var fileExtnArr = file.name.split('.');
        var extn = fileExtnArr[fileExtnArr.length - CONST_HELPER.ONE].toLowerCase();
        if (extn === 'png' || extn === 'jpg' || extn === 'jpeg') { // check valid file extention
          var reader = new FileReader();
          reader.onload = function (evt) {
            $scope.$apply(function ($scope) {
              $scope.FullPageData.page_logo = evt.currentTarget.result;
              $scope.pageLogo = file;
              $timeout(angular.noop);
            });
          };
          reader.readAsDataURL(file);
        } else {
          return false;
        }
      };

      $scope.initiateFileSelect = function () {
        angular.element(document.querySelector('#page_logo')).on('change', function (event) {
          $scope.logoUpload(event, 'original');
        });
      };

      // Called when the package value changes from drop down
      $scope.getAdventureDetails = function (selectedAdventureId, modalId) {
        if (selectedAdventureId === $scope.adventureDetails.id) {
          getSourceCode(modalId);
          return;
        }
        var adventurePackage = {
          package_id: selectedAdventureId,
          userToken: $scope.user.token
        };
        $scope.showLoader();
        $scope.slickHeliLodgingConfig = {};
        $scope.adventureDetails = {};
        adventurePackage.currency_code = $rootScope.localCurrencyCode ? $rootScope.localCurrencyCode : '';
        userAdventureDetailService.getAdventureDetails(adventurePackage).then(function (res) {
          if (res.data.code === CONST_HELPER.API_RESPONSE.OK) {
            resetSlickConfig();
            $scope.checkExpiredSlots([res.data.package]); // check for expired slots...
            $scope.adventureDetails = res.data.package;
            copiedSlots = angular.copy($scope.adventureDetails.package_slots);
            getFeaturedImage(res.data.package.package_medias, featuredImageElement);
            $scope.currentLodging = $scope.adventureDetails.lodgings.length === CONST_HELPER.ONE ? $scope.adventureDetails.lodgings[CONST_HELPER.ZERO] : ''; // set currentLodging if only slot is available...
            adventureVideoFirst(res.data.package.package_medias); // make adventure video position first...
            getSourceCode(modalId);
          } else {
            $scope.hideLoader();
          }
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };

      function resetSlickConfig (argument) {
        // lodging changes goes here ...
        var packageSlot;
        $scope.slickHeliLodgingConfig = {
          enabled: true,
          autoplay: false,
          draggable: false,
          slidesToShow: CONST_HELPER.ONE,
          slidesToScroll: CONST_HELPER.ONE,
          event: {
            init: function (event, slick) {
              slick.slickGoTo(CONST_HELPER.ZERO); // slide to correct index when init
            },
            beforeChange: function (event, slick, currentSlide, nextSlide) {
              $scope.currentLodging = $scope.adventureDetails.lodgings[nextSlide - CONST_HELPER.ONE];
              // if(nextSlide===0 && packageSlot.length || vm.adventureDetails.package_slots===0)
              $scope.adventureDetails.package_slots = copiedSlots;

              if (!$scope.currentLodging) {
                return;
              } // if no current lodging selected

              packageSlot = angular.copy($scope.adventureDetails.package_slots);
              var filteredLodgingSlot = packageSlot.filter(function (slot) {
                return slot.slot_lodgings.map(function (l) {
                  return l.lodging_id;
                }).indexOf($scope.currentLodging.id) !== CONST_HELPER.MINUS_ONE;
              });
              if (Array.isArray(filteredLodgingSlot)) {
                $scope.adventureDetails.package_slots = filteredLodgingSlot;
              }
            }
          }
        };
      }

      // Save the Code generated for white label package by the operator
      function saveOperatorGeneratedCode (packageId) {
        $scope.showLoader();
        var data = {
          code: $scope.packageCode,
          package_id: packageId
        };
        onMySiteService.saveGeneratedCode(data, $scope.user.token).then(function (response) { // send req for orders
          if (response.data.code === CONST_HELPER.API_RESPONSE.OK) { // success response
            $scope.packageCode = '';
            $scope.adventureDetails = '';
            $scope.package.selected_package_id = '';
            getOperatorGeneratedCodeList();
          } else if (response.data.code === CONST_HELPER.API_RESPONSE.NON_AUTHORITATIVE) {
            $scope.logout(); // unauthorised user
            $state.go('signin');
            $scope.hideLoader();
          }
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      }

      // get the featured image from package media
      function getFeaturedImage (media, element) {
        var featuredimage;
        media.map(function (obj) {
          if (obj.media_type === CONST_HELPER.ZERO && obj.is_main === CONST_HELPER.ONE) {
            featuredimage = obj.url;
          }
        });
        element[CONST_HELPER.ZERO].style.backgroundImage = 'url(' + featuredimage + ')';
      }

      // make adventure video position first...
      function adventureVideoFirst (packageMedias) {
        var packageVideos = [];
        var packageImages = [];
        packageMedias.map(function (media, key) {
          if (media.media_type === CONST_HELPER.ONE) {
            packageVideos.push(media);
          } else {
            packageImages.push(media);
          }
        });
        $scope.adventureDetails.package_medias = packageVideos.concat(packageImages);
      }

      $scope.itinerary_limit = 7;
      $scope.showLess = false;
      $scope.loadMoreItinerary = function () {
        $scope.showLess = !$scope.showLess;
        if ($scope.showLess) {
          $scope.itinerary_limit = $scope.adventureDetails.package_day_details.length;
        } else {
          $scope.itinerary_limit = 7;
        }
      };

      $scope.trustAsHtml = function (string) {
        return $sce.trustAsHtml(string);
      };

      // fetch the active packages of the operator for white label
      function getWhiteLabelPackages () {
        $scope.showLoader();
        onMySiteService.getWhiteLabelPackages($scope.user.token).then(function (response) { // get req for operator packages
          if (response.data && response.data.response) { // success response
            $scope.packageList = response.data.response.docs;
            getOperatorGeneratedCodeList();
          } else if (response.data.code === CONST_HELPER.API_RESPONSE.NON_AUTHORITATIVE) {
            $scope.logout(); // unauthorised user
            $state.go('signin');
            $scope.hideLoader();
          }
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      }

      // fetch the active packages of the operator for white label
      function getWhiteLabelPackagesDomain () {
        $scope.showLoader();
        onMySiteService.getWhiteLabelPackagesDomain($scope.user.token).then(function (response) { // get req for operator packages
          if (response.data && response.data.response) { // success response
            $scope.packageList = response.data.response.docs;
            if ($scope.isFullPageView) {
              filterPackages();
            }
          } else if (response.data.code === CONST_HELPER.API_RESPONSE.NON_AUTHORITATIVE) {
            $scope.logout(); // unauthorised user
            $state.go('signin');
            $scope.hideLoader();
          }
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      }

      function filterPackages () {
        if($scope.adventuresList) {
          var generatedPackageIds = $scope.adventuresList.map(function (code) {
            return code.package_id;
          });
        }
        $scope.filteredPackageList = $scope.packageList && $scope.packageList.filter(function (packageData) {
          return !generatedPackageIds.includes(packageData.package_id);
        });
      }

      // Get the list of white label package code generated by the operator
      function getOperatorGeneratedCodeList (eventType) { // eventType variable to handle delete case
        $scope.showLoader();
        onMySiteService.getGeneratedCodeList($scope.user.token).then(function (response) { // send req for orders
          if (response.data.code === CONST_HELPER.API_RESPONSE.OK) { // success response
            $scope.generatedCodeList = response.data.data;
            if (!eventType) {
              checkTutorialStatus(); // Set tutorial screen to show/hide
            }
            var generatedPackageIds = $scope.generatedCodeList.map(function (code) {
              return code.package_id;
            });
            $scope.filteredPackageList = $scope.packageList.filter(function (packageData) {
              return !generatedPackageIds.includes(packageData.package_id);
            });
          } else if (response.data.code === CONST_HELPER.API_RESPONSE.NON_AUTHORITATIVE) {
            $scope.logout(); // unauthorised user
            $state.go('signin');
          }
          $scope.hideLoader();
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      }
      // This method checks whether to show the tutorial or not
      function checkTutorialStatus () {
        // if (!$scope.generatedCodeList.length) {
        //   $scope.isShowTutorial = true;
        //   $scope.ishideTutorial = false;
        // } else {
        //   $scope.ishideTutorial = true;
        //   $scope.isShowTutorial = false;
        // }
      }
      // Delete the Code generated for white label package by the operator
      $scope.deleteGeneratedCode = function (codeId) {
        $scope.showLoader();
        var data = {
          on_my_site_id: codeId
        };
        onMySiteService.deleteGeneratedCode(data, $scope.user.token).then(function (response) { // send req for orders
          if (response.data.code === CONST_HELPER.API_RESPONSE.OK) { // success response
            getOperatorGeneratedCodeList('delete');
          } else if (response.data.code === CONST_HELPER.API_RESPONSE.NON_AUTHORITATIVE) {
            $scope.logout(); // unauthorised user
            $state.go('signin');
            $scope.hideLoader();
          }
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };
    }]);
