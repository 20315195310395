/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
'use strict';
/**
     * @ngdoc method
     * @name instagramServices
     *
     * @description
     * To get the list of instagram POSTS.
     **/

angular.module('heliApp').factory('instagramServices',['$http', '$window', function ($http, $window) {
  // var instagramClientId = "cdac4fb746de49308cb2dfd715d2a3af";
  var instagramClientId = 'db3d1ab1c9234f9bbc7b2f666997bd97';
  var instagramRedirectUri = $window.location.origin;
  var baseURL = 'https://api.instagram.com/v1';

  return {
    authenticate: function (callback) {
      var popup = window.open('https://instagram.com/oauth/authorize/?client_id=' + instagramClientId + '&redirect_uri=' + instagramRedirectUri + '&response_type=token&scope=basic', '_blank', 'width=560,height=520');
      var interval = setInterval(function () {
        try {
          // check if hash exists
          if (popup.location.hash.length) {
            // hash found, that includes the access token
            clearInterval(interval);

            var accessToken = popup.location.hash.slice(14); // slice #access_token= from string
            popup.close();

            if (callback !== undefined && typeof callback === 'function') callback(accessToken);
          }
        } catch (evt) {
          // permission denied
        }
      }, 100);
    },
    getInstaProfile: function (access_token, callback) {
      var requestUrl = baseURL + '/users/self/?access_token=' + access_token;
      var instaProfile = {};
      $.ajax({
        type: 'GET',
        url: requestUrl,
        jsonp: 'callback',
        dataType: 'jsonp',
        success: function (res) {
          if (res.data) { instaProfile = res.data; }

          if (callback !== undefined && typeof callback === 'function') { callback(instaProfile); }
        }
      });
    },
    fetchPopular: function (token, callback) {
      var endpoint = '/users/self/media/recent?count=10&access_token=' + token;
      var url = baseURL + endpoint;
      var config = {
        'params': {
          'callback': 'JSON_CALLBACK'
        }
      };
      $.ajax({
        type: 'GET',
        url: url,
        jsonp: 'callback',
        dataType: 'jsonp',
        success: function (res) {
          if (res.data) {
            if (callback !== undefined && typeof callback === 'function') { callback(res.data); }
          }
        }
      });
    }
  };
}]);
