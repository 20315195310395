'use strict';

angular.module('heliApp')
  .service('bookingService', [
    '$filter',
    'webService',
    'REQUEST_URL',
    'CONST_HELPER',
    function(
      $filter,
      webService,
      REQUEST_URL,
      CONST_HELPER
    ) {
      // calculate processing fee for each package.
      function calculateEach (item, USD, usdRate, Ffixed, Fpercent) {
        var eachProcessingFee = 0;

        if (item.package.is_partial_checked) {
          eachProcessingFee =
            Number(item.package.partial_amount) > 0? (item.package.partial_amount + item.tax_amount + Ffixed) / (1 - Fpercent) : 0;
          eachProcessingFee = eachProcessingFee - item.package.partial_amount - item.tax_amount;
        } else {
          var newTotal = Number((item.base_amount - item.discount_amount - item.nightly_discount));
          eachProcessingFee = newTotal > 0 ? (item.base_amount - item.discount_amount - item.nightly_discount + item.total_tax + Ffixed) / (1 - Fpercent) : 0;
          eachProcessingFee = eachProcessingFee - newTotal - item.total_tax;
        }

        if (USD === CONST_HELPER.payment_const.default_currency) {
          eachProcessingFee = eachProcessingFee / usdRate;

          return Number(eachProcessingFee);
        } else {
          return Number(eachProcessingFee);
        }
      }

      // calculate due amount
      function returnDueAmount (item, USD, usdRate) {
        if (item.package.is_partial_checked) {
          var due;

          if (item.is_promo_code === 1 && item.is_genric === 0) {
            due = Number((item.total - item.package.partial_amount));
          } else {
            due = Number((item.total - item.discount_amount - item.package.partial_amount));
          }
          var totalDue;
          if (USD === CONST_HELPER.payment_const.default_currency) {
            totalDue = Number((due / usdRate));
          } else {
            totalDue = Number(due);
          }
          return totalDue > 0 ? totalDue : 0;
        } else {
          return 0;
        }
      }

      // calculate slot amount
      function calculateSlotAmount (isFinalPayment, item, usdRate) {
        if (usdRate) {
          if (isFinalPayment) {
            if (item.package.deposit_type == 2) {
              return Number(((item.base_amount - item.discount_amount - item.nightly_discount) - item.package.amount_percentage) / usdRate);
            } else {
              return Number((item.base_amount - item.discount_amount - item.nightly_discount) * (1 - (item.package.amount_percentage / 100)) / usdRate);
            }
          } else {
            return Number((item.base_amount - item.discount_amount - item.nightly_discount) / usdRate);
          }
        } else {
          if (isFinalPayment) {
            if (item.package.deposit_type == 2) {
              return Number((item.base_amount - item.discount_amount - item.nightly_discount) - item.package.amount_percentage);
            } else {
              return Number((item.base_amount - item.discount_amount - item.nightly_discount) * (1 - (item.package.amount_percentage / 100)));
            }
          } else {
            return Number(item.base_amount - item.discount_amount - item.nightly_discount);
          }
        }
      }

      // calculate processing fee.
      function calculate (totalAmount, totalTax, totalProcessingFee, Ffixed, Fpercent) {
        totalAmount = Number(totalAmount);
        totalProcessingFee = totalAmount > 0 ? totalProcessingFee + ((totalAmount + totalTax + Ffixed) / (1 - Fpercent)) : totalProcessingFee + 0;
        totalProcessingFee = totalProcessingFee - totalAmount - totalTax;
        return Number(totalProcessingFee);
      }

      // calculate total processing fee for each package partial/full payment.
      function calculateProcessingFee (item, currency, usdRate, Ffixed, Fpercent) {
        var totalProcessingFee = 0;

        if (item.package.is_partial_checked) {
          var dueAmount;
          if (item.is_promo_code === 1 && item.is_genric === 0) {
            dueAmount = item.total - item.package.partial_amount - item.nightly_discount;
          } else {
            dueAmount = item.total - item.discount_amount - item.package.partial_amount - item.nightly_discount;
          }
          var payingAmount = item.package.partial_amount - item.nightly_discount;
          calculate(payingAmount, item.tax_amount, totalProcessingFee, Ffixed, Fpercent);
          calculate(dueAmount, item.total_tax - item.tax_amount, totalProcessingFee, Ffixed, Fpercent);
        } else {
          calculate(item.base_amount - item.discount_amount - item.nightly_discount, item.total_tax, totalProcessingFee, Ffixed, Fpercent);
        }
        if (currency === CONST_HELPER.payment_const.default_currency) {
          return Number((totalProcessingFee / usdRate));
        } else {
          return Number(totalProcessingFee);
        }
      }

      /**
       *
       * @param params : {
       *   cartSlotDetails
       *   usdRate
       *   Ffixed
       *   Fpercent
       *   siteInIFrame
       * }
       * @returns {{}}
       */
      this.createCartDetailsObject = function (params) {
        var CartDetailsObject = [];

        params.cartSlotDetails.map(function (item) { 	// creating payment slot objects.
          var obj = {
            slot_id: item.id,
            package_id: item.package.id,
            operator_id: item.package.user_id,
            tax_amount: Number((item.tax_amount / params.usdRate)),
            local_tax_amount: item.tax_amount,
            is_partial: ((typeof item.package.is_partial_checked !== 'undefined') || item.is_deposit || params.isFinalPayment) ? CONST_HELPER.payment_const.is_partial : CONST_HELPER.payment_const.is_full,
            due_date: item.package_slots ? item.package_slots.slot_due_date || item.package_slots.from_date : null,
            processing_fee: calculateEach(item, CONST_HELPER.payment_const.default_currency, params.usdRate, params.Ffixed, params.Fpercent),	//			Number(vm.processingFeeEach),
            local_processing_fee: calculateEach(item, null, params.usdRate, params.Ffixed, params.Fpercent),	//			Number(vm.processingFeeEach),
            due_amount: returnDueAmount(item, CONST_HELPER.payment_const.default_currency, params.usdRate),
            local_due_amount: returnDueAmount(item, null, params.usdRate),
            slot_amount: item.package.is_partial_checked ? Number(((item.package.partial_amount - item.nightly_discount) / params.usdRate)) : calculateSlotAmount(params.isFinalPayment, item, params.usdRate),
            local_slot_amount: item.package.is_partial_checked ? (item.package.partial_amount - item.nightly_discount) : calculateSlotAmount(params.isFinalPayment, item),
            is_final_payment: item.package.is_partial_checked ? CONST_HELPER.payment_const.partial_payment : CONST_HELPER.payment_const.full_payment,
            total_tax: item.tax_amount / params.usdRate,
            local_total_tax: item.tax_amount,
            total_slot_amount: Number(((item.base_amount - item.discount_amount - item.nightly_discount) / params.usdRate)),
            local_total_slot_amount: Number((item.base_amount - item.discount_amount - item.nightly_discount)),
            total_processing_fee: calculateProcessingFee(item, CONST_HELPER.payment_const.default_currency, params.usdRate, params.Ffixed, params.Fpercent),
            local_total_processing_fee: calculateProcessingFee(item, null, params.usdRate, params.Ffixed, params.Fpercent),
            cc_rate: params.usdRate,
            slot_end_date: item.package_slots ? $filter('date')(item.package_slots.to_date, 'yyyy-MM-dd') : null,
            referral_id: item.referral_id
          };

          if (item.operator_commission.is_on_site_enabled && (params.siteInIFrame || params.isSubDomain || params.isManualBooking)) {
            obj.opr_percentage = item.operator_commission.on_site_commission;
          } else {
            obj.opr_percentage = (item.package_slots && item.package_slot) ? item.package_slots.commission : item.operator_commission.amount;
          }

          CartDetailsObject.push(obj);
        });

        return CartDetailsObject;
      };


      this.getCart = function(token, id) {
        return webService.http({
          method: 'GET',
          url: REQUEST_URL + 'carts/' + parseInt(id),
          headers: {
            token: token
          }
        });
      };

      /**
       * @param token {string}
       * @param order_number {string}
       * @returns {HttpPromise}
       */
      this.getCartByOrderNumber = function(token, order_number) {
        return webService.http({
          method: 'GET',
          url: REQUEST_URL + 'carts',
          params: {
            order_number: order_number
          },
          headers: {
            token: token
          }
        });
      };
    }
  ]);
