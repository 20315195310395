import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name UserLoginService
 * @description
 * # userLoginService
 * Service in the heliApp.
 */

@Injectable()

export class UserLoginService {

  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

   sendReq(method) {
    return new Promise ((resolve, reject) => {
      method.then((response) => {
        resolve(response);
      },(error) => {
        reject(error);
      });
    })
  }

  loginUser (data) { // service to register a new user
    return this.sendReq(this.webService.loginUser(data));
  }

  forgotPassword (email) { // service to submit password forgot req
    return this.sendReq(this.webService.forgotPassword(email));
  }

  logOutUser (token) { // log out user from the platform    
    let req = {
      method: 'DELETE',
      url: this.REQUEST_URL + 'users/logout',
      headers: {
        'token': token
      }
    };
    return this.webService.httpReq(req);
  }
} 

angular.module('heliApp').service('userLoginService', downgradeInjectable(UserLoginService));

