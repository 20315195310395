/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:FilterCtrl
 * @description
 * # FilterCtrl
 * Controller of the heliApp
 */
angular.module('heliApp').controller('FilterCtrl',[
  '$scope',
  '$rootScope',
  '$stateParams',
  '$state',
  '$location',
  'searchService',
  '$anchorScroll',
  '$timeout',
  '$cookies',
  'userAdventureDetailService',
  '$window',
  'CONST_HELPER',
  'operatorPackageListService',
  function ($scope, $rootScope, $stateParams, $state, $location, searchService, $anchorScroll, $timeout, $cookies, userAdventureDetailService, $window, CONST_HELPER, operatorPackageListService) {
  $scope.showLoader(); // show loader
  $scope.canloadmore = false;
  $scope.noDataFoundAfterSearch = false;
  $scope.noDataFoundAfterFilter = false;
  $scope.priceValidation = true;
  $scope.minError = false;
  $scope.appliedFilters = [];
  // initializing Filter arrays.
  var filterApplied = [];
  var appliedActivity = [];
  var appliedAbility = [];
  var urlActivityId = $state.params.activity_id ? $state.params.activity_id.split(',').map(Number) : [];
  // get applied filters from cookies to maintain reload of page.
  var appliedFilter = $cookies.get('appliedFilter');
  var activityApplied = $cookies.get('appliedActivity');
  var abilityApplied = $cookies.get('appliedAbility');
  if (appliedFilter) {
    filterApplied = JSON.parse(appliedFilter);
  }
  if (activityApplied) {
    appliedActivity = JSON.parse(activityApplied);
  }
  if (abilityApplied) {
    abilityApplied = JSON.parse(abilityApplied);
  }
  // var filterApplied=JSON.parse($cookies.get('appliedFilter')) || [],appliedActivity=$cookies.get('appliedActivity') || [],appliedAbility=$cookies.get('appliedAbility') || [];

  // initializing Filter object with default values
  $scope.filter = {
    keyword: $state.params.keyword ? $state.params.keyword : '',
    mindate: $state.params.mindate ? moment($state.params.mindate.replace(/-/g, '/')).format('MM/DD/YYYY') : '',
    maxdate: $state.params.maxdate ? moment($state.params.maxdate.replace(/-/g, '/')).format('MM/DD/YYYY') : '',
    location: $state.params.location,
    lat: $state.params.lat ? $state.params.lat : '',
    lang: $state.params.lang ? $state.params.lang : '',
    limit: CONST_HELPER.page_limit,
    page: $state.params.page ? $state.params.page : 1,
    back: $state.params.back ? $state.params.back : 0 || $state.params.page > 1 ? 1 : 0,
    sort: $state.params.sort || '',
    min_price: parseInt($state.params.min_price) || '',
    max_price: parseInt($state.params.max_price) || '',
    days: $state.params.duration || 0,
    people: $state.params.people || 0,
    abilities: [
      { name: 'Beginner', isChecked: checkAbility('Beginner'), id: 1 },
      { name: 'Intermediate', isChecked: checkAbility('Intermediate'), id: 2 },
      { name: 'Expert', isChecked: checkAbility('Expert'), id: 3 },
      { name: 'Legendary', isChecked: checkAbility('Legendary'), id: 4 }
    ],
    activities: $rootScope.previousActivites ? $rootScope.previousActivites : [],
    previousFilterActivities: [],
    sortingKey: '',
    sortingReverse: false
  };
  function checkAbility (ability) {
    var activeActivity = $state.params.abilities ? $state.params.abilities.split(',') : [];
    if (activeActivity && activeActivity.indexOf(ability) !== -1) {
      return true;
    } else {
      return false;
    }
  }
  // call when search params changes.
  $scope.reloadOnSearch = function () {
    setupFilters(); // set the params filters
    // $state.params.page = ''

    setCurrentPage();
    updateFilters(); // update the applied Filters if url get changed...
    loadAdventure(); // reload advanture when filter page search button is clicked...

    var searchel = angular.element(document.getElementsByClassName('filter-search-panel'));
    if (searchel[0]) {
      searchel[0].classList.remove('mobile-filter');
    }
  };

  if ($rootScope.previousState && $rootScope.previousState.name === 'adventure-detail' && $state.params.page > 1) {
    // $state.params = {...$state.params};
    $state.params.back = 1;
    $location.search($state.params);
  } else {
    setCurrentPage(); // set the initial page count...
  }

  // function to set the filters as it is in params
  function setupFilters () {
    $scope.filter.keyword = $state.params.keyword ? $state.params.keyword : '';
    $scope.filter.mindate = $state.params.mindate ? moment($state.params.mindate.replace(/-/g, '/')).format('MM/DD/YYYY') : '';
    $scope.filter.maxdate = $state.params.maxdate ? moment($state.params.maxdate.replace(/-/g, '/')).format('MM/DD/YYYY') : '';
    $scope.locationVal = $state.params.location ? $state.params.location : '';
    $scope.filter.lat = $state.params.lat ? $state.params.lat : '';
    $scope.filter.lang = $state.params.lang ? $state.params.lang : '';
    $scope.filter.sort = $state.params.sort || '';
    $scope.filter.days = $state.params.duration || 0;
    $scope.filter.people = $state.params.people || 0;
    $scope.filter.max_price = parseInt($state.params.max_price) || '';
    $scope.filter.min_price = parseInt($state.params.min_price) || '';
    urlActivityId = $state.params.activity_id ? $state.params.activity_id.split(',').map(Number) : [];
    $scope.$broadcast('updateDate', $state.params.mindate);
  }

  // function to set the current page...
  function setCurrentPage () {
    $scope.filter.page = $state.params.page ? $state.params.page : 1;
    $state.params.page = parseInt($scope.filter.page);
    $location.search($state.params);
  }

  $rootScope.formData = {
    'startDate': $state.params.mindate,
    'endDate': $state.params.maxdate
  };
  $scope.dateRange.startDate = $state.params.mindate ? moment(new Date($state.params.mindate.replace(/-/g, '/'))) : null;
  $scope.dateRange.endDate = $state.params.maxdate ? moment(new Date($state.params.maxdate.replace(/-/g, '/'))) : null;
  var keyword = $state.params.keyword ? $state.params.keyword.split(',') : '';
  var newObj = [];
  for (var i = 0; i < keyword.length; i++) { // convert keyword to object with name key
    newObj.push({ 'name': keyword[i] });
  }
  $rootScope.tag = {
    'keyword': newObj
  };
  $scope.locationVal = $state.params.location;
  $rootScope.locationSelected = $state.params.location;
  $rootScope.location = {
    'latitude': $state.params.lat,
    'longitude': $state.params.lang
  };
  // call $anchorScroll() set the scroll position to top.
  $anchorScroll();

  /**
        // @function getAvailableSports()
        // Get Available Sports Activities list.
        **/
  //   function getAvailableSports () {
  //     searchService.getAvailableSports($scope.filter).then(
  //       function (response) {
  //         if (response) {
  //           $scope.filter.activities = response.data.sports

  //           customiseData($scope.filter.activities)
  //           loadAdventure() // call loadAdventure function to load data for first time....

  //         }
  //       },
  //       function (error) {
  //         $scope.hideLoader() // hide loader
  //       })
  //   }
  // getAvailableSports(); //calling of function to get available sports activity list

  function loadAdventure () { // Load the adventure with search creteria
    if (filterApplied.length > 0) { //  check if applied filters is empty...
      $scope.applyFilters();
      return false;
    } else if ($state.params.sort) {
      $scope.sortAdventure($state.params.sort);
      return;
    }
    $scope.showLoader(); // show loader

    updateParms();

    searchService.searchAdventures($scope.filter).then(
      function (response) {
        $scope.appliedFilters = angular.copy(filterApplied);
        if (response) {
          $scope.searchData = response.data.response.docs;
          $scope.resultActivities = response.data.activities;
          $scope.resultFound = response.data.response.numFound;
          $scope.canloadmore = couldLoadMore($scope.searchData, $scope.resultFound); // check wheather  to show loadmore button or not
          // resetScroll();
          getFeaturedImage($scope.searchData);
          $scope.checkExpiredSlots($scope.searchData); // check expired slots
          mainTainScroll(); // set the scrollPosition...
          if ($scope.searchData.length === 0) {
            $scope.noDataFoundAfterSearch = true;
            searchService.getAdventureSuggestion($scope.filter).then(function (res) {
              if (res.data.response) {
                $scope.suggestedAdventure = res.data.response.docs;
                if ($scope.suggestedAdventure.length > 0) {
                  getFeaturedImage($scope.suggestedAdventure);
                  $scope.checkExpiredSlots($scope.searchData); // check expired slots
                } else {
                  $scope.hideLoader(); // hide loader
                }
                getAdventureAvailableSeats($scope.suggestedAdventure); // calculate the available seats in every adventure's slots.
              }
            }, function () {
              $scope.hideLoader(); // hide loader
              $scope.showPopup('#serverErrModal');
            });
          } else {
            $scope.noDataFoundAfterSearch = false;
            $scope.noDataFoundAfterFilter = false;
            getAdventureAvailableSeats($scope.searchData); // calculate the available seats in every adventure's slots.
          }
        }
      },
      function (_error) {
        $scope.hideLoader(); // hide loader
        $scope.showPopup('#serverErrModal');
      });
  }

  /**
        //  function to get featured image of pacakages
        **/
  $scope.newActivies = [];
  function getFeaturedImage (searchData) {
    $scope.newActivies = [];
    searchData.map(function (obj, key) {
      // get localCurrencyRate of package currency...
      // if ($scope.user.id) {
      if (obj.currency_code[0] !== $rootScope.localCurrencyCode) {
        userAdventureDetailService.convertCurrency(1, obj.currency_code[0], $rootScope.localCurrencyCode).then(function (res) {
          obj.localCurrencyRate = res.data.usd_rate;
          obj.convertedPrice = obj.min_price * obj.localCurrencyRate;
          if (searchData.length === key + 1) {
            $scope.hideLoader(); // hide loader
            sortbyprice($state.params.sort);
          }
        });
      } else {
        obj.localCurrencyRate = 1;
        obj.convertedPrice = obj.min_price * obj.localCurrencyRate;
        if (searchData.length === key + 1) {
          $scope.hideLoader(); // hide loader
        }
      }
      obj.packageCurrencyCode = $rootScope.localCurrencyCode;
      // } else {
      //   obj.localCurrencyRate = 1;
      //   obj.packageCurrencyCode = obj.currency_code[0];
      //   $timeout(function () {
      //     $scope.hideLoader(); // hide loader
      //   }, 500);
      // }

      if (obj.medias) {
        obj.medias.map(function (image) {
          if (image && parseInt(image.media_type) === 0 && parseInt(image.is_main) === 1) {
            obj.featuredImg = image.url;
          }
        });
        if (!obj.featuredImg) {
          obj.featuredImg = 'images/img2.png';
        }
      }
      // if(obj.package_package_sports){
      //     obj.package_package_sports.map(function(activity){
      //         var act={
      //             id: parseInt(activity.package_sport_id),
      //             package_categories:[{
      //                 category_name: activity.package_category_name || activity.category_name,
      //                 id: parseInt(activity.package_category_id),
      //                 package_sport_id:parseInt(activity.package_sport_id)
      //             }],
      //             sport_name: activity.sport_name || activity.package_sport_name
      //         }
      //         $scope.newActivies.push(act)
      //     });
      // }
    });
    if ($scope.resultActivities.length) {
      $scope.resultActivities.map(function (obj, _key) {
        if (obj.package_package_sports.length) {
          obj.package_package_sports.map(function (activity) {
            var act = {
              id: parseInt(activity.package_sport_id),
              package_categories: [{
                category_name: activity.package_category_name || activity.category_name,
                id: parseInt(activity.package_category_id),
                package_sport_id: parseInt(activity.package_sport_id)
              }],
              sport_name: activity.sport_name || activity.package_sport_name
            };
            $scope.newActivies.push(act);
          });
        }
      });
    }
    mergeObject($scope.newActivies);
  }

  function mergeObject (a) {
    // clone a
    var a2 = JSON.parse(JSON.stringify(a));

    // make sure elements with the same id are next to each other
    a2.sort(function (x, y) {
      if (x['id'] < y['id']) {
        return -1;
      }
      if (x['id'] > y['id']) {
        return 1;
      }
      return 0;
    });
    // iterate over each one, if this one has the same id as the previous one, accumulate
    // else add to b
    var lastId;
    var b = [];
    for (var i = 0; i < a2.length; i++) {
      if (lastId === a2[i]['id']) {
        b[b.length - 1]['package_categories'] = b[b.length - 1]['package_categories'].concat(a2[i]['package_categories']);
      } else {
        b[b.length] = (a2[i]);
        lastId = a2[i]['id'];
      }
    }
    b.map(function (pkgCtgry) {
      var uniqueCategoryArray = removeDuplicates(pkgCtgry.package_categories, 'uniqueCode');
      pkgCtgry.package_categories = uniqueCategoryArray;
    });
    $scope.filter.previousFilterActivities = $scope.filter.activities;
    $scope.filter.activities = b;
    customiseData(b);
  }

  function removeDuplicates (arr, _prop) {
    var arrId = [];
    var newArr = [];

    newArr = arr.filter(function (v) {
      if (arrId.indexOf(parseInt(v.id)) < 0) {
        arrId.push(parseInt(v.id));
        return true;
      }
      return false;
    });
    return newArr;
  }

  /**
        // @function getAdventureAvailableSeats()
        // Get Available seats in Adventures for Available slots.
        **/
  function getAdventureAvailableSeats (adventures) {
    adventures.map(function (adv) {
      var availableSeats = 0;
      adv.startDate = new Date(adv.min * 1000);
      if (adv.package_slots && adv.package_slots.length) {
        adv.package_slots.map(function (slotData) {
          if (!slotData.expired) { availableSeats = parseInt(availableSeats) + parseInt(slotData.available_seat); }
        });
        adv.availableSeats = availableSeats;
      }
    });
  }

  /**
        // @function $scope.sortAdventure
        // sort the available adventures by newest/oldest price: low/high
        **/
  $scope.sortAdventureClicked = function (sortby) {
    $scope.filter.sort = sortby;
    $scope.filter.back = 1;
    // var ele = angular.element(document.getElementById('sort-filter'))
    // if(ele[0] && ele[0].style.display=='block'){
    //     ele[0].style.display='none';
    // }

    $state.params.sort = sortby;
    $location.search($state.params); // update the url is sorting is applied
  };

  function sortbyprice (sortby) {
    // handle price sorting in front end using orderby
    switch (sortby) {
      case 'min_price-asc':
        $scope.filter.sortingKey = 'convertedPrice';
        $scope.filter.sortingReverse = false;
        break;
      case 'min_price-desc':
        $scope.filter.sortingKey = 'convertedPrice';
        $scope.filter.sortingReverse = true;
        break;
      default :
        $scope.filter.sortingKey = '';
        $scope.filter.sortingReverse = false;
        break;
    }
  }

  $scope.sortAdventure = function (_sortby, event) {
    if (filterApplied.length > 0) {
      $scope.applyFilters();
    } else {
      $scope.showLoader(); // show loader
      searchService.sortData($scope.filter).then(
        function (response) {
          if (event !== 'loadmore') {
            $scope.searchData = response.data.response.docs;
            resetScroll(); // reset the scroll position...
          } else {
            $scope.searchData = $scope.searchData.concat(response.data.response.docs);
          }
          $scope.resultActivities = response.data.activities;
          $scope.resultFound = response.data.response.numFound;
          $scope.canloadmore = couldLoadMore($scope.searchData, $scope.resultFound); // check wheather  to show loadmore button or not
          $scope.checkExpiredSlots($scope.searchData); // check expired slots
          getAdventureAvailableSeats($scope.searchData); // get Available seats in all slots
          getFeaturedImage($scope.searchData);
          mainTainScroll(); // set the scrollPosition...
          if ($scope.searchData.length === 0) {
            $scope.noDataFoundAfterSearch = true;
          } else {
            $scope.noDataFoundAfterSearch = false;
            $scope.noDataFoundAfterFilter = false;
          }
          $scope.appliedFilters = angular.copy(filterApplied);
          $('.sort-filter-div').addClass('hide-div');
        }, function () {
          $scope.hideLoader(); // hide loader
          $scope.showPopup('#serverErrModal');
        });
    }
  };

  /**
        // @function $scope.loadMore
        // Load more available adventures
        **/
  $scope.loadMoreClicked = function () {
    $scope.filter.page = parseInt($scope.filter.page) + 1;
    $scope.filter.back = 0;
    $state.params.page = $scope.filter.page;
    $location.search($state.params);
  };
  $scope.loadMore = function () {
    if (filterApplied.length > 0) { //  check if applied filters is empty...
      // $scope.filter.back=1;
      $scope.applyFilters('loadmore');
      return false;
    } else if ($state.params.sort) {
      $scope.sortAdventure($state.params.sort, 'loadmore');
      return;
    }

    $scope.showLoader(); // show loader
    searchService.searchAdventures($scope.filter).then(
      function (response) {
        if ($scope.filter.back === 1) {
          $scope.searchData = response.data.response.docs;
        } else {
          $scope.searchData = $scope.searchData.concat(response.data.response.docs);
        }
        $scope.resultFound = response.data.response.numFound;
        $scope.canloadmore = couldLoadMore($scope.searchData, $scope.resultFound);
        $scope.checkExpiredSlots($scope.searchData); // check expired slots
        getAdventureAvailableSeats($scope.searchData);
        getFeaturedImage($scope.searchData);
      }, function () {
        $scope.hideLoader(); // hide loader
        $scope.showPopup('#serverErrModal');
      });
  };

  // check wheather  can loadmore adventure or not
  function couldLoadMore (adventure, resultFound) {
    if (adventure.length < resultFound) { return true; }
  }
  $scope.pageChanged = function (data) {
    if (data === 'pageIncreased') {
      $scope.loadMore();
    } else if (data === 'pageDecreased') {
      $scope.canloadmore = true;
      if ($scope.searchData) {
        $scope.searchData = $scope.searchData.slice(0, $rootScope.currentPage * $scope.filter.limit);
      } else {
        $scope.reloadOnSearch();
      }
    } else {
      $scope.reloadOnSearch();
    }
  };

  // update the applied Filters if url get changed...
  function updateFilters () {
    var urlActivities = $state.params.activities; var urlAbilities = $state.params.abilities;
    urlActivities = urlActivities ? urlActivities.split(',') : [];
    urlAbilities = urlAbilities ? urlAbilities.split(',') : [];

    // add or delete filter tag everytime when url ability get changed
    if (urlActivities && urlActivities.length > 0 && appliedActivity.length < urlActivities.length) {
      urlActivities.map(function (val, _key) {
        var obj = { type: 'activity', name: val };
        var filteredObj = filterApplied.find(function (item, _i) {
          if (item.name === val) {
            return true;
          }
        });
        if (!filteredObj) {
          filterApplied.push(obj);
          appliedActivity.push(obj.name);
        }
      });
    } else if (urlActivities && urlActivities.length > 0 && appliedActivity.length >= urlActivities.length) {
      var i = filterApplied.length;
      while (i > 0) {
        if (filterApplied[i - 1].type === 'activity') {
          if (urlActivities.indexOf(filterApplied[i - 1].name) === -1) {
            appliedActivity.splice(appliedActivity.indexOf(filterApplied[i - 1].name), 1);
            filterApplied.splice(i - 1, 1);
          };
        }
        i--;
      }
    } else {
      var j = filterApplied.length;
      while (j > 0) {
        if (filterApplied[j - 1].type === 'activity') {
          appliedActivity.splice(appliedActivity.indexOf(filterApplied[j - 1].name), 1);
          filterApplied.splice(j - 1, 1);
        }
        j--;
      }
    }

    // add or delete filter tag everytime when url ability get changed
    if (urlAbilities && urlAbilities.length > 0 && appliedAbility.length < urlAbilities.length) {
      urlAbilities.map(function (val, _key) {
        var obj = { type: 'ability', name: val };
        var filteredObj = filterApplied.find(function (item, _i) {
          if (item.name === val) {
            return true;
          }
        });
        if (!filteredObj) {
          filterApplied.push(obj);
          appliedAbility.push(obj.name);
        }
      });
    } else if (urlAbilities && urlAbilities.length > 0 && appliedAbility.length >= urlAbilities.length) {
      var i = filterApplied.length;
      while (i > 0) {
        if (filterApplied[i - 1].type === 'ability') {
          if (urlAbilities.indexOf(filterApplied[i - 1].name) === -1) {
            appliedAbility.splice(appliedAbility.indexOf(filterApplied[i - 1].name), 1);
            filterApplied.splice(i - 1, 1);
          }
        }

        i--;
      }
    } else {
      var i = filterApplied.length;
      while (i > 0) {
        if (filterApplied[i - 1].type === 'ability') {
          appliedAbility.splice(appliedAbility.indexOf(filterApplied[i - 1].name), 1);
          filterApplied.splice(i - 1, 1);
        }
        i--;
      }
    }
    // check/uncheck activities according to url...

    // check/uncheck abilities according to url...
    $scope.filter.abilities.map(function (val) {
      val.isChecked = checkAbility(val.name);
    });
    createTag(); //  create tags for price range , days and people.
    customiseData($scope.filter.activities);
  }

  // Activities filters
  // add isChecked and parent property to Activities data
  function customiseData (data) {
    data.forEach(function (val, _key) {
      // var parent=angular.copy(val)
      val.isChecked = checkAppliedActivity(filterApplied, val.sport_name);
      val.parent = '';
      var activityid = $state.params.activity_id ? $state.params.activity_id.split(',').map(Number) : [];
      val.checkedId = [];
      if (activityid.length > 0) {
        val.checkedId = activityid;
      }

      if (val.package_categories.length) {
        val.package_categories.forEach(function (v, _k) {
          v.isChecked = checkAppliedCategory(v, val.checkedId);
          // v.isChecked=true;
          v.parent = val;
        });
      }
    });
    $rootScope.previousActivites = data;
  }

  // check applied activity so we can checked its checkbox.
  function checkAppliedActivity (v, sName) {
    var res = false;
    v.map(function (activity) {
      if (activity.name === sName) {
        res = true;
      }
    });
    return res;
  }

  // check applied category so we can checked its checkbox.
  function checkAppliedCategory (v, id) {
    if (id && id.indexOf(v.id) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  //= ============= Implementaion of parent child nested checkbox =============///

  $scope.checkChange = function (item) {
    // handling the toggling of all of the children here
    if (item.package_categories) {
      parentCheckChange(item);
    } else {
      childCheckChange(item.parent);
    }
    // handle appied tags...
    handleAppiledTags(item);
  };

  function parentCheckChange (item) {
    for (var i in item.package_categories) {
      item.package_categories[i].isChecked = item.isChecked;
      if (item.isChecked) {
        urlActivityId.push(item.package_categories[i].id);
      } else {
        var pcIndex = urlActivityId.indexOf(item.package_categories[i].id);
        if (pcIndex !== -1) { urlActivityId.splice(urlActivityId.indexOf(item.package_categories[i].id), 1); }
      }
    }
  }
  /*
          // check the child checkbox changes, if all child get deselected then deselect the parend checkbox as well..
          */
  function childCheckChange (parent) {
    var allChecks = false;
    for (var i in parent.package_categories) {
      if (!parent.package_categories[i].isChecked) {
        // eslint-disable-next-line no-unused-expressions
        urlActivityId.indexOf(parent.package_categories[i].id) !== -1 ? urlActivityId.splice(urlActivityId.indexOf(parent.package_categories[i].id), 1) : '';
      } else {
        // eslint-disable-next-line no-unused-expressions
        urlActivityId.indexOf(parent.package_categories[i].id) === -1 ? urlActivityId.push(parent.package_categories[i].id) : '';
        allChecks = true;
      }
    }
    if (allChecks) {
      parent.isChecked = true;
    } else {
      parent.isChecked = false;
    }
    if (parent.parent) {
      childCheckChange(parent.parent);
    }
  }

  // validata min price and max-price
  $scope.$watch('filter.max_price', function (n, _o) {
    if (n === '0') {
      $scope.priceValidation = true;
      $scope.maxError = true;
      // $scope.minErrorMsg="Max price must be greater than 0";
    } else if (n < $scope.filter.min_price && $scope.filter.min_price) {
      $scope.priceValidation = false;
      $scope.maxError = false;
    } else if (!n) {
      if ($scope.filter.min_price === '0') {
        $scope.maxError = true;
        // $scope.minErrorMsg="Min price must be greater than 0";
      } else {
        $scope.maxError = false;
      }
    } else {
      $scope.priceValidation = true;
      $scope.maxError = false;
    }
  });
  $scope.$watch('filter.min_price', function (n, _o) {
    if (n === '0') {
      $scope.minError = true;
      $scope.minErrorMsg = 'Min price must be greater than 0';
    } else if (n > $scope.filter.max_price && ($scope.filter.max_price !== '' || $scope.filter.max_price === '0')) {
      $scope.priceValidation = false;
      $scope.minError = false;
    } else {
      $scope.minError = false;
      $scope.priceValidation = true;
    }
  });

  function clearFilterCookies () {
    $cookies.put('activity_id', '');
    $cookies.put('ability_id', '');
    $cookies.put('appliedFilter', JSON.stringify(filterApplied));
    $cookies.put('appliedActivity', JSON.stringify(appliedActivity));
    $cookies.put('appliedAbility', JSON.stringify(appliedAbility));
  }

  // Apply filters
  $scope.applyFiltersClicked = function () {
    $scope.filter.page = 1;
    var ele = angular.element(document.getElementById('filter-modal'));
    var headElement = angular.element(document.getElementById('filter-head'));
    ele[0].className = ele[0].className + ' mobile-display';
    headElement[0].className = headElement[0].className + ' toggled';
    $('body').removeClass('fix-scroll');
    $state.params.page = '';
    setCurrentPage();
    updateParms();
  };
  $scope.applyFilters = function (event) {
    if (urlActivityId.length > 0 && $scope.filter.activities.length === 0) {
      filterApplied = [];
      urlActivityId = [];
      updateParms();
      loadAdventure();
      return false;
    }
    $scope.showLoader(); //  show loader
    createTag(); //  create tags for price range , days and people.
    $scope.minError = false;
    if (filterApplied.length === 0) { //  check if applied filters is empty...
      loadAdventure();
      $scope.appliedFilters = angular.copy(filterApplied);
      clearFilterCookies();
      return false;
    }
    // get filtered adventures...
    searchService.filterAdventures($scope.filter).then(function (response) {
      if (!response.data.response && response.data.code !== '400') {
        $scope.applyFilters($scope.filter);
        return false;
      }
      if (response.data.code === '400') {
        filterApplied = [];
        loadAdventure(); // error handling ... if somehow we get bad request error load the adventure again...
      }
      if (response.data.response) {
        if (event !== 'loadmore') {
          $scope.searchData = response.data.response.docs;
          resetScroll(); // reset the scroll position...
        } else {
          $scope.searchData = $scope.searchData.concat(response.data.response.docs);
        }
        $scope.resultActivities = response.data.activities;
        $scope.resultFound = response.data.response.numFound;
        $scope.canloadmore = couldLoadMore($scope.searchData, $scope.resultFound);
        mainTainScroll(); // set the scroll position...
        if ($scope.searchData.length > 0) {
          getFeaturedImage($scope.searchData);
          $scope.checkExpiredSlots($scope.searchData); // check expired slots
        } else {
          $scope.hideLoader(); // hide loader
        }
        getAdventureAvailableSeats($scope.searchData);
        if ($scope.searchData.length === 0) {
          $scope.noDataFoundAfterFilter = true;
        } else {
          $scope.noDataFoundAfterFilter = false;
        }
        $scope.appliedFilters = angular.copy(filterApplied);
        $cookies.put('appliedFilter', JSON.stringify(filterApplied));
        $cookies.put('appliedActivity', JSON.stringify(appliedActivity));
        $cookies.put('appliedAbility', JSON.stringify(appliedAbility));
        $('.sort-filter-div').addClass('hide-div');
      }
    }, function (_error) {
      $scope.hideLoader(); // hide loader
      $scope.showPopup('#serverErrModal');
    });
  };
  // call the loadAdventure after applyFilter/sortAdventure function is loaded.
  if ($rootScope.localCurrencyCode) {
    loadAdventure(); // call loadAdventure function to load data for first time....
  } else {
    // $scope.showLoader();
    $rootScope.$on('get-package-addons', function () {
      loadAdventure(); // call loadAdventure function to load data for first time....
    });
  }
  // update the parms when filter applied
  function updateParms () {
    if ($state.params.duration || $scope.filter.days) { $state.params.duration = $scope.filter.days; }

    if ($state.params.people || $scope.filter.people) { $state.params.people = $scope.filter.people; }

    if ($state.params.min_price || $scope.filter.min_price || $scope.filter.max_price) {
      if ($scope.filter.max_price) {
        $state.params.min_price = $scope.filter.min_price ? $scope.filter.min_price : 1;
      } else {
        $state.params.min_price = '';
      }
    }

    if ($state.params.max_price || $scope.filter.max_price) { $state.params.max_price = $scope.filter.max_price; }

    if ($state.params.activities || appliedActivity.length > 0) {
      if ($scope.filter.activities.length > 0) {
        $state.params.activities = appliedActivity.toString();
      } else {
        appliedActivity = [];
        $state.params.activities = '';
      }
    }

    if ($state.params.abilities || appliedAbility.length > 0) { $state.params.abilities = appliedAbility.toString(); }

    if ($state.params.activity_id || urlActivityId.length > 0) {
      $state.params.activity_id = urlActivityId.toString();
      if (!$state.params.activities) {
        urlActivityId = [];
        $state.params.activity_id = urlActivityId.toString();
      }
    }
    $location.search($state.params);
  }

  // create tags for days, people and price.
  function createTag () {
    // check condition for days tag , people tag and price tag.
    var dayFilter = filterApplied.find(function (filter) { // find the day filter tag in array
      return filter.type === 'day';
    });
    var peopleFilter = filterApplied.find(function (filter) { // find the people filter tag in array
      return filter.type === 'people';
    });
    var priceFilter = filterApplied.find(function (filter) { // find the price filter tag in array
      return filter.type === 'price';
    });

    var dayObj = {
      name: $scope.filter.days + ' Days',
      type: 'day'
    };
    var peopleObj = {
      name: $scope.filter.people + ' people',
      type: 'people'
    };
    var priceObj = {
      name: ' $' + ($scope.filter.min_price || 1) + ' - ' + $scope.filter.max_price,
      type: 'price'
    };
    // check day filter is already in filterApplied array?
    if (dayFilter) {
      var i = filterApplied.indexOf(dayFilter); // get the index of day filter
      if ($scope.filter.days > 0) {
        filterApplied[i] = dayObj; // update when it is greater than 0
      } else {
        filterApplied.splice(i, 1); // delete from array when user set back it to 0
      }
    } else { // when it is applied first time push to filterApplied array.
      if ($scope.filter.days > 0) {
        filterApplied.push(dayObj);
      }
    }

    // check people filter is already in filterApplied array?
    if (peopleFilter) {
      var i = filterApplied.indexOf(peopleFilter); // get the index of people filter
      if ($scope.filter.people > 0) {
        filterApplied[i] = peopleObj; // update when it is greater than 0
      } else {
        filterApplied.splice(i, 1); // delete from array when user set back it to 0
      }
    } else { // push to array when it is applied first time and greater than 0
      if ($scope.filter.people > 0) {
        filterApplied.push(peopleObj);
      }
    }

    // check price filter is already in filterApplied array?
    if (priceFilter) {
      var i = filterApplied.indexOf(priceFilter); // get the index of price filter
      if ($scope.filter.max_price > 0) {
        filterApplied[i] = priceObj; // update when it is greater than 0
      } else {
        filterApplied.splice(i, 1); // delete from array when user set back it to 0
      }
    } else {
      if ($scope.filter.max_price > 0) { // push to array when it is applied first time and greater than 0
        $scope.filter.min_price = $scope.filter.min_price ? $scope.filter.min_price : 1;
      }
      if ($scope.filter.max_price > 0 && $scope.filter.min_price > 0) {
        filterApplied.push(priceObj);
      }
    }
  }

  /**
        // @function $scope.inreaseCount/$scope.decreaseCount
        // Increase/decrease count of days and peoples...
        **/
  $scope.inreaseCount = function (key) {
    if (key === 'days') { $scope.filter.days = (parseInt($scope.filter.days) || CONST_HELPER.ZERO) + CONST_HELPER.ONE; } else { $scope.filter.people = (parseInt($scope.filter.people) || CONST_HELPER.ZERO) + CONST_HELPER.ONE; }
  };
  $scope.decreaseCount = function (key, val) {
    if (val === 0) { return; }
    if (key === 'days') { $scope.filter.days = (parseInt($scope.filter.days) || CONST_HELPER.ZERO) - CONST_HELPER.ONE; } else { $scope.filter.people = (parseInt($scope.filter.people) || CONST_HELPER.ZERO) - CONST_HELPER.ONE; }
  };

  /**
        // @function $scope.clearAllFilters
        // clear all alpplied filters
        **/
  $scope.clearAllFilters = function () {
    $scope.appliedFilters.map(function (filter) {
      $scope.removeFilterTag(filter, true);
    });
    $state.params.page = '';
    setCurrentPage();
    loadAdventure();
    updateParms();
    clearFilterCookies();
  };

  /**
        // @function $scope.removeFilterTag
        // set default vaule and remove the filter obj from filterApplied array so it tag removed from view by clicking on cross icon
        **/
  $scope.removeFilterTag = function (tag, ifClearAll) {
    var index;
    if (tag.type === 'activity') {
      index = getIndex($scope.filter.activities, tag.name);
      if (index) {
        // eslint-disable-next-line no-unused-expressions
        $scope.filter.activities ? $scope.filter.activities[index].isChecked = false : '';
        // eslint-disable-next-line no-unused-expressions
        $scope.filter.previousFilterActivitieS ? $scope.filter.previousFilterActivities[index].isChecked = false : '';
        // uncheck subcategory of removed activities...
        if ($scope.filter.activities[index].package_categories) {
          for (var i in $scope.filter.activities[index].package_categories) {
            $scope.filter.activities[index].package_categories[i].isChecked = false;
            if ($scope.filter.previousFilterActivities[index] && $scope.filter.previousFilterActivities[index].package_categories[i]) {
              $scope.filter.previousFilterActivities[index].package_categories[i].isChecked = false;
            }
            urlActivityId.splice(urlActivityId.indexOf($scope.filter.activities[index].package_categories[i].id), 1);
          }
        }
      }
      appliedActivity.splice(appliedActivity.indexOf(tag.name), 1);
    } else if (tag.type === 'ability') {
      index = getIndex($scope.filter.abilities, tag.name);
      // eslint-disable-next-line no-unused-expressions
      $scope.filter.abilities ? $scope.filter.abilities[index].isChecked = false : '';
      appliedAbility.splice(appliedAbility.indexOf(tag.name), 1);
    } else if (tag.type === 'day') {
      $scope.filter.days = 0; // set to zero(default)
    } else if (tag.type === 'people') {
      $scope.filter.people = 0; // set to zero(default)
    } else if (tag.type === 'price') {
      $scope.filter.min_price = ''; // set to zero(default)
      $scope.filter.max_price = ''; // set to zero(default)
    }
    var i = $scope.appliedFilters.indexOf(tag);
    filterApplied.splice(i, 1);
    $scope.appliedFilters = angular.copy(filterApplied); // copy to scope vaiable so it can reflect to view
    if (!ifClearAll) { $scope.applyFiltersClicked(); }
  };
  // get index of activity and ability in $scope.filters array
  function getIndex (arr, name) {
    var i;
    arr.forEach(function (val, index) {
      if (val.sport_name && val.sport_name === name) {
        i = index;
      } else if (val.name === name) {
        i = index;
      }
    });
    return i;
  }

  /**
        // @function handleAppiledTags
        // check wheather to add or remove activity applied filter tag...
        **/
  function handleAppiledTags (activity) {
    if (activity.parent === '' && activity.isChecked) {
      addAppiledFilter(activity);
    } else if (activity.parent === '' && !activity.isChecked) {
      removeAppiledFilter(activity);
    } else {
      if (!activity.parent.isChecked) { removeAppiledFilter(activity.parent); }
    }
  }
  // remove activity applied filter tag...
  function addAppiledFilter (activity) {
    var obj = {
      name: activity.sport_name,
      type: 'activity'
    };
    filterApplied.push(obj);
    appliedActivity.push(obj.name);
  }
  // add activity applied filter tag...
  function removeAppiledFilter (activity) {
    var index = getIndex(filterApplied, activity.sport_name);
    filterApplied.splice(index, 1);
    var i = appliedActivity.indexOf(activity.sport_name);
    appliedActivity.splice(i, 1);
  }
  /**
        // @function checkAbilityChange
        // check wheather to add or remove ability applied filter tag...
        **/
  $scope.checkAbilityChange = function (ability) {
    if (ability.isChecked) {
      var obj = {
        name: ability.name,
        type: 'ability'
      };
      filterApplied.push(obj);
      appliedAbility.push(obj.name);
    } else {
      var index = getIndex(filterApplied, ability.name);
      filterApplied.splice(index, 1);
      var i = appliedAbility.indexOf(ability.name);
      appliedAbility.splice(i, 1);
    }
  };

  // view all Slots
  $scope.viewAllSlots = function (event, adventure) {
    var slotParams = {};
    slotParams.package_id = adventure.package_id;
    $scope.showLoader();
    operatorPackageListService.getOperatorPackagesSlots(slotParams).then(function (response) { // fetch from the service
      if (response.data.code === '200') {
        $scope.adventure = response.data.package;// add to scope
        $scope.checkExpiredSlots([$scope.adventure]);
        $scope.adventure.localCurrencyRate = adventure.localCurrencyRate;
        $scope.adventure.packageCurrencyCode = adventure.packageCurrencyCode;
        $scope.showPopup('#slot');
        $scope.hideLoader();
      }
    }, function () { // check for error
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
    event.stopPropagation();
  };

  var unbindChangeSuccess = $scope.$on('$locationChangeSuccess', function (_newLocation, _oldLocation) {
    if ($location.path() === '/filter') {
      var filters = $location.search() ? $location.search() : {};
      $rootScope.lastPage = angular.copy($rootScope.currentPage);
      $rootScope.currentPage = $location.search().page ? $location.search().page : 1;
      if ($rootScope.lastPage - $rootScope.currentPage === CONST_HELPER.ONE && !(filters.hasOwnProperty('location') || filters.hasOwnProperty('mindate'))) {
        $scope.pageChanged('pageDecreased');
      } else if ($rootScope.currentPage - $rootScope.lastPage === CONST_HELPER.ONE) {
        $scope.filter.page = parseInt($rootScope.currentPage);
        setupFilters(); // update the filters according ro params...
        $scope.pageChanged('pageIncreased');
      } else {
        if ($location.path() === '/filter' && (filters.hasOwnProperty('activities') || filters.hasOwnProperty('abilities') || filters.hasOwnProperty('duration') || filters.hasOwnProperty('max_price') || filters.hasOwnProperty('people') || filters.hasOwnProperty('sort') || filters.hasOwnProperty('keyword') || filters.hasOwnProperty('mindate') || filters.hasOwnProperty('location') || filters.hasOwnProperty('page'))) {
          $scope.reloadOnSearch('reloadOnSearch');
        }
      }
    }
  });
  $scope.$on('$destroy', function () {
    unbindChangeSuccess();
  });

  // save window scrollPostion
  angular.element($window).bind('scroll', function (_e) {
    // console.log(this.pageYOffset);
    if (this.pageYOffset > 500 && $rootScope.currentState && $rootScope.currentState.name === 'filter') {
      $rootScope.filterOffset = this.pageYOffset;
    } else if (this.pageYOffset < 500 && this.pageYOffset !== 0 && $rootScope.currentState && $rootScope.currentState.name === 'filter') {
      $rootScope.filterOffset = this.pageYOffset;
    }
  });
  function mainTainScroll () {
    var currentOffset = $(window).scrollTop();
    if (currentOffset !== $rootScope.filterOffset && $rootScope.filterOffset > 100 && $rootScope.previousState && ($rootScope.previousState.name === 'adventure-detail')) {
      $timeout(function () { // scroll to previous position.
        angular.element('html, body').animate({ scrollTop: $rootScope.filterOffset }, 1000);
      }, 10);
    }
  }
  // function to reset the scroll position after search change or filter applied..
  function resetScroll () {
    var currentOffset = $(window).scrollTop();
    if (!(currentOffset !== $rootScope.filterOffset && $rootScope.filterOffset > 100 && $rootScope.previousState && ($rootScope.previousState.name === 'adventure-detail'))) {
      // console.log($window.scrollY);
      $timeout(function () {
        angular.element('html, body').animate({ scrollTop: 0 }, 800);
      }, 500);
    }
  }
}]);
