//Modules
import { NgModule } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

//Angular Hybrid Router
import { UIRouterUpgradeModule, NgHybridStateDeclaration } from '@uirouter/angular-hybrid';

//Custom services
import { CookieService } from 'ngx-cookie-service';

//Angular/AngularJS Services
import { WebService } from './scripts/services/webService';
import { AgentService } from './scripts/services/agentService';
import { OnMySiteService } from './scripts/services/onMySiteService';
import { OperatorAgentService } from './scripts/services/operatorAgentService';
import { AmbassadorsListingService } from './scripts/services/ambassadorsListingService';
import { BinaryService } from './scripts/services/binaryService';
import { OperatorAnalyticsService } from './scripts/services/operatorAnalyticsService';
import { OperatorCalendarService } from './scripts/services/operatorCalendarService';
import { CreateAdventureService } from './scripts/services/operatorCreateAdventureService';
import { OperatorManifestService } from './scripts/services/operatorManifestService';
import { OperatorManualPaymentService } from './scripts/services/operatorManualPaymentService';
import { OperatorPackageListService } from './scripts/services/operatorPackageListService';
import { OperatorPayoutService } from './scripts/services/operatorPayoutService';
import { OperatorProfileService } from './scripts/services/operatorProfileService';
import { OperatorService } from './scripts/services/operatorService';
import { OperatorViewBookingService } from './scripts/services/operatorViewBookingService';
import { PaymentService } from './scripts/services/paymentService';
import { PublicFeedsService } from './scripts/services/publicFeedsService';
import { ScriptLoaderService } from './scripts/services/scriptLoaderService';
import { SearchService } from './scripts/services/searchService';
import { SharedService } from './scripts/services/sharedService';
import { TaxesService } from './scripts/services/taxesService';
import { UserAdventureDetailService } from './scripts/services/userAdventureDetailService';
import { UserCheckoutService } from './scripts/services/userCheckoutService';
import { UserListingService } from './scripts/services/userListingService';
import { UserLoginService } from './scripts/services/userLoginService';
import { UserOrdersListService } from './scripts/services/userOrdersListService';
import { UserProfileService } from './scripts/services/userProfileService';
import { UserRegisterService } from './scripts/services/userRegisterService';
import { UserSocialProfileService } from './scripts/services/userSocialProfileService';
import { RootScopeService } from '../ng-app/src/app/services/root-scope/root-scope.service';

//Angular Components
import { TestComponent } from '../ng-app/src/app/components/test/test.component';
import { LeftNavComponent } from '../ng-app/src/app/components/left-nav/left-nav.component';
//--Home Components--//
import { HomeComponent } from '../ng-app/src/app/pages/home/home.component';
import { VideoBgBlockComponent } from '../ng-app/src/app/components/home/video-bg-block/video-bg-block.component';
import { CategorySliderComponent } from '../ng-app/src/app/components/home/category-slider/category-slider.component';
import { AdventuresSliderComponent } from '../ng-app/src/app/components/home/adventures-slider/adventures-slider.component';
import { AdvantagesBlockComponent } from '../ng-app/src/app/components/home/advantages-block/advantages-block.component';
import { OperatorsSliderComponent } from '../ng-app/src/app/components/home/operators-slider/operators-slider.component';
import { ParallaxBlockComponent } from '../ng-app/src/app/components/home/parallax-block/parallax-block.component';
import { CommunityComponent } from '../ng-app/src/app/components/home/community/community.component';
import { PartnersComponent } from '../ng-app/src/app/components/home/partners/partners.component';
import { TestimonialsComponent } from '../ng-app/src/app/components/home/testimonials/testimonials.component';
import { UpdatesComponent } from '../ng-app/src/app/components/home/updates/updates.component';
//---------------//
import { OperatorsListingComponent } from '../ng-app/src/app/pages/operators-listing/operators-listing.component';
import { HeaderComponent } from '../ng-app/src/app/components/header/header.component';
import { FooterComponent } from '../ng-app/src/app/components/footer/footer.component';
import { SearchPanelComponent } from '../ng-app/src/app/components/search-panel/search-panel.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
//--------------//

//Directives
import { ParallaxScrollDirective } from '../ng-app/src/app/directives/parallax-scroll/parallax-scroll.directive';

//Pipes
import { TruncatePipe } from '../ng-app/src/app/pipes/truncate/truncate.pipe';

//Custom Modules
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';

//New Angular components connect to here! analog AngularRouter
export const home: NgHybridStateDeclaration = {
  name: 'home.page',
  url: '/',
  component: HomeComponent
};

export const operatorsListing: NgHybridStateDeclaration = {
  name: 'operators-listing.page',
  url: '/operators-listing?page&search&back',
  component: OperatorsListingComponent,
  dynamic: true
};

export const activityCategories: NgHybridStateDeclaration = {
  name: 'activity-categories.**',
  url: '/',
  loadChildren: () => import('../ng-app/src/app/pages/activity-categories/activity-categories.module').then(mod => mod.ActivityCategoriesModule)
};

export const destinationСategories: NgHybridStateDeclaration = {
  name: 'destination-categories.**',
  url: '/destination/{destinationCategory}',
  loadChildren: () => import('../ng-app/src/app/pages/destination-categories/destination-categories.module').then(mod => mod.DestinationСategoriesModule)
};

export const destinationLocation: NgHybridStateDeclaration = {
  name: 'destination-location.**',
  url: '/destination/{destinationCategory}/{destinationLocation}',
  loadChildren: () => import('../ng-app/src/app/pages/destination-location/destination-location.module').then(mod => mod.DestinationLocationModule)
};

export const filter: NgHybridStateDeclaration = {
  name: 'filter.**',
  url: '/filter?mindate&maxdate&keyword&location&lat&lang&page&back&sort&duration&people&min_price&max_price&activities&abilities&activity_id',
  loadChildren: () => import('../ng-app/src/app/pages/filter/filter.module').then(mod => mod.FilterModule)
};

export const waitlist: NgHybridStateDeclaration = {
  name: 'waitlist.**',
  url: '/info-request?package_id',
  data: {
    meta: {
      'title': 'Join the Waitlist',
      'description': 'See our full list of adventures from operators around the globe. Shop and book directly online today.'
    }
  },
  loadChildren: () => import('../ng-app/src/app/pages/waitlist/waitlist.module').then(mod => mod.WaitlistModule)
}

// The main NgModule for the Angular portion of the hybrid app
@NgModule({
  declarations: [
    HomeComponent,
    TestComponent,
    LeftNavComponent,
    VideoBgBlockComponent,
    CategorySliderComponent,
    AdventuresSliderComponent,
    AdvantagesBlockComponent,
    OperatorsSliderComponent,
    ParallaxBlockComponent,
    ParallaxScrollDirective,
    CommunityComponent,
    PartnersComponent,
    TestimonialsComponent,
    UpdatesComponent,
    OperatorsListingComponent,
    HeaderComponent,
    FooterComponent,
    SearchPanelComponent,
    TruncatePipe
  ],
  entryComponents: [
    LeftNavComponent,
    TestComponent,
    HeaderComponent,
    FooterComponent],
  imports: [
    // Provide angular upgrade capabilities
    BrowserModule,
    HttpClientModule,
    UpgradeModule,
    NgSelectModule,
    NgOptionHighlightModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDaterangepickerMd.forRoot(),
    GooglePlaceModule,
    // Provides the @uirouter/angular directives and registers
    // the future state for the lazy loaded contacts module
    UIRouterUpgradeModule.forRoot({states: [home, operatorsListing]}),
    UIRouterUpgradeModule.forChild({states: [activityCategories, filter, destinationСategories, destinationLocation, waitlist] }),

    //Provides custom modules
    SlickCarouselModule
  ],
  providers: [
    AgentService,
    AmbassadorsListingService,
    BinaryService,
    OnMySiteService,
    OperatorAgentService,
    OperatorAnalyticsService,
    OperatorCalendarService,
    CreateAdventureService,
    OperatorManifestService,
    OperatorManualPaymentService,
    OperatorPackageListService,
    OperatorPayoutService,
    OperatorProfileService,
    OperatorService,
    OperatorViewBookingService,
    PaymentService,
    PublicFeedsService,
    ScriptLoaderService,
    SearchService,
    SharedService,
    TaxesService,
    UserAdventureDetailService,
    UserCheckoutService,
    UserListingService,
    UserLoginService,
    UserOrdersListService,
    UserProfileService,
    UserRegisterService,
    UserSocialProfileService,
    WebService,
    CookieService,
    RootScopeService
  ]
})

export class HeliAppModuleAngular {
  constructor(private upgrade: UpgradeModule) { }
  ngDoBootstrap() {
    console.log("Bootstrapping in Hybrid mode with Angular & AngularJS");
    this.upgrade.bootstrap(document.body, ['heliApp'], { strictDi: false });
  }
}