/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:CreateAdventureCtrl
 * @description
 * # CreateAdventureCtrl
 * Controller of the heliApp
 */
angular.module('heliApp')
  .controller('operatorCaseSelectionCtrl',['$scope', '$filter', '$timeout', '$state', '$stateParams', '$rootScope', 'CONST_HELPER', '$cookies', '$location', function ($scope, $filter, $timeout, $state, $stateParams, $rootScope, CONST_HELPER, $cookies, $location) {
    $scope.homeRedirection();// check for authorized user

    $scope.package_type = $stateParams.package_type;
    $rootScope.packageType = $rootScope.packageType ? $rootScope.packageType : $cookies.get('package_type');

    if ($rootScope.packageType && parseInt($scope.package_type) !== $rootScope.packageType) {
      $stateParams.package_type = $rootScope.packageType;
      $location.search($stateParams);
      return;
    }

    $scope.caseSelection = function (packageCase) {
      $rootScope.packageCase = packageCase;
      $cookies.put('package_case', packageCase);
    };

    window.onunload = function () {
      // reset cookies on window reload
      $cookies.put('package_type', $rootScope.packageType);
    };
  }]);
