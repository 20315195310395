'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:followRequestCtrl
 * @description
 * # followRequestCtrl
 * Controller of the heliApp
 */
// eslint-disable-next-line no-undef
angular.module('heliApp')
  .controller('followRequestCtrl', ['$scope', '$stateParams', 'sharedService', 'CONST_HELPER', 'MESSAGE_HELPER', function ($scope, $stateParams, sharedService, CONST_HELPER, MESSAGE_HELPER) {
    $scope.response_message = '';
    $scope.validFollowRequestToken = function () {
      var data = {
        'request_token': $stateParams.token
      };
      sharedService.validFollowRequestToken(data).then(function (res) {
        switch (res.data.code) {
          case '200' :
            var name = res.data.data.name;
            if (res.data.status === CONST_HELPER.follow_user.FOLLOW_REQUEST_ACCEPTED) {
              $scope.response_message = 'Thanks, ' + name + ' can now see your posts.';
            } else {
              $scope.response_message = 'Yoh have rejected ' + name + ' follow request. ' + name + ' can not view any of your posts.';
            }
            break;
          case '404' :
            $scope.response_message = MESSAGE_HELPER.follow_user.FOLLOW_TOKEN_EXPIRE;
            break;
          default :
            $scope.showPopup('#serverErrModal');
            break;
        }
      }, function () { // check for error
        $scope.showPopup('#serverErrModal');
      });
    };
  }]);
