import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name CreateAdventureService
 * @description
 * # createAdventureService
 * Service in the heliApp.
 */

@Injectable()

export class CreateAdventureService {

  constructor(
    @Inject(WebService) private webService: WebService
  ) { }

  sendReq(method, param, file) {
    return new Promise((resolve, reject) => {
      method(param, file).then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    }) /* asynchronously complete request */
  }

  createPackage(data, file) { // service to create operator packages
    return new Promise((resolve, reject) => {
      this.webService.createPackage(data, file).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  getSkiierAbilities() { // service to get all amentities available
    return new Promise((resolve, reject) => {
      this.webService.getSkiierAbilities().then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  getPackageAmenities() { // service to get all amentities available
    return new Promise((resolve, reject) => {
      this.webService.getPackageAmenities().then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  getSportsList() { // service to get all available sports
    return new Promise((resolve, reject) => {
      this.webService.getSportsList().then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  publishPackage(data, file) { // service to send complete adventure Detail for publish
    return new Promise((resolve, reject) => {
      this.webService.publishPackage(data, file).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  getPackageDetail(data, file) { // service to get operator packages detail
    return new Promise((resolve, reject) => {
      this.webService.getPackageDetail(data, file).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  getCancelPolicyDeatil(data) { // service to get cancel policy detail
    return new Promise((resolve, reject) => {
      this.webService.getCancelPolicyDeatil(data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  getCurrencyList() { // service to get currency list
    return new Promise((resolve, reject) => {
      this.webService.getCurrencyList().then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  getAllListCountry() { // service to get country list
    return new Promise((resolve, reject) => {
      this.webService.getAllListCountry().then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
}

angular.module('heliApp').service('createAdventureService', downgradeInjectable(CreateAdventureService));
