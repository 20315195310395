import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name AmbassadorsListingService
 * @description
 * # ambassadorsListingService
 * Service in the heliApp.
 */

@Injectable()

export class AmbassadorsListingService {

  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  /**
   * @ngdoc method
   * @name getAmbassordsList
   *
   * @description
   * To get the list of Ambassadors
   **/

  getAmbassadorsList(data) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'influencers/get-list?' + 'limit=' + data.limit + '&page=' + data.page + '&search=' + data.search + '&sort=' + data.sort + '&back=' + data.back,
      data: data,
      headers: {
        'token': data.token,
        'Content-Type': 'application/json'
      }
    };
    return this.webService.httpReq(req);
  }
}

angular.module('heliApp').service('ambassadorsListingService', downgradeInjectable(AmbassadorsListingService));