import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name PublicFeedsService
 * @description
 * # publicFeedsService
 * Service in the heliApp.
 */

@Injectable()

export class PublicFeedsService {

  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  sendReq(method) {
    return new Promise((resolve, reject) => {
      method.then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })/* asynchronously complete request */
  }

  postReq(data, url) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + url,
      data: data.feed,
      headers: {
        'token': data.token
      }
    };
    return this.webService.httpReq(req);
  }
  getReq(data, url) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + url,
      headers: {
        'token': data.token
      }
    };
    return this.webService.httpReq(req);
  }
  deleteReq(feedData, url, token) {
    let req = {
      method: 'DELETE',
      url: this.REQUEST_URL + url,
      data: feedData,
      headers: {
        'token': token
      }
    };
    return this.webService.httpReq(req);
  }
  /**
     * @ngdoc method
     * @name getPublicFeeds
     *
     * @description
     * This method call API to get public feeds
     **/
  getPublicFeeds(data) {
    return this.getReq(data, 'feeds/getFeeds?limit=' + data.limit + '&page=' + data.page + '&back=' + data.back);
  }
  /**
     * @ngdoc method
     * @name likeFeed
     *
     * @description
     * This method call API to like feed
     **/
  likeFeed(data) {
    return this.postReq(data, 'social-activities/like');
  }
  /**
     * @ngdoc method
     * @name unlikeFeed
     *
     * @description
     * This method call API to like feed
     **/
  unlikeFeed(data) {
    return this.postReq(data, 'social-activities/Unlike');
  }
  /**
     * @ngdoc method
     * @name getLikeUsers
     *
     * @description
     * This method call API to get like user list names
     **/
  getLikeUsers(data) {
    return this.getReq(data, 'social-activities/get-feed-user-likes?limit=' + data.limit + '&page=' + data.page + '&back=' + data.back + '&feed_id=' + data.feed_id);
  }
  /**
     * @ngdoc method
     * @name getNewFeedsCount
     *
     * @description
     * This method call API to get new feeds count
     **/
  getNewFeedsCount(data) {
    return this.getReq(data, 'feeds/getTotalCountFeeds');
  }

  /**
     * @ngdoc method
     * @name deletePost
     *
     * @description
     * This method call API to delete feeds.
     **/
  deletePost(feedData, token) {
    return this.deleteReq(feedData, 'feeds/deleteFeed', token);
  }
}
angular.module('heliApp').service('publicFeedsService', downgradeInjectable(PublicFeedsService));
