import { Component, Input, OnInit } from '@angular/core';
import { UIRouter } from '@uirouter/core';

@Component({
  selector: 'app-operators-slider',
  templateUrl: '/ng-app/src/app/components/home/operators-slider/operators-slider.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/home/operators-slider/operators-slider.component.css']
})
export class OperatorsSliderComponent implements OnInit {

  @Input() homePageLogo: any = {};
  
  slickOptions = {
    enabled: true,
    autoplay: true,
    draggable: false,
    autoplaySpeed: 2000,
    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  }
  constructor(
    public router: UIRouter
  ) { }

  ngOnInit(): void {
    
  }

}
