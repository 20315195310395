/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:operatorBookingTypeSelectionCtrl
 * @description
 * # operatorBookingTypeSelectionCtrl
 * Controller of the heliApp
 */
angular.module('heliApp')
  .controller('operatorBookingTypeSelectionCtrl', ['$scope', '$filter', '$timeout', '$state', '$stateParams', '$rootScope', 'CONST_HELPER', '$cookies', '$location', function ($scope, $filter, $timeout, $state, $stateParams, $rootScope, CONST_HELPER, $cookies, $location) {
    $scope.homeRedirection();// check for authorized user

    $scope.package_type = $stateParams.package_type;
    $rootScope.packageType = $rootScope.packageType ? $rootScope.packageType : $cookies.get('package_type');
    $scope.package_case = $stateParams.package_case;
    $rootScope.packageCase = $rootScope.packageCase ? $rootScope.packageCase : $cookies.get('package_case');

    $scope.caseSelectionBookingType = function (bookingType) {
      $rootScope.packageBookingType = bookingType;
      $cookies.put('booking_type', bookingType);
    };

    window.onunload = function () {
      // reset cookies on window reload
      $cookies.put('package_type', $rootScope.packageType);
      $cookies.put('package_case', $rootScope.packageCase);
    };
  }]);
