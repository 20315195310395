/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
'use strict';
/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:operatorManifestCtrl
 * @description
 * # operatorManifestCtrl.
 * Controller of the heliApp
 */
angular.module('heliApp').controller('operatorManifestCtrl', [
  '$scope',
  '$rootScope',
  '$timeout',
  '$window',
  '$state',
  '$filter',
  'CONST_HELPER',
  'operatorManifestService',
  'binaryService',
  function($scope, $rootScope, $timeout, $window, $state, $filter, CONST_HELPER, operatorManifestService, binaryService) {

    // Initials values for dates filter is: current day -> current day + 1 year
    $scope.fromDateDefault = moment().startOf('day');
    $scope.toDateDefault = moment().add(1, 'years');

    $scope.filterToDate = moment($scope.toDateDefault).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
    $scope.filterFromDate = moment($scope.fromDateDefault).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);

    $scope.manifestFilter = {
      page: 1,
      limit: 10,
      order_by: 'arrival_date',
      order_direction: 'desc',
      search: {
        text: '',
        apply: function () {
          $scope.getOperatorManifestListFilter();
        },
        clear: function () {
          this.text = '';
        }
      },
      location_id: '',
      adventure_id: '',
      date_to: $scope.toDateDefault.format('YYYY-MM-DD'),
      date_from: $scope.fromDateDefault.format('YYYY-MM-DD'),
      is_filter_applied: false
    };

    $scope.pagination = {
      totalItems: 0,
      itemsPerPage: $scope.manifestFilter.limit,
      pageChanged: function () {
        $scope.getOperatorManifestListFilter();
      }
    };

    $scope.homeRedirection(); // check for authorised access
    $timeout(function() {
      angular.element(document.querySelector('#sandbox-container div')).datepicker({maxViewMode: 0}).on('changeDate', function(e) {
        setSpecificFromToValues(e);
      });
      $scope.showCustomFilter(false); // call show custom filter
    }, 1000);

    function setSpecificFromToValues(e) {
      var specificToDate = angular.element(document.querySelector('#specific-to-date'));
      var specificFromDate = angular.element(document.querySelector('#specific-from-date'));
      if ($scope.isSpecificFromSelected) {
        // $scope.isSpecificFromSelected is true means From specific textbox value is been selected from the emebeded calendar
        $scope.toDate = moment(e.date).format('YYYY-MM-DD');
        // Specific from Date is lesser than Specific To Date
        if (moment($scope.fromDate, CONST_HELPER.analytics_filter.DATE_FORMAT_PAYOUT).isBefore(moment($scope.toDate))) {
          specificToDate.val(moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY));
        } else {
          // Selected Specific To Date is lesser than From.So, Selected date will become Specific From date and already Selected From date will be To date
          $scope.toDate = $scope.fromDate;
          specificToDate.val(specificFromDate.val());
          specificFromDate.val(moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY));
          $scope.fromDate = moment(e.date).format('YYYY-MM-DD');
        }
        $scope.filterToDate = $scope.manifestFilter.date_to = moment($scope.toDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
        $scope.filterFromDate = $scope.manifestFilter.date_from = moment($scope.fromDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
        $scope.isSpecificFromSelected = false; // now again if calendar will be clicked then Specific From date textbox will be selected
        $scope.showCustomFilter(false);
        $scope.$apply();
      } else {
        specificFromDate.val(moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY));
        $scope.fromDate = moment(e.date).format('YYYY-MM-DD');
        specificToDate.val('');
        $scope.isSpecificFromSelected = true;
      }
    }

    $scope.showCustomFilter = function(isShow) {
      if (isShow) {
        angular.element(document.querySelector('.analytics-dropdown')).toggle();
      } else {
        angular.element(document.querySelector('.analytics-dropdown')).css('display', 'none');
      }
      $rootScope.isCustomCalendarClick = true; // to handle document click to close the custom filter dropdown
    };

    function getOperatorPackages() {
      var data = {
        'status': 1,
        'token': $scope.user.token
      };

      operatorManifestService.getOperatorPackages(data).then(function(response) {
        if (response.data && response.data.code === CONST_HELPER.API_RESPONSE.OK) {
          $scope.packageListAll = $scope.packageList = response.data.data;
        }
      });
    }

    getOperatorPackages();

    function getLocationNamesAll() {
      var data = {
        token: $scope.user.token
      };
      operatorManifestService.getLocationNames(data).then(function(response) {
        if (response.data && response.data.code === CONST_HELPER.API_RESPONSE.OK) {
          $scope.locationFilter = response.data.data;
          $scope.locationFilter.unshift({id: CONST_HELPER.ZERO, name: 'All locations'});
        }
      });
    };
    getLocationNamesAll();

    $scope.locationChangeHandler = function() {
      if ($scope.manifestFilter.location_id > 0) {
        $scope.packageList = $scope.packageListAll.filter(function (packageObject) {
          return packageObject.op_location_id === $scope.manifestFilter.location_id;
        });
      } else {
        $scope.packageList = $scope.packageListAll;
      }
    };

    $scope.getManifestListAll = function() {
      var data = {
        token: $scope.user.token,
        date_from: $scope.manifestFilter.date_from,
        date_to: $scope.manifestFilter.date_to
      };

      $scope.showLoader();

      operatorManifestService.getOperatorManifestList(data).then(function(response) {
        if (response.data && response.data.code === CONST_HELPER.API_RESPONSE.OK) {
          $scope.pagination.totalItems = response.data.count;

          $scope.allGuestsBookingListManifest = response.data.data;
          $scope.allGuestsBookingListManifest.forEach(function(eachGuests) {
            eachGuests.arrival_date = moment(eachGuests.arrival_date).utc().format('MM/DD/YYYY');
            eachGuests.departure_date = moment(eachGuests.departure_date).utc().format('MM/DD/YYYY');
            eachGuests.additionalInformation = false;
          })
        }
        $scope.hideLoader();
      }, function() {
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });
    };

    $scope.getOperatorManifestListFilter = function(config) {
      config = config || {};

      if ($scope.manifestFilter.date_to && $scope.manifestFilter.date_from) {
        $scope.manifestFilter.date_from = moment($scope.manifestFilter.date_from).format('YYYY-MM-DD')
        $scope.manifestFilter.date_to = moment($scope.manifestFilter.date_to).format('YYYY-MM-DD')
      }

      var data = {
        token: $scope.user.token,
        search: $scope.manifestFilter.search.text,
        location_id: $scope.manifestFilter.location_id,
        adventure_id: $scope.manifestFilter.adventure_id,
        date_from: $scope.manifestFilter.date_from,
        date_to: $scope.manifestFilter.date_to,
        order_by: $scope.manifestFilter.order_by,
        order_direction: $scope.manifestFilter.order_direction,
        page: $scope.manifestFilter.page,
        limit: $scope.manifestFilter.limit
      };

      if (config.params) {
        angular.extend(data, config.params);
      }

      $scope.showLoader();

      operatorManifestService
        .getOperatorManifestListFilter($scope.user.token, data, config.responseType)
        .then(function (response) {
          console.log(response);
          $scope.hideLoader();
          $scope.pagination.totalItems = response.data.count;

          var contentTypeHeader = response.headers.get('Content-Type');
          if (/json/i.test(contentTypeHeader)) {
            if (response.data && response.data.code === CONST_HELPER.API_RESPONSE.OK) {
              $scope.allGuestsBookingListManifest = response.data.data;
              $scope.allGuestsBookingListManifest.forEach(function (eachGuests) {
                eachGuests.arrival_date = moment(eachGuests.arrival_date).utc().format('MM/DD/YYYY');
                eachGuests.departure_date = moment(eachGuests.departure_date).utc().format('MM/DD/YYYY');
                eachGuests.additionalInformation = false;
              });
              $scope.filterWasAppliedHideButtonLoadMore = true;
            }
          } else if (/csv|pdf/i.test(contentTypeHeader)) {
            binaryService.downloadFileFromBlob({
              file: {
                name: binaryService.getFilenameFromResponse(response)
              },
              blob: {
                parts: response.data,
                options: { type: response.headers.get('Content-Type') }
              }
            });
          }
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
    }

    $scope.printPage = function() {
      window.print();
    }

    $scope.toggleOpenAllManifestItems = function(flag) {
      $scope.allGuestsBookingListManifest.forEach(function(eachGuests) {
        eachGuests.additionalInformation = flag;
      })
    }

    $scope.saveOperatorNotes = function(eachGuests) {
      var data = {
        operator_notes: eachGuests.operator_notes
      };
      $scope.showLoader();
      operatorManifestService.saveOperatorNotes($scope.user.token, eachGuests.guest_id, data).then(function(response) {
        eachGuests.edit_operator_notes = false;
        $scope.hideLoader();
      }, function() {
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });
    }

    $scope.changeOrderDirection = function(orderDirection) {
      if (orderDirection == 'asc') {
        $scope.manifestFilter.order_direction = 'desc';
      } else {
        $scope.manifestFilter.order_direction = 'asc';
      }
      $scope.getOperatorManifestListFilter()
    }

    $scope.applyManifestFilters = function () {
      $scope.manifestFilter.is_filter_applied = true;
      $scope.getOperatorManifestListFilter();
    };

    $scope.clearManifestFilters = function() {
      $scope.manifestFilter.is_filter_applied = false;
      $scope.filterWasAppliedHideButtonLoadMore = false;
      $scope.manifestFilter.search.text = '';
      $scope.manifestFilter.location_id = '';
      $scope.manifestFilter.adventure_id = '';
      $scope.manifestFilter.date_from = $scope.fromDateDefault.format('YYYY-MM-DD');
      $scope.manifestFilter.date_to = $scope.toDateDefault.format('YYYY-MM-DD');
      $scope.manifestFilter.page = 1;
      $scope.filterToDate = $scope.toDateDefault.format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
      $scope.filterFromDate = $scope.fromDateDefault.format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
      $scope.getOperatorManifestListFilter();
    }

    $scope.getBookingSourceNameById = function (bookingSourceId) {
      var bookingSourceNames = ['All Booking Sources', 'Heli', 'My Site', '', 'Manual'];

      return bookingSourceNames[bookingSourceId];
    };

    $scope.getPaymentStatusNameById = function (paymentStatusId, bookingSourceId) {
      if (paymentStatusId === 1) {
        return 'Fully Paid';
      } else if (paymentStatusId === 2) {
        return 'Received Deposit';
      } else if (paymentStatusId === 3) {
        return bookingSourceId === 3 ? 'Payment Pending' : 'Cancelled';
      }

      return '';
    };

    $scope.exportAs = function ($event, type, viewMode) {
      $scope.getOperatorManifestListFilter({
        event: $event,
        responseType: type,
        params: {
          no_limit: true,
          view_mode: viewMode
        }
      });
    };
  }
]);
