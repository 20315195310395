import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name OperatorPayoutService
 * @description
 * # operatorPayoutService
 * Service in the heliApp.
 */

@Injectable()

export class OperatorPayoutService {

  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  sendReq(method) {
    return new Promise((resolve, reject) => {
      method.then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    }) /* asynchronously complete request */
  }

  /**
        * @ngdoc method
        * @name post payout history list detail
        *
        * @description
        * This method call API to fetch payout history list detail
    **/
  getPayoutHistoryReq(data, token) {
    var req = {
      method: 'POST',
      url: this.REQUEST_URL + 'transactions/payoutHistory?to_date=' + data.to_date + '&from_date=' + data.from_date + '&page=' + data.page,
      headers: {
        'token': token
      },
      data: data
    };
    return this.webService.httpReq(req);
  }

  /**
        * @ngdoc method
        * @name post payout history list detail
        *
        * @description
        * This method call API to fetch payout history list detail
    **/
  getPayoutHistoryWithStatusReq(data, token) {
    var req = {
      method: 'POST',
      url: this.REQUEST_URL + 'transactions/operatorPayoutHistory',
      headers: {
        'token': token
      },
      data: data
    };
    return this.webService.httpReq(req);
  }
  /**
        * @ngdoc method
        * @name get payout history detail
        *
        * @description
        * This method call API to fetch payout history detail
    **/
  getPayoutHistoryDetailReq(data, token) {
    var req = {
      method: 'GET',
      url: this.REQUEST_URL + 'transactions/payoutHistoryDetails?payment_detail_id=' + data.payoutId + '&payment_no=' + data.payment_no,
      headers: {
        'token': token
      }
    };
    return this.webService.httpReq(req);
  }

  getPayoutHistoryWithStatus(data, token) {
    return this.sendReq(this.getPayoutHistoryWithStatusReq(data, token));
  }

  getPayoutHistory(data, token) { //  for get payout history list detail
    return this.sendReq(this.getPayoutHistoryReq(data, token));
  }

  getPayoutHistoryDetail(data, token) { //  for get payout history detail
    return this.sendReq(this.getPayoutHistoryDetailReq(data, token));
  }

  getAllCountry(token) {
    var req = {
      method: 'GET',
      url: this.REQUEST_URL + 'masters/all-country',
      headers: {
        'token': token,
        'Content-Type': 'application/json'
      }
    };
    return this.webService.httpReq(req);
  }

  exportCsv(token, params) {
    return this.webService.httpReq({
      method: 'GET',
      url: this.REQUEST_URL + 'transactions/operatorPayoutHistoryForExport',
      params: params,
      responseType: 'blob',
      headers: {
        token: token,
        Accept: 'text/csv'
      }
    })
  }
}

angular.module('heliApp').service('operatorPayoutService', downgradeInjectable(OperatorPayoutService));
