/* eslint-disable handle-callback-err */
/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:UserRegisterationCtrl
 * @description
 * # UserRegisterationCtrl
 * Controller of the heliApp
 */
angular.module('heliApp').controller(
  'UserRegisterationCtrl', ['$scope', '$rootScope', 'userRegisterService', '$window', '$state', '$timeout', '$stateParams', 'REQUEST_URL', '$cookies', 'agentService', '$location', 'CONST_HELPER',
  function ($scope, $rootScope, userRegisterService, $window, $state, $timeout, $stateParams, REQUEST_URL, $cookies, agentService, $location, CONST_HELPER) {
    $scope.user = {};
    $scope.showForm = false;
    $scope.emailErr = false;// sign up error messages
    $scope.passwordErr = false;
    $scope.confirmPasswordErr = false;
    $scope.signup = true;// show signup page
    $scope.registerErr = false; // registeration request err check
    $scope.firstNameErr = false;// complete sign up error messages
    $scope.lastNameErr = false;
    $scope.tcCheckBoxErr = false;
    $rootScope.userNameErr = false;
    $rootScope.userNameValid = true;
    $scope.userNameExistErr = false;// set error status
    $scope.accountErr = false;
    $scope.socialSignUp = false;
    $scope.accountMerge = false;
    $scope.resetPassword = false;// reset password screen
    $scope.resetPasswordErr = false;
    $scope.resetConfirmPasswordErr = false;
    $scope.resetPassErr = false;
    $scope.resetPasswordModel = {};
    $scope.mergeStatus = false;
    $scope.isSignupDetailsValid = false;
    $scope.agent = {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      confirmPassword: ''
    };
    $scope.emailPattern = /^([a-zA-Z0-9_.-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    $scope.validAgentUser = false;
    console.log('tag', $stateParams.signup_token);
    if ($rootScope.isAgent && $stateParams.signup_token) {
      $scope.showLoader();
      var requestObject = {
        signup_token: $stateParams.signup_token
      };
      agentService.validateAgentToken(requestObject).then(function (success) {
        console.log('tag', success);
        if (success.data.code == 200) {
          $scope.validAgentUser = true;
          $scope.agent.email = success.data.data.email;
        } else {
          $state.go('signin');
        }
        $scope.hideLoader();
        $scope.showForm = true;
      }, function (error) {
        $scope.showForm = true;
      });
    } else {
      $scope.showForm = true;
    }

    $scope.getAllCountryName = function () {
      userRegisterService.getAllCountry().then(function (data) {
        if (data.data.code === CONST_HELPER.API_RESPONSE.OK) {
          $scope.allcountryName = data.data.data;
        }
      });
    };
    $scope.getAllCountryName();

    $scope.registerAgent = function (form) {
      console.log('tag', $scope.agent);
      if (form.first_name.$pristine || (form.first_name.$viewValue).trim() === '') { // check for required validaiton of the first name
        $scope.firstNameErr = true;
      } else {
        $scope.firstNameErr = false;
      }
      if (form.last_name.$pristine || (form.last_name.$viewValue).trim() === '') { // check for required validaiton of the last name
        $scope.lastNameErr = true;
      } else {
        $scope.lastNameErr = false;
      }
      if (form.password.$pristine || (form.password.$viewValue).trim() === '') { // check for required validaiton of the password
        $scope.passwordErr = true;
      } else {
        $scope.passwordErr = false;
      }
      if ($scope.agent.password && ($scope.agent.password).trim() === '') {
        $scope.passwordErr = true;// password is blank
      }
      if (form.confirmPassword.$pristine || (form.confirmPassword.$viewValue).trim() === '') { // check for required validaiton of the confirm password
        $scope.confirmPasswordErr = true;
      } else {
        $scope.confirmPasswordErr = false;
      }
      if (!$scope.agent.tcCheckBox) {
        $scope.tcCheckBoxErr = true;
      } else {
        $scope.tcCheckBoxErr = false;
      }
      if ($scope.confirmPasswordErr || $scope.passwordErr || $scope.firstNameErr || $scope.lastNameErr || $scope.tcCheckBoxErr || !form.$valid) {
        $scope.isSignupDetailsValid = true;
        return;
      }
      $scope.showLoader();
      $scope.agent.signup_token = $stateParams.signup_token;
      agentService.completeAgentSignup($scope.agent).then(function (success) {
        console.log('tag', success);
        if (success.data.code == 200) {
          $scope.setUserDetails(success.data.data, 'second');// set user details in the rootScope;
          $scope.user = success.data.data;// set user data into local scope;
          $scope.hostName = $location.host();
          if ($scope.hostName != 'localhost') {
            console.log('tag', $scope.hostName);
            var protocol = window.location.href.split('://')[0];
            $scope.hostName = window.location.host.substr(window.location.host.indexOf('.'));
            console.log('hostname', protocol + '://' + $scope.user.username + $scope.hostName + '/agent-calendar');
            window.location.href = protocol + '://' + $scope.user.username + $scope.hostName + '/agent-calendar';
          } else {
            $state.go('agent-calendar');
          }
        } else {
          $scope.showPopup('#serverErrModal');
          $state.go('signin');
        }
        $scope.hideLoader();
      });
    };
    $scope.userRegister = function (form) { // user registeration to heli platform directly
      if (form.email.$pristine || (form.email.$viewValue).trim() === '') { // check for required validaiton of the email
        $scope.emailErr = true;
      } else {
        $scope.emailErr = false;
      }
      if (form.password.$pristine || (form.password.$viewValue).trim() === '') { // check for required validaiton of the password
        $scope.passwordErr = true;
      } else {
        $scope.passwordErr = false;
      }
      if ($scope.user.password && ($scope.user.password).trim() === '') {
        $scope.passwordErr = true;// password is blank
      }
      if (form.confirmPassword.$pristine || (form.confirmPassword.$viewValue).trim() === '') { // check for required validaiton of the confirm password
        $scope.confirmPasswordErr = true;
      } else {
        $scope.confirmPasswordErr = false;
      }
      if (!$scope.confirmPasswordErr && !$scope.emailErr && !$scope.passwordErr && form.$valid) { // check if form is valid or not
        var data = { 'email': ($scope.user.email).trim(), 'password': $scope.user.password };// set data field
        if ($stateParams.referral_id) { // check for influencer referral
          data.referral_id = $stateParams.referral_id;
          $rootScope.referral_id = $stateParams.referral_id;
        }
        $scope.showLoader();// show loader
        userRegisterService.registerUser(data).then(function (response) { // post to the server the form data
          if (response.data) {
            if (response.data.code !== '200') { // some error exits
              $scope.registerErr = true;// set error status
              for (var key in response.data.message[Object.keys(response.data.message)]) {
                $scope.registerErrMessage = response.data.message[Object.keys(response.data.message)][key];
              }
              var errType = Object.keys(response.data.message)[0];
              if (errType === 'email') { // its email error
                $scope.emailErr = true;
              } else if (errType === 'password') { // its password error
                $scope.passwordErr = true;
              }
            } else { // if  registeration is successful
              $scope.registerErr = false;// set error status
              $scope.setUserDetails(response.data.data, 'first');// set user details in the rootScope;
              $cookies.put('signup', false);
              $scope.user = response.data.data;// set user data into local scope;
              $scope.signup = false;// show complete you account screen
            }
            $scope.hideLoader();// hide loader
          }
        }, function () { // check for error
          $scope.hideLoader();// hide loader
          $scope.showPopup('#serverErrModal');
        });
      }
    };

    $scope.completeSignUp = function (form) { // complete the sign up process
      $scope.isSignupDetailsValid = true;
      var phoneNumberValue = $("#phoneFieldNoSocial").intlTelInput("getNumber");
      if (phoneNumberValue == "" || phoneNumberValue.length < 8 || phoneNumberValue.length > 16) {
        $scope.phoneNumberError = true;
      } else {
        $scope.phoneNumberError = false;
      };
      if (!$scope.user.tcCheckBox) {
        $scope.tcCheckBoxErr = true;
      } else {
        $scope.tcCheckBoxErr = false;
      }
      if (form.first_name.$pristine || (form.first_name.$viewValue).trim() === '') { // check for required validaiton of the first name
        $scope.firstNameErr = true;
      } else {
        $scope.firstNameErr = false;
      }
      if (form.last_name.$pristine || (form.last_name.$viewValue).trim() === '') { // check for required validaiton of the last name
        $scope.lastNameErr = true;
      } else {
        $scope.lastNameErr = false;
      }
      if ((form.username.$viewValue).trim() === '') { // check for required validaiton of the last name
        $rootScope.userNameErr = true;
      } else {
        $rootScope.userNameErr = false;
        if ($scope.userNameValidation((form.username.$viewValue).trim())) {
          $rootScope.userNameValid = true;
        } else {
          $rootScope.userNameValid = false;
        }
      }

      if (!$scope.user.country_id ) {
        $scope.userCountryError = true;
      } else {
        $scope.userCountryError = false;
      }

      if (!$scope.phoneNumberError && !$scope.firstNameErr && !$scope.lastNameErr && !$rootScope.userNameErr && !$scope.userNameExistErr && !$scope.tcCheckBoxErr && form.$valid && !$scope.userCountryError) { // check if form is valid or not
        var data = { 'first_name': ($scope.user.first_name).trim(), 'last_name': ($scope.user.last_name).trim(), 'username': ($scope.user.username).trim(), 'temp_id': $scope.user.id, 'phone_number': phoneNumberValue, country_id:  $scope.user.country_id};// set data field
        $scope.showLoader();// show loader
        userRegisterService.completeSignUp(data).then(function (response) { // post to the server the form data
          if (response.data) {
            if (response.data.code !== '200') { // some error exits
              $scope.accountErr = true;// set error status
              for (var key in response.data.message[Object.keys(response.data.message)]) {
                $scope.accountErrMessage = response.data.message[Object.keys(response.data.message)][key];
              }
              var errType = Object.keys(response.data.message[Object.keys(response.data.message)[0]])[0];
              if (errType === 'username') { // its email error
                $rootScope.userNameErr = true;
              }
            } else { // if final registeration is successful
              $scope.accountErr = false;// set error status
              $scope.setUserDetails(response.data.data, 'second');// set user details in the rootScope;
              $scope.user = response.data.data;// set user data into local scope;
              $scope.emailVerificationAlert(); // show email verification alert
              if (typeof $rootScope.redirectAfterRegister !== 'undefined' && $rootScope.redirectAfterRegister !== '') {
                $window.location.href = $rootScope.redirectAfterRegister;
              } else if ($rootScope.previousState && $rootScope.previousState.name !== '' && $rootScope.previousState.name !== 'mergeAccountSuccess') {
                $window.history.back();
              }  else {
                $state.go('home');
              }

              // Enhanced Ecommerce for sign up process goal...
              var ecData = {
                'hitType': 'event',
                'eventCategory': 'Signup',
                'eventAction': 'complete',
                'eventLabel': 'user sign up process completed',
                'event_type': 'signupComplete'
              };
              $rootScope.$broadcast('addEventToEC', ecData);
            }
            $scope.hideLoader();// hide loader
          }
        }, function (error) { // check for error
          $scope.hideLoader();// hide loader
          $scope.showPopup('#serverErrModal');
        });
      }
    };

    $scope.getSignUpStatus = function () { // get status of sing up process
      if ($scope.hasProfile) { // if already logged in user
        $state.go('home');// redirect to home page
      }
      if ($scope.signup && $cookies.get('user') && !$scope.hasProfile) {
        $cookies.remove('user');
      }
      var user = $cookies.get('user');
      $scope.user = (user) ? JSON.parse(user) : {};
      if ($rootScope.fromSocial) { // send request to social login if user tries to signup from login page using social media
        $rootScope.fromSocial = false;
        $scope.signup = false;
        $scope.socialSignUp = true;
        $timeout(function () {
          $rootScope.$broadcast('social-sign-in-from-login', $rootScope.userDetailsSocial, $rootScope.responseSocial);
        });
      }
      if ($rootScope.currentState.name === 'resetPassword') {
        checkForValidToken($stateParams.token);// check for valid token to show rest password page
      }
    };

    $scope.checkUserNameExistence = function (username) { // check for username existence
      if (typeof username === 'undefined') {
        username = document.getElementById($scope.socialSignUp ? "user_username" : "username").value;
      }
      if (username.length > 0) {
        $rootScope.userNameErr = false;// if username contains value after err display
      }
      $rootScope.userNameValid = $scope.userNameValidation(username);
      if (!$rootScope.userNameValid) {
        return $rootScope.userNameValid;
      }
      if (username.length >= 5 && username.length <= 20 && $rootScope.userNameValid) { // if user name is within specified range
        username = { 'username': username };
        userRegisterService.checkUserName(username).then(function (response) { // post to the server the user name
          if (response.data) {
            if (response.data.code !== '200') { // some error exits
              $scope.userNameExistErr = true;// set error status
              for (var key in response.data.message[Object.keys(response.data.message)]) {
                $scope.userNameExistErrMessage = response.data.message[Object.keys(response.data.message)][key];
              }
            } else {
              $scope.userNameExistErr = false;// set error status
            }
          }
        }, function (error) { $scope.showPopup('#serverErrModal'); });
      }
    };

    $scope.removeErr = function (model, err) { // remove error messages on inputs filling
      if (model !== '') {
        if (err === 'lastNameErr') {
          $scope.lastNameErr = false;
        } else if (err === 'tcCheckBoxErr') {
          $scope.tcCheckBoxErr = false;
        } else {
          $scope.firstNameErr = false;
        }
      }
    };

    $scope.userResetForgotPassword = function (form) { // set new password
      if (form.resetpassword.$pristine || (form.resetpassword.$viewValue).trim() === '') { // check for required validaiton of the password
        $scope.resetPasswordErr = true;
      } else {
        $scope.resetPasswordErr = false;
      }
      if (form.resetConfirmPassword.$pristine || (form.resetConfirmPassword.$viewValue).trim() === '') { // check for required validaiton of the confirm password
        $scope.resetConfirmPasswordErr = true;
      } else {
        $scope.resetConfirmPasswordErr = false;
      }
      if ($scope.resetPasswordModel.resetpassword && ($scope.resetPasswordModel.resetpassword).trim() === '') {
        $scope.resetPasswordErr = true;// password is blank
      }
      var data = { 'forgot_password_token': $stateParams.token, 'password': $scope.resetPasswordModel.resetpassword, 'confirm_password': $scope.resetPasswordModel.resetConfirmPassword };
      if (!$scope.resetPasswordErr && !$scope.resetConfirmPasswordErr) {
        userRegisterService.resetPass(data).then(function (response) { // post to the server the new  password
          if (response.data) {
            if (response.data.code !== '200') { // some error exits
              $scope.resetPassErr = true;// set error status
              for (var key in response.data.message[Object.keys(response.data.message)]) {
                $scope.resetPassErrMessage = response.data.message[Object.keys(response.data.message)][key];
              }
              var errType = Object.keys(response.data.message[Object.keys(response.data.message)[0]])[0];
              if (errType === 'password') { // its password error
                $scope.resetPasswordErr = true;
              } else if (errType === 'confirm_password') { // its confirm pasword error
                $scope.resetConfirmPasswordErr = true;
              }
            } else {
              $scope.resetPassErr = false;
              $rootScope.forgotPasswordSuccess = true;
              $state.go('forgot-success');
            }
          }
        }, function (error) { $scope.showPopup('#serverErrModal'); });
      }
    };

    function checkForValidToken (token) {
      var forgotToken = { 'forgot_password_token': token };
      userRegisterService.checkForValidToken(forgotToken).then(function (response) { // post to the server the token for forgot
        if (response.data) {
          if (response.data.code !== '200') { // some error exits
            $state.go('link-expire');// move to link expire page
          } else {
            $scope.resetPassword = true;
          }
        }
      }, function (error) { $scope.showPopup('#serverErrModal'); });
    }

    $scope.goBackCompleteAccount = function () { // error status on mergr true
      $scope.accountMerge = false;
      $scope.mergeStatus = true;
    };
  }]);
