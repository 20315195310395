import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name SharedService
 * @description
 * # sharedService
 * Service in the heliApp.
 */

@Injectable()

export class SharedService {

  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService
    ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  sendReq(method) {
    return new Promise((resolve, reject) => {
      method.then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })/* asynchronously complete request */
  }

  postReq(url, data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + url,
      data: data,
      headers: {
        'token': data.token,
        'Content-Type': 'application/json'
      }
    };
    return this.webService.httpReq(req);
  }
  getReq(url, token?) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + url,
      headers: {
        'token': token
      }
    };
    return this.webService.httpReq(req);
  }

  sendEmail(data) { // share the content on Email
    return this.sendReq(this.webService.sendEmail(data));
  }

  /**
   * @ngdoc method
   * @name connectFBAccountReq
   *
   * @description
   * This method call to save the FB access token
   **/
  connectFBAccountReq(data) {
    return this.postReq('social-accounts/connect-facebook', data);
  }

  /**
   * @ngdoc method
   * @name disconnectSocialAccount
   *
   * @description
   * This method call to disconnect social accounts
   **/
  disconnectSocialAccount(data) {
    let req = {
      method: 'DELETE',
      url: this.REQUEST_URL + 'social-accounts/remove',
      data: data,
      headers: {
        'token': data.token,
        'Content-Type': 'application/json'
      }
    };
    return this.webService.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name connectTwitterAccount
   *
   * @description
   * This method call to connect Twitter account
   **/
  connectTwitterAccount(data) {
    return this.postReq('social-accounts/connect-twitter', data);
    // return this.webService.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name connectLinkedInAccount
   *
   * @description
   * This method call to connect linkedin account
   **/
  connectLinkedInAccount(data) {
    return this.postReq('social-accounts/connect-linkedin', data);
    // return this.webService.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name changeAutoSharingStatus
   *
   * @description
   * This method
   **/
  changeAutoSharingStatus(data) {
    return this.postReq('social-accounts/auto-sharing', data);
  }
  /**
   * @ngdoc method
   * @name shareOnHeli
   *
   * @description
   * This method is to share content on heli feed
   **/
  shareOnHeli(data) {
    return this.postReq('shares/on-heli', data);
  }
  /**
   * @ngdoc method
   * @name shareOnHeliFeed
   *
   * @description
   * This method is to share feed post  on heli feed
   **/
  shareOnHeliFeed(data) {
    return this.postReq('shares/feed-share', data);
  }
  /**
   * @ngdoc method
   * @name followUser
   *
   * @description
   * To follow any user
   **/
  followUser(data) {
    return this.postReq('social-activities/follow', data);
  }
  /**
   * @ngdoc method
   * @name validFollowRequestToken
   *
   * @description
   * To Accept of Decline the follow request
   **/
  unFollowUser(data) {
    return this.postReq('social-activities/unfollow', data);
  }
  /**
   * @ngdoc method
   * @name user
   *
   * @description
   * To unfollow any user
   **/
  validFollowRequestToken(data) {
    return this.postReq('social-activities/accept-decline-follow-request', data);
  }
  /**
   * @ngdoc method
   * @name addSharingCounter
   *
   * @description
   * To increase the sharing counter when adventure is shared on social media
   **/
  addSharingCounter(data) {
    return this.postReq('shares/add-counter', data);
  }
  /**
   * @ngdoc method
   * @name getHeliTeam
   *
   * @description
   * Get the members detail of Heli team.
   **/
  getHeliTeam() {
    return this.getReq('masters/heli-team');
  }
  /**
   * @ngdoc method
   * @name subscribeHeliUpdate
   *
   * @description
   * subscribeHeliUpdate for the heli team updates
   **/
  subscribeHeliUpdate(data) {
    return this.postReq('subscriptions/updateSubscription', data);
  }
}

angular.module('heliApp').service('sharedService', downgradeInjectable(SharedService));