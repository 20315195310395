import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name OperatorCalendarService
 * @description
 * # operatorCalendarService
 * Service in the heliApp.
 */

@Injectable()

export class OperatorCalendarService {

  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService,
    @Inject(HttpClient) private httpClient: HttpClient
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  sendReq(method, param, token) {
    return new Promise((resolve, reject) => {
      method(param, token).then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    }) /* asynchronously complete request */
  }

  postReq(data, token, url) {
    var req = {
      method: 'POST',
      url: this.REQUEST_URL + url,
      data: data,
      headers: {
        'token': token
      }
    };
    return this.webService.httpReq(req);
  }
  getReq(data, url) {
    var req = {
      method: 'GET',
      url: this.REQUEST_URL + url,
      headers: {
        'token': data.token
      }
    };
    return this.webService.httpReq(req);
  }
  deleteReq(feedData, token, url) {
    var req = {
      method: 'DELETE',
      url: this.REQUEST_URL + url,
      data: feedData,
      headers: {
        'token': token
      }
    };
    return this.webService.httpReq(req);
  }
  putReq(data, token, url) {
    var req = {
      method: 'PUT',
      url: this.REQUEST_URL + url,
      data: data,
      headers: {
        'token': token
      }
    };
    return this.webService.httpReq(req);
  }

  /**
     * @ngdoc method
     * @name getOperatorSlots
     *
     * @description
     * This method call API to get operator slot data
     **/
  getOperatorSlots(data, token) {
    return this.postReq(data, token, 'calendars/getSlots');
  }

  /**
     * @ngdoc method
     * @name getOperatorPackage
     *
     * @description
     * This method call API to get operator package data
     **/
  getOperatorPackages(data) {
    return this.getReq(data, 'operators/packageList?status=1&limit=1000&page=1&back=0');
  }

  /**
     * @ngdoc method
     * @name getOperatorPackageGuestForm
     *
     * @description
     * This method call API to get operator package data
     **/
  getOperatorPackageGuestForm(data) {
    return this.getReq(data, 'guest-forms/packageList');
  }

  /**
     * @ngdoc method
     * @name getSlotGuestData
     *
     * @description
     * This method call API to get social analytics data
     **/
  getSlotGuestData(data) {
    return this.getReq(data, 'guests/getlist/' + data.slot_id);
  }

  /**
     * @ngdoc method
     * @name getNightlySlotGuestData
     *
     * @description
     * This method call API to get guest nightly data
     **/
  getNightlySlotGuestData(data) {
    return this.getReq(data, 'guests/getlistnightly/' + data.from_date + '/' + data.to_date + '/' + data.slot_id);
  }

  /**
     * @ngdoc method
     * @name addManualGuest
     *
     * @description
     * This method call API to save manual guest
     **/
  addManualGuest(data, token) {
    return this.postReq(data, token, 'Guests/saveGuest');
  }

  /**
     * @ngdoc method
     * @name deleteManualGuest
     *
     * @description
     * This method call API to delete manual guest
     **/
  deleteManualGuest(data, token) {
    return this.deleteReq(data, token, 'Guests/deleteGuest');
  }

  /**
     * @ngdoc method
     * @name editManualGuest
     *
     * @description
     * This method call API to edit manual/heli guest
     **/
  editManualGuest(data, token) {
    return this.putReq(data, token, 'Guests/editGuest');
  }

  /**
     * @ngdoc method
     * @name saveSlotNotes
     *
     * @description
     * This method call API to save slots notes.
     **/
  saveSlotNotes(data, token) {
    return this.postReq(data, token, 'Calendars/saveSlotNote');
  }
  /**
    * @ngdoc method
    * @name createGroup
    *
    * @description
    * This method call API to create Groups.
    **/
  createGroup(data, token) {
    return this.postReq(data, token, 'Groups/createGroup');
  }
  /**
    * @ngdoc method
    * @name editGroup
    *
    * @description
    * This method call API to edit Groups.
    **/
  editGroup(data, token) {
    return this.putReq(data, token, 'Groups/editGroup');
  }
  /**
     * @ngdoc method
     * @name deleteGroup
     *
     * @description
     * This method call API to delete manual guest
     **/
  deleteGroup(data, token) {
    return this.deleteReq(data, token, 'Groups/deleteGroup');
  }

  /**
     * @ngdoc method
     * @name getLocationNames
     *
     * @description
     * This method call API to get location names
     **/
  getLocationNames(data) {
    return this.getReq(data, 'locations');
  }

  /**
     * @ngdoc method
     * @name addGuest
     *
     * @description
     * This method call API to add guest to a particular group
     **/
  addGuestToGroup(data, token) {
    return this.postReq(data, token, 'Groups/addGuest');
  }
  /**
     * @ngdoc method
     * @name addGuest
     *
     * @description
     * This method call API to reorder group
     **/
  changeGroupOrder(data, token) {
    return this.putReq(data, token, 'Groups/changeDisplayOrder');
  }

  /**
     * @ngdoc method
     * @name deleteGuest
     *
     * @description
     * This method call API to delete guest from group
     **/
  deleteGuestFromGroup(data, token) {
    return this.deleteReq(data, token, 'Groups/deleteGuest');
  }
  /**
 * @ngdoc method
 * @name saveLodging
 *
 * @description
 * This method call API to saveLodging data
 **/
  saveLodging(data, file, token) {
    if (file.length) {
      const config = {
        url: this.REQUEST_URL + 'packages/saveLodging',
        fields: data,
        file: file,
        fileFormDataName: 'lodging_medias[]',
        headers: {
          'token': token
        }
      }
      return this.webService.angularUploadFile(config);
      // file.upload = this.$upload.upload({/* using angualr file upload service */
      // 	url: this.REQUEST_URL + 'packages/saveLodging',
      // 	method: 'POST',
      // 	fields: data,
      // 	file: file,
      // 	fileFormDataName: 'lodging_medias[]',
      // 	headers: {
      // 		'Content-Type': 'application/x-www-form-urlencoded',
      // 		'token': token
      // 	}
      // });
      // var promise = file.upload.progress((evt) => {
      // }).success((result) => {
      // 	return result;
      // }).error((error) => { /* If service is not successfull then show error */
      // 	return error;
      // });
      // return promise;
    } else {
      return this.postReq(data, token, 'packages/saveLodging');
    }
  }
  /**
     * @ngdoc method
     * @name deleteLodging
     *
     * @description
     * This method call API to deleteLodging data
     **/
  deleteLodging(data, token) {
    return this.postReq(data, token, 'packages/deleteLodging');
  }
}

angular.module('heliApp').service('operatorCalendarService', downgradeInjectable(OperatorCalendarService));
