/* eslint-disable no-useless-escape */
/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:operatorSignupCtrl
 * @description
 * # operatorSignupCtrl
 * Controller of the heliApp
 */

angular.module('heliApp').controller('operatorSignupCtrl',
  [
    '$scope',
    '$location',
    '$rootScope',
    '$timeout',
    '$window',
    'operatorService',
    '$state',
    'operatorProfileService',
    function ($scope, $location, $rootScope, $timeout, $window, operatorService, $state, operatorProfileService) {
      $scope.operatorReg = {};
      $scope.validation = {};
      $scope.operatorRegValidation = {};
      $scope.showEmailErr = false;
      $scope.numberPattern = /^\d*$/;
      $scope.alphaNumericPatternWithSpaces = /^[A-Za-z\d\s]+$/;
      $scope.patternWithSpecialCharAndSpace = /^\d*([a-zA-ZÀ-ž0-9_!$@%*.,'/#&+-\d\s])+([A-zÀ-ž])+([a-zA-ZÀ-ž0-9_!$@%*.,'/#&+-\d\s])*$/;
      $scope.urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
      $scope.emailPattern = /^([a-zA-Z0-9_.-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
      $rootScope.initializePhoneField("#operatorGetStartedPhone");
      $scope.getAllCountryName(); // Call function for get country name

      $scope.opertorSubmit = function () { /* SIGNUP Form for operator */
        if (!$scope.operatorSignupForm.$valid && ($("#operatorGetStartedPhone").intlTelInput("getNumber") == '' || $("#operatorGetStartedPhone").intlTelInput("getNumber").length < 8 || $("#operatorGetStartedPhone").intlTelInput("getNumber").length > 16)) {
          $scope.showPhoneError = true;
        } else {
          $scope.showPhoneError = false;
        }
        if (!$scope.operatorSignupForm.$valid) { // check form is valid or not
          angular.element('input.ng-invalid').first().focus(); // focus on input field's
          var invalidFields = $scope.operatorSignupForm.$error.required || $scope.operatorSignupForm.$error.pattern;
          for (var i = 0; i < invalidFields.length; i++) { // add flag for invalid field's and show error
            $scope.validation[invalidFields[i].$name] = true;
            if (invalidFields[i].$name === 'countryId') { // add class for cuntry
              $('#country').parent().addClass('ng-invalid');
            }
            if (invalidFields[i].$name === 'stateId') { // add class for cuntry
              $('#state').parent().addClass('ng-invalid');
            }
          }
          $('#country').focus(); // focus on country dropdown field
          $('#state').focus(); // focus on country dropdown field
          angular.element("[name='" + $scope.operatorSignupForm.$name + "']").find('input.ng-invalid').first().focus(); // focus on input field's
          return false;
        }

        var requestObject = {
          'business_name': $scope.operatorReg.business_name,
          'business_owner': $scope.operatorReg.business_owner,
          'contact_name': $scope.operatorReg.contact_name,
          'email': $scope.operatorReg.email,
          'phone': $("#operatorGetStartedPhone").intlTelInput("getNumber"),
          'street_address': $scope.operatorReg.street_address,
          'city': $scope.operatorReg.city,
          'state_id': $scope.operatorReg.state,
          'country_id': $scope.operatorReg.id,
          'zipcode': $scope.operatorReg.zipcode,
          'website': $scope.operatorReg.website,
          'notes': $scope.operatorReg.notes,
          'state_code': $scope.operatorReg.state_code
        };
        $scope.showLoader();// show loader

        operatorService.operatorRegistration(requestObject).then(function (response) { /* Call api for operator signup */
          if (response.data.code === '200') { // if response is success then redirect on next page
            $state.go('operator-success-msg');
            $scope.showEmailErr = false;
            $scope.hideLoader();// hide loader
          } else if (response.data.code === '422') { // if response is failed then show error
            $scope.showEmailErr = true;
            $('#email').focus();
            $scope.showEmailErr = true;
            $scope.hideLoader();// hide loader
          }
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };

      $scope.changeCountry = function (value, countryid) {
        if (value) {
          $scope.validation.countryId = false;
          $('#country').parent().removeClass('ng-invalid');
          if ((typeof (countryid) !== 'undefined') && countryid !== null) { // cehck fro undefined values
            getStates(countryid);
            $scope.operatorReg.state = '';
          }
        }
      };

      $scope.setStateCode = function (state) { // register state codes depending on state value
        for (var i = 0; i < $scope.states.length; i++) {
          $scope.validation.stateId = false;
          $('#state').parent().removeClass('ng-invalid');
          if ($scope.states[i].state_name === state) {
            $scope.operatorReg.state_code = $scope.states[i].state_code;
            return false;
          }
        }
      };
      //   var selectadded = false
      $scope.states = [];
      $timeout(function () { // for custom plugin initial load
        $('.state-selectDropdown').selectpicker();// set state
      }, 100);
      function getStates (value) { // get all states with reference to country id
        $scope.showLoader();
        operatorProfileService.getStates(value).then(function (response) {
          if (response.data.code === '200') {
            $scope.states = response.data.data;
            $timeout(function () { // for custom plugin
              $('.state-selectDropdown').selectpicker('refresh');// set state
              $scope.hideLoader();
            }, 100);
          }
        }, function () { $scope.hideLoader(); });
      }
    }
  ]
);
