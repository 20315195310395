import { Component, OnInit } from '@angular/core';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';
import { UserSocialProfileService } from '../../../../../app/scripts/services/userSocialProfileService';
import { helper } from '../../../../../app/scripts/helper';
import { LoaderService } from '../../services/loader/loader.service';
import { RootScopeService } from '../../services/root-scope/root-scope.service';

@Component({
  selector: 'app-operator-listing',
  templateUrl: '/ng-app/src/app/pages/operators-listing/operators-listing.component.html',
  styleUrls: ['../../../../../ng-app/src/app/pages/operators-listing/operators-listing.component.css']
})
export class OperatorsListingComponent implements OnInit {

  back: any;
  searchText: any = {};
  operatorList: any = [];
  noRecordFound: boolean;
  totalOperators: any;
  isLoadMoreButton: boolean;
  resCount: any;
  isSearchTextFocused: boolean = false;

  constructor(
    public rootScopeService: RootScopeService,
    public routerGlobals: UIRouterGlobals,
    public router: UIRouter,
    public loaderService: LoaderService,
    private userSocialProfileService: UserSocialProfileService,
  ) {
  }

  ngOnInit(): void {
    this.back = this.routerGlobals.params.page > 1 ? 1 : 0;
    this.operatorList = [];
    this.searchText.text = this.routerGlobals.params.search || '';
    this.getOperatorList(this.routerGlobals.params.search || '');
  }

  getOperatorList(query) {
    // get operator list for displaing operator
    this.loaderService.showLoader();
    this.searchText.text = this.routerGlobals.params.search || ''; // display text in search box
    if (query === undefined) {
      if (this.routerGlobals.params.page === 1) {
        this.operatorList = [];
      }
    } else {
      if (query !== this.routerGlobals.params.search && query) { // to handle search text functionality
        this.operatorList = [];
        this.routerGlobals.params.search = query;
        this.routerGlobals.params.page = 1;
        this.routerGlobals.params.back = 0;
      }
    }

    const requestObject = {
      'page': Number(this.routerGlobals.params.page),
      'search': (this.routerGlobals.params.search) ? this.routerGlobals.params.search : '',
      'back': this.back,
      'limit': 2000
    };

    this.userSocialProfileService.getOperatorListing(requestObject, this.rootScopeService.rootScope.user.token).then((response: any) => { // call opearot listing api
      if (response.data.code === '200') {
        this.noRecordFound = false;
        this.operatorList = this.operatorList.concat(response.data.data);
        this.operatorList.sort((a, b) => (a?.business_name ?? "").localeCompare(b?.business_name ?? ""));
        this.totalOperators = response.data.count;
        // more operator Shown on operator list Page
        this.isLoadMoreButton = ((response.data.count - this.operatorList.length) > 0);


        this.resCount = response.data.count;
        this.loaderService.hideLoader();
      } else if (response.data.code === '404') {
        this.isLoadMoreButton = false;
        this.operatorList = [];
        this.totalOperators = 0;
        this.noRecordFound = true;
        this.loaderService.hideLoader();
      }
    }, () => {
      this.loaderService.hideLoader();
      this.showPopup('#serverErrModal');
    });
  }

  searchOperator = (query) => {
    if (query.length >= 2) {
      this.operatorList = [];
      this.routerGlobals.params.back = 0;
      this.routerGlobals.params.search = query; // display text in search box
      this.router.stateService.go('operators-listing', this.routerGlobals.params, { notify: false, location: "replace" });
      this.getOperatorList(this.routerGlobals.params.search);
    }
  };

  resetListing = () => { // reset search input box
    if (this.routerGlobals.params.search) {
      this.back = 1;
      this.routerGlobals.params.search = ''; // display text in search box
      this.router.stateService.go('operators-listing', this.routerGlobals.params, { notify: false, location: "replace" });
      this.getOperatorList(this.routerGlobals.params.search);
    }
  };

  loadMore = () => {
    this.back = 0;
    this.routerGlobals.params.page = Number(this.routerGlobals.params.page) + 1;
    // eslint-disable-next-line no-self-assign
    this.routerGlobals.params.search = this.routerGlobals.params.search; // display text in search box
    this.router.stateService.go('operators-listing', this.routerGlobals.params, { notify: false, location: "replace" });
    this.getOperatorList(this.routerGlobals.params.search);
  };

  showPopup = (modalId) => { // show the popup
    $(modalId).modal('show');
  };

  onSearchChange(value) {
    this.searchText.text = value;
  }

}
