/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc directive
 * @name heliApp.directive:searchPanel
 * @description
 * # searchPanel
 */
angular.module('heliApp')
  .directive('searchPanel', function () {
    return {
      templateUrl: '././views/search-panel.html',
      restrict: 'E'
    };
  })
  .directive('searchPanelNew', function () {
    return {
      templateUrl: '././views/search-panel-new.html',
      restrict: 'E'
    };
  });
