import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { UIRouter } from '@uirouter/core';
import * as angular from 'angular';
import { helper } from '../../../../../app/scripts/helper';
import { environment } from '../../../../../app/scripts/environment';
import { LoaderService } from '../../services/loader/loader.service';
import { RootScopeService } from '../../services/root-scope/root-scope.service';

declare global {
  interface Window { prerenderReady: any; }
}

@Component({
  selector: 'app-home',
  templateUrl: '/ng-app/src/app/pages/home/home.component.html',
  styleUrls: ['../../../../../ng-app/src/app/pages/home/home.component.css']
})
export class HomeComponent implements OnInit {

  adventures = [];
  ambassadors = [];
  homePagelogos = {
    operatorProfile: [],
    mediaLogo: [],
    partnerLogo: []
  };
  operatorsLoaded: boolean = false
  advLoaded: boolean = false;
  currencySymbol = {
    'INR': 'INR',
    'USD': 'USD',
    'CAD': 'CAD',
    'EUR': 'EUR',
    'JPY': 'JPY',
    'GBP': 'GBP',
    'NZD': 'NZD',
    'AUD': 'AUD',
    'ISK': 'ISK',
    'MXN': 'MXN',
    'CHF': 'CHF',
    'BRL': 'BRL'
  };
  httpOptions: any;

  constructor(
    @Inject(UIRouter) public router: UIRouter,
    @Inject(HttpClient) private http: HttpClient,
    @Inject(RootScopeService) public rootScopeService: RootScopeService,
    @Inject(LoaderService) public loaderService: LoaderService,
    @Inject("$rootScope") public rootScope: any
  ) { 
    this.httpOptions = new HttpHeaders({
      'Cache-Control': 'max-age=2592000',
      'Pragma': 'no-cache',
      'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT'
    });
  }

  ngOnInit(): void {
    this.getFeaturedInfo();
    this.getLogos();
    this.rootScopeService.checkUserRoles();
    this.rootScopeService.callApiForOperatorProfile();
  }

  getFeaturedInfo() {
    this.http.get(environment.REQUEST_URL + 'masters/getFeaturedData', {headers: this.httpOptions}).subscribe((response: any) => {
      this.adventures = response.data.featuredAdventure;
      this.ambassadors = response.data.featuredAmbassadors;
      this.advLoaded = true;
      this.rootScopeService.checkExpiredSlots(response.data.featuredAdventure);
      !this.router.stateService.is('adventure-detail') && (window.prerenderReady = true);
    });
  }

  getLogos() {
    this.http
      .get(environment.REQUEST_URL + 'HomePageLogos', {headers: this.httpOptions})
      .subscribe((response: any) => {
        this.homePagelogos = response.result;
        this.rootScopeService.rootScope.homePagelogos = response.result;
        this.operatorsLoaded = true;
      });
  }

  bookingType(type, duration, singleDay) {
    if (type == 2) {
      if (singleDay == 1) {
        return 'Single Day';
      }
      return 'Nightly Booking'
    } else if (type == 1) {
      return duration + ' Day Adventure';
    }
  }

  locationShow(locationName) {
    if (locationName) {
      let arrayOflocationName = locationName.split(',');
      let [countryName] = arrayOflocationName.slice(-1);
      return countryName;
    }
  }

  // fucntion to redirect the adventure detail page...
  goToAdventureDetail(item) {
    item.package = item.package || item;
    item.operator_profile = item.operator_profile || item.operator_details;
    item.package_id = item.package_id || item.id;
    if (item.package.package_type == helper.PACKAGE_TYPE.PRIVATE) {
      this.router.stateService.go('adventure-detail-private', {
        operatorname: this.getSeoFriendlyURL(item.operator_profile.business_name),
        packagename: this.getSeoFriendlyURL(item.package.name),
        package_id: item.package_id,
        package_status: item.package.status == 1 ? 'active' : 'expired'
      });
    } else {
      this.router.stateService.go('adventure-detail', {
        operatorname: this.getSeoFriendlyURL(item.operator_profile.business_name),
        packagename: this.getSeoFriendlyURL(item.package.name),
        package_id: item.package_id,
        package_status: item.package.status == 1 ? 'active' : 'expired'
      });
    }
  };

  getSeoFriendlyURL(str) {
    return str.replace(/\W+(?!$)/g, '-').toLowerCase();
  }

  // set location length to 50 character
  setlocationlen(locationName, isradar) {
    if (isradar === 'filter') {
      var location = locationName.split(',');
      return location[location.length - 1];
    }
    var len = locationName.length;
    var canLen = isradar === 'radar' ? 30 : 50;
    if (len >= canLen) {
      return locationName.slice(0, canLen - 3) + '...';
    } else {
      return locationName;
    }
  };

 
}

angular.module('heliApp').directive('homeComponent', downgradeComponent({component: HomeComponent}));