/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc directive
 * @name heliApp.directive:header
 * @description
 * # header
 */
angular.module('heliApp')
  // .directive('headerPage',['$state', function ($state) { // header-page
  //   return {
  //     templateUrl: '././views/header-page.html',
  //     restrict: 'E'
  //   };
  // }])
  // .directive('footerPage',['$state', function ($state) { // footer-page
  //   return {
  //     templateUrl: '././views/footer-page.html',
  //     restrict: 'E'
  //   };
  // }])
  .directive('subdomainHeader',['$state', function ($state) { // header-page
    return {
      templateUrl: '././views/subdomain-header.html',
      restrict: 'E'
    };
  }]);
