/* eslint-disable camelcase */
/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:operatorInventoryCtrl
 * @description
 * # operatorInventoryCtrl
 * Controller of the heliApp
 */
angular.module('heliApp')
  .controller('operatorInventoryCtrl', ['$scope', 'operatorAgentService', 'CONST_HELPER', '$state', '$stateParams', 'userSocialProfileService', function ($scope, operatorAgentService, CONST_HELPER, $state, $stateParams, userSocialProfileService) {
    // --TYPE--USE----------------
    var dbPackageData = ''; // STRING -- All packages and slots
    $scope.packageData = []; // ARRAY OBJECTS -- All packages and slots
    $scope.updatedPackages = []; // ARRAY OBJECTS -- Updated packages and slots
    $scope.updateCommissionVal = 0; // INTEGER -- current editing slot commission
    $scope.editPackage = null; // OBJECT -- currently editing package
    $scope.editSlot = null; // OBJECT -- currently editing slot
    $scope.commSubmit = false; // BOOLEAN -- commission form submitted
    $scope.agentId = $stateParams.id;
    $scope.agentUserId = $stateParams.agent_id;
    $scope.isAgentInventory = $stateParams.id != '';
    $scope.isPackageCommission = false;

    // open edit slot commision popup method
    $scope.updateSlotCommission = function (editPackage, editSlot) {
      editPackage.checked = CONST_HELPER.ONE;
      editSlot.checked = CONST_HELPER.ONE;
      $scope.editPackage = editPackage;
      $scope.editSlot = editSlot;
      $scope.isPackageCommission = false;
      $scope.updateCommissionVal = parseInt(editSlot.commission);
      $scope.commSubmit = false;
      // open popup
      $scope.showPopup('#updateCommisionPopup');
    };
    $scope.updatePackageCommission = function (editPackage) {
      editPackage.checked = CONST_HELPER.ONE;
      $scope.editPackage = editPackage;
      $scope.isPackageCommission = true;
      $scope.updateCommissionVal = parseInt(editPackage.commission);
      $scope.commSubmit = false;
      // open popup
      $scope.showPopup('#updateCommisionPopup');
    };
    if ($scope.agentUserId) {
      var requestObject = {
        'user_id': $scope.agentUserId
      };
      userSocialProfileService.getAgentSocialProfile(requestObject, '').then(function (response) {
        if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
          var userProfile = response.data.data;
          console.log('userprofle', userProfile);
          if (userProfile.role_id == 5) {
            $scope.agentName = userProfile.first_name + userProfile.last_name;
          }
        }
      });
    }
    $scope.closeUpdateCommisionPopup = function () {
      $scope.hidePopup('#updateCommisionPopup');
    };
    // Update Method
    $scope.updateCommission = function (editPackage, editSlot) {
      $scope.commSubmit = true;
      if ($scope.updateCommissionVal > CONST_HELPER.ZERO && $scope.updateCommissionVal < CONST_HELPER.HUNDRED) {
        $scope.updateCommissionVal = parseInt($scope.updateCommissionVal);
        if (!$scope.isPackageCommission) {
          // Slot Commission
          $scope.editSlot.commission = $scope.updateCommissionVal;
        } else {
          // Package Commission
          $scope.editPackage.commission = $scope.updateCommissionVal;
          $scope.editPackage.package_slots.forEach(function (slot) {
            slot.commission = $scope.updateCommissionVal;
          });
        }
        $scope.addToUpdated($scope.editPackage, false);
        $scope.hidePopup('#updateCommisionPopup');
      }
    };

    // Add package/slot to updated array
    $scope.addToUpdated = function (_package, updateChild) {
      if (updateChild) {
        _package.package_slots.map(function (value) {
          value.checked = _package.checked;
          return value;
        });
      }
      var _index = CONST_HELPER.MINUS_ONE;
      // FIND IN UPDATED PACKAGES.
      var packageFound = $scope.updatedPackages.find(function (pck, i) {
        if (pck.package_id == _package.package_id) {
          _index = i;
        }
        return pck.package_id == _package.package_id;
      });
      // FIND IN DB PACKAGES.
      var _dbPackageData = JSON.parse(dbPackageData);
      var findPackageInDb = _dbPackageData.find(function (pck) {
        return pck.package_id == _package.package_id;
      });
      delete _package.$$hashKey;
      var isPackageUpdated;
      if (updateChild) {
        isPackageUpdated = findPackageInDb.checked != _package.checked;
      } else {
        isPackageUpdated = true;
      }

      var isPackageSlotUpdated = [];
      if (!isPackageUpdated) {
        // check internal slots only if package update is false.
        _package.package_slots.forEach(function (slot, index) {
          // FIND SLOT IN DB PACKAGE
          var dbslotfound = findPackageInDb.package_slots.find(function (dbslot) {
            return dbslot.id == slot.id;
          });
          // COMPARE SLOT COMMISSION AND CHECKED
          isPackageSlotUpdated.push(!(dbslotfound && dbslotfound.checked == slot.checked && dbslotfound.commission == slot.commission));
        });
      }
      // isPackageSlotUpdated contains true OR isPackageUpdated true then only send it to Api To UPDATE
      if (isPackageUpdated || isPackageSlotUpdated.includes(true)) {
        if (packageFound) {
          $scope.updatedPackages[_index] = _package;
        } else {
          $scope.updatedPackages.push(_package);
        }
      }
      // isPackageSlotUpdated contains false AND isPackageUpdated false BUT PACKAGE IS PRESENT IN UPDATED PACKAGE LIST then REMOVE PACKAGE FROM UPDATED LIST
      else if (packageFound && !isPackageUpdated && !isPackageSlotUpdated.includes(true)) {
        $scope.updatedPackages.splice(_index, CONST_HELPER.ONE);
      }
    };
    // Get Agent Inventory
    $scope.getAgentInventory = function () {
      $scope.showLoader();
      var promiseObj = null;
      if ($scope.isAgentInventory) {
        // For one Agent Inventory
        promiseObj = operatorAgentService.getAgentInventory($scope.user.token, $scope.agentId);
      } else {
        // For All Agent Inventory
        promiseObj = operatorAgentService.getAllAgentInventory($scope.user.token);
      }
      promiseObj.then(function (res) {
        $scope.hideLoader();
        if (res.data && res.data.code == CONST_HELPER.API_RESPONSE.OK) {
          res.data.data && res.data.data.map(function (val) {
            val.package_id = val.id;
            return val;
          });
          $scope.packageData = res.data.data;
          dbPackageData = JSON.stringify(res.data.data);
        }
      }, function () {
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });
    };

    // Save Agent Inventory
    $scope.updateAgentInventory = function () {
      $scope.showLoader();
      $scope.updatedPackages.map(function (obj) {
        delete obj.id;
        return obj;
      });
      var promiseObj = null;
      if ($scope.isAgentInventory) {
        // For one Agent Inventory
        var obj = {
          packages: $scope.updatedPackages,
          operator_agent_id: $scope.agentId
        };
        // $scope.updatedPackages.operator_agent_id = $scope.agentId;
        promiseObj = operatorAgentService.updateAgentInventory(obj, $scope.user.token);
      } else {
        // For All Agent Inventory
        promiseObj = operatorAgentService.updateAllAgentInventory($scope.updatedPackages, $scope.user.token);
      }
      promiseObj.then(function (res) {
        $scope.hideLoader();
        if (res.data && res.data.code == CONST_HELPER.API_RESPONSE.OK) {
          res.data.data && res.data.data.map(function (val) {
            val.package_id = val.id;
            return val;
          });
          $scope.packageData = res.data.data;
          dbPackageData = JSON.stringify(res.data.data);
          $scope.showPopup('#myUpdatedModal');
        }
      }, function () {
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });
    };
  }]);
