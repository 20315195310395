/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc directive
 * @name heliApp.directive:Digit only
 * @description
 * # user can write only Digit only
 */
angular.module('heliApp')
  .directive('onlyDigits', function () {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function (scope, element, attr, ctrl) {
        function inputValue(val) {
          if (val) {
            var digits = val.replace(/[^0-9]/g, '');

            if (digits !== val) {
              ctrl.$setViewValue(digits);
              ctrl.$render();
            }
            return parseInt(digits, 10);
          }
          return undefined;
        }
        ctrl.$parsers.push(inputValue);
      }
    };
  })
  .directive('preventEnterSubmit', function () {
    return function (scope, el, attrs) {
      el.bind('keydown', function (event) {
        if (13 == event.which) {
          event.preventDefault(); // Doesn't work at all
          window.stop(); // Works in all browsers but IE...
          document.execCommand('Stop'); // Works in IE
          return false; // Don't even know why it's here. Does nothing.
        }
      });
    };
  })
  .directive('onlyDigitsText', function () {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function (scope, element, attr, ctrl) {
        function inputValue(val) {
          if (val) {
            var digits = val.replace(/[^0-9]/g, '');

            if (digits !== val) {
              ctrl.$setViewValue(digits);
              ctrl.$render();
            }
            return digits;
          }
          return undefined;
        }
        ctrl.$parsers.push(inputValue);
      }
    };
  });
