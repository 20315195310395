  /**
 * @ngdoc overview
 * @name heliApp
 * @description
 * # heliApp
 *
 * MESSAGE_HELPER Declaration of the application.
 */

export const messages = {
    'password': {
      'CHANGE_PASSWORD_SUCCESSFULLY': 'Password Changed Successfully.',
      'PASSWORD_NOT_MATCH': 'Password and Confirm Password does not match.',
      'NEW_PASSWORD_BLANK': 'New password can not be blank.',
      'NEW_PASSWORD_CHARACTER_LIMIT': 'New password must be at least 8 characters long.',
      'OLD_PASSWORD_BLANK': 'Old password cannot be blank.'
    },
    'sharing': {
      'EMAIL_SEND_SUCCESSFULLY': 'Adventure has been shared successfully.',
      'EMAIL_REQUIRED': 'Please enter at least one email address.',
      'INCORRECT_EMAIL_FORMAT': 'Please enter a valid email id.',
      'DESCRIPTION_REQUIRED': 'Description is Required.',
      'SHORT_URL_REQUIRED': 'Short Url is Required.',
      'FACEBOOK_POST_SUCESSFULLY': 'Adventure has been shared successfully.',
      'FACEBOOK_ERROR_SUBMITTING': 'Error in Sharing Adventure.',
      'DESCRIPTION_MAXLENGTH': 'Please enter description  within 500 characters.',
      'FEED_POST_SHARE_SUCCESSFULLY': 'Post has been shared successfully.',
      'INVITE_SHARE_SUCCESSFULLY': 'Invitation has been shared successfully.'
    },
    'influencer_listing': {
      'MINIMUM_2_CHARACTERS_REQUIRED': 'Minimum 2 characters required.'
    },
    'follow_user': {
      'FOLLOW_ACCEPTED_MESSAGE': 'Accepted',
      'FOLLOW_DECLINED_MESSAGE': 'Declined',
      'FOLLOW_TOKEN_EXPIRE': 'Follow request expired. Please ask the user to resend the follow request.'
    },
    'feed': {
      'EDIT_FEED_SUCCESSFULLY': 'Post updated successfully.',
      'EDIT_FEED_TEXT_REQUIRED': 'Please enter the latest update.'
    },
    'coupon': {
      'date_expire_error': 'Coupon inactive. Please enter another promo code.',
      'my_limit_error': 'You have exhausted credit for this specific promocode. Please try another one.',
      'user_limit_error': 'Promocode currently not available. Please try another promocode.',
      'amount_minimum_error': 'The promo code you entered doesn\'t apply to this package.',
      'invalid_error': 'Invalid Promo Code.',
      'empty_error': 'Please enter a Promo Code.'
    }
  };