/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:operatorGetStartedCtrl
 * @description
 * # operatorGetStartedCtrl
 * Controller of the heliApp
 */
angular.module('heliApp')
  .controller('operatorGetStartedCtrl', ['$scope', '$state', '$rootScope', 'operatorProfileService', function ($scope, $state, $rootScope, operatorProfileService) {
    $scope.location_exists = $rootScope.user.location_exists;
    $scope.editProfileRedirection = function () { // on edit profile from get started
      if ($scope.user.is_profile_completed) { // redirect to edit screen if all steps are complete
        $state.go('operator-edit-profile');
      } else { // redirect to basic profile screen if all steps are not done yet
        $state.go('operator-basic-profile');
      }
    };

    $scope.getTotalAdventureCount = function () { // get total package count of operator
      $scope.location_exists = $rootScope.user.location_exists;
      if ($scope.user.token) { // check if user is logged in
        operatorProfileService.getAdventureCount($scope.user.token).then(function (response) {
          if (response.data.code === '200') { // success response
            $scope.updatePackageCount(response.data.numFound);// update user scope
            if (response.data.numFound > 0 && $scope.user.is_bank_details_completed && $scope.user.is_profile_completed) { // redirect to edit profile page if all params are completed
              $state.go('operator-edit-profile');
              return false;
            }
          }
        }, function () { $scope.hideLoader(); });
      } else { // else redirect to home page
        $state.go('home');
      }
    };
  }]);
