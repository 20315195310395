/* eslint-disable camelcase */
/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:operatorProfileCtrl
 * @description
 * # operatorProfileCtrl
 * Controller of the heliApp
 */
angular.module('heliApp')
  .controller('operatorProfileCtrl', ['$scope', '$state', 'operatorProfileService', '$timeout', '$location', '$window', '$rootScope', '$cookies', 'instagramServices', 'userProfileService', 'CONST_HELPER', 'NgMap', 'uppercaseFirstLetterFilter',
    function ($scope, $state, operatorProfileService, $timeout, $location, $window, $rootScope, $cookies, instagramServices, userProfileService, CONST_HELPER, NgMap, uppercaseFirstLetterFilter) {
      $scope.accountSettings = {
        payoutDetails: {
          countries: [],
          selectedCountryCode: null,
          isCountrySelected: false,
          currencies: [],
          selectedCurrencyCode: null,
          isCurrencySelected: false,
          businessTypes: [
            {
              id: 'individual',
              name: 'Individual'
            },
            {
              id: 'company',
              name: 'Company'
            }
          ],
          selectedBusinessType: null,
          isShowValidationErrors: false,
          errors: [],
          stripe: {
            account: null,
            setAccount: function (account) {
              if (account) {
                $scope.accountSettings.payoutDetails.stripe.account = account;
              }

              $scope.accountSettings.payoutDetails.selectedCountryCode = $scope.accountSettings.payoutDetails.stripe.account.country;
              $scope.accountSettings.payoutDetails.onChangeCountry().then(function () {
                $scope.accountSettings.payoutDetails.selectedCurrencyCode = $scope.accountSettings.payoutDetails.stripe.account.default_currency.toUpperCase();
              });
              $scope.accountSettings.payoutDetails.selectedBusinessType = $scope.accountSettings.payoutDetails.stripe.account.business_type;

              $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGrouped = {};
              $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGroupedTotalCount = 0;
              $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsFields = [];
              $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGrouped.general = [];
              var generalGroupFields = ['email', 'country', 'default_currency'];
              for (var i = 0; i < generalGroupFields.length; ++i) {
                var error = $scope.accountSettings.payoutDetails.stripe.checkAccountForError(generalGroupFields[i]);
                if (error) {
                  $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsFields.push(error.requirement);

                  var alreadyExists = $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGrouped.general.find(function(existingError) {
                    return error.code === existingError.code;
                  });
                  if (!alreadyExists) {
                    $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGrouped.general.push(error);
                  }
                }
              }
              $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGroupedTotalCount += $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGrouped.general.length;

              $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGrouped.business_profile = [];
              var businessProfileGroupFields = [
                'mcc',
                'name',
                'product_description',
                'support_address',
                'support_email',
                'support_phone',
                'support_url',
                'url'
              ];
              for (var i = 0; i < businessProfileGroupFields.length; ++i) {
                var error = $scope.accountSettings.payoutDetails.stripe.checkAccountForError(businessProfileGroupFields[i]);
                if (error) {
                  $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsFields.push(error.requirement);

                  var alreadyExists = $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGrouped.business_profile.find(function(existingError) {
                    return error.code === existingError.code;
                  });
                  if (!alreadyExists) {
                    $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGrouped.business_profile.push(error);
                  }
                }
              }
              $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGroupedTotalCount += $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGrouped.business_profile.length;

              $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGrouped[$scope.accountSettings.payoutDetails.stripe.account.business_type + '.address'] = [];
              var addressGroupFields = [
                'city',
                'country',
                'line1',
                'line2',
                'postal_code',
                'state'
              ];
              for (var i = 0; i < addressGroupFields.length; ++i) {
                var error = $scope.accountSettings.payoutDetails.stripe.checkAccountForError($scope.accountSettings.payoutDetails.stripe.account.business_type + '.address.' + addressGroupFields[i]);
                if (error) {
                  $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsFields.push(error.requirement);

                  var alreadyExists = $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGrouped[$scope.accountSettings.payoutDetails.stripe.account.business_type + '.address'].find(function(existingError) {
                    return error.code === existingError.code;
                  });
                  if (!alreadyExists) {
                    $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGrouped[$scope.accountSettings.payoutDetails.stripe.account.business_type + '.address'].push(error);
                  }
                }
              }
              $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGroupedTotalCount += $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGrouped[$scope.accountSettings.payoutDetails.stripe.account.business_type + '.address'].length;

              $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGrouped[$scope.accountSettings.payoutDetails.stripe.account.business_type] = [];
              var businessTypeGroupFields = [
                'company.name',
                'company.phone',
                'individual.first_name',
                'individual.last_name',
                'individual.maiden_name',
                'individual.gender',
                'individual.email',
                'individual.phone',
              ];
              for (var i = 0; i < businessTypeGroupFields.length; ++i) {
                var error = $scope.accountSettings.payoutDetails.stripe.checkAccountForError(businessTypeGroupFields[i]);
                if (error) {
                  $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsFields.push(error.requirement);

                  var alreadyExists = $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGrouped[$scope.accountSettings.payoutDetails.stripe.account.business_type].find(function(existingError) {
                    return error.code === existingError.code;
                  });
                  if (!alreadyExists) {
                    $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGrouped[$scope.accountSettings.payoutDetails.stripe.account.business_type].push(error);
                  }
                }
              }
              $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGroupedTotalCount += $scope.accountSettings.payoutDetails.stripe.account.requirements.errorsGrouped[$scope.accountSettings.payoutDetails.stripe.account.business_type].length;

              // Checking for not errors messages
              $scope.accountSettings.payoutDetails.stripe.account.requirements.isShowGlobalError = false;
              if (
                ($scope.accountSettings.payoutDetails.stripe.account.requirements.currently_due.length > 0)
                && ($scope.accountSettings.payoutDetails.stripe.account.requirements.eventually_due.length > 0)
                && ($scope.accountSettings.payoutDetails.stripe.account.requirements.past_due.length > 0)
              ) {
                $scope.accountSettings.payoutDetails.stripe.account.requirements.isShowGlobalError = true;
              }
            },
            checkAccountForError: function(fieldName) {
              return $scope.accountSettings.payoutDetails.stripe.account.requirements.errors.find(function(error) {
                return error.requirement === fieldName;
              });
            }
          },

          loadCountries: function () {
            $scope.showLoader();

            operatorProfileService
              .getPaymentCountry()
              .then(function (response) {
                if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
                  $scope.accountSettings.payoutDetails.countries = response.data.data;
                }
              })
              .catch(function () {
                $scope.showPopup('#serverErrModal');
              })
              .finally(function () {
                $scope.hideLoader();
              });
          },
          onChangeCountry: function (countryid) {
            $scope.showLoader();

            // Load currencies according selected country
            return operatorProfileService
              .getCurrencyCode({
                token: $scope.user.token,
                country_code: $scope.accountSettings.payoutDetails.selectedCountryCode,
                isMultiple: true
              })
              .then(function (response) {
                if (
                  response.data
                  &&
                  (response.data.code === CONST_HELPER.API_RESPONSE.OK)
                ) {
                  $scope.accountSettings.payoutDetails.currencies = response.data.currency_details;
                } else if ($scope.currencyList.length > 0) {
                  $scope.accountSettings.payoutDetails.currencies = $scope.currencyList.filter(function (currency) {
                    return currency.code === response.data.currency_details[0].currencyCode;
                  });
                }

                // Currency already was selected
                if ($scope.accountSettings.payoutDetails.selectedCurrencyCode) {
                  // If any currencies available for selected country
                  if ($scope.accountSettings.payoutDetails.currencies.length > 0) {
                    // If previously selected currency is not presented in country's list
                    // we select first item in list
                    if (!$scope.accountSettings.payoutDetails.currencies.find(function(currency) {
                      return currency.currencyCode === $scope.accountSettings.payoutDetails.selectedCurrencyCode;
                    })) {
                      $scope.accountSettings.payoutDetails.selectedCurrencyCode = $scope.accountSettings.payoutDetails.currencies[0].currencyCode;
                    }
                  } else {
                    // Reset previously selected currency
                    $scope.accountSettings.payoutDetails.selectedCurrencyCode = null;
                  }
                }

                return response;
              })
              .catch(function () {
                $scope.showPopup('#serverErrModal');
              })
              .finally(function () {
                $scope.hideLoader();
              });
          },
          validate: function () {
            $scope.accountSettings.payoutDetails.isShowValidationErrors = true;

            return (
                !$scope.operatorProfileForm.accountSettings_payoutDetails_selectedCountryCode
                ||
                $scope.operatorProfileForm.accountSettings_payoutDetails_selectedCountryCode.$valid
              ) && (
                !$scope.operatorProfileForm.accountSettings_payoutDetails_selectedCurrencyCode
                ||
                $scope.operatorProfileForm.accountSettings_payoutDetails_selectedCurrencyCode.$valid
              ) && (
                !$scope.operatorProfileForm.accountSettings_payoutDetails_selectedBusinessType
                ||
                $scope.operatorProfileForm.accountSettings_payoutDetails_selectedBusinessType.$valid
              );
          },
          isAllFieldsSelected: function () {
            return $scope.accountSettings.payoutDetails.selectedCountryCode
              && $scope.accountSettings.payoutDetails.selectedCurrencyCode
              && $scope.accountSettings.payoutDetails.selectedBusinessType;
          },
          getChangedFieldValues: function () {
            var changedvalues = {};

            if (
              $scope.operatorProfileForm.accountSettings_payoutDetails_selectedCountryCode
              &&
              $scope.operatorProfileForm.accountSettings_payoutDetails_selectedCountryCode.$dirty
            ) {
              changedvalues.countryCode = $scope.accountSettings.payoutDetails.selectedCountryCode;
            }

            if (
              $scope.operatorProfileForm.accountSettings_payoutDetails_selectedCurrencyCode
              &&
              $scope.operatorProfileForm.accountSettings_payoutDetails_selectedCurrencyCode.$dirty
            ) {
              changedvalues.currencyCode = $scope.accountSettings.payoutDetails.selectedCurrencyCode;
            }

            if (
              $scope.operatorProfileForm.accountSettings_payoutDetails_selectedBusinessType
              &&
              $scope.operatorProfileForm.accountSettings_payoutDetails_selectedBusinessType.$dirty
            ) {
              changedvalues.businessType = $scope.accountSettings.payoutDetails.selectedBusinessType;
            }

            return changedvalues;
          },
          redirectToOnboardingAccountForm: function () {
            if ($scope.accountSettings.payoutDetails.validate()) {
              $scope.showLoader();
              var accountLinksData = $scope.accountSettings.payoutDetails.getChangedFieldValues();
              angular.extend(accountLinksData, { token: $scope.user.token });

              operatorProfileService
                .getStripeAccountLinks(accountLinksData)
                .then(function (response) {
                  if (response.data && response.data.data) {
                    if (response.data.data.link && response.data.data.link.url) {
                      $window.location.href = response.data.data.link.url;
                    } else {
                      $scope.showPopup('#serverErrModal');
                    }
                  } else if (response.data.message) {
                    $scope.accountSettings.payoutDetails.errors.push(response.data.message);
                  } else {
                    $scope.showPopup('#serverErrModal');
                  }
                })
                .catch(function () {
                  $scope.showPopup('#serverErrModal');
                })
                .finally(function () {
                  $scope.hideLoader();
                });
            }
          },
          flywire: {
            currencies: [],
            account: {
              portalCode: '',
              portalCurrency: '',
              sharedSecret: '',
              setAccount: function (account) {
                $scope.accountSettings.payoutDetails.flywire.account.portalCode = account.portalCode;
                $scope.accountSettings.payoutDetails.flywire.account.portalCurrency = account.portalCurrency;
                $scope.accountSettings.payoutDetails.flywire.account.sharedSecret = account.sharedSecret;
              },
              updateAccount: function () {
                if ($scope.accountSettings.payoutDetails.flywire.form.isValid()) {
                  $scope.showLoader();

                  operatorProfileService
                    .updateFlywireAccount({
                      token: $scope.user.token,
                      portalCode: $scope.accountSettings.payoutDetails.flywire.account.portalCode,
                      portalCurrency: $scope.accountSettings.payoutDetails.flywire.account.portalCurrency,
                      sharedSecret: $scope.accountSettings.payoutDetails.flywire.account.sharedSecret
                    })
                    .then(function (response) {
                      if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
                        $scope.accountSettings.payoutDetails.flywire.account.setAccount(response.data.account);
                        $scope.accountSettings.payoutDetails.flywire.form.setPristine();
                      } else {
                        $scope.accountSettings.payoutDetails.flywire.form.error = response.data.message;
                      }
                    })
                    .catch(function () {
                      $scope.showPopup('#serverErrModal');
                    })
                    .finally(function () {
                      $scope.hideLoader();
                    });
                }
              }
            },
            form: {
              error: null,
              isValid: function () {
                return (
                  $scope.operatorProfileForm.accountSettings_payoutDetails_flywire_accountPortalCode.$valid
                  &&
                  $scope.operatorProfileForm.accountSettings_payoutDetails_flywire_accountPortalCurrency.$valid
                  &&
                  $scope.operatorProfileForm.accountSettings_payoutDetails_flywire_accountSharedSecret.$valid
                );
              },
              isDirty: function () {
                return (
                  $scope.operatorProfileForm.accountSettings_payoutDetails_flywire_accountPortalCode.$dirty
                  ||
                  $scope.operatorProfileForm.accountSettings_payoutDetails_flywire_accountPortalCurrency.$dirty
                  ||
                  $scope.operatorProfileForm.accountSettings_payoutDetails_flywire_accountSharedSecret.$dirty
                );
              },
              isCanBeUpdated: function () {
                return (
                  $scope.accountSettings.payoutDetails.flywire.form.isValid()
                  &&
                  $scope.accountSettings.payoutDetails.flywire.form.isDirty()
                );
              },
              setPristine: function () {
                $scope.operatorProfileForm.accountSettings_payoutDetails_flywire_accountPortalCode.$setPristine();
                $scope.operatorProfileForm.accountSettings_payoutDetails_flywire_accountPortalCurrency.$setPristine();
                $scope.operatorProfileForm.accountSettings_payoutDetails_flywire_accountSharedSecret.$setPristine();
              }
            }
          }
        },
        policies: {
          taxPolicy: {
            calculationTypes: [
              {
                id: CONST_HELPER.OPERATOR_TAXES.CALCULATION_TYPE.PERCENTAGE.ID,
                name: uppercaseFirstLetterFilter(CONST_HELPER.OPERATOR_TAXES.CALCULATION_TYPE.PERCENTAGE.NAME)
              },
              {
                id: CONST_HELPER.OPERATOR_TAXES.CALCULATION_TYPE.AMOUNT.ID,
                name: uppercaseFirstLetterFilter(CONST_HELPER.OPERATOR_TAXES.CALCULATION_TYPE.AMOUNT.NAME)
              }
            ],
            taxes: {},
            currentTaxForDelete: null,

            create: function (properties) {
              return angular.extend({
                name: null,
                calculation_type: CONST_HELPER.OPERATOR_TAXES.CALCULATION_TYPE.PERCENTAGE.ID,
                value: null,
                is_per_person: false,
                is_per_night_day: false
              }, properties);
            },
            add: function (type) {
              $scope.accountSettings.policies.taxPolicy.taxes[type].push(
                $scope.accountSettings.policies.taxPolicy.create()
              );
            },
            remove: function (type, index) {
              var taxForDelete = $scope.accountSettings.policies.taxPolicy.taxes[type][index];
              if (!taxForDelete.id) {
                $scope.accountSettings.policies.taxPolicy.currentTaxForDelete = null;
                $scope.accountSettings.policies.taxPolicy.taxes[type].splice(index, 1);
              } else {
                $scope.accountSettings.policies.taxPolicy.currentTaxForDelete = {
                  id: taxForDelete.id,
                  type: type
                };
                $scope.showPopup('#confirmDeleteTax');
              }
            },
            setTaxes: function (taxes) {
              $scope.accountSettings.policies.taxPolicy.taxes = angular.extend({
                country_id: null,
                international: [],
                domestic: []
              }, taxes);

              var initializeTaxes = function(type) {
                if ($scope.accountSettings.policies.taxPolicy.taxes[type].length === 0) {
                  $scope.accountSettings.policies.taxPolicy.add(type);
                }
              };

              initializeTaxes(CONST_HELPER.OPERATOR_TAXES.TYPE.INTERNATIONAL.NAME);
              initializeTaxes(CONST_HELPER.OPERATOR_TAXES.TYPE.DOMESTIC.NAME);
            },
            confirmDeleteTax: function (id) {
              $scope.showLoader();
              operatorProfileService.deleteTax(id, $scope.user.token).then(function (response) {
                $scope.hideLoader();
                if (response.data.code == CONST_HELPER.API_RESPONSE.NO_CONTENT || response.data.code == CONST_HELPER.API_RESPONSE.OK) {
                  // delete success
                  $scope.showPopup('#saveDetailModal');
                  $scope.$parent.successMsg = 'Tax deleted successfully.';

                  if ($scope.accountSettings.policies.taxPolicy.currentTaxForDelete) {
                    var taxesArray = $scope.accountSettings.policies.taxPolicy.taxes[$scope.accountSettings.policies.taxPolicy.currentTaxForDelete.type];
                    taxesArray = taxesArray.filter(function (tax) {
                      return tax.id != $scope.accountSettings.policies.taxPolicy.currentTaxForDelete.id;
                    });
                    $scope.accountSettings.policies.taxPolicy.taxes[$scope.accountSettings.policies.taxPolicy.currentTaxForDelete.type] = taxesArray;
                  }
                } else if (response.data.code == CONST_HELPER.API_RESPONSE.UNPROCESSABLE) {
                  // delete success
                  $scope.showPopup('#errModal');
                  $scope.$parent.errmsg = response.data.message;
                } else {
                  //  error while delete
                  $scope.showPopup('#serverErrModal');
                }
              });
            },
            changeTaxCheckboxes: function (tax) {
              tax.is_per_person = false;
              tax.is_per_day_night = false;
            },
            onCalculationTypeChange: function (tax, type, index) {
              $scope.accountSettings.policies.taxPolicy.changeTaxCheckboxes(tax);

              if ($scope.validation.operatorPolicy[type] === undefined) {
                $scope.validation.operatorPolicy[type] = [];
              }
              if ($scope.validation.operatorPolicy[type][index] === undefined) {
                $scope.validation.operatorPolicy[type][index] = {};
              }
              $scope.validation.operatorPolicy[type][index].valueTax = false;
              $scope.validation.operatorPolicy[type][index].typeTax = false;
            }
          }
        }
      };
      $rootScope.initializePhoneField("#operatorPhoneNumber");
      $scope.operatorProfile = {};
      $scope.name_new = '';
      $scope.locationLoaded = false;
      $scope.currentLocation = {
        latitude: CONST_HELPER.default_location.latitude,
        longitude: CONST_HELPER.default_location.longitude,
        address: CONST_HELPER.default_location.address,
        op_location_videos: [],
        lodgings: [],
        temp_id: $scope.getTempId(),
        name: ''
      };
      $scope.currentLocation.guest_can_select_room = '1';
      $scope.isUniqeLocationName = true;
      $scope.redirectToPaymentDetail = false;
      $scope.isUniqeLocationMessage = 'Location name already exists!';
      $scope.operatorImage = {};
      $scope.operatorPolicy = {};
      $scope.operatorPaymentDetail = {};
      $scope.timeZones = [];
      $scope.showEmailErr = false;
      $scope.operatorProfileLoading = true;
      $scope.marker = {
        'lat': CONST_HELPER.default_location.latitude,
        'lng': CONST_HELPER.default_location.longitude
      };
      $scope.countryStateLabel = '';
      // editor configuration....
      $scope.config = CONST_HELPER.EDITOR_CONFIG;
      $scope.allLocations = [];
      $scope.isAddLocation = true;
      $scope.validation = {};
      $scope.validation.operatorProfile = {}; // validations for four tabs
      $scope.validation.operatorLocation = {}; // validations for Location tab
      $scope.validation.operatorImage = {};
      $scope.validation.operatorPolicy = {};
      $scope.validation.operatorPaymentDetail = {};
      $scope.numberPattern = /^\d*$/;
      $scope.numberPatternwithdot = /^[0-9.-]/;
      $scope.alphaNumericPatternWithSpaces = /^[A-Za-z\d\s]+$/;
      $scope.alphaNumericPattern = /^[a-zA-Z0-9]+$/;
      $scope.patternWithSpecialCharAndSpace = /^\d*([a-zA-ZÀ-ž0-9_!$@%*.,'/#&+-\d\s])+([A-zÀ-ž])+([a-zA-ZÀ-ž0-9_!$@%*.,'/#&+-\d\s])*$/;
      // eslint-disable-next-line no-useless-escape
      $scope.urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
      $scope.emailPattern = /^([a-zA-Z0-9_.-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
      $scope.LocationErr = true;

      $scope.currentHash = 'basic-profile'; // set hash
      $scope.completeBasicProfile = false; // check if basic file is complete
      $scope.completeOperatorImage = false; // check if operator gallery is complete
      $scope.completeCancellationPolicy = false; // check if cancellation policy is  complete
      $scope.saveErr = false;
      $scope.old_gallery_images = [];
      $scope.myImage = [];
      $scope.mobileHash = 'BASIC PROFILE';
      $scope.bounds = {};
      $scope.gallery = {};
      $scope.savedImage = {};
      $scope.uploadImages = [];
      $scope.gallery.sourceImage = null;
      $scope.gallery.croppedImage = null;
      $scope.gallery.logoImage = null;
      $scope.bounds.left = CONST_HELPER.ZERO;
      $scope.bounds.right = CONST_HELPER.ZERO;
      $scope.bounds.top = CONST_HELPER.ZERO;
      $scope.bounds.bottom = CONST_HELPER.ZERO;
      $scope.minMultipleImgSize = false;
      $scope.maxMultipleImgSize = false;
      $scope.maxOriginalLogoImgSize = false;
      $scope.maxWhiteLogoImgSize = false;
      $scope.uploadType = 'original';
      $scope.airports = [];
      $scope.showCancelZeroDateErr = false;
      $scope.btnText = 'SAVE & EXIT';
      $scope.bankAccountErr = false;
      var today = new Date();
      $scope.maxDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      $scope.percentagePattern = /^([0-9]{1,2}){1}(\.[0-9]{1,3})?$|^100$/;

      $scope.accountHolderType = ['individual', 'company']; // Account holder types input for drop-down
      $scope.genderType = ['male', 'female']; // Gender type drop down used for JAPAN only
      $scope.currencyList = []; // List of all stripe supported currencies
      $scope.localCurrencyList = []; // List of all stripe supported currencies by country
      $scope.paymentStates = []; // List of all states filtered by country
      $scope.paymentCountries = []; // List of all countries payment supported
      $scope.accountDetailsKey = ['account_holder_name', 'account_holder_type', 'account_number', 'routing_number', 'currency', 'bank_country_code'];
      // location medias
      $scope.location_medias = {
        medias: [],
        uploaded: [],
        id: $scope.getTempId(),
        lodgingFormInvalid: false
      };
      $scope.video = '';
      $scope.videoUrlErr = false;
      $scope.deletedLodgings = [];
      $scope.redirectToAddLocation = $rootScope.redirectToAddLocation ? $rootScope.redirectToAddLocation : false;
      $scope.linkPattern = /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/;
      $scope.addMoreUrl = function () { // add more url for embbeded video
        if (!$scope.currentLocation.op_location_videos) {
          $scope.currentLocation.op_location_videos = [];
        }
        if ($scope.currentLocation.op_location_videos.length < CONST_HELPER.TWO) {
          $scope.currentLocation.op_location_videos.push({
            'url': $scope.video
          }); // push into array
          $scope.video = ''; // make video input blank
          $scope.videoUrlErr = false; // hide video error message
        } else {
          $scope.videoUrlErr = true; // show video error message
        }
      };

      $scope.deleteVideoUrl = function (index) { // delete added video url for array
        $scope.currentLocation.op_location_videos.splice(index, CONST_HELPER.ONE);
        $scope.videoUrlErr = false; // hide video error message
      };

      $scope.lodging = { // initialize lodging object.
        op_lodging_medias: []
      };
      $scope.newRoom = {
        op_lodging_medias: [],
        lodgingUploadImage: [],
        id: $scope.getTempId(),
        lodgingFormInvalid: false
      };
      $scope.lodgingObjCopy = [];

      $scope.editLodging = function (lodgingObj) {
        $scope.lodgingObjCopy.push(angular.copy(lodgingObj));
        lodgingObj.editing = true;
      };
      $scope.showAddLodgingFormFlag = false;
      $scope.showAddLodgingForm = function () {
        $scope.showAddLodgingFormFlag = true;
      };

      $scope.canChangeLodgingTypeSelect = function (type) {
        if ($scope.currentLocation.op_lodgings && $scope.currentLocation.op_lodgings.length && $scope.currentLocation.guest_can_select_room == '0') {
          $timeout(function () {
            $scope.currentLocation.guest_can_select_room = '1';
          });
          $scope.guestCanSelectLodgingError = true;
        } else {
          $scope.guestCanSelectLodgingError = false;
        }
      }

      $scope.canDeleteLodging = function (lodging) {
        if (lodging.op_location_id) {
          var requestObj = {
            op_location_id: lodging.op_location_id,
            lodging_id: lodging.id,
            token: $scope.user.token
          };
          $scope.showLoader();
          operatorProfileService.checkDeleteLodging(requestObj).then(function (response) {
            if (response.data.code === CONST_HELPER.API_RESPONSE.CREATED || response.data.code === CONST_HELPER.API_RESPONSE.OK) {
              $scope.hideLoader();
              $scope.currentLodging = lodging;
              $scope.showPopup('#confirmDeleteLodging');
            } else if (response.data.code === CONST_HELPER.API_RESPONSE.BAD_REQUEST) {
              $scope.hideLoader();
              $rootScope.errorAlert = response.data.message;
              $scope.showPopup('#AlertModal');
            }
            $scope.hideLoader();
          }, function () {
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
        } else {
          $scope.currentLodging = lodging;
          $scope.showPopup('#confirmDeleteLodging');
        }
      };
      $scope.deleteLodging = function (lodging) {
        $scope.deletedLodgings.push($scope.currentLodging.id);
        $scope.currentLocation.op_lodgings.splice($scope.currentLocation.op_lodgings.indexOf($scope.currentLodging), CONST_HELPER.ONE);
      };
      $scope.addLodgingOption = function (lodging, requestType) {
        if (requestType === 'cancel') {
          var canceledLodging = $scope.lodgingObjCopy.filter(function (l) {
            return l.id === lodging.id;
          });
          canceledLodging = canceledLodging[CONST_HELPER.ZERO];
          // lodging = canceledLodging;
          resetLodgingKeys(lodging, canceledLodging);
          lodging.editing = false;
          $scope.lodgingObjCopy.splice($scope.lodgingObjCopy.indexOf(canceledLodging), CONST_HELPER.ONE);
          lodging.lodgingUploadImage = [];
          return;
        }
        $scope.validation.operatorLocation.lodgings = false;
        if (!lodging.name || !lodging.description || !(lodging.op_lodging_medias.length || lodging.lodgingUploadImage.length)) {
          lodging.lodgingFormInvalid = true;
          return;
        }
        if (!$scope.currentLocation.op_lodgings) {
          $scope.currentLocation.op_lodgings = [];
        }
        var requestObj = {
          name: lodging.name,
          description: lodging.description,
          old_lodging_medias: getOldLocationMedias(lodging.op_lodging_medias),
          temp_id: $scope.currentLocation.temp_id,
          token: $scope.user.token,
          op_location_id: $scope.currentLocation.id ? $scope.currentLocation.id : ''
        };
        if (requestType === 'edit') {
          requestObj.id = lodging.id;
        }

        $scope.showLoader();

        operatorProfileService.saveLocationLodging(requestObj, lodging.lodgingUploadImage).then(function (response) {
          if (response.data.code === CONST_HELPER.API_RESPONSE.CREATED || response.data.code === CONST_HELPER.API_RESPONSE.OK) {
            $scope.saveErr = false;
            $scope.hideLoader();
            $scope.currentLocation.op_lodgings.push(lodging);
            $scope.newRoom = {
              op_lodging_medias: [],
              lodgingUploadImage: [],
              id: $scope.getTempId(),
              lodgingFormInvalid: false
            };
            lodging.lodgingFormInvalid = false;
            lodging.lodgingUploadImage = [];
            var tempArr = response.data.data;
            $scope.currentLocation.op_lodgings = [];
            tempArr.forEach(function (lodging) {
              if (!$scope.deletedLodgings.includes(lodging.id)) {
                $scope.currentLocation.op_lodgings.push(lodging);
              }
            });
          } else if (response.data.code === CONST_HELPER.API_RESPONSE.BAD_REQUEST) {
            for (var key in response.message) {
              $scope.validations['operatorLocation'][key] = true;
              $scope.operatorProfileForm[key].$error['required'] = true;
            }
            $scope.saveErr = true;
          }
          $scope.hideLoader();
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };

      // reset lodging to default values in case of lodging edit cancel...
      function resetLodgingKeys(lodging, preLodging) {
        for (var key in lodging) {
          lodging[key] = preLodging[key];
        }
      }

      $scope.checkValidLocationName = function () {
        if ($scope.name_new && $scope.name_new.length > CONST_HELPER.THREE) {
          setTimeout(function () {
            $scope.newRoom = {
              op_lodging_medias: [],
              lodgingUploadImage: [],
              id: $scope.getTempId(),
              lodgingFormInvalid: false
            };
            operatorProfileService.checkValidLocation({
              name: $scope.name_new,
              id: $scope.currentLocation.id
            }, $scope.user.token).then(function (response) {
              if (response && response.data.code == CONST_HELPER.API_RESPONSE.UNPROCESSABLE) {
                $scope.isUniqeLocationName = false;
                $scope.isUniqeLocationMessage = response.data.message;
                $scope.validation.operatorLocation.name = true;
              } else {
                $scope.isUniqeLocationName = true;
              }
            });
          }, CONST_HELPER.SEARCH_TIMEOUT);
        }
      };

      $scope.getOperatorProfile = function (redirectAfterAdd) {
        // fetch prefilled profile of the operator
        $scope.showLoader();
        $scope.homeRedirection(); // check for authorised access
        if ($location.url().indexOf('operator-add-location') != CONST_HELPER.ONE) {
          $scope.getOperatorLocations();
        }
        getCurrencyList(); // get list of currency supported

        // Loading all payment countries supported for Stripe Connect account
        $scope.accountSettings.payoutDetails.loadCountries();

        getTimeZones(); // Call function to get world time zones
        operatorProfileService.getOperatorProfile($scope.user.token).then(function (response) {
          if (response.data) {
            var code = response.data.code;
            if (code !== CONST_HELPER.API_RESPONSE.OK) { // if error return
              if (code === CONST_HELPER.API_RESPONSE.UNAUTHERIZED) { // unauthorised user
                $scope.logout();
              }
            } else { // valid results
              $scope.operatorProfileLoading = false;
              updateScopeUser(response.data.data); // update the user scope...
              if (redirectAfterAdd) {
                $state.go('operator-get-started');
              }
              $scope.operatorProfile = response.data.data.operator_profile; // set profile data for operator profile
              $scope.operatorProfile.state = $scope.operatorProfile.state_id; // set profile data for operator profile
              $scope.operatorProfile.username = response.data.data.username; // set username for operator profile

              $scope.getAllCountryName().then(function (countries) {
                var selectedCountry = countries.data.data.find(function (country) {
                  return country.id == $scope.operatorProfile.country_id;
                });

                $scope.countryStateLabel = selectedCountry ? selectedCountry.country_label : 'NA';
              });

              getStates($scope.operatorProfile.country_id); // get state list for country
              $scope.operatorProfile.email = response.data.data.email;
              // defined inrootscope as used in main.js
              $rootScope.operatorSocialAccounts = (response.data.data.social_accounts) ? response.data.data.social_accounts : {};
              if (response.data.data.social_accounts) {
                $rootScope.operatorSocialAccounts.facebook_auto_sharing = (response.data.data.social_accounts.facebook_auto_sharing === CONST_HELPER.ONE);
                $rootScope.operatorSocialAccounts.twitter_auto_sharing = (response.data.data.social_accounts.twitter_auto_sharing === CONST_HELPER.ONE);
                $rootScope.operatorSocialAccounts.linkedin_auto_sharing = (response.data.data.social_accounts.linkedin_auto_sharing === CONST_HELPER.ONE);
              }
              $scope.operatorImage = response.data.data.operator_image; // set profile data for operator image
              if ($scope.operatorImage.gallery_image && $scope.operatorImage.gallery_image.length > CONST_HELPER.ZERO) { // gallery image exist
                angular.copy(response.data.data.operator_image.gallery_image, $scope.old_gallery_images);
                $scope.myImage = response.data.data.operator_image.gallery_image;
              }
              if ($scope.operatorImage.cover_image) { // check if cover image already present
                $scope.gallery.cover_image = $scope.operatorImage.cover_image;
              }
              $scope.operatorPolicy = response.data.data.operator_policy; // set profile data for policy

              if (response.data.data.operator_policy.operator_taxes === undefined) {
                response.data.data.operator_policy.operator_taxes = {};
              }
              response.data.data.operator_policy.operator_taxes.country_id = $scope.operatorPolicy.country_code;
              $scope.accountSettings.policies.taxPolicy.setTaxes(response.data.data.operator_policy.operator_taxes);
              $scope.operatorPolicy.deposit_type = $scope.operatorPolicy.deposit_type ? String($scope.operatorPolicy.deposit_type) : CONST_HELPER.DEPOSIT_TYPE.PERCENTAGE;
              $scope.refreshSelectpicker();
              if ($scope.currentState.name === 'operator-edit-profile') {
                // Setting the operator payment details
                getOperatorPaymentDetails();
              } else {
                $scope.hideLoader();
              }
              $("#operatorPhoneNumber").intlTelInput("setNumber", $scope.operatorProfile.phone);
            }
          }
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };
      // Refresh selectpicker values on dynamic change
      $scope.refreshSelectpicker = function () {
        $timeout(function () {
          $('.selectpicker').selectpicker('refresh');
        }, CONST_HELPER.ZERO);
      };

      function getTimeZones() { // fetch world time zones
        operatorProfileService.getTimeZones().then(function (response) {
          if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
            $scope.timeZones = response.data.data;
          }
        }, function () {
          $scope.showPopup('#serverErrModal');
        });
      }
      $scope.checkForErrorOnLocation = function () {
        if (!$scope.name_new || $scope.name_new === '' || $scope.operatorProfileForm.name.$invalid || !$scope.isUniqeLocationName) { // check for contact name
          $scope.validation.operatorLocation.name = true;
        } else {
          $scope.validation.operatorLocation.name = false;
        }

        if (($scope.currentLocation.address === '') || $scope.operatorProfileForm.location_address.$invalid) { // check for contact name
          $scope.validation.operatorLocation.location_address = true;
        } else {
          $scope.validation.operatorLocation.location_address = false;
        }
        if (($scope.currentLocation.description === '') || $scope.operatorProfileForm.location_description.$invalid) { // check for contact name
          $scope.validation.operatorLocation.location_description = true;
        } else {
          $scope.validation.operatorLocation.location_description = false;
        }

        if ($scope.operatorProfileForm.arrival_instructions.$error.required || $scope.operatorProfileForm.arrival_instructions.$error.maxlength) { // check for arrival instructions
          $scope.validation.operatorLocation.arrival_instructions = true;
        } else {
          $scope.validation.operatorLocation.arrival_instructions = false;
        }

        if ($scope.operatorProfileForm.departure_instructions.$error.required || $scope.operatorProfileForm.departure_instructions.$error.maxlength) { // check for departure instructions
          $scope.validation.operatorLocation.departure_instructions = true;
        } else {
          $scope.validation.operatorLocation.departure_instructions = false;
        }
        if (!$scope.currentLocation.op_location_airports || ($scope.currentLocation.op_location_airports && $scope.currentLocation.op_location_airports.length == 0)) { // check for departure instructions
          $scope.validation.operatorLocation.op_location_airports = true;
        } else {
          $scope.validation.operatorLocation.op_location_airports = false;
        }

        if ((typeof $scope.currentLocation.op_lodgings == 'undefined' || $scope.currentLocation.op_lodgings.length <= CONST_HELPER.ZERO) && $scope.currentLocation.guest_can_select_room == 1) {
          $scope.validation.operatorLocation.lodgings = true;
        } else {
          $scope.validation.operatorLocation.lodgings = false;
        }

        // Before Save we check for a valid name in case of edit
        !$scope.isAddLocation ? $scope.checkValidLocationName() : null;
        if (($scope.location_medias.medias && $scope.location_medias.medias.length > CONST_HELPER.ZERO) || ($scope.location_medias.uploaded && $scope.location_medias.uploaded.length > CONST_HELPER.ZERO)) {
          $scope.location_medias.lodgingFormInvalid = false;
        } else {
          $scope.location_medias.lodgingFormInvalid = true;
        }
        for (var key in $scope.validation.operatorLocation) { // cehck if any key has errors
          if ($scope.validation.operatorLocation[key]) { // if yes
            $scope.completeBasicProfile = false; // dont show green tick
            $scope.LocationErr = false;
            return false; // prevent next click
          }
          if ($scope.location_medias.lodgingFormInvalid) {
            $scope.completeBasicProfile = false; // dont show green tick
            $scope.LocationErr = false;
            return false; // prevent next click
          }
        }
        $scope.completeBasicProfile = true; // show green tick
        $scope.LocationErr = true;
        $timeout(angular.noop);
        return true;
      };

      $scope.checkForErrorOnProfile = function () { // on basic profile check if any errors
        if (($scope.operatorProfile.contact_name === '') || $scope.operatorProfileForm.contact_name.$invalid) { // check for contact name
          $scope.validation.operatorProfile.contact_name = true;
        } else {
          $scope.validation.operatorProfile.contact_name = false;
        }
        if (($scope.operatorProfile.business_name === '') || $scope.operatorProfileForm.business_name.$invalid) { // check for business name
          $scope.validation.operatorProfile.business_name = true;
        } else {
          $scope.validation.operatorProfile.business_name = false;
        }
        if (($scope.operatorProfile.username === '') || ($scope.operatorProfileForm.username && $scope.operatorProfileForm.username.$invalid)) { // check for username
          $scope.validation.operatorProfile.username = true;
        } else {
          $scope.validation.operatorProfile.username = false;
        }
        if (($scope.operatorProfile.email === '') || $scope.operatorProfileForm.email.$invalid || $scope.showEmailErr) { // check for email
          $scope.validation.operatorProfile.email = true;
        } else {
          $scope.validation.operatorProfile.email = false;
        }
        if (($scope.operatorProfile.business_owner === '') || $scope.operatorProfileForm.business_owner.$invalid) { // check for bsuiness owner
          $scope.validation.operatorProfile.business_owner = true;
        } else {
          $scope.validation.operatorProfile.business_owner = false;
        }
        if (($scope.operatorProfile.website === '') || $scope.operatorProfileForm.website.$invalid) { // check for website eror
          $scope.validation.operatorProfile.website = true;
        } else {
          $scope.validation.operatorProfile.website = false;
        }
        if (($scope.operatorProfile.street_address === '') || $scope.operatorProfileForm.street_address.$invalid) { // check for street address eror
          $scope.validation.operatorProfile.street_address = true;
        } else {
          $scope.validation.operatorProfile.street_address = false;
        }
        if (($scope.operatorProfile.city === '') || $scope.operatorProfileForm.city.$invalid) { // check for city error
          $scope.validation.operatorProfile.city = true;
        } else {
          $scope.validation.operatorProfile.city = false;
        }
        var operatorPhoneNumberValidation = $("#operatorPhoneNumber").intlTelInput("getNumber");
        if ( operatorPhoneNumberValidation == '' || operatorPhoneNumberValidation.length < 8 || operatorPhoneNumberValidation.length > 16) { // check for phone eror
          $scope.validation.operatorProfile.phone = true;
        } else {
          $scope.validation.operatorProfile.phone = false;
        }
        if ($scope.operatorProfile.state_id === null) { // check for state
          $scope.validation.operatorProfile.state = true;
        } else {
          $scope.validation.operatorProfile.state = false;
        }
        if (($scope.operatorProfile.zipcode === '') || $scope.operatorProfileForm.zipcode.$invalid) { // check for zipcode eror
          $scope.validation.operatorProfile.zipcode = true;
        } else {
          $scope.validation.operatorProfile.zipcode = false;
        }

        if ($scope.operatorProfile.time_zone === null) { // cehck for timezone
          $scope.validation.operatorProfile.time_zone = true;
        } else {
          $scope.validation.operatorProfile.time_zone = false;
        }
        if ($scope.operatorProfile.country_id === null) { // check for country
          $scope.validation.operatorProfile.country_id = true;
        } else {
          $scope.validation.operatorProfile.country_id = false;
        }
        if (($scope.operatorProfile.safety_certification === '') || $scope.operatorProfileForm.safety_certification.$invalid) { // check for safety_certification
          $scope.validation.operatorProfile.safety_certification = true;
        } else {
          $scope.validation.operatorProfile.safety_certification = false;
        }

        for (var key in $scope.validation.operatorProfile) { // cehck if any key has errors
          if ($scope.validation.operatorProfile[key]) { // if yes
            $scope.completeBasicProfile = false; // dont show green tick
            return false; // prevent next click
          }
        }
        $scope.completeBasicProfile = true; // show green tick
        $timeout(angular.noop);
        return true;
      };

      $scope.handleSingleFileSelect = function (evt) { // for single file upload
        $scope.minSingleImgSize = false; // hide error message
        $scope.maxSingleImgSize = false; // hide error message
        $scope.invalidFormatImg = false;
        if (evt.currentTarget.files[CONST_HELPER.ZERO].size < CONST_HELPER.MEDIA.MIN_SIZE) { // check size of image is less then 50KB
          if ($scope.savedImage.croppedImage) { // if value already croped then set cropped value as well
            evt.currentTarget.value = '';
            $scope.gallery.croppedImage = $scope.savedImage.croppedImage;
            $scope.savedImage.croppedImage = null;
          } else {
            $scope.gallery.croppedImage = null;
          }
          $scope.minSingleImgSize = true; // show error message
          $timeout(angular.noop);
          return false;
        }
        var file = evt.currentTarget.files[CONST_HELPER.ZERO];
        var fileExtnArr = file.name.split('.');
        var extn = fileExtnArr[fileExtnArr.length - CONST_HELPER.ONE].toLowerCase();
        if (extn === 'png' || extn === 'jpg' || extn === 'jpeg' || extn === 'gif') { // check valid file extention
          $scope.showPopup('#cropImagePopup'); // show crop image popup
        } else {
          $scope.invalidFormatImg = true;
          return false;
        }
      };

      angular.element(document.querySelector('#operatorCoverImage')).on('change', $scope.handleSingleFileSelect);
      angular.element(document.querySelector('#changePhoto')).on('change', $scope.handleSingleFileSelect);

      $scope.background.cropPopup = function () {
        $scope.gallery.sourceImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
      }

      $scope.cropImage = function () { // crop image function
        $scope.hidePopup('#cropImagePopup'); // hide crop image popup
        $scope.gallery.cover_image = $scope.gallery.croppedImage; // reset cover image
        angular.copy($scope.gallery, $scope.savedImage);
        document.getElementById('changePhoto').value = '';
        $scope.background.cropPopup();
      };

      $scope.cropLogoImage = function () { // crop image function
        $scope.hidePopup('#cropLogoPopup'); // hide crop image popup
        $scope.operatorImage.original_logo = $scope.gallery.logoImage;
        $scope.background.cropPopup();
      };

      $scope.$on('mapentrySelected', function (event, data) {
        var lat = data.geometry.location.lat();
        var lng = data.geometry.location.lng();
        var bounds = new google.maps.LatLngBounds();
        var latlng = new google.maps.LatLng(lat, lng);
        bounds.extend(latlng);
        $scope.currentLocation.latitude = lat;
        $scope.currentLocation.longitude = lng;
        $scope.currentLocation.op_location_airports = null;
        $scope.currentLocation.address = data.formatted_address;
        resetMapToNewLocation({
          latitude: lat,
          longitude: lng
        });
      });

      $scope.$watch('currentLocation.address', function (event, data) {
        if ($scope.currentLocation.latitude && $scope.currentLocation.longitude) {
          resetMapToNewLocation($scope.currentLocation);
        }
      });

      // Update airports objects
      $scope.setAirportObj = function (selected) {
        var arr = [];
        $scope.currentLocation.op_location_airports && $scope.currentLocation.op_location_airports.length > CONST_HELPER.ZERO && $scope.currentLocation.op_location_airports.forEach(function (selectedObj) {
          var airportName = selectedObj.code ? selectedObj.name + '(' + selectedObj.code + ')' : selectedObj.name;
          arr.push({
            id: selectedObj.id,
            name: airportName,
            latitude: (selectedObj.geometry ? selectedObj.geometry.location.lat() : selectedObj.latitude),
            longitude: (selectedObj.geometry ? selectedObj.geometry.location.lng() : selectedObj.longitude)
          });
        });
        return arr;
      };

      checkUploadingGoogleMap();

      document.addEventListener('googleMapApiLoaded', checkUploadingGoogleMap);

      function checkUploadingGoogleMap() {
        $scope.googleMapApiLoaded = googleApiLoaded;
      }

      $scope.onDragEnd = function ($event, ctrl) {
        var lat = $event.latLng.lat();
        var lng = $event.latLng.lng();
        var bounds = new google.maps.LatLngBounds();
        var latlng = new google.maps.LatLng(lat, lng);
        bounds.extend(latlng);

        NgMap.getMap().then(function (map) {
          getLocationName(latlng, lat, lng);
        });
      };

      function getLocationName(latLng, lat, lng) {
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({
          'location': latLng
        }, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results[CONST_HELPER.ZERO]) {
              $scope.currentLocation.address = results[CONST_HELPER.ZERO].formatted_address;
              $scope.currentLocation.latitude = lat;
              $scope.currentLocation.longitude = lng;
              $scope.marker.lat = lat;
              $scope.marker.lng = lng;
              $timeout(angular.noop);
            } else {
              $scope.showPopup('#errModal');
            }
          } else {
            $scope.showPopup('#errModal');
          }
        });
      }

      $scope.cancelImageCrop = function (popupId) { // cancel image crop
        document.getElementById('operatorCoverImage').value = '';
        document.getElementById('changePhoto').value = '';
        $scope.hidePopup('#' + popupId);
        $scope.background.cropPopup();

        if(popupId === "cropLogoPopup"){
          document.getElementById('original_logo').value = '';
        }
      };

      $scope.handleMultiFileSelect = function (evt) { // upload gallery images
        var imagesCanByUploedCount = CONST_HELPER.TEN - $scope.myImage.length;
        var uploadedImagesCount = evt.currentTarget.files.length;
        if (uploadedImagesCount > imagesCanByUploedCount) {
          uploadedImagesCount = imagesCanByUploedCount;
        }

        for (var i = CONST_HELPER.ZERO; i < uploadedImagesCount; i++) {
          if (evt.currentTarget.files[i].size < CONST_HELPER.MEDIA.MIN_SIZE) { // check size of image is less then 50KB
            $scope.minMultipleImgSize = true;
            evt.currentTarget.value = '';
            $timeout(angular.noop);
            return false;
          } else {
            $scope.minMultipleImgSize = false;
          }
          var file = evt.currentTarget.files[i];
          var fileExtnArr = file.name.split('.');
          var extn = fileExtnArr[fileExtnArr.length - CONST_HELPER.ONE].toLowerCase();
          if (extn === 'png' || extn === 'jpg' || extn === 'jpeg' || extn === 'gif') {
            /* check valid image extn */
            var image = new Image();
            image.file = file;
            image.onload = function () {
              var quality = 1;
              var fileName = this.file.name;
              if (this.file.size > CONST_HELPER.MEDIA.MAX_SIZE) {
                quality = CONST_HELPER.MEDIA.MAX_SIZE / this.file.size;
              }
              var canvas = document.createElement('canvas');
              canvas.width = this.width * quality;
              canvas.height = this.height * quality;
              var ctx = canvas.getContext('2d');
              ctx.drawImage(this, 0, 0, this.width * quality, this.height * quality);
              var new_image = canvas.toDataURL('image/jpeg', 0.5);
              $scope.myImage.push(new_image);
              fetch(new_image)
                .then(function (res) {
                  return res.blob();
                })
                .then(function (blob) {
                  return $scope.uploadImages.push(new File([blob], fileName));
                });
              $scope.$digest();
            };
            image.src = window.URL.createObjectURL(file);
          } else {
            /* if image extn invalid then show alert message */
            file = '';
            alert("Please upload a valid image format(acceptable formats 'png','jpg','gif')");
          }
        }
      };

      $scope.deleteImage = function (imgId, index) { // delete images from gallery
        $scope.myImage.splice(index, CONST_HELPER.ONE);
        $scope.uploadImages.splice(index, CONST_HELPER.ONE);
        if (imgId) {
          angular.forEach($scope.old_gallery_images, function (img) {
            if (img.id === imgId) {
              $scope.old_gallery_images.splice(index, CONST_HELPER.ONE);
            }
          });
        }
      };
      $scope.logoUpload = function (evt, imageType) { // logo upload method
        $scope.uploadType = imageType;
        $scope.maxOriginalLogoImgSize = false;
        $scope.maxWhiteLogoImgSize = false;
        var file = evt.currentTarget.files[CONST_HELPER.ZERO];
        var fileExtnArr = file.name.split('.');
        var extn = fileExtnArr[fileExtnArr.length - CONST_HELPER.ONE].toLowerCase();
        if (extn === 'png' || extn === 'jpg' || extn === 'jpeg') { // check valid file extention
          var reader = new FileReader();
          reader.onload = function (evt) {
            $scope.$apply(function ($scope) {
              if ($scope.uploadType === 'original') {
                $scope.showPopup('#cropLogoPopup');
              } else {
                var image = new Image();
                image.file = file;
                image.onload = function () {
                  var quality = 1;
                  var fileName = this.file.name;
                  if (this.file.size > CONST_HELPER.MEDIA.MAX_SIZE) {
                    quality = CONST_HELPER.MEDIA.MAX_SIZE / this.file.size;
                  }
                  var canvas = document.createElement('canvas');
                  canvas.width = this.width * quality;
                  canvas.height = this.height * quality;
                  var ctx = canvas.getContext('2d');
                  ctx.drawImage(this, 0, 0, this.width * quality, this.height * quality);
                  var new_image = canvas.toDataURL('image/png', 0.5);
                  fetch(new_image)
                    .then(function (res) {
                      return res.blob();
                    })
                    .then(function (blob) {
                      var reader = new FileReader();
                      reader.readAsDataURL(blob);
                      reader.onloadend = function () {
                        $scope.operatorImage.white_logo = reader.result;
                        $scope.$digest();
                      }
                    });
                };
                image.src = window.URL.createObjectURL(file);
              }
            });
          };
          reader.readAsDataURL(file);
        } else {
          return false;
        }
      };

      angular.element(document.querySelector('#multipleFileInputOperator')).on('change', $scope.handleMultiFileSelect);
      angular.element(document.querySelector('#addMultipleFileInputOperator')).on('change', $scope.handleMultiFileSelect);
      angular.element(document.querySelector('#original_logo')).on('change', function (event) {
        $scope.logoUpload(event, 'original');
      });
      angular.element(document.querySelector('#white_logo')).on('change', function (event) {
        $scope.logoUpload(event, 'white_logo');
      });

      $scope.removeLogo = function (id) { // remove logo image
        $scope.operatorImage[id] = null;
        angular.element(document.querySelector('#' + id))[0].value = '';
      };

      $scope.removeCoverImage = function () { // remove cover image
        $scope.gallery.cover_image = ''; // reset everyhting for cover image
        $scope.gallery.croppedImage = null;
      };

      $scope.checkForErrorOnImage = function () { // on operator image check if any errors

        // check for description
        $scope.validation.operatorImage.description = $scope.operatorImage.description === '' || $scope.operatorProfileForm.description.$invalid;

        // check for original logo
        $scope.validation.operatorImage.original_logo = $scope.operatorImage.original_logo === null || $scope.operatorImage.original_logo === '' || $scope.maxOriginalLogoImgSize;

        // check for cover image
        $scope.validation.operatorImage.cover_image = !$scope.gallery.cover_image.length;

        //check if any key has errors
        $scope.completeOperatorImage = !$scope.validation.operatorImage.description &&
          !$scope.validation.operatorImage.original_logo &&
          !$scope.validation.operatorImage.cover_image;

        return $scope.completeOperatorImage
      };
      var amountValuePattern = /^[+-]?([0-9]*[.,])?[0-9]+$/;

      $scope.checkForCancellationPolicy = function () { // on operator cancellation policy check for errors
        if ($scope.operatorPolicy.country_code) {
          $scope.validation.operatorPolicy.country_code = false;
        } else {
          $scope.validation.operatorPolicy.country_code = true;
        }
        $scope.validation.operatorPolicy.domestic = [];
        $scope.validation.operatorPolicy.international = [];
        $scope.domesticInternationalTaxValidation = false;
        $scope.accountSettings.policies.taxPolicy.taxes.domestic.forEach(function(domesticTax, index){
          var errors = {};
          if ($scope.operatorProfileForm['domesticTax_name_' + index].$invalid) {
            errors.name = true;
            $scope.domesticInternationalTaxValidation = true;
          } else {
            errors.name = false;
          }
          if (domesticTax.calculation_type == CONST_HELPER.OPERATOR_TAXES.CALCULATION_TYPE.PERCENTAGE.ID) {
            // for percentage
            if ($scope.operatorProfileForm['domestic_tax_value_' + index].$invalid || Number(domesticTax.value) >= 100) {
              errors.valueTax = true;
              $scope.domesticInternationalTaxValidation = true;
            } else {
              errors.valueTax = false;
            }
          } else {
            // for amount
            if ($scope.operatorProfileForm['domestic_tax_value_' + index].$invalid || Number(domesticTax.value) > 99999.99) {
              errors.valueTax = true;
              $scope.domesticInternationalTaxValidation = true;
            } else {
              errors.valueTax = false;
            }
          }
          $scope.validation.operatorPolicy.domestic.push(errors)
        })

        $scope.accountSettings.policies.taxPolicy.taxes.international.forEach(function(internationalTax, index){
          var errors = {};
          if ($scope.operatorProfileForm['international_tax_name_' + index].$invalid) {
            errors.name = true;
            $scope.domesticInternationalTaxValidation = true;
          } else {
            errors.name = false;
          }
          if (internationalTax.calculation_type == CONST_HELPER.OPERATOR_TAXES.CALCULATION_TYPE.PERCENTAGE.ID) {
            // for percentage
            if ($scope.operatorProfileForm['international_tax_value_' + index].$invalid || Number(internationalTax.value) >= 100) {
              errors.valueTax = true;
              $scope.domesticInternationalTaxValidation = true;
            } else {
              errors.valueTax = false;
            }
          } else {
            // for amount
            if ($scope.operatorProfileForm['international_tax_value_' + index].$invalid || Number(internationalTax.value) > 99999.99) {
              errors.valueTax = true;
              $scope.domesticInternationalTaxValidation = true;
            } else {
              errors.valueTax = false;
            }
          }
          $scope.validation.operatorPolicy.international.push(errors)
        })


        if (!$scope.operatorProfileForm.cancellation_description.$error.maxlength && !$scope.operatorPolicy.cancellation_description) { // check for description
          $scope.validation.operatorPolicy.cancellation_description = true;
        } else {
          $scope.validation.operatorPolicy.cancellation_description = false;
        }
        if ($scope.operatorProfileForm.cancellation_description.$error.maxlength) { // check for description
          $scope.validation.operatorPolicy.can_desc_maxlenError = true;
        } else {
          $scope.validation.operatorPolicy.can_desc_maxlenError = false;
        }
        if (!$scope.operatorProfileForm.insurance_policy.$error.maxlength && !$scope.operatorPolicy.insurance_policy) { // check for description
          $scope.validation.operatorPolicy.insurance_policy = true;
        } else {
          $scope.validation.operatorPolicy.insurance_policy = false;
        }
        if ($scope.operatorProfileForm.insurance_policy.$error.maxlength) { // check for description
          $scope.validation.operatorPolicy.tm_insurance_maxlenError = true;
        } else {
          $scope.validation.operatorPolicy.tm_insurance_maxlenError = false;
        }
        if ($scope.operatorPolicy.is_partial) { // check for description
          if (!$scope.operatorPolicy.amount_percentage || ($scope.operatorPolicy.deposit_type === CONST_HELPER.DEPOSIT_TYPE.PERCENTAGE && $scope.operatorPolicy.amount_percentage > CONST_HELPER.HUNDRED)) {
            $scope.validation.operatorPolicy.amount_percentage = true;
          } else {
            $scope.validation.operatorPolicy.amount_percentage = false;
          }
        } else {
          $scope.validation.operatorPolicy.amount_percentage = false;
        }
        if (($scope.operatorPolicy.min_num_cancellation_days === '') || $scope.operatorProfileForm.min_num_cancellation_days.$invalid || $scope.showCancelZeroDateErr) { // check for min_num_cancellation_days eror
          $scope.validation.operatorPolicy.min_num_cancellation_days = true;
        } else {
          $scope.validation.operatorPolicy.min_num_cancellation_days = false;
        }
        for (var key in $scope.validation.operatorPolicy) { // cehck if any key has errors
          if ($scope.validation.operatorPolicy[key] && !$scope.validation.operatorPolicy.international && $scope.validation.operatorPolicy.domestic) { // if yes
            $scope.completeCancellationPolicy = false; // dont show green tick
            return false; // prevent next click
          }
        }
        if ($scope.domesticInternationalTaxValidation) {
          $scope.completeCancellationPolicy = false; // dont show green tick
          return false; // prevent next click
        }
        $scope.completeCancellationPolicy = true; // show green tick
        return true;
      };

      function getOldLocationMedias(media) {
        if (!media) {
          return [];
        }

        var old_images = media.filter(function (val) {
          return val.id;
        });
        if (old_images.length) {
          return old_images;
        } else {
          return [];
        }
      }
      $scope.saveOperatorLocation = function (from, airport, fromDashboard, createMore) {
        if (from == 'location') {
          var requestObj = {};
          requestObj.address = $scope.currentLocation.address;
          requestObj.arrival_instructions = $scope.currentLocation.arrival_instructions;
          requestObj.departure_instructions = $scope.currentLocation.departure_instructions;
          requestObj.description = $scope.currentLocation.description;
          requestObj.id = $scope.currentLocation.id;
          requestObj.latitude = $scope.currentLocation.latitude;
          requestObj.longitude = $scope.currentLocation.longitude;
          requestObj.modified = $scope.currentLocation.modified;
          requestObj.name = $scope.name_new;
          requestObj.guest_can_select_room = $scope.currentLocation.guest_can_select_room;
          requestObj.op_location_airports = airport;
          requestObj.token = $scope.user.token;
          requestObj.is_save_exit = CONST_HELPER.is_save_exit[from];
          requestObj.op_location_videos = $scope.currentLocation.op_location_videos;
          requestObj.old_location_medias = getOldLocationMedias($scope.location_medias.medias);
          requestObj.op_lodgings = $scope.currentLocation.op_lodgings;
          requestObj.temp_id = $scope.currentLocation.temp_id;
          $scope.newRoom = {
            op_lodging_medias: [],
            lodgingUploadImage: [],
            id: $scope.getTempId(),
            lodgingFormInvalid: false
          };
          $scope.operatorProfileForm.$setPristine();
          $scope.operatorProfileForm.$setUntouched();
          $scope.showLoader();
          operatorProfileService.saveOperatorLocation(requestObj, $scope.location_medias.uploaded).then(function (response) {
            if (response.data.code === CONST_HELPER.API_RESPONSE.CREATED || response.data.code === CONST_HELPER.API_RESPONSE.OK) {
              $scope.saveErr = false;
              $scope.hideLoader();
              if ($location.url().indexOf('operator-edit-profile') > CONST_HELPER.NOT_FOUND) { // if on edit page
                $scope.$parent.successMsg = 'Location added successfully.';
                $scope.showPopup('#saveDetailModal'); // show success modal
                $scope.currentLocation = response.data ? response.data.data[CONST_HELPER.ZERO] : {};
              } else {
                if (createMore) {
                  $scope.operatorProfileForm.$setPristine();
                  $scope.currentLocation = {
                    latitude: CONST_HELPER.default_location.latitude,
                    longitude: CONST_HELPER.default_location.longitude,
                    address: CONST_HELPER.default_location.address,
                    op_location_videos: [],
                    lodgings: [],
                    temp_id: $scope.getTempId(),
                    id: null
                  };
                  $scope.name_new = '';
                  $scope.location_medias = [];
                  $scope.video = '';
                  resetMapToNewLocation($scope.currentLocation);
                } else {
                  $scope.getOperatorProfile(true);
                }
                return true;
              }
              $scope.allLocations = [];
              // Airport Error fix.
              $scope.currentLocation.op_location_airports = $scope.currentLocation.op_location_airports && $scope.currentLocation.op_location_airports.length > CONST_HELPER.ZERO ? $scope.currentLocation.op_location_airports : null;
              $scope.getOperatorLocations();
              $scope.getOperatorProfile();
            } else if (response.data.code === CONST_HELPER.API_RESPONSE.BAD_REQUEST) {
              for (var key in response.message) {
                $scope.validations['operatorLocation'][key] = true;
                $scope.operatorProfileForm[key].$error['required'] = true;
              }

              $scope.saveErr = true;
            }
            $scope.hideLoader();
          }, function () {
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
        }
      };

      /*
      //  function to customize tags
      */
      $scope.tagTransform = function (newTag) {
        newTag = newTag.replace(/[^a-zA-Z0-9 ]/g, '');
        if (newTag !== '' && newTag !== ' ' && newTag.trim().length > 2) {
          var item = {
            name: newTag,
            id: 12,
            category: 'custom'
          };
          if (item) {
            return item;
          }
        }
      };

      $scope.saveOperatorProfile = function (from) { // create operator profile
        var requestObj = setRequestObject(); // set request object
        if ($scope.operatorImage.original_logo === null) { // cehck for original logo presence
          requestObj.original_logo = '';
        } else {
          requestObj.original_logo = $scope.operatorImage.original_logo;
        }

        if ($scope.operatorImage.white_logo === null) { // check for white logo presence
          requestObj.white_logo = '';
        } else {
          requestObj.white_logo = $scope.operatorImage.white_logo;
        }
        if ($scope.minSingleImgSize || $scope.maxSingleImgSize || $scope.invalidFormatImg) {
          requestObj.cover_image = $scope.operatorImage.cover_image ? $scope.operatorImage.cover_image : '';
        }
        var isValid = true;
        if ($scope.currentHash === 'basic-profile') { // if on policies page
          isValid = $scope.checkForErrorOnProfile(); // check if basic profile is complete
        } else if ($scope.currentHash === 'policies') { // if on policies page
          requestObj.is_save_exit = 2; // for validation check on submit
          isValid = $scope.checkForCancellationPolicy(); // check if cancellation policy is valid
        }
        if (from) { // if from edit profile section
          requestObj.is_save_exit = CONST_HELPER.is_save_exit[from];
        }

        if (isValid) {
          $scope.showLoader();
          operatorProfileService.saveOperatorProfile(requestObj, $scope.uploadImages).then(function (response) {
            if (response.data.code === CONST_HELPER.API_RESPONSE.CREATED || response.data.code === CONST_HELPER.API_RESPONSE.OK) {
              updateScopeUser(response.data.data); // UPDATE USER SCOPE.
              operatorProfileService.updateOperatorProfileOnAllPackages({
                token: $scope.user.token
              }).then(function (response) {
                console.log('tag');
              });
              $scope.saveErr = false;
              $scope.hideLoader();
              if ($location.url().indexOf('operator-edit-profile') > CONST_HELPER.NOT_FOUND) { // if on edit page
                $scope.$parent.successMsg = 'Your profile has been updated successfully.';
                $scope.showPopup('#saveDetailModal'); // show success modal
              } else {
                $state.go('operator-get-started'); // move to get started page
              }
            } else if (response.data.code === CONST_HELPER.API_RESPONSE.UNPROCESSABLE) {
              if (response.data.message.username) {
                $scope.errorUserMsg = response.data.message.username.isUsernameExists;
              }
            } else {
              $scope.saveErr = true;
            }
            $scope.hideLoader();
          }, function () {
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
        }
      };
      // update user scope and update cookies.
      function updateScopeUser(user) {
        $rootScope.user.name = user.name;
        $rootScope.user.email = user.email;
        $rootScope.user.username = user.username;
        $rootScope.user.operator_profile = user.operator_profile;
        $rootScope.user.profile_pic = user.operator_image.original_logo;
        $rootScope.user.cover_pic = user.operator_image.cover_pic;
        $rootScope.user.is_basic_profile_completed = user.is_basic_profile_completed; // update profile status
        $rootScope.user.is_bank_details_completed = user.is_bank_details_completed;
        $rootScope.user.is_profile_completed = user.is_profile_completed;
        $rootScope.user.location_exists = user.location_exists;
        $scope.user = $rootScope.user;
        $scope.setUserDetails($rootScope.user);
      }

      $scope.confirmDeleteLocation = function () {
        $scope.showPopup('#confirmDeleteLocation');
      };

      $scope.deleteLocation = function (id) {
        $scope.showLoader();
        operatorProfileService.deleteLocation(id, $scope.user.token).then(function (response) {
          $scope.hideLoader();
          if (response.data.code == CONST_HELPER.API_RESPONSE.NO_CONTENT || response.data.code == CONST_HELPER.API_RESPONSE.OK) {
            // delete success
            $scope.showPopup('#saveDetailModal');
            $scope.allLocations = [];
            $scope.$parent.successMsg = 'Location deleted successfully.';
            $scope.location_medias = {
              medias: [],
              uploaded: [],
              id: $scope.getTempId(),
              lodgingFormInvalid: false
            };
            $scope.name_new = '';
            $scope.getOperatorLocations(true);
            $scope.getOperatorProfile();
          } else if (response.data.code == CONST_HELPER.API_RESPONSE.UNPROCESSABLE) {
            // delete success
            $scope.showPopup('#errModal');
            $scope.$parent.errmsg = response.data.message;
            $scope.getOperatorLocations();
          } else {
            //  error while delete
            $scope.showPopup('#serverErrModal');
          }
        });
      };

      function setRequestObject() {
        var obj = {
          is_save_exit: CONST_HELPER.ONE,
          business_name: $scope.operatorProfile.business_name,
          business_owner: $scope.operatorProfile.business_owner,
          contact_name: $scope.operatorProfile.contact_name,
          tagline: $scope.operatorProfile.tagline === null ? '' : $scope.operatorProfile.tagline,
          tag_description: $scope.operatorProfile.tag_description === null ? '' : $scope.operatorProfile.tag_description,
          website: $scope.operatorProfile.website,
          street_address: $scope.operatorProfile.street_address,
          country_id: $scope.operatorProfile.country_id,
          state_id: $scope.operatorProfile.state_id,
          state_code: $scope.operatorProfile.state_code === null ? '' : $scope.operatorProfile.state_code,
          city: $scope.operatorProfile.city,
          zipcode: $scope.operatorProfile.zipcode,
          phone: $("#operatorPhoneNumber").intlTelInput("getNumber"),
          username: $scope.operatorProfile.username,
          email: $scope.operatorProfile.email,
          safety_certification: $scope.operatorProfile.safety_certification,
          time_zone: $scope.operatorProfile.time_zone,
          description: $scope.operatorImage.description,
          tax_name: $scope.operatorPolicy.tax_name,
          country_code: $scope.operatorPolicy.country_code,
          international_percent: $scope.operatorPolicy.international_percent,
          domestic_percent: $scope.operatorPolicy.domestic_percent,
          min_num_cancellation_days: $scope.operatorPolicy.min_num_cancellation_days,
          cover_image: $scope.gallery.cover_image,
          cancellation_description: $scope.operatorPolicy.cancellation_description,
          old_gallery_images: $scope.old_gallery_images.length ? $scope.old_gallery_images : [{id: 0}],
          token: $scope.user.token,
          is_use_for_sale_taxes: CONST_HELPER.ZERO,
          tax_verification_err: $scope.operatorPolicy.tax_verification_err,
          allow_refund_partial: $scope.operatorPolicy.allow_refund_partial ? $scope.operatorPolicy.allow_refund_partial : CONST_HELPER.ZERO,
          allow_refund_balance: $scope.operatorPolicy.allow_refund_balance ? $scope.operatorPolicy.allow_refund_balance : CONST_HELPER.ZERO,
          is_partial: $scope.operatorPolicy.is_partial ? $scope.operatorPolicy.is_partial : CONST_HELPER.ZERO,
          deposit_type: $scope.operatorPolicy.deposit_type,
          amount_percentage: $scope.operatorPolicy.amount_percentage,
          insurance_policy: $scope.operatorPolicy.insurance_policy,
          operator_taxes: $scope.accountSettings.policies.taxPolicy.taxes
        };
        return obj;
      }

      $scope.changeCountry = function (value, countryid) { // remove time zone or country error on selection
        if (value) {
          $(value).parent().removeClass('ng-invalid');
          if ((typeof (countryid) !== 'undefined') && countryid !== null) { // check for undefined values
            $scope.getAllCountryName().then(function (countries) {
              var selectedCountry = countries.find(function (country) {
                return country.id == countryid;
              });

              $scope.countryStateLabel = selectedCountry ? selectedCountry.country_label : 'NA';
            });

            if (value.indexOf('payment_country') > CONST_HELPER.NOT_FOUND) { // only for country
              $scope.validation.operatorPaymentDetail = {}; // Removing all the previous validations as the country changes
              getPaymentStates(countryid);
              $scope.operatorPaymentDetail.payment_state = '';
            } else if (value.indexOf('country') > CONST_HELPER.NOT_FOUND) {
              getStates(countryid);
              $scope.operatorProfile.state_id = '';
            }
          }
        }
      };
      $scope.changeCurrency = function (countryid, callbackFn) { // change currency values for selected country
        if ((typeof (countryid) !== 'undefined') && countryid !== null) { // check for undefined values
          $scope.showLoader();
          $scope.isEuropean = checkCountryForIBAN(countryid); // true when the country requires IBAN number
          var data = {
            country_code: countryid,
            isMutiple: CONST_HELPER.ONE,
            token: $scope.user.token
          };
          $scope.operatorPaymentDetail.currency = '';
          $scope.operatorPaymentDetail.account_number = '';
          if (!$scope.isEuropean && $scope.operatorProfileForm.routing_number) {
            $scope.operatorPaymentDetail.routing_number = '';
            $scope.operatorProfileForm.routing_number.$setPristine(false);
          }
          if ($scope.operatorProfileForm.account_number) {
            $scope.operatorProfileForm.account_number.$setPristine(false);
          }
          if ($scope.operatorProfileForm.currency) {
            $scope.operatorProfileForm.currency.$setPristine(false);
          }
          operatorProfileService.getCurrencyCode(data).then(function (response) {
            $scope.hideLoader();
            if (response.data && response.data.code === CONST_HELPER.API_RESPONSE.OK) {
              $scope.localCurrencyList = response.data.currency_details;
              if (callbackFn) callbackFn();
            } else {
              $scope.localCurrencyList = $scope.currencyList.filter(function (currency) {
                return currency.code.indexOf(countryid) > CONST_HELPER.NOT_FOUND;
              });
            }
          }, function () {
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
        }
      };
      // Check for the European countries which accept IBAN instead of Account Number
      function checkCountryForIBAN(countryCode) {
        if (countryCode === 'FR' || countryCode === 'NO' || countryCode === 'PT' || countryCode === 'ES' || countryCode === 'CH' || countryCode === 'DE') {
          return true;
        }
        return false;
      }

      $scope.$on('$locationChangeSuccess', function (event, newUrl, oldUrl) { // get new hash value as the tab is switched
        $scope.currentHash = newUrl.split('#')[CONST_HELPER.ONE] || 'basic-profile';
        switch ($scope.currentHash) {
          case 'operator-image':
            $scope.mobileHash = 'OPERATOR IMAGE';
            $scope.btnText = 'SAVE & EXIT';
            break;
          case 'policies':
            $scope.mobileHash = 'POLICIES';
            $scope.btnText = 'SAVE';
            break;
          case 'basic-profile':
            $scope.mobileHash = 'BASIC PROFILE';
            $scope.btnText = 'SAVE & EXIT';
            break;
          case 'payment-detail':
            $scope.mobileHash = 'PAYMENT DETAILS';
            $scope.btnText = 'SUBMIT';
            break;
        }
        $window.scrollTo(CONST_HELPER.ZERO, CONST_HELPER.ZERO); // redirect on top when hash change
      });

      $scope.editOperatorProfile = function (from, fromDashboard, createNew) {
        $scope.isSaveDetailsValid = true;
        var isValid = true;
        var airports = [];
        if (from === 'profile') { // on basic profile check if any errors
          isValid = $scope.checkForErrorOnProfile();
        } else if (from === 'location') { // on basic location check if any errors
          isValid = $scope.checkForErrorOnLocation();
          airports = $scope.setAirportObj();
          return isValid ? $scope.saveOperatorLocation(from, airports, fromDashboard, createNew) : false;
        } else if (from === 'gallery') { // on IMAGE section check if any errors
          isValid = $scope.checkForErrorOnImage();
        } else if (from === 'policies') { // on policy section check if any errors
          isValid = $scope.checkForCancellationPolicy();
        }

        if (isValid) {
          $scope.saveOperatorProfile(from);
          $scope.maxOriginalLogoImgSize = false; // hide error message
          $scope.minMultipleImgSize = false; // hide error message
          $scope.maxMultipleImgSize = false; // hide error message
          $scope.minSingleImgSize = false; // hide error message
          $scope.maxSingleImgSize = false; // hide error message
          $scope.maxWhiteLogoImgSize = false; // hide error message
        }
      };

      $scope.checkEmailExistence = function (email) { // check if email already exists while typing
        if (typeof email === 'undefined') { // check if email exists
          email = document.getElementById('email').value;
        }
        if ($scope.operatorProfileForm.email.$error.pattern && $scope.operatorProfileForm.email.$dirty) { // has pattern error
          return false;
        } // check for valid pattern
        email = {
          'email': email
        }; // email address
        operatorProfileService.checkEmailExistence(email, $scope.user.token).then(function (response) { // post to the server the user name
          if (response.data) {
            if (response.data.code === CONST_HELPER.API_RESPONSE.UNPROCESSABLE) { // some error exits
              $scope.showEmailErr = true; // set error status
            } else if (response.data.code === CONST_HELPER.API_RESPONSE.OK) { // perfect
              $scope.showEmailErr = false; // set error status
            }
          }
        }, function () {
          $scope.showPopup('#serverErrModal');
        });
      };

      $scope.checkCancelDurationDay = function (value) { // check number of days for cancallation policy page
        if (value < CONST_HELPER.ONE) { // check for less then CONST_HELPER.ZERO value
          $scope.showCancelZeroDateErr = true; // show error message
        } else {
          $scope.showCancelZeroDateErr = false; // show error message
        }
      };
      var end = new Date();
      var start = new Date();
      end.setFullYear(end.getFullYear() - 13);
      start.setFullYear(start.getFullYear() - 150);
      $('#dob').datepicker({
        endDate: end,
        startDate: start
      });

      // get operator stripe account details
      function getOperatorPaymentDetails() {
        operatorProfileService.getPaymentDetails($scope.user.token).then(function (response) {
          if (response.data) {
            if (response.data.code === CONST_HELPER.API_RESPONSE.OK) { // perfect
              $scope.showEmailErr = false; // set error status
              $scope.showProfileDetails = true;

              if (response.data.data.flywire) {
                $scope.accountSettings.payoutDetails.flywire.account.setAccount(response.data.data.flywire);
              }

              if (response.data.data.stripe) {
                $scope.accountSettings.payoutDetails.stripe.setAccount(response.data.data.stripe);

                setPaymentDetails(response.data.data.stripe);
              } else {
                $scope.hideLoader();
              }

              hideCaret(); // Hides the caret of country drop down
            } else {
              $scope.hideLoader();
            }
          }
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      }

      function setAccountVerificationStatus(status, from) {
        // if(!$scope.unverifiedDocument)status='unverified';
        switch (status) {
          case 'verified':
            $scope.verifiedDocument = true; // verified account
            // $scope.disableAllPaymentElements = true;
            $scope.unverifiedDocument = false;
            $scope.pendingDocument = false;
            break;
          case 'pending':
            $scope.pendingDocument = true; // pending verification
            $scope.verifiedDocument = false;
            // $scope.disableAllPaymentElements = false;
            $scope.unverifiedDocument = false;
            break;
          case 'unverified':
            $scope.unverifiedDocument = true; // unverified account
            // $scope.disableAllPaymentElements = false;
            $scope.pendingDocument = false;
            $scope.verifiedDocument = false;
            break;
        }
      }

      // setting the operator payout account details
      function setPaymentDetails(payment) {
        if (payment.account_links) {
          $scope.operatorPaymentDetail.account_links = payment.account_links;
        }

        if (payment.business_type === 'company') {
          // TODO: show and prefill company's fields
        } else if(payment.business_type === 'individual') {
          $scope.operatorPaymentDetail.first_name = payment.individual.first_name;
          $scope.operatorPaymentDetail.last_name = payment.individual.last_name;
          $scope.operatorPaymentDetail.payment_country_code = payment.country;
          $scope.operatorPaymentDetail.dob = getOperatorDobStringFromObject(payment.individual.dob);
          if (payment.country === 'JP') {
            $scope.operatorPaymentDetail.phone_number = payment.legal_entity.phone_number;
            $scope.operatorPaymentDetail.gender = payment.legal_entity.gender;
            $scope.operatorPaymentDetail.first_name_kana = payment.legal_entity.first_name_kana;
            $scope.operatorPaymentDetail.last_name_kana = payment.legal_entity.last_name_kana;
            $scope.operatorPaymentDetail.payment_street_address_kana = payment.legal_entity.address_kana.line1;
            $scope.operatorPaymentDetail.payment_town_kana = payment.legal_entity.address_kana.town;
            $scope.operatorPaymentDetail.payment_city_kana = payment.legal_entity.address_kana.city;
            $scope.operatorPaymentDetail.payment_state_kana = payment.legal_entity.address_kana.state;
            $scope.operatorPaymentDetail.payment_zipcode_kana = payment.legal_entity.address_kana.postal_code;
            $scope.operatorPaymentDetail.first_name_kanji = payment.legal_entity.first_name_kanji;
            $scope.operatorPaymentDetail.last_name_kanji = payment.legal_entity.last_name_kanji;
            $scope.operatorPaymentDetail.payment_street_address_kanji = payment.legal_entity.address_kanji.line1;
            $scope.operatorPaymentDetail.payment_town_kanji = payment.legal_entity.address_kanji.town;
            $scope.operatorPaymentDetail.payment_city_kanji = payment.legal_entity.address_kanji.city;
            $scope.operatorPaymentDetail.payment_state_kanji = payment.legal_entity.address_kanji.state;
            $scope.operatorPaymentDetail.payment_zipcode_kanji = payment.legal_entity.address_kanji.postal_code;
          } else {
            $scope.operatorPaymentDetail.payment_street_address = payment.individual.address.line1;
            $scope.operatorPaymentDetail.payment_city = payment.individual.address.city;
            $scope.operatorPaymentDetail.payment_state = payment.individual.address.state;
            $scope.operatorPaymentDetail.payment_zipcode = payment.individual.address.postal_code;
          }

          setAccountVerificationStatus(payment.individual.verification.status, 'second'); // check verification doc status
        }

        if (payment.external_accounts.data.length) {
          $scope.showAccountDetails = true;
          setOperatorBankDetails(payment.external_accounts.data[CONST_HELPER.ZERO]);
        } else {
          $scope.showAccountDetails = false;
        }

        getPaymentStates($scope.operatorPaymentDetail.payment_country_code); // fetching all payment country's state supported
      }

      $scope.redirectToOnboardingAccountForm = function () {
        $scope.showLoader();

        operatorProfileService.getStripeAccountLinks($scope.user.token)
          .then(
            function(response) {
              if (response.data && response.data.data && response.data.data.url) {
                $window.location.href = response.data.data.url;
              } else {
                $scope.showPopup('#serverErrModal');
              }
            },
            function() {
              $scope.showPopup('#serverErrModal');
            })
          .finally(function() {
            $scope.hideLoader();
          });
      }

      // convert DOB object into formatted string
      function getOperatorDobStringFromObject(obj) {
        return obj.month + '/' + obj.day + '/' + obj.year;
      }

      // Set Operator Stripe Connect Bank Account Details
      function setOperatorBankDetails(bank_account) {
        $scope.changeCurrency(bank_account.country, function () {
          if (!$scope.isEuropean) {
            $scope.operatorPaymentDetail.account_holder_name = bank_account.account_holder_name;
            $scope.operatorPaymentDetail.account_holder_type = bank_account.account_holder_type;
            if (bank_account.country === 'CA' || bank_account.country === 'JP') { // separate transit and institution number...
              var routing_number = bank_account.routing_number.split('-');
              $scope.operatorPaymentDetail.transit_number = routing_number[CONST_HELPER.ZERO];
              $scope.operatorPaymentDetail.institution_number = routing_number[CONST_HELPER.ONE];
              $scope.operatorPaymentDetail.routing_number = bank_account.routing_number;
            } else {
              $scope.operatorPaymentDetail.routing_number = bank_account.routing_number;
            }
          }
          $scope.operatorPaymentDetail.bank_country_code = bank_account.country;
          $scope.operatorPaymentDetail.currency = bank_account.currency.toUpperCase();

          // Masking routing number and account number fields
          if (
            angular.isString($scope.operatorPaymentDetail.routing_number)
            && ($scope.operatorPaymentDetail.routing_number.length > 0)
          ) {
            $scope.operatorPaymentDetail.routing_number = '*'.repeat($scope.operatorPaymentDetail.routing_number.length);
          }

          if (bank_account.last4) {
            $scope.operatorPaymentDetail.account_number = '********' + bank_account.last4;
          }
        });
      }

      $scope.clearRoutingAndAccountNumberFields = function () {
        if ($scope.operatorProfileForm.account_number.$pristine) {
          $scope.operatorPaymentDetail.account_number = '';
        }
        if ($scope.operatorProfileForm.routing_number.$pristine) {
          $scope.operatorPaymentDetail.routing_number = '';
        }

        $scope.operatorProfileForm.account_holder_name.$setDirty();
      };

      /* get supported currency list for stripe connect account payment */
      function getCurrencyList() {
        operatorProfileService.getCurrencies().then(function (response) { // fetch from the service
          if (response) {
            $scope.currencyList = response.data.data; // add to scope
            $scope.accountSettings.payoutDetails.flywire.currencies = $scope.currencyList;
          }
        }, function () { // check for error
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      }

      $scope.gotoDashboard = function () {
        $state.go('operator-get-started');
      };

      $scope.setAddLocation = function (val) {
        $scope.isAddLocation = val;
        $scope.isUniqeLocationName = val ? true : $scope.isUniqeLocationName;
        $scope.validation.operatorLocation.name = false;
        $scope.validation.operatorLocation.op_location_airports = false;
        $scope.validation.operatorLocation.lodgings = false;
        if (val) {
          if ($scope.operatorProfileForm) {
            $scope.name_new = '';
            $scope.operatorProfileForm.$setPristine();
            $scope.operatorProfileForm.$setUntouched();
            for (var key in $scope.validation.operatorLocation) {
              $scope.validation.operatorLocation[key] = false;
            }
          }
        } else {
          $scope.name_new = $scope.allLocations[CONST_HELPER.ZERO].name;
        }
        $scope.currentLocation = val ? {
          latitude: CONST_HELPER.default_location.latitude,
          longitude: CONST_HELPER.default_location.longitude,
          address: CONST_HELPER.default_location.address
        } : $scope.allLocations[CONST_HELPER.ZERO];
        resetMapToNewLocation($scope.currentLocation);
        $scope.location_medias = val ? {
          medias: [],
          uploaded: [],
          id: $scope.getTempId(),
          lodgingFormInvalid: false
        } : {
          medias: $scope.currentLocation.op_location_medias,
          uploaded: [],
          id: $scope.getTempId(),
          lodgingFormInvalid: false
        };
        $scope.tempLodgings = $scope.deletedLodgings;
        $scope.deletedLodgings = val ? [] : $scope.tempLodgings;
        $scope.tempData = $scope.newRoom;
        $scope.newRoom = val ? {
          op_lodging_medias: [],
          lodgingUploadImage: [],
          id: $scope.getTempId(),
          lodgingFormInvalid: false
        } : $scope.tempData;
        $scope.currentLocation.temp_id = val ? $scope.getTempId() : $scope.currentLocation.temp_id;
        $scope.video = '';
        $scope.checkValidLocationName();
      };

      /* get operator locations */
      $scope.getOperatorLocations = function (isdelete) {
        operatorProfileService.getOperatorLocations({
          token: $scope.user.token
        }).then(function (response) { // fetch from the service
          $scope.locationLoaded = true;
          if (response && response.data.code === CONST_HELPER.API_RESPONSE.OK || response.data.code === CONST_HELPER.API_RESPONSE.NO_CONTENT) {
            // get index of update location
            $scope.allLocations = response.data.data; // add to scope
            if (!$scope.redirectToAddLocation || $rootScope.redirectToAddAndViewLocation) {
              $scope.isAddLocation = !($scope.allLocations && $scope.allLocations.length > CONST_HELPER.ZERO);
              var index = CONST_HELPER.ZERO;
              if (!isdelete) {
                index = $scope.allLocations.findIndex(function (x) {
                  return x.id === $scope.currentLocation.id;
                });
                if (index >= CONST_HELPER.ZERO) {
                  $scope.currentLocation = $scope.allLocations[index];
                }
              } else {
                $scope.currentLocation = $scope.allLocations[index];
              }
              if ($scope.currentLocation && !$scope.currentLocation.id) {
                $scope.currentLocation = $scope.allLocations && $scope.allLocations.length > CONST_HELPER.ZERO ? $scope.allLocations[CONST_HELPER.ZERO] : $scope.currentLocation;
              }
              $scope.name_new = $scope.currentLocation.name;
              if (!$scope.currentLocation) {
                $scope.currentLocation = {
                  latitude: CONST_HELPER.default_location.latitude,
                  longitude: CONST_HELPER.default_location.longitude,
                  address: CONST_HELPER.default_location.address,
                  op_location_videos: [],
                  lodgings: [],
                  temp_id: $scope.getTempId(),
                  name: ''
                };
                $scope.video = '';
                $scope.deletedLodgings = [];
              }
              $scope.currentLocation.op_location_airports = $scope.currentLocation && $scope.currentLocation.op_location_airports && $scope.currentLocation.op_location_airports.length > CONST_HELPER.ZERO ? $scope.currentLocation.op_location_airports : null;
              resetMapToNewLocation($scope.currentLocation);
              if ($scope.currentLocation.op_location_medias && $scope.currentLocation.op_location_medias.length) {
                $scope.location_medias.medias = $scope.currentLocation.op_location_medias.filter(function (media) {
                  return media.media_type == CONST_HELPER.ZERO;
                });
              }
            }
          }
        }, function () { // check for error
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };

      $scope.updateLocation = function (selected) {
        $scope.currentLocation = selected;
        $scope.name_new = $scope.currentLocation.name;
        $scope.location_medias.medias = selected.op_location_medias;
        $scope.location_medias = {
          medias: selected.op_location_medias,
          uploaded: [],
          id: $scope.getTempId(),
          lodgingFormInvalid: false
        };
        $scope.video = '';
        $scope.currentLocation.temp_id = $scope.getTempId();
        $scope.deletedLodgings = [];
        // Need to update location airports to prevent log error in case of null value.
        $scope.currentLocation.op_location_airports = $scope.currentLocation.op_location_airports && $scope.currentLocation.op_location_airports.length > CONST_HELPER.ZERO ? $scope.currentLocation.op_location_airports : null;
        resetMapToNewLocation($scope.currentLocation);
      };

      function resetAirportsLocation(obj) {
        var airports = obj && obj.op_location_airports && obj.op_location_airports.length > CONST_HELPER.ZERO ? obj.op_location_airports : null;
        obj.op_location_airports = airports;
        obj && obj.op_location_airports ? obj.op_location_airports.forEach(function (airport, index) {
          $scope.airports.find(function (gair) {
            if (gair.name == airport.name) {
              obj.op_location_airports[index] = gair;
            }
          });
        }) : null;
      }

      function resetMapToNewLocation(obj) {
        NgMap.getMap().then(function (map) {
          var bounds = new google.maps.LatLngBounds();
          var latlng = new google.maps.LatLng(obj.latitude, obj.longitude);
          bounds.extend(latlng);
          map.setCenter(bounds.getCenter());
          $scope.marker = {
            'lat': obj.latitude,
            'lng': obj.longitude
          };
          map.fitBounds(bounds);
          map.setZoom(12);
        });
      }

      // This method is used to update only Bank Details of Operator Stripe Connect Account
      $scope.updatePaymentDetails = function () {
        validateOperatorAccountDetails(); // check if details exist
        var isValid = validateAllKeys();
        if (isValid) {
          var obj = angular.copy($scope.operatorPaymentDetail, obj);
          var requestObj = {};
          for (var key in $scope.validation.operatorPaymentDetail) {
            if ($scope.operatorProfileForm[key].$dirty) {
              requestObj[key] = obj[key];
            }

            delete obj[key];
          }

          if (angular.equals(requestObj, {})) {
            return false;
          }

          $scope.showLoader();
          var data = {
            id: requestObj,
            token: $scope.user.token
          };
          operatorProfileService.stripeUpdateDetail(data).then(function (res) {
            $scope.bankAccountErr = false;
            if (res.data.code === CONST_HELPER.API_RESPONSE.OK) {
              $scope.hideLoader();
              $scope.validation.operatorPaymentDetail = {};
              $scope.$parent.successMsg = 'Your bank details have been updated successfully.';
              $scope.showPopup('#saveDetailModal'); // show success modal
              $scope.accountSettings.payoutDetails.stripe.setAccount(res.data.data);
              if ($scope.accountSettings.payoutDetails.stripe.account) {
                setOperatorBankDetails(res.data.data.external_accounts.data[CONST_HELPER.ZERO]);
                $rootScope.user.is_bank_details_completed = true;
              }
              $scope.setUserDetails($rootScope.user);
              $timeout(angular.noop);
              $scope.hideLoader();
            } else {
              $scope.hideLoader();
              $scope.bankAccountErr = true;
              $scope.bankAccountErrMsg = res.data.message;
            }
          }, function (error) {
            $scope.hideLoader();
            $scope.bankAccountErr = true;
            $scope.bankAccountErrMsg = error.data.message;
          });
        }
      };

      $scope.accountHolderTypeError = false;
      $scope.paymentCountryCodeError = false;
      $scope.operatorPaymentDetailFirstNameError = false;
      $scope.operatorPaymentDetailLastNameError = false;
      $scope.operatorPaymentDetailCompanyNameError = false;


      if($state.params.stripe_status == "success"){
        $scope.redirectToPaymentDetail = true;
      }else if($state.params.stripe_status == "fail"){
        $scope.redirectToPaymentDetail = true;
        $scope.showPopup('#connectStripeAccountDenied');
      }

      $scope.connectStripeAccountOperatorProfile = function(){
        $scope.showLoader();

        if(!$scope.operatorPaymentDetail.account_holder_type){
          $scope.accountHolderTypeError = true;
        }else{
          $scope.accountHolderTypeError = false;
        }

        if(!$scope.operatorPaymentDetail.payment_country_code){
          $scope.paymentCountryCodeError = true;
        }else{
          $scope.paymentCountryCodeError = false;
        }

        if($scope.operatorPaymentDetail.account_holder_type != 'company' && !$scope.operatorPaymentDetail.first_name){
          $scope.operatorPaymentDetailFirstNameError = true;
        }else{
          $scope.operatorPaymentDetailFirstNameError = false;
        }

        if($scope.operatorPaymentDetail.account_holder_type != 'company' && !$scope.operatorPaymentDetail.last_name){
          $scope.operatorPaymentDetailLastNameError = true;
        }else{
          $scope.operatorPaymentDetailLastNameError = false;
        }

        if($scope.operatorPaymentDetail.account_holder_type == 'company' && !$scope.operatorPaymentDetail.company_name){
          $scope.operatorPaymentDetailCompanyNameError = true;
        }else{
          $scope.operatorPaymentDetailCompanyNameError = false;
        }

        if($scope.operatorPaymentDetailCompanyNameError ||
          $scope.operatorPaymentDetailLastNameError ||
          $scope.operatorPaymentDetailFirstNameError ||
          $scope.paymentCountryCodeError ||
          $scope.accountHolderTypeError)
        {
          return;
        }else{
          var obj = {
            'type_account': $scope.operatorPaymentDetail.account_holder_type,
            'country': $scope.operatorPaymentDetail.payment_country_code,
            'first_name': $scope.operatorPaymentDetail.first_name ? $scope.operatorPaymentDetail.first_name : '',
            'last_name': $scope.operatorPaymentDetail.last_name ? $scope.operatorPaymentDetail.last_name : '',
            'company_name': $scope.operatorPaymentDetail.company_name ? $scope.operatorPaymentDetail.company_name : ''
          }

        }
        operatorProfileService.connectStripeAccount(obj).then(function (response) {
          if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
            $scope.hideLoader();


          }
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      }

      // save operator payment details
      $scope.savePaymentDetails = function () { // save account details
        var isValid = validateOperatorPaymentForm(); // check if details exist
        if (isValid) {
          $scope.showLoader();

          operatorProfileService
            .saveFlywirePortalCode({
              token: $scope.user.token,
              flywirePortalCode: $scope.operatorPaymentDetail.flywire_portal_code
            })
            .then(function (response) {
              $scope.bankAccountErr = false;

              if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
                getOperatorPaymentDetails();
                $scope.validation.operatorPaymentDetail = {};

                $scope.$parent.successMsg = 'Your Flywire account details have been saved successfully.';
                $scope.showPopup('#saveDetailModal'); // show success modal
                $rootScope.user.is_bank_details_completed = true;
                $scope.setUserDetails($rootScope.user);
                $scope.hideLoader();
                $timeout(angular.noop);
              } else {
                $scope.bankAccountErr = true;
                $scope.bankAccountErrMsg = response.data.message;

                $scope.hideLoader();
              }
            }, function (error) {
              $scope.bankAccountErr = true;
              $scope.bankAccountErrMsg = error.data.message;

              $scope.hideLoader();
            });
        }
      };

      // common payment details submission form for all countries
      function validateOperatorPaymentForm() {
        return $scope.operatorProfileForm.flywire_portal_code && $scope.operatorProfileForm.flywire_portal_code.$valid;
      }

      function validateOperatorPaymentFormForJapan() {
        // Phone number and Gender are two additonal fields required for JAPAN payout account verification
        if ($scope.operatorProfileForm.gender.$invalid || !$scope.operatorProfileForm.gender.$viewValue) {
          $scope.validation.operatorPaymentDetail.gender = true;
        } else {
          $scope.validation.operatorPaymentDetail.gender = false;
        }
        if ($scope.operatorProfileForm.phone_number.$invalid || !$scope.operatorProfileForm.phone_number.$viewValue) {
          $scope.validation.operatorPaymentDetail.phone_number = true;
        } else {
          $scope.validation.operatorPaymentDetail.phone_number = false;
        }
        validateKanaFields();
        validateKanjiFields();
      }

      function validateKanaFields() {
        if ($scope.operatorProfileForm.first_name_kana.$invalid || !$scope.operatorProfileForm.first_name_kana.$viewValue) {
          $scope.validation.operatorPaymentDetail.first_name_kana = true;
        } else {
          $scope.validation.operatorPaymentDetail.first_name_kana = false;
        }
        if ($scope.operatorProfileForm.last_name_kana.$invalid || !$scope.operatorProfileForm.last_name_kana.$viewValue) {
          $scope.validation.operatorPaymentDetail.last_name_kana = true;
        } else {
          $scope.validation.operatorPaymentDetail.last_name_kana = false;
        }
        if ($scope.operatorProfileForm.payment_street_address_kana.$invalid || !$scope.operatorProfileForm.payment_street_address_kana.$viewValue) {
          $scope.validation.operatorPaymentDetail.payment_street_address_kana = true;
        } else {
          $scope.validation.operatorPaymentDetail.payment_street_address_kana = false;
        }
        if ($scope.operatorProfileForm.payment_town_kana.$invalid || !$scope.operatorProfileForm.payment_town_kana.$viewValue) {
          $scope.validation.operatorPaymentDetail.payment_town_kana = true;
        } else {
          $scope.validation.operatorPaymentDetail.payment_town_kana = false;
        }
        if ($scope.operatorProfileForm.payment_city_kana.$invalid || !$scope.operatorProfileForm.payment_city_kana.$viewValue) {
          $scope.validation.operatorPaymentDetail.payment_city_kana = true;
        } else {
          $scope.validation.operatorPaymentDetail.payment_city_kana = false;
        }
        if ($scope.operatorProfileForm.payment_state_kana.$invalid || !$scope.operatorProfileForm.payment_state_kana.$viewValue) {
          $scope.validation.operatorPaymentDetail.payment_state_kana = true;
        } else {
          $scope.validation.operatorPaymentDetail.payment_state_kana = false;
        }
        if ($scope.operatorProfileForm.payment_zipcode_kana.$invalid || !$scope.operatorProfileForm.payment_zipcode_kana.$viewValue) {
          $scope.validation.operatorPaymentDetail.payment_zipcode_kana = true;
        } else {
          $scope.validation.operatorPaymentDetail.payment_zipcode_kana = false;
        }
      }

      function validateKanjiFields() {
        if ($scope.operatorProfileForm.first_name_kanji.$invalid || !$scope.operatorProfileForm.first_name_kanji.$viewValue) {
          $scope.validation.operatorPaymentDetail.first_name_kanji = true;
        } else {
          $scope.validation.operatorPaymentDetail.first_name_kanji = false;
        }
        if ($scope.operatorProfileForm.last_name_kanji.$invalid || !$scope.operatorProfileForm.last_name_kanji.$viewValue) {
          $scope.validation.operatorPaymentDetail.last_name_kanji = true;
        } else {
          $scope.validation.operatorPaymentDetail.last_name_kanji = false;
        }
        if ($scope.operatorProfileForm.payment_street_address_kanji.$invalid || !$scope.operatorProfileForm.payment_street_address_kanji.$viewValue) {
          $scope.validation.operatorPaymentDetail.payment_street_address_kanji = true;
        } else {
          $scope.validation.operatorPaymentDetail.payment_street_address_kanji = false;
        }
        if ($scope.operatorProfileForm.payment_town_kanji.$invalid || !$scope.operatorProfileForm.payment_town_kanji.$viewValue) {
          $scope.validation.operatorPaymentDetail.payment_town_kanji = true;
        } else {
          $scope.validation.operatorPaymentDetail.payment_town_kanji = false;
        }
        if ($scope.operatorProfileForm.payment_city_kanji.$invalid || !$scope.operatorProfileForm.payment_city_kanji.$viewValue) {
          $scope.validation.operatorPaymentDetail.payment_city_kanji = true;
        } else {
          $scope.validation.operatorPaymentDetail.payment_city_kanji = false;
        }
        if ($scope.operatorProfileForm.payment_state_kanji.$invalid || !$scope.operatorProfileForm.payment_state_kanji.$viewValue) {
          $scope.validation.operatorPaymentDetail.payment_state_kanji = true;
        } else {
          $scope.validation.operatorPaymentDetail.payment_state_kanji = false;
        }
        if ($scope.operatorProfileForm.payment_zipcode_kanji.$invalid || !$scope.operatorProfileForm.payment_zipcode_kanji.$viewValue) {
          $scope.validation.operatorPaymentDetail.payment_zipcode_kanji = true;
        } else {
          $scope.validation.operatorPaymentDetail.payment_zipcode_kanji = false;
        }
      }

      function validateOperatorAccountDetails() {
        if ($scope.accountSettings.payoutDetails.stripe.account) {
          if (!$scope.isEuropean) {
            if ($scope.operatorProfileForm.account_holder_name.$invalid || !$scope.operatorProfileForm.account_holder_name.$viewValue) {
              $scope.validation.operatorPaymentDetail.account_holder_name = true;
            } else {
              $scope.validation.operatorPaymentDetail.account_holder_name = false;
            }

            $scope.validation.operatorPaymentDetail.account_holder_type = false;

            if ($scope.operatorPaymentDetail.bank_country_code !== 'CA' && $scope.operatorPaymentDetail.bank_country_code !== 'JP') {
              if ($scope.operatorProfileForm.routing_number.$invalid || !$scope.operatorProfileForm.routing_number.$viewValue) {
                $scope.validation.operatorPaymentDetail.routing_number = true;
              } else {
                $scope.validation.operatorPaymentDetail.routing_number = false;
                $scope.operatorPaymentDetail.routing_number = $scope.operatorProfileForm.routing_number.$viewValue;
              }
            } else { // separate transit and institution number...
              if ($scope.operatorProfileForm.transit_number.$invalid || !$scope.operatorProfileForm.transit_number.$viewValue) {
                $scope.validation.operatorPaymentDetail.transit_number = true;
              } else {
                $scope.validation.operatorPaymentDetail.transit_number = false;
                $scope.operatorPaymentDetail.transit_number = $scope.operatorProfileForm.transit_number.$viewValue;
              }
              if ($scope.operatorProfileForm.institution_number.$invalid || !$scope.operatorProfileForm.institution_number.$viewValue) {
                $scope.validation.operatorPaymentDetail.institution_number = true;
              } else {
                $scope.validation.operatorPaymentDetail.institution_number = false;
                $scope.operatorPaymentDetail.institution_number = $scope.operatorProfileForm.institution_number.$viewValue;
              }
            }
          }

          if ($scope.operatorProfileForm.account_number.$invalid || !$scope.operatorProfileForm.account_number.$viewValue) {
            $scope.validation.operatorPaymentDetail.account_number = true;
          } else {
            $scope.validation.operatorPaymentDetail.account_number = false;
            $scope.operatorPaymentDetail.account_number = $scope.operatorProfileForm.account_number.$viewValue;
          }

          $scope.validation.operatorPaymentDetail.currency = false;
          $scope.validation.operatorPaymentDetail.bank_country_code = false;
        }
      }

      function validateAllKeys() {
        for (var key in $scope.validation.operatorPaymentDetail) { // cehck if any key has errors
          if ($scope.validation.operatorPaymentDetail[key]) { // if yes
            return false; // prevent next click
          }
        }
        return true;
      }
      // get operator profile country states
      function getStates(value) { // get all states with reference to country id
        operatorProfileService.getStates(value).then(function (response) {
          if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
            $scope.states = response.data.data;
          }
        }, function () {});
      }
      // get operator bank account payment states by country
      function getPaymentStates(country_id) { // get all states with reference to country id
        if (isNaN(country_id)) {
          country_id = $scope.paymentCountries.find(function (country) {
            return country.code === country_id;
          });
          if (country_id) {
            country_id = country_id.id;
          }
        }
        operatorProfileService.getStates(country_id).then(function (response) {
          if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
            $scope.paymentStates = response.data.data;
          }
          $scope.hideLoader();
        }, function () {
          $scope.hideLoader();
        });
      }
      // operator verification document upload in payment details
      $scope.verificationDocUpload = function (evt) {
        $scope.validation.operatorPaymentDetail.verification_document = false;
        var file = evt.currentTarget.files[CONST_HELPER.ZERO];
        var fileExtnArr = file.name.split('.');
        var extn = fileExtnArr[fileExtnArr.length - CONST_HELPER.ONE].toLowerCase();
        if (extn === 'png' || extn === 'jpeg' || extn === 'jpg') { // check valid file extention
          var reader = new FileReader();
          reader.onload = function (evt) {
            $scope.$apply(function ($scope) {
              $scope.operatorPaymentDetail.verification_document = evt.currentTarget.result;
              $timeout(angular.noop);
            });
          };
          reader.readAsDataURL(file);
        } else {
          $scope.validation.operatorPaymentDetail.verification_document = true;
          $timeout(angular.noop);
          return false;
        }
      };
      // callled when operator selects or change image in payment details
      angular.element(document.querySelector('#verification_document')).on('change', $scope.verificationDocUpload);
      angular.element(document.querySelector('#change_verification_document')).on('change', $scope.verificationDocUpload);

      $scope.depositCheckbox = function (checked) {
        if (checked) {
          $scope.operatorPolicy.allow_refund_balance = CONST_HELPER.ONE;
        }
      };

      // merge the transit and institution number to get routing number...
      $scope.mergeRoutingNumber = function () {
        $scope.operatorPaymentDetail.routing_number = parseInt($scope.operatorPaymentDetail.transit_number + '' + $scope.operatorPaymentDetail.institution_number);
      };

      $scope.zeroTaxName = function () {
        if ($scope.operatorPolicy.international_percent === CONST_HELPER.ZERO && $scope.operatorPolicy.domestic_percent === CONST_HELPER.ZERO) {
          $scope.operatorPolicy.tax_name = 'Tax';
        }
      };

      $scope.resetPaymentDetails = function () {
        var countryCode = $scope.operatorPaymentDetail.payment_country_code;
        $scope.operatorPaymentDetail = {};
        $scope.operatorPaymentDetail.payment_country_code = countryCode;
      };
      // merge account_number in one for new zealand...
      $scope.addAccountNumber = function () {
        var acc1 = $scope.operatorPaymentDetail.account_number1 || '';
        var acc2 = $scope.operatorPaymentDetail.account_number2 || '';
        var acc3 = $scope.operatorPaymentDetail.account_number3 || '';
        var acc4 = $scope.operatorPaymentDetail.account_number4 || '';
        $scope.operatorPaymentDetail.account_number = acc1 + '' + acc2 + '' + acc3 + '' + acc4;
      };
      // merge sort code in one...
      $scope.addSortCode = function () {
        var sort1 = $scope.operatorPaymentDetail.sort_code1 || '';
        var sort2 = $scope.operatorPaymentDetail.sort_code2 || '';
        var sort3 = $scope.operatorPaymentDetail.sort_code3 || '';

        $scope.operatorPaymentDetail.sort_code = sort1 + '' + sort2 + '' + sort3;
      };
      // connect to instagram...
      $scope.connectInstagram = function () {
        instagramServices.authenticate(function (accessToken) {
          if (accessToken.length > CONST_HELPER.ZERO) {
            // get the user instagram profile...
            $scope.showLoader();
            instagramServices.getInstaProfile(accessToken, function (data) {
              if (data.hasOwnProperty('username')) {
                $scope.instaProfile = data;
                var dataObj = {
                  'instagram_username': data.username,
                  'instagram_access_token': accessToken
                };
                userProfileService.connectInstagram(dataObj, $scope.user.token).then(function (res) {
                  $scope.operatorSocialAccounts = res.data.data.social_accounts; // update consumer profile data
                  $scope.hideLoader();
                });
              } else {
                $scope.hideLoader();
                $scope.showPopup('#serverErrModal');
              }
            });
          }
        });
      };
      // disconnect user instagram account.
      $scope.disconnectInstagram = function () {
        $scope.showLoader();
        var data = {
          'instagram_username': '',
          'instagram_access_token': ''
        };
        userProfileService.connectInstagram(data, $scope.user.token).then(function (res) {
          $scope.operatorSocialAccounts = res.data.data.social_accounts; // update operator social profile data
          $scope.hideLoader();
        });
      };

      if ($scope.redirectToAddLocation && !$rootScope.redirectToAddAndViewLocation) {
        $scope.setAddLocation(true);
      }

      $('[data-toggle="tooltip"]').tooltip();
      // Hide the caret sign of select country dropdown from payout
      function hideCaret() {
        if ($scope.showProfileDetails) {
          $('#payment_country_code').find('.caret').addClass('caret-hide');
        }
      }

      $scope.$on("$destroy", function () {
        $rootScope.redirectToAddLocation = false;
        $scope.redirectToAddLocation = false;
      });
    }
  ]);
