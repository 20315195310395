import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';
/**
 * @ngdoc service
 * @name AgentService
 * @description
 * # agentService
 * Service in the heliApp.
 */

@Injectable()

export class AgentService {
	
	private REQUEST_URL;

	constructor(@Inject(WebService) private webService: WebService) {
		this.REQUEST_URL = environment.REQUEST_URL;
	}

	sendReq(method, param, token) {
		return new Promise((resolve, reject) => { /* asynchronously complete request */
			method(param, token).then((response) => {
				resolve(response);
			}, (error) => {
				reject(error);
			});
		});
	}

	postReq(url, data, token) {
		let req = {
			method: 'POST',
			url: this.REQUEST_URL + url,
			data: data,
			headers: {
				'token': token
			}
		};
		return req;
	}

	getReq(url, token) {
		let req = {
			method: 'GET',
			url: this.REQUEST_URL + url,
			headers: {
				'token': token
			}
		};
		return req;
	}

	deleteReq(url, data, token) {
		let req = {
			method: 'DELETE',
			url: this.REQUEST_URL + url,
			data: data,
			headers: {
				'token': token
			}
		};
		return req;
	}

	/**
	 * @ngdoc method
	 * @name agentSignUpRequest
	 *
	 * @description
	 * This method call API to get operator package generated code list for White Label
	 **/
	agentSignUpRequest(data, token) {
		let req = this.postReq('agents/sign-up-request', data, token);
		return this.webService.httpReq(req);
	}
	resendInvite(data, token) {
		let req = this.postReq('agents/sign-up-request/' + data.agent_id, data, token);
		return this.webService.httpReq(req);
	}
	validateAgentToken(data, token) {
		let req = this.postReq('agents/validate-token', data, token);
		return this.webService.httpReq(req);
	}
	completeAgentSignup(data, token) {
		let req = this.postReq('agents/complete-your-account', data, token);
		return this.webService.httpReq(req);
	}
	changeOperatorRequestStatus(data, token) {
		let req = this.postReq('agents/accept-reject-cancel', data, token);
		return this.webService.httpReq(req);
	}
	allOperators(userData, token) { // Req To get Operator Listing
		let url = 'agents/all-operators?limit=' + userData.limit + '&page=' + userData.page + '&search=' + userData.search;
		let req = this.getReq(url, token);
		return this.webService.httpReq(req);
	}
	getAgentOperators(data, token) {
		let url = 'agents/operator-invitation/' + data.status;
		let req = this.getReq(url, token);
		return this.webService.httpReq(req);
	}
	getAgentOperatorPackages(data) {
		let url = 'agents/all-adventures?status=' + data.status + '&limit=' + data.limit + '&page=' + data.page + '&back=' + data.back + '&op_location_id=' + data.location_id + '&type=' + data.type + '' + '&operator_id=' + data.operator_id + '';
		let req = this.getReq(url, data.token);
		return this.webService.httpReq(req);
	}

}

angular.module('heliApp').service('agentService', downgradeInjectable(AgentService));