/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable handle-callback-err */
"use strict";

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:userRadarCtrl
 * @description
 * # userRadarCtrl manage the user radar
 * Controller of the heliApp
 */

angular
  .module("heliApp")
  .controller("userRadarCtrl", [
    "$scope",
    "userAdventureDetailService",
    "$stateParams",
    "$rootScope",
    "$location",
    "sharedService",
    "MESSAGE_HELPER",
    "$state",
    "rootScopeService",
    "CONST_HELPER",
    userRadarCtrl,
  ]);

function userRadarCtrl(
  $scope,
  userAdventureDetailService,
  $stateParams,
  $rootScope,
  $location,
  sharedService,
  MESSAGE_HELPER,
  $state,
  rootScopeService
) {
  var vm = this;
  $scope.userRadarList = [];
  $scope.pageLoaded = false;
  $scope.MESSAGE_HELPER = MESSAGE_HELPER;
  // hide search icon
  // angular.element(document.getElementsByClassName("only-tablet"))[0].style.display = "none";

  if ($state.current.name === "consumer-radar") {
    var userRadar = {
      // my own radar list
      limit: 12,
      page: $state.params.page ? $state.params.page : 1,
      back: $state.params.back ? $state.params.back : 0,
      username: $scope.user.username,
      // token:$scope.user.token
    };
  } else {
    // other user's radar list
    var userRadar = {
      limit: 12,
      page: 1,
      back: 0,
      username: $scope.$parent.username,
    };
  }

  if (
    $rootScope.previousState &&
    $rootScope.previousState.name === "adventure-detail" &&
    $rootScope.currentState.name !== "myprofile"
  ) {
    // back not from user feed
    $state.params.back = 1;
    $location.search($state.params);
  }

  function getUserRadarList() {
    $scope.showLoader();
    userAdventureDetailService.userRadarList(userRadar).then(
      function (res) {
        if (res.data.code === "200") {
          $scope.userRadarList = $scope.userRadarList.concat(res.data.data);
          vm.resultFound = res.data.numFound;
          $scope.canloadmore = couldLoadMore(
            $scope.userRadarList,
            vm.resultFound
          );
          getFeaturedImage($scope.userRadarList);
          $scope.checkExpiredSlots($scope.userRadarList); // check for expired slots...
          $scope.pageLoaded = true;
        } else if (res.data.code === "203") {
          $scope.logout(); // unauthorised user
          $state.go("signin");
        }
        $scope.hideLoader();
        $scope.isRadarPage = window.location.pathname === '/radar';
        $scope.isAddedByUser = rootScopeService.rootScope.user.id == $scope.userRadarList[0].user_id;
      },
      function (error) {
        $scope.hideLoader();
      }
    );
  }
  getUserRadarList(); // get user radar list

  // check wheather  can loadmore adventure or not
  function couldLoadMore(adventure, resultFound) {
    if (adventure.length < resultFound) {
      return true;
    }
  }

  vm.loadMore = function () {
    userRadar.page = parseInt(userRadar.page) + 1;
    userRadar.back = 0;
    getUserRadarList();
  };

  /**
        //  function to get featured image of pacakages
        **/
  function getFeaturedImage(radarList) {
    radarList.map(function (obj) {
      if (obj && obj.package_medias) {
        obj.package_medias.map(function (image) {
          if (image && image.media_type === 0 && image.is_main === 1) {
            obj.featuredImg = image.url;
          }
        });
        if (!obj.featuredImg) {
          obj.featuredImg = "images/img2.png";
        }
      }
    });
  }

  $scope.redirectToShared = function (adventure) {
    window.location.href = adventure.package.short_url;
  }


  // remove from user radar list
  $scope.removeFromRadar = function (adventure) {
    var adventurePackage = {
      package_id: adventure.package_id,
      userToken: $scope.user.token,
    };
    $scope.showLoader();
    userAdventureDetailService.removeFromUserRadar(adventurePackage).then(
      function (res) {
        var i = $scope.userRadarList.indexOf(adventure);
        if (i !== -1) {
          $scope.userRadarList.splice(i, 1);
        }
        $scope.pageLoaded = true;
        $scope.hideLoader();
      },
      function (error) {
        $scope.hideLoader();
        $scope.showPopup("#serverErrModal");
      }
    );
  };

  // view all slots and cart
  $scope.viewAllSlots_Cart = function (event, pacakage) {
    vm.adventure = pacakage;
    vm.adventure.selected_slot = false;
    previousSlot = "";
    $scope.showPopup("#add-cart");
    event.stopPropagation();
  };

  // select slot...
  var previousSlot;
  vm.selectedSlots = {};
  vm.selectSlots = function (model, slot) {
    if (previousSlot === model) {
      vm.adventure.selected_slot = false;
      vm.selectedSlots = {};
      previousSlot = "";
    } else {
      previousSlot = model;
      vm.selectedSlots = slot;
    }
  };
  vm.proceed = function (slot_id) {
    $scope.hidePopup("#add-cart");
    var bodyElement = angular.element(document.getElementsByTagName("body"));
    bodyElement[0].className = bodyElement[0].className.replace(
      /\bmodal-open\b/,
      ""
    );
    if ($scope.userProfile && $scope.userProfile.role_id === 4) {
      // if influencer profile
      slot_id.referral_id = $scope.userProfile.referral_id;
    }
    $state.go("package-slot-addons-summary", slot_id);
  };

  function fbLogin(url, msg, hash) {
    FB.login(
      function (res) {
        if (res.status === "connected") {
          // obj.accessToken = res.authResponse.accessToken;
          // userAdventureDetailService.getFBAccessToken().then(function(res){
          // $scope.fbAccessToken = res.token;
          // })
          fbPost(url, msg, hash);
        }
      },
      {
        scope: "public_profile,manage_pages,publish_pages,email,user_posts",
        return_scopes: true,
      }
    );
  }

  function fbPost(url, msg, hash) {
    FB.ui(
      {
        method: "share",
        href: url || "www.googl",
        quote: msg,
      },
      function (response) {
        if (response && !response.error_message) {
          $scope.successMsg = MESSAGE_HELPER.sharing.FACEBOOK_POST_SUCESSFULLY;
          rootScopeService.setValue("successMsg", $scope.successMsg);
          angular.element(document.getElementById("sucess")).modal("show");
        } else {
          $scope.successMsg = MESSAGE_HELPER.sharing.FACEBOOK_ERROR_SUBMITTING;
          rootScopeService.setValue("successMsg", $scope.successMsg);
        }
      }
    );
  }
}
