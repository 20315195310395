/* eslint-disable camelcase */
/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:operatorPayoutCtrl
 * @description
 * # operatorPayoutCtrl
 * Controller of the heliApp
 */
angular.module('heliApp').controller(
  'operatorPayoutCtrl',
  [
    '$q',
    '$scope',
    '$state',
    '$rootScope',
    '$stateParams',
    '$filter',
    'CONST_HELPER',
    'operatorPayoutService',
    'operatorViewBookingService',
    'operatorCalendarService',
    'binaryService',
    function ($q, $scope, $state, $rootScope, $stateParams, $filter, CONST_HELPER, operatorPayoutService, operatorViewBookingService, operatorCalendarService, binaryService) {
      var payoutId = $stateParams.payment_detail_id;
      var paymentNo = $stateParams.payment_no;
      // eslint-disable-next-line no-unused-vars
      var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      $scope.bookingFilter = ['All Booking Sources', 'Heli', 'My Site', '', 'Manual'];
      $scope.payout = {};
      $scope.maxSize = 5;
      $scope.payoutHistoryList = [];
      $scope.payout.currentPage = 1;
      $scope.payoutHistoryDetail = {};
      $scope.getPayoutHistoryCsvList = [];
      $scope.toDate = moment().format('YYYY-M-DD');
      $scope.fromDate = moment().subtract(365, 'days').format('YYYY-M-DD');
      $scope.isFromToCalendar = false; // whether to show specific From and To
      $scope.displaySpecificTo = moment().format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
      $scope.displaySpecificFrom = moment().subtract(29, 'days').format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
      $scope.isSpecificFromSelected = false; // to check the first select on calendar should be for specific From , then second click on calendar will be for Specific To
      $rootScope.isCustomCalendarFilter = false; // whether to show Complete custom filter dropdown$rootScope.isCustomCalendarFilter = false; //whether to show Complete custom filter dropdown
      $scope.operatorCurrencySelected = true; // checks which currency is selected.
      var packageIds = [];
      $scope.filter_date_type = $stateParams.filter_date_type ? $stateParams.filter_date_type : 0;
      $scope.sortValue = $state.params.sort_value ? $state.params.sort_value : 'created';
      $scope.reverse = $state.params.reverse ? $state.params.reverse : 'true';
      $scope.applySort = function (sortKey, sortOrder) {
        $scope.sortValue = sortKey;
        $scope.reverse = sortOrder;
        var toDate = $state.params.to_date ? $state.params.to_date : $scope.toDate;
        var fromDate = $state.params.from_date ? $state.params.from_date : $scope.fromDate;
        $state.go('operator-payout-history', ({ sort_value: $scope.sortValue, reverse: $scope.reverse, page: $scope.payout.currentPage, to_date: toDate, from_date: fromDate, 'filter_date_type' : $scope.filter_date_type }));
      };
      $scope.getAllCountryName = function () {
        operatorPayoutService.getAllCountry($scope.user.token).then(function (data) {
          if (data.data.code === CONST_HELPER.API_RESPONSE.OK) {
            $scope.allcountryName = data.data.data;
          }
        });
      };
      $scope.getAllCountryName();
      $scope.getPayoutHistoryList = function (type) { // get payout history list on page load
        $scope.homeRedirection();// check for authorised access
        if ((!$scope.user) || ($scope.user && !$scope.user.token)) { // check for user logged in status
          $state.go('signin');
          return false;
        }
        $scope.showLoader();

        if (type === 'firstLoad') {
          $scope.toDate = $state.params.to_date ? $state.params.to_date : moment().format('YYYY-M-DD');
          $scope.fromDate = $state.params.from_date ? $state.params.from_date : moment().subtract(365, 'days').format('YYYY-M-DD');
          $scope.payout.currentPage = $state.params.page ? $state.params.page : 1;
          $scope.displaySpecificTo = moment($scope.toDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
          $scope.displaySpecificFrom = moment($scope.fromDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
          $scope.sortValue = $state.params.sort_value ? $state.params.sort_value : 'created';
          $scope.reverse = $state.params.reverse ? $state.params.reverse : 'true';
        }

        var requestObject = {
          'page': $scope.payout.currentPage ? $scope.payout.currentPage : $state.params.page,
          'to_date': $state.params.to_date ? $state.params.to_date : $scope.toDate,
          'from_date': $state.params.from_date ? $state.params.from_date : $scope.fromDate,
          'package_ids': packageIds,
          'sort': $scope.sortValue,
          'sort_type': $scope.reverse == 'false' ? 'ASC' : 'DESC',
          'filter_date_type' : $scope.filter_date_type
        };

        operatorPayoutService.getPayoutHistoryWithStatus(requestObject, $scope.user.token).then(function (res) { // hit service to get payout history
          if (res.data.code === '200') {
            $scope.payoutHistoryList = res.data.data;
            $scope.payoutHistoryList.forEach(function(eachPayout){
              if (eachPayout.package_currency_code == 'USD' && eachPayout.package_cc_rate > 1) {
                eachPayout.package_cc_rate = 1;
              }
            })
            $scope.totalViewItems = res.data.total; // set pagination count
            $scope.hideLoader();
          } else if (res.data.code === '401') {
            $scope.logout();
          } else {
            $scope.hideLoader();
          }
        }, function () { // check for error
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };
      $scope.returnInteger = function (argument) {
        return parseFloat(argument);
      }
      $scope.totalManualPaidAmount = 0;
      $scope.totalManualRefudedAmount = 0;
      // get Total Manual Paid Amount...
      $scope.getTotalPaidAmount = function (manualPayout) {
        if (manualPayout.type === 1 && manualPayout.is_deleted !== 1) {
          $scope.totalManualPaidAmount = $scope.totalManualPaidAmount + manualPayout.amount;
        } else if (manualPayout.type === 2 && manualPayout.is_deleted !== 1) {
          $scope.totalManualRefudedAmount = $scope.totalManualRefudedAmount + manualPayout.amount;
        }
      };
      function getOperatorPackages () {
        var data = {
          'status': 1,
          'token': $scope.user.token
        };

        operatorCalendarService.getOperatorPackages(data).then(function (res) {
          if (res && res.data.response) {
            packageIds = [];
            $scope.packageList = res.data.response.docs;

            $scope.packageList.map(function (pkgobj) {
              pkgobj.selected = false;
            });
          } else {
            logoutCheck(res.data.code);
          }
        });
      }
      function logoutCheck (code) {
        if (code === '401') {
          $scope.logout();
        }
      }
      $scope.toggleAll = function (filteredArray) {
        var toggleStatus = $scope.isAllSelected;
        packageIds = [];
        angular.forEach(filteredArray, function (itm) {
          itm.selected = toggleStatus;
          if (toggleStatus) {
            packageIds.push(itm.package_id);
          }
        });
        $scope.getPayoutHistoryList();
      };
      $scope.checkAllSelected = function () {
        $scope.isAllSelected = $scope.packageList.every(function (itm) { return itm.selected; });
      };

      $scope.resetFilter = function (event) {
        event.stopPropagation();
        $scope.packageSearch = '';
        $timeout(function () {
          $('#package-filter').focus();
        });
        $scope.checkAllSelected();
      };

      $scope.optionToggled = function (obj) {
        $scope.isAllSelected = $scope.packageList.every(function (itm) { return itm.selected; });
        if (obj.selected) {
          packageIds.push(obj.package_id);
        } else {
          packageIds.splice(packageIds.indexOf(obj.package_id), 1);
        }
        $scope.getPayoutHistoryList();
        /* filter slot data according to packages selected */
        // getEvents is a custom function that fetch events...
      };
      getOperatorPackages();
      $scope.pageChange = function (page) { // set pagination
        var toDate = $state.params.to_date ? $state.params.to_date : $scope.toDate;
        var fromDate = $state.params.from_date ? $state.params.from_date : $scope.fromDate;
        $state.go('operator-payout-history', ({ sort_value: $scope.sortValue, reverse: $scope.reverse, page: page, to_date: toDate, from_date: fromDate, 'filter_date_type' : $scope.filter_date_type }));
      };

      $scope.paymentStatus = {
        1: 'Full Payment',
        2: 'Deposit',
        3: 'Cancelled'
      };
      $scope.downloadPayoutHistoryCsv = function () {
        $scope.showLoader();

        var count = 0;
        var requestObject = {
          'to_date': $scope.toDate,
          'from_date': $scope.fromDate,
          'page': 1,
          'package_ids': packageIds,
          'sort': $scope.sortValue,
          'sort_type': $scope.reverse == 'false' ? 'ASC' : 'DESC',
          'all_data': 1
        };
        var deferred = $q.defer();
        operatorPayoutService.getPayoutHistoryForExport(requestObject, $scope.user.token).then(function (res) { // hit service to get payout history
          if (res.data.code === '200') {
            angular.forEach(res.data.data, function (row) {
              $scope.getPayoutHistoryCsvList.push(row);

              deferred.resolve($scope.getPayoutHistoryCsvList);
            });

            $scope.hideLoader();
          } else if (res.data.code === '401') {
            $scope.logout();
          } else {
            $scope.hideLoader();
          }
        }, function () { // check for error
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
        return deferred.promise;
      };

      $scope.getHeaderForSlotBooking = function () { // set header for csv file
        return ['No.', 'Customer Name', 'Order ID', 'Payout Date', 'Transaction Date', 'Quantity', 'Addon Quantity', 'Total Addon Price', 'Promo Code', 'Promo Discount', 'Heli Commission', 'Net Payout', 'Payment Status', 'Tax', 'Deposit Price', 'Total Amount'];
      };

      $scope.getPayoutHistoryDetail = function () { // get payout detail by payment detail id
        $scope.homeRedirection();// check for authorised access
        if ((!$scope.user) || ($scope.user && !$scope.user.token)) { // check for user logged in status
          $state.go('signin');
          return false;
        }
        $scope.showLoader();
        var requestObject = {
          'payoutId': payoutId,
          'payment_no': paymentNo
        };
        operatorPayoutService.getPayoutHistoryDetail(requestObject, $scope.user.token).then(function (response) { // hit service to get payout detail
          if (response.data.code === '200') {
            $scope.payoutHistoryDetail = response.data.data; // add value in scope
            calculateTotalGuests($scope.payoutHistoryDetail.booking);
            $scope.payoutHistoryDetail.booking.booking_slot_detail.discount_amount = $scope.payoutHistoryDetail.booking.booking_slot_detail.discount_amount / $scope.payoutHistoryDetail.booking.booking_slot_detail.cc_rate;
            $scope.payoutHistoryDetail.booking.booking_slot_detail.nightly_discount = $scope.payoutHistoryDetail.booking.booking_slot_detail.nightly_discount / $scope.payoutHistoryDetail.booking.booking_slot_detail.cc_rate;
            $scope.payoutHistoryDetail.booking.booking_slot_detail.discount_amount = $scope.payoutHistoryDetail.booking.booking_slot_detail.discount_amount + $scope.payoutHistoryDetail.booking.booking_slot_detail.nightly_discount;
            $scope.selectedCurrency = $scope.payoutHistoryDetail.operator_currency_code ? $scope.payoutHistoryDetail.operator_currency_code : $scope.payoutHistoryDetail.booking.booking_slot_detail.package_currency_code; // default selected currency is package currency.
            $scope.selectedCurrencyRate = $scope.payoutHistoryDetail.booking.booking_slot_detail.package_cc_rate;

            // convert taxes value from operator currency to USD and subtract differens in paid_amount
            var differensCurrencyTaxesValue = $scope.payoutHistoryDetail.tax - ($scope.payoutHistoryDetail.tax / $scope.selectedCurrencyRate);
            $scope.payoutHistoryDetail.payments[0].paid_amount = $scope.payoutHistoryDetail.payments[0].paid_amount - differensCurrencyTaxesValue;

            // convert taxes from operator currency value to USD value
            $scope.payoutHistoryDetail.tax = $scope.payoutHistoryDetail.tax / $scope.selectedCurrencyRate;
            $scope.payoutHistoryDetail.booking.taxes.total.price.amount = $scope.payoutHistoryDetail.booking.taxes.total.price.amount / $scope.selectedCurrencyRate;

            if ($scope.payoutHistoryDetail.package_slot_details) {
              $scope.adultUnitPrice = $scope.payoutHistoryDetail.package_slot_details.adult_price;
            } else {
              $scope.adultUnitPrice = $scope.payoutHistoryDetail.booking.booking_slot_detail.slot_usd_adult_price / $scope.payoutHistoryDetail.booking.booking_slot_detail.slot_adult_quantity;
              $scope.days = moment($scope.payoutHistoryDetail.booking.booking_slot_detail.to_date).diff(moment($scope.payoutHistoryDetail.booking.booking_slot_detail.from_date), 'days') + CONST_HELPER.ONE;
            }
            if ($scope.payoutHistoryDetail.user_local_country_id && $scope.allcountryName) {
              $scope.payoutHistoryDetail.consumer_country_name = $scope.allcountryName.find(function(contry){
                if (contry.id == $scope.payoutHistoryDetail.user_local_country_id) {
                  return contry.name;
                }
              })
              console.log($scope.payoutHistoryDetail.consumer_country_name);
            }
            $scope.childUnitPrice = $scope.payoutHistoryDetail.booking.booking_slot_detail.slot_usd_child_price / $scope.payoutHistoryDetail.booking.booking_slot_detail.slot_child_quantity;
          } else if (response.data.code === '203') {
            $state.go('signin');
          } else if (response.data.code === '401') {
            $scope.logout();
          }
          $scope.hideLoader();
        }, function () { // check for error
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };

      // round off number
      $scope.roundOff = function (price) {
        return Math.round(price);
      };

      // on currency code change...
      $scope.changeCurrencyCode = function (code, whichCode, is_operator) {
        $scope.selectedCurrency = code;
        $scope.selectedCurrencyRate = $scope.payoutHistoryDetail.booking.booking_slot_detail[whichCode];
        $scope.operatorCurrencySelected = is_operator === 1;
      };

      // calculate all guests going in case of lodging added
      function calculateTotalGuests (bookingDetail) {
        // convert into array if slot lodging is in json object;
        if (bookingDetail.booking_slot_lodging_details && !bookingDetail.booking_slot_lodging_details.length) {
          var obj = angular.copy(bookingDetail.booking_slot_lodging_details); var lodgingArrayObject = [];

          for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
              var val = obj[key];
              lodgingArrayObject.push(val);
            }
          }
          bookingDetail.booking_slot_lodging_details = lodgingArrayObject;
        }

        if ($scope.payoutHistoryDetail.package_details.package_case != CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION && bookingDetail.booking_slot_lodging_details && bookingDetail.booking_slot_lodging_details.length) {
          bookingDetail.booking_slot_detail.slot_adult_quantity = 0;
          bookingDetail.sub_total = 0;
          bookingDetail.booking_slot_lodging_details.map(function (bsl) {
            bookingDetail.booking_slot_detail.slot_adult_quantity += bsl.quantity;
            bookingDetail.sub_total += !bsl.is_private ? bsl.usd_price : bsl.usd_private_price;
            if (bsl.nightly_slot_details) {
              bsl.slot_lodging = bsl.nightly_slot ? bsl.nightly_slot : {};
              !bsl.is_private ? bsl.slot_lodging.price = bsl.usd_price / bsl.quantity : bsl.slot_lodging.private_price = bsl.usd_private_price / bsl.quantity;
            }
          });
        }
      }

      angular.element(document.querySelector('#sandbox-container div')).datepicker({
        maxViewMode: 0
      }).on('changeDate', function (e) {
        setSpecificFromToValues(e);
      });

      function setSpecificFromToValues (e) {
        var specificToDate = angular.element(document.querySelector('#specific-to-date'));
        var specificFromDate = angular.element(document.querySelector('#specific-from-date'));
        if ($scope.isSpecificFromSelected) {
          // $scope.isSpecificFromSelected is true means From specific textbox value is been selected from the emebeded calendar
          $scope.toDate = moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT_PAYOUT);
          // Specific from Date is lesser than Specific To Date
          if (moment($scope.fromDate, CONST_HELPER.analytics_filter.DATE_FORMAT_PAYOUT).isBefore(moment($scope.toDate))) {
            specificToDate.val(moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY));
          } else {
            // Selected Specific To Date is lesser than From.So, Selected date will become Specific From date and already Selected From date will be To date
            $scope.toDate = $scope.fromDate;
            specificToDate.val(specificFromDate.val());
            specificFromDate.val(moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY));
            $scope.fromDate = moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT_PAYOUT);
          }
          $scope.displaySpecificTo = moment($scope.toDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
          $scope.displaySpecificFrom = moment($scope.fromDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
          $scope.isSpecificFromSelected = false; // now again if calendar will be clicked then Specific From date textbox will be selected
          $scope.showCustomFilter(false);
          $scope.$apply();
          $scope.payout.currentPage = 1;
          $state.go('operator-payout-history', ({ sort_value: $scope.sortValue, reverse: $scope.reverse, page: 1, to_date: $scope.toDate, from_date: $scope.fromDate, 'filter_date_type' : $scope.filter_date_type }));
          $scope.getPayoutHistoryCsvList = []; // set blank csv array
          $scope.payoutHistoryList = [];
        } else {
          specificFromDate.val(moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY));
          $scope.fromDate = moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT_PAYOUT);
          specificToDate.val('');
          $scope.isSpecificFromSelected = true;
        }
      }

      $scope.showCustomFilter = function (isShow) {
        if (isShow) {
          angular.element(document.querySelector('.analytics-dropdown')).toggle();
        } else {
          angular.element(document.querySelector('.analytics-dropdown')).css('display', 'none');
        }
        $rootScope.isCustomCalendarClick = true; // to handle document click to close the custom filter dropdown
      };
      $scope.showCustomFilter(false); // call show custom filter

      $scope.clickEvntSpecificDates = function () { // prevent the close of dropdown on click of From and To textbox
        $rootScope.isCustomCalendarClick = true; // to handle document click to close the custom filter dropdown
      };
      $scope.changeFilterDateType = function (value) {
        $scope.filter_date_type = value;
        $scope.showCustomFilter(true);
      }
      // function to edit resend email with link to complete the partial payment...
      $scope.resendCompletePaymentMail = function () {
        $scope.showLoader();
        var obj = { order_id: $scope.payoutHistoryDetail.order_number, token: $scope.user.token };

        // Api request /v1/payments/sendReminderEmail
        operatorViewBookingService.sendReminderEmail(obj).then(function (response) {
          if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
            $scope.hideLoader();
            $scope.showPopup('#emailSent');
          }
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };

      $scope.exportCsv = function () {
        $scope.showLoader();

        var params = {
          orderBy: $scope.sortValue,
          orderDirection: $scope.reverse == 'true' ? 'desc' : 'asc',
          filterDateType: $scope.filter_date_type,
          fromDate: $scope.fromDate,
          toDate: $scope.toDate
        };

        operatorPayoutService
          .exportCsv($scope.user.token, params)
          .then(function (response) {
            $scope.hideLoader();

            binaryService.downloadFileFromBlob({
              file: {
                name: binaryService.getFilenameFromResponse(response)
              },
              blob: {
                parts: response.data,
                options: { type: response.headers.get('Content-Type') }
              }
            });
          }, function () {
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
      };
    }
  ]
);
