/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc directive
 * @name heliApp.directive:userValidations
 * @description
 * # userValidations
 */
angular.module('heliApp')
  .directive('pwCheck', function () { // passwords matching directive
    return {
      require: 'ngModel',
      link: function (scope, elem, attrs, ctrl) {
        var firstPassword = '#' + attrs.pwCheck;// get the id of password field
        elem.add(firstPassword).on('keyup', function () {
          scope.$apply(function () {
            var v = elem.val() === $(firstPassword).val();// check for matching the passwords
            ctrl.$setValidity('pwmatch', v);
          });
        });
      }
    };
  });

angular.module('heliApp')
  .directive('pwUncheck', function () { // old and new passwords should not be same
    return {
      require: 'ngModel',
      link: function (scope, elem, attrs, ctrl) {
        var newPassword = '#' + attrs.pwUncheck;// get the id of password field
        elem.add(newPassword).on('keyup', function () {
          scope.$apply(function () {
            var v = elem.val() !== $(newPassword).val();// check for matching the passwords
            ctrl.$setValidity('pwoldmatch', v);
          });
        });
      }
    };
  });
