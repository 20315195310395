/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:onMySiteSettingsCtrl
 * @description
 * # onMySiteSettingsCtrl for opeartor to white label the packages.
 * Controller of the heliApp
 */
angular.module('heliApp')
  .controller('onMySiteSettingsCtrl', ['$scope', '$rootScope', '$compile', '$timeout', '$window', '$state', '$stateParams', '$sce', 'GOOGLE_API_KEY', 'onMySiteService', 'userAdventureDetailService', 'CONST_HELPER',
    function ($scope, $rootScope, $compile, $timeout, $window, $state, $stateParams, $sce, GOOGLE_API_KEY, onMySiteService, userAdventureDetailService, CONST_HELPER) {
      if ($scope.user.role_id != CONST_HELPER.USER_ROLE.OPERATOR) {
        $state.go('home');
      }
    }]);
