/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc directive
 * @name heliApp.directive:commonFeatures
 * @description
 * # commonFeatures
 */
angular.module('heliApp')
  .directive('file', function() {
    return {
        require:"ngModel",
        restrict: 'A',
        scope: {
          waiver_file: '@'
        },
        link: function($scope, el, attrs, ngModel){
            el.bind('change', function(event){
                var files = event.target.files;
                var file = files[0];
                ngModel.$setViewValue(file);
                $scope.waiver_file = file;
                $scope.$apply();
            });
        }
    };
});
