/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:sitemapController
 * @description
 * # sitemapController
 * Controller of the heliApp
 */
angular.module('heliApp')
  .controller('sitemapController',['$scope', '$http', 'REQUEST_URL', function ($scope, $http, REQUEST_URL) {
    $scope.HeliSitemap = {};
    $scope.showLoader();
    $http.get(REQUEST_URL + 'masters/sitemaps').then(function (res) {
      console.log(res);
      if (res.data) {
        $scope.HeliSitemap = res.data.data;
        $scope.hideLoader();
      }
    });
  }]);
