/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc directive
 * @name heliApp.directive:commonFeatures
 * @description
 * # commonFeatures
 */
angular.module('heliApp')
  .directive('checkForAdult', ['$timeout', function ($timeout) { // directive for keeping adult and child price in sync
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.on('keyup', function () {
          if (scope.checkForAdult) { // id same as adult option is selected
            var price = scope.addOns[attrs.index].adult_price;
            scope.addOns[attrs.index].child_price = price;// keep the models in sync
            $timeout(angular.noop);
          }
        });
      }
    };
  }])
  .directive("ngUploadChange",function(){
    return{
        scope:{
            ngUploadChange:"&"
        },
        link:function($scope, $element, $attrs){
            $element.on("change",function(event){
                $scope.$apply(function(){
                    $scope.ngUploadChange({$event: event})
                })
            })
            $scope.$on("$destroy",function(){
                $element.off();
            });
        }
    }
  })
  .directive('tabSwitch',['$location', '$window', '$timeout', function ($location, $window, $timeout) { // directive for switching to new tab
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.on('click', function () {
          if (element[0].attributes.to.value === 'next') {
            if ($location.hash() === 'location' || $location.hash() === '') {
              if (scope.currentLocation.name === 'Select a Location') {
                scope.noLocationSelected = true;
                return false;
              } else {
                scope.noLocationSelected = false;
              }
            }
            if ($location.hash() === 'eventDetails') {
              if (!scope.newPackage.event_eventName) {
                scope.showEventName = true;
                return false;
              }
              if (scope.newPackage.event_description && (scope.newPackage.event_description.length < 200 || scope.newPackage.event_description.length > 5000)) {
                scope.showEventDesc = true;
              }
              if (scope.newPackage.event_location_description && (scope.newPackage.event_location_description.length < 200 || scope.newPackage.event_location_description.length > 5000)) {
                scope.showEventLocationDesc = true;
              }
            }
            if ($location.hash() === 'lodging' && parseInt(scope.newPackage.package_case) !== 3 && +scope.newPackage.event_lodging_status != 0) {
              scope.lodgingCompleted = true;
              if (scope.newPackage.lodgingDetail && scope.newPackage.lodgingDetail.length) {
                scope.newPackage.lodgingDetail.map(function (lodging) {
                  if (!lodging.max_guests) {
                    scope.lodgingCompleted = false;
                  }
                });
                if (!scope.lodgingCompleted) { // if lodging is not completed then return;
                  return;
                }
              }
            }

            if (scope.packageCreation.$error.minlength) { // check for minlength error for textarea fields
              var invalidFields = scope.packageCreation.$error.minlength;
              for (var i = 0; i < invalidFields.length; i++) { // add flag for invalid field's and show error
                var fieldName = invalidFields[i].$name; // set error fiel name in variable
                if ($location.hash() === 'eventDetails' && fieldName === 'event_description') { // prevent save from Save and exit.if event detail input values have invalid data.
                  document.getElementById('event-desc').focus();// show focus on input field on event deatil page.
                  return false;
                }
              }
            }

            if ($location.hash() === 'itinerary' && scope.packageCreation.$error.minlength) { // prevent save from Save and exit.if itinerary input values have invalid data.
              document.getElementById('itinerary_day_description').focus();// show focus on input field on itinerary page.
              return false;
            }

            if (scope.packageCreation.$error.pattern) { // prevent save from Save and exit.if duration input values have invalid data.
              var invalidFields = scope.packageCreation.$error.pattern;
              for (var i = 0; i < invalidFields.length; i++) { // add flag for invalid field's and show error
                var fieldName = invalidFields[i].$name; // set error field name in variable
                if ($location.hash() === 'duration' && fieldName === 'duration_Day') { // prevent save from Save and exit.if event detail input values have invalid data.
                  document.getElementById('dayDuration').focus();// show focus on input field on duration page.
                  return false;
                }
                // if ($location.hash() === 'duration' && fieldName === 'amount_percentage') { // prevent save from Save and exit.if event detail input values have invalid data.
                //   document.getElementById('amountPercentage').focus();// show focus on input field on duration page.
                //   return false;
                // }
              }
            }

            if ($location.hash() === 'duration') {
              var day = parseInt(scope.newPackage.duration_Day);
              if (scope.newPackage.booking_type == 1 && (day < 1 || day > 51)) { // prevent save from Save and exit.if event detail input values have invalid data.
                document.getElementById('dayDuration').focus();// show focus on input field on duration page.
                return false;
              }

              // if (scope.showAmountPercErr || scope.depositErr) {
              //   document.getElementById('amountPercentage').focus();
              //   return false;
              // }
            }

            if ($location.hash() === 'gallery' && scope.packageCreation.$error.pattern) { // prevent save from Save and exit.if gallery input values have invalid data.
              document.getElementById('galleryVideoUrl').focus();// show focus on input field on duration page.
              return false;
            }
            // scope.minSingleImgSize = false; // hide error message
            // scope.maxSingleImgSize = false;// hide error message

            $('.adventure-tab-list li.active').next().find('a')[0].click();
          } else if (element[0].attributes.to.value === 'previous') {
            // scope.minSingleImgSize = false; // hide error message
            // scope.maxSingleImgSize = false;// hide error message
            $timeout(function () {
              $window.scrollTo(0, 0);
            }, 150);
            $('.adventure-tab-list li.active').prev().find('a')[0].click();
          }
        });
      }
    };
  }])
  .directive('tabSwitchOperatorProfile',['$location', function ($location) { // directive for switching to new tab
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.on('click', function () {
          if (element[0].attributes.to.value === 'next') {
            var isValid = true;
            if (scope.currentHash === 'basic-profile' || scope.currentHash === '') { // on basic profile check if any errors
              isValid = scope.checkForErrorOnProfile();
            } else if (scope.currentHash === 'operator-image') {
              isValid = scope.checkForErrorOnImage();
            }
            if (isValid) {
              $('.operator-profile-list li.active').next().find('a')[0].click();
            }
          } else if (element[0].attributes.to.value === 'previous') {
            $('.operator-profile-list li.active').prev().find('a')[0].click();
          }
        });
      }
    };
  }])
  .directive('shareClasses',['$document', '$rootScope', function ($document, $rootScope) { // directive for updating classes on share show and hide tooltip
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        function hide (nextEle) {
          nextEle.classList.add('hidden');
          nextEle.classList.remove('active');
        }

        function show (nextEle) {
          nextEle.classList.add('active');
          nextEle.classList.remove('hidden');
        }

        element.on('click', function () {
          if ($rootScope.currentState.name !== 'mycart') {
            $rootScope.isUserLoggedIn(); // check is user loggedin
          }
          $rootScope.isToolTipClick = true; // check click is from tooltip
          var nextEle = this.parentElement.querySelector('.order-share-div');
          if (nextEle.classList.contains('hidden')) { // show and hide tooltip
            show(nextEle, $rootScope.isToolTipClick);
          } else {
            hide(nextEle);
          }
        });
      }
    };
  }])
  .directive('googleplace',['$rootScope', function ($rootScope) {
    return {
      require: 'ngModel',
      scope: { locationChanged: '&' },
      link: function (scope, element, attrs, model) {

        scope.gPlace = new google.maps.places.Autocomplete(element[0]);

        google.maps.event.addListener(scope.gPlace, 'place_changed', function () {
          $rootScope.location = {
            'latitude': '',
            'longitude': ''
          };
          var geoComponents = scope.gPlace.getPlace();
          // var latitude = geoComponents.geometry.location.lat();
          // var longitude = geoComponents.geometry.location.lng();
          $rootScope.location.latitude = geoComponents.geometry.location.lat();
          $rootScope.location.longitude = geoComponents.geometry.location.lng();
          $rootScope.locationName = element.val();
          scope.$apply(function () {
            model.$setViewValue(element.val());
            scope.locationChanged();
          });
        });
      }
    };
  }]);
