/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-extend-native */
/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc directive
 * @name heliApp.directive:Image Gallery
 * @description
 * # show image in Image Gallery.
 */
angular.module('heliApp')
  .directive('imageGallary',['$rootScope', '$timeout', function ($rootScope, $timeout) {
    return {
      restrict: 'EA',
      templateUrl: 'views/image-gallery.html',
      scope: {
        images: '=',
        title: '@'
      },
      controller: ['$scope', function ($scope) {
        // var vm = $scope
      }],
      link: function (scope, element, attr, ctrl) {
        scope.siteInIFrame = $rootScope.siteInIFrame;
        scope.currentIndex = $rootScope.selectedImgIndex ? $rootScope.selectedImgIndex : 0;
        if (scope.images.length === 0) { return false; }

        String.prototype.splice = function (idx, rem, str) {
          return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
        };

        scope.images.map(function (obj) {
          if (obj.media_type === 0 || obj.media_type === 5) { return false; }

          var isYoutube = obj.url.search('youtube');

          var formatedUrl = formatUrl(obj.url, isYoutube);
          obj.url = formatedUrl;
        });

        function formatUrl (url, isYoutube) {
          if (isYoutube !== -1) {
            url = url.split('&')[0].replace('watch?v=', 'embed/');
          } else {
            var i = url.search('//');
            var j = url.search('com');
            url = url.splice(i + 2, 0, 'player.');
            url = url.splice(j + 10, 0, '/video');
          }
          return url;
        }

        scope.currentImage = scope.images[scope.currentIndex].media_type === 1 ? scope.images[scope.currentIndex].url.replace('watch?v=', 'embed/') + '?autoplay=1' : scope.images[scope.currentIndex].url;
        scope.mediaType = scope.images[scope.currentIndex].media_type;

        scope.changeImage = function (index) {
          scope.currentIndex = index;
          scope.mediaType = 0;
          $timeout(function () {
            scope.mediaType = scope.images[index].media_type;
            if (scope.mediaType === 1) {
              scope.currentImage = scope.images[index].url + '?autoplay=1';
            } else {
              scope.currentImage = scope.images[index].url;
            }
          }, 10);
        };

        scope.closeGallary = function () {
        // reseting to default
          scope.currentIndex = 0;
          $rootScope.selectedImgIndex = 0;
          scope.currentImage = scope.images[scope.currentIndex].media_type === 1 ? scope.images[scope.currentIndex].url.replace('watch?v=', 'embed/') : scope.images[scope.currentIndex].url;
          scope.mediaType = scope.images[scope.currentIndex].media_type;
        };

        scope.$watch('images', function (newV, oldV) {
          if (newV !== oldV) {
            scope.currentIndex = $rootScope.selectedImgIndex ? $rootScope.selectedImgIndex : 0;
            scope.currentImage = scope.images[scope.currentIndex].url;
            scope.mediaType = scope.images[scope.currentIndex].media_type;
          }
        });

        scope.$watch('currentIndex', function (newV, oldV) {
          if (newV > oldV) {
            var diff = newV - oldV;
            scrollLeft(newV, diff);
          } else {
            var diff = oldV - newV;
            scrollRight(newV, diff);
          }
        });

        function scrollLeft (currentIndex, diff) {
          currentIndex = currentIndex + 1;
          var ul_el = angular.element(document.getElementsByClassName('thumbnail-ul'));
          var li_width = 120;
          var ul_width = ul_el.width();
          var total_li_can = Math.floor(ul_width / li_width);
          var half = Math.ceil(total_li_can / 2);
          if (currentIndex > half) {
            var dif = currentIndex - half;
            var scroll = diff * 100;
            $('.thumbnail-ul').animate({ scrollLeft: ul_el[0].scrollLeft + scroll }, 'slow');
          }
        };
        function scrollRight (currentIndex, diff) {
          currentIndex = currentIndex - 1;
          var ul_el = angular.element(document.getElementsByClassName('thumbnail-ul'));
          var li_width = 120;
          var ul_width = ul_el.width();
          var total_li_can = Math.ceil(ul_width / li_width);
          var half = Math.ceil(total_li_can / 2);
          var dif = half - currentIndex;
          var scroll = diff * 100;
          $('.thumbnail-ul').animate({ scrollLeft: ul_el[0].scrollLeft - scroll }, 'slow');
        };
      }
    };
  }]);
