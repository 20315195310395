import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { RootScopeService } from '../../../../../../ng-app/src/app/services/root-scope/root-scope.service';
import { WebService } from '../../../../../../app/scripts/services/webService';

@Component({
  selector: 'app-video-bg-block',
  templateUrl: '/ng-app/src/app/components/home/video-bg-block/video-bg-block.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/home/video-bg-block/video-bg-block.component.css']
})
export class VideoBgBlockComponent implements OnInit, AfterViewInit {

  user: any = {}
  titleFirstString = 'Have your ';
  titleSecondString = 'Pay for your ';
  subtitleString = 'Earn 10% credit when you bring a friend';
  wordsPairs = [
    {
      title: 'DISCOVER THE WORLD’S BEST EXPERIENCES',
      subTitle: 'EARN 10% CREDIT WHEN YOU BRING A FRIEND',
      link: 'https://promo.heli.life/credits/'
    },
    {
      title: 'EACH ADVENTURE HELPS PAY FOR THE NEXT',
      subTitle: 'EARN CREDIT ON EVERY TRIP',
      link: 'https://promo.heli.life/credits/'
    },
    {
      title: 'EXPERT GUIDANCE FOR YOUR NEXT ADVENTURE',
      subTitle: 'HAVE OUR TEAM HELP YOU PLAN THE ULTIMATE TRIP',
      link: 'https://experts.heli.life/'
    }
  ];

  header;

  @ViewChild('titleTextEl') titleTextEl: ElementRef;
  @ViewChild('titleTextSecondEl') titleTextSecondEl: ElementRef;
  @ViewChild('subtitleTextEl') subtitleTextEl: ElementRef;
  @ViewChild('subtitleLinkEl') subtitleLinkEl: ElementRef;

  constructor(
    public renderer: Renderer2,
    public webService: WebService,
    public router: UIRouter,
    public rootScopeService: RootScopeService
    ) {
  }

  ngOnInit(): void {
    this.header = this.getRandomWordsPair(this.wordsPairs);

  }

  ngAfterViewInit(): void {
  }

  handlePageLaunching() {
    const {firstPhrase, secondPhrase} = this.getRandomWordsPair(this.wordsPairs);
  }

  getRandomWordsPair(array) {
    array.sort(() => 0.5 - Math.random());
    return array[0];
  }
}
