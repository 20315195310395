/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable no-extend-native */
/* eslint-disable handle-callback-err */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc directive
 * @name heliApp.directive:Feed Post
 * @description
 * # user can write feed and post it, can upload images and vedio.
 */
angular.module('heliApp').directive('feedPost',['$timeout', '$http', 'GOOGLE_API_KEY', 'userSocialProfileService', '$rootScope', function ($timeout, $http, GOOGLE_API_KEY, userSocialProfileService, $rootScope) {
  return {
    restrict: 'EA',
    templateUrl: 'views/feedPostTemplate.html',
    scope: {
      profilePic: '=',
      user: '=',
      loader: '&showLoader',
      hideloader: '&hideLoader'
    },
    controller: function () {

    },
    link: function ($scope, element, attr, ctrl) {
      $scope.postType = 1;
      angular.element(document.querySelector('#addMultipleFileInput')).on('change', handleMultiFileSelect);
      $scope.myImage = [];
      $scope.uploadImages = [];
      $scope.minMultipleImgSize = false;
      $scope.maxMultipleImgSize = false;
      $scope.imageLengthAlert = false;
      $scope.errorInPost = false;

      // upload gallery images
      function handleMultiFileSelect (evt) {
        // reset errors
        $scope.minMultipleImgSize = false;
        $scope.maxMultipleImgSize = false;
        var count = $scope.uploadImages.length - 1;
        for (var i = 0; i < evt.currentTarget.files.length; i++) {
          // image resolution validation
          var _URL = window.URL || window.webkitURL; // initiate _URL
          var imgfile, img;

          if (evt.currentTarget.files[i].size < 50000) { // check size of image is less then 50KB
            $scope.minMultipleImgSize = true;
            $scope.errorInPost = false;
            evt.currentTarget.value = '';
            $timeout(angular.noop);
            return false;
          } else {
            $scope.minMultipleImgSize = false;
          }
          if (evt.currentTarget.files[i].size > 5000000) { // check size of image is greater then 5MB
            $scope.maxMultipleImgSize = true;
            evt.currentTarget.value = '';
            $timeout(angular.noop);
            return false;
          } else {
            $scope.maxMultipleImgSize = false;
          }
          var file = evt.currentTarget.files[i];
          var fileExtnArr = file.name.split('.');
          var extn = fileExtnArr[fileExtnArr.length - 1].toLowerCase();
          if (extn === 'png' || extn === 'jpg' || extn === 'jpeg' || extn === 'gif') { /* check valid image extn */
            if ($scope.uploadImages.length >= 10) {
              $scope.imageLengthAlert = true;
              evt.currentTarget.value = '';
              $timeout(angular.noop);
              return false;
            }
            $scope.errorInPost = false;
            $scope.uploadImages.push(file);
            var reader = new FileReader();
            reader.onload = function (e) {
              if (file) {
                img = new Image();
                img.src = e.target.result;
                img.onload = function () {
                  count = count + 1;
                  if (this.width < 800 || this.height < 300) {
                    $scope.minMultipleImgSize = true;
                    $scope.errorInPost = false;
                    e.currentTarget.value = '';
                    $timeout(angular.noop);
                    $scope.uploadImages.splice(count, 1);
                    count = count - 1;
                    // return false;
                  } else {
                    $scope.$apply(function ($scope) {
                      console.log(e);
                      $scope.myImage.push(e.target.result); // save image in myImage array
                    });
                  }
                };
              }
            };
            reader.readAsDataURL(file);
          } else { /* if image extn invalid then show alert message */
            file = '';
            alert("Please upload a valid image format(acceptable formats 'png','jpg','gif')");
          }
        }
      }
      // end
      $scope.deleteImage = function (index) { // delete images from gallery
        $scope.myImage.splice(index, 1);
        $scope.uploadImages.splice(index, 1);
        $scope.imageLengthAlert = false;
      };
      // end deleteImage function.

      // add video thumbnail...
      $scope.videoThumbnail = [];
      $scope.youtubeUrl = '';
      $scope.errorMsg = '';
      $scope.addThumbnail = function (video_url) {
        $scope.errorInPost = false;
        if (video_url.length < 20) {
          return;
        }
        if (!video_url || video_url === '') {
          $scope.errorMsg = 'Please enter a Youtube or Vimeo url.';
          $scope.errorInPost = false;// keep one error
          return false;
        } else if (!matchYoutubeUrl(video_url) && !matchVimeoUrl(video_url)) {
          $scope.errorMsg = 'Invalid Url.';
          $scope.errorInPost = false;// keep one error
          return false;
        }
        var video_id;
        $scope.errorInPost = false;
        if (matchYoutubeUrl(video_url)) {
          video_id = video_url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);

          $http.get('https://www.googleapis.com/youtube/v3/videos?part=snippet&id=' + video_id[1] + '&fields=items/snippet/title,items/snippet/description,items/snippet/thumbnails&key=' + GOOGLE_API_KEY).then(function (res) {
            if (res.data.items[0]) {
              if ($scope.videoThumbnail.length > 0) { return; }

              res.data.items[0].video_source = 'YOUTUBE.COM';
              res.data.items[0].video_url = embedUrl(video_url, true);
              $scope.videoThumbnail.push(res.data.items[0]);
              $scope.youtubeUrl = '';
              $scope.errorMsg = '';
            } else {
              $scope.errorMsg = 'Youtube url did not match.';
              return false;
            }
          }, function (error) {
            $scope.errorMsg = 'Something went wrong.';
          });
        } else if (matchVimeoUrl(video_url)) {
          video_id = video_url.split('.com/');
          $.ajax({
            type: 'GET',
            url: 'http://vimeo.com/api/v2/video/' + video_id[1] + '.json',
            jsonp: 'callback',
            dataType: 'jsonp',
            success: function (res) {
              console.log(res);
              if (res) {
                if ($scope.videoThumbnail.length > 0) { return; }

                res[0].video_source = 'VIMEO.COM';
                res[0].video_url = embedUrl(video_url, false);
                $scope.videoThumbnail.push(res[0]);
                $scope.youtubeUrl = '';
                $scope.errorMsg = '';
                $scope.$apply();
              } else {
                $scope.errorMsg = 'Vimeo url did not match.';
                return false;
              }
            },
            error: function () {

            }
          });
        }
      };
      // delete the added video...
      $scope.deleteVideo = function (index) {
        $scope.videoThumbnail.splice(index, 1);
      };

      // string splice prototype...
      String.prototype.splice = function (idx, rem, str) {
        return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
      };

      // convert video url to embed url
      function embedUrl (url, isYoutube) {
        if (isYoutube) {
          url = url.replace('watch?v=', 'embed/');
        } else {
          var i = url.search('//');
          var j = url.search('com');
          url = url.splice(i + 2, 0, 'player.');
          url = url.splice(j + 10, 0, '/video');
        }
        return url;
      }

      // validate youtube url...
      function matchYoutubeUrl (url) {
        var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        if (url.match(p)) {
          return url.match(p)[1];
        }
        return false;
      }
      // validate vimeo url
      function matchVimeoUrl (url) {
        if (/^(http\:\/\/|https\:\/\/)?(www\.)?(vimeo\.com\/)([0-9]+)$/.test(url)) {
          return true;
        }
        return false;
      }

      // wrap the text if video desc is long...
      $scope.TestWraping = function (text, row) {
        var len = text.length;
        var canLen = row === 'title' ? 50 : 160;
        if (len >= canLen) {
          return text.slice(0, canLen - 3) + '...';
        } else {
          return text;
        }
      };

      $scope.postFeed = function (postType) {
        $scope.errorInPost = false;
        if (!isValidPost(postType)) { return false; }

        $scope.loader();
        var postData = getPostData(postType);
        userSocialProfileService.postFeed(postData, $scope.uploadImages, $scope.user.token).then(function (res) {
          $scope.textPostText = '';
          $scope.imgPostText = '';
          $scope.videoPostText = '';
          $scope.uploadImages = [];
          $scope.myImage = [];
          $scope.videoThumbnail = [];
          $scope.imageLengthAlert = false;
          $scope.minMultipleImgSize = false;
          $scope.maxMultipleImgSize = false;
          $scope.hideloader();
          $rootScope.$broadcast('new-post-created', res.data.feed_details[0]);
        }, function () {

        });
      };

      // validate data of post...
      function isValidPost (postType) {
        switch (postType) {
          case 1: {
            if ($scope.textPostText) {
              return true;
            } else {
              $scope.errorInPost = true;
            }
            break;
          }
          case 2: {
            if ($scope.uploadImages.length > 0) {
              return true;
            } else {
              $scope.errorInPost = true;
            }
            break;
          }
          case 3: {
            if ($scope.videoThumbnail.length > 0) {
              return true;
            } else {
              $scope.errorMsg = '';// remove earlier message
              $scope.errorInPost = true;
            }
          }
        }
      }

      // get required format post data
      function getPostData (postType) {
        var data = {
          text: '',
          'image[]': '',
          video: '',
          post_type: postType
        };
        switch (postType) {
          case 1: {
            data.text = $scope.textPostText;
            break;
          }
          case 2: {
            data = { 'image[]': $scope.uploadImages };
            data.text = $scope.imgPostText;
            data.post_type = postType;
            break;
          }
          case 3: {
            data.text = $scope.videoPostText;
            data.video = getVideoDesc($scope.videoThumbnail[0].video_source);
            break;
          }
        }
        return data;
      }
      function getVideoDesc (video_source) {
        if (video_source === 'YOUTUBE.COM') {
          return {
            video_title: $scope.videoThumbnail[0].snippet.title,
            video_description: $scope.videoThumbnail[0].snippet.description,
            video_url: $scope.videoThumbnail[0].video_url,
            video_source: video_source,
            video_thumbnail: $scope.videoThumbnail[0].snippet.thumbnails.high ? $scope.videoThumbnail[0].snippet.thumbnails.high.url : $scope.videoThumbnail[0].snippet.thumbnails.default.url
          };
        } else {
          return {
            video_title: $scope.videoThumbnail[0].title,
            video_description: $scope.videoThumbnail[0].description,
            video_url: $scope.videoThumbnail[0].video_url,
            video_source: video_source,
            video_thumbnail: $scope.videoThumbnail[0].thumbnail_large ? $scope.videoThumbnail[0].thumbnail_large : $scope.videoThumbnail[0].thumbnail_medium
          };
        }
      }
    }
  };
}])
  .directive('expandVideo',['$sce', function ($sce) {
    return {
      restrict: 'A',
      scope: {
        'video': '='
      },
      link: function (scope, element, attrs) {
        element.on('click', function () {
          var div_element = this.parentElement;
          var inner_text = '<iframe class="responsiveYoutube" expand-video id="youtubeVideo"  src="' + trustSrc(scope.video.video_url) + '?autoplay=1" width="684px" height="484px" allowfullscreen="true"></iframe>';
          div_element.style.display = 'none'; // hide the iamge div
          $(div_element.parentElement).find('.videoDescription').css('display', 'none'); // hide the video description div
          $(div_element.parentElement).find('.expandVideo')[0].innerHTML = inner_text; // show the youtube video div
        });

        function trustSrc (src) {
          return $sce.trustAsResourceUrl(src);
        }
      }
    };
  }])
  .directive('feedPostSharing', function () {
    return {
      restrict: 'EA',
      templateUrl: 'views/feed-post-sharing.html',
      scope: {
        sharingdata: '=',
        fbmedia: '=',
        initializeHeliFeedShare: '&initializeHeliFeedShare',
        initializeMail: '&'
      },
      controller: function () {

      },
      link: function ($scope, element, attr, ctrl) {
        $scope.sharing_data = $scope.sharingdata;
        $scope.fb_media = $scope.fbmedia;
      }
    };
  });
