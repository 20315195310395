import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name UserCheckoutService
 * @description
 * # userCheckoutService
 * Service in the heliApp.
 */

@Injectable()

export class UserCheckoutService {

  constructor(@Inject(WebService) private webService: WebService) { }

  sendReq(method) {
    return new Promise((resolve, reject) => {
      method.then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })/* asynchronously complete request */
  }

  getCartItemslist(cart_id, token) {
    return this.sendReq(this.webService.getCartList(cart_id, token));
  }
}

angular.module('heliApp').service('userCheckoutService', downgradeInjectable(UserCheckoutService))
