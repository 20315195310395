/* eslint-disable no-tabs */
/* eslint-disable no-undef */
/* eslint-disable no-redeclare */
/* eslint-disable camelcase */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:userPackageSlotAddons
 * @description
 * # userPackageSlotAddons manage the user selected Package Slot Addons;
 * Controller of the heliApp
 */

angular.module('heliApp').controller('userPackageSlotAddons', ['$scope', '$stateParams', 'userAdventureDetailService', '$timeout', '$cookies', '$state', '$rootScope', 'userRegisterService', 'CONST_HELPER', '$filter', 'MESSAGE_HELPER', 'STRIPE_KEY', 'userLoginService', userPackageSlotAddons]).filter('datetime',['$filter', function ($filter) {
  return function (input) {
    if (input == null) { return ''; }

    var _date = $filter('date')(new Date(input), 'MMM d, y');
    return _date.toUpperCase();
  };
}]);

function userPackageSlotAddons ($scope, $stateParams, userAdventureDetailService, $timeout, $cookies, $state, $rootScope, userRegisterService, CONST_HELPER, $filter, MESSAGE_HELPER, STRIPE_KEY, userLoginService) {
  var vm = this;
  var selectedSlotId = $stateParams.selected_slot_id;
  var cartId = $stateParams.cart_id ? $stateParams.cart_id : '';
  $rootScope.selectedGuest = $stateParams.adults ? $stateParams.adults : '1';
  $scope.guests_detail = [];
  vm.Ffixed = CONST_HELPER.PROCESS_FEE.Ffixed;
  vm.Fpercent = CONST_HELPER.PROCESS_FEE.Fpercent;
  vm.taxAmount = CONST_HELPER.ZERO;
  vm.processingFee = CONST_HELPER.ZERO;
  vm.CartGrandTotal = CONST_HELPER.ZERO;
  vm.partial_amount = CONST_HELPER.ZERO;
  vm.totalGrandTotal = CONST_HELPER.ZERO;
  vm.guestEmail = '';
  vm.discount_amount = CONST_HELPER.ZERO;
  vm.nightly_discount = CONST_HELPER.ZERO;
  vm.appliedCouponDetails = {};
  // scope variables
  vm.selectedSlotDetails = {};
  vm.selectForEveryone = true;
  $scope.dataNotFound = false;
  vm.selectedAddons = [];
  vm.addonAdults = [];
  vm.selectedLodging = [];
  vm.guestMorethanOne = 'false';
  vm.moreGuests = '0';
  vm.selectedGuests = '0';
  vm.totalSelectedGuest = CONST_HELPER.ZERO;
  vm.remainingSelectedGuest = CONST_HELPER.ZERO;
  vm.remainingAccommodationGuest = CONST_HELPER.ZERO;
  vm.maximumRooms = CONST_HELPER.ZERO;
  vm.remainingRooms = CONST_HELPER.ZERO;
  vm.currentLodgingId = $stateParams.lodging_id;
  $scope.emailPattern = /^([a-zA-Z0-9_.-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  $scope.guestDetail = {};
  $scope.generalGuest = {};
  $scope.acknowledgeAgreeError = false;
  $scope.guestDetailsInformationError = false;
  $scope.waiverAvailable = $cookies.get('waiverAvailable') ? JSON.parse($cookies.get('waiverAvailable')) : null;
  $rootScope.initializePhoneField("#generalGuestPhone");
  $scope.isManualBooking = $rootScope.currentState.name === 'operator-manual-booking';
  $scope.selectedOptionsForRooms = [];
  $scope.selectedOptionsForAddons = [];
  for (var i = 0; i < $rootScope.selectedGuest-1; i++) {
    $scope.guests_detail.push({
      guest_adult_child: 1,
      guest_tbo: 0
    })
  }
  /**
    * @ngdoc function
    * @name getSlotAddons
    * @description
    * # get the selected slot details and manipulate data accordingly...
  */
  function getSlotAddons () {
    $scope.showLoader();
    // set selected currency code to the user local currency for white lable...
    vm.selectedSlotDetails = {};
    vm.slotAddonsDetails = {};
    $rootScope.selectedCurrencyCode = $rootScope.localCurrencyCode;
    vm.selectedCurrencyCodenew = $rootScope.localCurrencyCode;
    $scope.package_id = $stateParams.package_id ? $stateParams.package_id : '';
    console.debug('getSlotAddons: $stateParams: %O', $stateParams);
    console.debug('getSlotAddons: selectedSlotId: %O', selectedSlotId);
    if ($stateParams.package_id) {
      $scope.start_date = moment(new Date(Number($stateParams.start_date)));
      $scope.end_date = moment(new Date(Number($stateParams.end_date)));
      var reqObj = {'package_id': $stateParams.package_id, 'start_date': $scope.start_date.format('YYYY/MM/DD'), 'end_date': $scope.end_date.format('YYYY/MM/DD'), 'cart_id': cartId};
      $scope.days = $scope.end_date.diff($scope.start_date, 'days') + CONST_HELPER.ONE; // include the start date as well
    } else {
      var reqObj = {'slot_id': selectedSlotId, 'cart_id': cartId};
    }
    console.trace('userAdventureDetailService.getPackageSlotAddons: reqObj: %O', reqObj);
    console.debug('userAdventureDetailService.getPackageSlotAddons: reqObj: %O', reqObj);
    userAdventureDetailService.getPackageSlotAddons(reqObj).then(function (res) {
      console.debug('userAdventureDetailService.getPackageSlotAddons.then: res: %O', res);
      console.debug('userAdventureDetailService.getPackageSlotAddons.then: res.data.slot_details: %O', res.data.slot_details);
      if ((!res.data && !res.data.slot_details)) {
        getSlotAddons();
        return;
      }
      if (res.data.code === CONST_HELPER.API_RESPONSE.OK) {
        vm.totalGrandTotal = CONST_HELPER.ZERO;
        vm.selectedSlotDetails = res.data.slot_details;
        vm.slotAddonsDetails = res.data.addon_details;
        if (!$rootScope.operatorAdventureCurrencyCode) {
          $rootScope.operatorAdventureCurrencyCode = vm.selectedSlotDetails.currency.code;
        }
        $rootScope.package_id_forGuestSignup = vm.selectedSlotDetails.package.id;
        if ($stateParams.package_id) {
          $scope.days = vm.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION ? $scope.end_date.diff($scope.start_date, 'days') : $scope.end_date.diff($scope.start_date, 'days') + CONST_HELPER.ONE; // include the start date as well
        }

        if($stateParams.selected_slot_id && vm.selectedSlotDetails.package.waiver){
          $cookies.put('waiverAvailable', JSON.stringify(vm.selectedSlotDetails.package.waiver));
        }else if($stateParams.package_id && vm.selectedSlotDetails.waiver){
          $cookies.put('waiverAvailable', JSON.stringify(vm.selectedSlotDetails.waiver));
        }else{
          $cookies.put('waiverAvailable', null);
        }

        vm.adultRange = vm.selectedSlotDetails.available_seat;
        // format nightly slot lodging data...
        if ($scope.package_id) {
          formatNightlySlotLodging(vm.selectedSlotDetails);
        }

        if (vm.selectedSlotDetails && vm.selectedSlotDetails.slot_lodgings) {
          vm.selectedSlotDetails.slot_lodgings.forEach(function (lodging) {
            var lodgingObj = {
              lodging_id: lodging.id
            }
            $scope.selectedOptionsForRooms.push(lodgingObj)
          });
        }

        if (vm.slotAddonsDetails && vm.slotAddonsDetails.length) {
          vm.slotAddonsDetails.forEach( function (eachAddon) {
            var addOn = {
              id : eachAddon.id
            }
            $scope.selectedOptionsForAddons.push(addOn)
          })
        }

        $timeout(function () {
          $('.adult-selectpicker').selectpicker('refresh');
        }, CONST_HELPER.ZERO);
        $timeout(function () { // for custom plugin
          $('.selectpicker').selectpicker();
        }, 1000);
        if (cartId && cartId !== '') {
          updateSelectedAddons_People();
        }
        // check if slot has lodging and private lodging
        if (vm.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_SEAT) { checkAndUpdateLodging(); }

        // check accommodation max guests package case B
        if (vm.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) { checkMaxGuests(); }

        // calculate the price for case C
        if (vm.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) { updateAddonChild_AdultCount(); }

        if (vm.selectedSlotDetails.cart_slot_lodging_details && vm.selectedSlotDetails.cart_slot_lodging_details.length && vm.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_SEAT) {
          setSavedLodgingData();
        } else if (vm.selectedSlotDetails.cart_slot_lodging_details && vm.selectedSlotDetails.cart_slot_lodging_details.length && vm.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
          setSavedAccommodationData();
        } else {
          vm.adult = vm.selectedSlotDetails.cart_slot_details ? JSON.stringify(vm.selectedSlotDetails.cart_slot_details.slot_adult_quantity) : $rootScope.selectedGuest;
        }
        if (vm.selectedSlotDetails.currency.code != 'USD') {
          $scope.getSlotUsdRate(vm.selectedSlotDetails.currency.code, 'USD', function (currencyRate) {
            $scope.usdCurencyRate = currencyRate;
            if ($scope.package_id) {
              vm.selectedSlotDetails.usd_adult_price = vm.selectedSlotDetails.usd_adult_price * $scope.usdCurencyRate;
            }
          });
        } else {
          $scope.usdCurencyRate = CONST_HELPER.ONE;
        }
        $scope.getSlotUsdRate(vm.selectedSlotDetails.currency.code, $rootScope.localCurrencyCode, function (currencyRate) {
          $scope.localCurencyRate = currencyRate;
          if ($scope.package_id) {
            vm.selectedSlotDetails.adult_price = vm.selectedSlotDetails.adult_price;
          }
          updateAddonChild_AdultCount();
        });
        if (vm.selectedSlotDetails.package.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT && vm.selectedSlotDetails.slot_lodgings.length) {
          vm.moreGuests = $rootScope.selectedGuest;
        } else {
          vm.adult = $rootScope.selectedGuest;
        }
        updateSlotLodgingRoomsData('init'); // Initialy set room options...

        if (
          vm.selectedSlotDetails
          &&
          vm.selectedSlotDetails.slot_lodgings
          &&
          vm.selectedSlotDetails.slot_lodgings.length == 1
          &&
          vm.selectedSlotDetails.slot_lodgings[0].available_units == 1
          &&
          !vm.selectedSlotDetails.slot_lodgings[0].canBookedPrivate
          &&
          !$stateParams.selectedRoomOptions
        ) {
          vm.selectedSlotDetails.slot_lodgings[0].rooms = '1';
          if (vm.selectedSlotDetails.package.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT && vm.selectedSlotDetails.package.lodging_status != CONST_HELPER.EVENT_LODGING.NO) {
            vm.SelectRoomsForLodging(vm.selectedSlotDetails.slot_lodgings[0] ,0 , vm.selectedSlotDetails.slot_lodgings[0].canBookedPrivate, true);
            vm.selectedSlotDetails.slot_lodgings[0].booking_lodging_rooms = [];
            var roomGuests = {
              rooms_guest: $rootScope.selectedGuest
            }
            vm.selectedSlotDetails.slot_lodgings[0].booking_lodging_rooms.push(roomGuests);
            var indexGuestsSelect = $rootScope.selectedGuest - 1;
            vm.SelectGuestForLodging(vm.selectedSlotDetails.slot_lodgings[0].booking_lodging_rooms[0],vm.selectedSlotDetails.slot_lodgings[0],indexGuestsSelect, true)
          } else if (vm.selectedSlotDetails.package.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
            vm.selectAccommodation(vm.selectedSlotDetails.slot_lodgings[0]);
          }
        }

        if ($stateParams.selectedRoomOptions) {
          var alreadySelectedOptions = JSON.parse($stateParams.selectedRoomOptions);
          $scope.selectedOptionsForRooms = alreadySelectedOptions;
          for (var i = 0; i < alreadySelectedOptions.length; i++) {
            if (vm.selectedSlotDetails.package.package_case == CONST_HELPER.PACKAGE_CASE.PER_SEAT && vm.selectedSlotDetails.package.lodging_status != CONST_HELPER.EVENT_LODGING.NO) {
              vm.selectedSlotDetails.slot_lodgings[i].rooms = alreadySelectedOptions[i].rooms && alreadySelectedOptions[i].rooms.length ? alreadySelectedOptions[i].rooms.length.toString() : '0';
              vm.SelectRoomsForLodging(vm.selectedSlotDetails.slot_lodgings[i] ,i , vm.selectedSlotDetails.slot_lodgings[i].canBookedPrivate, true);
              if (alreadySelectedOptions[i].rooms && alreadySelectedOptions[i].rooms.length) {
                for (var jj = 0; jj < alreadySelectedOptions[i].rooms.length; jj++) {
                  vm.selectedSlotDetails.slot_lodgings[i].booking_lodging_rooms[jj].rooms_guest = alreadySelectedOptions[i].rooms[jj].total.toString();
                  if (alreadySelectedOptions[i].rooms[jj].type && (alreadySelectedOptions[i].rooms[jj].type == "private")) {
                    vm.selectedSlotDetails.slot_lodgings[i].booking_lodging_rooms[jj].selectedGuestType = alreadySelectedOptions[i].rooms[jj].type;
                    vm.selectedSlotDetails.slot_lodgings[i].booking_lodging_rooms[jj].is_private_charged = true;
                    var privateOptions = {
                      guest: alreadySelectedOptions[i].rooms[jj].total,
                      label: alreadySelectedOptions[i].rooms[jj].total.toString() + " Guest (private)",
                      type: "private"
                    }
                    vm.selectedSlotDetails.slot_lodgings[i].booking_lodging_rooms[jj].rooms_guest = JSON.stringify(privateOptions);
                  } else {
                    if (vm.selectedSlotDetails.slot_lodgings[i].canBookedPrivate) {
                      vm.selectedSlotDetails.slot_lodgings[i].booking_lodging_rooms[jj].selectedGuestType = "shared";
                      vm.selectedSlotDetails.slot_lodgings[i].booking_lodging_rooms[jj].is_private_charged = false;
                      var sharedOptions = {
                        guest: alreadySelectedOptions[i].rooms[jj].total,
                        label: alreadySelectedOptions[i].rooms[jj].total.toString() + " Guest",
                        type: "shared"
                      }
                      vm.selectedSlotDetails.slot_lodgings[i].booking_lodging_rooms[jj].rooms_guest = JSON.stringify(sharedOptions);
                    } else {
                      vm.selectedSlotDetails.slot_lodgings[i].booking_lodging_rooms[jj].rooms_guest = alreadySelectedOptions[i].rooms[jj].total.toString();
                    }
                  }
                  var indexGuestsSelect = +alreadySelectedOptions[i].rooms[jj].total - 1;
                  vm.SelectGuestForLodging(vm.selectedSlotDetails.slot_lodgings[i].booking_lodging_rooms[jj],vm.selectedSlotDetails.slot_lodgings[i],indexGuestsSelect, true);
                }
              }
            } else if (vm.selectedSlotDetails.package.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
              vm.selectedSlotDetails.slot_lodgings[i].rooms = alreadySelectedOptions[i].rooms ? alreadySelectedOptions[i].rooms.toString() : '0';
              vm.selectAccommodation(vm.selectedSlotDetails.slot_lodgings[i]);
            }
          }
        }

        if ($stateParams.selectedAddons && vm.slotAddonsDetails && vm.slotAddonsDetails.length) {
          var alreadySelectedOptionsForAddons = JSON.parse($stateParams.selectedAddons);
          $scope.selectedOptionsForAddons = alreadySelectedOptionsForAddons;
          vm.slotAddonsDetails.forEach(function(savedAddon){
            alreadySelectedOptionsForAddons.forEach(function(urlAddon){
              if ((savedAddon.id == urlAddon.id) && urlAddon.selected == true) {
                savedAddon.isSelected = urlAddon.selected;
                savedAddon.addonAdults = urlAddon.total;
                vm.selectedAddons.push(savedAddon);
              }
            })
          })
          updateAddonChild_AdultCount();		// call updateAddonChild_AdultCount() to reset selected people for addons.
        }
      } else if (res.data.code === '404') {
        // $state.go('link-expire');
        $scope.dataNotFound = true;
      }
      $scope.hideLoader();
    }, function () {
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
  };

  if ($rootScope.isSubDomain) {
    $rootScope.getOperatorOnMySiteDetail();
  }

  $scope.getAllCountryName = function () {
    $scope.showLoader();
    userRegisterService.getAllCountry().then(function (data) {
      if (data.data.code === CONST_HELPER.API_RESPONSE.OK) {
        $scope.allcountryName = data.data.data;
      }
      $scope.hideLoader();
    });
  };
  $scope.getAllCountryName();

  if ($rootScope.localCurrencyCode) {
    getSlotAddons(); // call getSlotAddons() initially...
  } else {
    $scope.showLoader();
    $rootScope.$on('get-package-addons', function () {
      getSlotAddons(); // call getSlotAddons() initially...
    });
  }

  /**
 * @ngdoc function
 * @name formatNightlySlotLodging
 * @description
 * # format nightly lodging data as it is in slot lodging...
 */
  function formatNightlySlotLodging (slotDetails) {
    var slotNightlyLodging = [];
    if (slotDetails.lodging_status && vm.selectedSlotDetails.package.package_case !== CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) { // if lodging is added...
      slotDetails.nightly_slots.map(function (nSlot) {
        nSlot.calendar = JSON.parse(nSlot.calendar);
        setAvailableBeds(nSlot);
        var obj = {
          available_units: getMinimumCount('available_room', nSlot.calendar),
          calendar: nSlot.calendar,
          id: nSlot.id,
          lodging: nSlot.lodging,
          lodging_id: nSlot.lodging_id,
          price: getMinimumCount('price', nSlot.calendar),
          private_price: getMinimumCount('private_price', nSlot.calendar),
          total_beds: Number(getMinimumCount('total_bed', nSlot.calendar, nSlot.lodging)),
          usd_price: nSlot.usd_price,
          usd_private_price: nSlot.usd_private_price
        };
        if (isNaN(obj.total_beds)) {
          obj.total_beds = 0;
        }
        vm.adultRange = nSlot.minSeatAvailable;
        slotNightlyLodging.push(obj);
      });
      slotDetails.slot_lodgings = slotNightlyLodging;
    } else { // if no lodging
      var minSeatAvailable = 0; var price = 0;
      slotDetails.nightly_slots.map(function (nSlot) {
        nSlot.calendar = JSON.parse(nSlot.calendar);
        nSlot.calendar.map(function (slotCalendar, key) {
          minSeatAvailable = minSeatAvailable ? Math.min(minSeatAvailable, slotCalendar.available_seat) : slotCalendar.available_seat;
          if (
            // Day is not last for nightly
            (key < (nSlot.calendar.length - 1) && slotDetails.daily_rate_activity != 1 && slotDetails.single_day_activity != 1)
            ||
            // for daily package
            (slotDetails.daily_rate_activity == 1 && slotDetails.single_day_activity != 1)
            ||
            // Last day - we include to price only if one-day adventure
            (nSlot.calendar.length === 1)
            && slotDetails.daily_rate_activity != 1
          ) {
            price += parseInt(slotCalendar.price);
          }
        });
      });
      vm.adultRange = minSeatAvailable;
      slotDetails.slot_lodgings = [];
      slotDetails.adult_price = price;
      slotDetails.usd_adult_price = price;
    }
    slotDetails.available_seat = vm.adultRange;
    if (slotDetails.package_discounts.length) {
      var isNightly = slotDetails.booking_type == 2 && slotDetails.daily_rate_activity == 0 ? true : false;
      $scope.sortDiscountArray(slotDetails.package_discounts, $scope.days, isNightly, slotDetails.package_case);
    }
  }

  function getMinimumCount (prop, obj, lodging) {
    var min = 0;
    obj.map(function (val) {
      var slot_eachDate = moment(val.date);
      // if package case B
      if (vm.selectedSlotDetails.package.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
        if ((slot_eachDate.isSameOrAfter($scope.start_date.format('YYYY-MM-DD')) && slot_eachDate.isBefore($scope.end_date.format('YYYY-MM-DD'))) || moment($scope.start_date.format('YYYY-MM-DD')).isSame($scope.end_date)) {
          if (prop == 'total_bed') {
            val[prop] = val.available_room * lodging.max_guests;
          }
          if (!min || min > val[prop]) {
            min = val[prop];
          }
        }
      } else {
        if (prop == 'total_bed') {
          val[prop] = val.available_room * lodging.max_guests;
        }
        if (!min || min > val[prop]) {
          min = val[prop];
        }
      }
    });
    return min;
  }

  function setAvailableBeds (nightly_slot) {
    var minSeatAvailable = 0;
    nightly_slot.calendar.map(function (slotCalendar) {
      slotCalendar.total_bed = slotCalendar.total_bed || (slotCalendar.available_room * nightly_slot.lodging.max_guests);
      if (vm.selectedSlotDetails.package.package_case == CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
        minSeatAvailable = minSeatAvailable ? Math.min(minSeatAvailable, slotCalendar.available_room) : slotCalendar.available_room;
      } else {
        minSeatAvailable = minSeatAvailable ? Math.min(minSeatAvailable, slotCalendar.available_seat) : slotCalendar.available_seat;
      }
    });
    nightly_slot.minSeatAvailable = minSeatAvailable;
  }

  /**
 * @ngdoc function
 * @name checkMaxGuests
 * @description
 * # calculate the seleceted maximum guest going for package type Accomodation...
 */
  function checkMaxGuests () {
    var maximumGuests = CONST_HELPER.ZERO; var totalAvailableRooms = CONST_HELPER.ZERO;
    vm.selectedSlotDetails.slot_lodgings.map(function (slot) {
      slot.maximum_guests = slot.available_units * slot.lodging.max_guests;
      maximumGuests += slot.available_units * slot.lodging.max_guests;
      totalAvailableRooms += slot.available_units;
      slot.rooms = '0';
    });
    vm.selectedSlotDetails.maximum_guests = maximumGuests;
    vm.selectedSlotDetails.total_available_rooms = totalAvailableRooms;
  }

  vm.updateGuest = function () {
    if (vm.adult !== '0') { vm.noGuestError = false; }
  };

  /**
 * @ngdoc function
 * @name vm.selectAccommodation
 * @description
 * # select the accomodation unit and update the grand total...
 */
  vm.selectAccommodation = function (slot_lodging, autoselsect) {
    if (vm.selectedLodging.indexOf(slot_lodging) === -1) {
      vm.selectedLodging.push(slot_lodging);
    }
    if (parseInt(slot_lodging.rooms) === CONST_HELPER.ZERO) { vm.selectedLodging.splice(vm.selectedLodging.indexOf(slot_lodging), 1); }
    if ($scope.package_id) { // if nightly booking, calculate day wise price.
      var calendarTotal = calculateDayWisePriceCaseB(slot_lodging);
      slot_lodging.priceAllNight = calendarTotal;
    }
    updateAddonChild_AdultCount();
    refreshSelectpicker();
    if (!autoselsect && $scope.selectedOptionsForRooms && $scope.selectedOptionsForRooms.length) {
      $scope.selectedOptionsForRooms.forEach( function (lodging) {
        if (lodging.lodging_id == slot_lodging.id) {
          lodging.rooms = slot_lodging.rooms
        }
      })
      setSelectedLodgingParametrsToUrl(autoselsect);
    }
  };

  /**
 * @ngdoc function
 * @name setSavedAccommodationData
 * @description
 * # set the Accommodation selected values in case of slot get edited...
 */
  function setSavedAccommodationData () {
    vm.selectedSlotDetails.cart_slot_lodging_details.map(function (c_s_l) {
      var slot_lodging_obj = vm.selectedSlotDetails.slot_lodgings.filter(function (obj) { return obj.id === c_s_l.slot_lodging_id; });
      slot_lodging_obj = slot_lodging_obj[0];
      slot_lodging_obj.rooms = JSON.stringify(c_s_l.quantity);
      vm.selectedLodging.push(slot_lodging_obj);
      updateAddonChild_AdultCount();
    });
    vm.adult = JSON.stringify(vm.selectedSlotDetails.cart_slot_details.slot_adult_quantity);
  }

  // package case B End...

  // package case A

  /**
 * @ngdoc function
 * @name checkAndUpdateLodging
 * @description
 * # check if private option is available in lodging for package-charge by seats (case A)...
 */
  function checkAndUpdateLodging () {
    if (vm.selectedSlotDetails.slot_lodgings && vm.selectedSlotDetails.slot_lodgings.length) {
      vm.selectedSlotDetails.total_available_beds = CONST_HELPER.ZERO;
      vm.selectedSlotDetails.slot_lodgings.map(function (lodging) {
        if (!lodging.canBookedPrivate) {
          vm.selectedSlotDetails.total_available_beds += lodging.total_beds;
        }
      });
      vm.selectedSlotDetails.slot_lodgings.map(function (slot_lodging, slot_index) {
        if (slot_lodging.private_price && (vm.selectedSlotDetails.available_seat - CONST_HELPER.ONE <= vm.selectedSlotDetails.total_available_beds)) {
          slot_lodging.remaining_beds = slot_lodging.total_beds;
          slot_lodging.canBookedPrivate = true;
          slot_lodging.moreGuests = '0';
          slot_lodging.rooms = '0';
        } else {
          slot_lodging.moreGuests = '0';
          slot_lodging.rooms = '0';
          slot_lodging.canBookedPrivate = false;
          slot_lodging.remaining_beds = slot_lodging.total_beds;
        }
      });
    }
  }

  /**
 * @ngdoc function
 * @name setSavedAccommodationData
 * @description
 * # set the lodgingd selected values in case of slot get edited...
 */
  // set selected values of slot lodging....
  function setSavedLodgingData () {
    vm.selectedSlotDetails.slot_lodgings.map(function (c_s_l) {
      // get private lodging...
      var slot_lodging_arr = vm.selectedSlotDetails.cart_slot_lodging_details.filter(function (obj) { return obj.slot_lodging_id === c_s_l.id; });
      if (slot_lodging_arr && slot_lodging_arr.length) {
        var rooms = 0; var guests = 0;
        c_s_l.booking_lodging_rooms = [];
        slot_lodging_arr.map(function (sl) {
          rooms += sl.units;
          guests += sl.quantity;
          c_s_l.booking_lodging_rooms = c_s_l.booking_lodging_rooms.concat(sl.booking_lodging_rooms);
        });
        c_s_l.rooms = String(rooms);
        c_s_l.previousSelectedRooms = c_s_l.rooms;

        vm.totalSelectedGuest += guests;
        c_s_l.selected_guest = guests;

        privateCaseCheck(c_s_l);
        $scope.calculateLodgingTotal(c_s_l);
        vm.selectedLodging.push(c_s_l);
      }
    });
    if (vm.totalSelectedGuest > 1) {
      vm.guestMorethanOne = 'true';
      vm.moreGuests = String(vm.totalSelectedGuest);
    } else {
      vm.remainingSelectedGuest = CONST_HELPER.ZERO;
      vm.guestMorethanOne = 'false';
    }
    // update grand Total...
    updateAddonChild_AdultCount();
  }

  // update values according to slot case private or public
  function privateCaseCheck (slot_lodging_obj) {
    slot_lodging_obj.booking_lodging_rooms.map(function (r) {
      var rooms_obj = JSON.parse(r.rooms_guest);
      r.selected = rooms_obj.guest;
      r.selectedGuestType = rooms_obj.type;
      if (rooms_obj.guest) { // private room
        slot_lodging_obj.remaining_beds = slot_lodging_obj.total_beds - slot_lodging_obj.lodging.max_guests;
      } else { // shared room
        slot_lodging_obj.remaining_beds = slot_lodging_obj.total_beds - rooms_obj;
      }
    });
  }


  // update the lodging options when user select more guest...
  $scope.$watch('vm.moreGuests', function (newv, oldv) {
    if (newv !== oldv) {
      newv = parseInt(newv);
      oldv = oldv ? parseInt(oldv) : CONST_HELPER.ZERO;
      var diff = newv - oldv;

      var alreadySelectedGuests = vm.totalSelectedGuest - vm.remainingSelectedGuest;
      var alreadySelectedRooms = vm.maximumRooms - vm.remainingRooms;

      vm.totalSelectedGuest = newv; // get the total guest going
      vm.noGuestError = !(newv >= CONST_HELPER.ONE);
      vm.maximumRooms = newv;
      // calcualte remaining selected guests...
      if (diff < CONST_HELPER.ZERO) {
        vm.remainingSelectedGuest = newv;
        vm.remainingRooms = newv;
        vm.selectedLodging = []; 	// RESET selectedLodging on guest count decreases...
      } else {
        vm.remainingSelectedGuest = newv - alreadySelectedGuests;
        vm.remainingRooms = newv - alreadySelectedRooms;
      }

      vm.selectedSlotDetails.slot_lodgings.map(function (sl) {
        sl.canBookedPrivate = sl.canBookedPrivate ? sl.canBookedPrivate : false;
        if (diff < CONST_HELPER.ZERO) {			// if guest decreased then reset the selected lodging...
          sl.remaining_beds = sl.total_beds;
          sl.moreGuests = '0';
          sl.rooms = '0';
          sl.selected_guest = CONST_HELPER.ZERO;
          sl.private_guests = '0';
          sl.previousSelectedRooms = CONST_HELPER.ZERO;
          sl.booking_lodging_rooms = [];
        }
        updateOptionsForLodging(sl);
      });

      // update grand Total...
      vm.lodgingForall = checkLodgingForall();
      updateAddonChild_AdultCount();
      refreshSelectpicker();
    }
  });

  function setSelectedLodgingParametrsToUrl(autoselsect) {
    if (!autoselsect) {
      if (vm.selectedSlotDetails.package.booking_type == 1) {
        $state.go('package-slot-addons-summary', {
          'adults': $stateParams.adults,
          'selected_slot_id': $stateParams.selected_slot_id,
          'lodging_id': $stateParams.lodging_id ? $stateParams.lodging_id : '',
          'referral_id': $stateParams.referral_id,
          'selectedRoomOptions': JSON.stringify($scope.selectedOptionsForRooms),
          'selectedAddons': JSON.stringify($scope.selectedOptionsForAddons)
        });
      } else {
        $state.go('package-slot-addons-summary', {
          'adults': $stateParams.adults,
          'package_id': $stateParams.package_id,
          'lodging_id': $stateParams.lodging_id ? $stateParams.lodging_id : '',
          'start_date': $stateParams.start_date ? $stateParams.start_date : '',
          'end_date': $stateParams.end_date ? $stateParams.end_date : '',
          'referral_id': $stateParams.referral_id,
          'selectedRoomOptions': JSON.stringify($scope.selectedOptionsForRooms),
          'selectedAddons': JSON.stringify($scope.selectedOptionsForAddons)
        });
      }
    }
  }

  // lodging selection from other guest added....
  vm.SelectRoomsForLodging = function (slot_lodging, index, is_private, autoselsect) {
    slot_lodging.previousSelectedRooms = slot_lodging.previousSelectedRooms ? slot_lodging.previousSelectedRooms : CONST_HELPER.ZERO;
    var room_diff = Math.abs(slot_lodging.previousSelectedRooms - slot_lodging.rooms);

    if (!slot_lodging.rooms) {
      return;
    }
    // CHECK LODGING IS INCREASED OR DECREASED...
    if (slot_lodging.previousSelectedRooms && parseInt(slot_lodging.previousSelectedRooms) >= parseInt(slot_lodging.rooms)) {			// if seats goes up
      vm.selectedLodging = [];
      vm.roomsInreased = false;
      vm.remainingRooms = vm.remainingRooms + room_diff;
      getRemainingGuest(index);
    } else {
      vm.roomsInreased = true;
      vm.remainingRooms = vm.remainingRooms - room_diff;
    }
    initiateSlotLodgingRooms(slot_lodging);

    if (!+slot_lodging.rooms && !autoselsect) {
      var slotIndex = vm.selectedLodging.indexOf(slot_lodging);
      vm.selectedLodging.splice(slotIndex, 1);
    }
    updateSlotLodgingData(index);
    $timeout(function () {
      updateAddonChild_AdultCount();
      refreshSelectpicker();
    });
    if (!autoselsect) {
      $scope.selectedOptionsForRooms.forEach(function(lodging){
        if (lodging.lodging_id == slot_lodging.id) {
          lodging.rooms = [];
          for (var i = 0; i < +slot_lodging.rooms; i++) {
             lodging.rooms.push({
               id: i,
               total: slot_lodging.booking_lodging_rooms[i].rooms_guest
             })
          }
        }
      });
    }
    setSelectedLodgingParametrsToUrl(autoselsect);
  };

  // function to initiate the rooms array objects
  function initiateSlotLodgingRooms (slot_lodgings) {
    var selected_rooms = vm.roomsInreased ? parseInt(slot_lodgings.rooms) - parseInt(slot_lodgings.previousSelectedRooms) : parseInt(slot_lodgings.rooms);
    slot_lodgings.booking_lodging_rooms = vm.roomsInreased ? slot_lodgings.booking_lodging_rooms || [] : [];
    for (var i = 0; i < parseInt(selected_rooms); i++) {
      var roomsObjects = { id: i, rooms_guest: '0', slot_lodging_id: slot_lodgings.id };
      if (slot_lodgings.canBookedPrivate) {
        roomsObjects.guestOption = $filter('guestRange')([], Math.min(vm.remainingSelectedGuest, slot_lodgings.lodging.max_guests, slot_lodgings.total_beds), slot_lodgings.lodging.max_guests, slot_lodgings.lodging.min_private_guests);
      } else {
        roomsObjects.guestOption = $filter('range')([], Math.min(vm.remainingSelectedGuest, slot_lodgings.lodging.max_guests, slot_lodgings.total_beds));
      }
      slot_lodgings.booking_lodging_rooms.push(roomsObjects);
    }
  }

  // function to return remaining guest to be selected...
  function getRemainingGuest (index) {
    if (vm.selectedSlotDetails.slot_lodgings && vm.selectedSlotDetails.slot_lodgings.length) {
      var lodging_slelected_guest = 0;
      vm.selectedSlotDetails.slot_lodgings.map(function (sl, i) {
        if (i !== index) { return; }
        if (sl.booking_lodging_rooms && sl.booking_lodging_rooms.length) {
          sl.booking_lodging_rooms.map(function (r) {
            var guest = JSON.parse(r.rooms_guest);
            var selected_guest = guest.hasOwnProperty('guest') ? guest.guest : guest;

            lodging_slelected_guest = lodging_slelected_guest + selected_guest;
          });
        }
      });
      vm.remainingSelectedGuest = vm.remainingSelectedGuest + lodging_slelected_guest;
    }
  }

  // function to update the slot lodging objects on guest selection changes...
  vm.SelectGuestForLodging = function (rooms, slot_lodging, index, autoSelect) {
    var room_guest_obj ;
    if (typeof rooms.rooms_guest == 'string') {
      room_guest_obj = JSON.parse(rooms.rooms_guest);
    } else {
      room_guest_obj = rooms.rooms_guest;
    }

    var guest_type;
    var rooms_guest = 0;
    if (room_guest_obj.guest) {
      rooms_guest = room_guest_obj.guest;
      guest_type = room_guest_obj.type;
    } else {
      rooms_guest = room_guest_obj;
      guest_type = 'shared';
    }
    rooms.selected = rooms_guest;
    rooms.selectedGuestType = guest_type;
    rooms.previousSelectedGuests = rooms.previousSelectedGuests ? rooms.previousSelectedGuests : CONST_HELPER.ZERO;
    var guest_diff = Math.abs(rooms.previousSelectedGuests - rooms_guest);

    // CHECK LODGING IS INCREASED OR DECREASED...
    if (rooms.previousSelectedGuests && parseInt(rooms.previousSelectedGuests) >= parseInt(rooms_guest)) {			// if seats goes up
      vm.guestsInreased = false;
    } else {
      vm.guestsInreased = true;
    }

    var ind = vm.selectedLodging.indexOf(slot_lodging);
    // UPDATE SELECTED SLOTS...
    if (ind === -1) {	// check if slot lodging is not already added...
      slot_lodging.selected_guest = angular.copy(parseInt(rooms_guest));
      slot_lodging.guest_type = guest_type;
      vm.selectedLodging.push(slot_lodging);
      vm.remainingSelectedGuest = vm.remainingSelectedGuest - guest_diff;
    } else {
      if (vm.guestsInreased) {
        vm.selectedLodging[ind].selected_guest = vm.selectedLodging[ind].selected_guest + guest_diff;
        vm.remainingSelectedGuest = vm.remainingSelectedGuest - guest_diff;
      } else {
        vm.selectedLodging[ind].selected_guest = vm.selectedLodging[ind].selected_guest - guest_diff;
        vm.remainingSelectedGuest = vm.remainingSelectedGuest + guest_diff;
      }
    }

    slot_lodging.booking_lodging_rooms.map(function (rooms, i) {
      if (i !== index) {	// find the other rooms of that lodging...
        if (slot_lodging.canBookedPrivate) {
          rooms.guestOption = $filter('guestRange')([], Math.min(vm.remainingSelectedGuest + getSelectedRoomGuest(rooms), slot_lodging.lodging.max_guests, slot_lodging.total_beds), slot_lodging.lodging.max_guests, slot_lodging.lodging.min_private_guests);
        } else {
          rooms.guestOption = $filter('range')([], Math.min(vm.remainingSelectedGuest + getSelectedRoomGuest(rooms), slot_lodging.lodging.max_guests, slot_lodging.total_beds));
        }
      }
      rooms.previousSelectedGuests = getSelectedRoomGuest(rooms); 	// store the previously selected guest count...
    });

    if (!slot_lodging.selected_guest) {
      vm.selectedLodging.splice(ind, 1);
    }

    // update the slot lodging rooms data of other lodging...
    updateSlotLodgingRoomsData(slot_lodging);
    $scope.calculateLodgingTotal(slot_lodging);
    refreshSelectpicker();
    if (!autoSelect) {
      $scope.selectedOptionsForRooms.forEach(function (lodging) {
        if (rooms.slot_lodging_id == lodging.lodging_id) {
          lodging.rooms[index].total = rooms.selected;
          if (rooms.selectedGuestType == "private") {
            lodging.rooms[index].type = "private";
          } else {
            lodging.rooms[index].type = "shared";
          }
        }
      })
    }
    setSelectedLodgingParametrsToUrl(autoSelect);
  };
  // function to return the selected guests in rooms
  function getSelectedRoomGuest (rooms) {
    var selected_room_guest = 0;
    var rooms_guest_obj = typeof rooms.rooms_guest == 'string' ? JSON.parse(rooms.rooms_guest) : rooms.rooms_guest;

    if (rooms_guest_obj.hasOwnProperty('guest')) {
      selected_room_guest = rooms_guest_obj.guest;
    } else {
      selected_room_guest = rooms.rooms_guest;
    }
    return parseInt(selected_room_guest);
  }

  $scope.disableLodgingOption = function (sl) {
    if (!sl.canBookedPrivate && sl.remaining_beds === CONST_HELPER.ZERO) {
      return true;
    } else if (sl.canBookedPrivate && sl.remaining_beds < sl.lodging.max_guests) {
      return true;
    }
    return false;
  };

  function updateSlotLodgingData (index) {
    vm.selectedSlotDetails.slot_lodgings.map(function (sl, i) {
      if (i !== index) {
        updateOptionsForLodging(sl);		// update options of other lodging...
        // sl.options === Math.min(sl.options, vm.remainingSelectedGuest);
      } else {
        switch (String(sl.canBookedPrivate)) {
          case 'true':
            // sl.private_guests=String(Math.min(sl.private_guests,parseInt(sl.moreGuests)*sl.lodging.max_guests));
            sl.previousPrivateGuests = parseInt(sl.private_guests);
            break;
        }
      }
      sl.previousSelectedRooms = angular.copy(sl.rooms);
    });
  }

  function updateOptionsForLodging (sl) {
    var selected_rooms = sl.rooms ? angular.copy(sl.rooms) : '0';
    var total_rooms = Math.ceil((sl.total_beds - (parseInt(selected_rooms) * sl.lodging.max_guests)) / sl.lodging.max_guests);
    sl.total_rooms = total_rooms;
    sl.options = Math.min(vm.remainingRooms, total_rooms, vm.remainingSelectedGuest) + parseInt(selected_rooms);

    if (parseInt(selected_rooms) >= CONST_HELPER.ONE && sl.booking_lodging_rooms && sl.booking_lodging_rooms.length) {
      sl.booking_lodging_rooms.map(function (rooms, i) {
        if (sl.canBookedPrivate) {
          rooms.guestOption = $filter('guestRange')([], Math.min(vm.remainingSelectedGuest + getSelectedRoomGuest(rooms), sl.lodging.max_guests, sl.total_beds), sl.lodging.max_guests, sl.lodging.min_private_guests);
        } else {
          rooms.guestOption = $filter('range')([], Math.min(vm.remainingSelectedGuest + getSelectedRoomGuest(rooms), sl.lodging.max_guests, sl.total_beds));
        }
        rooms.previousSelectedGuests = getSelectedRoomGuest(rooms); 	// store the previously selected guest count...
      });
    }
  }

  function updateSlotLodgingRoomsData (slot_lodging) {
    vm.selectedSlotDetails.slot_lodgings.map(function (sl, i) {
      if (slot_lodging === 'init' || sl.id !== slot_lodging.id) {	// update the other lodging options...
        updateOptionsForLodging(sl);
      }
    });
    refreshSelectpicker();
  }

  $scope.calculateLodgingTotal = function (slot_lodging) {
    var total = 0;
    if (!$scope.package_id) { // slot booking
      slot_lodging.booking_lodging_rooms.map(function (r) {
        r.is_private_charged = false;
        var rooms_guest = typeof r.rooms_guest == 'string' ? JSON.parse(r.rooms_guest) : r.rooms_guest;
        var guest_selected = rooms_guest.guest ? rooms_guest.guest : rooms_guest;
        if (rooms_guest.type === 'private' && rooms_guest.guest <= slot_lodging.lodging.min_private_guests) {
          r.is_private_charged = true;
          r.total_price = guest_selected * slot_lodging.private_price;	// total price including private price...
          r.total_price_usd = guest_selected * slot_lodging.usd_private_price;	// total price including private price...

          total = total + guest_selected * slot_lodging.private_price;
        } else {
          total = total + guest_selected * slot_lodging.price;
        }
      });
      slot_lodging.sub_total = total;
    } else { // nightly booking
      total = calculateDayWisePrice(slot_lodging);
      slot_lodging.sub_total = total;
    }
    updateAddonChild_AdultCount();	// update the total price...
    return total;
  };
  // for case A
  function calculateDayWisePrice (slot_lodging) {
    var total = CONST_HELPER.ZERO;
    slot_lodging.booking_lodging_rooms.map(function (r) {
      r.is_private_charged = false;
      var rooms_guest = JSON.parse(r.rooms_guest);
      var guest_selected = rooms_guest.guest ? rooms_guest.guest : rooms_guest;
      slot_lodging.calendar.map(function (eachDates) {
        if (rooms_guest.type === 'private' && rooms_guest.guest <= slot_lodging.lodging.min_private_guests) {
          r.is_private_charged = true;
          r.total_price = guest_selected * slot_lodging.private_price;	// total price including private price...
          r.total_price_usd = guest_selected * slot_lodging.usd_private_price;	// total price including private price...

          total = total + guest_selected * eachDates.private_price;
        } else {
          total = total + guest_selected * eachDates.price;
        }
      });
      if (vm.selectedSlotDetails.booking_type == 2 && vm.selectedSlotDetails.daily_rate_activity != 1) {
        if (rooms_guest.type === 'private' && rooms_guest.guest <= slot_lodging.lodging.min_private_guests) {
          total= total -  guest_selected * slot_lodging.calendar[slot_lodging.calendar.length - 1].private_price;
        } else {
          total = total - guest_selected * slot_lodging.calendar[slot_lodging.calendar.length - 1].price;
        }
      }
    });
    return total;
  }

  // calendar day wise price for case B
  function calculateDayWisePriceCaseB (slot_lodging) {
    var total = 0;
    slot_lodging.calendar.map(function (eachDates, key) {
      var slot_eachDate = moment(eachDates.date);
      if ( vm.selectedSlotDetails.daily_rate_activity == 0 && (slot_eachDate.isSameOrAfter($scope.start_date.format('YYYY-MM-DD')) && slot_eachDate.isBefore($scope.end_date.format('YYYY-MM-DD'))) || (moment($scope.start_date).isSame($scope.end_date.format('YYYY-MM-DD')))) {
        total += parseInt(eachDates.price);
      }else if( vm.selectedSlotDetails.daily_rate_activity == 1 && (slot_eachDate.isSameOrAfter($scope.start_date.format('YYYY-MM-DD')) && slot_eachDate.isSameOrBefore($scope.end_date.format('YYYY-MM-DD'))) || (moment($scope.start_date).isSame($scope.end_date.format('YYYY-MM-DD')))){
        total += parseInt(eachDates.price);
      }
    });
    return total;
  }

  $scope.$watch('vm.guestMorethanOne', function () {
    refreshSelectpicker();
  });

  function refreshSelectpicker () {
    $timeout(function () {
      $('.selectpicker').selectpicker('refresh');
    });
  }

  function updateSelectedAddons_People () {
    if (vm.slotAddonsDetails) {
      vm.slotAddonsDetails.map(function (addon, index) {
        if (addon.cart_addons_details) {
          addon.addonAdults = JSON.stringify(addon.cart_addons_details.package_addon_adult_quantity);
          // addon.addonChilds=JSON.stringify(addon.cart_addons_details.package_addon_child_quantity);
          if (isSelectAddonsForAll(addon.cart_addons_details) === false) {
            vm.selectForEveryone = false;
          }
          addon.isSelected = true;
          vm.selectAddons(addon, 'OnLoad');
        }
      });
    }
  }

  function isSelectAddonsForAll (addon_detail) {
    return addon_detail.package_addon_adult_quantity === vm.adult;
  }

  // select user addons
  vm.selectAddons = function (addon, isLoad, autoSelect) {
    if (addon.isSelected) {
      if (vm.selectForEveryone) {
        if (vm.selectedSlotDetails.package.package_case !== 1 || vm.selectedSlotDetails.package.lodging_status === CONST_HELPER.EVENT_LODGING.NO || !vm.selectedSlotDetails.slot_lodgings.length) {
          addon.addonAdults = vm.adult;
        } else {
          addon.addonAdults = parseInt(vm.totalSelectedGuest);
        }
      }
      vm.selectedAddons.push(addon);
    } else {
      vm.selectedAddons.splice(vm.selectedAddons.indexOf(addon), 1);
    }
    if (isLoad !== 'OnLoad') { updateAddonChild_AdultCount(); }
    if (!autoSelect) {
      $scope.selectedOptionsForAddons.forEach (function (eachAddon) {
        if (eachAddon.id == addon.id) {
          eachAddon.selected = addon.isSelected;
          eachAddon.total = addon.addonAdults;
        }
      })
      setSelectedLodgingParametrsToUrl(autoSelect);
    }
  };

  vm.selectAddonForEveryone = function (autoSelect) {
    var totalGuests = 0;
    vm.slotAddonsDetails.map(function (addon, index) {
      if (vm.selectForEveryone) {
        if (vm.selectedSlotDetails.package.package_case !== 1 || vm.selectedSlotDetails.package.lodging_status === CONST_HELPER.EVENT_LODGING.NO || !vm.selectedSlotDetails.slot_lodgings.length) {
          addon.addonAdults = vm.adult;
          totalGuests = vm.adult;
        } else {
          addon.addonAdults = parseInt(vm.totalSelectedGuest);
          totalGuests = parseInt(vm.totalSelectedGuest);
        }
      }
    });
    if (!autoSelect) {
      $scope.selectedOptionsForAddons.forEach (function (eachAddon) {
        eachAddon.total = totalGuests;
      })
      setSelectedLodgingParametrsToUrl(autoSelect);
    }

    updateAddonChild_AdultCount();		// call updateAddonChild_AdultCount() to reset selected people for addons.
    refreshSelectpicker();
  };

  vm.mergePrivateGuests = function (sl) {
    sl.selected_guest = parseInt(sl.private_guests) + 1;
    return parseInt(sl.private_guests) + 1;
  };
  $scope.$watch('vm.adult', function (newv, oldv) {
    if (newv && newv !== oldv) {
      $timeout(function () {
        $('.addon-adult-selectpicker').selectpicker('refresh');
      }, 0);
      updateAddonChild_AdultCount();
      vm.totalSelectedGuest = newv;
      vm.lodgingForall = checkLodgingForall();
    }
  });

  $scope.addonGuestChange = function (addon, autoSelect) {
    if (addon.addonAdults == '0' || addon.addonAdults[0] == '0') {
      addon.addonAdults = 1;
    }
    if (!autoSelect) {
      $scope.selectedOptionsForAddons.forEach (function (eachAddon) {
        if (eachAddon.id == addon.id) {
          eachAddon.total = addon.addonAdults;
        }
      })
      setSelectedLodgingParametrsToUrl(autoSelect);
    }
    updateAddonChild_AdultCount();
  };

  // function to updated selected people and calulate Grand total.
  function updateAddonChild_AdultCount (forEveryone) {
    var grandTotal = CONST_HELPER.ZERO;
    if (vm.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE) {
      // package case C
      grandTotal = grandTotal + vm.selectedSlotDetails.adult_price;
    } else if (vm.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE || vm.selectedSlotDetails.package.lodging_status === CONST_HELPER.EVENT_LODGING.NO || !vm.selectedSlotDetails.slot_lodgings.length) {
      // package case A with out lodging...
      grandTotal = grandTotal + vm.adult * +vm.selectedSlotDetails.adult_price;
    } else if (vm.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_SEAT && vm.selectedSlotDetails.package.lodging_status === CONST_HELPER.EVENT_LODGING.YES && vm.selectedSlotDetails.slot_lodgings.length) {
      // package case A with lodging...
      vm.selectedLodging.map(function (lodge, index) {
        grandTotal = grandTotal + +lodge.sub_total || 0;
      });
    } else {	// package case B lodging...
      vm.selectedSlotDetails.selectedGuests = CONST_HELPER.ZERO;
      vm.selectedLodging.map(function (lodge, index) {
        if ($scope.package_id) {
          grandTotal = grandTotal + (lodge.rooms ? parseInt(lodge.rooms) : 1) * +lodge.priceAllNight;
        } else {
          grandTotal = grandTotal + (lodge.rooms ? parseInt(lodge.rooms) : 1) * +lodge.price;
        }
        vm.selectedSlotDetails.selectedGuests += lodge.rooms * lodge.lodging.max_guests;
      });
    }
    vm.promoCodeGrandTotal = angular.copy(grandTotal);
    vm.adultTotalPrice = vm.adult * vm.selectedSlotDetails.adult_price;
    if (vm.slotAddonsDetails) {
      vm.slotAddonsDetails.map(function (addon, index) {
        if (addon.isSelected) {
          grandTotal = grandTotal + (addon.addonAdults ? +addon.addonAdults * addon.adult_price : CONST_HELPER.ZERO * addon.adult_price);
        }
      });
    }
    vm.totalGrandTotal = grandTotal;
  };

  // add to cart
  vm.addToCart = function (callback) {
    vm.lodgingForEveryoneErr = false;
    var cart_session_id = $cookies.get('cart_session_id');
    var data = {
      package_id: vm.selectedSlotDetails.package.id,
      cart_session_id: cart_session_id || userAdventureDetailService.generateCartSessionId(),
      total_amount: vm.totalGrandTotal,
      local_currency_code: vm.selectedSlotDetails.currency.code,
      referral_id: $stateParams.referral_id ? $stateParams.referral_id : '',
      package_addons: getAddonsDetails(),
      package_slot_id: vm.selectedSlotDetails.id,
      booking_source: $rootScope.siteInIFrame ? CONST_HELPER.OPERATOR_SITE : '',
      is_nightly: $scope.package_id ? CONST_HELPER.ONE : CONST_HELPER.ZERO
    };
    if ($scope.package_id) {
      data.from_date = $scope.start_date.format('YYYY/MM/DD');
      data.to_date = $scope.end_date.format('YYYY/MM/DD');
    }
    var token = $scope.user.token ? $scope.user.token : '';

    if (vm.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE || vm.selectedSlotDetails.package.lodging_status === CONST_HELPER.EVENT_LODGING.NO || !vm.selectedSlotDetails.slot_lodgings.length) {
      data.package_slot = {
        package_slot_id: vm.selectedSlotDetails.id,
        slot_adult_quantity: vm.adult,
        slot_adult_price: getSlotPrice(),
        slot_usd_adult_price: getSlotPriceUsd()
      };
    } else if (vm.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_SEAT && vm.selectedSlotDetails.package.lodging_status === CONST_HELPER.EVENT_LODGING.YES && vm.selectedSlotDetails.slot_lodgings.length) {
      if (vm.totalSelectedGuest === '0') {
        vm.noGuestError = true;
        return;
      } else if (vm.remainingSelectedGuest) {		// check if lodging is added for every one or not...
        vm.lodgingForEveryoneErr = true;
        return;
      }
      data.package_slot = {
        package_slot_id: vm.selectedSlotDetails.id,
        slot_adult_quantity: CONST_HELPER.ZERO,
        slot_adult_price: CONST_HELPER.ZERO,
        slot_usd_adult_price: CONST_HELPER.ZERO
      };
      data.package_slot_lodgings = getSlotLodgingDetails();		// package case A with Lodging
    } else {							// for case B ...
      if (vm.adult === '0') {
        vm.noGuestError = true;
        return;
      } else if (parseInt(vm.adult) > vm.selectedSlotDetails.selectedGuests) {
        vm.lodgingForEveryoneErr = true;
        return;
      } else {
        vm.lodgingForEveryoneErr = false;
      }
      data.package_slot = {
        package_slot_id: vm.selectedSlotDetails.id,
        slot_adult_quantity: vm.adult,
        slot_adult_price: CONST_HELPER.ZERO,
        slot_usd_adult_price: CONST_HELPER.ZERO
      };
      data.package_slot_lodgings = getAccommodationDetails();		// package case  B
    }
    $scope.showLoader();

    userAdventureDetailService.addToCart(data, token).then(function (res) {
      if (callback) { callback(res); }

      if (res.data && res.data.message === 'Success') {
        var now = new Date();
        // this will set the expiration to 12 months
        var exp = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
        $cookies.put('cart_id', res.data.cart_id, {
          expires: exp
        });
        $rootScope.cart_id = res.data.cart_id;

        var GAdata = {
          id: vm.selectedSlotDetails.package.id.toString(),
          name: vm.selectedSlotDetails.package.name,
          category: CONST_HELPER.PACKAGE_CASE_NAME[vm.selectedSlotDetails.package.package_case],
          price: vm.totalGrandTotal.toString(),
          quantity: (vm.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_SEAT && vm.selectedSlotDetails.package.lodging_status === CONST_HELPER.EVENT_LODGING.YES) ? vm.totalSelectedGuest.toString() : vm.adult.toString(),
          actionField: { 'step': 2, 'option': 'Heli Add To Cart' },
          event_type: 'addToCart',
          event: 'gtm.heli.addtocart'
        };
        $rootScope.$broadcast('addEventToGTM', GAdata);	// add data to Google tag manager...

        $rootScope.$broadcast('addEventToEC', GAdata);	// add data to enhanced ecommerce

        let tagGAData = {
          currency: 'USD',
          value:  res.data.google_analytics.usd_total_amount,
          items: [
            {
              item_id: res.data.google_analytics.booking_id,
              item_name: res.data.google_analytics.package_name,
              affiliation: res.data.google_analytics.operator_name,
              price: res.data.google_analytics.usd_total_amount,
              quantity: 1
            }
          ]
        };
        $rootScope.$broadcast('googleTagManagerAddToCart', tagGAData);

        if (!callback) {
          $scope.hideLoader();
          $state.go('mycart');
        }
      }
    }, function () {
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
  };

  // check lodging for everyone
  function checkLodgingForall () {
    if (vm.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE || vm.selectedSlotDetails.package.lodging_status === CONST_HELPER.EVENT_LODGING.NO || !vm.selectedSlotDetails.slot_lodgings.length) {
      return vm.adult !== '0';
    } else if (vm.selectedSlotDetails.package.package_case === CONST_HELPER.PACKAGE_CASE.PER_ACCOMMODATION) {
      if (!parseInt(vm.adult) || parseInt(vm.adult) > vm.selectedSlotDetails.selectedGuests) {
        return false;
      } else {
        vm.lodgingForEveryoneErr = false;
        return true;
      }
    }

    var guestsHaveLodging = CONST_HELPER.ZERO;
    vm.selectedLodging.map(function (lodge) {
      guestsHaveLodging += (lodge.selected_guest ? parseInt(lodge.selected_guest) : CONST_HELPER.ZERO);
    });
    if (parseInt(vm.totalSelectedGuest) === guestsHaveLodging) {
      vm.lodgingForEveryoneErr = false;
      return true;
    } else {
      return false;
    }
  }

  // calculate the slot price
  function getSlotPrice () {
    switch (vm.selectedSlotDetails.package.package_case) {
      case CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE:
        return vm.selectedSlotDetails.adult_price.toFixed(2);

      default:
        return parseInt(vm.adult) * vm.selectedSlotDetails.adult_price.toFixed(2);
    }
  }
  // calculate the slot price USD
  function getSlotPriceUsd () {
    switch (vm.selectedSlotDetails.package.package_case) {
      case CONST_HELPER.PACKAGE_CASE.PER_ENTIRE_PACKAGE:
        return vm.selectedSlotDetails.usd_adult_price.toFixed(2);

      default:
        return parseInt(vm.adult) * vm.selectedSlotDetails.usd_adult_price.toFixed(2);
    }
  }

  // get Addons details
  function getAddonsDetails () {
    var arr = [];
    if (vm.selectedAddons && vm.selectedAddons.length !== CONST_HELPER.ZERO) {
      vm.selectedAddons.map(function (addon) {
        if (!addon.addonAdults) {
          addon.addonAdults = CONST_HELPER.ZERO;
        }
        if (!addon.addonChilds) {
          addon.addonChilds = CONST_HELPER.ZERO;
        }
        var obj = {
          package_addon_id: addon.id,
          package_addon_adult_quantity: addon.addonAdults,
          // package_addon_child_quantity:addon.child_price>0?addon.addonChilds:0,
          package_addon_adult_price: parseInt(addon.addonAdults) * addon.adult_price,
          // package_addon_child_price:parseInt(addon.addonChilds)*addon.child_price,
          package_addon_usd_adult_price: parseInt(addon.addonAdults) * addon.usd_adult_price
          // package_addon_usd_child_price:parseInt(addon.addonChilds)*addon.usd_child_price
        };
        arr.push(obj);
      });
    }
    return arr;
  }

  function getAccommodationDetails () {
    var arr = [];
    if (vm.selectedLodging && vm.selectedLodging.length !== CONST_HELPER.ZERO) {
      vm.selectedLodging.map(function (lodge) {
        var obj = {
          slot_lodging_id: lodge.id,
          quantity: lodge.rooms,
          price: lodge.rooms * lodge.price,
          usd_price: lodge.rooms * lodge.usd_price,
          private_price: CONST_HELPER.ZERO,
          usd_private_price: CONST_HELPER.ZERO
        };
        if ($scope.package_id) { // if nightly booking
          var total = calculateAccommodationRoomPrice(lodge);
          obj.price = total;
          obj.usd_price = total * $scope.usdCurencyRate;
        }
        arr.push(obj);
      });
    }
    return arr;
  }

  function getSlotLodgingDetails () {
    var arr = [];
    if (vm.selectedLodging && vm.selectedLodging.length !== CONST_HELPER.ZERO) {
      vm.selectedLodging.map(function (lodge) {
        var sharedGuestRoom = []; var privateGuestRoom = []; var slotLodgingSharedRoom = []; var slotLodgingPrivateRoom = [];
        if (lodge.canBookedPrivate) {
          // get the selected slot lodging rooms containing shared guest...
          sharedGuestRoom = lodge.booking_lodging_rooms.filter(function (r) {
            var rooms_guest_obj = JSON.parse(r.rooms_guest);
            return rooms_guest_obj.type === 'shared';
          });

          if (sharedGuestRoom && sharedGuestRoom.length) {
            var quantity = 0;
            sharedGuestRoom.map(function (sr) {
              var rooms_guest_obj = JSON.parse(sr.rooms_guest);
              quantity += rooms_guest_obj.guest;
              var sharedRoomObj = {
                slot_lodging_id: lodge.id,
                rooms_guest: sr.rooms_guest
              };
              slotLodgingSharedRoom.push(sharedRoomObj);
            });
            var obj = {
              slot_lodging_id: lodge.id,
              quantity: quantity,
              price: quantity * lodge.price || CONST_HELPER.ZERO,
              usd_price: quantity * lodge.usd_price || CONST_HELPER.ZERO,
              private_price: CONST_HELPER.ZERO,
              usd_private_price: CONST_HELPER.ZERO,
              is_private: CONST_HELPER.ZERO,
              has_single_guest: CONST_HELPER.ZERO,
              units: sharedGuestRoom.length || CONST_HELPER.ZERO,
              booking_lodging_rooms: slotLodgingSharedRoom
            };
            if ($scope.package_id) { // if nightly booking
              var total = calculateBookingRoomPrice(sharedGuestRoom, lodge);
              obj.price = total;
              obj.usd_price = total * $scope.usdCurencyRate;
            }
            arr.push(obj);
          }

          // get the selected slot lodging rooms containing private guest...
          privateGuestRoom = lodge.booking_lodging_rooms.filter(function (r) {
            var rooms_guest_obj = JSON.parse(r.rooms_guest);
            return rooms_guest_obj.type === 'private';
          });

          if (privateGuestRoom && privateGuestRoom.length) {
            var quantity = 0; var private_price = 0; var usd_private_price = 0;
            privateGuestRoom.map(function (sr) {
              var rooms_guest_obj = JSON.parse(sr.rooms_guest);

              quantity += rooms_guest_obj.guest;
              private_price += sr.is_private_charged ? sr.total_price : rooms_guest_obj.guest * lodge.price;
              usd_private_price += sr.is_private_charged ? sr.total_price_usd : rooms_guest_obj.guest * lodge.usd_price;

              var privateRoomObj = {
                slot_lodging_id: lodge.id,
                rooms_guest: sr.rooms_guest
              };
              slotLodgingPrivateRoom.push(privateRoomObj);
            });
            var obj = {
              slot_lodging_id: lodge.id,
              quantity: quantity,
              price: CONST_HELPER.ZERO,
              usd_price: CONST_HELPER.ZERO,
              private_price: private_price || CONST_HELPER.ZERO,
              usd_private_price: usd_private_price || CONST_HELPER.ZERO,
              is_private: CONST_HELPER.ONE,
              has_single_guest: CONST_HELPER.ZERO,
              units: privateGuestRoom.length || CONST_HELPER.ZERO,
              booking_lodging_rooms: slotLodgingPrivateRoom
            };
            if ($scope.package_id) { // if nightly booking
              var total = calculateBookingRoomPrice(privateGuestRoom, lodge);
              obj.private_price = total;
              obj.usd_private_price = total * $scope.usdCurencyRate;
            }
            arr.push(obj);
          }
        }

        if (!lodge.canBookedPrivate) {
          // eslint-disable-next-line no-unused-expressions
          sharedGuestRoom = [];
          slotLodgingSharedRoom = [];
          // get the selected slot lodging rooms containing shared guest...
          sharedGuestRoom = lodge.booking_lodging_rooms.filter(function (r) {
            var rooms_guest_obj = JSON.parse(r.rooms_guest);
            return rooms_guest_obj > 0;
          });

          sharedGuestRoom.map(function (sr) {
            var sharedRoomObj = {
              slot_lodging_id: lodge.id,
              rooms_guest: sr.rooms_guest
            };
            slotLodgingSharedRoom.push(sharedRoomObj);
          });

          var obj = {
            slot_lodging_id: lodge.id,
            quantity: !lodge.canBookedPrivate ? lodge.selected_guest : parseInt(lodge.private_guests),
            price: !lodge.canBookedPrivate ? lodge.selected_guest * lodge.price : CONST_HELPER.ZERO,
            usd_price: !lodge.canBookedPrivate ? lodge.selected_guest * lodge.usd_price : CONST_HELPER.ZERO,
            private_price: CONST_HELPER.ZERO,
            usd_private_price: CONST_HELPER.ZERO,
            is_private: CONST_HELPER.ZERO,
            has_single_guest: CONST_HELPER.ZERO,
            units: slotLodgingSharedRoom.length || CONST_HELPER.ZERO,
            booking_lodging_rooms: slotLodgingSharedRoom
          };
          if ($scope.package_id) { // if nightly booking
            var total = calculateBookingRoomPrice(sharedGuestRoom, lodge);
            obj.price = total;
            obj.usd_price = total * $scope.usdCurencyRate;
          }
          arr.push(obj);
        }
      });
    }
    return arr;
  }

  // function to return the commulative price of each day for per_seat nightly booking ...
  function calculateBookingRoomPrice (booking_lodging_rooms, slot_lodging) {
    var total = CONST_HELPER.ZERO;
    booking_lodging_rooms.map(function (r) {
      r.is_private_charged = false;
      var rooms_guest = JSON.parse(r.rooms_guest);
      var guest_selected = rooms_guest.guest ? rooms_guest.guest : rooms_guest;
      var slotLodgingCalendar;

      // For nightly bookings we shouldn't include last calendar day for calculating total price
      if ($scope.package_id && (slot_lodging.calendar.length > 1) && vm.selectedSlotDetails.daily_rate_activity != 1) {
        slotLodgingCalendar = slot_lodging.calendar.slice(0, slot_lodging.calendar.length - 1);
      } else {
        slotLodgingCalendar = slot_lodging.calendar;
      }

      slotLodgingCalendar.map(function (eachDates) {
        // total = total + guest_selected * eachDates.price;
        // check if fewer people book the room privatly... charge extra.
        if (rooms_guest.type === 'private' && rooms_guest.guest <= slot_lodging.lodging.min_private_guests) {
          r.is_private_charged = true;
          r.total_price = guest_selected * slot_lodging.private_price;	// total price including private price...
          r.total_price_usd = guest_selected * slot_lodging.usd_private_price;	// total price including private price...

          total = total + guest_selected * eachDates.private_price;
        } else {
          total = total + guest_selected * eachDates.price;
        }
      });
    });
    return total;
  }

  // function to return the commulative price of each day for accommodation nightly booking ...
  function calculateAccommodationRoomPrice (slot_lodging) {
    var total = CONST_HELPER.ZERO;
    slot_lodging.calendar.map(function (eachDates, key) {
      // if (slot_lodging.calendar && slot_lodging.calendar.length && key < slot_lodging.calendar.length - 1 || slot_lodging.calendar.length == 1) {
      var slot_eachDate = moment(eachDates.date);
      if (
        (
          slot_eachDate.isSameOrAfter($scope.start_date.format('YYYY-MM-DD')) &&
          slot_eachDate.isBefore($scope.end_date.format('YYYY-MM-DD'))
        )
        ||
        (
          moment($scope.start_date).isSame($scope.end_date.format('YYYY-MM-DD'))
        )
        ||
        (
          slot_eachDate.isSameOrAfter($scope.start_date.format('YYYY-MM-DD')) &&
          slot_eachDate.isSameOrBefore($scope.end_date.format('YYYY-MM-DD')) &&
          vm.selectedSlotDetails.daily_rate_activity == 1
        )
      ) {
        total = total + parseInt(slot_lodging.rooms) * eachDates.price;
      }
    });
    return total;
  }

  // =================================================================================================

  // white label code goes here

  if (typeof Stripe === 'undefined' && $rootScope.siteInIFrame) {
    $scope.loadScript('https://js.stripe.com/v2/', function () {
      $scope.loadScript('https://js.stripe.com/v3/', function () {
        window.Stripe.setPublishableKey(STRIPE_KEY);
        // Storing stripe object to global context for accessing it
        // outside of this callback
        window.stripe = Stripe(STRIPE_KEY, { locale: 'en' });
        var existingModule = angular.module('heliApp');
        existingModule.requires.push('angular-stripe');
        $rootScope.$broadcast('stripe-loaded');
      });
    });
  }

  // get total currency list...
  userAdventureDetailService.getCurrency().then(function (res) {
    if (res.data.code === CONST_HELPER.API_RESPONSE.OK) {
      vm.Currencies = res.data.data;
      vm.Currencies.map(function (val) {
        val.symbol = ' (' + $scope.currencySymbol[val.code] + ')';
      });
    }
  }, function () {

  });
  vm.showCouponBox = function () {
    if (vm.openCouponBox) {
      vm.openCouponBox = false;
    } else {
      vm.openCouponBox = true;
    }
  };
  vm.changeCurrency = function (selectedCurrencyCodenew) {
    if (selectedCurrencyCodenew !== $rootScope.localCurrencyCode) {
      $rootScope.selectedCurrencyCode = selectedCurrencyCodenew;
      $rootScope.localCurrencyCode = $rootScope.selectedCurrencyCode;	// change the local cuurency code and fetch slot details again...
      $rootScope.localCurrencySymbol = $scope.currencySymbol[$rootScope.localCurrencyCode];
      $scope.getSlotUsdRate(vm.selectedSlotDetails.currency.code, $rootScope.selectedCurrencyCode, function (currencyRate) {
        $scope.localCurencyRate = currencyRate;
      });
    }
  };

  vm.cancelconvertCurrency = function () {
    vm.selectedCurrencyCodenew = $rootScope.localCurrencyCode;
  };

  function resetSummary () {
    vm.totalGrandTotal = CONST_HELPER.ZERO;
    vm.processingFee = CONST_HELPER.ZERO;
    vm.CartGrandTotal = CONST_HELPER.ZERO;
    vm.discount_amount = CONST_HELPER.ZERO;
    vm.nightly_discount = CONST_HELPER.ZERO;
    vm.promoCodeGrandTotal = CONST_HELPER.ZERO;
    vm.selectedAddons = [];
    vm.addonAdults = [];
    vm.selectedLodging = [];
    vm.guestMorethanOne = 'false';
    vm.moreGuests = '0';
    vm.adult = '0';
    vm.selectedGuests = '0';
    vm.totalSelectedGuest = CONST_HELPER.ZERO;
    vm.remainingSelectedGuest = 0;
    vm.remainingAccommodationGuest = CONST_HELPER.ZERO;
  }

  // check change in vm.totalGrandTotal

  $scope.$watch('vm.totalGrandTotal', function (newTotal, oldTotal) {
    if (oldTotal !== undefined && newTotal !== oldTotal) {
      // vm.partial_amount= vm.selectedSlotDetails.package.is_partial_checked ? newTotal*vm.selectedSlotDetails.package.amount_percentage/100:newTotal;
      if (vm.selectedSlotDetails.package.is_partial_checked) {
        switch (String(vm.selectedSlotDetails.package.deposit_type)) {
          case CONST_HELPER.DEPOSIT_TYPE.PERCENTAGE: 				// IF DEPOSIT TYPE IS PERCENTAGE
            vm.partial_amount = newTotal * vm.selectedSlotDetails.package.amount_percentage / CONST_HELPER.HUNDRED;
            break;

          case CONST_HELPER.DEPOSIT_TYPE.AMOUNT: 				// IF DEPOSIT TYPE IS AMOUNT
            vm.partial_amount = vm.selectedSlotDetails.package.amount_percentage;
            if ( vm.selectedSlotDetails.package.package_case == 1) {
              // calculate deposite price for per seat
              vm.partial_amount = vm.selectedSlotDetails.package.amount_percentage * vm.adult;
            } else if ( vm.selectedSlotDetails.package.package_case == 2) {
              // calculate deposite price for per accomodative
              var countUnits = 0;
              if(vm.selectedLodging && vm.selectedLodging.length){
                vm.selectedLodging.forEach(function(lodge){
                  countUnits += Number(lodge.rooms);
                })
              }
              vm.partial_amount = vm.selectedSlotDetails.package.amount_percentage * countUnits;
            } else {
              vm.partial_amount = vm.selectedSlotDetails.package.amount_percentage;
            }
            break;
        }
      } else {
        vm.partial_amount = newTotal;
      }
      // calculate nightly discount if applied
      if ($scope.appliedNightlyDiscount && $scope.appliedNightlyDiscount != {}) {
        $scope.appliedNightlyDiscount.hasOwnProperty('is_applied') ? calculateNightlyDiscount($scope.appliedNightlyDiscount) : '';
      }
      getPartialAmount();
      claculateTaxAmount();
      if (vm.appliedCoupon) {
        vm.applyCoupon(vm.appliedCoupon);
      }
      vm.lodgingForall = checkLodgingForall();
    }
  });

  // show email popup if lodging is selected for everyone
  vm.showEmailPopup = function () {
    if (vm.lodgingForall) {
      $scope.showPopup('#emailCheckModal');
      $rootScope.initializePhoneField("#phoneGuestEmailForm");
    } else {
      vm.lodgingForEveryoneErr = true;
    }
  };

  // calculate the processing charge....
  function calculateProcessingFee () {
    var processingTotal = CONST_HELPER.ZERO;
    vm.discount_amount = vm.discount_amount && vm.discount_amount > 0 ? vm.discount_amount : CONST_HELPER.ZERO;
    vm.nightly_discount = vm.nightly_discount && vm.nightly_discount > 0 ? vm.nightly_discount : CONST_HELPER.ZERO;
    vm.taxAmount = vm.taxAmount && vm.taxAmount > 0 ? vm.taxAmount : CONST_HELPER.ZERO;
    processingTotal = Number((vm.partial_amount - vm.discount_amount - vm.nightly_discount).toFixed(2)) > CONST_HELPER.ZERO ? (vm.partial_amount - vm.discount_amount - vm.nightly_discount) : CONST_HELPER.ZERO;
    vm.processingFee = processingTotal - (vm.partial_amount - vm.discount_amount - vm.nightly_discount + vm.taxAmount);
    vm.CartGrandTotal = processingTotal;
    $cookies.put('cartTaxAmount', vm.taxAmount);
    $cookies.put('cartProcessingAmount', vm.processingFee);
  }
  // calculate the Tax amount ...
  function claculateTaxAmount () {
    if (vm.selectedSlotDetails.package && vm.selectedSlotDetails.package.is_partial_checked) {
      if (vm.selectedSlotDetails.package.deposit_type == CONST_HELPER.DEPOSIT_TYPE.PERCENTAGE) {
        // Discount needs to be decreased by the same % as deposit
        vm.nightly_discount = vm.nightly_discount_original * vm.selectedSlotDetails.package.amount_percentage / 100;
      } else {
        // Discount will be applied to the final balance payment
        vm.nightly_discount = 0;
      }
    } else {
      // Restore original discount value
      if (vm.nightly_discount_original !== undefined) {
        vm.nightly_discount = vm.nightly_discount_original;
      }
    }

    var totalAmount = vm.partial_amount - vm.discount_amount - vm.nightly_discount;
    var taxPercent = is_apply_domestic_tax(vm.selectedSlotDetails.operator_tax_cloud);
    vm.taxAmount = totalAmount * taxPercent / CONST_HELPER.HUNDRED;
    vm.totalTaxAmount = (vm.totalGrandTotal - vm.discount_amount - vm.nightly_discount) * taxPercent / CONST_HELPER.HUNDRED;
    calculateProcessingFee();
  }
  // get domestic or international price.
  function is_apply_domestic_tax (operatorTax) {
    if (!operatorTax) { return 0; }
    if (!$rootScope.userLocalCountry && $cookies.get('user_country_id')) {
      $rootScope.userLocalCountry = JSON.parse($cookies.get('user_country_id'))
    }
    if (operatorTax.country_code == $rootScope.userLocalCountry) {
      return operatorTax.domestic_percent;		// apply domestictax
    } else {
      return operatorTax.international_percent; // apply internation tax.
    }
  }

  // calculate nightly Discount;
  function calculateNightlyDiscount (nDiscount) {
    switch (nDiscount.discount_type) {
      case CONST_HELPER.DISCOUNT_TYPE.IN_PERCENTAGE:
        vm.nightly_discount = vm.partial_amount * nDiscount.discount_value / 100;
        break;
      case CONST_HELPER.DISCOUNT_TYPE.IN_CURRENCY:
        vm.nightly_discount = nDiscount.discount_value;
        break;
    }

    // Store original discount amount
    vm.nightly_discount_original = vm.nightly_discount;
  }

  /**
		 * @ngdoc function
		 * @name addToCartAndApplyCoupon
		 * @description
		 * # for booking through white lable first add package to cart and then apply coupon.
		 */

  vm.addToCartAndApplyCoupon = function (coupon) {
    vm.addToCart(function (res) {
      if (res.data.code === CONST_HELPER.API_RESPONSE.OK) {
        vm.cart_slot_id = res.data.cart_slot_id;
        vm.cart_id = res.data.cart_id;
        vm.applyCoupon(coupon);
      }
    });
  };
  /**
		 * @ngdoc function
		 * @name applyCoupon
		 * @description
		 * # apply coupon on selected lodging...
		 */
  vm.applyCoupon = function (coupon) {
    vm.errormsg = '';
    if (!coupon || coupon === '') {
      vm.errormsg = MESSAGE_HELPER.coupon.empty_error;
      $scope.hideLoader();
    } else {
      if (vm.totalGrandTotal <= CONST_HELPER.ZERO) {
        return false;
      }
      $scope.showLoader();
      vm.discount_amount = CONST_HELPER.ZERO;
      var couponData = {
        'code': coupon,
        'cart_id': vm.cart_id,
        'total_amount': vm.promoCodeGrandTotal,
        'currency_code': vm.selectedSlotDetails.currency.code
      };
      userAdventureDetailService.applyCoupon(couponData).then(function (res) {
        if (res.data.message === 'Success') {
          vm.couponBox = '';
          vm.appliedCouponDetails = res.data;
          vm.appliedCoupon = coupon;
          vm.openCouponBox = false;
          if (vm.appliedCouponDetails.is_applied === 1 || vm.appliedCouponDetails.is_genric === 1) {
            vm.finalDiscountAmount = vm.totalGrandTotal - vm.appliedCouponDetails.discounted_amount - (vm.totalGrandTotal - vm.promoCodeGrandTotal); // get the final discount amount excluding addon price
            if (vm.selectedSlotDetails.package.is_partial_checked) {
              switch (String(vm.selectedSlotDetails.package.deposit_type)) {
                case CONST_HELPER.DEPOSIT_TYPE.PERCENTAGE: 				// IF DEPOSIT TYPE IS PERCENTAGE
                  // vm.discount_amount = vm.discount_amount * vm.selectedSlotDetails.package.amount_percentage / CONST_HELPER.HUNDRED;
                  break;

                case CONST_HELPER.DEPOSIT_TYPE.AMOUNT: 				// IF DEPOSIT TYPE IS AMOUNT
                  // var depositPercentage = vm.totalGrandTotal / vm.discount_amount; // get the actual percentage of discounted amount...
                  // vm.discount_amount = vm.partial_amount * depositPercentage / CONST_HELPER.HUNDRED;
                  break;
              }
            } else {
              vm.discount_amount = vm.promoCodeGrandTotal - vm.appliedCouponDetails.discounted_amount;
            }
            vm.CartGrandTotal = vm.CartGrandTotal - vm.discount_amount - vm.nightly_discount;
            claculateTaxAmount();
            $scope.hideLoader();
            saveCookie('couponApplied', coupon);	// save coupon cookie for 20 min...
            saveCookie('coupon_id', vm.appliedCouponDetails.promo_code_id);
          } else {
            vm.openCouponBox = true;
            vm.couponBox = coupon;
            vm.errormsg = 'Not Applicable.';
            $cookies.put('couponApplied', '');
            $cookies.put('coupon_id', '');
            $scope.hideLoader();
          }
        } else if (res.data.message === 'Resource not found.') {
          if (res.data.date_expire_error === 1) {
            vm.errormsg = MESSAGE_HELPER.coupon.date_expire_error;
          } else if (res.data.my_limit_error === 1) {
            vm.errormsg = MESSAGE_HELPER.coupon.my_limit_error;
          } else if (res.data.user_limit_error === 1) {
            vm.errormsg = MESSAGE_HELPER.coupon.user_limit_error;
          } else if (res.data.amount_minimum_error === 1) {
            vm.errormsg = MESSAGE_HELPER.coupon.amount_minimum_error;
          } else {
            vm.errormsg = MESSAGE_HELPER.coupon.invalid_error;
          }
          $scope.hideLoader();
          if (vm.appliedCouponDetails.promo_code_id) { // if promocode is applied previously and after updation it is not applicable the remove the coupon;
            vm.removeCoupon();
          }
        }
      }, function () {
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });
    }
  };
  /**
		 * @ngdoc function
		 * @name removeCoupon
		 * @description
		 * # remove coupon if coupon is applied...
		 */

  vm.removeCoupon = function (couponData, callback) {
    $scope.showLoader();
    var data = {
      'promo_code_id': vm.appliedCouponDetails.promo_code_id,
      'cart_id': vm.cart_id,
      'base_amount': vm.totalGrandTotal,
      'total_amount': vm.totalGrandTotal + vm.taxAmount
    };
    userAdventureDetailService.removeCoupon(data, $scope.user.token).then(function (res) {
      if (res.data.message === 'Success') {
        $scope.hideLoader();
        vm.couponBox = '';
        vm.discount_amount = CONST_HELPER.ZERO;
        vm.appliedCoupon = '';
        vm.errormsg = '';
        claculateTaxAmount();
        $cookies.put('couponApplied', '');
        $cookies.put('coupon_id', '');
        vm.appliedCouponDetails = {};
      }
    }, function () {

    });
  };

  $scope.guestEmailFormInvalid = false;
  vm.userEmailVerified = false;
  vm.validateUserEmail = function (guestEmailForm) {
    var phoneNumberValue = $("#phoneGuestEmailForm").intlTelInput("getNumber");
    if (phoneNumberValue == "" || phoneNumberValue.length < 8 || phoneNumberValue.length > 16) {
      $scope.phoneNumberError = true;
    } else {
      $scope.phoneNumberError = false;
    };
    if (!guestEmailForm.$valid || !vm.guestEmail || !vm.firstName || !vm.lastName || $scope.phoneNumberError || !vm.userCountryId) {
      vm.guestEmailFormInvalid = true;
      return;
    }
    $scope.showLoader();
    $scope.user.email = vm.guestEmail;
    var email = { 'email': vm.guestEmail, 'first_name': vm.firstName, 'last_name': vm.lastName, 'package_id': $rootScope.package_id_forGuestSignup, country_id: vm.userCountryId, 'phone_number': phoneNumberValue};// email address
    userAdventureDetailService.checkUserEmail(email).then(function (response) { // post to the server the user email
      if (response.data) {
        $scope.hidePopup('#emailCheckModal');
        if (response.data.code === '422') { // if email exits
          // if register user then log-in to purchase package...
          var reqObject = {
            'email': vm.guestEmail,
            'password': '',		// bypassing the login for guest user...
            'guest_user': true
          };
          userLoginService.loginUser(reqObject).then(function (response) {
            if (response.data.code === CONST_HELPER.API_RESPONSE.OK) { // success response
              // $scope.setUserDetails(response.data.data, 'second');// after completing SECOND-STEP of sign up set user details in cookies;
              $scope.user = response.data.data;// set user data into local scope;
              $rootScope.userLocalCountry = response.data.data.country_id;
              $cookies.put('user_country_id', JSON.stringify(response.data.data.country_id));

              // if you enter operator email...
              if ($rootScope.siteInIFrame && response.data.data && response.data.data.role_id === CONST_HELPER.USER_ROLE.OPERATOR) {
                $scope.logout();// logout operator
                $scope.showPopup('#whiteLabelOperator');
                return;
              }
              claculateTaxAmount();
              vm.addToCartAndProceedToCheckout();
            }
          });
        } else if (response.data.code === CONST_HELPER.API_RESPONSE.OK) { // if email does not exist.. treat them as guest user...
          $scope.setUserDetails(response.data.data, 'second');// set user details in the rootScope;
          $scope.user = response.data.data;// set user data into local scope;=
          $rootScope.userLocalCountry = response.data.data.country_id;
          // $scope.showPopup("#guestConfirmation")
          claculateTaxAmount();
          vm.addToCartAndProceedToCheckout();
        } else {
          $scope.hideLoader();
        }
      }
    }, function () { $scope.hideLoader(); $scope.showPopup('#serverErrModal'); });
  };

  $rootScope.$on('wl-social-signin', function (event, data) {
    vm.addToCartAndProceedToCheckout();
  });
  /**
		 * @ngdoc function
		 * @name addToCartAndProceedToCheckout
		 * @description
		 * # add package into cart and proceed to checkout on single click
		 */

  vm.addToCartAndProceedToCheckout = function () {
     if (vm.cart_id) {
       vm.proceedToCheckout();
     } else {
       vm.addToCart(function (res) {
         if (res.data.code === CONST_HELPER.API_RESPONSE.OK) {
           vm.cart_slot_id = res.data.cart_slot_id;
           vm.cart_id = res.data.cart_id;
           vm.proceedToCheckout();
         }
       });
      }
   }

  $scope.generalGuest = {};
  $scope.generalGuest.first_name = $scope.user.first_name;
  $scope.generalGuest.last_name = $scope.user.last_name;
  $scope.generalGuest.email = $scope.user.email;
  if($scope.user.phone && $scope.user.phone.toString()[0] != "+"){
    $("#generalGuestPhone").intlTelInput("setNumber", '+' + $scope.user.phone.toString());
  }else{
    if(!!$scope.user.phone){
      $("#generalGuestPhone").intlTelInput("setNumber", $scope.user.phone.toString());
    }else{
      $("#generalGuestPhone").intlTelInput("setNumber", '');
    }
  }

  $scope.clearAllFieldsForGuest = function (count, value) {
    if (value) {
      $scope.guestDetailsInformationError = false;
      for (var keyField in $scope.guests_detail[count]) {
        if(keyField != 'guest_tbo'){
          $scope.guests_detail[count][keyField] = '';
        }
      }
    } else {
      $scope.guests_detail[count]['guest_adult_child'] = 1;
      $scope.guests_detail[count]['guest_tbo'] = 0;
    }
  }

  function validateGuestsInformation () {
    $scope.guestsDetailInfoError = [];
    !$scope.generalGuest.first_name ? $scope.generalGuestFirstNameError = true : $scope.generalGuestFirstNameError = false;
    !$scope.generalGuest.last_name ? $scope.generalGuestLastNameError = true : $scope.generalGuestLastNameError = false;
    !$scope.generalGuest.email || !$scope.generalGuest.email.match($scope.emailPattern) ? $scope.generalGuestEmailError = true : $scope.generalGuestEmailError = false;
    var phoneNumberValue = $("#generalGuestPhone").intlTelInput("getNumber");
    if (phoneNumberValue == "" || phoneNumberValue.length < 8 || phoneNumberValue.length > 16) {
      $scope.generalGuestPhoneError = true;
    } else {
      $scope.generalGuestPhoneError = false;
    };

    $scope.guests_detail.forEach(function (eachGuest) {
      var errorsInfo = {};
      $scope.guestDetailsInformationError = false;
      if(!eachGuest.guest_tbo){
        if(!eachGuest.guest_email || !eachGuest.guest_email.match($scope.emailPattern)){
          errorsInfo.email_error = true;
          $scope.guestDetailsInformationError = true;
        }else{
          errorsInfo.email_error = false;
        }
        if(!eachGuest.guest_adult_child){
          errorsInfo.adult_child_error = true;
          $scope.guestDetailsInformationError = true;
        }else{
          errorsInfo.adult_child_error = false;
        }
        if(!eachGuest.guest_name){
          errorsInfo.name_error = true;
          $scope.guestDetailsInformationError = true;
        }else{
          errorsInfo.name_error = false;
        }
        errorsInfo.guest_allergies = false;
        errorsInfo.guest_additional_information = false;
        $scope.guestsDetailInfoError.push(errorsInfo)
      }else{
        errorsInfo.email_error = false;
        errorsInfo.adult_child_error = false;
        errorsInfo.name_error = false;
        errorsInfo.guest_allergies = false;
        errorsInfo.guest_additional_information = false;
        $scope.guestsDetailInfoError.push(errorsInfo);

      }
    });
    if(!$scope.acknowledge_agree){
      $scope.acknowledgeAgreeError = true;
    }
  }

  $scope.goToPayment = function () {
    validateGuestsInformation()
    if(!$scope.guestDetailsInformationError
      && !$scope.acknowledgeAgreeError
      && !$scope.generalGuestFirstNameError
      && !$scope.generalGuestLastNameError
      && !$scope.generalGuestEmailError
      && !$scope.generalGuestPhoneError
    ){
      var phoneNumber = $("#generalGuestPhone").intlTelInput("getNumber");
      if (phoneNumber.substr(0, 1) == '+') {
        phoneNumber = phoneNumber.slice(1);
      }
      $scope.generalGuest.phone_number = phoneNumber;
      $scope.guestDetail.acknowledge_agree = $scope.acknowledge_agree;
      $scope.guestDetail.guests_detail = $scope.guests_detail;
      $scope.guestDetail.generalGuest = $scope.generalGuest;
      $rootScope.guests_details = $scope.guestDetail;
      $cookies.put('guests_details', JSON.stringify($scope.guestDetail));
      $state.go('secure-checkout');
    }else{
      return;
    }
  }

  var usdRate;
  /**
		 * @ngdoc function
		 * @name proceedToCheckout
		 * @description
		 * # Proceed to checkout if package is already added in to the cart
		 */
  vm.proceedToCheckout = function () {
    $scope.showLoader();
    $rootScope.CartDetails = [];
    $rootScope.usdRate = CONST_HELPER.ZERO;
    // check if user email is verified...
    // userRegisterService.isEmailVerified($scope.user.token).then(function(res){
    //        if(res.data && res.data.code==="200"){

    // for white label bypass the email verification process
    $scope.user.is_email_verified = 1;
    $scope.setUserDetails($scope.user, 'second', true);
    $('.alert-success').css('display', 'none');
    // get the usd rate against to user selected currency.
    userAdventureDetailService.convertCurrency(1, CONST_HELPER.payment_const.default_currency, vm.selectedSlotDetails.currency.code).then(function (ress) {
      usdRate = ress.data.usd_rate;
      $rootScope.usdRate = usdRate;
      var localTotalSlotAmount = 0;
      if (vm.totalGrandTotal !== undefined) {
        localTotalSlotAmount = vm.totalGrandTotal;
      }
      if (vm.discount_amount !== undefined) {
        localTotalSlotAmount -= vm.discount_amount;
      }

      // We need checking because property "nightly_discount_original" may be not defined in "vm" object here
      if (vm.nightly_discount_original !== undefined) {
        localTotalSlotAmount -= vm.nightly_discount_original;
      } else if (vm.nightly_discount !== undefined) {
        localTotalSlotAmount -= vm.nightly_discount;
      }
      console.debug('vm.selectedSlotDetails: %O', vm.selectedSlotDetails);
      // creating payment slot objects.
      var obj = {
        'slot_id': vm.cart_slot_id,
        'package_id': vm.selectedSlotDetails.package.id,
        'operator_id': vm.selectedSlotDetails.operator_commission ? vm.selectedSlotDetails.operator_commission.user_id : null,
        'tax_amount': vm.taxAmount / usdRate,
        'local_tax_amount': vm.taxAmount,
        'is_partial': vm.selectedSlotDetails.package.is_partial_checked ? CONST_HELPER.payment_const.is_partial : CONST_HELPER.payment_const.is_full,
        'due_date': vm.selectedSlotDetails.slot_due_date,
        'processing_fee': vm.processingFee / usdRate,	//			Number(vm.processingFeeEach),
        'local_processing_fee': vm.processingFee,	//			Number(vm.processingFeeEach),
        'due_amount': returnDueAmount(CONST_HELPER.payment_const.default_currency),
        'local_due_amount': returnDueAmount(),
        'slot_amount': (vm.partial_amount - vm.discount_amount - vm.nightly_discount) / usdRate,
        'local_slot_amount': vm.partial_amount - vm.discount_amount - vm.nightly_discount,
        'is_final_payment': vm.selectedSlotDetails.package.is_partial_checked ? CONST_HELPER.payment_const.partial_payment : CONST_HELPER.payment_const.full_payment,
        'total_tax': vm.totalTaxAmount / usdRate,
        'local_total_tax': vm.totalTaxAmount,
        'total_slot_amount': Number(localTotalSlotAmount / usdRate).toFixed(2),
        'local_total_slot_amount': localTotalSlotAmount,
        'total_processing_fee': vm.processingFee / usdRate,
        'local_total_processing_fee': vm.processingFee,
        'cc_rate': $rootScope.usdRate,
        'slot_end_date': vm.selectedSlotDetails.to_date ? $filter('date')(vm.selectedSlotDetails.to_date, 'yyyy-MM-dd') : $filter('date')(new Date($scope.end_date), 'yyyy-MM-dd'),
        'referral_id': '',
        'booking_source': CONST_HELPER.OPERATOR_SITE
      };

      if (vm.selectedSlotDetails.operator_commission.is_on_site_enabled && ($rootScope.siteInIFrame || $rootScope.isSubDomain || $scope.isManualBooking)) {
        obj.opr_percentage = vm.selectedSlotDetails.operator_commission.on_site_commission;
      } else {
        obj.opr_percentage = (vm.selectedSlotDetails.package_slots && vm.selectedSlotDetails.package_slot) ? vm.selectedSlotDetails.package_slots.commission : vm.selectedSlotDetails.operator_commission.amount;
      }

      $rootScope.CartDetails.push(obj);
      if ($scope.bookingDeposit.length > 0) {
        // Update booking_slot_id by ID from `booking_slot_details` DB table
        $scope.bookingDeposit[0].booking_slot_id = vm.cart_slot_id;
      }
      var cartData = {
        'promo_code_id': vm.appliedCouponDetails.promo_code_id || '',
        'cart_id': vm.cart_id,
        'total_amount': vm.CartGrandTotal,
        'base_amount': vm.totalGrandTotal - vm.finalDiscountAmount,
        user_currency: $rootScope.selectedCurrencyCode,
        deposits: $scope.bookingDeposit
      };
      // saving final cart value and proceed to checkout.
      userAdventureDetailService.proceedToCheckout(cartData, $scope.user.token).then(function (res) {
        if (res.data.message === 'Success') {
          $rootScope.doNotReleaseSeat = false;
          $cookies.put('canCheckout', 1);

          // Update CartDetails item prices according response
          $rootScope.CartDetails[0].tax_amount = res.data.data.tax_amount;
          $rootScope.CartDetails[0].local_tax_amount = res.data.data.local_tax_amount;
          $rootScope.CartDetails[0].total_tax = res.data.data.total_tax;
          $rootScope.CartDetails[0].local_total_tax = res.data.data.local_total_tax;
          $cookies.put('cartDetails', JSON.stringify($rootScope.CartDetails));

          $cookies.put('usdRate', $rootScope.usdRate);
          $cookies.put('selectedCurrencyCode', $rootScope.selectedCurrencyCode);
          $state.go('guest-details-summary', {adults: $stateParams.adults});
        } else if (res.data.code === '422') {		// unprocessable intity
          $scope.showPopup('#unprocessableCart');
        }
        $scope.hideLoader();
      });
    });
    var data = {
      cart_id: vm.cart_id,
      total_amount: vm.CartGrandTotal,
      slot_detail: [{ 'id': vm.cart_slot_id, 'total_amount': vm.totalGrandTotal + vm.taxAmount }],
      local_currency_code: vm.selectedSlotDetails.currency.code
    };
    userAdventureDetailService.updateTotalAmount(data, $scope.user.token);
  };

  // calculate due amount
  function returnDueAmount (USD) {
    if (vm.selectedSlotDetails.package.is_partial_checked) {
      var due;
      if (vm.appliedCouponDetails.promo_code_id) {
        due = Number((vm.totalGrandTotal - vm.finalDiscountAmount - vm.partial_amount).toFixed(2));
      } else {
        due = Number((vm.totalGrandTotal - vm.partial_amount));
      }
      if (USD === CONST_HELPER.payment_const.default_currency) {
        var totalDue = Number((due / usdRate).toFixed(2));
      } else {
        var totalDue = Number(due.toFixed(2));
      }
      return totalDue > CONST_HELPER.ZERO ? totalDue : CONST_HELPER.ZERO;
    } else {
      return CONST_HELPER.ZERO;
    }
  }

  function saveCookie (key, val, exp) {
    $cookies.put(key, val);
  }

  $scope.$watch('vm.selectedSlotDetails.package.is_partial_checked', function (newPC, oldPC) {
    if (newPC) {
      switch (String(vm.selectedSlotDetails.package.deposit_type)) {
        case CONST_HELPER.DEPOSIT_TYPE.PERCENTAGE: 				// IF DEPOSIT TYPE IS PERCENTAGE
          vm.partial_amount = vm.totalGrandTotal * vm.selectedSlotDetails.package.amount_percentage / CONST_HELPER.HUNDRED;
          break;

        case CONST_HELPER.DEPOSIT_TYPE.AMOUNT: 				// IF DEPOSIT TYPE IS AMOUNT
          if ( vm.selectedSlotDetails.package.package_case == 1) {
            // calculate deposite price for per seat
            vm.partial_amount = vm.selectedSlotDetails.package.amount_percentage * vm.adult;
          } else if ( vm.selectedSlotDetails.package.package_case == 2) {
            // calculate deposite price for per accomodative
            var countUnits = 0;
            if(vm.selectedLodging && vm.selectedLodging.length){
              vm.selectedLodging.forEach(function(lodge){
                countUnits += Number(lodge.rooms);
              })
            }
            vm.partial_amount = vm.selectedSlotDetails.package.amount_percentage * countUnits;
          } else {
            vm.partial_amount = vm.selectedSlotDetails.package.amount_percentage;
          }
          break;
      }
      $scope.bookingDeposit = [{
        booking_slot_id : vm.selectedSlotDetails.id,
				is_deposit: vm.selectedSlotDetails.package.is_partial_checked
      }];
    } else {
      $scope.bookingDeposit = [];
      vm.partial_amount = angular.copy(vm.totalGrandTotal);
    }
    if (vm.appliedCoupon) {
      vm.applyCoupon(vm.appliedCoupon);
    } else {
      claculateTaxAmount();
    }
  });
  // function to get the actual partialAmount...
  function getPartialAmount () {
    if (vm.selectedSlotDetails.package.is_partial !== 0) {
      switch (String(vm.selectedSlotDetails.package.deposit_type)) {
        case CONST_HELPER.DEPOSIT_TYPE.PERCENTAGE: 				// IF DEPOSIT TYPE IS PERCENTAGE
          vm.actual_partial_amount = vm.totalGrandTotal * vm.selectedSlotDetails.package.amount_percentage / CONST_HELPER.HUNDRED;
          break;

        case CONST_HELPER.DEPOSIT_TYPE.AMOUNT: 				// IF DEPOSIT TYPE IS AMOUNT

          if ( vm.selectedSlotDetails.package.package_case == 1) {
            // calculate deposite price for per seat
            vm.actual_partial_amount = vm.selectedSlotDetails.package.amount_percentage * vm.adult * $scope.localCurencyRate;
          } else if ( vm.selectedSlotDetails.package.package_case == 2) {
            // calculate deposite price for per accomodative
            var countUnits = 0;
            if(vm.selectedLodging && vm.selectedLodging.length){
              vm.selectedLodging.forEach(function(lodge){
                countUnits += Number(lodge.rooms);
              })
            }
            vm.actual_partial_amount = vm.selectedSlotDetails.package.amount_percentage * countUnits * $scope.localCurencyRate;
          } else {
            vm.actual_partial_amount = vm.selectedSlotDetails.package.amount_percentage * $scope.localCurencyRate;
          }
          break;
      }
    }
  }
  $scope.removeActiveClass = function (form) {
    $('button[data-value="white-label"]').focus(function () {
      this.blur();
    });
    form.$setPristine();
    vm.guestEmailFormInvalid = false;
  };
  $scope.closeLoginPopup = function () {
    $timeout(function () {
      $('#navigation-scroll').css({ 'padding-right': '0px' });
    }, 1000);
  };
  $scope.closeLoginPopup();

  $scope.checkGrandTotal = function () {	// check the grand total if full discount is applied...
    if (vm.discount_amount && vm.CartGrandTotal === CONST_HELPER.ZERO) {
      vm.selectedSlotDetails.package.is_partial_checked = false;
    }
    return !(vm.discount_amount && vm.CartGrandTotal === CONST_HELPER.ZERO);
  };
}
