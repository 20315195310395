import { Component, OnInit} from '@angular/core';
import { UIRouter } from '@uirouter/core';

@Component({
  selector: 'app-parallax-block',
  templateUrl: '/ng-app/src/app/components/home/parallax-block/parallax-block.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/home/parallax-block/parallax-block.component.css']
})
export class ParallaxBlockComponent implements OnInit {

  constructor(public router: UIRouter) { }

  ngOnInit(): void {
  }

}
