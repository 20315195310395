import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name UserRegisterService
 * @description
 * # userRegisterService
 * Service in the heliApp.
 */

@Injectable()

export class UserRegisterService {

  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  sendReq(method) {
    return new Promise((resolve, reject) => {
      method.then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }

  getReq(url, token?) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + url,
      headers: {
        'token': token
      }
    };
    return req;
  }

  checkIfDomainValid(data) { // service to register a new user
    return this.sendReq(this.webService.checkDomainValid(data));
  }
  registerUser(data) { // service to register a new user
    return this.sendReq(this.webService.registerUser(data));
  }
  checkUserName(username) { // service to register a new user
    return this.sendReq(this.webService.checkUserName(username));
  }
  completeSignUp(data) { // service to complete the sign up process
    return this.sendReq(this.webService.completeSignUp(data));
  }
  isAlreadyUser(data) { // service to check whether user login from social media already exists or not
    return this.sendReq(this.webService.isAlreadyUser(data));
  }
  completeSocialSignUp(data) { // service to complete the social sign up process
    return this.sendReq(this.webService.completeSocialSignUp(data));
  }
  mergeAccounts(data) { // service to complete the social sign up process by mergin accounts
    return this.sendReq(this.webService.mergeAccounts(data));
  }
  checkForValidToken(token) { // service to check if forgot pass token is valid or no
    return this.sendReq(this.webService.checkForValidToken(token));
  }
  resetPass(data) { // service to check reset forgotten pass
    return this.sendReq(this.webService.resetPass(data));
  }
  checkForValidTokenEmail(token) { // service to check if email verification token is valid or no
    return this.sendReq(this.webService.checkForValidTokenEmail(token));
  }
  resendEmailVerificationLink(data) {
    return this.sendReq(this.webService.resendEmailVerificationLink(data));
  }
  isEmailVerified(token) {
    return this.sendReq(this.webService.isEmailVerified(token));
  }
  checkForValidTokenMergeAcc(token) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'users/merge-account-verification',
      data: token
    };
    return this.webService.httpReq(req);
  }

  getAllCountry(token) {
    let url = 'masters/all-country';
    let req = this.getReq(url, token);
    return this.webService.httpReq(req);
  }

  saveUserCountry(data, id, token) {
    let req = {
      method: 'PATCH',
      url: this.REQUEST_URL + 'users/' + id,
      data: data,
      headers: {
        'token': token,
        'Content-Type': 'application/json'
      }
    };
    return this.webService.httpReq(req);
  }
}
angular.module('heliApp').service('userRegisterService', downgradeInjectable(UserRegisterService));
