'use strict';

angular.module('heliApp').service('plaidService', [
  '$q',
  'webService',
  'scriptLoaderService',
  'PLAID_ENVIRONMENT',
  'PLAID_PUBLIC_KEY',
  'PLAID_SECRET',
  'PLAID_CLIENT_ID',
  'PLAID_WEBHOOK_URL',
  function (
    $q,
    webService,
    scriptLoaderService,
    PLAID_ENVIRONMENT,
    PLAID_PUBLIC_KEY,
    PLAID_SECRET,
    PLAID_CLIENT_ID,
    PLAID_WEBHOOK_URL
  ) {
        var context = this;

        var LINK_INITIALIZE_SCRIPT_URI = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js';

        var plaidLinkHandler;

        var onSuccessUserHandler = null;
        var userToken = null;

        var plaidLinkConfig = {
            // Displayed once a user has successfully linked their Item.
            clientName: 'Heli App',
            // A list of Plaid product(s) to use
            product: ['auth'],
            // The public_key associated with account
            key: '',
            // The Plaid API environment on which to create user accounts
            env: '',
            // A function that is called when a user has successfully onboarded an Item
            onSuccess: function(publicToken, metadata) {
              // Getting bank account token for stripe. Use first account if it not selected before
              var accountId = (metadata.account_id !== null) ? metadata.account_id : metadata.accounts[0].id;
              context
                .createBankAccountTokenForStripe(userToken, publicToken, accountId)
                .then(function (response) {
                  plaidLinkConfig.accessToken = response.data.data.accessToken;
                  plaidLinkConfig.itemId = response.data.data.itemId;
                  plaidLinkConfig.requestId = response.data.data.requestId;
                  plaidLinkConfig.stripeBankAccountToken = response.data.data.stripeBankAccountToken;

                  if (typeof onSuccessUserHandler === 'function') {
                    onSuccessUserHandler(plaidLinkConfig);
                  }
                });
            },
            // A webhook to associate with an Item
            webhook: '',
            // A rotatable token unique to a single Item; used to access data for that Item
            accessToken: '',
            // Plaid’s unique identifier for the Item; used to identify an Item in a webhook
            itemId: '',
            requestId: '',
            stripeBankAccountToken: ''
        };


        /**
         * @returns {boolean}
         */
        var isLinkInitializeScriptLoaded = function() {
            return !!window.Plade;
        };

        /**
         * @returns {Promise}
         */
        var loadLinkInitializeScript = function() {
            var deferred = $q.defer();

            if (isLinkInitializeScriptLoaded()) {
                deferred.resolve();
            } else {
              scriptLoaderService
                .load(LINK_INITIALIZE_SCRIPT_URI)
                .then(function() {
                  deferred.resolve();
                });
            }

            return deferred.promise;
        };

        var sendRequest = function  (request, params) {
          var deferred = $q.defer();

          request(params).then(function (response) {
            deferred.resolve(response);
          }, function (error) {
            deferred.reject(error);
          });
          return deferred.promise;
        };

        /**
         * @returns {Promise}
         */
        this.init = function(heliUserToken, onSuccess) {
            var deferred = $q.defer();
            // Loading Plaid Link Initialize script if necessary
            loadLinkInitializeScript()
              .then(function() {
                onSuccessUserHandler = onSuccess;
                userToken = heliUserToken;

                angular.extend(plaidLinkConfig, {
                  env: PLAID_ENVIRONMENT,
                  key: PLAID_PUBLIC_KEY,
                  webhook: PLAID_WEBHOOK_URL,
                  client_id: PLAID_CLIENT_ID,
                  secret: PLAID_SECRET,
                });

                plaidLinkHandler = Plaid.create({
                  clientName: plaidLinkConfig.clientName,
                  product: plaidLinkConfig.product,
                  key: plaidLinkConfig.key,
                  env: plaidLinkConfig.env,
                  onSuccess: plaidLinkConfig.onSuccess,
                  webhook: plaidLinkConfig.webhook,
                });

                deferred.resolve();
              });

            return deferred.promise;
        };

        /**
         * @returns {*}
         */
        this.getPlaidLinkHandler = function() {
          return plaidLinkHandler;
        };

        /**
         * @returns {*}
         */
        this.getPlaidLinkConfig = function() {
          return plaidLinkConfig;
        };

        /**
         * @param publicToken {string}
         * @returns {*}
         */
        this.exchangePublicToken = function (publicToken) {
          return sendRequest(webService.plaidGetAccessToken, publicToken);
        };

        /**
         * @param userToken {string}
         * @param publicToken {string}
         * @param accountId {string}
         * @returns {*}
         */
        this.createBankAccountTokenForStripe = function (userToken, publicToken, accountId) {
          // return sendRequest(webService.plaidCreateBankAccountTokenForStripe, );
          return webService.plaidCreateBankAccountTokenForStripe({
            userToken: userToken,
            publicToken: publicToken,
            accountId: accountId
          });
        };
    }
]);
