import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { CookieService } from 'ngx-cookie-service';

/**
 * @ngdoc service
 * @name WebService
 * @description
 * # webService
 * Service in the heliApp.
 */

@Injectable()

export class WebService {

  private REQUEST_URL;
  token: any;
  constructor(
    @Inject(HttpClient) private httpClient: HttpClient,
    @Inject(CookieService) private cookieService: CookieService
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  httpReq(config) {
    let token: string = '';
    if (this.cookieService.get('user')) {
      token = JSON.parse(this.cookieService.get('user')).token;
      this.token = token;
    }

    if (config.headers && !config.headers['token']) {
      config.headers['token'] = token;
    }

    if (config.headers && !config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json'
    } else if (config.headers && config.headers['Accept']) {
      config.headers['Content-Type'] = config.headers['Accept'];
    }

    if(config.headers) {
      config.headers['Cache-Control'] = 'max-age=2592000';
      config.headers['Pragma'] = 'no-cache';
      config.headers['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
    } else {
      config.headers = {
        'Cache-Control': 'max-age=2592000',
        'Pragma': 'no-cache',
        'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT'
      };
    }

    return new Promise((resolve, reject) => {
      this.httpClient.request(config.method, config.url,
        {
          body: config.data,
          params: config.params,
          headers: config.headers,
          observe: 'response',
          responseType: config.responseType ? config.responseType as 'json' : 'json'
        }).toPromise().then((response: any) => {
          response.data = response.body;
          resolve(response);
        }, (error) => {
          // Check various error status code
          switch (error.status) {
            case -1:
              console.debug('Unable to connect server, please try after sometime.', 'Server Maintenance');
              break;

            case 400:
              console.debug('Please enter value. Request doesn\'t have any params');
              break;

            case 500:
              console.debug('Internal server error.');
              break;

            case 404:
              console.debug('Not found');
              break;

            default:
              break;
          }
          // $log.debug(error)
          if (error && error.status !== -1) {
            reject(error);
          } else {
            resolve(error);
          }
        });
    })
  }

  angularUploadFile(config) {
    const formData = new FormData();

    for (let key in config.fields) {
      let val = config.fields[key];
      if (val !== undefined) {
        if (Object.prototype.toString.call(val) === '[object String]') {
          formData.append(key, val);
        } else {
          if (config.sendObjectsAsJsonBlob && typeof val === 'object') {
            formData.append(key, new Blob([val], { type: 'application/json' }));
          } else {
            formData.append(key, JSON.stringify(val));
          }
        }
      }
    }

    if (config.file != null) {
      let fileFormName = config.fileFormDataName || 'file';
      if (Object.prototype.toString.call(config.file) === '[object Array]') {
        let isFileFormNameString = Object.prototype.toString.call(fileFormName) === '[object String]';
        for (let i = 0; i < config.file.length; i++) {
          formData.append(isFileFormNameString ? fileFormName : fileFormName[i], config.file[i],
            (config.fileName && config.fileName[i]) || config.file[i].name);
        }
      } else {
        formData.append(fileFormName, config.file, config.fileName || config.file.name);
      }
    }

    return new Promise((resolve, reject) => {
      this.httpClient.post(config.url, formData, { headers: config.headers, observe: 'response'}).toPromise().then((response: any) => {
        response.data = response.body;
        resolve(response);
      }, error => {
        reject(error);
      })
    });
  }

  http(config) {
    return this.httpReq(config);
  }

  /*
  // formate the date as YYYY-MM-DD
  // MM-DD-YYYY
  */

  formatDate(date) {
    if (!date) {
      return '';
    }
    let d = date.split('/');
    let c = [d[2], d[0], d[1]];
    let formatedDate = c.join('-');
    return formatedDate;
  }
  /**
   * @ngdoc method
   * @name createPackage
   *
   * @description
   * This method call API to create package.
   *
   * @param {Object} params Request parameter.
   * @return {Object} Promise of ajax request.
   */

  createPackage(params, file) {
    if (file) { // if file is present
      const config = {
        url: this.REQUEST_URL + 'operator-packages/save',
        fields: params,
        file: file,
        fileFormDataName: 'gallery_images[]',
        headers: {
          'token': params.token
        }
      }
      return this.angularUploadFile(config);
      // file.upload = this.$upload.upload({
      //   /* using angualr file upload service */
      //   url: this.REQUEST_URL + 'operator-packages/save',
      //   method: 'POST',
      //   fields: params,
      //   file: file,
      //   fileFormDataName: 'gallery_images[]',
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded',
      //     'token': params.token
      //   }
      // });
      // let promise = file.upload.progress(function (evt) { }).success(function (result) {
      //   return result;
      // }).error(function (error) {
      //   /* If service is not successfull then show error */
      //   return error;
      // });
      // return promise;
    } else {
      let req = {
        method: 'POST',
        url: this.REQUEST_URL + 'operator-packages/save',
        data: params,
        headers: {
          'token': params.token,
          'Content-Type': 'application/json'
        }

      };
      return this.httpReq(req);
    }
  }

  /**
 * @ngdoc method
 * @name getPackageAmenities
 *
 * @description
 * This method call API to get assets of module.
 *
 * @param {Object} folderName folder name for which data fetch.
 * @return {Object} Promise of ajax request.
 */
  getPackageAmenities() {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'packages/get-amenities'
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name getSportsList
   *
   * @description
   * This method call API to get assets of module.
   * @return {Object} Promise of ajax request.
   */
  getSportsList() {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'packages/get-package-sports'
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name getOperatorForms
   *
   * @description
   * This method call API to get operator forms
   * @return {Object} Promise of ajax request.
   */
  getOperatorForms(token) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'guest-forms?is_main=1',
      headers: {
        'token': token,
        'Content-Type': 'application/json'
      }
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name getOperatorResponse
   *
   * @description
   * This method call API to get operator forms
   * @return {Object} Promise of ajax request.
   */
  getOperatorResponse(data) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'guest-forms/all-responses?date_from=' + data.params.date_from +
        "&date_to=" + data.params.date_to +
        "&package_id=" + data.params.package_id +
        "&page=" + data.params.page +
        "&guest_form_title=" + data.params.guest_form_title +
        "&order_direction=" + data.params.order_direction +
        "&order_by=" + data.params.order_by + "&limit=10",
      headers: {
        'token': data.token,
        'Content-Type': 'application/json'
      }
    };
    return this.httpReq(req);
  }

  /**
 * @ngdoc method
 * @name getOperatorForms
 *
 * @description
 * This method call API to get operator forms
 * @return {Object} Promise of ajax request.
 */
  getOperatorFormDetail(params) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'guest-forms/details/' + params.id,
      headers: {
        'token': params.token,
        'Content-Type': 'application/json'
      }
    };
    return this.httpReq(req);
  }

  getOperatorResponseFormDetail(params) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'guest-forms/view-responses/' + params.id,
      headers: {
        'token': params.token,
        'Content-Type': 'application/json'
      }
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name deleteOperatorForms
   *
   * @description
   * This method call API to delete operator forms
   * @return {Object} Promise of ajax request.
   */
  deleteOperatorForms(params) {
    let req = {
      method: 'DELETE',
      url: this.REQUEST_URL + 'guest-forms/delete/' + params.id,
      headers: {
        'token': params.token,
        'Content-Type': 'application/json'
      }
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name addEditOperatorForms
   *
   * @description
   * This method call API to Add / Edit operator forms
   * @return {Object} Promise of ajax request.
   */
  addEditOperatorForms(param) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'guest-forms/add' + (param.id ? ('/' + param.id) : ''),
      data: param.json,
      headers: {
        'token': param.token,
        'Content-Type': 'application/json'
      }
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name getAvailableAdventures
   *
   * @description
   * This method call API to get results for available adventures to auto populate the search box.
   * @return {Object} Promise of ajax request.
   */
  getAvailableAdventures(key) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'autoPopulates/auto-searches?q=' + key
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name searchAdventures
   *
   * @description
   * This method call API to get search results using search criteria.
   * @return {Object} Promise of ajax request.
   */
  searchAdventures(criteria) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'searches/search?keyword=' + criteria.keyword + '&mindate=' + this.formatDate(criteria.mindate) + '&maxdate=' + this.formatDate(criteria.maxdate) + '&limit=' + criteria.limit + '&page=' + criteria.page + '&back=' + criteria.back + '&latitude=' + criteria.lat + '&longitude=' + criteria.lang + '&sort=' + criteria.sort
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name getAdventureSuggesion
   *
   * @description
   * This method call API to get search suggesion extending search criteria.
   * @return {Object} Promise of ajax request.
   */
  getAdventureSuggestion(criteria) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'searches/searchSuggestion?keyword=' + criteria.keyword + '&mindate=' + this.formatDate(criteria.mindate) + '&maxdate=' + this.formatDate(criteria.maxdate) + '&limit=' + criteria.limit + '&page=' + criteria.page + '&back=' + criteria.back + '&latitude=' + criteria.lat + '&longitude=' + criteria.lang + '&sort=' + criteria.sort
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name logout
   *
   * @description
   * Logout user.
   *
   * @param {String} reqParam Request parameter.
   * @return {Object} Promise of ajax request.
   */

  /**
   * @ngdoc method
   * @name sortAdventures
   *
   * @description
   * This method call API to sort the searched Adventure packages using sort criteria.
   * @return {Object} Promise of ajax request.
   */

  sortAdventures(sortCriteria) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'searches/sorting?keyword=' + sortCriteria.keyword + '&mindate=' + this.formatDate(sortCriteria.mindate) + '&maxdate=' + this.formatDate(sortCriteria.maxdate) + '&limit=' + sortCriteria.limit + '&page=' + sortCriteria.page + '&back=' + sortCriteria.back + '&latitude=' + sortCriteria.lat + '&longitude=' + sortCriteria.lang + '&sort=' + sortCriteria.sort
    };
    return this.httpReq(req);
  }

  getSports() {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'autoPopulates/getSports'
    };
    return this.httpReq(req);
  }

  getfilteredAdventures(filters) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'searches/filter?keyword=' + filters.keyword + '&mindate=' + this.formatDate(filters.mindate) + '&maxdate=' + this.formatDate(filters.maxdate) + '&limit=' + filters.limit + '&page=' + filters.page + '&back=' + filters.back + '&latitude=' + this.parseLatLang(filters.lat) + '&longitude=' + this.parseLatLang(filters.lang) + '&sort=' + filters.sort +
        '&activity_id=' + filters.activity_id + '&min_price=' + filters.min_price + '&max_price=' + filters.max_price + '&duration=' + filters.days + '&people=' + filters.people + '&abilities=' + filters.ability_id + '&package_sports_id=' + filters.package_sports_id
    };
    return this.httpReq(req);
  }

  parseLatLang(latlang) {
    return latlang || '';
  }
  /**
   * @ngdoc method
   * @name registerUser
   *
   * @description
   * This method call API to register a new user.
   *
   * @param {Object} params Request parameter.
   * @return {Object} Promise of ajax request.
   */

  registerUser(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'users/sign-up',
      data: data
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name checkUserName
   *
   * @description
   * This method call API to check if username exists or not.
   *
   * @param {Object} params Request parameter.
   * @return {Object} Promise of ajax request.
   */

  checkUserName(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'users/check-user-name',
      data: data
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name operatorRegistration
   *
   * @description
   * This method call API to create Operator.
   *
   * @param {Object} params Request parameter.
   * @return {Object} Promise of ajax request.
   */
  operatorRegistration(data) {
    /* Operator Signup in operatorSignupCtrl */
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'operators/sign-up-request',
      data: data,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name getCountry
   *
   * @description
   * This method call API to get country results.
   * @return {Object} Promise of ajax request.
   */
  getCountry(data) {
    /* Operator Signup in operatorSignupCtrl */
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'masters/country'
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name getCountry
   *
   * @description
   * This method call API to get country results.
   * @return {Object} Promise of ajax request.
   */
  getLocation(query) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'autoPopulates/auto-locations?q=' + query
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name completeSignUp
   *
   * @description
   * This method call API to complete user account.
   **/

  completeSignUp(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'users/complete-your-account',
      data: data
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name loginUser
   *
   * @description
   * This method call API for user login.
   *
   * @param {Object} params Request parameter.
   * @return {Object} Promise of ajax request.
   */

  loginUser(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'users/login',
      data: data
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name isAlreadyUser
   *
   * @description
   * This method call API for checking if user is already registered.
   *
   * @param {Object} params Request parameter.
   * @return {Object} Promise of ajax request.
   */
  isAlreadyUser(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'users/social-login',
      data: data
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name completeSocialSignUp
   *
   * @description
   * This method call API to complete user account via social sign.
   **/

  completeSocialSignUp(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'users/social-sign-up',
      data: data
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name loginOperator
   *
   * @description
   * This method call API for operator login.
   * @param {Object} params Request parameter.
   * @return {Object} Promise of ajax request.
   */
  loginOperator(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'operators/sign-in',
      data: data
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name checkValidToken
   *
   * @description
   * This method call API for valid token.
   *
   * @param {Object} params Request parameter.
   * @return {Object} Promise of ajax request.
   */

  checkValidToken(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'operators/validate-token',
      data: data
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name forgotPassword
   *
   * @description
   * This method call API to submit new password request.
   **/

  forgotPassword(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'users/forgot-password',
      data: data
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name set password for operator
   *
   * @description
   * This method call API for set password for operator.
   *
   * @param {Object} params Request parameter.
   * @return {Object} Promise of ajax request.
   */

  setOperatorPassword(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'operators/set-new-password',
      data: data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'token': data.token
      }
    };
    return this.httpReq(req);
  }
  /*
   * @name mergeAccounts
   *
   * @description
   * This method call API to merge accounts.
   */
  mergeAccounts(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'users/request-merge-account',
      data: data
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name checkForValidToken
   *
   * @description
   * This method call API to check if forgot password token is valid or not
   **/

  checkForValidToken(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'users/validate-forgot-password-token',
      data: data
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name getOperatorPackages
   *
   * @description
   * This method call API to list packages for Operator.
   *
   * @param {Object} params Request parameter.
   * @return {Object} Promise of ajax request.
   */
  getOperatorPackages(data) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'operators/packageList?status=' + data.status + '&limit=' + data.limit + '&page=' + data.page + '&back=' + data.back + '&op_location_id=' + data.location_id + '&type=' + data.type + '',
      headers: {
        'Content-Type': 'application/json',
        'token': data.token
      }
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name getOperatorPackagesSlots
   *
   * @description
   * This method call API to list slots for OperatorPackages.
   *
   * @param {Object} params Request parameter.
   * @return {Object} Promise of ajax request.
   */
  getOperatorPackagesSlots(data) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'operators/getOperatorPackageSlot?package_id=' + data.package_id + '',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'token': data.token
      }
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name resetPass
   *
   * @description
   * This method call API to check if forgot password token is valid or not
   **/

  resetPass(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'users/reset-password',
      data: data
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name resendEmailVerificationLink
   *
   * @description
   * This method call API to resend the email verification link...
   **/

  resendEmailVerificationLink(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'users/resend-email-verification-link',
      data: data
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name isEmailVerified
   *
   * @description
   * This method call API to check if user email is verified or not
   **/

  isEmailVerified(token) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'users/is-email-verified',
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name checkForValidTokenEmail
   *
   * @description
   * This method call API to check if email verification token is valid or not
   **/

  checkForValidTokenEmail(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'users/email-verification',
      data: data
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name publishPackage
   *
   * @description
   * This method call API to send complete package info for publishing
   **/

  publishPackage(params, file) {
    if (file) { // if file is present
      const config = {
        url: this.REQUEST_URL + 'packages/publish',
        fields: params,
        file: file,
        fileFormDataName: 'gallery_images[]',
        headers: {
          'token': params.token
        }
      }
      return this.angularUploadFile(config);
      // file.upload = this.$upload.upload({
      //   /* using angualr file upload service */
      //   url: this.REQUEST_URL + 'packages/publish',
      //   method: 'POST',
      //   fields: params,
      //   file: file,
      //   fileFormDataName: 'gallery_images[]',
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded',
      //     'token': params.token
      //   }
      // });
      // let promise = file.upload.progress(function (evt) { }).success(function (result) {
      //   return result;
      // }).error(function (error) {
      //   /* If service is not successfull then show error */
      //   return error;
      // });
      // return promise;
    } else {
      let req = {
        method: 'POST',
        url: this.REQUEST_URL + 'packages/publish',
        data: params,
        headers: {
          'Content-Type': 'application/json',
          'token': params.token
        }
      };
      return this.httpReq(req);
    }
  }
  /**
   * @ngdoc method
   * @name fetchAdventureDetails
   *
   * @description
   * This method call API to send complete package info for publishing
   **/

  fetchAdventureDetails(adventure) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'packages/getPackageDetails?package_id=' + adventure.package_id + '&referral_id=' + adventure.referral_id + '&currency_code=' + adventure.currency_code,
      headers: {
        'Content-Type': 'application/json',
        'token': adventure.userToken ? adventure.userToken : ''
      }
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name getConsumerProfile
   *
   * @description
   * This method call API to get basic information of consumer profile
   **/

  getConsumerProfile(data) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'consumers/profile',
      headers: {
        'Content-Type': 'application/json',
        'token': data.token
      }
    };
    return this.httpReq(req);
  }
  /**
   * @name getOperatorProfile
   *
   * @description
   * This method call API to fetch operator profile
   **/
  getOperatorProfile(data) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'operators/profile',
      headers: {
        'token': data
      }
    };
    return this.httpReq(req);
  }

  /**
   * @name getOperatorProfile
   *
   * @description
   * This method call API to fetch operator profile
   **/
  getOperatorProfileById(data) {
    return this.httpReq({
      method: 'GET',
      url: this.REQUEST_URL + 'operators/profileById?operatorId=' + data.operatorId,
      headers: {
        token: data.token
      }
    });
  }

  checkValidLocation(name, data) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'locations/location-name-unique?name=' + name.name + (name.id ? '&id=' + name.id : ''),
      headers: {
        'token': data
      }
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name save basic details of consumer
   *
   * @description
   * This method call API to save basic details of consumer.
   */

  addBasicConsumerDetails(data, params) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'consumers/add-basic-profile',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'token': params.token
      }
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name save privacy features of consumer
   *
   * @description
   * This method call API to privacy features of consumer.
   */

  addPrivacy(data, params) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'consumers/add-privacy',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'token': params.token
      }
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name change password of the user
   *
   * @description
   * This method call API to change password of the user.
   */

  changePassword(data, params) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'users/changePassword',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'token': params.token
      }
    };
    return this.httpReq(req);
  }
  /**
   * @name IncreseHit
   *
   * @description
   * This method call API to increase package hits
   **/

  increaseAdventureHits(data) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'searches/addHits?package_id=' + data.package_id
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name getTimeZones
   *
   * @description
   * This method call API to fetch world tome zones
   **/
  getTimeZones() {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'masters/time-zone'
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name saveOperatorProfile
   *
   * @description
   * This method call API to complete operator profile
   **/
  saveOperatorProfile(params, file) {
    let token = params.token;
    delete params['token'];

    if (file.length > 0) { // if file is present
      const config = {
        url: this.REQUEST_URL + 'operators/add-basic-profile',
        fields: params,
        file: file,
        fileFormDataName: 'gallery_image[]',
        headers: {
          'token': token
        }
      }
      return this.angularUploadFile(config);
      // file.upload = this.$upload.upload({
      //   /* using angualr file upload service */
      //   url: this.REQUEST_URL + 'operators/add-basic-profile',
      //   method: 'POST',
      //   fields: params,
      //   file: file,
      //   fileFormDataName: 'gallery_image[]',
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded',
      //     'token': token
      //   }
      // });
      // let promise = file.upload.progress(function (evt) { }).success(function (result) {
      //   return result;
      // }).error(function (error) {
      //   /* If service is not successfull then show error */
      //   return error;
      // });
      // return promise;
    } else {
      params.gallery_image = ''; // set gallery images to be blank
      let req = {
        method: 'POST',
        url: this.REQUEST_URL + 'operators/add-basic-profile',
        data: params,
        headers: {
          'Content-Type': 'application/json',
          'token': token
        }
      };
      return this.httpReq(req);
    }
  }
  /**
   * @ngdoc method
   * @name saveOperatorLocation
   *
   * @description
   * This method call API to complete operator profile
   **/
  saveOperatorLocation(params, file) {
    let token = params.token;
    delete params['token'];

    if (file && file.length > 0) { // if file is present
      const config = {
        url: this.REQUEST_URL + 'locations/add',
        fields: params,
        file: file,
        fileFormDataName: 'op_location_medias[]',
        headers: {
          'token': token
        }
      }
      return this.angularUploadFile(config);
      // file.upload = this.$upload.upload({
      //   /* using angualr file upload service */
      //   url: this.REQUEST_URL + 'locations/add',
      //   method: 'POST',
      //   fields: params,
      //   file: file,
      //   fileFormDataName: 'op_location_medias[]',
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded',
      //     'token': token
      //   }
      // });
      // let promise = file.upload.progress(function (evt) { }).success(function (result) {
      //   return result;
      // }).error(function (error) {
      //   /* If service is not successfull then show error */
      //   return error;
      // });
      // return promise;
    } else {
      // params.gallery_image = '';// set gallery images to be blank
      let req = {
        method: 'POST',
        url: this.REQUEST_URL + 'locations/add',
        data: params,
        headers: {
          'Content-Type': 'application/json',
          'token': token
        }
      };
      return this.httpReq(req);
      // }
    }
  }

  /**
   * @ngdoc method
   * @name saveLocationLodging
   *
   * @description
   * This method call API to complete operator profile
   **/
  saveLocationLodging(params, file) {
    let token = params.token;
    delete params['token'];

    if (file && file.length > 0) { // if file is present
      const config = {
        url: this.REQUEST_URL + 'locations/add-lodging',
        fields: params,
        file: file,
        fileFormDataName: 'op_lodging_medias[]',
        headers: {
          'token': token
        }
      }
      return this.angularUploadFile(config);
      // file.upload = this.$upload.upload({
      //   /* using angualr file upload service */
      //   url: this.REQUEST_URL + 'locations/add-lodging',
      //   method: 'POST',
      //   fields: params,
      //   file: file,
      //   fileFormDataName: 'op_lodging_medias[]',
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded',
      //     'token': token
      //   }
      // });
      // let promise = file.upload.progress(function (evt) { }).success(function (result) {
      //   return result;
      // }).error(function (error) {
      //   /* If service is not successfull then show error */
      //   return error;
      // });
      // return promise;
    } else {
      let req = {
        method: 'POST',
        url: this.REQUEST_URL + 'locations/add-lodging',
        data: params,
        headers: {
          'Content-Type': 'application/json',
          'token': token
        }
      };
      return this.httpReq(req);
      // }
    }
  }
  /**
   * @ngdoc method
   * @name checkDeleteLodging
   *
   * @description
   * This method call API to check status if lodging should be deleted
   **/
  checkDeleteLodging(params) {
    let token = params.token;
    delete params['token'];

    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'locations/check-delete-lodging',
      data: params,
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    };
    return this.httpReq(req);
    // }
  }
  /**
   * @ngdoc method
   * @name get package detaiil
   *
   * @description
   * This method call API to fetch package detail
   **/
  getPackageDetail(data, file) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'packages/getPackageDetails?package_id=' + data.package_id + '&is_operator_edit=' + data.is_operator_edit,
      headers: {
        'token': data.token || data
      }
    };
    return this.httpReq(req);
  }

  deleteLocation(data, token) {
    let req = {
      method: 'DELETE',
      url: this.REQUEST_URL + 'locations/delete-location/' + data,
      headers: {
        'token': token
      }
    };
    return this.httpReq(req);
  }

  deleteTax(taxId, token) {
    return this.httpReq({
      method: 'DELETE',
      url: this.REQUEST_URL + 'taxes/' + taxId,
      headers: {
        'token': token
      }
    });
  }
  /**
   * @ngdoc method
   * @name addToUserRadarList
   *
   * @description
   * This method call API to add package to radar list
   **/

  addToUserRadarList(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'radars/addRadar',
      data: {
        'package_id': data.package_id,
        'referral_id': data.referral_id
      },
      headers: {
        'Content-Type': 'application/json',
        'token': data.userToken
      }
    };
    return this.httpReq(req);
  }

  checkDomainValid(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'checkDomainValid',
      data: {
        sub_domain: data.subDomain,
        username: data.username
      },
      headers: {
        'Content-Type': 'application/json',
        'token': data.userToken
      }
    };
    return this.httpReq(req);
  }
  /* @name removeFromUserRadarList
   *
   * @description
   * This method call API to remove the package from user radar list
   * @return {Object} Promise of ajax request.
   */
  removeFromUserRadarList(data) {
    let req = {
      method: 'DELETE',
      url: this.REQUEST_URL + 'radars/deleteRadar',
      data: {
        'package_id': data.package_id
      },
      headers: {
        'Content-Type': 'application/json',
        'token': data.userToken
      }
    };
    return this.httpReq(req);
  }

  /* @name getUserRadarList
   *
   * @description
   * This method call API to get user radar list
   */
  getUserRadarList(data) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'radars/radarListing?limit=' + data.limit + '&page=' + data.page + '&back=' + data.back + '&username=' + data.username,
      headers: {
        'Content-Type': 'application/json'
        // ,'token' : data.token
      }
    };
    return this.httpReq(req);
  }

  /* @name getSkiierAbilities
   *
   * @description
   * This method call API to get assets of module.
   * @return {Object} Promise of ajax request.
   */
  getSkiierAbilities() {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'packages/get-skiier-abilities'
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name getCancelPolicyDeatil
   *
   * @description
   * This method call API to get assets of module.
   * @return {Object} Promise of ajax request.
   */
  getCancelPolicyDeatil(data) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'operators/getDefaultCancelationPolicy',
      headers: {
        'token': data
      }
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name checkOldPassword
   *
   * @description
   * This method call API to change the operator password
   **/
  changeOperatorPass(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'users/changePassword',
      data: data,
      headers: {
        'token': data.token,
        'Content-Type': 'application/json'
      }
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name getAdventureCount
   *
   * @description
   * This method call API to get total operator package count
   * @return {Object} Promise of ajax request.
   */
  getAdventureCount(token) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'operators/totalPackageCount',
      headers: {
        'token': token
      }
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name checkEmailExistence
   *
   * @description
   * This method call API to check if email already exists.
   * @return {Object} Promise of ajax request.
   */
  checkEmailExistence(email, token) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'users/check-email-editable',
      data: email,
      headers: {
        'token': token
      }
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name clonePackage
   *
   * @description
   * This method call API to clone the package
   **/
  clonePackage(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'operator-packages/clone-package',
      data: data,
      headers: {
        'token': data.token,
        'Content-Type': 'application/json'
      }
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name saveFBAccessToken
   *
   * @description
   * This method call to save the FB access token
   **/
  saveFBAccessToken(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'operator-packages/clone-package',
      data: data,
      headers: {
        'token': data.token,
        'Content-Type': 'application/json'
      }
    };
    return this.httpReq(req);
  }

  /* @name getFBAccessToken
   *
   * @description
   * This method call API to get FB access Token
   */
  getFBAccessToken(data) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'radars/radarListin',
      headers: {
        'Content-Type': 'application/json',
        'token': data.token
      }
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name sendEmail
   *
   * @description
   * This method call share the content thorugh email
   **/
  sendEmail(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'shares/with-email',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'token': data.token
      }
    };
    return this.httpReq(req);
  }
  /**
   * @name getPackageSlotAddons
   *
   * @description
   * This method call API to get package slot addons
   **/
  getPackageSlotAddons(data) {
    if (data.package_id) {
      var req = {
        method: 'GET',
        url: this.REQUEST_URL + 'Carts/viewCart?package_id=' + data.package_id + '&cart_id=' + data.cart_id + '&from_date=' + data.start_date + '&to_date=' + data.end_date
      };
    } else {
      var req = {
        method: 'GET',
        url: this.REQUEST_URL + 'Carts/viewCart?slot_id=' + data.slot_id + '&cart_id=' + data.cart_id
      };
    }
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name addToCart
   *
   * @description
   * This method call API to add package to cart
   **/
  addToCart(data, token) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'Carts/saveCart',
      data: data,
      headers: {
        'token': token,
        'Content-Type': 'application/json'
      }
    };
    return this.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name getCartList
   *
   * @description
   * This method call API to fetch user/guest card list...
   **/
  getCartList(cart_id, token, options?) {
    let config = {
      method: 'GET',
      url: this.REQUEST_URL + 'carts/myCartList',
      params: {
        cart_id: cart_id
      },
      headers: {
        'token': token,
        'Content-Type': 'application/json'
      }
    };

    if (options && options.params) {
      angular.extend(config.params, options.params);
    }

    return this.httpReq(config);
  }
  /**
   * @ngdoc method
   * @name getCreditsBalance
   * Retrieves the credits balance for a user.
   *
   * @param {string} token - The token for authorization
   * @return {Observable<any>} The response containing the credits balance
   */
  getCreditsBalance(token) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'credit/get-credits',
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    };
    return this.httpReq(req);
  }

  validateCredits(creditsData, token) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + `credit/validate-credits?amount=${creditsData.amount}&cart_id=${creditsData.cart_id}&is_deposit=${creditsData.is_deposit}`,
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    }
    return this.httpReq(req);
  }

  applyCredits(creditsData, token) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'carts/applyCredits',
      data: creditsData,
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    }
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name removeCredits
   *
   * @description
   * This method call API to remove credits from cart...
   *
   * @param {Object} creditsData - The data containing the amount and cart_id
   * @param {string} token - The token for authorization
   * @return {Promise<any>} The response containing the credits balance
   */
  removeCredits(creditsData, token) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'carts/removeCredits',
      data: creditsData,
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    }
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name deleteCartItem
   *
   * @description
   * This method call API to delete user/guest card item...
   **/
  deleteCartItem(item, token) {
    let req = {
      method: 'DELETE',
      url: this.REQUEST_URL + 'carts/removeCart?cart_id=' + item.cart_id + '&slot_id=' + item.slot_id + '&package_id=' + item.package_id + '&nightly_slot_id=' + item.nightly_slot_id + '&booking_slot_detail_id=' + item.booking_slot_detail_id,
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name becomeAmbassador
   *
   * @description
   * This method call API to apply for becoming an ambassador
   */
  becomeAmbassador(params) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'consumers/apply-to-influencer',
      headers: {
        'Content-Type': 'application/json',
        'token': params.token
      }
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name getCurrencyList
   *
   * @description
   * This method call API to get assets of module.
   * @return {Object} Promise of ajax request.
   */
  getCurrencyList() {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'masters/currencies'
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name getfeaturedAdventures
   *
   * @description
   * This method call API to get assets of module.
   * @return {Object} Promise of ajax request.
   */
  getfeaturedAdventures() {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'masters/getFeaturedData'
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name getfeaturedActivity
   *
   * @description
   * This method call API to get featured activity.
   * @return {Object} Promise of ajax request.
   */
  getfeaturedActivity() {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'masters/getFeaturedSports'
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name getHomePagelogo
   *
   * @description
   * This method call API to get home page logos.
   * @return {Object} Promise of ajax request.
   */
  getHomePagelogo() {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'HomePageLogos'
    };
    return this.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name getHomePagelogo
   *
   * @description
   * This method call API to get home page logos.
   * @return {Object} Promise of ajax request.
   */
  sendFormsGuest(data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'guest-forms/sendToGuest',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'token': data.token
      }
    };
    return this.httpReq(req);
  }

  plaidGetAccessToken(publicToken) {
    return this.httpReq({
      method: 'POST',
      url: this.REQUEST_URL + 'payments/plaidGetAccessToken',
      data: {
        publicToken: publicToken
      },
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  plaidCreateBankAccountTokenForStripe(params) {
    return this.httpReq({
      method: 'POST',
      url: this.REQUEST_URL + 'payments/plaidCreateBankAccountTokenForStripe',
      data: {
        userToken: params.userToken,
        publicToken: params.publicToken,
        accountId: params.accountId
      },
      headers: {
        'Content-Type': 'application/json',
        'token': JSON.parse(this.cookieService.get('user')).token
      }
    });
  }

  getAllListCountry() {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'masters/all-country'
    };
    return this.httpReq(req);
  }

  getDestinationCategories() {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'destination-pages/get-categories-simple-data-type'
    };
    return this.httpReq(req);
  }

  getDestinationLocations(destinationName, pageName: string = '') {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + `destination-pages/get-categories-middle-data-type/${destinationName}` + (pageName ? `?page_name=${pageName}` : '')
    };
    return this.httpReq(req);
  }
}

angular.module('heliApp')
  .service('webService', downgradeInjectable(WebService));

