import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environment';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name UserSocialProfileService
 * @description
 * # userProfileService
 * Service in the heliApp.
 */

@Injectable()

export class UserSocialProfileService {

  private REQUEST_URL;
  isAgent: boolean;

  constructor(
    @Inject(WebService) private webService: WebService,
    @Inject(HttpClient) private httpClient: HttpClient
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  setAgent(value) {
    this.isAgent = Boolean(value);
  }

  sendReq(method) {
    return new Promise((resolve, reject) => {
      method.then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }

  postReq(url, data, token) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + url,
      data: data,
      headers: {
        'token': token
      }
    };
    return req;
  }

  getReq(url, token?) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + url,
      headers: {
        'Content-Type': 'application/json',
        'token': token
      }
    };
    return this.webService.httpReq(req);
  }

  // send request to get user social profile..
  getUserSocialProfile(userData, token) {
    let req = this.postReq('users/social-profile', userData, token);
    return this.webService.httpReq(req);
  }

  // send request to get user social profile..
  getAgentSocialProfile(userData, token) {
    let req = this.postReq('Agents/socialProfile', userData, token);
    return this.webService.httpReq(req);
  }

  // send request to get user location adventures.
  getUserLocationAdventures(id) {
    return this.getReq('locations/detail-with-adventures?userid=' + id);
  }

  getOperatorListing(userData, token) { // Req To get Operator Listing
    let url = 'operators/get-list?limit=' + userData.limit + '&page=' + userData.page + '&search=' + userData.search + '&back=' + userData.back;
    // let req = this.getReq("operators/get-list",userData,token)
    return this.getReq(url, token);
  }

  getFollowerListing(userData, token) { // Req To get Follower listing
    let url = 'social-activities/get-followers?user_id=' + userData.user_id + '&limit=' + userData.limit + '&page=' + userData.page + '&back=' + userData.back;
    return this.getReq(url, token);
  }

  getFollowingListing(userData, token) { // Req To get Follower listing
    let url = 'social-activities/get-followings?user_id=' + userData.user_id + '&limit=' + userData.limit + '&page=' + userData.page + '&back=' + userData.back;
    return this.getReq(url, token);
  }

  operatorPackageList(data, token) { // call API to fetch get Operator Package List
    let url = 'operators/publishPackageList?user_id=' + data.user_id + '&limit=' + data.limit + '&page=' + data.page + '&back=' + data.back + '&privateIncluded=' + data.privateIncluded;
    console.log(this.getReq(url));

    return this.getReq(url, token);
  }

  operatorPackageListSubDomain(data) { // call API to fetch get Operator Package List
    // operator-adventure-site-profile/?limit=12&user_name=michaelchors&page=1
    let url = 'operator-sites/operator-adventure-site-profile?user_name=' + data.user_name + '&domain_url=' + data.domain_url + '&limit=' + data.limit + '&page=' + data.page;
    return this.getReq(url);
  }

  // post the user feed...
  postFeed(postData, file, token) {
    if (file) { // if file is present
      const config = {
        url: this.REQUEST_URL + 'feeds/savePost',
        fields: postData,
        file: file,
        fileFormDataName: 'image[]',
        headers: {
          'token': token
        }
      }
      return this.webService.angularUploadFile(config);
      // file.upload = this.$upload.upload({/* using angualr file upload service */
      //   url: this.REQUEST_URL + 'feeds/savePost',
      //   method: 'POST',
      //   fields: postData,
      //   file: file,
      //   fileFormDataName: 'image[]',
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded',
      //     'token': token
      //   }
      // });
      // let promise = file.upload.progress((evt) => {
      // }).success((result) => {
      //   return result;
      // }).error((error) => { /* If service is not successfull then show error */
      //   return error;
      // });
      // return promise;
    } else {
      let req = this.postReq('feeds/savePost', postData, token);
      return this.webService.httpReq(req);
    }
  }

  // get user own feeds...
  getUserFeeds(feedData) {
    let url = 'feeds/getUserFeeds?user_id=' + feedData.user_id + '&limit=' + feedData.limit + '&page=' + feedData.page + '&back=' + feedData.back;
    return this.getReq(url, feedData.token);
  }

  /**
 * @ngdoc method
 * @name getFeedDetail
 *
 * @description
 * Get detail about specific feed
 **/
  getFeedDetail(data) {
    let url = 'feeds/get-feed-details?feed_id=' + data.feed_id;
    return this.getReq(url, data.token);
  }

  /**
 * @ngdoc method
 * @name editFeedPost
 *
 * @description
 * edit feed of feed type 1 and post type 1,2,3
 **/
  editFeedPost(data) {
    let req = this.postReq('feeds/editPost', data, data.token);
    return this.webService.httpReq(req);
  }
}

angular.module('heliApp').service('userSocialProfileService', downgradeInjectable(UserSocialProfileService));