
/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:SearchCtrl
 * @description
 * # SearchCtrl
 * Controller of the heliApp
 */
// eslint-disable-next-line no-undef
angular.module('heliApp').controller('agentSignupCtrl',[
  '$scope', 
  '$rootScope', 
  '$timeout', 
  'userSocialProfileService', 
  'agentService', 
  function ($scope, $rootScope, $timeout, userSocialProfileService, agentService) {
  $scope.searchText = {};
  $scope.searchText.text = '';
  $scope.operatorList = [];
  $scope.noRecordFound = false;
  $scope.isSearchTextFocused = false;
  $scope.page = 1;
  $scope.agent = {};
  $scope.agent.email = '';
  $scope.emailPattern = /^([a-zA-Z0-9_.-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  var listLimit = 24;
  $scope.selectedOperator = {};
  $scope.addguestFormIsNotValid = false;
  $scope.operatorIds = [];
  getOperatorList();

  $scope.loadMore = function () {
    $scope.page = Number($scope.page) + 1;
    console.log('tag', $scope.selectedOperator);
    // eslint-disable-next-line no-self-assign
    getOperatorList();
  };
  $scope.searchOperator = function (query) {
    if (query.length >= 2 || query.length == 0) {
      $scope.operatorList = [];
      $scope.page = 1;
      $scope.showLoader();
      getOperatorList();
    }
  };
  $scope.onChange = function () {
    $scope.operatorIds = Object.keys($scope.selectedOperator).filter(function (key) { return $scope.selectedOperator[key] == true; });
  };
  $scope.sendAgentRequest = function () {
    if (!$scope.agent.email || $scope.agent.email == '' || $scope.operatorIds.length < 1) {
      $scope.addguestFormIsNotValid = true;
      return;
    } else {
      $scope.addguestFormIsNotValid = false;
    }
    $scope.showLoader();
    $scope.operatorIds = Object.keys($scope.selectedOperator).filter(function (key) { return $scope.selectedOperator[key] == true; });
    var requestObject = {
      email: $scope.agent.email,
      operator_ids: $scope.operatorIds
    };
    agentService.agentSignUpRequest(requestObject).then(function (response) {
      console.log('tag', response);
      if (response.data.code == '200') {
        console.log('if called');
        $scope.hideLoader();
        $scope.showPopup('#agentRequest');
      } else if (response.data.code == '409') {
        $scope.hideLoader();
        $rootScope.errorAlert = response.data.message.email.email;
        $scope.showPopup('#AlertModal');
      } else {
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      }
    });
  };
  function getOperatorList () {
    $scope.showLoader();
    var requestObject = {

      'page': $scope.page,
      'search': $scope.searchText.text,
      'limit': listLimit
      // "search":  tempSearchValue ?  tempSearchValue : query || '',
    };
    userSocialProfileService.getOperatorListing(requestObject, $scope.user.token).then(function (response) { // call opearot listing api
      if (response.data.code === '200') {
        $scope.noRecordFound = false;
        $scope.operatorList = $scope.operatorList.concat(response.data.data);
        $scope.totalOperators = response.data.count;
        // more operator Shown on operator list Page
        $scope.isLoadMoreButton = ((response.data.count - $scope.operatorList.length) > 0);
        $scope.resCount = response.data.count;
        $timeout(function () { // scroll to previous position.
          if ($rootScope.operatorOffset > 500 && $rootScope.previousState && ($rootScope.previousState.name === 'myprofile' || $rootScope.previousState.name === 'signin')) {
            angular.element('html, body').animate({ scrollTop: $rootScope.operatorOffset }, 1000);
          }
        }, 10);
        $scope.hideLoader();
      } else if (response.data.code === '404') {
        $scope.isLoadMoreButton = false;
        $scope.operatorList = [];
        $scope.totalOperators = 0;
        $scope.noRecordFound = true;
        $scope.hideLoader();
      }
    }, function () {
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
  };
}]);
