/* eslint-disable camelcase */
/* eslint-disable handle-callback-err */
/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:userPaymentCtrl
 * @description
 * # userPaymentCtrl
 * Controller of the heliApp for managing user payment methods and payments
 */
angular.module('heliApp').controller(
  'userPaymentCtrl', ['$scope', '$location', '$anchorScroll', '$cookies', '$filter', '$timeout', 'paymentService', 'operatorProfileService', 'flywireService', 'plaidService', 'userAdventureDetailService', '$rootScope', '$state', '$stateParams', 'STRIPE_KEY', 'CONST_HELPER', 'operatorManualPaymentService', '$q', 'rootScopeService', 'scriptLoaderService',
  function ($scope, $location, $anchorScroll, $cookies, $filter, $timeout, paymentService, operatorProfileService, flywireService, plaidService, userAdventureDetailService, $rootScope, $state, $stateParams, STRIPE_KEY, CONST_HELPER, operatorManualPaymentService, $q, rootScopeService, scriptLoaderService) {
    $scope.cart = {
      id: null
    };

    $scope.showTabs = false;
    $scope.showBankWireTransferTab = false;
    $scope.isFlywirePaymentAvailable = false;
    $scope.isPlaidPaymentAvailable = false;
    $scope.showPlaidPayButtonWithPrice = false;

    $scope.$on('secureCheckout::cartLoaded', function (event, cartItemsList) {
      if ($stateParams.order_id) {
        $scope.cart.id = cartItemsList.cartSlotDetails[0].cart_id;
        $scope.paymentDateDepositPayment = cartItemsList;
      } else {
        $scope.cart.id = cartItemsList.cartDetails.id;
      }

      angular.extend($scope.cart, cartItemsList);

      // Reset Flywire available flag
      $scope.isFlywirePaymentAvailable = false;
      // Reset Plaid available flag
      $scope.isPlaidPaymentAvailable = false;

      if (
        // "On My Site" mode: sub-domain only
        $rootScope.isSubDomain
        &&
        // All items in cart belongs to one operator
        (cartItemsList.itemsOperators.length === 1)
        &&
        // All items in cart defined in one currency
        (cartItemsList.itemsCurrencies.length === 1)
      ) {
        operatorProfileService
          .getOperatorProfileById({
            token: $rootScope.user.token,
            operatorId: $rootScope.CartDetails[0].operator_id
          })
          .then(function (response) {
            $scope.operator = response.data.data;

            if (
              // Is operator Flywire account is fully completed
              flywireService.isAccountComplete($scope.operator.paymentAccounts.flywire)
              &&
              // Cart items currency is the same as operator's Flywire portal currency
              (cartItemsList.itemsCurrencies[0].toUpperCase() === $scope.operator.paymentAccounts.flywire.portalCurrency.toUpperCase())
            ) {
              $scope.showTabs = true;
              $scope.showBankWireTransferTab = true;
              $scope.isFlywirePaymentAvailable = true;
              $cookies.put('isFlywirePaymentAvailable', $scope.isFlywirePaymentAvailable)
            }

            // Check Stripe account for Plaid availability
            if (
              $scope.operator.paymentAccounts.stripe
              &&
              $scope.operator.paymentAccounts.stripe.external_accounts
              &&
              $scope.operator.paymentAccounts.stripe.external_accounts.data
              &&
              ($scope.operator.paymentAccounts.stripe.external_accounts.data.length > 0)
              &&
              ($scope.operator.paymentAccounts.stripe.external_accounts.data[0].currency.toUpperCase() === 'USD')
            ) {
              $scope.isPlaidPaymentAvailable = true;
            }

            // Update payment systems availability flags
            $scope.$broadcast('userPayment::updateAvailabilityFlags');
          });
      }
    });

    plaidService.init($scope.user.token, function(plaidConfig) {
      $rootScope.$broadcast('plaid-payment', plaidConfig.stripeBankAccountToken);
    });

    // Calculate processing fee for each package.
    function calculateEach(item, USD, usdRate) {
      var eachProcessingFee = 0;
      if (item.is_deposit) {
        eachProcessingFee =
          Number(item.package.partial_amount.toFixed(0)) > 0
            ? (item.package.partial_amount + item.tax_amount + CONST_HELPER.PROCESS_FEE.Ffixed) / (1 - CONST_HELPER.PROCESS_FEE.Fpercent)
            : 0;

        eachProcessingFee = eachProcessingFee - item.package.partial_amount - item.tax_amount;
      } else {
        var newTotal = Number((item.base_amount - item.discount_amount - item.nightly_discount).toFixed(0));
        eachProcessingFee =
          newTotal > 0
            ? (item.base_amount - item.discount_amount - item.nightly_discount + item.total_tax + CONST_HELPER.PROCESS_FEE.Ffixed) / (1 - CONST_HELPER.PROCESS_FEE.Fpercent)
            : 0;

        eachProcessingFee = eachProcessingFee - newTotal - item.total_tax;
      }

      if (USD === CONST_HELPER.payment_const.default_currency) {
        eachProcessingFee = eachProcessingFee / usdRate;

        return Number(eachProcessingFee.toFixed(2));
      } else {
        return Number(eachProcessingFee.toFixed(2));
      }
    }

    function returnDueAmount(item, USD, usdRate) {
      if (item.is_deposit) {
        var due;
        var totalDue;

        if (item.is_promo_code === 1 && item.is_genric === 0) {
          due = Number((item.total_amount - item.package.partial_amount));
        } else {
          due = Number((item.total_amount - item.discount_amount - item.package.partial_amount).toFixed(2));
        }

        if (USD === CONST_HELPER.payment_const.default_currency) {
          totalDue = Number((due / usdRate).toFixed(2));
        } else {
          totalDue = Number(due.toFixed(2));
        }

        return totalDue > CONST_HELPER.ZERO ? totalDue : CONST_HELPER.ZERO;
      } else {
        return 0;
      }
    }

    function calculateSlotAmount(item, usdRate) {
      if (usdRate) {
        if (item.amount_percentage) {
          return Number((((item.base_amount - item.discount_amount - item.nightly_discount) * item.package.amount_percentage / 100) / usdRate).toFixed(2));
        } else {
          return Number(((item.base_amount - item.discount_amount - item.nightly_discount) / usdRate).toFixed(2));
        }
      } else {
        if (item.amount_percentage) {
          return Number(((item.base_amount - item.discount_amount - item.nightly_discount) * item.package.amount_percentage / 100).toFixed(2));
        } else {
          return Number((item.base_amount - item.discount_amount - item.nightly_discount).toFixed(2));
        }
      }
    }

    // Calculate total processing fee for each package partial/full payment.
    function calculateProcessingFee(item, currency, usdRate) {
      var totalProcessingFee = 0;
      var dueAmount;

      if (item.is_deposit) {
        if (item.is_promo_code === 1 && item.is_genric === 0) {
          dueAmount = item.total - item.package.partial_amount - item.nightly_discount;
        } else {
          dueAmount = item.total - item.discount_amount - item.package.partial_amount - item.nightly_discount;
        }

        var payingAmount = item.package.partial_amount - item.nightly_discount;

        calculate(payingAmount, item.tax_amount);
        calculate(dueAmount, item.total_tax - item.tax_amount);
      } else {
        calculate(item.base_amount - item.discount_amount - item.nightly_discount, item.total_tax);
      }
      if (currency === CONST_HELPER.payment_const.default_currency) {
        return Number((totalProcessingFee / usdRate).toFixed(2));
      } else {
        return Number(totalProcessingFee.toFixed(2));
      }
    }

    function calculate(totalAmount, totalTax) {		// calculate processing fee.
      totalAmount = Number(totalAmount.toFixed());

      var totalProcessingFee =
        totalAmount > 0
          ? totalProcessingFee + ((totalAmount + totalTax + CONST_HELPER.PROCESS_FEE.Ffixed) / (1 - CONST_HELPER.PROCESS_FEE.Fpercent))
          : totalProcessingFee;
      totalProcessingFee = totalProcessingFee - totalAmount - totalTax;

      return Number(totalProcessingFee.toFixed(2));
    }

    function saveFlywirePaymentCheckout (cartItemsListResponse, checkoutResult) {
      userAdventureDetailService
        .convertCurrency(
          CONST_HELPER.ONE,
          CONST_HELPER.payment_const.default_currency,
          $scope.operator.paymentAccounts.flywire.portalCurrency
        )
        .then(function (convertCurrencyResponse) {
          var cartSlotBookingDetail = [];
          var currentCart;
          if (!$stateParams.order_id) {
            currentCart = cartItemsListResponse.data;
            cartSlotBookingDetail = currentCart.cart_slot_details;
          } else {
            currentCart = cartItemsListResponse;
            cartSlotBookingDetail = currentCart.cartSlotDetails;
          }

          var usdRate = convertCurrencyResponse.data.usd_rate;
          var currentCartSlotDetails = [];

          // get domestic or international price.
          function isApplyDomesticTax (operatorTax) {
            if (!operatorTax) { return 0; }

            if (operatorTax.country_code === $rootScope.localCurrencyCode) {
              return operatorTax.domestic_percent;		// apply domestictax
            } else {
              return operatorTax.international_percent; // apply internation tax.
            }
          }

          cartSlotBookingDetail.map(function (cartItem, index) { 	// creating payment slot objects.
            var cartItemsFromRoot = $rootScope.CartDetails[index];
            var is_partial = cartItem.is_deposit ? CONST_HELPER.payment_const.is_partial : CONST_HELPER.payment_const.is_full;
            var is_final_payment = cartItem.is_deposit ? CONST_HELPER.payment_const.partial_payment : CONST_HELPER.payment_const.full_payment;

            if (cartItem.is_deposit && cartItem.package.deposit_type == 2) {
              cartItem.package.partial_amount = cartItem.package.amount_percentage;
            } else if (cartItem.is_deposit && cartItem.package.deposit_type == 1) {
              cartItem.package.partial_amount = cartItem.total * (cartItem.package.amount_percentage / 100);
            }

            if (cartItemsFromRoot) {
              if (cartItemsFromRoot.is_partial !== undefined) {
                is_partial = cartItemsFromRoot.is_partial;
              }

              if (cartItemsFromRoot.is_final_payment !== undefined) {
                is_final_payment = cartItemsFromRoot.is_final_payment;
              }
            }

            var totalTax = cartItem.total_tax;
            if (!totalTax && cartItem.operator_tax_cloud) {
              var taxPercent = isApplyDomesticTax(cartItem.operator_tax_cloud);
              totalTax = cartItem.base_amount * (taxPercent / 100);
            }

            var cartSlotDetail = {
              slot_id: cartItem.id,
              package_id: cartItem.package.id,
              operator_id: cartItem.package.user_id,
              tax_amount: Number(($scope.$parent.taxAmount / usdRate).toFixed(2)),
              local_tax_amount: $scope.$parent.taxAmount,
              is_partial: is_partial,
              due_date: cartItem.package_slots.slot_due_date || cartItem.package_slots.from_date,
              processing_fee: Number(($scope.$parent.processingFee / usdRate).toFixed(2)),
              local_processing_fee: $scope.$parent.processingFee,
              due_amount: $rootScope.CartDetails[0].due_amount,
              local_due_amount: $rootScope.CartDetails[0].local_due_amount,
              slot_amount: Number(($scope.$parent.CartTotal / usdRate).toFixed(2)),
              local_slot_amount: $scope.$parent.CartTotal,
              is_final_payment: is_final_payment,
              total_tax: Number(($scope.$parent.taxAmount / usdRate).toFixed(2)),
              local_total_tax: $scope.$parent.taxAmount,
              total_slot_amount: Number(((cartItem.base_amount - cartItem.discount_amount - cartItem.nightly_discount) / $rootScope.usdRate).toFixed(2)),
              local_total_slot_amount: Number((cartItem.base_amount - cartItem.discount_amount - cartItem.nightly_discount).toFixed(2)),
              total_processing_fee: Number(($scope.$parent.processingFee / usdRate).toFixed(2)),
              local_total_processing_fee: $scope.$parent.processingFee,
              cc_rate: $rootScope.usdRate,
              slot_end_date: $filter('date')(cartItem.package_slots.to_date, 'yyyy-MM-dd'),
              referral_id: cartItem.referral_id,
              credits_amount: $scope.$parent.creditsAmount,
            };

            // set booking source
            if ($rootScope.siteInIFrame) {
              cartSlotDetail.booking_source = CONST_HELPER.OPERATOR_SITE;
            } else if ($scope.isManualBooking) {
              cartSlotDetail.booking_source = CONST_HELPER.MANUAL_PAID;
            } else {
              cartSlotDetail.booking_source = '';
            }

            if (cartItem.operator_commission.is_on_site_enabled && ($rootScope.siteInIFrame || $rootScope.isSubDomain || $scope.isManualBooking)) {
              cartSlotDetail.opr_percentage = cartItem.operator_commission.on_site_commission;
            } else {
              cartSlotDetail.opr_percentage = (cartItem.package_slots && cartItem.package_slot) ? cartItem.package_slots.commission : cartItem.operator_commission.amount;
            }

            if (cartItem.payment_details && cartItem.payment_details.length) {
              cartSlotDetail.payment_detail_id = cartItem.payment_details[0].id;
            }

            currentCartSlotDetails.push(cartSlotDetail);
          });

          // Create transaction in our DB
          var paymentOptions = {
            flywire_reference: checkoutResult.reference,
            flywire_portal_code: $scope.operator.paymentAccounts.flywire.portalCode,
            amount: parseFloat(checkoutResult.amount),
            status: checkoutResult.status
          };

          // set booking source
          if ($rootScope.siteInIFrame) {
            paymentOptions.booking_source = CONST_HELPER.OPERATOR_SITE;
          } else if ($scope.isManualBooking) {
            paymentOptions.booking_source = CONST_HELPER.MANUAL_PAID;
          } else {
            paymentOptions.booking_source = '';
          }

          paymentOptions.guests_details = $rootScope.guests_details ? $rootScope.guests_details : JSON.parse($cookies.get('guests_details'));
          paymentOptions.total_processing_fee_amount = Number(($scope.$parent.processingFee).toFixed(2));
          paymentOptions.total_tax_amount = Number(($scope.$parent.taxAmount).toFixed(2));
          paymentOptions.booking_site = $rootScope.siteInIFrame ? (document.referrer != '' ? document.referrer : (window.location.href ? window.location.href : 'thegreatamericanskilodge.com')) : '';
          paymentOptions.iframe_site = $rootScope.siteInIFrame && !$rootScope.isSubDomain ? CONST_HELPER.ONE : CONST_HELPER.ZERO;
          paymentOptions.cart_id = $scope.cart.id;
          paymentOptions.slot_data = currentCartSlotDetails;
          paymentOptions.local_currency_code = $rootScope.operatorAdventureCurrencyCode ? $rootScope.operatorAdventureCurrencyCode : cartSlotBookingDetail[0].package_currency_code;
          paymentOptions.has_single_operator = $scope.$parent.hasSingleOperator;
          paymentOptions.final_connect_amount = $scope.$parent.hasSingleOperator ? Number(($scope.$parent.finalOperatorAmount / $rootScope.usdRate).toFixed(2)) : 0;
          paymentOptions.final_connect_amount_operator = $scope.$parent.hasSingleOperator && $scope.$parent.finalConnectOperatorAmount ? Number(($scope.$parent.finalConnectOperatorAmount).toFixed(2)) : 0; // final connect amount in operator currency...
          // for operator manualbookings...
          paymentOptions.user_email = $scope.$parent.manualGuest && $scope.$parent.manualGuest.email ? $scope.$parent.manualGuest.email : '';
          paymentOptions.user_notes = $scope.$parent.manualGuest && $scope.$parent.manualGuest.notes ? $scope.$parent.manualGuest.notes : '';
          if ($stateParams.order_id) {
            paymentOptions.order_id = $stateParams.order_id ? $stateParams.order_id : '';
          }

          var card_token_id = angular.copy(paymentOptions.card_token);
          var card_id = angular.copy(paymentOptions.card_id);
          delete paymentOptions.card_token;
          delete paymentOptions.card_id;

          var paymentDataForAPI = {
            id: paymentOptions,
            token: $rootScope.user.token,
            card_token: card_token_id,
            card_id: card_id,
            full_discount: (paymentOptions.amount === 0) ? 1 : ''
          };

          paymentService
            .makeFlywirePayment(paymentDataForAPI)
            .then(function (makeFlywirePaymentResponse) { // call payment api
              if (makeFlywirePaymentResponse && makeFlywirePaymentResponse.data && (makeFlywirePaymentResponse.data.code === CONST_HELPER.API_RESPONSE.OK)) {
                $scope.paymentErr = false;
                makeFlywirePaymentResponse.data.order_details.ord_date = Date.parse(makeFlywirePaymentResponse.data.order_details.ord_date);
                // set local storage again
                $cookies.put('orderDetails', JSON.stringify(makeFlywirePaymentResponse.data.order_details));
                $rootScope.orderDetails = makeFlywirePaymentResponse.data.order_details;
                $rootScope.myCartLength = 0;// reset cart list
                rootScopeService.setValue("myCartLength", $rootScope.myCartLength);

                $scope.hideLoader();

                var req = { id: { 'cart_id': paymentOptions.cart_id, 'order_number': makeFlywirePaymentResponse.data.order_details.order_number }, token: token };// set payment options
                paymentService.sendEmailToOperators(req);// send operators notification of successful payment

                // call API to link guest to guest-form urls
                var reqObj = {
                  id: makeFlywirePaymentResponse.data.guest_details,
                  token: $rootScope.user.token,
                };
                $cookies.put('guestDetails', JSON.stringify(reqObj)); // set guest details in cookies

                // if order is done on operator subdomain redirect to callback url.
                if ($rootScope.isSubDomain) {
                  paymentService.sendGuestForms(reqObj).then(function () {
                    $scope.callback_url = makeFlywirePaymentResponse.data.callback_url;
                    if ($scope.callback_url) {
                      // document.location = callback_url;
                      $scope.showPopup('#packageBookedSubDomain');
                    } else {
                      $state.go('order-successful');
                    }
                  });// send the guest forms to users
                } else {
                  $state.go('order-successful');
                  return false;
                }
                if ($rootScope.siteInIFrame) {
                  return;
                } // if white label booking then do not send data to google analytics
              }
            });

        });
    }

    $scope.openFlywireCheckoutWindow = function() {
      if ($scope.isFlywirePaymentAvailable) {
        var commissionAmount = 0.00;

        if (
          $scope.cart
          &&
          $scope.cart.cartSlotDetails
          &&
          $scope.cart.cartSlotDetails.length
        ) {
          if (!$scope.cart.cartSlotDetails[0].is_deposit && !$stateParams.order_id) {
            commissionAmount = $scope.cart.cartDetails && $scope.cart.cartDetails.commission && $scope.cart.cartDetails.commission.amount ? $scope.cart.cartDetails.commission.amount.toFixed(3) : 0;
          } else if ($scope.cart.cartSlotDetails[0].operator_commission.is_on_site_enabled && $scope.cart.cartSlotDetails[0].is_deposit && $stateParams.order_id) {
            commissionAmount = $scope.cart.cartSlotDetails[0].base_amount * ($scope.cart.cartSlotDetails[0].operator_commission.on_site_commission / 100);
          } else if ($scope.cart.cartSlotDetails[0].operator_commission.is_on_site_enabled && $scope.cart.cartSlotDetails[0].is_deposit && !$stateParams.order_id) {
            commissionAmount = 0;
          }
        }

        var checkoutConfig = {
          recipientCode: $scope.operator.paymentAccounts.flywire.portalCode,
          amount: $scope.$parent.GrandTotal.toFixed(3),
          country: ($rootScope.selectCountryTransfer && $rootScope.selectCountryTransfer.code) ? $rootScope.selectCountryTransfer.code : null,
          recipientFields: {
            booking_reference: String($scope.cart.id),
            additional_information: null
          },
          callbackId: String($scope.cart.id)
        };

        if (commissionAmount > 0) {
          checkoutConfig.commission = {
            amount: commissionAmount
          };
        }

        $scope.showLoader();

        flywireService
          .checkout(checkoutConfig)
          .then(function(checkoutResult) {
            if (checkoutResult.result === flywireService.CHECKOUT_RESULT.COMPLETE) {
              if (!$stateParams.order_id) {
                userAdventureDetailService
                  .getCartItemslist($scope.cart.id, $rootScope.user.token)
                  .then(function(cartItemsListResponse) {
                    if (
                      (cartItemsListResponse.data.code === CONST_HELPER.API_RESPONSE.OK)
                      &&
                      cartItemsListResponse.data.cart_details
                      &&
                      (cartItemsListResponse.data.cart_details.user_id !== undefined)
                      &&
                      (cartItemsListResponse.data.cart_details.is_booked !== undefined)
                      &&
                      (cartItemsListResponse.data.cart_details.is_booked === CONST_HELPER.ZERO)
                    ) {
                      saveFlywirePaymentCheckout(cartItemsListResponse, checkoutResult)
                    } else {
                      $scope.showPopup('#serverErrModal');
                    }
                  });
              } else {
                if (
                  $scope.paymentDateDepositPayment.cartDetails
                  &&
                  ($scope.paymentDateDepositPayment.cartDetails.is_final_payment == 1)
                  &&
                  $scope.paymentDateDepositPayment.cartSlotDetails
                  &&
                  $scope.paymentDateDepositPayment.cartSlotDetails.length
                ) {
                  saveFlywirePaymentCheckout($scope.paymentDateDepositPayment, checkoutResult)
                }
              }
            } else {
              $scope.hideLoader();
            }
          })
          .catch(function(reason) {
            $scope.hideLoader();
            console.error('Flywire Service Checkout error: %O', reason);
            $scope.showPopup('#serverErrModal');
          });
      } else {
        $scope.showPopup('#serverErrModal');
      }
    };

    $scope.payWithPlaid = function () {
      plaidService.getPlaidLinkHandler().open();
    };

    $scope.cardTotal = -1;
    $scope.addPaymentMethod = false;
    $scope.cardsList = [];
    $scope.cardTobeDeleted = '';
    $scope.cardType = '';
    $scope.callback_url = null;
    $scope.alphaNumericPatternWithSpaces = /^[A-Za-z\d\s]+$/;
    $scope.patternWithSpecialCharAndSpace = /^\d*([a-zA-Z0-9_!$@%*.,/#&+-\d\s])+([A-z])+([a-zA-Z0-9_!$@%*.,/#&+-\d\s])*$/;
    $scope.cardMode = 'new';
    var token = $scope.user.token || $rootScope.user.token;
    $scope.getAllCountryName();// get all country names
    $scope.cardSelected = '';// initial card selected in payment list
    $scope.checked = 0;// intial cvv state
    $scope.setDiffCard = false;
    var paymentOptions = {};// payment options
    $scope.alreadySaved = {};
    $scope.paymentErr = false;// set payment err to false
    $scope.countryStateLabel = ''; // initialize state label blank;
    // var cvcInValid = false

    $scope.hasBankAccount = false;

    // if site is open in iframe then set Inital PaymentModels;
    if ($rootScope.siteInIFrame) {
      setInitalPaymentModels();
      $scope.setDiffCard = true;
    }
    $rootScope.$on('initialize-payment', function (event, operatorId) {
      setInitalPaymentModels();
      $scope.operatorManualPaymentId = operatorId;
      $scope.setDiffCard = true;
    });

    $rootScope.$on('plaid-payment', function (event, stripeBankAccountToken) {
      $scope.alreadySavedCardPayment(stripeBankAccountToken, 'plaid');
    });

    // if operator is doing manual bookings then set Inital PaymentModels;
    $scope.isManualBooking = $rootScope.currentState.name === 'operator-manual-booking';
    if ($scope.isManualBooking) {
      setInitalPaymentModels();
      $scope.setDiffCard = true;
    }

    $scope.changeTabType = function (type) {
      $scope.tabType = type;
    };
    $scope.paymentSource = '';
    $scope.showAddCard = function (type) { // add card screen
      $scope.cardMode = 'new';// add new card
      $scope.cardType = '';
      $scope.addPaymentMethod = true;
      $scope.cardTotal = -1;// set card total to negative
      $scope.changeTabType(type);
      setInitalPaymentModels();// set intial model values
      setExpirationDate();// set expiration dates dropdown
    };

    $scope.getSavedCards = function () { // get list of saved cards
      $scope.showLoader();
      $scope.addPaymentMethod = false;// hide save card page
      paymentService.getCardsList(token).then(function (response) {
        $scope.cardTotal = 0;// no card saved yet
        if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
          if (response.data.data.data.length > 0) { // if user has saved cards
            $scope.cardTotal = response.data.data.data.length;
            $scope.cardsList = response.data.data.data;
            $scope.defaultCard = response.data.default_card;
            $scope.cardSelected = $scope.defaultCard;// set card selected to default card
            setAddressField($scope.cardsList);
            setExpiryMonth($scope.cardsList);
          } else {
            if ($scope.hasBankAccount) {
              $scope.addPaymentMethod = true;
            }
            $scope.getAllCountryName();// get all country names
            setDifferentCardOption();// set different card option
          }
          $scope.countryStateLabel = '';
        }
        $scope.$parent.userWantToProceed = true;// show cards list
        $scope.hideLoader();
      }, function (err) { $scope.hideLoader(); $scope.showPopup('#serverErrModal'); });
    };

    function setExpirationDate () { // set expiration dates dropdown
      $scope.lastYear = $scope.currentYear + 20;
      $scope.years = [$scope.currentYear];
      var i = $scope.currentYear;
      while (i < $scope.lastYear) { // set year values upto 20 months
        i = 1 + i;
        $scope.years.push(i);
      }
    }
    $scope.currentYear = parseInt($filter('date')(new Date(), 'yyyy'));// 2014 like
    setExpirationDate();
    $scope.stripeCallback = function (code, result) {
      $scope.showLoader();// show loader
      $scope.validation.err = false;
      $scope.validation.errMessage = '';
      var form = typeof (this.$parent.cardSaveForm) === 'undefined' ? this.cardSaveForm : this.$parent.cardSaveForm;
      if (form.expMonth.$viewValue === '') {
        form.expMonth.$viewValue = $scope.mnth;
      }

      if (form.expYear.$viewValue === '') {
        form.expYear.$viewValue = $scope.yr;
      }

      validateCardDetails('new', form);
      if (result.error) {
        if (result.error.type === 'card_error' || result.error.type === 'invalid_request_error') {
          if (result.error.type === 'invalid_request_error') {
            var errmesaageSplit = result.error.message.split(':');
            if (errmesaageSplit[1]) {
              result.error.param = ((errmesaageSplit[1]).split('.')[0]).trim();
            }
          }
          switch (result.error.param) {
            case 'card': $scope.validation.number = true; break;
            case 'cvc': $scope.validation.cvc = true; break;
            case 'exp_month':$scope.validation.exp_month = true; break;
            case 'exp_year': $scope.validation.exp_year = true; break;
            default : $scope.validation.err = true; $scope.validation.errMessage = result.error.message; break;
          }
          $scope.hideLoader();// hide loader
        } else {
          $scope.validation.err = true; $scope.validation.errMessage = result.error.message;
          $scope.hideLoader();// hide loader
        }
        $scope.hideLoader();// hide loader
      } else if (code === 200) { // successful token generated call server api
        if ($rootScope.currentState.name === 'consumer-profile') { // if on account settings page
          $scope.setDiffCard = false;
        }
        if (!$scope.setDiffCard) { // if request is for saving the card
          var data = { id: { 'card_token': result.id }, token: token };
          paymentService.saveCardId(data).then(function (response) { // send card token
            $scope.hideLoader();// hide loader;
            if (response && response.data && response.data.code === CONST_HELPER.API_RESPONSE.OK) { // show success message
              $scope.$parent.$parent.successMsg = 'Your card details have been saved successfully.';
              $scope.showPopup('#saveDetailModal');
              $scope.countryStateLabel = ''; // reset the state label after card save;
            } else { // error in saving card
              $scope.validation.err = true;
              $scope.validation.exp_month = false; $scope.validation.exp_year = false;
              if (response && response.data) {
                $scope.validation.errMessage = response.data.message;
              } else {
                $scope.validation.errMessage = 'Some error occurred. Please try again.';
              }
            }
          }, function (error) { $scope.validation.exp_month = false; $scope.validation.exp_year = false; $scope.hideLoader(); $scope.showPopup('#serverErrModal'); });
        } else { // if request for direct payment from card
          $rootScope.checkoutDone = true;// to make payment available on timer
          paymentOptions.card_id = '';// set payment options
          paymentOptions.card_token = result.id;
          paymentOptions.paymentSource = 'card';
          if ($rootScope.currentState.name === 'manual-payment') { // make manual payment by card
            $scope.makeManualPayment(paymentOptions.card_token);
          } else {
            $scope.makePayment();// make final payment
          }
        }
      }
    };
    $scope.makeManualPayment = function (cardToken) {
      var data = {
        user_id: $scope.operatorManualPaymentId,
        card_token: cardToken,
        token: token
      };

      operatorManualPaymentService.makeManualPaymentByCard(data).then(function (res) {
        if (res.data && res.data.message === 'Success') {
          $scope.hideLoader();
          $scope.showPopup('#manual-payment-success');
        } else {
          $scope.paymentErr = true;
          $scope.paymentErrMessage = res.data.message;
          $scope.hideLoader();
        }
      });
    };
    $scope.setFormValues = function (key, key2, page) {
      var form = typeof (this.$parent.cardSaveForm) === 'undefined' ? this.cardSaveForm : this.$parent.cardSaveForm;
      if (key === 'addressCountry') {
        $scope[key] = form[key2].$modelValue ? form[key2].$modelValue.name : '';
      } else {
        $scope[key] = form[key2].$modelValue;
      }
      $scope.expiry = $scope.expMonth + '/' + $scope.expYear;
    };

    function setInitalPaymentModels () {
      $scope.currentYear = parseInt($filter('date')(new Date(), 'yyyy'));// 2014 like
      $scope.validation = {};
      $scope.name = '';// set initial scope value for cardholder name
      $scope.number = '';// set card number scope
      $scope.expMonth = '';// set exp month
      $scope.expYear = '';// set exp year
      $scope.expMonth1 = '';// set exp month
      $scope.expYear1 = '';// set exp year
      $scope.expiry = '';
      $scope.cvc = '';// set cvc code;
      $scope.addressLine1 = '';// set address_line1 code;
      $scope.addressLine2 = '';// set address_line2 code;
      $scope.addressCity = '';// set city code;
      $scope.addressState = '';// set state code;
      $scope.addressState1 = {};// set state1 code;
      $scope.addressCountry = '';// set country code;
      $scope.addressCountry1 = '';// set country1 code;
      $scope.addressZip = '';// set cvc code;
    }
    var getCards = $rootScope.$on('card-saved', function (event, response) { // new card is saved
      if ($rootScope.siteInIFrame) { // if site is in iframe do not call get save card Api...
        $scope.getAllCountryName();// get all country names
        setDifferentCardOption();// set different card option
        $scope.$parent.userWantToProceed = true;// show cards list
        $scope.hideLoader();
      } else {
        $scope.getSavedCards();// fetch card list
        $scope.getBankAccount();
        if ($rootScope.redirectPaymentTab) {
          $scope.changeTabType('bank');
        }
      }
    });
    $scope.$on('destroy', function () {
      getCards();
    });

    $scope.deleteCard = function (cardId) { // confirm card deletion
      $scope.cardTobeDeleted = cardId;// set id to be deleted
      $scope.showPopup('#deleteSavedCard');// show pop up of card deletion
    };

    $scope.deleteSavedCard = function () { // to delete saved card
      $scope.showLoader();
      var data = { id: { 'card_id': $scope.cardTobeDeleted }, token: token };
      paymentService.deleteSavedCard(data).then(function (response) { // send request server to delete card
        if (response.data.code === CONST_HELPER.API_RESPONSE.OK) { // if user has saved cards
          $scope.getSavedCards();// fetch new card list
        }
      }, function (err) {});
    };

    $scope.changePrimaryCard = function (cardId) { // set new primary card
      $scope.showLoader();
      var data = { id: { 'card_id': cardId }, token: token };
      paymentService.changePrimaryCard(data).then(function (response) { // send request server to delete card
        if (response.data.code === CONST_HELPER.API_RESPONSE.OK) { // if user has saved cards
          $scope.defaultCard = response.data.data;// set new default cars
          $scope.getSavedCards(); // fetch new card list to change the order
        }
        // $scope.hideLoader();
      }, function (err) { $scope.hideLoader(); $scope.showPopup('#serverErrModal'); });
    };

    $scope.checkCardBrand = function () { // find card brand
      var ccnumber = cardSaveForm.card.value;
      if (ccnumber.length >= 2) { // if at least 2 digits then test for card brands
        // test patterns for card brand
        if (/^(34)|^(37)/.test(ccnumber)) {
          $scope.cardType = 'American Express';
        }
        if (/^(62)|^(88)/.test(ccnumber)) {
          $scope.cardType = 'Union Pay';
        }
        if (/^30[0-5]/.test(ccnumber)) {
          $scope.cardType = 'Diners Club';
        }
        if (/^(2014)|^(2149)/.test(ccnumber)) {
          $scope.cardType = 'Diners Club';
        }
        if (/^36/.test(ccnumber)) {
          $scope.cardType = 'Diners Club';
        }
        if (/^(6011)|^(622(1(2[6-9]|[3-9][0-9])|[2-8][0-9]{2}|9([01][0-9]|2[0-5])))|^(64[4-9])|^65/.test(ccnumber)) {
          $scope.cardType = 'Discover';
        }
        if (/^35(2[89]|[3-8][0-9])/.test(ccnumber)) {
          $scope.cardType = 'JCB';
        }
        if (/^(6304)|^(6706)|^(6771)|^(6709)/.test(ccnumber)) {
          $scope.cardType = 'Laser';
        }
        if (/^(5018)|^(5020)|^(5038)|^(5893)|^(6304)|^(6759)|^(6761)|^(6762)|^(6763)|^(0604)/.test(ccnumber)) {
          $scope.cardType = 'Maestro';
        }
        if (/^5[1-5]/.test(ccnumber)) {
          $scope.cardType = 'MasterCard';
        }
        if (/^4/.test(ccnumber)) {
          $scope.cardType = 'Visa';
        }
        if (/^(4026)|^(417500)|^(4405)|^(4508)|^(4844)|^(4913)|^(4917)/.test(ccnumber)) {
          $scope.cardType = 'Visa';
        }
      } else {
        $scope.cardType = '';// set blank
      }
    };
    $scope.cardIdToBUpdated = '';
    $scope.address = '';
    $scope.updateCardDetails = function (cardId) { // update the existing crediat payment method
      var i = 0;
      while (i < $scope.cardsList.length) {
        if ($scope.cardsList[i].id === cardId) { // set the card details from card list
          $scope.showAddCard('credit-card');// show the add card screen
          $scope.cardMode = 'edit';
          $scope.validation = {};
          $scope.cardType = $scope.cardsList[i].brand;
          $scope.name = $scope.cardsList[i].name;// set initial scope value for cardholder name
          $scope.cardnumber = '**** **** **** ' + $scope.cardsList[i].last4;// set card number scope
          $scope.addressCountry = $scope.cardsList[i].address_country;
          $scope.addressCountry1 = {};
          $scope.addressCountry1.name = $scope.cardsList[i].address_country;
          var selectedCountry = $scope.allcountryName.find(function (country) {
            return country.name == $scope.addressCountry;
          });
          $scope.countryStateLabel = selectedCountry ? selectedCountry.country_label : 'NA';
          $scope.expMonth = ($scope.cardsList[i].exp_month).toString();// set exp month
          $scope.expYear = ($scope.cardsList[i].exp_year).toString();// set exp year
          $scope.expMonth1 = ($scope.cardsList[i].exp_month).toString();// set exp month
          $scope.expYear1 = ($scope.cardsList[i].exp_year).toString();// set exp year
          $scope.cardcvc = '***';// set cvc code;
          $scope.addressLine1 = $scope.cardsList[i].address_line1;// set address_line1 code;
          $scope.addressLine2 = ($scope.cardsList[i].address_line2).trim();// set address_line2 code;
          $scope.addressCity = $scope.cardsList[i].address_city;
          $scope.addressState = $scope.cardsList[i].address_state;
          $scope.addressState1.selected = $scope.cardsList[i].address_state;
          $scope.addressZip = $scope.cardsList[i].address_zip;
          $scope.cardIdToBUpdated = $scope.cardsList[i].id;
          return false;
        }
        i = i + 1;
      }
    };

    $rootScope.$on('delete-card', function (event, response) { // broadcast to delete saved card
      $scope.deleteSavedCard();// delete saved card
    });

    $scope.saveUpdatedCard = function () { // save updated card details
      $scope.showLoader();
      var form = this.$parent.cardSaveForm;
      if (validateCardDetails('edit', form)) {
        var cardDetails = {// set updated card details
          'id': $scope.cardIdToBUpdated,
          'name': cardSaveForm.name.value,
          'expMonth': $scope.expMonth,
          'expYear': $scope.expYear,
        };

        if (!$scope.isManualBooking) {
          cardDetails.addressLine1 = cardSaveForm.addressLine1.value;
          cardDetails.addressLine2 = cardSaveForm.addressLine2.value;
          cardDetails.addressCity = cardSaveForm.addressCity.value;
          cardDetails.addressState = $scope.addressState;
          cardDetails.addressCountry = $scope.addressCountry;
          cardDetails.addressZip = cardSaveForm.addressZip.value;
        }

        var data = { id: cardDetails, token: token };// set parameter for request
        paymentService.updateCardDetails(data).then(function (response) { // send new card details
          $scope.hideLoader();
          if (response.data.code === CONST_HELPER.API_RESPONSE.OK) { // card updated successfully
            $scope.$parent.$parent.successMsg = 'Your card details have been updated successfully.';
            $scope.showPopup('#saveDetailModal');
          } else { // some problem with card saving
            $scope.validation.err = true; $scope.validation.errMessage = response.data.message;
          }
        }, function (err) { $scope.hideLoader(); $scope.showPopup('#serverErrModal'); });
      } else {
        $scope.hideLoader();
      }
    };

    function validateCardDetails (from, form) {
      $scope.expiry = $scope.expMonth + '/' + $scope.expYear;

      if (from === 'new') { // check only if new card is being added
        if ((cardSaveForm.card.value.trim() === '') || cardSaveForm.card.$invalid) { // check for description
          $scope.validation.number = true;
        } else {
          $scope.validation.number = false;
        }
        if (cardSaveForm.cvc.value.trim() === '') { // check for cvc
          $scope.validation.cvc = true;
        } else {
          $scope.validation.cvc = false;
        }
      }
      if ((cardSaveForm.name.value.trim() === '') || cardSaveForm.name.$invalid) { // check for description
        $scope.validation.name = true;
      } else {
        $scope.validation.name = false;
      }
      if ($scope.expMonth === '') { // check for expiration month selection
        $scope.validation.exp_month = true;
      } else {
        $scope.validation.exp_month = false;
      }
      if ($scope.expYear === '') { // check for expiration year selection
        $scope.validation.exp_year = true;
      } else {
        $scope.validation.exp_year = false;
      }

      if (!$scope.isManualBooking) {
        if (cardSaveForm.addressLine1.value.trim() === '') { // check for address_line1
          $scope.validation.address_line1 = true;
        } else {
          $scope.validation.address_line1 = false;
        }
        if ($scope.addressState === '') { // check for address_state
          $scope.validation.address_state = true;
        } else {
          $scope.validation.address_state = false;
        }
        if (cardSaveForm.addressCity.value.trim() === '') { // check for address_city
          $scope.validation.address_city = true;
        } else {
          $scope.validation.address_city = false;
        }
        if (cardSaveForm.addressZip.value.trim() === '') { // check for address_zip
          $scope.validation.address_zip = true;
        } else {
          $scope.validation.address_zip = false;
        }
        if ($scope.addressCountry === '') { // check for address_country
          $scope.validation.address_country = true;
        } else {
          $scope.validation.address_country = false;
        }
      }

      if (form.$invalid) {
        $scope.mnth = cardSaveForm.expMonth.value !== '' ? (cardSaveForm.expMonth.value).toString() : '';// set exp month
        $scope.yr = cardSaveForm.expYear.value !== '' ? (cardSaveForm.expYear.value).toString() : '';// set exp year
      }
      if (from === 'edit') {
        for (var key in $scope.validation) {
          if ($scope.validation[key] === true) {
            return false;
          }
        }
        if (form.$invalid) {
          return false;
        }
        return true;
      }
    }

    function setExpiryMonth (list) { // set expiry month with prefix 0
      var i = 0;
      while (i < list.length) {
        if (list[i].exp_month < 10) { // if month less than 10 then prefix with zero
          list[i].exp_month = '0' + list[i].exp_month;
        }
        i = i + 1;
      }
    }

    function setAddressField (list) { // set address param
      var i = 0;
      while (i < list.length) {
        if (
          list[i].address_line2
          &&
          (list[i].address_line2).trim() !== ''
          &&
          (list[i].address_state).trim() != ''
        ) {
          list[i].address = list[i].address_line1 + ', ' + list[i].address_line2 + ', ' + list[i].address_city + ', ' + list[i].address_state + ', ' + list[i].address_zip + ', ' + list[i].address_country;
        } else if (
          list[i].address_state
          &&
          (list[i].address_state).trim() == ''
          &&
          (list[i].address_line2).trim() != ''
        ) {
          list[i].address = list[i].address_line1 + ', ' + list[i].address_line2 + ', ' + list[i].address_city + ', ' + list[i].address_zip + ', ' + list[i].address_country;
        } else {
          list[i].address = list[i].address_line1 + ', ' + list[i].address_city + ', ' + list[i].address_state + ', ' + list[i].address_zip + ', ' + list[i].address_country;
        }
        i = i + 1;
      }
    }

    $scope.changeCardSelection = function (cardSelected, id, index) {
      if (cardSelected === 'new') {
        $scope.getAllCountryName();// get all country names
        setDifferentCardOption();// set different card option
      }
      $scope.checked = index;
    };

    function setDifferentCardOption () { // set different card option
      $scope.cardType = '';
      $scope.setDiffCard = true;// set different card option for payment

      setInitalPaymentModels();// set intial model values
      setExpirationDate();// set expiration dates dropdown
    }

    $scope.alreadySavedCardPayment = function (cardId, paymentSource) { // params for already saved card
      $scope.paymentSource = paymentSource;
      $rootScope.checkoutDone = true;// set timer consition on payment
      paymentOptions.card_id = cardId;// set payment options
      paymentOptions.card_token = '';
      paymentOptions.paymentSource = paymentSource;
      if (paymentSource != 'plaid') {
        $scope.showLoader();
        $scope.makePayment();// make final payment
      } else {
        $scope.showPlaidPayButtonWithPrice = true;
      }
    };

    $scope.makePayment = function () { // make final payment
      $scope.showLoader();

      // getCurrencyUsdRate($scope.operatorAdventureCurrencyCode).then(function () {
        paymentOptions.amount = Number(($scope.$parent.GrandTotal).toFixed(2));
        if ($scope.isManualBooking) {
          paymentOptions.total_processing_fee_amount = Number(($scope.$parent.manualBooking.processingFee).toFixed(2));
          paymentOptions.total_tax_amount = Number(($scope.$parent.manualBooking.taxes).toFixed(2));
        } else {
          paymentOptions.total_processing_fee_amount = Number(($scope.$parent.processingFee).toFixed(2));
          paymentOptions.total_tax_amount = Number(($scope.$parent.taxAmount).toFixed(2));
        }

        var full_discount = paymentOptions.amount === 0 ? 1 : '';
        paymentOptions.order_id = $scope.$parent.order_id;
        // set booking source
        if ($rootScope.siteInIFrame) {
          paymentOptions.booking_source = CONST_HELPER.OPERATOR_SITE;
        } else if ($scope.isManualBooking) {
          paymentOptions.booking_source = CONST_HELPER.MANUAL_PAID;
        } else {
          paymentOptions.booking_source = '';
        }
        if ($rootScope.isAgent) {
          paymentOptions.agent_booking_source = CONST_HELPER.ONE;
        }
        if (!$rootScope.guests_details && $cookies.get('guests_details')) {
          $rootScope.guests_details = JSON.parse($cookies.get('guests_details'));
        }
        paymentOptions.booking_site = $rootScope.siteInIFrame ? (document.referrer != '' ? document.referrer : (window.location.href ? window.location.href : 'thegreatamericanskilodge.com')) : '';
        paymentOptions.iframe_site = $rootScope.siteInIFrame && !$rootScope.isSubDomain ? CONST_HELPER.ONE : CONST_HELPER.ZERO;
        paymentOptions.cart_id = $cookies.get('cart_id') || String($rootScope.cart_id);
        paymentOptions.slot_data = $rootScope.CartDetails;
        // if ($scope.$parent.processingFee && ($rootScope.siteInIFrame || $rootScope.isSubDomain)) {
        if ($scope.$parent.processingFee) {
          paymentOptions.slot_data[0].processing_fee = $scope.$parent.processingFee / $rootScope.usdRate;
          paymentOptions.slot_data[0].local_processing_fee = $scope.$parent.processingFee;
          paymentOptions.slot_data[0].total_processing_fee = $scope.$parent.processingFee / $rootScope.usdRate;
          paymentOptions.slot_data[0].local_total_processing_fee = $scope.$parent.processingFee;
        }
        // Check for setting important fields
        if (isNaN(paymentOptions.slot_data[0].local_tax_amount)) {
          paymentOptions.slot_data[0].local_tax_amount = $scope.$parent.taxAmount;
        }
        if (isNaN(paymentOptions.slot_data[0].tax_amount)) {
          paymentOptions.slot_data[0].tax_amount = $scope.$parent.taxAmount / $rootScope.usdRate;
        }
        if (isNaN(paymentOptions.slot_data[0].local_processing_fee)) {
          paymentOptions.slot_data[0].local_processing_fee = $scope.$parent.processingFee;
        }
        if (isNaN(paymentOptions.slot_data[0].processing_fee)) {
          paymentOptions.slot_data[0].processing_fee = $scope.$parent.processingFee / $rootScope.usdRate;
        }
        if (isNaN(paymentOptions.slot_data[0].total_processing_fee)) {
          paymentOptions.slot_data[0].total_processing_fee = paymentOptions.slot_data[0].processing_fee;
        }
        if ($rootScope.referral_id) {
          paymentOptions.slot_data[0].referral_id = $rootScope.referral_id;
        }

        paymentOptions.local_currency_code = $rootScope.selectedCurrencyCode;
        paymentOptions.guests_details = $rootScope.guests_details ? $rootScope.guests_details : $cookies.get('guests_details');
        var card_token_id = angular.copy(paymentOptions.card_token); var card_id = angular.copy(paymentOptions.card_id);
        paymentOptions.has_single_operator = $scope.$parent.hasSingleOperator;
        paymentOptions.final_connect_amount = $scope.$parent.hasSingleOperator ? Number(($scope.$parent.finalOperatorAmount).toFixed(2)) : 0;
        paymentOptions.final_connect_amount_operator = $scope.$parent.hasSingleOperator && $scope.$parent.finalConnectOperatorAmount ? Number(($scope.$parent.finalConnectOperatorAmount).toFixed(2)) : 0; // final connect amount in operator currency...
        // for operator manualbookings...
        paymentOptions.user_name = $scope.$parent.manualGuest && $scope.$parent.manualGuest.name ? $scope.$parent.manualGuest.name : '';
        paymentOptions.user_email = $scope.$parent.manualGuest && $scope.$parent.manualGuest.email ? $scope.$parent.manualGuest.email : '';
        paymentOptions.user_notes = $scope.$parent.manualGuest && $scope.$parent.manualGuest.notes ? $scope.$parent.manualGuest.notes : '';
        $scope.$parent.creditsAmount && (paymentOptions.credits_amount = $scope.$parent.creditsAmount);
        delete paymentOptions.card_token;
        delete paymentOptions.card_id;
        var data = { id: paymentOptions, token: token, card_token: card_token_id, card_id: card_id, full_discount: full_discount };// set payment options

        function initializePaymentMethod(paymentData) {
          return new Promise(function (resolve, reject) {
            if (paymentData.id.paymentSource === 'card') {
              // Initialize Stripe Payment Intent
              var paymentIntentConfig = {
                token: token,
                cart_id: paymentOptions.cart_id
              };

              // Manual booking case - we should provide customer email manually
              if (paymentData.id.booking_source == CONST_HELPER.MANUAL_PAID) {
                paymentIntentConfig.user_email = paymentData.id.user_email;

                paymentIntentConfig.amount = paymentData.id.amount;
                paymentIntentConfig.final_connect_amount_operator = paymentData.id.final_connect_amount_operator;
                paymentIntentConfig.local_currency_code = paymentData.id.local_currency_code;
              }

              if (paymentData.card_id) {
                paymentIntentConfig.card_id = paymentData.card_id;
              } else if (paymentData.card_token) {
                paymentIntentConfig.card_token = paymentData.card_token;
              }

              paymentService.getPaymentIntent(paymentData)
                .then(
                  function (response) {
                    if (response.data && response.data.stripe_payment_intent) {
                      let GTdata = {
                        currency: 'USD',
                        transaction_id: response.data.google_analytics.transaction_id,
                        value: response.data.google_analytics.usd_amount,
                        tax: $scope.$parent.taxAmount,
                        items: [
                          {
                            item_id: response.data.google_analytics.booking_id,
                            item_name: response.data.google_analytics.package_name,
                            affiliation: response.data.google_analytics.operator_name,
                            price: response.data.google_analytics.usd_amount,
                            quantity: 1
                          }
                        ]
                      };
                      $rootScope.$broadcast('googleTagManagerPurchase', GTdata);

                      resolve(response.data.stripe_payment_intent);
                    } else {
                      reject(new Error('Payment Intent was not created'));
                    }
                  }, function (error) {
                    reject(new Error('Payment Intent was not created'));
                  }
                );
            } else {
              // Don't needed to initialize
              resolve();
            }
          });
        }

        var initializePaymentMethodPromise = initializePaymentMethod(data);
        initializePaymentMethodPromise
          .then(
            function(stripePaymentIntent) {
              return new Promise(function(resolve, reject) {
                if (stripePaymentIntent) {
                  // Stripe initialization
                  if (typeof window.stripe === 'undefined') {
                    scriptLoaderService
                      .load('https://js.stripe.com/v2/', true)
                      .then(function() {
                        window.Stripe.setPublishableKey(STRIPE_KEY);

                        console.log('Initialize Stripe modules');
                        var existingModule = angular.module('heliApp');
                        existingModule.requires.push('angular-stripe');

                        console.log('Sets stripeScriptInitialized flag to true');
                        $scope.stripeScriptInitialized = true;
                        $rootScope.$broadcast('stripe-loaded');

                        scriptLoaderService
                          .load('https://js.stripe.com/v3/', true)
                          .then(function () {
                            console.log('Stripe V3 version initialization');
                            // Storing stripe object to global context for accessing it
                            // outside of this callback
                            window.stripe = Stripe(STRIPE_KEY, { locale: 'en' });

                            window.stripe
                              .confirmCardPayment(stripePaymentIntent.client_secret)
                              .then(function (result) {
                                resolve(result);
                              })
                          })
                          .catch(function () {
                            $scope.showPopup('#serverErrModal');
                          });
                      })
                      .catch(function () {
                        $scope.showPopup('#serverErrModal');
                      });
                  } else {
                    window.stripe
                      .confirmCardPayment(stripePaymentIntent.client_secret)
                      .then(function (result) {
                        resolve(result);
                      })
                  }
                } else {
                  resolve();
                }
              })
            }, function (error) {
              console.log('error', error);
              $scope.hideLoader();
              $scope.showPopup('#serverErrModal');
            }
          )
          .then(
            function (confirmationResult) {
              return new Promise(function(resolve, reject) {
                if (confirmationResult) {
                  // Updating Payment Intent in DB
                  var stripePaymentIntentForUpdate = confirmationResult.error
                    ? confirmationResult.error.payment_intent
                    : confirmationResult.paymentIntent;

                  paymentService
                    .updatePaymentIntent({
                      id: stripePaymentIntentForUpdate.id,
                      token: token
                    })
                    .then(
                      function (response) {
                        if (confirmationResult.error) {
                          $scope.hideLoader();
                          $scope.$parent.stripeConfirmCardPaymentErrorModalData = confirmationResult.error;
                          $scope.showPopup('#stripeConfirmCardPaymentErrorModal');
                        }

                        resolve(confirmationResult);
                      }, function () {
                        reject(new Error('Payment Intent was not updated'));
                      }
                    );
                } else {
                  resolve();
                }
              })
            }, function (error) {
              $scope.hideLoader();
              $scope.$parent.stripeConfirmCardPaymentErrorModalData = error;
              $scope.showPopup('#stripeConfirmCardPaymentErrorModal');
            }
          )
          .then(
            function (confirmationResult) {
              if (!confirmationResult || confirmationResult.paymentIntent) {
                var paymentPromise = null;
                if (confirmationResult.paymentIntent) {
                  var convertPaymentIntentToPaymentConfig = {
                    id: confirmationResult.paymentIntent.id,
                    data: data,
                    token: token
                  };

                  paymentPromise = paymentService.convertPaymentIntentToPayment(convertPaymentIntentToPaymentConfig);
                } else {
                  paymentPromise = paymentService.makePayment(data);
                }

                paymentPromise
                  .then(function (response) { // call payment api
                    $scope.hideLoader();// hide loader
                    if (response && !response.data) {
                      $scope.makePayment();
                    }
                    if (response && response.data && response.data.code === CONST_HELPER.API_RESPONSE.OK) {
                      // if operator is doing manual bookings then set Inital PaymentModels;
                      if ($rootScope.currentState.name === 'operator-manual-booking') {
                        // call API to link guest to guest-form urls
                        var reqObj = { 'id': response.data.guest_details, 'token': token };
                        $cookies.put('guestDetails', JSON.stringify(reqObj)); // set guest details in cookies
                        paymentService.sendGuestForms(reqObj).then(function () {
                          $scope.hideLoader();
                        });// send the guest forms to users
                        $scope.showPopup('#paymentConfirmation');
                        // Enhance Ecommerce data
                        // google analytics code when a Order is successfull add ordersuccessfull event in Enhanced ecommerce...
                        sendGoogleAnalyticsData(response);
                        return;
                      }

                      $scope.paymentErr = false;
                      response.data.order_details.ord_date = Date.parse(response.data.order_details.ord_date);
                      $cookies.put('orderDetails', JSON.stringify(response.data.order_details));// set local storage again
                      $rootScope.orderDetails = response.data.order_details;
                      var req = { id: { 'cart_id': paymentOptions.cart_id, 'order_number': response.data.order_details.order_number }, token: token };// set payment options
                      paymentService.sendEmailToOperators(req);// send operators notification of successful payment

                      $rootScope.myCartLength = 0;// reset cart list
                      rootScopeService.setValue("myCartLength", $rootScope.myCartLength);
                      if ($stateParams.order_id) { // full payment on second go
                        $scope.hideLoader();
                        $scope.callback_url = response.data.callback_url;
                        if ($rootScope.guestUserRemainingPayment) {
                          $scope.showPopup('#packageBookedSubDomain');
                        } else {
                          $state.go('user-orders');
                        }
                      } else { // initial payment or full payment on first go
                        // call API to link guest to guest-form urls
                        var reqObj = { 'id': response.data.guest_details, 'token': token };
                        $cookies.put('guestDetails', JSON.stringify(reqObj)); // set guest details in cookies

                        // if order is done on operator subdomain redirect to callback url.
                        if ($rootScope.isSubDomain) {
                          paymentService.sendGuestForms(reqObj).then(function () {
                            $scope.callback_url = response.data.callback_url;
                            if ($scope.callback_url) {
                              // document.location = callback_url;
                              // TODO: ADD POPUP
                              $scope.showPopup('#packageBookedSubDomain');
                              $scope.hideLoader();
                            } else {
                              $state.go('order-successful');
                              $scope.hideLoader();
                            }
                          });// send the guest forms to users
                        } else {
                          $state.go('order-successful');
                          $scope.hideLoader();
                        }

                        // if white label booking then do not send data to google analytics
                        if (!$rootScope.siteInIFrame) {
                          // Enhance Ecommerce data
                          // google analytics code when a Order is successfull add ordersuccessfull event in Enhanced ecommerce...
                          sendGoogleAnalyticsData(response);
                        }
                      }
                    } else { // some error occurred
                      $scope.paymentErr = true;
                      setInitalPaymentModels();if (response && response.data) {
                        $scope.paymentErrMessage = response.data.message;
                      } else {
                        $scope.paymentErrMessage = 'Some error occurred. Please try again.';
                      }
                      $rootScope.checkoutDone = false; // set timer consition on payment
                      $scope.hideLoader();
                    }
                  }, function (err) {
                    $scope.hideLoader();
                    $scope.showPopup('#serverErrModal');
                  });
              }
            }, function () {
              $scope.hideLoader();
              $scope.showPopup('#serverErrModal');
            }
          );
    };

    function sendGoogleAnalyticsData (response) {
      // Enhance Ecommerce data
      // google analytics code when a Order is successfull add ordersuccessfull event in Enhanced ecommerce...
      var GCdata = {
        items: $scope.$parent.myCartItems.cart_slot_details,
        actionField: { 'step': 4, 'option': 'Order Successfull' }, // optional
        event_type: 'orderSuccessfull',
        event: 'gtm.heli.transaction.completed',
        currencyCode: $rootScope.operatorAdventureCurrencyCode
      };
      $rootScope.$broadcast('addEventToEC', GCdata); // add event to google Analytics.

      // Google analytics Add a transaction

      var ecTransationData = {
        items: $scope.$parent.myCartItems.cart_slot_details,
        event_type: 'addTransaction',
        order_detail: {
          'order_id': response.data.order_details.order_number,
          'total_tax': $scope.$parent.taxAmount,
          'total_processing_fee': $scope.$parent.processingFee,
          'grandTotal': $scope.$parent.GrandTotal
        }
      };
      $rootScope.$broadcast('addEventToEC', ecTransationData); // add transaction to google Analytics Enhanced Ecommerce.
    }

    $scope.redirectToCallback = function () {
      $scope.hidePopup('#packageBookedSubDomain');
      if ($scope.callback_url) {
        window.location.href = $scope.callback_url;
      }
    };

    // Change card processing fee event is triggered when consumer switch tabs during payment
    $scope.changeCardProcessingFee = function (type) {
      $scope.$emit('change-cardFee', type);
    };

    $scope.checkValidCVC = function (invalid) {
      cvcInValid = invalid;// set cvc at checkout valid status
    };

    // country - state dependency implementation.

    $scope.changeCountry = function (country_id, country) {
      if (country_id) {
        $scope.countryStateLabel = country.country_label;
        if (!$scope.validation) {
          $scope.validation = {};
        }
        $scope.validation.address_country = false;
        if (!$scope.addressState1) {
          $scope.addressState1 = {};
        }
        $scope.addressState1.selected = '';
        $scope.addressState = '';
        // $('#country').parent().removeClass('ng-invalid');
        if ((typeof (country_id) !== 'undefined') && country_id !== null) { // cehck fro undefined values
          getStates(country_id);
        }
      }
    };
    function getStates (value) { // get all states with reference to country id
      // $scope.showLoader();
      operatorProfileService.getStates(value).then(function (response) {
        if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
          $scope.states = response.data.data;
        }
      }, function (error) { $scope.hideLoader(); });
    }

    /* ============================Bank Account ======================================= */
    // Initialize Bank account add variables
    $scope.showBankAccount = function () {
      $scope.showAddCard('bank');
      setInitialBankAccountModels();
    };

    $scope.accountHolderType = ['individual', 'company'];
    // Initialize Bank account add variables
    function setInitialBankAccountModels () {
      $scope.bankValidation = {};// Hold errors returned by stripe
      $scope.account_holder_name = '';
      $scope.account_holder_type = '';
      $scope.routing_number = '';
      $scope.account_number = '';
      $scope.account_terms = false;// Check for user accept terms and conditions for ACH
      $scope.formInvalid = false;
    }

    $scope.clearBankAccountForm = function (){
      document.querySelector('#bankAccountTypeSelect .btn-link').click()
      document.getElementById('accountSaveForm').reset();
    }

    $scope.agreedTerms = function () {
      $scope.account_terms = !$scope.account_terms;
    };
    $scope.getBankAccount = function () {
      $scope.showLoader();
      paymentService.getBankAccountDetails(token).then(function (response) {
        if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
          $scope.accountObject = response.data.data;
          $scope.is_send_microdeposit = response.data.is_send_microdeposit;
          if ($scope.accountObject.status === 'new' && !response.data.is_send_microdeposit) { // bank is new and micro deposit send false
            $scope.accountObject.bank_status = 'un-verified';
          } else if ($scope.accountObject.status === 'verification_failed') {
            $scope.accountObject.bank_status = 'verification failed';
          } else if ($scope.accountObject.status === 'errored') {
            $scope.accountObject.bank_status = 'error';
          } else if ($scope.accountObject.status !== 'verified' && response.data.is_send_microdeposit) {
            $scope.accountObject.bank_status = 'pending';
          } else {
            $scope.accountObject.bank_status = $scope.accountObject.status;
          }
          $scope.hasBankAccount = true;
        } else {
          $scope.hasBankAccount = false;
          setInitialBankAccountModels();
        }
        // if ($scope.$parent.userWantToProceed) {
        //   $scope.hideLoader();
        // }
      }, function (err) { console.log(err); $scope.showPopup('#serverErrModal'); });
    };

    // Check whether the stripe generates token for the account details
    function validateBankAccount (result) {
      if (result.error) {
        if (result.error.type === 'invalid_request_error') {
          switch (result.error.code) {
            case 'routing_number_invalid':
              $scope.bankValidation.routing_number = true;
              break;
            case 'account_number_invalid':
              $scope.bankValidation.account_number = true;
              break;
            case 'account_holder_name_invalid':
              $scope.bankValidation.account_holder_name = true;
              break;
            case 'account_holder_type_invalid':
              $scope.bankValidation.account_holder_type = true;
              break;
            default :
              $scope.bankValidation.err = true;
              $scope.bankValidation.errMessage = result.error.message;
              break;
          }
        } else {
          $scope.bankValidation.err = true;
          $scope.bankValidation.errMessage = result.error.message;
        }
        $scope.hideLoader();// hide loader
      } else {
        saveBankAccount(result);
      }
    }
    // save the bank account details for the consumer
    function saveBankAccount (result) {
      $scope.changeTabType('bank');
      var data = { bank_token: result.token.id, token: token };
      paymentService.saveBankAccountDetails(data).then(function (response) {
        if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
          $scope.hideLoader();
          $scope.getBankAccount();
          $scope.$parent.$parent.successMsg = 'Your bank account details have been saved successfully.';
          $scope.showPopup('#saveDetailModal');
        } else {
          $scope.bankValidation.save = true;
          if (response && response.data) {
            $scope.bankValidation.error = response.data.message;
          } else {
            $scope.bankValidation.error = 'Some error occurred. Please try again.';
          }
          $scope.hideLoader();
        }
      }, function (err) { console.log(err); $scope.showPopup('#serverErrModal'); });
    }

    $scope.addMicroDeposit = function () {
      $scope.$parent.first_deposite = '';// micro deposit for ACH Verification
      $scope.$parent.second_deposite = '';
      $scope.$parent.depositInvalid = false;
      $scope.showPopup('#microDepositModal');
    };

    // Check micro deposit form input for null or spaces
    function validateMicroDepositForm (form) {
      if (form.first_deposite.$viewValue.trim() === '' || form.first_deposite.$viewValue === undefined || !form.first_deposite.$modelValue) {
        form.first_deposite.$error.required = true;
      } else {
        form.first_deposite.$error.required = false;
      }

      if (form.second_deposite.$viewValue.trim() === '' || form.second_deposite.$viewValue === undefined || !form.second_deposite.$modelValue) {
        form.second_deposite.$error.required = true;
      } else {
        form.second_deposite.$error.required = false;
      }
    }

    // Add micro deposit payments from consumer to verify account details
    $scope.$parent.verifyBankAccount = function (microDepositForm) {
      $scope.changeTabType('bank');
      if (!microDepositForm.$valid) {
        $scope.$parent.depositInvalid = true;
        validateMicroDepositForm(microDepositForm);
        return;
      }
      if (!microDepositForm.first_deposite.$modelValue) {
        $scope.$parent.depositInvalid = true;
        microDepositForm.first_deposite.$error.required = true;
        return;
      }
      if (!microDepositForm.second_deposite.$modelValue) {
        $scope.$parent.depositInvalid = true;
        microDepositForm.second_deposite.$error.required = true;
        return;
      }
      $scope.showLoader();
      var data = { id: { first_deposite: $scope.$parent.first_deposite, second_deposite: $scope.$parent.second_deposite }, token: token };
      paymentService.verifyBankAccountDetails(data).then(function (response) {
        if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
          $scope.hidePopup('#microDepositModal');
          $scope.getBankAccount();
        } else {
          $scope.bankValidation.verify = true;
          if (response && response.data) {
            $scope.bankValidation.errMessage = response.data.message;
          } else {
            $scope.bankValidation.errMessage = 'Some error occurred. Please try again.';
          }
          $scope.hideLoader();
        }
      }, function (err) { console.log(err); $scope.showPopup('#serverErrModal'); $scope.hidePopup('#microDepositModal'); $scope.hideLoader(); });
    };
    $scope.confirmDeleteBankAccount = function () {
      $scope.showPopup('#deleteBankAccountModal');
    };
    $scope.$parent.deleteBankAccount = function () {
      $scope.showLoader();
      var data = { token: token };
      paymentService.deleteBankAccountDetails(data).then(function (response) {
        $scope.changeTabType('bank');
        if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
          $scope.getBankAccount();
          $scope.getSavedCards();// fetch card list
        } else {
          $scope.hideLoader();
        }
      }, function (err) { console.log(err); $scope.showPopup('#serverErrModal'); });
    };
    var stripe;
    $rootScope.$on('stripe-loaded', function () {
      stripe = Stripe(STRIPE_KEY);
    });

    $scope.addBankAccount = function (addBankForm) {
      if (!addBankForm.$valid) {
        $scope.formInvalid = true;
        return;
      }
      $scope.showLoader();
      // setBankAccountModels();
      stripe.createToken('bank_account', {
        country: 'US',
        currency: 'usd',
        routing_number: addBankForm.routing_number.$viewValue,
        account_number: addBankForm.account_number.$viewValue,
        account_holder_name: addBankForm.account_holder_name.$viewValue,
        account_holder_type: addBankForm.account_holder_type.$viewValue
      }).then(function (result) {
        validateBankAccount(result);
      });
    };
    //    Redirect to Add Bank Account from Payment page
    $scope.redirectToAddBank = function () {
      $rootScope.redirectPaymentTab = true;
      $location.url('/user-profile');
      $anchorScroll();
    };

    $scope.getFlyWireCountry = function () {
      /* create funtion for get country for checking flywire */
      paymentService.getFlyWireCountry().then(function (data) {
        /* Call api for operator signup */
        if (data.data.code === CONST_HELPER.API_RESPONSE.OK) {
          $scope.flyWireCountry = data.data.data;
          var selectedCountry = null;
          if ($rootScope.selectedCurrencyCode && $scope.isFlywirePaymentAvailable) {
            // Try to pre-select country by selected currency
            selectedCountry = $scope.flyWireCountry.find(function (country) {
              return country.default_currency === $rootScope.selectedCurrencyCode;
            });
          }

          if (!selectedCountry) {
            selectedCountry = $scope.flyWireCountry.find(function (country) {
              return country.name === 'United States'; // set USA default value for select, search by name country
            });
          }

          $scope.selectCountryTransfer.selected = selectedCountry;

          // Update payment systems availability flags
          $scope.$broadcast('userPayment::updateAvailabilityFlags');
        }
      });
    };
    $scope.getFlyWireCountry();
    $scope.selectCountryTransfer = function() {
      $rootScope.selectCountryTransfer = $scope.selectCountryTransfer.selected;
      $rootScope.changeBankFee();

      // Update payment systems availability flags
      $scope.$broadcast('userPayment::updateAvailabilityFlags');
    };

    $scope.paymentSystems = {
      flywire: {
        isAvailable: false
      },
      plaid: {
        isAvailable: false
      },
      updateAvailabilityFlags: function () {
        var isCountrySelected = $scope.selectCountryTransfer && $scope.selectCountryTransfer.selected;
        var countryIdUSA = 231;

        $scope.paymentSystems.flywire.isAvailable =
          // Is Flywire account setted
          $scope.isFlywirePaymentAvailable
          &&
          isCountrySelected
          &&
          (
            // Any country in that Flywire available
            $scope.selectCountryTransfer.selected.flywire == 1
            ||
            (
              // Selected country - is USA
              $scope.selectCountryTransfer.selected.id == countryIdUSA
              &&
              // Plaid unavailable
              !$scope.isPlaidPaymentAvailable
            )
          );

        $scope.paymentSystems.plaid.isAvailable =
          $scope.isPlaidPaymentAvailable
          &&
          isCountrySelected
          &&
          $scope.selectCountryTransfer.selected.id == countryIdUSA;

          $rootScope.paymentSystemsFlywireIsAvailable = $scope.paymentSystems.flywire.isAvailable;
          $rootScope.paymentSystemsPlaidIsAvailable = $scope.paymentSystems.plaid.isAvailable;
      }
    };

    $scope.$on('userPayment::updateAvailabilityFlags', function () {
      $scope.paymentSystems.updateAvailabilityFlags();
    });
  }]);
