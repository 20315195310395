import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name UserProfileService
 * @description
 * # userProfileService
 * Service in the heliApp.
 */

@Injectable()

export class UserProfileService {

  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  sendReq(method) {
    return new Promise((resolve, reject) => {
      method.then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }
  getReq(data, url) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + url,
      headers: {
        'token': data.token
      }
    };
    return this.webService.httpReq(req);
  }

  getConsumerProfile(data) { // service to get consumer profile
    return this.sendReq(this.webService.getConsumerProfile(data));
  }
  addBasicConsumerDetails(data, params) { // service to save user details
    return this.sendReq(this.webService.addBasicConsumerDetails(data, params));
  }
  addPrivacy(data, params) { // service to add privacy features
    return this.sendReq(this.webService.addPrivacy(data, params));
  }
  changePassword(data, params) { // service to add privacy features
    return this.sendReq(this.webService.changePassword(data, params));
  }
  becomeAmbassador(params) { // service to become ambassador
    return this.sendReq(this.webService.becomeAmbassador(params));
  }
  // service to fetch ambassador credits
  getTotalCredits(userType, data) {
    let section = 'influencers'
    if(userType == 2) {
      section = 'consumers';
    }

    let req = {
      method: 'GET',
      url: this.REQUEST_URL + section +'/getCreditReferralDetails?limit=' + data.limit + '&page=' + data.page,
      headers: {
        'token': data.token,
        'Content-Type': 'application/json'
      }
    };
    return this.webService.httpReq(req);
  }
  getAnalyticsResult(data) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + 'influencers/dashboard?fr=' + data.fromDate + '&to=' + data.toDate,
      headers: {
        'token': data.token
      }
    };
    return this.webService.httpReq(req);
  }
  /**
  * @ngdoc method
  * @name getTrafficAnalytics
  *
  * @description
  * This method call API to get public feeds
  **/
  getTrafficAnalytics(data) {
    return this.getReq(data, 'google-analytics/get-influencer-traffic?fr=' + data.fromDate + '&to=' + data.toDate);
  }

  /**
  * @ngdoc method
  * @name connectInstagram
  *
  * @description
  * This method call API to save instagram username and access
  **/
  connectInstagram(data, token) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + 'social-accounts/connect-instagram',
      data: data,
      headers: {
        'token': token
      }
    };
    return this.webService.httpReq(req);
  }
}

angular.module('heliApp').service('userProfileService', downgradeInjectable(UserProfileService));
