import { Component } from '@angular/core';

@Component({
selector: 'pages-component',
templateUrl: '/ng-app/src/app/components/test/test.component.html',
styleUrls: ['../../../../../ng-app/src/app/components/test/test.component.css'],
})

export class TestComponent {
    
    title: string = 'Heli hybrid';

    constructor() {}
}