import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';

/**
 * @ngdoc service
 * @name BinaryService
 * @description
 * # binaryService
 * Service in the heliApp.
 */

@Injectable()

export class BinaryService {

  constructor() { }

  getFilenameFromResponse(response) {
    let contentDispositionHeader = response.headers.get('Content-Disposition');
    if (angular.isString(contentDispositionHeader)) {
      let filename = contentDispositionHeader.match(/filename="(.+?)"/);
      if (filename) {
        return filename[1];
      }
    }

    return null;
  }

  downloadFileFromBlob(config) {
    config = angular.extend({}, {
      file: {
        name: 'file'
      },
      blob: {
        parts: [],
        options: {}
      },
      dom: {
        element: null
      }
    }, config);

    if (!config.dom.element) {
      config.dom.element = document.createElement('a');
    }

    if (!angular.isArray(config.blob.parts)) {
      config.blob.parts = [config.blob.parts];
    }

    config.dom.element.setAttribute('download', config.file.name);
    let blob = new Blob(config.blob.parts, config.blob.options);
    config.dom.element.href = URL.createObjectURL(blob);
    config.dom.element.click();
    URL.revokeObjectURL(config.dom.element.href);
    config.dom.element.remove();
  }
}

angular.module('heliApp').service('binaryService', downgradeInjectable(BinaryService))