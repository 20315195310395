/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:SearchCtrl
 * @description
 * # SearchCtrl
 * Controller of the heliApp
 */
// eslint-disable-next-line no-undef
angular.module('heliApp')
  .controller('SearchCtrl',['$rootScope', '$location', '$scope', 'searchService', '$state', '$cookies', '$timeout', '$stateParams', '$anchorScroll', 'CONST_HELPER', function ($rootScope, $location, $scope, searchService, $state, $cookies, $timeout, $stateParams, $anchorScroll, CONST_HELPER) {
    $scope.mergeArray = [];
    // eslint-disable-next-line no-undef
    $scope.currentDate = moment().format('YYYY-MM-DD');
    $cookies.put('appliedFilter', '');
    $cookies.put('appliedActivity', '');
    $cookies.put('appliedAbility', '');
    if ($rootScope.currentState.name !== 'filter') {
      $scope.formData = {
        'keyword': '',
        'location': '',
        'startDate': '',
        'endDate': '',
        'searchlocations': [],
        'locationFetched': false
      };
      $rootScope.location = {
        'latitude': '',
        'longitude': ''
      };
      $rootScope.tag = {
        'keyword': ''
      };
      // $scope.locationVal='';
    }
    /*
    // function to get available adventure result when user type something in serach box.
    */
    $scope.getAvailableAdventures = function (keys, $select) {
      var categoriesData = []; var sportsData = []; var operatorsData = []; var packagesData = [];
      searchService.getAvailableAdventures(keys).then(
        function (response) {
          if (response && response.data && response.data.result) {
            sportsData = response.data.result.sports;
            if (sportsData) {
              sportsData = sportsData.map(function (obj) {
                obj['category'] = 'sports';
                obj['name'] = obj.sport_name;
                return obj;
              }).slice(0, 3);
            }
            operatorsData = response.data.result.operators;
            if (operatorsData) {
              operatorsData = operatorsData.map(function (obj) {
                obj['category'] = 'operators';
                obj['name'] = obj.business_name;
                return obj;
              }).slice(0, 3);
            }
            categoriesData = response.data.result.categories;
            if (categoriesData) {
              categoriesData = categoriesData.map(function (obj) {
                obj['category'] = 'activities';
                obj['name'] = obj.category_name;
                return obj;
              }).slice(0, 2);
            }
            if (sportsData && categoriesData) {
              if (sportsData.length === 2) {
                categoriesData = categoriesData.slice(0, 2);
              } else if (sportsData.length === 1) {
                categoriesData = categoriesData.slice(0, 3);
              } else {
                categoriesData = categoriesData.slice(0, 4);
              }
            }
            packagesData = response.data.result.packages;
            if (packagesData) {
              packagesData = packagesData.map(function (obj) {
                obj['category'] = 'packages';
                return obj;
              }).slice(0, 3);
            }
            if (!sportsData) {
              sportsData = [];
              $scope.mergeArray = sportsData.concat(
                categoriesData, operatorsData, packagesData);
            } else {
              $scope.mergeArray = sportsData.concat(
                categoriesData, operatorsData, packagesData);
            }
          }
        },
        function (_error) { // check for error
        });
    };
    /*
    // function which trigger when user click on search button
    */
    $scope.searchClicked = false;
    $scope.searchAdventures = function (_formValid) {
      // $scope.searchClicked=true;      // show the validation error on search click.
      // if(formValid)
      //   return;                       // return false when form is not valid.
      if ($rootScope.tag.keyword && $rootScope.tag.keyword.length) {
        var selectedExp = $rootScope.tag.keyword.map(function (obj) {
          if (obj) { return obj.name; }
        });
      }
      var searchCriteria = {
        'keyword': selectedExp ? selectedExp.join(', ') : ''
        // "location": $scope.locationVal,
        // "mindate":$scope.formData.startDate,
        // "maxdate":$scope.formData.endDate,
        // "lat":$scope.locationVal?$rootScope.location.latitude:'',
        // "lang":$scope.locationVal?$rootScope.location.longitude:''
      };

      // broadcast an event when search from search page.
      // eslint-disable-next-line no-undef
      $('body').removeClass('fix-scroll');
      var data = {
        category: 'User Search',
        action: 'Click',
        gtmLabel: 'Search'
      };
      $rootScope.$broadcast('addEventToGTM', data);
      $scope.hidePopup('#search-modal');
      $state.go('filter', searchCriteria);
    };

    $scope.filterDateAdventures = function (event) {
      if ($rootScope.tag.keyword) {
        var selectedExp = $rootScope.tag.keyword.map(function (obj) {
          if (obj) { return obj.name; }
        });
      }
      // console.log($stateParams.mindate);
      // if ($stateParams.mindate) {
      //   $scope.dateRange.startDate = moment(new Date($stateParams.mindate));
      //   $scope.dateRange.endDate = moment(new Date($stateParams.maxdate));
      // }
      var searchCriteria = {
        'keyword': selectedExp ? selectedExp.join(', ') : '',
        'location': $scope.locationVal,
        'mindate': !$scope.dateRange.dateRangeCleared && $scope.dateRange.startDate ? moment($scope.dateRange.startDate).format('MM-DD-YYYY') : '',
        'maxdate': !$scope.dateRange.dateRangeCleared && $scope.dateRange.endDate ? moment($scope.dateRange.endDate).format('MM-DD-YYYY') : '',
        'lat': $scope.location.latitude,
        'lang': $scope.location.longitude,
        'page': 1
      };

      $('body').removeClass('fix-scroll');
      if ($scope.currentState.name === 'myprofile') {
        // if location is changed...
        if (event === 'location-changed') {
          $scope.$emit('dateFilterApplied', 'location-updated');
          return;
        }
        $scope.$emit('dateFilterApplied', $scope.dateRange); // if date is changed...
      } else {
        $scope.$broadcast('dateFilterApplied', $scope.dateRange); // if date or location is changed...
        $state.go('filter', searchCriteria);
      }
    };

    $scope.$on('updateDate', function (event, dates) {
      $timeout(function () {
        $scope.dateRange.startDate = $stateParams.mindate ? moment(new Date($stateParams.mindate.replace(/-/g, '/'))) : null;
        $scope.dateRange.endDate = $stateParams.maxdate ? moment(new Date($stateParams.maxdate.replace(/-/g, '/'))) : null;
        $scope.locationVal = $stateParams.location ? $stateParams.location : '';
      });
    });

    /*
    //  function to customize tags
    */
    $scope.tagTransform = function (newTag) {
      newTag = newTag.replace(/[^a-zA-Z0-9 ]/g, '');
      if (newTag !== '' && newTag !== ' ' && newTag.trim().length > 2) {
        var item = {
          name: newTag,
          id: 12,
          category: 'custom'
        };
        if (item) { return item; }
      }
    };
    /*
    //  watch the length of tag containner and accordingly decrease the length of textbox
    */
    $scope.$watch('tag.keyword', function (n, _o) {
      if (!n) { return false; }
      $timeout(function () {
        n.forEach(function (item, key) {
          if (item === undefined) {
            n.splice(key, 1);
          }
        });
      }, 10);

      var tagEle = angular.element(document.getElementsByClassName('ui-select-match'));
      var ulEle = angular.element(document.getElementsByClassName('select2-choices'));
      var liEle = angular.element(document.getElementsByClassName('select2-search-field'));
      $timeout(function () {
        var newWidth = parseInt(ulEle.width()) - parseInt(tagEle.width());
        if (newWidth > 50) {
          liEle[0].style.width = newWidth - 10 + 'px';
        } else {
          liEle[0].style.width = '100%';
        }
      }, 100);
    });

    // location textbox, search button elements..
    var locationInput = angular.element(document.getElementsByClassName('location-txtbox'));
    // eslint-disable-next-line no-unused-vars
    var searchButton = angular.element(document.getElementsByClassName('column-5'));
    var searchFocus = false;

    var searchContainer, searchInput, tagContainer, ulContainer;

    // set timeout to get the ui select elements
    $timeout(function () {
      searchInput = angular.element(document.getElementsByClassName('select2-input'));
      searchContainer = angular.element(document.getElementsByClassName('ui-select-container'));
      tagContainer = angular.element(document.getElementsByClassName('ui-select-match'));
      ulContainer = angular.element(document.getElementsByClassName('select2-choices'));
      // searchInput.focus();

      // show and hide search tag on focus and blur respectively...
      searchInput.on('focus', function () {
        searchContainer[0].style.height = 'auto';
        tagContainer[0].style.height = 'auto';
        tagContainer[0].style.width = 'auto';
        ulContainer[0].style.height = 'auto';
        searchFocus = true;
        deleteMoreElement();
      }).on('blur', function () {
        searchFocus = false;
        $timeout(function () {
          if (!searchFocus) {
            var splitWidth = searchContainer.width() - 60;

            // show More button when tagcontainer width is greater than spilt width...
            if (tagContainer.width() > splitWidth && splitWidth > 0) {
              tagContainer[0].style.width = splitWidth - 10 + 'px';
              createMoreElement();
              andMoretag = angular.element(document.getElementById('andMore'));
              setClickEvent(andMoretag);
              searchContainer[0].style.height = '35px';
              tagContainer[0].style.height = '35px';
              ulContainer[0].style.height = '35px';
            }
          }
        }, 700);
      });

      // on tag click set focus to serch input
      searchContainer.on('click', function () {
        searchInput.focus();
      });
      // searchInput.blur();
    }, 500);

    onReload();
    // on reload create More Element
    function onReload () {
      $timeout(function () {
        var splitWidth = searchContainer.width();

        // show More button when tagcontainer width is greater than spilt width...
        if (tagContainer.width() > splitWidth) {
          tagContainer[0].style.width = splitWidth - 10 + 'px';
          createMoreElement();
          andMoretag = angular.element(document.getElementById('andMore'));
          setClickEvent(andMoretag);
        }
        searchContainer[0].style.height = '35px';
        tagContainer[0].style.height = '35px';
        var searchInputA = angular.element(document.getElementsByClassName('select2-input'));
        searchInputA.focus();
      }, 600);
    }

    var andMoretag = angular.element(document.getElementById('andMore'));

    // on more button click set focus to serch input
    function setClickEvent (andMoretag) {
      andMoretag.on('click', function () {
        var searchInputA = angular.element(document.getElementsByClassName('select2-input'));
        searchInputA.focus();
      });
    }
    // append More... button
    function createMoreElement () {
      var ul = angular.element(document.getElementsByClassName('select2-choices'));
      var li = document.createElement('li');
      li.setAttribute('id', 'andMore');
      li.appendChild(document.createTextNode('More...'));
      ul[0].appendChild(li);
    }
    // delete More... button
    function deleteMoreElement () {
      var Moretag = angular.element(document.getElementById('andMore'));
      if (Moretag.length) { Moretag[0].parentNode.removeChild(Moretag[0]); }
    }

    // hit location api when user type something
    $scope.getLocation = function (q) {
      searchService.getLocation(q).then(function (response) {
        if (response) {
          $scope.formData.locationFetched = true;
          $scope.formData.searchlocations = response.data.locations;
        }
      }, function (_error) {});
    };

    // show and hide location result on focus and blur respectively...
    locationInput.on('focus', function () {
      $timeout(function () {
        $scope.formData.showLocation = true;
        // searchButton[0].classList.add('move-down')
        // eslint-disable-next-line no-unused-vars
        var deviceWidth = window.innerWidth;
      }, 200);
    }).on('blur', function () {
      $timeout(function () {
        $scope.formData.showLocation = false;
        // searchButton[0].classList.remove('move-down')
      }, 200);
    });

    // select the location when user click on available location.
    $scope.selectLocation = function (loc) {
      $rootScope.locationName = loc.name;
      $rootScope.location = loc;
      $scope.formData.showLocation = false;
    };

    // clear location
    $scope.clearSelectedLocation = function () {
      $scope.locationVal = '';
      $('.location-txtbox').val('');
      $rootScope.location = { 'latitude': '', 'longitude': '' };
      $rootScope.locationSelected = '';
      $rootScope.locationName = '';
      $scope.filterDateAdventures('location-changed');
    };

    // date range picker options
    $scope.dateRangeOptions = {
      locale: { format: 'D MMM' },
      autoApply: false,
      eventHandlers: {
        'hide.daterangepicker': function () {
          $('.calendar-close i').trigger('click');
        },
        'show.daterangepicker': function () {
          $scope.dateRangeClicked();
        }
      }
    };

    // function to update the user clicked datepicker name FROM/To.
    $scope.dateRangeClicked = function (_clickedOn) {
      // date rance picker functionality
      $('.daterangepicker .applyBtn').on('click', function () {
        $timeout(function () {
          $scope.dateRange.dateRangeCleared = false;
          $scope.filterDateAdventures();
        });
      });
      $('.daterangepicker .cancelBtn').on('click', function () {
        $timeout(function () {
          $scope.dateRange.dateRangeCleared = true;
          $scope.dateRange.startDate = null;
          $scope.dateRange.endDate = new Date();
          $scope.filterDateAdventures();
          $('.calendar-close i').trigger('click');
        });
      });
      var currentDate = new Date();
      var startDate = $scope.dateRange.startDate ? moment($scope.dateRange.startDate).toDate() : new Date();
      var endDate = $scope.dateRange.endDate ? moment($scope.dateRange.endDate).toDate() : new Date();
      if (startDate.isSameDateAs(currentDate) && endDate.isSameDateAs(currentDate)) {
        $('.daterangepicker .applyBtn').attr('disabled', true);
        $('.daterangepicker .cancelBtn').attr('disabled', true);
      } else {
        $('.daterangepicker .applyBtn').attr('disabled', false);
        $('.daterangepicker .cancelBtn').attr('disabled', false);
      }

      // if scree size in less than 568(in Mobiles)
      if (window.innerWidth <= CONST_HELPER.SCREEN.MOBILE) {
        $('#navigation-scroll').addClass('modal-open');
        if (!$('.daterangepicker').find('.calendar-close').length) {
          $('.daterangepicker').prepend('<span class="cross-btn calendar-close" aria-hidden="true"><i class="icon-small-cancel"></i></span>');
        }
        $('.calendar-close i').on('click', function () {
          $('.daterangepicker').hide();
          $('#navigation-scroll').removeClass('modal-open');
        });
        $('.daterangepicker').show();
      }
    };

    $timeout(function () {
      if (($rootScope.currentState.name === 'filter' || $rootScope.currentState.name === 'myprofile') && window.innerWidth <= CONST_HELPER.SCREEN.MOBILE) {
        if (!$('.daterangepicker').find('.calendar-close').length) {
          $('.daterangepicker').prepend('<span class="cross-btn calendar-close" aria-hidden="true"><i class="icon-small-cancel"></i></span>');
        }
        $('.calendar-close i').on('click', function () {
          $('.daterangepicker').hide();
          $('#navigation-scroll').removeClass('modal-open');
        });
      }
    }, 100);

    // eslint-disable-next-line no-extend-native
    Date.prototype.isSameDateAs = function (pDate) {
      return (
        this.getFullYear() === pDate.getFullYear() &&
        this.getMonth() === pDate.getMonth() &&
        this.getDate() === pDate.getDate()
      );
    };

    // get featured adventure and featured ambassadors...
    function getfeaturedData () {
      if ($rootScope.featuredAdventures.length > 0) { return; }
      searchService.getfeaturedAdventures().then(
        function (response) {
          if (response.data && response.data.code === '200') {
            $scope.hideLoader();
            $rootScope.featuredAdventures = response.data.data.featuredAdventure;
            $rootScope.featuredAmbassadors = response.data.data.featuredAmbassadors;

            $scope.checkExpiredSlots($rootScope.featuredAdventures);
            $rootScope.currentState.name !== 'adventure-detail' ? window.prerenderReady = true : null;
          }
        });
    }

    // get featured activity ...
    function getfeaturedActivity () {
      // $scope.showLoader();
      if ($rootScope.featuredActivity.length > 0) { return; }
      searchService.getfeaturedActivity().then(
        function (response) {
          if (response.data && response.data.code === '200') {
            $scope.hideLoader();
            $rootScope.featuredActivity = response.data.data;
            $rootScope.featuredActivity.map(function (activity) {
              if (activity.sport_name === 'Expedition') {
                activity.sport_name = 'Safari';
              }
            });
            window.prerenderReady = true;
          }
        }, function () {
          // $scope.hideLoader()
        });
    }

    // call getfeaturedData and featuredActivity function
    // window.addEventListener('load', function () {
    $(document).ready(function () {
      getfeaturedData();
      // call getHomePagelogo() function
      getHomePagelogo();
    });
    getfeaturedActivity();

    // get featured/operator/partners logos...
    function getHomePagelogo () {
      // $scope.showLoader();
      if ($rootScope.HomePagelogo.hasOwnProperty('operatorProfile') && $rootScope.HomePagelogo.operatorProfile.length > 0) {
        $timeout(function () {
          $('.load-img').closest('.first-img').removeClass('loading');
        }, 100);
        return;
      }
      searchService.getHomePagelogo().then(
        function (response) {
          if (response.data && response.data.code === '200') {
            $scope.hideLoader();
            $rootScope.HomePagelogo = response.data.result;

            // $timeout(function(){
            //   var image=document.getElementsByClassName('load-img')
            //   if(image.length>0){
            //     for (var i = 0; i < image.length; i++) {
            //         $(image[i]).on('load',triggerAction);
            //     }
            //     $(".load-img").closest('.first-img').addClass('loading')
            //   }else{
            //     $(".load-img").closest('.first-img').removeClass('loading')
            //   }

            //   function triggerAction() {
            //     this.closest('.first-img').classList.remove('loading')
            //   }
            // },500)
          }
        }, function () {
          // $scope.hideLoader()
        });
    }

    $scope.addUniqueClass = function (_locationText) {
      // add a uniqe class for location dropdown in filter page...
      if ($scope.currentState.name === 'filter' && window.screen && window.screen.width >= 1024) { $('.pac-container').addClass('filter-search'); }
    };
  }]);
