/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:OperatorAddGuestFormCtrl
 * @description
 * # OperatorAddGuestFormCtrl
 * Controller of the heliApp
 */
angular.module('heliApp')
  .controller('OperatorAddGuestFormCtrl', ['$scope', 'CONST_HELPER', 'operatorProfileService', '$state', '$stateParams', function ($scope, CONST_HELPER, operatorProfileService, $state, $stateParams) {
    $scope.formData = {
      name: '',
      questions: []
    };
    $scope.config = CONST_HELPER.EDITOR_CONFIG;
    $scope.formId = $stateParams.formId;
    $scope.showQuestionError = false;
    $scope.showMaxLengthError = false;
    $scope.showOptionalError = false;
    $scope.showOptionsError = false;
    $scope.showFormSaveError = false;
    $scope.selectedQuestionDelete = null;

    $scope.temp = '';

    $scope.allQuestions = [];

    $scope.questionData = {
      type: '',
      questionText: ''
    };

    $scope.questionsType = [{
      id: 1,
      name: 'Short Text'
    }, {
      id: 2,
      name: 'Long Text'
    }, {
      id: 3,
      name: 'Statement'
    }, {
      id: 4,
      name: 'Multiple Choice'
    }, {
      id: 5,
      name: 'Dropdown'
    }];
    $scope.openAddQuestionsPopup = function () {
      $scope.questionData = {
        type: '',
        questionText: ''
      };
      $scope.showPopup('#add-questions-modal');
    };

    $scope.getOperatorFormDetail = function () {
      /* get all the sports list along with categories */
      operatorProfileService.getOperatorFormDetail($scope.formId, $scope.user.token).then(function (response) { // fetch from the service
        if (response) {
          // $scope.operatorFormOptions = response.data.sports;// add to scope
          // $scope.formData = response.data.data[0];
          var data = response.data.data[CONST_HELPER.ZERO];
          $scope.formData.name = data.title;
          $scope.parseQuestionsData(data.guest_form_detail.request);
        }
      }, function () { // check for error
        $scope.showPopup('#serverErrModal');
      });
    };

    $scope.parseQuestionsData = function (questionsReq) {
      // TODO SET ALL QUESTIONS OBJECT
      if (questionsReq) {
        $scope.allQuestions = [];
        questionsReq.fields.forEach(function (field) {
          var tempObj = {
            type: CONST_HELPER.QUESTOIN_TYPE[field.type],
            questionText: field.title,
            optional: field.validations ? field.validations.required : false,
            maxlength: field.validations ? field.validations.max_length : CONST_HELPER.ZERO,
            options: field.properties.choices,
            temp_id: $scope.getTempId()
          };
          // MAX LENGTH IS ONLY FOR SHORT TEXT & LONG TEXT
          if (tempObj.type > CONST_HELPER.TWO) {
            delete tempObj.max_length;
          }
          // FOR STATEMENT REQUIRED IS NOT NEEDED
          if (tempObj.type == CONST_HELPER.THREE) {
            var _data = $.parseHTML(tempObj.questionText) ? $.parseHTML(tempObj.questionText) : tempObj.questionText;
            if(_data.length) {
              tempObj.questionText = _data[0].data;
            }
            delete tempObj.optional;
            delete tempObj.maxlength;
          }

          if (tempObj.type <= CONST_HELPER.THREE) {
            delete tempObj.options;
          }

          $scope.allQuestions.push(tempObj);
        });
      }
    };

    $scope.addEditGuestFormApi = function (id, json) {
      $scope.showLoader();
      /* add a guest form */
      operatorProfileService.addEditOperatorForms({ id: $scope.formId, json: json, token: $scope.user.token }).then(function (response) { // fetch from the service
        $scope.hideLoader();
        if (response && response.data.code === CONST_HELPER.API_RESPONSE.OK) {
          $state.go('guest-form');
        }
      }, function () { // check for error
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });
    };

    $scope.changeQuestionType = function () {
      if ($scope.questionData.type > CONST_HELPER.THREE) {
        $scope.questionData.options = [{
          label: ''
        }];
      }
    };

    $scope.addMoreOptions = function () {
      $scope.questionData.options.push({
        label: ''
      });
    };

    $scope.deleteOption = function (index) {
      $scope.questionData.options.splice(index, CONST_HELPER.ONE);
    };

    $scope.editQuestion = function (index) {
      $scope.questionData = $scope.allQuestions[index];
      $scope.showPopup('#add-questions-modal');
    };

    $scope.closeAddQuestionPopup = function () {
      $scope.hidePopup('#add-questions-modal');
    };

    $scope.deleteQuestionConfirmation = function (index) {
      $scope.selectedQuestionDelete = index;
      $scope.showPopup('#delete-questions-modal');
    };

    $scope.deleteQuestion = function (index) {
      $scope.allQuestions.splice($scope.selectedQuestionDelete, CONST_HELPER.ONE);
      $scope.hidePopup('#delete-questions-modal');
    };

    $scope.closeDeleteQuestion = function () {
      $scope.hidePopup('#delete-questions-modal');
    };

    $scope.saveQuestion = function (saveQuestion) {
      // QUESTION TYPE AND QUESTION TEXT
      if ($scope.questionData && (!$scope.questionData.type || !$scope.questionData.questionText || $scope.questionData.questionText == '')) {
        $scope.showQuestionError = true;
      } else {
        $scope.showQuestionError = false;
      }

      if ($scope.questionData && $scope.questionData.type > CONST_HELPER.THREE) {
        // ONLY CHECK FOR MULTIPLE OPTIONS AND DROPDOWN
        var temp_arr = [];
        if ($scope.questionData.options && $scope.questionData.options.length != CONST_HELPER.ZERO) {
          $scope.questionData.options.forEach(function (option) {
            temp_arr.push(option.label == '');
          });

          $scope.showOptionsError = temp_arr.indexOf(true) != CONST_HELPER.MINUS_ONE;
        } else if ($scope.questionData.options && $scope.questionData.options.length == CONST_HELPER.ZERO) {
          $scope.showOptionsError = true;
        }
      } else {
        $scope.showOptionsError = false;
      }

      // NOT FOR STATEMENT
      if ($scope.questionData && $scope.questionData.type != CONST_HELPER.THREE) {
        if (typeof $scope.questionData.optional == 'undefined') {
          $scope.showOptionalError = true;
        } else {
          $scope.showOptionalError = false;
        }

        if ($scope.questionData && $scope.questionData.type <= CONST_HELPER.TWO && (!$scope.questionData.maxlength || typeof $scope.questionData.maxlength == 'undefined' || $scope.questionData.maxlength < CONST_HELPER.ONE)) {
          $scope.showMaxLengthError = true;
        } else {
          $scope.showMaxLengthError = false;
        }
      }

      if ($scope.showQuestionError || $scope.showOptionsError || $scope.showOptionalError || $scope.showMaxLengthError) {
        return;
      }

      if (!saveQuestion.temp_id) {
        saveQuestion.temp_id = $scope.getTempId();
        $scope.allQuestions.push(saveQuestion);
      } else {
        $scope.allQuestions.map(function (q) {
          q.temp_id == saveQuestion.temp_id ? q = saveQuestion : null;
        });
        // $scope.allQuestions.push(saveQuestion);
      }

      $scope.hidePopup('#add-questions-modal');
    };

    $scope.createForm = function () {
      if ($scope.formData && (!$scope.formData.name || $scope.formData.name == '' || $scope.formData.name.length < CONST_HELPER.THREE)) {
        $scope.showFormSaveError = true;
        return;
      } else {
        $scope.showFormSaveError = false;
      }

      if ($scope.allQuestions && $scope.allQuestions.length < CONST_HELPER.ONE) {
        $scope.showFormSaveError = true;
        return;
      } else {
        $scope.showFormSaveError = false;
      }

      // if (!$scope.showFormSaveError) {
      $scope.addEditGuestFormApi(null, createRequestObject());
      // }
    };

    function createRequestObject () {
      var obj = {
        'title': $scope.formData.name,
        'fields': []
      };
      $scope.allQuestions.forEach(function (question) {
        if (question && question.options) {

          question.options.map(function (option, index) {
            if(question.type == CONST_HELPER.FOUR) {
              option.ref = CONST_HELPER.QUESTION_ID_TEXT[question.type] + '_' + moment().unix() + '_' + Math.floor(Math.random() * Math.floor(CONST_HELPER.TEXTLIMIT)) + '_' + index;
            }
            delete option.$$hashKey;
            delete option.id;
          });
        }
        var tempObj = {
          ref: CONST_HELPER.QUESTION_ID_TEXT[question.type] + '_' + moment().unix() + '_' + Math.floor(Math.random() * Math.floor(CONST_HELPER.TEXTLIMIT)),
          title: question.questionText,
          type: CONST_HELPER.QUESTION_ID_TEXT[question.type],
          validations: {
            required: question.optional,
            max_length: question.maxlength
          },
          properties: {
            description: '',
            choices: question.options,
            allow_multiple_selection: question.type == CONST_HELPER.FOUR,
            allow_other_choice: false,
            vertical_alignment: false
          }
        };
        // MAX LENGTH IS ONLY FOR SHORT TEXT & LONG TEXT
        if (question.type > CONST_HELPER.TWO) {
          delete tempObj.validations.max_length;
        }
        // FOR STATEMENT REQUIRED IS NOT NEEDED
        if (question.type == CONST_HELPER.THREE) {
          tempObj.title = question.questionText;
          tempObj.properties.hide_marks = true;
          delete tempObj.validations;
        }

        if (question.type <= CONST_HELPER.THREE) {
          delete tempObj.properties.choices;
          delete tempObj.properties.allow_multiple_selection;
          delete tempObj.properties.allow_other_choice;
          delete tempObj.properties.vertical_alignment;
        } else if (question.type == CONST_HELPER.FIVE) {
          delete tempObj.properties.allow_multiple_selection;
          delete tempObj.properties.allow_other_choice;
          delete tempObj.properties.vertical_alignment;
        }

        obj.fields.push(tempObj);
      });
      return obj;
    }
  }]);
