/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc directive
 * @name heliApp.directive:Image Upload section
 * @description
 * # upload image in lodging
 */

angular.module('heliApp')
  .directive('lodgingImageUpload',['$timeout', 'CONST_HELPER', function ($timeout, CONST_HELPER) {
    return {
      restrict: 'EA',
      templateUrl: 'views/lodging-image-upload.html',
      scope: {
        lodgingImages: '=',
        lodgingUploadImage: '=',
        lodgingFormValid: '=',
        lodgingId: '=',
        currenctLocation: '='
      },
      link: function (scope, element, attr, ctrl) {
        $timeout(function () {
          if (!scope.lodgingId) {
            angular.element(document.querySelector('#lodgingMultipleFileInput')).on('change', handleMultiFileSelect);
          }
        });
        scope.lodgingUploadImage = scope.lodgingUploadImage || [];
        var maxImageLength = CONST_HELPER.PACKAGE.MAX_LODGING_IMAGES;
        if (scope.lodgingImages) {
          maxImageLength -= scope.lodgingImages.length;
        }
        if (scope.lodgingUploadImage) {
          maxImageLength -= scope.lodgingUploadImage.length;
        }

        function handleMultiFileSelect (evt) {
          scope.lodgingImages = scope.lodgingImages || [];

          // reset size error msg...
          scope.lodgingMinSingleImgSize = false;
          scope.lodgingMaxSingleImgSize = false;
          scope.lodgingInvalidFormatImg = false;

          var _URL = window.URL || window.webkitURL; // initiate _URL

          if (!evt) {
            $timeout(angular.noop);
            return;
          }

          for (var i = 0; i < evt.currentTarget.files.length; i++) {
            var file = evt.currentTarget.files[i];
            var fileExtnArr = file.name.split('.');
            var extn = fileExtnArr[fileExtnArr.length - 1].toLowerCase();
            if (extn === 'png' || extn === 'jpg' || extn === 'jpeg' || extn === 'gif') { /* check valid image extn */
              // image min size validation
              if (evt.currentTarget.files[0].size < CONST_HELPER.MEDIA.MIN_SIZE) {
                showImgSizeError('min'); // show min image size error;
                $timeout(angular.noop);
                return false;
              }
              // image max size validation...
              if (evt.currentTarget.files[0].size > CONST_HELPER.MEDIA.MAX_SIZE) { // check size of image is greater then 5MB
                showImgSizeError('max'); // show max image size error;
                $timeout(angular.noop);
                return false;
              }

              //   var img = new Image();
              // img.onload = function(){
              // if(this.width<900 || this.height<300){
              //               showImgSizeError('min') // show min image size error;
              //               return false;
              //           }else{
              //             readImage(file);// if the image size is good then read the file and push to image array...
              //           }
              // }
              // img.src = _URL.createObjectURL(file);

              // readImage(file); // if the image size is good then read the file and push to image array...
              scope.lodgingUploadImage = scope.lodgingUploadImage || [];
              if (scope.lodgingUploadImage.length >= maxImageLength) {
                $timeout(angular.noop);
                return;
              }

              scope.lodgingUploadImage.push(file);
              var reader = new FileReader();
              reader.onload = function (e) {
                if (!scope.$$phase) {
                  scope.$apply(function () {
                    if (scope.lodgingImages) {
                      if (scope.lodgingImages.length < CONST_HELPER.PACKAGE.MAX_LODGING_IMAGES) {
                        // save image in lodgingImages array
                        scope.lodgingImages.push(e.currentTarget.result);
                      }

                      if (scope.lodgingImages.length >= CONST_HELPER.PACKAGE.MAX_LODGING_IMAGES) {
                        // if lodging image length CONST_HELPER.PACKAGE.MAX_LODGING_IMAGES then return
                        // $('#add-more-li').css('display','none');
                        // scope.lodgingUploadImage.pop();
                        return false;
                        //   $timeout(angular.noop)
                      }
                    }

                    if (!$('#addMoreLodgingImage').is(':visible')) { // initiate multiple file input tag
                      $timeout(function () {
                        angular.element(document.querySelector('#addMoreLodgingImage')).off('change', handleMultiFileSelect).on('change', handleMultiFileSelect);
                      }, 100);
                    }
                    $timeout(angular.noop);
                  });
                }
              };
              reader.readAsDataURL(file);
            } else { /* if image extn invalid then show alert message */
              if (!scope.$$phase) {
                scope.$apply(function () {
                  scope.lodgingInvalidFormatImg = true;
                  file = '';
                  $timeout(angular.noop);
                });
              }
            }
          }
        }

        function readImage (file) {
          scope.lodgingUploadImage.push(file);
          var reader = new FileReader();
          reader.onload = function (e) {
            if (!scope.$$phase) {
              scope.$apply(function () {
                scope.lodgingImages.push(e.currentTarget.result); // save image in lodgingImages array

                if (
                  scope.lodgingImages
                  &&
                  (scope.lodgingImages.length >= CONST_HELPER.PACKAGE.MAX_LODGING_IMAGES)
                ) {
                  // if lodging image length CONST_HELPER.PACKAGE.MAX_LODGING_IMAGES then return
                  // $('#add-more-li').css('display','none');
                  // scope.lodgingUploadImage.pop();
                  return false;
                }

                if (!$('#addMoreLodgingImage').is(':visible')) { // initiate multiple file input tag
                  $timeout(function () {
                    angular.element(document.querySelector('#addMoreLodgingImage')).off('change', handleMultiFileSelect).on('change', handleMultiFileSelect);
                  }, 100);
                }
              });
            }
          };
          reader.readAsDataURL(file);
        }

        function showImgSizeError (type) { // function to show error message for profile image
          if (!scope.$$phase) {
            scope.$apply(function (scope) {
              if (type === 'min') {
                scope.lodgingMinSingleImgSize = true; // show error message
              } else {
                scope.lodgingMaxSingleImgSize = true; // show error message
              }
            });
          }
        }

        scope.deleteImage = function (imgid, index) {
          if (scope.lodgingImages) {
            if (!imgid) {
              var previousLength = scope.lodgingImages.length - scope.lodgingUploadImage.length;
              var uploadImageIndex = index - previousLength;
              scope.lodgingUploadImage.splice(uploadImageIndex, 1);
            }
            scope.lodgingImages.splice(index, 1);
            if (scope.lodgingImages.length < CONST_HELPER.PACKAGE.MAX_LODGING_IMAGES) {
              // $('#add-more-li').css('display','block');
            }
          }
        };

        element.find('input:file').each(function (index, val) {
          if (scope.lodgingId) { $(val).on('change', handleMultiFileSelect); }
        });

        // check if lodging image is already CONST_HELPER.PACKAGE.MAX_LODGING_IMAGES then hide add more image button
        if (
          scope.lodgingImages
          &&
          (scope.lodgingImages.length >= CONST_HELPER.PACKAGE.MAX_LODGING_IMAGES)
        ) {
          // $('#add-more-li').css('display','none');
        }
      }
    };
  }]);
