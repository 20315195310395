import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { UIRouter, UIRouterGlobals } from '@uirouter/core';
import * as angular from 'angular';
import { helper } from '../../../../../app/scripts/helper';
import { UserSocialProfileService } from '../../../../../app/scripts/services/userSocialProfileService';
import { CookieService } from 'ngx-cookie-service';
import { UserLoginService } from '../../../../../app/scripts/services/userLoginService';
import { UserAdventureDetailService } from '../../../../../app/scripts/services/userAdventureDetailService';
import { Meta, Title } from '@angular/platform-browser';


declare var WebFont: any;

@Injectable()

export class RootScopeService {

  rootScope: any = {
    user: <any>{},
    isAgent: <boolean>false,
    showFooter: <boolean>false,
    siteInIFrame: <boolean>false,
    homePagelogos: <any>{
      operatorProfile: [],
      mediaLogo: [],
      partnerLogo: []
    },
    showFullHeader: <boolean>true,
    userRole: <string>'',
    hasProfile: <boolean>false,
    feedsCount: <number>0,
    myCartLength: <number>0,
    featuredActivity: <any>[],
    activityFilterPage: <boolean>false,
    hideSideMenu: <boolean>true,
    hideSearchIcon: <boolean>false,
    selectedActivity: <string>'',
    guestUserRemainingPayment: <any>'',
    disableHeaderBackBtn: <boolean>false,
    successMsg: <string>''
  };
  searchValue: string;
  listShown = helper.LIST.SHOWN;
  listSize = helper.LIST.SIZE;
  filteredAdventures: any[];
  numFound: any;
  currencySymbol = {
    'INR': 'INR',
    'USD': 'USD',
    'CAD': 'CAD',
    'EUR': 'EUR',
    'JPY': 'JPY',
    'GBP': 'GBP',
    'NZD': 'NZD',
    'AUD': 'AUD',
    'ISK': 'ISK',
    'MXN': 'MXN',
    'CHF': 'CHF',
    'BRL': 'BRL'
  };

  allowedAgentURLs: any = [
    'new-route-agent',
    'agent-signup',
    'signup',
    'signin',
    'agent-calendar',
    'agent-analytics',
    'agent-booking-list',
    'agent-operators',
    'agent-adventures',
    'resetPassword',
    'forgotPassword',
    'operator-manual-booking',
    'operator-edit-slots',
    'adventure-detail'
  ];

  constructor(
    @Inject("$rootScope") private rootScopeJS: any,
    @Inject(CookieService) private cookies: CookieService,
    @Inject(UIRouter) private router: UIRouter,
    @Inject(UserSocialProfileService) private userSocialProfileService: UserSocialProfileService,
    @Inject(UserLoginService) private userLoginService: UserLoginService,
    @Inject(UserAdventureDetailService) private userAdventureDetailService: UserAdventureDetailService,
    @Inject(Meta) private ngMeta: Meta,
    @Inject(Title) private ngMetaTitle: Title,
    public routerGlobals: UIRouterGlobals
  ) {
    this.router.locationService.onChange(() => {
      if (!this.routerGlobals.params.activityCategory) {
        let state = this.rootScopeJS.currentState.data.meta;
        this.updateMetaTitle(state.title ? state.title : 'Heli Adventures | Unite Through Adventure');
        this.updateMetaDescription(state.description ? state.description : '');
        this.updateMetaImage(state.image ? state.image : '');
        this.updateMetaUrl(state.url ? state.url : '');
      }
    })
  }

  updateMetaTitle(title) {
    this.ngMetaTitle.setTitle(title);
    this.ngMeta.updateTag({property: 'og:title', content: title});
    this.ngMeta.updateTag({name: 'twitter:title', content: title});
  }

  updateMetaDescription(description){
    this.ngMeta.updateTag({name: 'description', content: description});
    this.ngMeta.updateTag({property: 'og:description', content: description});
    this.ngMeta.updateTag({name: 'twitter:description', content: description});
  }

  updateMetaImage(image) {
    this.ngMeta.updateTag({property: 'og:image', content: image});
    this.ngMeta.updateTag({name: 'twitter:image', content: image});
  }

  updateMetaUrl(url) {
    this.ngMeta.updateTag({property: 'og:url', content: url});
  }

  //AngularJS SET rootScopeService.setValue("variable", value);
  setValue = (variable: string, value: any) => {
    this.rootScope[variable] = value;
  }

  //AngularJS GET rootScopeService.getValue("variable");
  getValue(variable: string) {
    return this.rootScope[variable];
  }

  callApiForOperatorProfile() {
    if (this.router.stateService.is('new-route-white-label') || this.rootScopeJS.isSubDomain) {
      this.getOperatorPackageList(helper.ONE);
    }
  };

  getOperatorPackageList(page) { //  get active package list function
    this.rootScopeJS.showLoaderRootScope();
    this.rootScopeJS.loading = true;
    this.rootScopeJS.page = page;
    var hostName = this.router.locationConfig.host();
    var requestObject = {
      'user_name': hostName.slice(0, hostName.indexOf('.')),
      'domain_url': window.location.href,
      'page': this.rootScopeJS.page,
      'limit': helper.LIST.SIZE,
      'back': helper.ZERO
    };
    this.userSocialProfileService.operatorPackageListSubDomain(requestObject).then((response: any) => { // call active package list api
      if (response && response.data.response) {
        this.rootScopeJS.loading = false;
        if (response.data.response.docs) {
          this.filteredAdventures = []
          response.data.response.docs.forEach((adv) => {
            if (adv.package_type == 1) {
              this.filteredAdventures.push(adv)
            }
          })
        }
        if (response.data.response.docs && page > helper.ONE) {
           this.rootScopeJS.packageList =  this.rootScopeJS.packageList.concat(this.filteredAdventures);
        } else {
           this.rootScopeJS.packageList = this.filteredAdventures;
        }

         this.rootScopeJS.pageDetails = response.data.response.pageDetails[0];
        if ( this.rootScopeJS.pageDetails && ! this.rootScopeJS.pageDetails.is_verified) {
          // Show Black page if not verified domain.
           this.rootScopeJS.blankPage = true;
        }

        this.ngMetaTitle.setTitle( this.rootScopeJS.pageDetails.page_title); // set dynamic title and description of the page.
        this.ngMeta.addTag({name: 'description', content: this.rootScopeJS.pageDetails.page_desc})
        // TODO: GET FONT FROM WEB FONTS
        if (( this.rootScopeJS.pageDetails.head_font ||  this.rootScopeJS.pageDetails.body_font) && WebFont) {
          WebFont.load({
            google: {
              families: [ this.rootScopeJS.pageDetails.head_font,  this.rootScopeJS.pageDetails.body_font]
            }
          });
          this.setCustomOnMySiteStyles();
        }
        this.rootScopeJS.hideLoaderRootScope();
        this.checkExpiredSlots( this.rootScopeJS.packageList);
        // more package Shown on adventure tab
        this.numFound = response.data.response.numFound;
      } else {
         this.rootScopeJS.blankPage = true;
      }
    }, (error) => {
      this.rootScopeJS.hideLoaderRootScope();
      this.showPopup('#serverErrModal');
    });
  }

  setCustomOnMySiteStyles () {
    var customStyles = '* {font-family: ' + (this.rootScopeJS.pageDetails.body_font ? (this.rootScopeJS.pageDetails.body_font.toString() +' !important') : '') + '}'
    + '.domain-heading { font-family: ' + (this.rootScopeJS.pageDetails.head_font ? (this.rootScopeJS.pageDetails.head_font.toString() +' !important') : '') + '}'
    + '.domain-btn { background-color: ' + (this.rootScopeJS.pageDetails.button_color ? (this.rootScopeJS.pageDetails.button_color.toString() +' !important;') : '') + 'color: ' + (this.rootScopeJS.pageDetails.button_text_color ? (this.rootScopeJS.pageDetails.button_text_color.toString() +' !important') : '') +'}'
    +'.domain-btn:active, .domain-btn:focus, .domain-btn:hover { background-color: ' + (this.rootScopeJS.pageDetails.button_color ? (this.rootScopeJS.pageDetails.button_color.toString() +' !important;') : '') + 'color: ' + (this.rootScopeJS.pageDetails.button_text_color ? (this.rootScopeJS.pageDetails.button_text_color.toString() +' !important') : '') +'}';
    $('<style>').text(customStyles).appendTo(document.head);
  }

  // check for expired slots...
  checkExpiredSlots(packageData) {
    packageData.map((packageList, keys) => {
      packageList.hasActiveSlots = false;
      packageList.availableSeats = 0;
      packageList.activeSlots = 0;
      if (packageList.package_slots) {
        packageList.package_slots.map((slot, key) => {
          var slotStartDate = new Date(slot.from_date);
          var currentDate = new Date();
          var diffDays = Math.floor((Date.UTC(slotStartDate.getFullYear(), slotStartDate.getMonth(), slotStartDate.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
          if (diffDays < 0) { // if slot expired
            slot.expired = true;
          } else {
            slot.expired = false;
            packageList.hasActiveSlots = true;
            packageList.availableSeats += slot.available_seat; // get available seats of active stots.
            packageList.activeSlots = packageList.activeSlots + 1; // get package active slots...
          }
        });
      }
    });
  };

  loadMorePackage() { // load more packages
    this.getOperatorPackageList(this.rootScopeJS.page + helper.ONE);
    this.listShown = this.listShown + helper.ONE;
  };

  packageLimit() { // set package list limit here
    return String(this.listSize * this.listShown);
  };

  hasMorePackageToShow() {
    return this.listShown < (this.numFound / this.listSize);
  };

  showPopup = (modalId) => { // show the popup
    $(modalId).modal('show');
  };

  checkUserRoles(){ // check for user roles for redirection to dashboards
    var user: any = this.cookies.get('user');
    if (user) {
      user = JSON.parse(user);
      if (user.role_id == '3') { // if user is an operator
        if (!this.rootScopeJS.isAgent) {
          if (user.is_profile_completed) {
            this.router.stateService.go('operator-dashboard');
            return false;
          } else {
            this.router.stateService.go('operator-get-started');
            return false;
          }
        } else {
          this.logout();
        }
      } else if (user.role_id == '5') {
        if (!this.rootScopeJS.isAgent) {
          this.logout();
        } else {
          if (this.rootScopeJS.isAgent && !this.allowedAgentURLs.includes(this.rootScopeJS.currentState.name)) {
            this.router.stateService.go('agent-calendar');
          }
        }
      }
    }
  };

  logout = () => { // logout user from heli
    this.userLoginService.logOutUser(this.rootScope.user.token).then((response: any) => {
      const cookies = this.cookies.getAll();
      var couponData = {
        promo_code_id: this.cookies.get('coupon_id'),
        cart_id: this.cookies.get('cart_id'),
        previousPrice: '' // static cart total.
      };
      angular.forEach(cookies, (v, k) => {
        this.cookies.delete(k);
      }); // clear local storage
      // var domainName = window.location.host.substr(window.location.host.indexOf('.'));
      (() => {
        var cookies = document.cookie.split('; ');
        for (var c = 0; c < cookies.length; c++) {
          var d = window.location.hostname.split('.');
          while (d.length > 0) {
            var cookieBase = encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
            var p = location.pathname.split('/');
            document.cookie = cookieBase + '/';
            while (p.length > 0) {
              document.cookie = cookieBase + p.join('/');
              p.pop();
            };
            d.shift();
          }
        }
      })();
      this.rootScope.userLoggedIn = false; // rest scope
      this.rootScope.hasProfile = false; // rest scope
      // this.getUserDetails(); // reset scope
      this.rootScopeJS.userRole = 'user';
      this.rootScope.myCartLength = helper.ZERO;
      this.rootScope.user = {};
      // dont redirect user to home page...
      if (this.router.stateService.is('filter') || this.router.stateService.is('adventure-detail')) {
        // do nothing when user logout...
        this.rootScopeJS.$broadcast('user.logout', couponData);
      } else {
        this.rootScopeJS.$broadcast('user.logout', couponData);
        if (!this.rootScopeJS.siteInIFrame) {
          this.router.stateService.go('home');
        } // redirect to home page
        if (this.rootScopeJS.isAgent) {
          this.router.stateService.go('signin');
        }
      }
      this.getUserIP();
      this.closeNav();
    }, () => { });
  };

  getUserIP() {
    if (this.rootScopeJS.user && this.rootScopeJS.currentState.name !== 'secure-checkout') {
      if (this.rootScopeJS.user.currency_code) {
        this.rootScopeJS.localCurrencyCode = this.rootScopeJS.user.currency_code;
        this.rootScopeJS.localCurrencySymbol = this.currencySymbol[this.rootScopeJS.localCurrencyCode];
        if (this.rootScopeJS.currentState.name === 'package-slot-addons-summary' || this.rootScopeJS.currentState.name === 'adventure-detail' || this.rootScopeJS.currentState.name === 'adventure-detail-pre' || this.rootScopeJS.currentState.name === 'adventure-detail-private') {
          this.rootScopeJS.$broadcast('get-package-addons'); // now get package addons if page refresh in addons page
        }
        return false;
      }
    }
    this.rootScopeJS.showLoaderRootScope();
    $.ajax({
      type: 'GET',
      url: 'https://ipapi.co/json/',
      success: (res) => {
        this.userAdventureDetailService.getCurrencyCode(res.ip, res.country).then((result: any) => {
          var currencyDetails = result ? result.data.currency_details : {};

          if (currencyDetails.is_available === 1) {
            this.rootScopeJS.convertToLocalCurrency = true;
            this.rootScopeJS.localCurrencyCode = currencyDetails.currencyCode;
            this.rootScopeJS.localCurrencySymbol = this.currencySymbol[this.rootScopeJS.localCurrencyCode];
          } else {
            this.rootScopeJS.convertToLocalCurrency = false;
            this.rootScopeJS.localCurrencyCode = 'USD';
            this.rootScopeJS.localCurrencySymbol = this.currencySymbol[this.rootScopeJS.localCurrencyCode];
          }
          this.rootScopeJS.currencyAlreadyFounded = true;
          this.rootScopeJS.hideLoaderRootScope();
          if (this.rootScopeJS.currentState.name === 'filter' || this.rootScopeJS.currentState.name === 'package-slot-addons-summary' || this.rootScopeJS.currentState.name === 'adventure-detail' || this.rootScopeJS.currentState.name === 'adventure-detail-pre' || this.rootScopeJS.currentState.name === 'adventure-detail-private') {
            this.rootScopeJS.$broadcast('get-package-addons'); // now get package addons if page refresh in addons page
          }
        }, () => {
          this.rootScopeJS.hideLoaderRootScope();
        });
      },
      error: (xhr, error) => {
        this.rootScopeJS.hideLoaderRootScope();
      }
    });
  };

  closeNav() {
    if (document.getElementById('Navbar-Sidenav')) { document.getElementById('Navbar-Sidenav').style.right = '-100%'; }
  }

}

angular.module('heliApp').service("rootScopeService", downgradeInjectable(RootScopeService));
