import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-category-slider',
  templateUrl: '/ng-app/src/app/components/home/category-slider/category-slider.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/home/category-slider/category-slider.component.css']
})
export class CategorySliderComponent implements OnInit {

  @Input() homePagelogo: any = {};

  slickHeliPartnerConfig = {
    enabled: true,
    autoplay: false,
    draggable: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };

  constructor() { }

  ngOnInit(): void {
  }

}
