import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name PaymentService
 * @description
 * # paymentService
 * Service in the heliApp.
 */

@Injectable()

export class PaymentService {

  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  sendReq(method) {
    return new Promise((resolve, reject) => {
      method.then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })/* asynchronously complete request */
  }

  postReq(url, data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + url,
      data: data.id,
      headers: {
        'token': data.token,
        'card-token': data.card_token || '',
        'card-id': data.card_id  || '',
        'full-discount': data.full_discount || ''
      }
    };
    return req;
  }
  getReq(url, token?) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + url,
      headers: {
        'token': token
      }
    };
    return req;
  }
  // This is used to save consumer bank details for ACH Payment
  postReqSaveBankAccount(url, data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + url,
      headers: {
        'token': data.token,
        'bank-token': data.bank_token
      }
    };
    return req;
  }
  deleteReq(url, data) {
    let req = {
      method: 'DELETE',
      url: this.REQUEST_URL + url,
      headers: {
        'token': data.token
      }
    };
    return req;
  }
  /**
     * @ngdoc method
     * @name deleteSavedCard
     *
     * @description
     * This method call API to delete saved card
     **/
  deleteSavedCardReq(data) {
    let req = this.postReq('payments/delete-card', data);
    return this.webService.httpReq(req);
  }
  /**
     * @ngdoc method
     * @name saveCardId
     *
     * @description
     * This method call API save customer card id
     **/
  saveCardIdReq(data) {
    let req = this.postReq('payments/save-card', data);
    return this.webService.httpReq(req);
  }
  /**
     * @ngdoc method
     * @name changePrimaryCard
     *
     * @description
     * This method call API to change default card
     **/
  changePrimaryCardReq(data) {
    let req = this.postReq('payments/set-default-card', data);
    return this.webService.httpReq(req);
  }
  /**
     * @ngdoc method
     * @name getCardsList
     *
     * @description
     * This method call API get users saved cards list
     **/
  getCardsListReq(token) {
    let req = this.getReq('payments/get-cards', token);
    return this.webService.httpReq(req);
  }
  /**
     * @ngdoc method
     * @name updateCardDetailsReq
     *
     * @description
     * This method call API update user card details
     **/
  updateCardDetailsReq(data) {
    let req = this.postReq('payments/update-card', data);
    return this.webService.httpReq(req);
  }
  /**
     * @ngdoc method
     * @name makePaymentReq
     *
     * @description
     * This method call API make final payment for cart order
     **/
  makePaymentReq(data) {
    let req = this.postReq('payments/savePayment', data);
    return this.webService.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name makeFlywirePaymentReq
   *
   * @description
   * This method call API make final Flywire payment for cart order
   **/
  makeFlywirePaymentReq(data) {
    let req = this.postReq('payments/saveFlywirePayment', data);
    return this.webService.httpReq(req);
  }
  /**
     * @ngdoc method
     * @name sendEmailToOperatorsReq
     *
     * @description
     * This method call API to give operators notification of successful payment
     **/
  sendEmailToOperatorsReq(data) {
    let req = this.postReq('payments/sendOperatorEmail', data);
    return this.webService.httpReq(req);
  }
  saveCardId(data) { // service to save consumer's card
    return this.sendReq(this.saveCardIdReq(data));
  }

  getCardsList(token) { // service to get saved cards
    return this.sendReq(this.getCardsListReq(token));
  }

  deleteSavedCard(data) { // service to delete saved card
    return this.sendReq(this.deleteSavedCardReq(data));
  }

  changePrimaryCard(data) { // service to change default card
    return this.sendReq(this.changePrimaryCardReq(data));
  }

  updateCardDetails(data) { // service to update card details
    return this.sendReq(this.updateCardDetailsReq(data));
  }

  makePayment(data) { // make final payment
    return this.sendReq(this.makePaymentReq(data));
  }

  makeFlywirePayment(data) { // make final Flywire payment
    return this.sendReq(this.makeFlywirePaymentReq(data));
  }

  sendEmailToOperators(data) { // send emails to operators
    return this.sendReq(this.sendEmailToOperatorsReq(data));
  }
  /**
     * @ngdoc method
     * @name save consumer Bank Account Details
     *
     * @description
     * This method call API to save consumer Bank Account Details For ACH Payment
     **/
  saveBankAccountDetails(data) {
    let req = this.postReqSaveBankAccount('Achs/saveBank', data);
    return this.webService.httpReq(req);
  }
  /**
     * @ngdoc method
     * @name verify consumer Bank Account Details for ACH Payment
     *
     * @description
     * This method call API to veify consumer Bank Account Details for ACH Payment
     **/
  verifyBankAccountDetails(data) {
    let req = this.postReq('Achs/verifyBank', data);
    return this.webService.httpReq(req);
  }
  /**
     * @ngdoc method
     * @name get consumer Bank Account Details
     *
     * @description
     * This method call API to get consumer Bank Account Details
     **/
  getBankAccountDetails(token) {
    let req = this.getReq('Achs/getBanks', token);
    return this.webService.httpReq(req);
  }
  /**
     * @ngdoc method
     * @name delete consumer Bank Account Details
     *
     * @description
     * This method call API to delete consumer Bank Account Details
     **/
  deleteBankAccountDetails(data) {
    let req = this.deleteReq('Achs/deleteBank', data);
    return this.webService.httpReq(req);
  }
  /**
     * @ngdoc method
     * @name sendGuestForms
     *
     * @description
     * This method call API to link guest to guest-form links
     **/
  sendGuestForms(data) {
    let req = this.postReq('guest-forms/add-guest', data);
    return this.webService.httpReq(req);
  }

  /**
     * @ngdoc method
     * @name getFlyWireCountry
     *
     * @description
     * This method call API get country list
     **/
  getFlyWireCountry() {
    let req = this.getReq('masters/flyWireCountry');
    return this.webService.httpReq(req);
  }

  getPaymentIntent(config) {
    return this.webService.httpReq({
      method: 'POST',
      url: this.REQUEST_URL + 'payments/payment-intents',
      headers: {
        token: config.token,
        'card-token': config.card_token || '',
        'card-id': config.card_id  || '',
        'full-discount': config.full_discount || ''
      },
      data: config.id
    });
  }

  updatePaymentIntent(config) {
    return this.webService.httpReq({
      method: 'PATCH',
      url: this.REQUEST_URL + 'payments/payment-intent/' + config.id,
      headers: {
        token: config.token
      }
    });
  }


  convertPaymentIntentToPayment(config) {
    return this.webService.httpReq({
      method: 'POST',
      url: this.REQUEST_URL + 'payments/payment-intent/convert-payment-intent-to-payment/' + config.id,
      headers: {
        token: config.token,
        'card-token': config.data.card_token || '',
        'card-id': config.data.card_id  || '',
        'full-discount': config.data.full_discount || ''
      },
      data: config.data
    });
  }
}

angular.module('heliApp').service('paymentService', downgradeInjectable(PaymentService));
