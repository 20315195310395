/* eslint-disable no-undef */
/**
 * @ngdoc overview
 * @name heliApp
 * @description
 * # heliApp
 *
 * Constant Declaration of the application.
 */
var messages = {
  'password': {
    'CHANGE_PASSWORD_SUCCESSFULLY': 'Password Changed Successfully.',
    'PASSWORD_NOT_MATCH': 'Password and Confirm Password does not match.',
    'NEW_PASSWORD_BLANK': 'New password can not be blank.',
    'NEW_PASSWORD_CHARACTER_LIMIT': 'New password must be at least 8 characters long.',
    'OLD_PASSWORD_BLANK': 'Old password cannot be blank.'
  },
  'sharing': {
    'EMAIL_SEND_SUCCESSFULLY': 'Adventure has been shared successfully.',
    'EMAIL_REQUIRED': 'Please enter at least one email address.',
    'INCORRECT_EMAIL_FORMAT': 'Please enter a valid email id.',
    'DESCRIPTION_REQUIRED': 'Description is Required.',
    'SHORT_URL_REQUIRED': 'Short Url is Required.',
    'FACEBOOK_POST_SUCESSFULLY': 'Adventure has been shared successfully.',
    'FACEBOOK_ERROR_SUBMITTING': 'Error in Sharing Adventure.',
    'DESCRIPTION_MAXLENGTH': 'Please enter description  within 500 characters.',
    'FEED_POST_SHARE_SUCCESSFULLY': 'Post has been shared successfully.',
    'INVITE_SHARE_SUCCESSFULLY': 'Invitation has been shared successfully.'
  },
  'influencer_listing': {
    'MINIMUM_2_CHARACTERS_REQUIRED': 'Minimum 2 characters required.'
  },
  'follow_user': {
    'FOLLOW_ACCEPTED_MESSAGE': 'Accepted',
    'FOLLOW_DECLINED_MESSAGE': 'Declined',
    'FOLLOW_TOKEN_EXPIRE': 'Follow request expired. Please ask the user to resend the follow request.'
  },
  'feed': {
    'EDIT_FEED_SUCCESSFULLY': 'Post updated successfully.',
    'EDIT_FEED_TEXT_REQUIRED': 'Please enter the latest update.'
  },
  'coupon': {
    'date_expire_error': 'Coupon inactive. Please enter another promo code.',
    'my_limit_error': 'You have exhausted credit for this specific promocode. Please try another one.',
    'user_limit_error': 'Promocode currently not available. Please try another promocode.',
    'amount_minimum_error': 'The promo code you entered doesn\'t apply to this package.',
    'invalid_error': 'Invalid Promo Code.',
    'empty_error': 'Please enter a Promo Code.'
  }
};

var helper = {
  'INFLUENCER_COUNT_PER_PAGE': 24,
  'page_limit': 24,
  'COPY_RIGHT_YEAR': 2020,
  'NOT_FOUND': -1,
  'default_location': {
    latitude: 40.7068758,
    longitude: -74.01126479999999,
    address: 'New York Stock Exchange, New York, NY 10005, USA'
  },
  // "COPY_RIGHT_YEAR":new Date().getFullYear(),
  'twitterWidgetUrl': 'https://platform.twitter.com/widgets.js',
  'airportApiUrl': 'https://cometari-airportsfinder-v1.p.rapidapi.com/api/airports/by-radius',
  'googleMapApiUrl': 'https://maps.googleapis.com/maps/api/js?key=AIzaSyD_atMDLfEzxynR5iPmV0hXrSD3_0MIrE4&libraries=places',
  'share_on_heli': {
    'PACKAGE_SHARE': 1,
    'RADAR_SHARE': 2,
    'CART_SHARE': 3,
    'ORDER_SHARE_SUCCESS': 4,
    'OPERATOR_SELF_PACKAGE_SHARE': 5,
    'PACKAGE_SLOT_SHARE': 6
  },
  'page_name': {
    'FEED': 'feeds',
    'REFERRAL': 'referral'
  },
  'DEFAULT_HEAD_FAMILY': 'OSWALD',
  'DEFAULT_BODY_FAMILY': 'ROBOTO',
  'follow_user': {
    'REQUESTED_FOR_FOLLOW': 'requested',
    'FOLLOW_REQUEST_ACCEPTED': 'Accepted',
    'FOLLOW_REQUEST_DECLINED': 'Declined'
  },
  'analytics_filter': {
    'DATE_FORMAT': 'YYYY-MM-DD',
    'DATE_FORMAT_PAYOUT': 'YYYY/MM/DD',
    'DATE_FORMAT_DISPLAY': 'MMM D, YYYY'
  },
  'ambassador_referral': {
    'INVITE': 7,
    'PACKAGE': 8
  },
  'payment_const': {
    'full_payment': 1,
    'partial_payment': 2,
    'is_partial': 1,
    'is_full': 0,
    'default_currency': 'USD'
  },
  'PACKAGE_TYPE': {
    'PUBLIC': 1,
    'PRIVATE': 2
  },
  'PACKAGE_CASE': {
    'PER_SEAT': 1,
    'PER_ACCOMMODATION': 2,
    'PER_ENTIRE_PACKAGE': 3
  },
  'PACKAGE_CASE_NAME': {
    1: 'Seat Pricing',
    2: 'Accommodation Pricing',
    3: 'Flat Rate Pricing'
  },
  'EVENT_LODGING': {
    'YES': 1,
    'NO': 0
  },
  'USER_ROLE': {
    'CONSUMER': 1,
    'AMBASSADOR': 2,
    'OPERATOR': 3,
    'INFLUENCER': 4
  },
  'ON_HELI': 1,
  'OPERATOR_SITE': 2,
  'MANUAL': 3,
  'MANUAL_PAID': 4,
  'TEXTLIMIT': 1000,
  'SIXTY': 60,
  'TWENTYFOUR': 24,
  'TWENTYSIXTEEN': 2016,
  'SCROLL_FILTER_LIMIT': 5,
  'CALENDAR_EVENT_LIMIT': 5,
  'SCREEN': {
    'DESKTOP_WIDTH': 1280,
    'PACKAGE_NAME_LIMIT': 100,
    'MOBILE_WIDTH': 600,
    'SCROLL_HEIGHT': 200,
    'ACTIVITY_OFFSET': 575,
    'MOBILE': 568
  },
  'GUEST_TYPE': {
    'HELI_USER': 0,
    'OFFLINE': 1,
    'MANUAL_USER': 2
  },
  'EDITOR_CONFIG': {
    'basicEntities': false,
    'toolbarGroups': [
      { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
      { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
      { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
      { name: 'links', groups: [ 'links' ] }
    ],
    'removeButtons': 'Subscript,Superscript,Paste,PasteText,PasteFromWord,Copy,Cut,Styles,Font,Strike,RemoveFormat,Blockquote',
    'removePlugins': 'magicline,elementspath'
  },
  'DEFAULT_LATITUDE': 40.7068758,
  'DEFAULT_LONGITUDE': -74.01126479999999,
  'DEFAULT_LOCATION_NAME': 'New York Stock Exchange, New York, NY 10005, USA',
  'DEFAULT_ZOOM': 14,
  'API_RESPONSE': {
    'OK': '200',
    'CREATED': '201',
    'NO_CONTENT': '204',
    'UNAUTHERIZED': '401',
    'NOT_FOUND': '404',
    'UNPROCESSABLE': '422',
    'BAD_REQUEST': '400',
    'NO_DATA': '204',
    'NON_AUTHORITATIVE': '203',
    'MOVED_PERMANENTLY': '301'
  },
  'LOCATION': {
    'PRE_DEFINED': 1,
    'CUSTOM': 0
  },
  'MEDIA': {
    'VIDEO': 1,
    'IMAGE': 0,
    'NORMAL_VIDEO_URL': 5,
    'FEATURED_IMAGE': 1,
    'MAX_SIZE': 5000000, // IN BYTES
    'MIN_SIZE': 50000
  },
  'DEPOSIT_TYPE': {
    'PERCENTAGE': '1',
    'AMOUNT': '2'
  },
  'SEARCH_TIMEOUT': 300,
  'LOGO_MAX_SIZE': 2000000,
  'ZERO': 0,
  'MINUS_ONE': -1,
  'ONE': 1,
  'TWO': 2,
  'THREE': 3,
  'FOUR': 4,
  'SIX': 6,
  'FIVE': 5,
  'SEVEN': 7,
  'NINE': 9,
  'TEN': 10,
  'TWELVE': 12,
  'HUNDRED': 100,
  'TWOHUNDRED': 200,
  'FIVETHOUSAND': 5000,
  'KEYCODE': 110,
  'TEXTCHARLIMIT': 85,
  'EVENTLIMIT': 25,
  'LIST': {
    'SHOWN': 1,
    'SIZE': 12,
    'LIMIT': 24
  },
  'PACKAGE': {
    'MAX_GALLERY_IMAGES': 30,
    'MIN_SLOT_DAYS': 1,
    'MAX_SLOT_DAYS': 51,
    'MIN_CANCELLATION_DAYS': 1,
    'MAX_LODGING_IMAGES': 5,
    'DEACTIVATED': 2 // PACKAGE STATUS
  },
  is_save_exit: {
    'profile': 3,
    'gallery': 4,
    'policies': 5,
    'location': 6
  },
  'PROCESS_FEE': {
    'Ffixed': 0.30,
    'Fpercent': 0.035
  },
  'BOOKING_TYPE': {
    'PACKAGE_SLOTS': 1,
    'NIGHTLY_SLOTS': 2,
    'DAILY_RATE': 3,
    'SINGLE_DAY': 4
  },
  'BOOKING_TYPE_NAME': {
    1: 'Multiday package',
    2: 'Nightly rate',
    3: 'Daily rate',
    4: 'Single day',
  },
  'DISCOUNT_TYPE': {
    'IN_CURRENCY': 1,
    'IN_PERCENTAGE': 2
  },
  'QUESTOIN_TYPE': {
    'short_text': 1,
    'long_text': 2,
    'statement': 3,
    'multiple_choice': 4,
    'dropdown': 5
  },
  'QUESTION_ID_TEXT': {
    1: 'short_text',
    2: 'long_text',
    3: 'statement',
    4: 'multiple_choice',
    5: 'dropdown'
  },
  'AGENT_REQUEST_STATUS': {
    'ACCEPT': 1,
    'DEACTIVATE': 2,
    'REJECT': 3,
    'PENDING': 4,
    'INVITE': 5,
    'CANCEL': 6
  },
  'ROLLBAR_TOKEN': 'be3614eccc364341a060e53bcee48466',
  'FEATURED_ACTIVITY': [{ 'id': 1, 'slug': 'ski', 'image_url': 'https://s3.amazonaws.com/heli-staging/category_images/Ski%2BRide.jpg' }, { 'id': 2, 'slug': 'Kite', 'image_url': 'https://s3.amazonaws.com/heli-staging/category_images/Kite.jpg' }, { 'id': 3, 'slug': 'Surf', 'image_url': 'https://s3.amazonaws.com/heli-staging/category_images/Surf.jpg' }, { 'id': 4, 'slug': 'Fish', 'image_url': 'https://s3.amazonaws.com/heli-staging/category_images/Fish.jpg' }, { 'id': 6, 'slug': 'Bike', 'image_url': 'https://heli-staging.s3.amazonaws.com/category_images/Bike.jpg' }, { 'id': 5, 'slug': 'Dive', 'image_url': 'https://s3.amazonaws.com/heli-staging/category_images/Dive.jpg' }, { 'id': 9, 'slug': 'Safari', 'image_url': 'https://s3.amazonaws.com/heli-staging/category_images/Safari.jpg' }],
  'STRIPE': {
    'ACCOUNT_VERIFICATION_STATUS': {
      'INITIALIZED': 0,
      'PENDING': 1,
      'VERIFIED': 2,
      'REJECTED': 3
    },
    // https://stripe.com/docs/api/external_account_bank_accounts/object#account_bank_account_object-status
    'BANK_ACCOUNT_VERIFICATION_STATUS': {
      'NEW': 'new',
      'VALIDATED': 'validated',
      'VERIFIED': 'verified',
      'VERIFICATION_FAILED': 'verification_failed',
      'ERRORED': 'errored'
    }
  },
  OPERATOR_TAXES: {
    TYPE: {
      INTERNATIONAL: {
        ID: 1,
        NAME: 'international'
      },
      DOMESTIC: {
        ID: 2,
        NAME: 'domestic'
      }
    },
    CALCULATION_TYPE: {
      PERCENTAGE: {
        ID: 1,
        NAME: 'percentage'
      },
      AMOUNT: {
        ID: 2,
        NAME: 'amount'
      }
    }
  }
};
angular.module('heliApp').constant('MESSAGE_HELPER', messages);
angular.module('heliApp').constant('CONST_HELPER', helper);
