/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc directive
 * @name heliApp.directive:Feed Post
 * @description
 * # user can write feed and post it, can upload images and vedio.
 */

angular.module('heliApp')
  .directive('followerFollowingsShortList',['$state', '$timeout', function ($state, $timeout) {
    return {
      restrict: 'EA',
      templateUrl: 'views/followers-followings.html',
      scope: {
        followersList: '=',
        followingList: '=',
        state: '=',
        username: '=',
        firstName: '=',
        lastName: '=',
        profilePic: '=',
        otherUserProfile: '=',
        hideLeftPanel: '&hideLeftPanel',
        individualFeedPostId: '='
      },
      link: function ($scope, element, attr, ctrl) {
        var feedNavTabs = $('.location-tabs li');
        feedNavTabs.addClass("feed-disable-link");
        $timeout(function () {
          feedNavTabs.removeClass("feed-disable-link");
          $scope.brandsTop = $('.brands-bottom').offset().top;
        }, 1000);/// set scope value for brrand top
        $scope.checkProfile = function (user) {
          $state.go('myprofile', { myusername: user.username });
        };
      }
    };
  }])
  .directive('scroll',['$window', '$rootScope', '$timeout', function ($window, $rootScope, $timeout) { // scroll event on feeds page
    return function (scope, element, attrs) {
      if (($rootScope.currentState.name === 'user-public-feeds' || $rootScope.currentState.name === 'operator-public-feeds' ||
          ($rootScope.currentState.name === 'myprofile' && ($rootScope.userProfile.role_id === 2 || $rootScope.userProfile.role_id === 4)) ||
          ($rootScope.currentState.name === 'myprofile' && $rootScope.userProfile.role_id === 3 && ($rootScope.tab === 'feed' || $rootScope.tab === 'about')))) {
        angular.element($window).bind('scroll onload', function () {
          if ($rootScope.currentState.name === 'myprofile' && $rootScope.tab === 'about' && $('.activity-feeds-bar').innerHeight() > $('.feed-about-content').innerHeight()) {

          } else {
            var ele = element[0].getElementsByClassName('rightpanel-feeds')[0];
            var height = $(ele).find('.myProfile-sidebar.community-top')[0].clientHeight;
            // brand div top plus image height of brand image//985 = 870 + 115
            // 115 = brand div Height
            // 75 === header height
            if (scope.brandsTop) {
              if ((scope.brandsTop + 115 + 75 - screen.height) < $(window)[0].scrollY) {
                ele.classList.add('feed-sidebar-fixed');
              } else {
                ele.classList.remove('feed-sidebar-fixed');
              }
            } else {
              $timeout(function () {
                if ((scope.brandsTop + 115 + 75 - screen.height) < $(window)[0].scrollY) {
                  ele.classList.add('feed-sidebar-fixed');
                } else {
                  ele.classList.remove('feed-sidebar-fixed');
                }
              }, 1000);
            }
          }
        });
      }
      // }else{
      // //$rootScope.$on('feedLengthUpdate',function(event,feedlength){
      //   console.log(feedlength)
      //   if($rootScope.currentState.name === 'myprofile' && $rootScope.feedLength > 0){
      //   //if(feedlength > 0){
      //     angular.element($window).bind("scroll", function() {
      //        var ele = element[0].getElementsByClassName('rightpanel-feeds')[0]
      //        //brand div top plus image height of brand image//985 = 870 + 115
      //        if((scope.brandsTop + 115 - screen.height)  < $(window)[0].scrollY){
      //         ele.classList.add("feed-sidebar-fixed");
      //         }else{
      //         ele.classList.remove("feed-sidebar-fixed");
      //        }
      //     });
      //   //}
      //   }
      // }
      // });
    };
  }])
  .directive('summaryScroll',['$window', '$rootScope', '$timeout', function ($window, $rootScope, $timeout) { // scroll event on feeds page
    return function (scope, element, attrs) {
      angular.element($window).bind('scroll onload', function () {
        var ele = element;
        var cartDetailOffset = 155 - $(window)[0].scrollY;
        var isFixed = ele.hasClass('payment-summary-fixed');
        if (cartDetailOffset <= 90) {
          !isFixed ? ele.addClass('payment-summary-fixed') : null;
        } else {
          isFixed ? ele.removeClass('payment-summary-fixed') : null;
        }
      });
    }
  }]);
