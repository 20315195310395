/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:SearchCtrl
 * @description
 * # SearchCtrl
 * Controller of the heliApp
 */
// eslint-disable-next-line no-undef
angular.module('heliApp').controller('aboutUsCtrl', ['$scope', 'sharedService', function ($scope, sharedService) {
  $scope.showLoader();
  sharedService.getHeliTeam().then(function (res) {
    if (res.data.code === '200') {
      $scope.hideLoader();
      $scope.heliTeam = res.data.data;
      mergeData($scope.heliTeam);// find username and merge it with array
    } else if (res.data.code === '404') {
      $scope.hideLoader();
    }
  }, function () {
    $scope.hideLoader();
  });
  $scope.HeliTeamMembers = [
    { name: 'Brock Strasbourger', role: 'Co-Founder', image: 'images/Brock-Strasbourger.jpg', username: '' },
    { name: 'Andy Culp', role: 'Founder & CEO', image: 'images/Andy-Culp.jpg', username: '' },
    { name: 'Brandon Darnton', role: 'Director of Partnerships', image: 'images/Brandon-Darnton.jpg', username: '' },
    { name: 'Matt Glazier', role: 'Sales', image: 'images/Matt-Glazier.jpg', username: '' },
    { name: 'Clark Winter', role: 'Growth', image: 'images/Clark-Winter.jpg', username: '' },
    { name: 'Ethan Fischer', role: 'Product', image: 'images/Ethan-Fischer.jpg', username: '' }
  ];
  function mergeData (team) {
    team.map(function (val, key) {
      var role = val.business_role;
      for (var i = 0; i < team.length; i++) {
        if ($scope.HeliTeamMembers[i].role === role) {
          $scope.HeliTeamMembers[i].username = val.username;
          break;
        }
      }
    });
  }
}]);
