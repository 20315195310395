'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:followingListingCtrl
 * @description
 * # followingListingCtrl
 * Controller of the heliApp
 */

// eslint-disable-next-line no-undef
angular.module('heliApp').controller(
  'followingListingCtrl',
  [
    '$scope',
    '$state',
    '$rootScope',
    'sharedService',
    'userSocialProfileService',
    'CONST_HELPER',
    '$timeout',
    '$stateParams',
    '$location',
    function ($scope, $state, $rootScope, sharedService, userSocialProfileService, CONST_HELPER, $timeout, $stateParams, $location) {
      // $rootScope.isUserLoggedIn(); // check is user Logged In or not
      $scope.followingList = [];
      $scope.noRecordFound = false;
      var listLimit = CONST_HELPER.page_limit;
      if ($rootScope.previousState && $rootScope.previousState.name === 'myprofile') {
        $stateParams.back = 1;
        $location.search($stateParams);
      }
      var back = $stateParams.back ? $stateParams.back : 0 || $stateParams.page > 1 ? 1 : 0;
      $scope.getfollowingList = function (type) { // get operator list for displaing operator
        $scope.showLoader();
        var requestObject = {
          'page': $stateParams.page,
          'limit': listLimit,
          'back': back,
          'user_id': $stateParams.other_userId
        };
        userSocialProfileService.getFollowingListing(requestObject, $scope.user.token).then(function (response) { // call opearot listing api
          if (response.data.code === '200') {
            $scope.followingCount = response.data.numFound;
            $scope.followingList = $scope.followingList.concat(response.data.following_details);

            if ($scope.followingList.length === 0) { // Show record not found messgae
              $scope.noRecordFound = true;
            } else {
              $scope.noRecordFound = false;
            }
            $timeout(function () { // scroll to previous position.
              if ($rootScope.followingListingOffset > 500 && $rootScope.previousState && ($rootScope.previousState.name === 'myprofile')) {
                // eslint-disable-next-line no-undef
                angular.element('html, body').animate({ scrollTop: $rootScope.followingListingOffset }, 1000);
              }
            }, 10);

            // more following Shown on following list Page
            $scope.isLoadMoreButton = ((response.data.numFound - $scope.followingList.length) > 0);
            $scope.resCount = response.data.numFound;
            $scope.hideLoader();
          } else if (response.data.code === '404') {
            $scope.isLoadMoreButton = false;
            $scope.hideLoader();
          }
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };
      $scope.getfollowingList();
      $scope.followOperator = function (userId, index, followUser) { // to follow follower
        $scope.showLoader();
        var data = {
          'follower_id': userId,
          'token': $scope.user.token
        };
        if (followUser) { // user wants to follow influencer
          sharedService.followUser(data).then(function (res) {
            switch (res.data.code) {
              case '200' :
                $scope.followingList[index].is_following = followUser;
                break;
              case '201' : // user has requested to follow private profile user
                $scope.followingList[index].is_following = CONST_HELPER.follow_user.REQUESTED_FOR_FOLLOW;
                break;
              default :
                $scope.hideLoader();
                $scope.showPopup('#serverErrModal');
                break;
            }
            $scope.hideLoader();
          }, function () {
            $scope.hideLoader(); $scope.showPopup('#serverErrModal');
          });
        } else { // user wants to unfollow influencer
          sharedService.unFollowUser(data).then(function (res) {
            switch (res.data.code) {
              case '200' :
                $scope.followingList[index].is_following = followUser;
                break;
              default :
                $scope.hideLoader();
                $scope.showPopup('#serverErrModal');
                break;
            }
            $scope.hideLoader();
          }, function () {
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
        }
      };

      $scope.loadMore = function () {
        back = 0;
        $stateParams.page = Number($stateParams.page) + 1;
        // eslint-disable-next-line no-self-assign
        $stateParams.other_userId = $stateParams.other_userId;
        $location.search($stateParams); // update the url is sorting is applied
      };

      $rootScope.pageChanged = function (data) {
        if (data === 'pageIncreased') {
          back = 0;
          $scope.getfollowingList();
        } else if (data === 'pageDecreased') {
          $scope.followingList = $scope.followingList.slice(0, $rootScope.currentPage * listLimit);
          $scope.isLoadMoreButton = (($scope.resCount - $scope.followingList.length) > 0);
        }
      };
    }
  ]
);
