/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:operatorPackageDetailCtrl
 * @description
 * # operatorPackageDetailCtrl
 * Controller of the heliApp
 */

angular.module('heliApp').controller('operatorPackageDetailCtrl',['$scope', '$sce', '$stateParams', 'userAdventureDetailService', '$location', '$anchorScroll', '$rootScope', 'GOOGLE_API_KEY', 'rootScopeService', operatorPackageDetailCtrl]);

function operatorPackageDetailCtrl ($scope, $sce, $stateParams, userAdventureDetailService, $location, $anchorScroll, $rootScope, GOOGLE_API_KEY, rootScopeService) {
  var vm = this;
  vm.adventureDetails = [];
  var adventurePackage = {
    package_id: $stateParams.package_id,
    userToken: $scope.user.token
  };
  var featuredImageElement = angular.element(document.getElementById('banner-image'));

  // check user is logged in or not...
  vm.isUserLoggedIn = !!$scope.user.token;
  vm.activeTab = 'activities';
  vm.selectedSlots = [];
  vm.googleApiKey = GOOGLE_API_KEY;
  vm.galleryOpened = false;

  if ($scope.user.role_id === 3) {
    $rootScope.localCurrencyCode = '';
    getAdventureDetails(); // call getAdventureDetails(); initially for operator...
  } else {
    getAdventureDetails();
  }

  function getAdventureDetails () {
    $scope.showLoader();
    adventurePackage.currency_code = $rootScope.localCurrencyCode ? $rootScope.localCurrencyCode : '';
    userAdventureDetailService.getAdventureDetails(adventurePackage).then(function (res) {
      vm.adventureDetails = res.data.package;
      getFeaturedImage(res.data.package.package_medias, featuredImageElement);
      $scope.checkExpiredSlots([res.data.package]); // check for expired slots...
      adventureVideoFirst(res.data.package.package_medias); // make adventure video position first...
      rootScopeService.updateMetaTitle(vm.adventureDetails.name); // set dynamic title and description of the page.
      rootScopeService.updateMetaDescription(vm.adventureDetails.description);
      $scope.hideLoader();
    }, function () {
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
  }

  // get the featured image from package media
  function getFeaturedImage (media, element) {
    var featuredimage;
    media.map(function (obj) {
      if (obj.media_type === 0 && obj.is_main === 1) {
        featuredimage = obj.url;
      }
    });
    element[0].style.backgroundImage = 'url(' + featuredimage + ')';
  }

  // make adventure video position first...
  function adventureVideoFirst (packageMedias) {
    var packageVideos = []; var packageImages = [];
    packageMedias.map(function (media, key) {
      if (media.media_type === 1) {
        packageVideos.push(media);
      } else {
        packageImages.push(media);
      }
    });
    vm.adventureDetails.package_medias = packageVideos.concat(packageImages);
  }

  // view all slots
  vm.viewAllSlots = function (adventure) {
    vm.adventure = adventure;
  };

  vm.trustAsHtml = function (string) {
    return $sce.trustAsHtml(string);
  };

  $scope.itinerary_limit = 7;
  vm.showLess = false;
  $scope.loadMoreItinerary = function () {
    vm.showLess = !vm.showLess;
    if (vm.showLess) {
      $scope.itinerary_limit = vm.adventureDetails.package_day_details.length;
    } else {
      $scope.itinerary_limit = 7;
    }
  };
  $scope.openGallery = function () {
    vm.galleryOpened = true;
  };
}
