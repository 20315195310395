'use strict';

/**
 * @ngdoc overview
 * @name heliApp
 * @description
 * # heliApp
 *
 * Main module of the application.
 */

var heliApp = angular.module('heliApp', [
  'ui.router',
  'ui.router.upgrade',
  'ui.router.state.events',
  'ngFileUpload',
  'daterangepicker',
  'ui.select',
  'ngSanitize',
  'ngMessages',
  'ngMap',
  'vsGoogleAutocomplete',
  'ngParallax',
  'socialLogin',
  'angular-img-cropper',
  'ngWYSIWYG',
  'ngCookies',
  'angularPayments',
  'ngTagsInput',
  'angular-circular-progress',
  'ngCsv',
  'duParallax',
  'ui.bootstrap',
  'ngScrollbars',
  'uiCropper',
  'infinite-scroll',
  'slickCarousel',
  'ngTextTruncate',
  'chart.js',
  'ui.calendar',
  'ui.sortable',
  'ng.ckeditor',
  'angularjs-dropdown-multiselect',
  'colorpicker.module',
  'tandibar/ng-rollbar'
]);

heliApp.config(['RollbarProvider', 'CONST_HELPER', function (RollbarProvider, CONST_HELPER) {
  var hostName = location.hostname;
  if (hostName != 'localhost') {
    RollbarProvider.init({
      accessToken: CONST_HELPER.ROLLBAR_TOKEN,
      captureUncaught: true,
      scrubTelemetryInputs: true,
      payload: {
        environment: hostName
      }
    });
  }
}]);

heliApp.config(['$httpProvider', function ($httpProvider) {
  $httpProvider.defaults.useXDomain = false;
  $httpProvider.defaults.withCredentials = false;
  $httpProvider.interceptors.push();
  $httpProvider.interceptors.push(['$q', '$injector', function ($q, $injector) {
    return {
      'response': function (response) {
        // Will only be called for HTTP up to 300
        return response;
      },
      'responseError': function (rejection) {
        var statusCodeHandler = {
          500: 'serverError',
          401: 'invalidAuthToken',
          403: 'invalidAuthToken',
          400: 'invalidCredentials',
          404: 'recordNotFound'
        };
        $injector.get('$rootScope').$broadcast(statusCodeHandler[rejection.status], rejection);
        return $q.reject(rejection);
      }
    };
  }]);

  // initialize get if not there
  if (!$httpProvider.defaults.headers.get) {
    $httpProvider.defaults.headers.get = {};
  }
  // disable IE ajax request caching
  var origin = window.origin || window.location.origin;
  if (origin !== 'http://localhost') {
    $httpProvider.defaults.headers.get['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
    $httpProvider.defaults.headers.get['Cache-Control'] = 'max-age=2592000';
    $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
  }
}]);
heliApp.config(['socialProvider', 'GOOGLE_KEY', 'LINKEDIN_KEY', 'FB_KEY', 'STRIPE_KEY', '$compileProvider', function (socialProvider, GOOGLE_KEY, LINKEDIN_KEY, FB_KEY, STRIPE_KEY, $compileProvider) {
  // var location = window.location;
  socialProvider.setGoogleKey(GOOGLE_KEY);// qa
  socialProvider.setLinkedInKey(LINKEDIN_KEY);
  socialProvider.setFbKey({ appId: FB_KEY.appId, apiVersion: FB_KEY.apiVersion }); // qa
  // window.Stripe.setPublishableKey(STRIPE_KEY);
  // Stripe(STRIPE_KEY)
  // stripeProvider.setPublishableKey(STRIPE_KEY);
  $compileProvider.debugInfoEnabled(false);
}]);

heliApp.run(['$rootScope', '$location', '$state', '$window', '$timeout', '$anchorScroll', 'CONST_HELPER', 'CURRENT_HOST', function ($rootScope, $location, $state, $window, $timeout, $anchorScroll, CONST_HELPER, CURRENT_HOST) {
  var locationUrl = $location.url();
  if (locationUrl.indexOf('createAdventure') > -1 || locationUrl.indexOf('operator-basic-profile') > -1) { // redirect url to main tab
    var location = window.location;
    var url = location.origin + location.pathname;
    if ((location.search).indexOf('package_id') > -1) { // for edit package
      url = url + location.search;
    }
    $window.history.pushState(null, 'any', url);
  }
  $rootScope.$on('$stateChangeStart', function (e, toState, toParams, fromState, fromParams) {
    fromState.params = fromParams;
    $state.previous = fromState;
    var hostName = $location.host();
    if(!CURRENT_HOST.includes(hostName) && !$rootScope.isAgent && !hostName.startsWith('localhost')) {
      $rootScope.isSubDomain = true;
    } else {
      $rootScope.isSubDomain = false;
    }

    $rootScope.screenWidth = $window.innerWidth; // get window width for left navigation for operator
    if ($rootScope.screenWidth <= CONST_HELPER.SCREEN.DESKTOP_WIDTH) {
      $rootScope.menuBarShow = true;
    } else {
      $rootScope.menuBarShow = false;
    }
    $rootScope.currentState = toState;
    $rootScope.previousState = fromState;
    $rootScope.$broadcast('state-change');
  });
  $rootScope.$on('$stateChangeSuccess', function (e, toState, toParams, fromState, fromParams) {
    if (toState.name === 'home' && (fromState.name === 'ambassadors-listing' || fromState.name === 'operators-listing' || fromState.name === 'user-listing' || fromState.name === 'privacy-policy' || fromState.name === 'terms-conditions' || fromState.name === 'about-us')) {
      $timeout(function () {
        if ($rootScope.homeOffset > 500) {
          angular.element('html, body').animate({ scrollTop: $rootScope.homeOffset }, 1000);
        }
      }, 500);
    }
    $anchorScroll();
  });
  $rootScope.$on('$locationChangeSuccess', function (newLocation, oldLocation) {
    if ($location.path() === '/followers-listing' || $location.path() === '/following-listing' || $location.path() === '/ambassadors-listing' || $location.path() === '/user-listing') {
      $rootScope.lastPage = angular.copy($rootScope.currentPage);
      $rootScope.currentPage = $location.search().page ? $location.search().page : 1;
      if ($rootScope.lastPage > $rootScope.currentPage) {
        $rootScope.pageChanged('pageDecreased');
      } else if ($rootScope.currentPage > $rootScope.lastPage) {
        $rootScope.pageChanged('pageIncreased');
      } else {
        $timeout(function () {
          $rootScope.pageChanged('searchChanged');
        }, 500);
      }
    }
  });
  $rootScope.$on('addEventToGTM', function (event, data) {
    if ($window.dataLayer) {
      $window.dataLayer.push(data);
    }
  });

  // Enhanced Ecommerce
  $rootScope.$on('addEventToEC', function (data) {
    if (ga) {
      switch (data.event_type) {
        case 'packageView':
          ga('heliGAtracker.ec:addProduct', data);
          ga('heliGAtracker.ec:setAction', 'detail');
          ga('heliGAtracker.send', 'pageview'); // Send product details view with the initial pageview.
          break;

        case 'addToCart':
          ga('heliGAtracker.ec:addProduct', data);
          ga('heliGAtracker.ec:setAction', 'add');
          ga('heliGAtracker.send', 'event', 'UX', 'click', 'gtm.heli.addtocart'); // Send data using an event.
          break;

        case 'proceedToCheckout':

          ecAddProduct(data); // add product to ecommerce...

          // specify a checkout step and option.
          ga('heliGAtracker.ec:setAction', 'checkout', {
            'step': 1, // A value of 1 indicates this action is first checkout step.
            'option': 'Proceed To Checkout' // Used to specify additional info about a checkout stage, e.g. payment method.
          });
          ga('heliGAtracker.send', 'pageview'); // Send Pageview

          break;

        case 'ProceedToPayment':

          ecAddProduct(data); // add product to ecommerce...

          // specify a checkout step and option.
          ga('heliGAtracker.ec:setAction', 'checkout', { 'step': 2 });
          ga('heliGAtracker.send', 'pageview'); // Send Pageview
          break;

        case 'orderSuccessfull':

          ga('heliGAtracker.set', 'currencyCode', data.currencyCode); // Set tracker currency to payment currency.

          ecAddProduct(data); // add product to ecommerce...

          // specify a checkout step and option.
          ga('heliGAtracker.ec:setAction', 'checkout', { 'step': 3 });
          ga('heliGAtracker.send', 'pageview'); // Send Pageview
          break;

        case 'addTransaction':
          // get the coupon applied on cart...
          var coupon = data.items[0].promo_code ? data.items[0].promo_code : '';

          ecAddProduct(data); // add product to ecommerce...

          var order = data.order_detail;
          ga('heliGAtracker.ec:setAction', 'purchase', { // Transaction details are provided in an actionFieldObject.
            'id': order.order_id, // (Required) Transaction id (string).
            'affiliation': 'Heli - Online', // Affiliation (string).
            'revenue': order.grandTotal, // Revenue (currency).
            'tax': order.total_tax, // Tax (currency).
            'shipping': order.total_processing_fee, // Shipping (currency).
            'coupon': coupon
          });

          ga('heliGAtracker.send', 'pageview'); // Send Pageview
          break;

        case 'signupComplete':
          ga('heliGAtracker.send', {
            hitType: data.hitType,
            eventCategory: data.eventCategory,
            eventAction: data.eventAction,
            eventLabel: data.eventLabel
          });
          break;
      }
    }
  });
  /*
      function to add product data into enhanced ecommerce...
    */
  function ecAddProduct (data) {
    for (var i = 0; i < data.items.length; i++) {
      var product = data.items[i];
      ga('heliGAtracker.ec:addProduct', {
        'id': product.package.id,
        'name': product.package.name,
        'category': CONST_HELPER.PACKAGE_CASE_NAME[product.package.package_case],
        'brand': product.operator_profile.business_name,
        'price': product.base_amount,
        'quantity': product.total_guests || product.slot_adult_quantity
      });
    }
  }

  $rootScope.$on('googleTagManagerAddToCart', function (event, data) {
    if (typeof gtag === 'undefined') {
      return;
    }

    // Send Google Tag
    gtag('event', 'add_to_cart', data);
  });

  $rootScope.$on('googleTagManagerProceedToCheckout', function (event, data) {
    if (typeof gtag === 'undefined') {
      return;
    }

    // Send Google Tag
    gtag('event', 'begin_checkout', data);
  });

  $rootScope.$on('googleTagManagerPurchase', function (event, data) {
    if (typeof gtag === 'undefined') {
      return;
    }

    // Send Google Tag
    gtag('event', 'purchase', data);
  });
}]);
