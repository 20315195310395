import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name OperatorPackageListService
 * @description
 * # operatorPackageListService
 * Service in the heliApp.
 */

@Injectable()

export class OperatorPackageListService {

  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  sendReq(method) {
    return new Promise((resolve, reject) => {
      method.then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    }) /* asynchronously complete request */
  }

  /**
         * @ngdoc method
         * @name changePackageStatus
         *
         * @description
         * This method call API to apply for change Package Status
     */

  changePackageStatusReq(data) {
    var req = {
      method: 'POST',
      url: this.REQUEST_URL + 'operators/changePackageStatusSingle',
      headers: {
        'Content-Type': 'application/json',
        'token': data.token
      },
      data: data
    };
    return this.webService.httpReq(req);
  }

  /**
     * @ngdoc method
     * @name changeBulkPackageStatus
     *
     * @description
     * This method call API to apply for change Bulk Package Status
     */

  changePackageStatusBulkReq(data) {
    var req = {
      method: 'POST',
      url: this.REQUEST_URL + 'operators/changePackageStatusBulk',
      headers: {
        'Content-Type': 'application/json',
        'token': data.token
      },
      data: data
    };
    return this.webService.httpReq(req);
  }

  /**
     * @ngdoc method
     * @name updatePackagePublishDate
     *
     * @description
     * This method call API to apply for update Package Publish Date
     */

  updatePackagePublishDateReq(data) {
    var req = {
      method: 'POST',
      url: this.REQUEST_URL + 'packages/updatePackageDate',
      headers: {
        'Content-Type': 'application/json',
        'token': data.token
      },
      data: data
    };
    return this.webService.httpReq(req);
  }

  /**
     * @ngdoc method
     * @name updatePackageSlot
     *
     * @description
     * This method call API to apply for update package slot
     */

  updatePackageSlotReq(data) {
    var req = {
      method: 'POST',
      url: this.REQUEST_URL + 'packages/update-package-slot',
      headers: {
        'Content-Type': 'application/json',
        'token': data.token
      },
      data: data
    };
    return this.webService.httpReq(req);
  }

  /**
     * @ngdoc method
     * @name deletePackageReq
     *
     * @description
     * This method call API to apply Delete package.
     */

  deletePackageReq(data) {
    var req = {
      method: 'POST',
      url: this.REQUEST_URL + 'operator-packages/deletePackage',
      headers: {
        'Content-Type': 'application/json',
        'token': data.token
      },
      data: data
    };
    return this.webService.httpReq(req);
  }

  getOperatorPackages(data) { // service to get operator packages
    return this.sendReq(this.webService.getOperatorPackages(data));
  }

  getOperatorPackagesSlots(data) { // service to get operator packages slots
    return this.sendReq(this.webService.getOperatorPackagesSlots(data));
  }

  clonePackage(data) { // clone package api
    return this.sendReq(this.webService.clonePackage(data));
  }

  changePackageStatus(data) { // change package status
    return this.sendReq(this.changePackageStatusReq(data));
  }

  changePackageStatusBulk(data) { // change package status active or deactive with bulk of data
    return this.sendReq(this.changePackageStatusBulkReq(data));
  }

  updatePackagePublishDate(data) { // update package publish date
    return this.sendReq(this.updatePackagePublishDateReq(data));
  }

  updatePackageSlot(data) { // update package slot
    return this.sendReq(this.updatePackageSlotReq(data));
  }

  deletePackage(data) { // delete the package which has no booking done...
    return this.sendReq(this.deletePackageReq(data));
  }
}

angular.module('heliApp').service('operatorPackageListService', downgradeInjectable(OperatorPackageListService));

