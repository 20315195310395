import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';
/**
 * @ngdoc service
 * @name OperatorAgentService
 * @description
 * # operatorAgentService
 * Service in the heliApp.
 */

@Injectable()

export class OperatorAgentService {

  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  sendReq(method, param, token) {
    return new Promise((resolve, reject) => {
      method(param, token).then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    }) /* asynchronously complete request */
  }
  postReq(url, data, token) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + url,
      data: data,
      headers: {
        'token': token
      }
    };
    return req;
  }
  getReq(url, token) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + url,
      headers: {
        'token': token
      }
    };
    return req;
  }
  deleteReq(url, data, token) {
    let req = {
      method: 'DELETE',
      url: this.REQUEST_URL + url,
      data: data,
      headers: {
        'token': token
      }
    };
    return req;
  }
  /**
   * @ngdoc method
   * @name agentSignUpRequest
   *
   * @description
   * This method call API to get operator agent requests
   **/
  getOperatorAgentRequests(token) {
    let req = this.getReq('operator-agents/new-request', token);
    return this.webService.httpReq(req);
  }
  acceptRejectAgents(data, token) {
    let req = this.postReq('operator-agents/accept-reject', data, token);
    return this.webService.httpReq(req);
  }
  getOperatorAgents(status, token, data) {
    let req = this.postReq('operator-agents/all-agents/' + status, data, token);
    return this.webService.httpReq(req);
  }
  getAllAgentInventory(token) {
    let url = 'OperatorAgentPackages/allAgentPackages';
    let req = this.getReq(url, token);
    return this.webService.httpReq(req);
  }
  getAgentInventory(token, agent_id) {
    let url = 'OperatorAgentPackages/agentPackages?operator_agent_id=' + agent_id;
    let req = this.getReq(url, token);
    return this.webService.httpReq(req);
  }
  updateAllAgentInventory(data, token) {
    let url = 'OperatorAgentPackages/updateAllAgentPackages';
    let req = this.postReq(url, data, token);
    return this.webService.httpReq(req);
  }
  updateAgentInventory(data, token) {
    let url = 'OperatorAgentPackages/updateAgentPackages';
    let req = this.postReq(url, data, token);
    return this.webService.httpReq(req);
  }
  editAgentCommission(data, token) {
    let req = this.postReq('operator-agents/edit-commission', data, token);
    return this.webService.httpReq(req);
  }
  getAnalyticsByAgentId(data) {
    let req = this.getReq('operator-agents/analytics?fr=' + data.fromDate + '&to=' + data.toDate + '&agent_id=' + data.agent_id, data.token);
    return this.webService.httpReq(req);
  }
  getAllAgentAnalytics(data) {
    let req = this.getReq('operator-agents/analytics?fr=' + data.fromDate + '&to=' + data.toDate, data.token);
    return this.webService.httpReq(req);
  }
}

angular.module('heliApp').service('operatorAgentService', downgradeInjectable(OperatorAgentService));