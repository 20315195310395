import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SharedService } from '../../../../../../app/scripts/services/sharedService';
import { helper } from '../../../../../../app/scripts/helper';
import { LoaderService } from '../../../../../../ng-app/src/app/services/loader/loader.service';
import * as JQuery from 'jquery'
import * as bootstrap from "bootstrap";

@Component({
  selector: 'app-updates',
  templateUrl: '/ng-app/src/app/components/home/updates/updates.component.html',
  styleUrls: ['../../../../../../ng-app/src/app/components/home/updates/updates.component.css']
})
export class UpdatesComponent implements OnInit {

  public heliUpdatefrm: FormGroup
  emailErr: boolean;
  visiterSubscribed: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
    private loaderService: LoaderService
    ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.heliUpdatefrm = this.formBuilder.group({
      fname: ['', Validators.compose([Validators.required])],
      lname: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, this.validEmail()])]
    });
  }

  public validEmail() {
    return function (control: FormControl) {
      let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(control.value)) {
        return null
      } else {
        return ({restrictedText: 'Email is invalid.'})
      }
    }
  }

  heliUpdateSignUp(form) {
    if (this.heliUpdatefrm.controls.email.pristine || this.heliUpdatefrm.controls.email.invalid || form.email === '') { // check for required validaiton of the email
      this.emailErr = true;
    } else {
      this.loaderService.showLoader();
      this.emailErr = false;
      this.sharedService.subscribeHeliUpdate(form).then((res: any) => {
        if (res.data.code === helper.API_RESPONSE.OK) {
          this.visiterSubscribed = true;
        }
        this.loaderService.hideLoader();
      }, (error) => {
        this.loaderService.hideLoader();
        this.showPopup('#serverErrModal');
      });
    }
  };

  showPopup = (modalId) => { // show the popup
    $(modalId).modal('show');
  };

}
