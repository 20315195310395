import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name OperatorManualPaymentService
 * @description
 * # operatorManualPaymentService
 * Service in the heliApp.
 */

@Injectable()

export class OperatorManualPaymentService {

  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  sendReq(method, param, token) {
    return new Promise((resolve, reject) => {
      method(param, token).then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    }) /* asynchronously complete request */
  }

  postReq(data, token, url) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + url,
      data: data,
      headers: {
        'token': token
      }
    };
    return this.webService.httpReq(req);
  }
  getReq(data, url) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + url,
      headers: {
        'token': data.token
      }
    };
    return this.webService.httpReq(req);
  }
  // This is used to manual payment by card of operator
  postReqMakeManualPayment(url, data) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + url,
      data: { user_id: data.user_id },
      headers: {
        'token': data.token,
        'card-token': data.card_token
      }
    };
    return req;
  }

  /**
         * @ngdoc method
         * @name saveOperatorGuestDetails
         *
         * @description
         * This method call API to save operator guest details
         **/
  saveOperatorGuestDetails(data, token) {
    return this.postReq(data, token, 'ManualPayments/saveManualPaymentUser');
  }

  /**
         * @ngdoc method
         * @name makeManualPaymentByCard
         *
         * @description
         * This method call API to make operator guest payment by card details
         **/
  makeManualPaymentByCard(data) {
    let req = this.postReqMakeManualPayment('ManualPayments/saveManualPayment', data);
    return this.webService.httpReq(req);
  }

  /**
         * @ngdoc method
         * @name getManualPaymentList
         *
         * @description
         * This method call API to get the list of all manual payments
         **/
  getManualPaymentList(data) {
    let Url;
    let pageNumber = data.page;
    if (data.keyword) {
      Url = this.REQUEST_URL + 'ManualPayments?page=' + pageNumber + '&keyword=' + data.keyword;
    } else {
      Url = this.REQUEST_URL + 'ManualPayments?page=' + pageNumber;
    }
    let req = {
      method: 'GET',
      url: Url,
      headers: {
        'token': data.token,
        'Content-Type': 'application/json'
      },
      data: data.page
    };
    return this.webService.httpReq(req);
  }

  getList(token, params, responseType) {
    let notEmptyParams = {};
    for (let key in params) {
      if (
        (key !== 'token')
        &&
        params[key]
      ) {
        notEmptyParams[key] = params[key];
      }
    }

    let httpReqConfig:any = {
      method: 'GET',
      url: this.REQUEST_URL + 'ManualPayments',
      params: notEmptyParams
    };

    let headers:any = { token: token };
    responseType = responseType || 'json';
    if (responseType === 'csv') {
      headers.Accept = 'text/csv';
      httpReqConfig.responseType = 'blob';
    }

    httpReqConfig.headers = headers;

    return this.webService.httpReq(httpReqConfig);
  }
}


angular.module('heliApp').service('operatorManualPaymentService', downgradeInjectable(OperatorManualPaymentService));
