'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:EmailVerificationCtrl
 * @description
 * # EmailVerificationCtrl
 * Controller of the heliApp
 */
// eslint-disable-next-line no-undef
angular.module('heliApp')
  .controller('EmailVerificationCtrl', [ '$scope', '$stateParams', '$rootScope', 'userRegisterService', '$state', function ($scope, $stateParams, $rootScope, userRegisterService, $state) {
    $scope.getTokenStatus = function () {
      if ($rootScope.currentState.name === 'emailVerification') { // check for valid token to show email verification
        checkForValidTokenEmail($stateParams.token);
      }
    };

    function checkForValidTokenEmail (token) {
      var emailToken = { 'email_verification_token': token };
      userRegisterService.checkForValidTokenEmail(emailToken).then(function (response) { // post to the server the token for email verification
        if (response.data) {
          if (response.data.code !== '200') { // some error exits
            $state.go('link-expire');// move to link expire page
          } else {
            $scope.emailVerified = true;
          }
        }
      }, function (_error) { // check for error
        $scope.showPopup('#serverErrModal');
      });
    }
  }]);
