/* eslint-disable no-useless-escape */
/* eslint-disable no-redeclare */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc filter
 * @name heliApp.formatDate
 * @description
 * # create a new date object
 * Filter in the heliApp.
 */
angular.module('heliApp')
  .filter('fromatDate', ['$sce', function ($sce) {
    return function (date) {
      return new Date(date);
    };
  }])
  .filter('titlecase', function () {
    return function (s) {
      s = (s === undefined || s === null) ? '' : s;
      return s.toString().toLowerCase().replace(/\b([a-z])/g, function (ch) {
        return ch.toUpperCase();
      });
    };
  }).filter('removeSpace', function () {
    return function (s) {
      s = (s === undefined || s === null) ? '' : s;
      return s.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
    };
  }).filter('spaceless', function () {
    return function (input) {
      if (input) {
        return input.replace(/\s+/g, '-');
      }
    };
  }).filter('range', function () {
    return function (input, total) {
      total = parseInt(total) + 1;

      for (var i = 0; i < total; i++) {
        input.push(i);
      }
      return input;
    };
  }).filter('guestRange', function () {
    return function (input, total, max_guest, max_private_guests) {
      total = parseInt(total) + 1;
      for (var i = 1; i < total; i++) {
        var obj = { 'guest': i, 'label': i + ' Guest (private)', 'type': 'private' };
        if(max_private_guests > (i - 1) ){
          input.push(obj);
        }
        var obj = { 'guest': i, 'label': i + ' Guest', 'type': 'shared' };
        input.push(obj);
      }
      return input;
    };
  }).filter('changeDateObject',['$filter', function ($filter) {
    return function (dateobj) {
      if (dateobj) {
        dateobj = dateobj.toString();
        var changedDate = dateobj.replace(/-/g, '\/').replace(/T.+/, '');
        return new Date(changedDate);
      }
    };
  }]);
