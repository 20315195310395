/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc directive
 * @name heliApp.directive:scrollOnClick
 * @description
 * #scroll the page to particular div...
 */
angular.module('heliApp')
  .directive('scrollOnClick', function () {
    return {
      restrict: 'A',
      link: function (scope, $elm, attrs) {
        var idToScroll = attrs.scrollid;
        $elm.on('click', function () {
          var $target;
          if (idToScroll) {
            $target = $('#' + idToScroll);
          } else {
            $target = $elm;
          }
          $('body,html').animate({ scrollTop: $target.offset().top - 80 }, 'slow');
        });
      }
    };
  });
