/* eslint-disable camelcase */
/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:userOrderSuccessCtrl
 * @description
 * # userOrderSuccessCtrl
 * Controller of the heliApp for order management
 */
angular.module('heliApp').controller(
  'userOrderSuccessCtrl', ['$scope', '$state', '$cookies', 'userAdventureDetailService', 'paymentService', '$rootScope', '$timeout', 'CONST_HELPER', '$window', 'rootScopeService',
  function ($scope, $state, $cookies, userAdventureDetailService, paymentService, $rootScope, $timeout, CONST_HELPER, $window, rootScopeService) {
    // var vm = this
    // remove coupon...
    $cookies.put('couponApplied', '');
    $cookies.put('coupon_id', '');
    $scope.guestFormsData = [];
    $timeout(function () {
      $rootScope.myCartLength = 0;
      rootScopeService.setValue("myCartLength", $rootScope.myCartLength);
    }, 1000);
    $scope.emailPattern = /^([a-zA-Z0-9_.-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;



    $scope.getOrderDetails = function () { // get order details
      if (!$rootScope.siteInIFrame) {
        $rootScope.isUserLoggedIn();// check id user is logged in
      }
      if ($rootScope.orderDetails || typeof ($cookies.get('orderDetails')) !== 'undefined') {
        $scope.orderDetails = $rootScope.orderDetails || JSON.parse($cookies.get('orderDetails'));
        if ($cookies.get('guestDetails')) {
          $scope.showLoader();
          var guestDetail = JSON.parse($cookies.get('guestDetails'));
          paymentService.sendGuestForms(guestDetail).then(function () {
            $cookies.put('guestDetails', '');
            $scope.getCurrentOrderDetails();
            shareOnHeli(); // auto share on Heli...
          });// send the guest forms to users
        } else {
          $scope.getCurrentOrderDetails();
          shareOnHeli(); // auto share on Heli...
        }
      } else { // if no  order details
        $state.go('link-expire');
      }
    };

    $scope.getCurrentOrderDetails = function (redirectToForm) {
      $scope.showLoader();
      var order_id = $scope.orderDetails.order_number;
      // var order_id = '59868FFE5';
      var token = $scope.user.token || $rootScope.user.token;
      userAdventureDetailService.myCurrentOrderList(order_id, token).then(function (res) {
        if (res.data.message === 'Success') {
          $scope.myBookings = res.data.bookings;
          // $scope.myBookings.forEach(function (booking) {
          $scope.myBookings.cart_slot_details.forEach(function (cartSlot) {
            if (cartSlot.guest_details && cartSlot.guest_details.length > 0) {
              cartSlot.guest_details.map(function (guest) {
                guest.isFormsComplete = !$scope.incompleteForm(guest.guest_forms);
                if (redirectToForm) {
                  var findGuestForm = guest.guest_forms.find(function (form) {
                    return form.id == redirectToForm.id && form.status_id != CONST_HELPER.ONE;
                  });
                  if (findGuestForm) {
                    if (findGuestForm.status_id != CONST_HELPER.ONE) {
                      $window.open(findGuestForm.form_url, '_blank');
                    }
                  }
                }
              });
            }
          });
          // });
          var total_tax = $scope.myBookings.cart.taxes.total.price.amount;
          var total_processing_fee = 0;
          var total_discount = 0;
          var total_due = 0;
          var total_paid = 0;
          var total_nightly_discount = 0;
          var is_booking_partial = false;
          $scope.guestFormsData = $scope.myBookings.guest_details;
          $scope.myBookings.cart_slot_details.map(function (currentOrder) {
            total_due = total_due + currentOrder.payment_detail.local_due_amount;
            if (currentOrder.payment_detail.payment_status === 2) { // if package is partially paid...
              // currentOrder.package.amount_percentage
              // total_paid = total_paid + (currentOrder.base_amount - currentOrder.payment_detail.local_due_amount - currentOrder.discount_amount) + currentOrder.discount_amount * currentOrder.payment_detail.opr_percentage / 100;
              total_paid = total_paid + currentOrder.payment[0].local_amount;
              // total_discount = total_discount + currentOrder.discount_amount * currentOrder.payment_detail.opr_percentage / 100;
              total_processing_fee = total_processing_fee + currentOrder.payment[0].local_proc_fee_amount;
              is_booking_partial = true;
            } else {
              total_paid = total_paid + currentOrder.base_amount;
              total_discount = total_discount + currentOrder.discount_amount;
              total_processing_fee = total_processing_fee + currentOrder.payment_detail.local_total_processing_fee;
            }
            total_nightly_discount = total_nightly_discount + currentOrder.nightly_discount;
          });
          $scope.myBookings.cart.total_paid = total_paid;
          $scope.myBookings.cart.total_due = total_due;
          $scope.myBookings.cart.total_tax = total_tax;
          $scope.myBookings.cart.is_booking_partial = is_booking_partial ? 1 : 0;
          $scope.myBookings.cart.total_processing_fee = total_processing_fee;
          if ($rootScope.guestUserRemainingPayment) {
            $scope.myBookings.cart.grandTotal = total_paid - total_due + total_tax + total_processing_fee;
          } else {
            $scope.myBookings.cart.grandTotal = total_paid + total_tax + total_processing_fee;
            if (!$scope.myBookings.cart.is_booking_partial) {
              $scope.myBookings.cart.grandTotal -= $scope.myBookings.cart.credit_amount
            }
          }
          $scope.myBookings.cart.discount_amount = total_discount;
          $scope.myBookings.cart.nightly_discount = total_nightly_discount;
          $rootScope.selectedCurrencyCode = $scope.myBookings.cart_slot_details[0].local_currency_code;
          $rootScope.selectedCurrencySymbol = $scope.currencySymbol[$rootScope.selectedCurrencyCode];

          // google analytics code...
          var gaData = {
            order_number: $scope.orderDetails.order_number,
            total_amount: $scope.myBookings.cart.grandTotal,
            tax_amount: $scope.myBookings.cart.total_tax,
            processing_fee: $scope.myBookings.cart.total_processing_fee,
            currency: $rootScope.selectedCurrencyCode,
            event_type: 'orderSuccessfull',
            ecommerce: {
              actionField: { 'step': 4, 'option': 'Order Successfull' }
            },
            event: 'gtm.heli.transaction.completed'
          };
          gaData.ecommerce.packages = [];
          $scope.myBookings.cart_slot_details.map(function (slot) {
            var package_slot = {
              package_id: slot.package.id.toString(),
              package_name: slot.package.name,
              operator_name: slot.operator_name,
              slot_date: slot.package_slots.from_date + ' - ' + slot.package_slots.to_date,
              price: slot.base_amount.toString()
            };
            gaData.ecommerce.packages.push(package_slot);
          });
          $rootScope.$broadcast('addEventToGTM', { 'event': gaData.event, 'ecommerce': gaData }); // add event to google tag manager.
        }
        $scope.hideLoader();
      }, function () {
        $scope.hideLoader();
        $scope.showPopup('#serverErrModal');
      });
    }

    // static code
    $scope.editGuest = false;
    // $scope.getCurrentOrderDetails();
    // $scope.orderDetails = {};
    // static code end here...

    // open addons popup
    $scope.showAddons = function (addons) {
      $scope.currnetselectedAddons = addons;
    };

    $scope.sendForms = function (guest, package_id) {
      if (guest && guest.email != '') {
        var token = $scope.user.token || $rootScope.user.token;
        $scope.showLoader();
        var formData = {
          'guest_id': guest.id,
          'guest_name': guest.name,
          'guest_email': guest.email,
          'guest_forms': getGuestForms(guest),
          'token': token
        };
        userAdventureDetailService.sendForms(formData).then(function (res) {
          $scope.hideLoader();
          if (res.data.code === CONST_HELPER.API_RESPONSE.OK || res.data.code === CONST_HELPER.API_RESPONSE.NO_CONTENT) {
            $scope.$parent.successMsg = 'Your information has been sent.';
            $scope.showPopup('#saveDetailModal');
          }
        }).catch(function () {
          $scope.hideLoader();
        });
      } else {
        $scope.showPopup('#AlertModal');
        $rootScope.errorAlert = 'Please complete guest details.';
      }
    };

    function getGuestForms (guest) {
      var guest_form = [];
      guest.guest_forms.map(function (gf) {
        var formObj = {
          'title': gf.title,
          'form_url': gf.form_url
        };
        guest_form.push(formObj);
      });
      return guest_form;
    }

    // Show Room Details in the lodging popup
    $scope.showRoomsDetails = function (lodging_details, order_package) {
      $scope.lodging_details = lodging_details;
      $scope.selectedPackage = order_package;
    };
    // update slot-adult_quantity by slot_lodging_details
    $scope.calculateGuestsFromLodging = function (lodging) {
      var quantity = 0;
      if (lodging.package.package_case !== 3 && lodging.package.lodging_status !== 0) {
        lodging.cart_slot_lodging_details.forEach(function (item) {
          quantity += item.quantity;
        });
        lodging.total_guests = quantity;
      }
    };
    // Toogle edit guest form
    $scope.showEditGuest = function (item) {
      item.editGuest = !item.editGuest;
    };
    function shareOnHeli () {
      var orderDetail = {
        'order_number': $scope.orderDetails.order_number
      };
      var token = $scope.user.token;
      userAdventureDetailService.autoShareOnHeli(orderDetail, token);
    }
    var remainingTax = 0; var localremainingTax = 0;
    $rootScope.CartDetails = [];
    $rootScope.packageDetail = [];
    $scope.payRemainingAmount = function (item, orderId, bookingSource) {
      // set currency code and currency symbol...
      $rootScope.selectedCurrencyCode = item.local_currency_code;
      $rootScope.selectedCurrencySymbol = $scope.currencySymbol[$rootScope.selectedCurrencyCode];
      $rootScope.usdRate = item.payment_detail.cc_rate;

      remainingTax = item.payment_detail.tax - item.payment[0].tax_amount;
      localremainingTax = item.payment_detail.local_tax - item.payment[0].local_tax_amount;
      var obj = {
        'slot_id': item.id,
        'package_id': item.package.id,
        'operator_id': item.package.user_id,
        'tax_amount': remainingTax,
        'local_tax_amount': localremainingTax,
        'is_partial': 1,
        'processing_fee': calculateRemainingProcessingFee(item.payment_detail.due_amount),
        'local_processing_fee': item.payment_detail.local_total_processing_fee - item.payment[0].local_proc_fee_amount,
        'due_amount': item.payment_detail.due_amount,
        'local_due_amount': item.payment_detail.local_due_amount,
        'slot_amount': item.payment_detail.due_amount,
        'local_slot_amount': item.payment_detail.local_due_amount,
        'is_final_payment': 1,
        'total_tax': item.payment_detail.tax,
        'local_total_tax': item.payment_detail.local_tax,
        // "opr_percentage":0,
        'due_date': item.payment_detail.due_date,
        'payment_detail_id': item.payment_detail.id
      };
      $rootScope.CartDetails.push(obj);

      delete item.package_medias;
      delete item.booking_time;
      delete item.created;
      delete item.is_cancelled;
      delete item.is_genric;
      delete item.is_mail_sent;
      delete item.modified;
      delete item.package_cancellation_policy;
      delete item.referral_id;
      delete item.cart_slot_lodging_details;
      delete item.guests;
      delete item.package.description;
      delete item.package.package_case;
      delete item.package.package_type;
      delete item.package.currency_id;
      delete item.package.location_name;
      delete item.package.lodging_status;
      delete item.package.publish_end_date;
      delete item.package.publish_start_date;
      delete item.slot_usd_child_price;
      delete item.slot_child_price;
      delete item.slot_child_quantity;
      delete item.can_be_cancelled;
      delete item.operator_tax_cloud;
      delete item.currency;
      delete item.addons_details;
      $rootScope.packageDetail.push(item);
      $rootScope.bookingSource = bookingSource;
      $cookies.put('cart_id', item.cart_id);
      $cookies.put('canCheckout', 1);
      $cookies.put('cartDetails', JSON.stringify($rootScope.CartDetails));
      $cookies.put('packageDetail', JSON.stringify($rootScope.packageDetail));
      $cookies.put('cartTaxAmount', localremainingTax);
      $cookies.put('order_id', orderId);
      $cookies.put('usdRate', $rootScope.usdRate);
      $cookies.put('selectedCurrencyCode', $rootScope.selectedCurrencyCode);
      $cookies.put('bookingSource', bookingSource);
      $rootScope.doNotReleaseSeat = true;
      $state.go('secure-checkout', { order_id: orderId });
    };
    function calculateRemainingProcessingFee (remainingAmount) {
      var remainingProcessingFee = 0; var total;
      total = (remainingAmount + remainingTax + 0.30) / (1 - 0.029);
      remainingProcessingFee = total - (remainingAmount + remainingTax);
      return remainingProcessingFee;
    }
    $scope.incompleteForm = function (forms) {
      var statusArr = [];
      forms && forms.forEach(function (form) { statusArr.push(form.status_id); });
      return statusArr.indexOf(4) != CONST_HELPER.MINUS_ONE;
    };
    // save guest
    $scope.EditGuestDetails = function (form, guest) {
      if (form.$invalid) {
        return;
      }
      $('.selectpicker').prop('disabled', true);
      $('.selectpicker').selectpicker('refresh');
      $scope.showLoader();
      var dataObj = [];
      guest.forEach(function (ins) {
        dataObj.push({
          'guest_id': ins.id,
          'name': ins.name,
          'email': ins.email,
          'notes': ins.notes,
          'is_adult': ins.is_adult,
          'allergies': ins.allergies
        });
      });
      var guest_data = { 'guest_data': dataObj };
      userAdventureDetailService.updateGuestConsumerDetails(guest_data, $scope.user.token).then(function (res) {
        if (res.data.code === '200') {
          $scope.editGuest = false;
          $scope.getCurrentOrderDetails();
          $scope.hideLoader();
        } else if (res.data.code === '203') {
          $scope.logout();
          $scope.hideLoader();
        }
      });
    };
  }]);
