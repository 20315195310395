import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name OperatorAnalyticsService
 * @description
 * # operatorAnalyticsService
 * Service in the heliApp.
 */

@Injectable()

export class OperatorAnalyticsService {

  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  sendReq(method, param, token) {
    return new Promise((resolve, reject) => {
      method(param, token).then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    }) /* asynchronously complete request */
  }

  postReq(data, url) {
    let req = {
      method: 'POST',
      url: this.REQUEST_URL + url,
      data: data.feed,
      headers: {
        'token': data.token
      }
    };
    return this.webService.httpReq(req);
  }
  getReq(data, url) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + url,
      headers: {
        'token': data.token
      }
    };
    return this.webService.httpReq(req);
  }
  deleteReq(feedData, url, token) {
    let req = {
      method: 'DELETE',
      url: this.REQUEST_URL + url,
      data: feedData,
      headers: {
        'token': token
      }
    };
    return this.webService.httpReq(req);
  }
  /**
         * @ngdoc method
         * @name getAnalyticsResult
         *
         * @description
         * This method call API to get social analytics data
         **/
  getAnalyticsResult(data) {
    return this.getReq(data, 'operators/dashboard?fr=' + data.fromDate + '&to=' + data.toDate + '&booking_source=' + data.bookingSource + '&location_id=' + data.location_id);
  }

  /**
   * @ngdoc method
   * @name getAnalyticsResult
   *
   * @description
   * This method call API to get social analytics data
   **/
  getAgentAnalyticsResult(data) {
    return this.getReq(data, 'agents/analytics?fr=' + data.fromDate + '&to=' + data.toDate + '&booking_source=' + data.bookingSource + '&location_id=' + data.location_id + '&operator_id=' + data.op_id);
  }

  /**
         * @ngdoc method
         * @name likeFeed
         *
         * @description
         * This method call API to like feed
         **/
  // likeFeed(data) {
  //     return postReq(data, 'social-activities/like');
  // }

  /**
         * @ngdoc method
         * @name getTrafficAnalytics
         *
         * @description
         * This method call API to get public feeds
         **/
  getTrafficAnalytics(data) {
    return this.getReq(data, 'google-analytics/get-operator-traffic?fr=' + data.fromDate + '&to=' + data.toDate + '&location_id=' + data.location_id);
  }
}

angular.module('heliApp').service('operatorAnalyticsService', downgradeInjectable(OperatorAnalyticsService));

