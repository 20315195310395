/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-extend-native */
/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc directive
 * @name heliApp.directive:number
 * @description
 * # attribute 'number' allow only numbers 0-9 .
 */
angular.module('number', [])
.directive('number', function () {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, element, attrs, ctrl) {
            ctrl.$parsers.push(function (input) {
                if (input == undefined) return ''
                var inputNumber = input.toString().replace(/[^0-9]/g, '');
                if (inputNumber != input) {
                    ctrl.$setViewValue(inputNumber);
                    ctrl.$render();
                }
                return inputNumber;
            });
        }
    };
});
