
/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc service
 * @name heliApp.flywireService
 * @description
 * # flywireService
 * Service in the heliApp.
 */
angular.module('heliApp').service('flywireService', [
  '$q',
  'scriptLoaderService',
  'FLYWIRE_ENVIRONMENT',
  'FLYWIRE_PORTAL_CODE',
  'REQUEST_URL',
  function(
    $q,
    scriptLoaderService,
    FLYWIRE_ENVIRONMENT,
    FLYWIRE_PORTAL_CODE,
    REQUEST_URL
  ) {
    // Save context
    var _self = this;

    this.PAYMENT_SCRIPT_URI = 'https://checkout.flywire.com/flywire-payment.js';
    this.CHECKOUT_RESULT = {
      CANCEL: 'cancel',
      COMPLETE: 'complete'
    };


    var defaultConfig = {
      // Set the target environment (demo, prod)
      env: FLYWIRE_ENVIRONMENT,

      requestPayerInfo: true,

      requestRecipientInfo: false,
      // Pass in partial custom field information (missing parameters can
      // alternatively be omitted rather than included with empty or null
      // values)
      recipientFields: {
        booking_reference: '',
        additional_information: null
      },

      // Enable payment status notification callbacks
      callbackUrl: REQUEST_URL + 'payments-notifications/flywire',

      // Split payments configuration
      payables: [
        {
          amount: 0.000,
          recipient: FLYWIRE_PORTAL_CODE,
          description: 'Heli commission'
        }
      ]
    };

    /**
     * @returns {Promise}
     */
    var init = function() {
      var deferred = $q.defer();

      scriptLoaderService
        .load(_self.PAYMENT_SCRIPT_URI)
        .then(function() {
          deferred.resolve(window.FlywirePayment);
        })
        .catch(function() {
          deferred.reject();
        });

      return deferred.promise;
    };

    /**
     * @param checkoutConfig {Object}
     * @param defer {Deferred}
     * @returns {Object}
     */
    var prepareConfig = function(checkoutConfig, defer) {
      var resultConfig = angular.extend({}, defaultConfig, checkoutConfig, {
        // The callback function that is called once the payment is cancelled
        onCancel: function() {
          defer.resolve({ result: _self.CHECKOUT_RESULT.CANCEL });
        },
        // The callback function that is called once the payment is complete
        onCompleteCallback: function(completeArgs) {
          defer.resolve(angular.extend(completeArgs, { result: _self.CHECKOUT_RESULT.COMPLETE }));
        }
      });
      if (resultConfig.commission) {
        angular.extend(resultConfig.payables[0], resultConfig.commission);
        delete resultConfig.commission;
      } else {
        delete resultConfig.payables;
      }
      return resultConfig;
    };


    /**
     * @param account {Object}
     * @returns {string|boolean|boolean}
     */
    this.isAccountComplete = function(account) {
      return (
        account.portalCode && (typeof account.portalCode === 'string') && (account.portalCode.length > 0)
        &&
        account.portalCurrency && (typeof account.portalCurrency === 'string') && (account.portalCurrency.length > 0)
        &&
        account.sharedSecret && (typeof account.sharedSecret === 'string') && (account.sharedSecret.length > 0)
      );
    };

    /**
     *
     * @param config {Object}
     * @returns {Promise}
     */
    this.checkout = function(config) {
      var deferred = $q.defer();
      init()
        .then(function(flywirePayment) {
          flywirePayment
            .initiate(prepareConfig(config, deferred))
            .render();
        })
        .catch(function(reason) {
          deferred.reject(reason);
        });

      return deferred.promise;
    };
  }]
);
