/* eslint-disable no-unused-vars */

'use strict';
var socialLogin = angular.module('socialLogin', ['ngCookies']);

socialLogin.provider('social', function () {
  var fbKey, fbApiV, googleKey, linkedInKey;
  var authorizationResult = false;
  return {
    setFbKey: function (obj) {
      fbKey = obj.appId;
      fbApiV = obj.apiVersion;
      var d = document; var fbJs; var id = 'facebook-jssdk'; var ref = d.getElementsByTagName('script')[0];
      fbJs = d.createElement('script');
      fbJs.id = id;
      fbJs.async = true;
      fbJs.src = '//connect.facebook.net/en_US/sdk.js';

      fbJs.onload = function () {
        FB.init({
          appId: fbKey,
          status: true,
          cookie: true,
          xfbml: true,
          version: fbApiV
        });
      };

      ref.parentNode.insertBefore(fbJs, ref);
    },
    setGoogleKey: function (value) {
      googleKey = value;
      var d = document; var gJs; var ref = d.getElementsByTagName('script')[0];
      gJs = d.createElement('script');
      gJs.async = true;
      gJs.src = '//apis.google.com/js/platform.js';

      gJs.onload = function () {
        var params = {
          client_id: value,
          scope: 'email'
        };
        gapi.load('auth2', function () {
          gapi.auth2.init(params);
        });
      };

      ref.parentNode.insertBefore(gJs, ref);
    },
    setLinkedInKey: function (value) {
      linkedInKey = value;
      var lIN; var d = document; var ref = d.getElementsByTagName('script')[0];
      lIN = d.createElement('script');
      lIN.async = false;
      lIN.src = '//platform.linkedin.com/in.js?async=false';
      lIN.text = ('api_key: ' + linkedInKey).replace('"', '');
      ref.parentNode.insertBefore(lIN, ref);
    },
    $get: function () {
      return {
        fbKey: fbKey,
        googleKey: googleKey,
        linkedInKey: linkedInKey,
        fbApiV: fbApiV
      };
    }
  };
});

socialLogin.factory('socialLoginService',['$window', '$rootScope', '$cookies', function ($window, $rootScope, $cookies) {
  return {
    logout: function () {
    // var provider = $window.localStorage.getItem('_login_provider');
      var provider = $cookies.get('_login_provider');
      switch (provider) {
        case 'google':
        // its a hack need to find better solution.
          var gElement = document.getElementById('gSignout');
          if (typeof (gElement) !== 'undefined' && gElement != null) {
            gElement.remove();
          }
          var d = document; var gSignout; var ref = d.getElementsByTagName('script')[0];
          gSignout = d.createElement('script');
          gSignout.src = 'https://accounts.google.com/Logout';
          gSignout.type = 'text/javascript';
          gSignout.id = 'gSignout';
          $cookies.remove('_login_provider');
          // $window.localStorage.removeItem('_login_provider');
          $rootScope.$broadcast('event:social-sign-out-success', 'success');
          ref.parentNode.insertBefore(gSignout, ref);
          break;
        case 'linkedIn':
          IN.User.logout(function () {
            $cookies.remove('_login_provider');
            // $window.localStorage.removeItem('_login_provider');
            $rootScope.$broadcast('event:social-sign-out-success', 'success');
          }, {});
          break;
        case 'facebook':
          FB.logout(function (res) {
            $cookies.remove('_login_provider');
            // $window.localStorage.removeItem('_login_provider');
            $rootScope.$broadcast('event:social-sign-out-success', 'success');
          });
          break;
      }
    },
    setProvider: function (provider) {
      $cookies.put('_login_provider', provider);
    // $window.localStorage.setItem('_login_provider', provider);
    }
  };
}]);

socialLogin.factory('fbService',['$q', '$timeout', function ($q, $timeout) {
  return {
    login: function () {
      var deferred = $q.defer();
      FB.login(function (res) {
        deferred.resolve(res);
      }, { scope: 'email', auth_type: 'rerequest' });
      return deferred.promise;
    },
    getUserDetails: function () {
      var deferred = $q.defer();
      FB.api('/me?fields=name,email,picture', function (res) {
        if (!res || res.error) {
          deferred.reject('Error occured while fetching user details.');
        } else {
          deferred.resolve(res);
        }
      });
      return deferred.promise;
    },
    removeHoverEffect: function (obj) {
      $(obj).addClass('social-icon-hover');
    }
  };
}]);

socialLogin.directive('linkedIn',['$rootScope', 'social', 'socialLoginService', '$window', function ($rootScope, social, socialLoginService, $window) {
  return {
    restrict: 'EA',
    scope: {},
    link: function (scope, ele, attr) {
      ele.on('click', function () {
        IN.User.authorize(function () {
          IN.API.Raw('/people/~:(id,first-name,last-name,email-address,picture-url)').result(function (res) {
            socialLoginService.setProvider('linkedIn');
            var userDetails = { name: res.firstName + ' ' + res.lastName, email: res.emailAddress, uid: res.id, provider: 'linkedIN', imageUrl: res.pictureUrl };
            $rootScope.$broadcast('event:social-sign-in-success', userDetails);
          });
        });
      });
    }
  };
}]);

socialLogin.directive('linkedInShare',['$rootScope', 'fbService', function ($rootScope, fbService) {
  return {
    restrict: 'EA',
    scope: {
      'package': '=',
      'media': '=',
      'posttype': '=',
      'removeHtml': '&removeTags'
    },
    link: function (scope, ele, attr) {
      function onSuccess (data) {
        console.log('data', data);
      }

      // Handle an error response from the API call
      function onError (error) {
        console.log('error', error);
      }
      ele.on('click', function (e) { // +" "+ scope.package.short_url,
        $('.icon-linkedin').on('mouseover', function (e) {
          $(e.currentTarget).removeClass('social-icon-hover');
        });
        fbService.removeHoverEffect(e.currentTarget);
        // hide share slot modal, when share adventure from package detail page
        if ($('#share').length) { $('#share').modal('hide'); }
        if ($('#operator-adventure-share').length) { $('#operator-adventure-share').modal('hide'); } // share modal in oeprator package listing
        if ($rootScope.anyOpenWindow) {
          $rootScope.anyOpenWindow.close();
        }
        // updating meta tags value.
        $("meta[property='og\\:title']").attr('content', scope.package.name);
        $("meta[property='og\\:description']").attr('content', scope.removeHtml({ 'desc': scope.package.description }));
        $("meta[property='og\\:url']").attr('content', scope.package.short_url);
        $("meta[property='og\\:image']").attr('content', scope.media.url);

        if (scope.media && scope.media.url.indexOf('www.youtube.com') > 0 || scope.media && scope.media.url.indexOf('vimeo.com') > 0) {
          var url = 'https://www.linkedin.com/shareArticle?mini=true&url=' + scope.media.url + '&title=Heli&image=' + scope.package.short_url + '&source=Heli';
        } else {
          var url = 'https://www.linkedin.com/shareArticle?mini=true&url=' + scope.package.short_url;
        }

        if (scope.posttype && (scope.posttype == 1 || scope.posttype == 2 || scope.posttype == 3 || scope.posttype == 4 || scope.posttype == 5 || scope.posttype == 6 || scope.posttype == 7 || scope.posttype == 8)) {
          $rootScope.$broadcast('event:social-account-share', scope.package.id, 'linkedin');
        }
        $rootScope.anyOpenWindow = window.open(url, 'targetWindow', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500, height=500');
      });
    }
  };
}]);

socialLogin.directive('gLogin',['$rootScope', 'social', 'socialLoginService', function ($rootScope, social, socialLoginService) {
  return {
    restrict: 'EA',
    scope: {},
    replace: true,
    link: function (scope, ele, attr) {
      ele.on('click', function () {
        if (typeof (scope.gauth) === 'undefined') { scope.gauth = gapi.auth2.getAuthInstance(); }
        scope.gauth.signIn().then(function (googleUser) {
          var profile = googleUser.getBasicProfile();
          var idToken = googleUser.getAuthResponse().id_token;
          socialLoginService.setProvider('google');
          $rootScope.$broadcast('event:social-sign-in-success', { token: idToken, name: profile.getName(), email: profile.getEmail(), uid: profile.getId(), provider: 'google', imageUrl: profile.getImageUrl() });
        }, function (err) {
          console.log(err);
        });
      });
    }
  };
}]);

socialLogin.directive('fbLogin',['$rootScope', 'fbService', 'social', 'socialLoginService', function ($rootScope, fbService, social, socialLoginService) {
  return {
    restrict: 'A',
    scope: {},
    replace: true,
    link: function (scope, ele, attr) {
      ele.on('click', function () {
        fbService.login().then(function (res) {
          if (res.status == 'connected') {
            fbService.getUserDetails().then(function (user) {
              socialLoginService.setProvider('facebook');
              var userDetails = { name: user.name, email: user.email, uid: user.id, provider: 'facebook', imageUrl: user.picture.data.url, accessToken: res.authResponse.accessToken };
              $rootScope.$broadcast('event:social-sign-in-success', userDetails);
            }, function (err) {
              console.log(err);
            });
          }
        }, function (err) {
          console.log(err);
        });
      });
    }
  };
}]);

socialLogin.directive('fbConnect',['$rootScope', 'fbConnectService', 'social', function ($rootScope, fbConnectService, social) {
  return {
    restrict: 'A',
    scope: {},
    replace: true,
    link: function (scope, ele, attrs) {
      ele.on('click', function () {
        fbConnectService.login();
      });
    }
  };
}]);

socialLogin.directive('twitterShare',['$rootScope', '$timeout', 'fbService', function ($rootScope, $timeout, fbService) {
  return {
    restrict: 'EA',
    scope: {
      'package': '=',
      'posttype': '=',
      'removeHtml': '&removeTags'
    },
    link: function (scope, ele, attr) {
      ele.on('click', function (e) {
        $('.icon-twitter').on('mouseover', function (e) {
          $(e.currentTarget).removeClass('social-icon-hover');
        });

        fbService.removeHoverEffect(e.currentTarget);

        // hide share slot modal, when share adventure from pacakge detail page
        if ($('#share').length) { $('#share').modal('hide'); }
        if ($('#operator-adventure-share').length) { $('#operator-adventure-share').modal('hide'); } // share modal in oeprator package listing
        if ($rootScope.anyOpenWindow) {
          $rootScope.anyOpenWindow.close();
        }
        if (scope.package.short_url.indexOf('www.youtube.com') > 0 || scope.package.short_url.indexOf('vimeo.com') > 0) {
          var url = 'https://twitter.com/intent/tweet?original_referer=&ref_src=&text=' + scope.package.description + '&tw_p=tweetbutton&via=Heli Team';
        } else {
          var url = 'https://twitter.com/intent/tweet?original_referer=&ref_src=&text=' + scope.package.description + '&tw_p=tweetbutton&url=' + scope.package.short_url + '&via=Heli Team';
        }
        if (scope.posttype && (scope.posttype == 1 || scope.posttype == 2 || scope.posttype == 3 || scope.posttype == 4 || scope.posttype == 5 || scope.posttype == 6 || scope.posttype == 7 || scope.posttype == 8)) {
          $rootScope.$broadcast('event:social-account-share', scope.package.id, 'twitter');
        }
        $rootScope.anyOpenWindow = window.open(url, 'targetWindow', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500, height=500');
      });
    }
  };
}]);

socialLogin.directive('fbShare',['$rootScope', 'fbConnectService', 'fbService', function ($rootScope, fbConnectService, fbService) {
  return {
    restrict: 'A',
    scope: {
      'package': '=',
      'media': '=',
      'posttype': '=',
      'removeHtml': '&removeTags'
    },
    replace: true,
    link: function (scope, ele, attrs) {
      ele.on('click', function () {
        $('.icon-facebook').on('mouseover', function (e) {
          $(e.currentTarget).removeClass('social-icon-hover');
        });
        fbService.removeHoverEffect(this);
        fbConnectService.fbShare(scope.package.package_name ? scope.package.package_name : scope.package.name, scope.package ? (scope.package.short_url ? scope.package.short_url.replace('localhost', 'heli-qa.kiwireader.com') : '') : '', scope.package ? scope.removeHtml({ 'desc': scope.package.description }) : '', scope.media ? (scope.media.url ? scope.media.url : '') : '',
          function (response) {
            if (scope.posttype && (scope.posttype == 1 || scope.posttype == 2 || scope.posttype == 3 || scope.posttype == 4 || scope.posttype == 5 || scope.posttype == 6 || scope.posttype == 7 || scope.posttype == 8)) {
              if (response && !response.error_message) {
                $rootScope.$broadcast('event:social-account-share', scope.package.id, 'facebook');
              }
            }
          });
      });
    }
  };
}]);
socialLogin.factory('fbConnectService',['$rootScope', function ($rootScope) {
  var obj = {};
  obj.login = function () {
    FB.login(function (res) {
      if (res.status === 'connected') {
        $rootScope.factoryShowLoader();
        obj.fbDetails(res.authResponse.userID, res.authResponse.accessToken);
      }
    }, { scope: 'public_profile,manage_pages,publish_pages,email,user_posts', return_scopes: true, auth_type: 'reauthenticate' });
  };
  obj.fbDetails = function (id, accessToken) {
    FB.api('/me', function (res) {
      console.log('res facebook me', res);
      var data = {
        facebook_user_id: res.id,
        facebook_username: (res.username) ? res.username : ((res.name) ? res.name : ''),
        facebook_access_token: accessToken
      };
      $rootScope.$broadcast('event:social-fb-account-connect', data);
    });
  },
  obj.fbShare = function (package_name, url, msg, picture, call_back) {
    FB.login(function (res) {
      if (res.status === 'connected') {
        var params = {
          'og:url': url,
          'og:title': package_name || 'Adventure Package'
        };
        var description = msg;
        if (description && description.length) {
          params['og:description'] = description;
        }
        if (picture.length) {
          params['og:image'] = picture;
        }
        FB.ui({
          method: 'feed',
          link: url,
          // link: 'https://heli.life/GOU1z3/',
          display: 'popup'
        }, function (response) {
          if ($('#share').length) {
            // hide adventure slot share modal
            $('#share').modal('hide');
            $('#operator-adventure-share').modal('hide'); // share modal in oeprator package listing
          }
          call_back(response);
          // $rootScope.$broadcast('event:social-fb-account-share', response);
        });
      }
    }, { scope: 'public_profile,manage_pages,publish_pages,email,user_posts', return_scopes: true });
  };
  return obj;
}]);
