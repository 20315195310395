/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:FilterCtrl
 * @description
 * # FilterCtrl
 * Controller of the heliApp
 */
// eslint-disable-next-line no-undef
angular.module('heliApp').controller('ActivityfilterCtrl', [
  '$scope',
  '$rootScope',
  '$window',
  '$timeout',
  '$cookies',
  '$stateParams',
  'searchService',
  'CONST_HELPER',
  'userAdventureDetailService',
  'operatorPackageListService',
  'rootScopeService',
  function ($scope, $rootScope, $window, $timeout, $cookies, $stateParams, searchService, CONST_HELPER, userAdventureDetailService, operatorPackageListService, rootScopeService) {
  var featuredActivity = {
    'ski': [1, 2, 3, 4],
    'kite': [5, 6, 37, 39, 38],
    'surf': [8, 7, 40, 41],
    'fish': [43, 10, 9, 42],
    'bike': [13, 14],
    'dive': [11, 12],
    'safari': [22, 20, 48]
  };
  var activityID = {
    'ski': 1,
    'kite': 2,
    'surf': 3,
    'fish': 4,
    'dive': 5,
    'bike': 6,
    'safari': 9
  };
  var package_sports_id = activityID[$stateParams.myusername];
  var filterApplied = [];
  var obj = { 'type': 'activity', 'name': $stateParams.myusername };

  // initializing Filter object with default values
  $scope.selecctedAbility = $cookies.get('abilityApplied') ? JSON.parse($cookies.get('abilityApplied')) : [];
  $rootScope.location = { 'latitude': '', 'longitude': '' };
  $rootScope.selectedActivity = $stateParams.myusername;
  rootScopeService.setValue("selectedActivity", $rootScope.selectedActivity);
  if (!$rootScope.featuredActivity.length) {
    $rootScope.featuredActivity = CONST_HELPER.FEATURED_ACTIVITY;
  }
  $scope.activityData = $rootScope.featuredActivity.find(function (obj) {
    return obj.slug.toLowerCase() === $rootScope.selectedActivity.toLowerCase();
  });
  $scope.filter = {
    keyword: '',
    mindate: '',
    maxdate: '',
    location: $scope.locationVal,
    lat: $rootScope.location.latitude,
    lang: $rootScope.location.longitude,
    limit: CONST_HELPER.page_limit,
    page: 1,
    back: 0,
    sort: '',
    activities: [],
    min_price: '',
    max_price: '',
    days: 0,
    people: 0,
    abilities: [
      { name: 'Beginner', isChecked: checkAbility('Beginner'), id: 1 },
      { name: 'Intermediate', isChecked: checkAbility('Intermediate'), id: 2 },
      { name: 'Expert', isChecked: checkAbility('Expert'), id: 3 },
      { name: 'Legendary', isChecked: checkAbility('Legendary'), id: 4 }
    ],
    sortingKey: '',
    sortingReverse: false,
    featuredActivityId: featuredActivity[$stateParams.myusername],
    package_sports_id: package_sports_id
  };

  // store applied filters in localstorage...
  $scope.storeAppliedFilters = function (_filter) {
    $cookies.put('appliedActivityFilter', JSON.stringify(filterApplied));
    $cookies.put('filterObject', JSON.stringify($scope.filter));
    $cookies.put('searchedActivity', JSON.stringify($stateParams.myusername));
    if ($scope.selecctedAbility.length) {
      $cookies.put('abilityApplied', JSON.stringify($scope.selecctedAbility));
    }
  };

  // get applied filters in localstorage...
  $scope.getAppliedFilters = function (_filter) {
    filterApplied = $cookies.get('appliedActivityFilter') ? JSON.parse($cookies.get('appliedActivityFilter')) : [];
    var isActivityApplied = filterApplied.find(function (val) {
      return val.type === 'activity';
    });
    if (!isActivityApplied) {
      filterApplied.push(obj);
    }
    if ($cookies.get('filterObject')) {
      $scope.filter = JSON.parse($cookies.get('filterObject'));
      // set dates and location
      if ($scope.filter) {
        // eslint-disable-next-line no-undef
        $scope.dateRange.startDate = $scope.filter.mindate ? moment(new Date($scope.filter.mindate)) : null;
        $scope.dateRange.endDate = $scope.filter.maxdate ? moment(new Date($scope.filter.maxdate)) : null;
        $scope.locationVal = $scope.filter.location;
        $rootScope.location.latitude = $scope.filter.lat || '';
        $rootScope.location.longitude = $scope.filter.lang || '';
        $rootScope.locationSelected = $scope.filter.location;
      }
    }
  };

  // get the filters applied on page refresh...
  var previousActivity = $cookies.get('searchedActivity');
  if (previousActivity && JSON.parse(previousActivity) === $stateParams.myusername) {
    $scope.getAppliedFilters();
  } else {
    $scope.dateRange = { startDate: null, endDate: null };
    filterApplied.push(obj);
  }

  // function to verify if any ability is checked or not...
  function checkAbility (ability) {
    var activeActivity = $scope.selecctedAbility || [];
    if (activeActivity && activeActivity.indexOf(ability) !== -1) {
      return true;
    } else {
      return false;
    }
  }

  $scope.applyFilters = function (event, scroll) {
    if (event !== 'loadmore') { // reset page for other applied filters except load more.
      $scope.filter.page = 1;
    }
    $scope.minError = false;

    var ele = angular.element(document.getElementById('filter-modal'));
    var headElement = angular.element(document.getElementById('filter-head'));
    ele[0].className = ele[0].className + ' mobile-display';
    headElement[0].className = headElement[0].className + ' toggled';

    $('body').removeClass('fix-scroll');

    $scope.showLoader(); //  show loader
    createTag(); //  create tags for price range , days and people.

    // get filtered adventures...
    searchService.filterAdventures($scope.filter).then(function (response) {
      if (!response.data.response && response.data.code !== '400') {
        $scope.applyFilters($scope.filter);
        return false;
      }
      if (response.data.response) {
        if (event !== 'loadmore') {
          $scope.searchData = priortizeCategoryPackages(response.data.response.docs, package_sports_id);
          if (scroll === 'reset-scroll') {
            resetScroll(); // reset the scroll position...
          }
        } else {
          $scope.searchData = $scope.searchData.concat(priortizeCategoryPackages(response.data.response.docs, package_sports_id));
        }
        $scope.resultActivities = response.data.activities;
        $scope.resultFound = response.data.response.numFound;
        $scope.canloadmore = couldLoadMore($scope.searchData, $scope.resultFound);
        mainTainScroll(); // set the scroll position...
        if ($scope.searchData.length > 0) {
          getFeaturedImage($scope.searchData);
          $scope.checkExpiredSlots($scope.searchData); // check expired slots
        } else {
          $scope.hideLoader(); // hide loader
        }
        getAdventureAvailableSeats($scope.searchData);
        if ($scope.searchData.length === 0) {
          $scope.noDataFoundAfterFilter = true;
        } else {
          $scope.noDataFoundAfterFilter = false;
        }
        $scope.appliedFilters = angular.copy(filterApplied);
        $scope.storeAppliedFilters();
        $('.sort-filter-div').addClass('hide-div');
      }
    }, function (_error) {
      $scope.hideLoader(); // hide loader
      $scope.showPopup('#serverErrModal');
    });
  };

  function priortizeCategoryPackages (dataArr, primaryCategoryId) {
    var _tempPrimaryPakcages = [];
    var _tempSecondaryPakcages = [];
    _tempPrimaryPakcages = dataArr.filter(function (pckg) {
      return pckg.package_sports_id && pckg.package_sports_id == primaryCategoryId;
    });
    _tempSecondaryPakcages = dataArr.filter(function (pckg) {
      return !pckg.package_sports_id || pckg.package_sports_id != primaryCategoryId;
    });

    return _tempPrimaryPakcages.concat(_tempSecondaryPakcages);
  }

  $scope.applyFilters();// initial call...

  // register date filter changed...
  $scope.$on('dateFilterApplied', function (_event, data) {
    if (data === 'location-updated') {
      $scope.filter.location = $rootScope.locationName;
      $rootScope.locationSelected = $rootScope.locationName;
      $scope.filter.lat = $rootScope.location.latitude;
      $scope.filter.lang = $rootScope.location.longitude;
    } else {
      $scope.filter.mindate = !data.dateRangeCleared && data.startDate ? moment(data.startDate).format('MM/DD/YYYY') : '';
      $scope.filter.maxdate = !data.dateRangeCleared && data.endDate ? moment(data.endDate).format('MM/DD/YYYY') : '';
    }
    $scope.applyFilters();
  });

  // view all Slots
  // $scope.viewAllSlots = function (event, adventure) {
  //   $scope.adventure = adventure;
  //   $scope.showPopup('#slot');
  //   event.stopPropagation();
  // };

  $scope.viewAllSlots = function (event, adventure) {
    var slotParams = {};
    slotParams.package_id = adventure.package_id;
    $scope.showLoader();
    operatorPackageListService.getOperatorPackagesSlots(slotParams).then(function (response) { // fetch from the service
      if (response.data.code === '200') {
        $scope.adventure = response.data.package;// add to scope
        $scope.checkExpiredSlots([$scope.adventure]);
        $scope.adventure.localCurrencyRate = adventure.localCurrencyRate;
        $scope.adventure.packageCurrencyCode = adventure.packageCurrencyCode;
        $scope.showPopup('#slot');
        $scope.hideLoader();
      }
    }, function () { // check for error
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    });
    event.stopPropagation();
  };

  // function to reset the scroll position after search change or filter applied..
  function resetScroll () {
    var currentOffset = $(window).scrollTop();
    $timeout(function () {
      if (!(currentOffset !== $rootScope.filterOffset && $rootScope.filterOffset > 100 && $rootScope.previousState && ($rootScope.previousState.name === 'adventure-detail'))) {
        angular.element('html, body').animate({ scrollTop: CONST_HELPER.SCREEN.ACTIVITY_OFFSET }, 800);
      }
    }, 500);
  }

  // check wheather  can loadmore adventure or not
  function couldLoadMore (adventure, resultFound) {
    if (adventure.length < resultFound) { return true; }
  }

  // get the featured image of the packages...
  function getFeaturedImage (searchData) {
    $scope.newActivies = [];
    searchData.map(function (obj, key) {
      // get localCurrencyRate of package currency...
      // if ($scope.user.id) {
      if (obj.currency_code[0] !== $rootScope.localCurrencyCode) {
        userAdventureDetailService.convertCurrency(1, obj.currency_code[0], $rootScope.localCurrencyCode).then(function (res) {
          obj.localCurrencyRate = res.data.usd_rate;
          obj.convertedPrice = obj.min_price * obj.localCurrencyRate;
          if (searchData.length === key + 1) {
            $scope.hideLoader(); // hide loader
            sortbyprice($stateParams.sort);
          }
        });
      } else {
        obj.localCurrencyRate = 1;
        obj.convertedPrice = obj.min_price * obj.localCurrencyRate;
        if (searchData.length === key + 1) {
          $scope.hideLoader(); // hide loader
        }
      }
      obj.packageCurrencyCode = $rootScope.localCurrencyCode;
      // } else {
      //   obj.localCurrencyRate = 1;
      //   obj.packageCurrencyCode = obj.currency_code[0];
      //   $scope.hideLoader(); // hide loader
      // }

      if (obj.medias) {
        obj.medias.map(function (image) {
          if (image && image.media_type === 0 && image.is_main === 1) {
            obj.featuredImg = image.url;
          }
        });
        if (!obj.featuredImg) {
          obj.featuredImg = 'images/img2.png';
        }
      }
    });
  }

  /**
        // @function getAdventureAvailableSeats()
        // Get Available seats in Adventures for Available slots.
        **/
  function getAdventureAvailableSeats (adventures) {
    adventures.map(function (adv) {
      var availableSeats = 0;
      if(adv.booking_type && adv.booking_type[0] == 2){
        adv.availableSeats = adv.total_nightly_available_days[0];
        return;
      }
      adv.startDate = new Date(adv.min * 1000);
      adv.package_slots.map(function (slotData) {
        if (!slotData.expired) { availableSeats = parseInt(availableSeats) + parseInt(slotData.available_seat); }
      });
      adv.availableSeats = availableSeats;
    });
  }

  /**
        // @function $scope.loadMore
        // Load more the adventure...
        **/
  $scope.loadMore = function () {
    $scope.filter.page = parseInt($scope.filter.page) + 1;
    $scope.filter.back = 0;
    if (filterApplied.length > 0) { //  check if applied filters is empty...
      // $scope.filter.back=1;
      $scope.applyFilters('loadmore');
      return false;
    } else if ($stateParams.sort) {
      $scope.filter.back = 1;
      $scope.sortAdventure($stateParams.sort, 'loadmore');
      return;
    }

    $scope.showLoader(); // show loader
    searchService.searchAdventures($scope.filter).then(
      function (response) {
        $scope.searchData = $scope.searchData.concat(response.data.response.docs);
        $scope.canloadmore = couldLoadMore($scope.searchData, $scope.resultFound);
        $scope.checkExpiredSlots($scope.searchData); // check expired slots
        getAdventureAvailableSeats($scope.searchData);
        getFeaturedImage($scope.searchData);
      }, function () {
        $scope.hideLoader(); // hide loader
        $scope.showPopup('#serverErrModal');
      });
  };

  function sortbyprice (sortby) {
    // handle price sorting in front end using orderby
    switch (sortby) {
      case 'min_price-asc':
        $scope.filter.sortingKey = 'convertedPrice';
        $scope.filter.sortingReverse = false;
        break;
      case 'min_price-desc':
        $scope.filter.sortingKey = 'convertedPrice';
        $scope.filter.sortingReverse = true;
        break;
      default :
        $scope.filter.sortingKey = '';
        $scope.filter.sortingReverse = false;
        break;
    }
  }

  /**
        // @function $scope.sortAdventure
        // sort the available adventures by newest/oldest price: low/high
        **/
  $scope.sortAdventure = function (sortby, event) {
    $scope.filter.sort = sortby;
    $scope.filter.back = 1;
    if (filterApplied.length > 0) {
      $scope.applyFilters();
      return;
    }
    $scope.showLoader(); // show loader
    searchService.sortData($scope.filter).then(
      function (response) {
        $scope.searchData = response.data.response.docs;
        $scope.resultActivities = response.data.activities;
        $scope.resultFound = response.data.response.numFound;
        $scope.canloadmore = couldLoadMore($scope.searchData, $scope.resultFound); // check wheather  to show loadmore button or not
        if (event !== 'loadmore') { resetScroll(); }; // reset the scroll position...
        $scope.checkExpiredSlots($scope.searchData); // check expired slots
        getAdventureAvailableSeats($scope.searchData); // get Available seats in all slots
        getFeaturedImage($scope.searchData);
        mainTainScroll(); // set the scrollPosition...
        if ($scope.searchData.length === 0) {
          $scope.noDataFoundAfterSearch = true;
        } else {
          $scope.noDataFoundAfterSearch = false;
          $scope.noDataFoundAfterFilter = false;
        }
        $scope.appliedFilters = angular.copy(filterApplied);
      }, function () {
        $scope.hideLoader(); // hide loader
        $scope.showPopup('#serverErrModal');
      });
  };

  // FILTER FUNCTIONALITY...
  // validata min price and max-price
  $scope.$watch('filter.max_price', function (n, _o) {
    if (n === '0') {
      $scope.priceValidation = true;
      $scope.maxError = true;
      // $scope.minErrorMsg="Max price must be greater than 0";
    } else if (n < $scope.filter.min_price && $scope.filter.min_price) {
      $scope.priceValidation = false;
      $scope.maxError = false;
    } else if (!n) {
      if ($scope.filter.min_price === '0') {
        $scope.maxError = true;
        // $scope.minErrorMsg="Min price must be greater than 0";
      } else {
        $scope.maxError = false;
      }
    } else {
      $scope.priceValidation = true;
      $scope.maxError = false;
    }
  });
  $scope.$watch('filter.min_price', function (n, _o) {
    if (n === '0') {
      $scope.minError = true;
      $scope.minErrorMsg = 'Min price must be greater than 0';
    } else if (n > $scope.filter.max_price && ($scope.filter.max_price !== '' || $scope.filter.max_price === '0')) {
      $scope.priceValidation = false;
      $scope.minError = false;
    } else {
      $scope.minError = false;
      $scope.priceValidation = true;
    }
  });

  // create tags for days, people and price.
  function createTag () {
    // check condition for days tag , people tag and price tag.
    var dayFilter = filterApplied.find(function (filter) { // find the day filter tag in array
      return filter.type === 'day';
    });
    var peopleFilter = filterApplied.find(function (filter) { // find the people filter tag in array
      return filter.type === 'people';
    });
    var priceFilter = filterApplied.find(function (filter) { // find the price filter tag in array
      return filter.type === 'price';
    });

    var dayObj = {
      name: $scope.filter.days + ' Days',
      type: 'day'
    };
    var peopleObj = {
      name: $scope.filter.people + ' people',
      type: 'people'
    };
    var priceObj = {
      name: ' $' + ($scope.filter.min_price || 1) + ' - ' + $scope.filter.max_price,
      type: 'price'
    };
    // check day filter is already in filterApplied array?
    if (dayFilter) {
      var i = filterApplied.indexOf(dayFilter); // get the index of day filter
      if ($scope.filter.days > 0) {
        filterApplied[i] = dayObj; // update when it is greater than 0
      } else {
        filterApplied.splice(i, 1); // delete from array when user set back it to 0
      }
    } else { // when it is applied first time push to filterApplied array.
      if ($scope.filter.days > 0) {
        filterApplied.push(dayObj);
      }
    }

    // check people filter is already in filterApplied array?
    if (peopleFilter) {
      // eslint-disable-next-line no-redeclare
      var i = filterApplied.indexOf(peopleFilter); // get the index of people filter
      if ($scope.filter.people > 0) {
        filterApplied[i] = peopleObj; // update when it is greater than 0
      } else {
        filterApplied.splice(i, 1); // delete from array when user set back it to 0
      }
    } else { // push to array when it is applied first time and greater than 0
      if ($scope.filter.people > 0) {
        filterApplied.push(peopleObj);
      }
    }

    // check price filter is already in filterApplied array?
    if (priceFilter) {
      // eslint-disable-next-line no-redeclare
      var i = filterApplied.indexOf(priceFilter); // get the index of price filter
      if ($scope.filter.max_price > 0) {
        filterApplied[i] = priceObj; // update when it is greater than 0
      } else {
        filterApplied.splice(i, 1); // delete from array when user set back it to 0
      }
    } else {
      if ($scope.filter.max_price > 0) { // push to array when it is applied first time and greater than 0
        $scope.filter.min_price = $scope.filter.min_price ? $scope.filter.min_price : CONST_HELPER.ONE;
      }
      if ($scope.filter.max_price > 0 && $scope.filter.min_price > 0) {
        filterApplied.push(priceObj);
      }
    }
  }

  /**
    // @function $scope.inreaseCount/$scope.decreaseCount
    // Increase/decrease count of days and peoples...
    **/
  $scope.inreaseCount = function (key) {
    if (key === 'days') { $scope.filter.days = parseInt($scope.filter.days) + 1; } else { $scope.filter.people = parseInt($scope.filter.people) + 1; }
  };
  $scope.decreaseCount = function (key, val) {
    if (val === 0) { return; }
    if (key === 'days') { $scope.filter.days = parseInt($scope.filter.days) - 1; } else { $scope.filter.people = parseInt($scope.filter.people) - 1; }
  };

  /**
    // @function $scope.clearAllFilters
    // clear all alpplied filters
    **/
  $scope.clearAllFilters = function () {
    $scope.appliedFilters.map(function (filter) {
      $scope.removeFilterTag(filter, true);
    });
    $scope.applyFilters();
  };

  /**
        // @function $scope.removeFilterTag
        // set default vaule and remove the filter obj from filterApplied array so it tag removed from view by clicking on cross icon
        **/
  $scope.removeFilterTag = function (tag, ifClearAll) {
    var index;
    if (tag.type === 'ability') {
      index = getIndex($scope.filter.abilities, tag.name);
      // eslint-disable-next-line no-unused-expressions
      $scope.filter.abilities ? $scope.filter.abilities[index].isChecked = false : '';
      $scope.selecctedAbility = $scope.selecctedAbility.splice($scope.selecctedAbility.indexOf(tag.name), 1);
    } else if (tag.type === 'day') {
      $scope.filter.days = 0; // set to zero(default)
    } else if (tag.type === 'people') {
      $scope.filter.people = 0; // set to zero(default)
    } else if (tag.type === 'price') {
      $scope.filter.min_price = ''; // set to zero(default)
      $scope.filter.max_price = ''; // set to zero(default)
    }
    var i = $scope.appliedFilters.indexOf(tag);
    if(tag.type != 'activity') {
      filterApplied.splice(i, 1);
    }
    $scope.storeAppliedFilters();
    $scope.appliedFilters = angular.copy(filterApplied); // copy to scope vaiable so it can reflect to view
    if (!ifClearAll) { $scope.applyFilters(); }
  };
  // get index of activity and ability in $scope.filters array
  function getIndex (arr, name) {
    var i;
    arr.forEach(function (val, index) {
      if (val.sport_name && val.sport_name === name) {
        i = index;
      } else if (val.name === name) {
        i = index;
      }
    });
    return i;
  }

  /**
   // @function checkAbilityChange
   // check wheather to add or remove ability applied filter tag...
  **/
  $scope.checkAbilityChange = function (ability) {
    if (ability.isChecked) {
      var obj = {
        name: ability.name,
        type: 'ability'
      };
      filterApplied.push(obj);
      $scope.selecctedAbility.push(ability.name);
    } else {
      var index = getIndex(filterApplied, ability.name);
      filterApplied.splice(index, 1);
      $scope.selecctedAbility.splice($scope.selecctedAbility.indexOf(ability.name), 1);
    }
  };

  // save window scrollPostion
  angular.element($window).bind('scroll', function (_e) {
    // console.log(this.pageYOffset);
    if (this.pageYOffset > 500 && $rootScope.currentState && $rootScope.currentState.name === 'myprofile' && $rootScope.activityFilterPage) {
      $rootScope.filterOffset = this.pageYOffset;
    } else if (this.pageYOffset < 500 && this.pageYOffset !== 0 && $rootScope.currentState && $rootScope.currentState.name === 'filter') {
      $rootScope.filterOffset = this.pageYOffset;
    }
  });
  function mainTainScroll () {
    var currentOffset = $(window).scrollTop();
    if (currentOffset !== $rootScope.filterOffset && $rootScope.filterOffset > 100 && $rootScope.previousState && ($rootScope.previousState.name === 'adventure-detail')) {
      $timeout(function () { // scroll to previous position.
        angular.element('html, body').animate({ scrollTop: $rootScope.filterOffset }, 1000);
      }, 10);
    }
  }
}]);
