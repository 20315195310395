import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { environment } from '../environment';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name OperatorManifestService
 * @description
 * # operatorManifestService
 * Service in the heliApp.
 */

@Injectable()

export class OperatorManifestService {

  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  sendReq(method, param) {
    return new Promise((resolve, reject) => {
      method(param).then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    }) /* asynchronously complete request */
  }

  getReq(data, url) {
    let req = {
      method: 'GET',
      url: this.REQUEST_URL + url,
      headers: {
        'token': data.token
      }
    };
    return this.webService.httpReq(req);
  }

  getLocationNames(data) {
    return this.getReq(data, 'locations');
  }

  getOperatorPackages(data) {
    return this.getReq(data, 'operator-packages?status=1');
  }

  getOperatorManifestList(data) {
    let url = 'manifest';
    if (data.date_from || data.date_to) {
      url += '?';

      if (data.date_from) {
        url += 'date_from=' + data.date_from + '&';
      }

      if (data.date_to) {
        url += 'date_to=' + data.date_to;
      }
    }
    return this.getReq(data, url);
  }

  getOperatorManifestListFilter(token, params, responseType) {
    let notEmptyParams = {};
    for (let key in params) {
      if (
        (key !== 'token')
        &&
        params[key]
      ) {
        notEmptyParams[key] = params[key];
      }
    }

    let httpReqConfig: any = {
      method: 'GET',
      url: this.REQUEST_URL + 'manifest',
      params: notEmptyParams
    };

    let headers: any = { token: token };
    responseType = responseType || 'json';
    if (responseType === 'pdf') {
      headers.Accept = 'application/pdf';
      httpReqConfig.responseType = 'blob';
    } else if (responseType === 'csv') {
      headers.Accept = 'text/csv';
      httpReqConfig.responseType = 'blob';
    }

    httpReqConfig.headers = headers;

    return this.webService.httpReq(httpReqConfig);
  }

  saveOperatorNotes(token, guestId, data) {
    return this.webService.httpReq({
      method: 'PATCH',
      url: this.REQUEST_URL + 'guests/' + guestId,
      data: data,
      headers: {
        'token': token
      }
    });
  }
}

angular.module('heliApp').service('operatorManifestService', downgradeInjectable(OperatorManifestService));
