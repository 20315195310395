/* eslint-disable handle-callback-err */
/* eslint-disable no-undef */
'use strict';

/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:UserSocialSignInCtrl
 * @description
 * # UserSocialSignInCtrl
 * Controller of the heliApp
 */
angular.module('heliApp').controller(
  'UserSocialSignInCtrl', ['$scope', '$rootScope', 'userRegisterService', '$window', '$state', '$stateParams', 'CONST_HELPER',
  function ($scope, $rootScope, userRegisterService, $window, $state, $stateParams, CONST_HELPER) {
    $scope.firstNameErr = false;// complete sign up error messages
    $scope.lastNameErr = false;
    $scope.tcCheckBoxErr = false;
    $rootScope.userNameErr = false;
    $rootScope.userNameValid = true;
    $scope.accountMerge = {};
    $scope.accountMerge.mergeStatus = true;
    $scope.mergeErr = false;
    if ($rootScope.accountMergeStatus) {
      $scope.emailErr = false;// sign up error messages
      $scope.accountErr = false;// set error status
    } else {
      $scope.emailErr = true;// sign up error messages
      $scope.accountErr = true;// set error status
      $scope.accountErrMessage = 'Email already exists. Please change this email address or merge accounts';
    }
    $rootScope.$on('social-sign-in', function (event, userDetails, response) { // new user social sign in
      updateScope(userDetails, response);
    });

    $rootScope.$on('social-sign-in-from-login', function (event, userDetails, response) { // new user social sign in from log in page
      updateScope(userDetails, response);
    });

    $scope.getAllCountryName = function () {
      userRegisterService.getAllCountry().then(function (data) {
        if (data.data.code === CONST_HELPER.API_RESPONSE.OK) {
          $scope.allcountryName = data.data.data;
        }
      });
    };
    $scope.getAllCountryName();
    $rootScope.initializePhoneField("#phoneFieldSocial");

    function updateScope (userDetails, response) { // update scope for social logins
      if ($scope.$parent.$parent) {
        $scope.$parent.$parent.signup = false;// show complete you account page
        $scope.$parent.$parent.socialSignUp = true;
        $scope.$parent.$parent.user.email = userDetails.email;// set email
        $scope.$parent.$parent.user.username = response.data.data.username;// set username
        $scope.$parent.$parent.user.first_name = '';
        $scope.$parent.$parent.user.last_name = '';
        userDetails.provider = (userDetails.provider).toLowerCase();
        if (userDetails.provider === 'linkedin') {
          $scope.$parent.$parent.user.accessToken = '';
        } else if (userDetails.provider === 'google') {
          $scope.$parent.$parent.user.accessToken = userDetails.token;
        } else {
          $scope.$parent.$parent.user.accessToken = userDetails.accessToken;
        }
        if (userDetails.name && (userDetails.name).trim() !== '') { // check if name exists in response
          var name = (userDetails.name).trim();
          var namesplit = name.split(' ');
          $scope.$parent.$parent.user.first_name = namesplit[0]; // get first name
          var ln = '';// for last name
          if (namesplit[1]) {
            for (var i = 1; i < namesplit.length; i++) {
              ln = ln + ' ' + namesplit[i];
            }
          }
          $scope.$parent.$parent.user.last_name = ln; // last name
        } else if (userDetails.firstName && (userDetails.firstName).trim() !== '') {
          $scope.$parent.$parent.user.first_name = userDetails.firstName || ''; // get first name
          $scope.$parent.$parent.user.last_name = userDetails.lastName || ''; // last name
        }
        $scope.$parent.$parent.user.social_type = userDetails.provider;
        $scope.$parent.$parent.user.social_user_id = userDetails.uid;
      }
    }

    $scope.userSocialRegister = function (form) { // user registeration to heli platform directly
      var phoneNumberValue = $("#phoneFieldSocial").intlTelInput("getNumber");
      if (phoneNumberValue == "" || phoneNumberValue.length < 8 || phoneNumberValue.length > 16) {
        $scope.phoneNumberError = true;
      } else {
        $scope.phoneNumberError = false;
      };
      if (typeof ($scope.$parent.$parent.user.email) !== 'undefined') { // check for required validaiton of the email
        if ((form.email.$viewValue).trim() === '') {
          $scope.emailErr = true;
        } else {
          $scope.emailErr = false;
        }
      } else {
        $scope.emailErr = true;
      }
      if (typeof ($scope.$parent.$parent.user.first_name) !== 'undefined') { // check for required validaiton of the email
        if ((form.first_name.$viewValue).trim() === '') {
          $scope.firstNameErr = true;
        } else {
          $scope.firstNameErr = false;
        }
      } else {
        $scope.firstNameErr = true;
      }
      if (typeof ($scope.$parent.$parent.user.last_name) !== 'undefined') { // check for required validaiton of the email
        if ((form.last_name.$viewValue).trim() === '') {
          $scope.lastNameErr = true;
        } else {
          $scope.lastNameErr = false;
        }
      } else {
        $scope.lastNameErr = true;
      }

      if (typeof ($scope.$parent.$parent.user.username) !== 'undefined') {
        if ((form.username.$viewValue).trim() === '') {
          $rootScope.userNameErr = true;
        } else {
          $rootScope.userNameErr = false;
        }
      } else {
        $rootScope.userNameErr = true;
      }
      if (!$rootScope.userNameErr) { // check for required validaiton of the last name
        if ($scope.userNameValidation((form.username.$viewValue).trim())) {
          $rootScope.userNameValid = true;
        } else {
          $rootScope.userNameValid = false;
        }
      }

      if (!$scope.user.country_id ) {
        $scope.userCountryError = true;
      } else {
        $scope.userCountryError = false;
      }

      if (typeof ($scope.$parent.$parent.user.tcCheckBox) !== 'undefined') { // check for required validaiton of the email
        if (!$scope.$parent.$parent.user.tcCheckBox) {
          $scope.tcCheckBoxErr = true;
        } else {
          $scope.tcCheckBoxErr = false;
        }
      } else {
        $scope.tcCheckBoxErr = true;
      }

      if (!$scope.phoneNumberError && !$scope.emailErr && !$scope.firstNameErr && !$scope.lastNameErr && !$rootScope.userNameErr && !$scope.userNameExistErr && $rootScope.userNameValid && !$scope.tcCheckBoxErr && form.$valid) { // check if form is valid or not
        var data = {
          'first_name': ($scope.$parent.$parent.user.first_name).trim(),
          'last_name': ($scope.$parent.$parent.user.last_name).trim(),
          'username': ($scope.$parent.$parent.user.username).trim(),
          'phone_number': phoneNumberValue,
          'social_type': $scope.$parent.$parent.user.social_type,
          'social_user_id': $scope.$parent.$parent.user.social_user_id,
          'social_secret': {
            'accesstoken': $scope.$parent.$parent.user.accessToken
          },
          'email': ($scope.$parent.$parent.user.email).trim()
        }; // set data field
        if ($stateParams.referral_id) { // check for influencer referral
          data.referral_id = $stateParams.referral_id;
          $rootScope.referral_id = $stateParams.referral_id;
        }
        $scope.showLoader();// show loader
        $scope.accountErr = false;// set inital error to be false
        userRegisterService.completeSocialSignUp(data).then(function (response) { // post to the server the form data
          if (response.data) {
            if (response.data.code !== '200') { // some error exits
              $scope.accountErr = true;// set error status
              for (var key in response.data.message[Object.keys(response.data.message)]) {
                $scope.accountErrMessage = response.data.message[Object.keys(response.data.message)][key];
              }
              var errType = Object.keys(response.data.message)[0];
              if (errType === 'username') { // its email error
                $rootScope.userNameErr = true;
              } else if (errType === 'email') { // its email error
                $scope.emailErr = true;
                if (response.data.code === '412') { // if account merge
                  $scope.$parent.$parent.$parent.accountMerge = true;// show account merge screen
                }
              }
            } else { // if  registeration is successful
              $scope.accountErr = false;// set error status
              $scope.setUserDetails(response.data.data, 'second');// set user details in the rootScope;
              $scope.$parent.$parent.user = response.data.data;// set user data into local scope;
              $scope.emailVerificationAlert(); // show email verification alert
              // $rootScope.$broadcast('user.signin');
              if ($rootScope.previousState && $rootScope.previousState.name !== '' && $rootScope.previousState.name !== 'mergeAccountSuccess') {
                $window.history.back();
              } else {
                $state.go('home');
              }
            }
            $scope.hideLoader();// hide loader
          }
        }, function (error) { // check for error
          $scope.hideLoader();// hide loader
          $scope.showPopup('#serverErrModal');
        });
      }
    };

    $scope.removeErr = function (model, err) { // remove error messages on inputs filling
      if (model !== '') {
        if (err === 'lastNameErr') {
            $scope.lastNameErr = false;
        } else if (err === 'tcCheckBoxErr') {
            $scope.tcCheckBoxErr = false;
        } else {
            $scope.firstNameErr = false;
        }
    }
    };

    $scope.userMergeAccount = function () { // account merge status
      var data = { 'social_type': $scope.$parent.$parent.user.social_type, 'social_user_id': $scope.$parent.$parent.user.social_user_id, 'email': ($scope.$parent.$parent.user.email).trim(), 'is_merge': $scope.accountMerge.mergeStatus };// set data field
      userRegisterService.mergeAccounts(data).then(function (response) { // post to the server the form data
        if (response.data) {
          if (response.data.code !== '200') { // some error exits
            $scope.mergeErr = true;// set error status
            for (var key in response.data.message[Object.keys(response.data.message)]) {
              $scope.mergeErrMessage = response.data.message[Object.keys(response.data.message)][key];
            }
          } else { // if user wants to merge
            if ($scope.accountMerge.mergeStatus) {
              $scope.showPopup('#mergeAccounts');
            } else { // does not want to merge
              $scope.$parent.$parent.accountMerge = false;
              $rootScope.accountMergeStatus = false;
              $scope.$parent.$parent.mergeStatus = false;// merge status is set to no
            }
          }
          $scope.hideLoader();// hide loader
        }
      }, function (error) { // check for error
        $scope.hideLoader();// hide loader
        $scope.showPopup('#serverErrModal');
      });
    };
  }]);
