/* eslint-disable new-cap */
/* eslint-disable camelcase */
/* eslint-disable no-eval */
/* eslint-disable handle-callback-err */
/* eslint-disable no-undef */
'use strict';
/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:userProfileCtrl
 * @description
 * # userProfileCtrl
 * Controller of the heliApp
 */
angular.module('heliApp').controller(
  'userProfileCtrl', ['$scope', '$window', 'userProfileService', '$state', '$cookies', '$filter', '$timeout', 'paymentService', '$rootScope', 'userAdventureDetailService', 'userRegisterService', 'CONST_HELPER', 'instagramServices', 'STRIPE_KEY', 'rootScopeService', 'ENABLE_CREDITS',
  function ($scope, $window, userProfileService, $state, $cookies, $filter, $timeout, paymentService, $rootScope, userAdventureDetailService, userRegisterService, CONST_HELPER, instagramServices, STRIPE_KEY, rootScopeService, ENABLE_CREDITS) {
    $scope.callSpecificFromToValueCalender = function () { // call calender element for date range in case of ambassador analytics
      if ($scope.user.role_id === 4) {
        $timeout(function () {
          angular.element(document.querySelector('#sandbox-container div')).datepicker({
            startDate: '-1y',
            endDate: 'now',
            maxViewMode: 0
          })
            .on('changeDate', function (e) {
              setSpecificFromToValues(e);
            });
        }, 100);
      }
    };
    if (typeof Stripe === 'undefined') {
      $scope.loadScript('https://js.stripe.com/v2/', function () {
        $scope.loadScript('https://js.stripe.com/v3/', function () {
          window.Stripe.setPublishableKey(STRIPE_KEY);
          Stripe(STRIPE_KEY);
          var existingModule = angular.module('heliApp');
          existingModule.requires.push('angular-stripe');
          $rootScope.$broadcast('stripe-loaded');
        });
      });
    }

    $scope.consumerProfile = {
      first_name: '',
      last_name: '',
      phone: ''
    };
    $scope.consumerProfileInfo = {
      username: '',
      email: ''

    };

    $scope.passwordData = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''

    };
    $scope.passwordForm = {};
    $scope.validation = {};
    $scope.validation.oldPasswordErr = false;
    $scope.validation.pwdLengthErr = false;
    $scope.showUserPhoneError = false;
    var profileImage;
    var coverImage; var viewAllCreditHistory = [];
    $scope.isUpdateValid = false;
    $scope.isUpdateDirtyReset = false;
    $scope.isSaveDetailsValid = false;
    $scope.ambassadorEnabled = false;
    $scope.creditHistory = [];
    $scope.credits = {};
    $scope.credits.currentPage = 1;

    $scope.enableCredits = ENABLE_CREDITS;
    $scope.activeBasicInformation = true;
    $scope.itemsPerPage = 10;
    $scope.form = {};
    /* ui cropper begins */
    $scope.myCroppedImage = '';
    $scope.profileUIImage = '';
    $rootScope.initializePhoneField("#userPhoneNumber");
    $scope.closeImage = function () { // Remove profile pic
      $scope.myCroppedImage = null;
      $scope.profileUIImage = null;
    };

    $scope.callCropFuntion = function () {
      profileImage = '';
      $scope.hidePopup('#cropProfileImagePopup');
      document.getElementById('fileInput').value = '';
      $scope.myCroppedImage = $scope.myCroppedImageAfterCrop;
    };

    $scope.getAllCountryName = function () {
      userRegisterService.getAllCountry().then(function (data) {
        if (data.data.code === CONST_HELPER.API_RESPONSE.OK) {
          $scope.allcountryName = data.data.data;
        }
      });
    };
    $scope.getAllCountryName();

    var handleFileSelect = function (evt) {
      $scope.minSingleImgSize = false; // hide error message
      $scope.maxSingleImgSize = false;// hide error message

      // image resolution validation
      var _URL = window.URL || window.webkitURL; // initiate _URL
      var file, img;

      // image min size validation
      if (evt.currentTarget.files[0].size < 50000) {
        showImgSizeError('min'); // show min image size error;
        return false;
      }

      file = evt.currentTarget.files[0];
      var fileExtnArr = file.name.split('.');
      var extn = fileExtnArr[fileExtnArr.length - 1].toLowerCase();
      if (extn === 'png' || extn === 'jpg' || extn === 'jpeg') { // check valid file extention
        var reader = new FileReader();
        reader.onload = function (evt) {
          $scope.$apply(function ($scope) {
            $scope.profileUIImage = evt.target.result;
          });
        };
        reader.readAsDataURL(file);
        // image resolution validation
        if ((file = this.files[0])) {
          img = new Image();
          img.onload = function () {
            $scope.showPopup('#cropProfileImagePopup'); // show crop image popup
          };
          img.src = _URL.createObjectURL(file);
        }
      } else {
        alert("Please upload a valid image format(acceptable formats 'png','jpg','gif')");
      }
    };

    $scope.cancelUICrop = function () {
      $scope.hidePopup('#cropProfileImagePopup');
      document.getElementById('fileInput').value = '';
      $scope.myCroppedImageAfterCrop = null;
      $scope.profileUIImage = null;
    };

    angular.element(document.querySelectorAll('#fileInput')).on('change', handleFileSelect);

    function showImgSizeError (type) { // function to show error message for profile image
      if (!$scope.$$phase) {
        $scope.$apply(function ($scope) {
          if (type === 'min') {
            $scope.minSingleImgSize = true; // show error message
          } else {
            $scope.maxSingleImgSize = true; // show error message
          }
        });
      }
    }

    // ui cropper ends

    // ui cropper cover pic begins

    $scope.coverCropped = '';
    $scope.coverCropperImage = '';

    $scope.closeCoverImage = function () { // Remove profile pic
      $scope.coverCropped = null;
      $scope.coverCropperImage = null;
    };

    $scope.callCoverFuntion = function () {
      coverImage = '';
      $scope.hidePopup('#uiCoverImagePopup');
      document.getElementById('coverfileInput').value = '';
      $scope.coverCropped = $scope.coverCroppedAfterCrop;
    };
    var handleFileCoverSelect = function (evt) {
      $scope.minCoverImgSize = false; // hide error message
      $scope.maxCoverImgSize = false;// hide error message

      // image resolution validation
      var _URL = window.URL || window.webkitURL; // initiate _URL
      var file, img;

      // image min size validation
      if (evt.currentTarget.files[0].size < 50000) {
        showCoverImgSizeError('min'); // show min cover image size error;
        return false;
      }

      file = evt.currentTarget.files[0];
      var fileExtnArr = file.name.split('.');
      var extn = fileExtnArr[fileExtnArr.length - 1].toLowerCase();
      if (extn === 'png' || extn === 'jpg' || extn === 'jpeg') { // check valid file extention
        var reader = new FileReader();
        reader.onload = function (evt) {
          $scope.$apply(function ($scope) {
            $scope.coverCropperImage = evt.target.result;
          });
        };
        reader.readAsDataURL(file);
        if ((file = this.files[0])) {
          img = new Image();
          img.onload = function () {
            $scope.showPopup('#uiCoverImagePopup'); // show crop image popup
          };
          img.src = _URL.createObjectURL(file);
        }
      } else {
        alert("Please upload a valid image format(acceptable formats 'png','jpg','gif')");
      }
    };

    $scope.cancelUICover = function () {
      $scope.hidePopup('#uiCoverImagePopup');
      document.getElementById('coverfileInput').value = '';
      $scope.coverCropperImage = null;
      $scope.coverCroppedAfterCrop = null;
    };

    angular.element(document.querySelectorAll('#coverfileInput')).on('change', handleFileCoverSelect);

    function showCoverImgSizeError (type) { // function to show error message for cover image
      if (!$scope.$$phase) {
        $scope.$apply(function ($scope) {
          if (type === 'min') {
            $scope.minCoverImgSize = true; // show error message
          } else {
            $scope.maxCoverImgSize = true; // show error message
          }
        });
      }
    }
    // ui cropper ends

    var userdata = $cookies.get('user');
    if (userdata) {
      $scope.user = JSON.parse(userdata);
    }
    if ($scope.user) { // get token
      var params = {
        token: $scope.user.token
      };
    }

    // get total currency list...
    userAdventureDetailService.getCurrency().then(function (res) {
      if (res.data.code === CONST_HELPER.API_RESPONSE.OK) {
        $scope.Currencies = res.data.data;
        $scope.Currencies.map(function (val) {
          val.symbol = ' (' + $scope.currencySymbol[val.code] + ')';
        });
      }
    }, function () { });

    $scope.getBasicInfo = function () { // fetch basic information for the user
      $rootScope.isUserLoggedIn();
      $scope.showLoader();
      userProfileService.getConsumerProfile(params).then(function (response) {
        if (response) {
          if (response.data.code === '401') { // unauthorised user
            $scope.logout();// unauthorised user
            $state.go('signin');
            return false;
          } else {
            $rootScope.user = Object.assign(response.data.data, $rootScope.user);
            rootScopeService.setValue('user', $rootScope.user);
            $rootScope.user.role_id = response.data.data.role_id;// update user role id
            $scope.user.role_id = response.data.data.role_id;// update user role id;
            $scope.callSpecificFromToValueCalender();
            $cookies.put('user', JSON.stringify($rootScope.user));// set local storage again
            $scope.consumerProfile = response.data.data.consumer_profile;// add to scope
            $scope.consumerProfile.phone = parseInt(response.data.data.consumer_profile.phone);
            if($scope.consumerProfile.phone && $scope.consumerProfile.phone.toString()[0] != "+"){
              $("#userPhoneNumber").intlTelInput("setNumber", '+' + $scope.consumerProfile.phone.toString());
            }else{
              if(!!$scope.consumerProfile.phone){
                $("#userPhoneNumber").intlTelInput("setNumber", $scope.consumerProfile.phone.toString());
              }else{
                $("#userPhoneNumber").intlTelInput("setNumber", '');
              }
            }
            $scope.consumerProfileInfo = response.data.data;
            $scope.consumerProfile.dob = ($scope.consumerProfile.dob) ? moment($scope.consumerProfile.dob).format('MM/DD/YYYY') : '';
            // defined inrootscope as used in main.js
            $rootScope.consumerSocialAccounts = (response.data.data.social_accounts) ? response.data.data.social_accounts : {};
            if (response.data.data.social_accounts) {
              $rootScope.consumerSocialAccounts.facebook_auto_sharing = (response.data.data.social_accounts.facebook_auto_sharing === 1);
              $rootScope.consumerSocialAccounts.twitter_auto_sharing = (response.data.data.social_accounts.twitter_auto_sharing === 1);
              $rootScope.consumerSocialAccounts.linkedin_auto_sharing = (response.data.data.social_accounts.linkedin_auto_sharing === 1);
            }
            $scope.myCroppedImage = $scope.user.profile_pic || response.data.data.consumer_profile.profile_pic;
            $scope.coverCropped = $scope.user.cover_pic || response.data.data.consumer_profile.cover_pic;
            if (response.data.data.role_id === 4) { // check if role id is influencer
              $scope.ambassadorEnabled = false;
              document.getElementById('publicPrivacy').setAttribute('disabled', 'disabled');
            } else if (response.data.data.role_id === 2 && response.data.data.influencer_status === 2) { // check if role id is consumer and request is pending from admin
              $scope.ambassadorEnabled = false; // disable button
            } else {
              $scope.ambassadorEnabled = true;// enable button if request is rejected
            }

            // profile default currency
            $scope.consumerProfile.defaultCurrencyCode = response.data.data.currency_code ? response.data.data.currency_code : '';
            $rootScope.localCurrencyCode = response.data.data.currency_code ? response.data.data.currency_code : $rootScope.localCurrencyCode;
            $rootScope.localCurrencySymbol = $scope.currencySymbol[$rootScope.localCurrencyCode];

            $scope.hideLoader();

            let goToCredits = $cookies.get('goToCredits');
            $scope.isCredits = goToCredits ? JSON.parse(goToCredits) : 0;
            if ($scope.isCredits) {
              $scope.getAmbassadorCredits($scope.credits.currentPage);
              $cookies.put('goToCredits', false)
            }
          }
        }
      }, function (error) { // check for error
        errScenario();
      });
    };

    $scope.saveDetails = function (formInvalid, form) { // save basic details of the user
      $scope.isSaveDetailsValid = true;
      if (formInvalid) {
        return;
      };
      if ($("#userPhoneNumber").intlTelInput("getNumber") == '' || $("#userPhoneNumber").intlTelInput("getNumber").length < 8 || $("#userPhoneNumber").intlTelInput("getNumber").length > 16) {
        $scope.showUserPhoneError = true;
        return;
      } else {
        $scope.showUserPhoneError = false;
      };
      $scope.showLoader();
      var data = {
        'first_name': $scope.consumerProfile.first_name,
        'last_name': $scope.consumerProfile.last_name,
        'phone': $("#userPhoneNumber").intlTelInput("getNumber"),
        'profile_pic': profileImage ? '' : $scope.myCroppedImage,
        'cover_pic': coverImage ? '' : $scope.coverCropped,
        'username': $scope.consumerProfileInfo.username,
        'email': $scope.consumerProfileInfo.email,
        'dob': ($scope.consumerProfile.dob) ? moment($scope.consumerProfile.dob).format('YYYY-MM-DD') : '',
        'currency_code': $scope.consumerProfile.defaultCurrencyCode,
        'tagline': $scope.consumerProfile.tagline,
        'tag_description': $scope.consumerProfile.tag_description,
        'country_id': $scope.consumerProfile.country_id
      };

      userProfileService.addBasicConsumerDetails(data, params).then(function (response) {
        if (response) {
          if (response.data.code === '401') { // unauthorised user
            $scope.logout();// unauthorised user
            $state.go('signin');
            return false;
          } else if (response.data.code === '422') {
            if (response.data.message.username) {
              $scope.errorUserMsg = response.data.message.username.isUsernameExists;
            } else {
              $scope.errorEmailMsg = response.data.message.email.isEmailExists;
            }
          } else if (response.data.code === '400') {
            $scope.showPopup('#serverErrModal');
          } else if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
            $scope.showPopup('#saveProfileDetailModal');
            $scope.user = response.data.data;
            updateScopeUser(response.data.data); // update the $scope.user
            $rootScope.localCurrencyCode = response.data.data.currency_code ? response.data.data.currency_code : $rootScope.localCurrencyCode;
            $rootScope.localCurrencySymbol = $scope.currencySymbol[$rootScope.localCurrencyCode];
          }
          $scope.hideLoader();
        }
      }, function (error) { // check for error
        errScenario();
      });
    };

    function updateScopeUser (user) {
      $rootScope.user.name = user.consumer_profile.first_name + ' ' + user.consumer_profile.last_name;
      $rootScope.user.email = user.email;
      $rootScope.user.phone = user.consumer_profile.phone;
      $rootScope.user.username = user.username;
      $rootScope.user.profile_pic = user.consumer_profile.profile_pic;
      $scope.user.profile_pic = user.consumer_profile.profile_pic;
      $rootScope.user.cover_pic = user.consumer_profile.cover_pic;
      $scope.user.cover_pic = user.consumer_profile.cover_pic;
      $rootScope.user.currency_code = user.currency_code;
      $rootScope.user.country_id = user.country_id;
      $scope.user.country_id = user.country_id;
      $rootScope.userLocalCountry = user.country_id;
      $cookies.put('user', JSON.stringify($rootScope.user));// set local storage again
      $cookies.put('user_country_id', JSON.stringify($scope.user.country_id));
      $scope.getBasicInfo()
    }

    $scope.toggleFeatures = function (data, type) {
      var obj = {};
      if (type === 'is_public') {
        obj = {
          'is_public': data
        };
      } else {
        obj = {
          'is_auto_sharing': data
        };
      }
      userProfileService.addPrivacy(obj, params).then(function (response) {
        if (response) {
          if (response.data.code === '401') { // unauthorised user
            $scope.logout();// unauthorised user
            $state.go('signin');
            return false;
          } else if (response.data.code === '422') {
            $scope.isInfluencer = response.data.message.is_public.isInfluencer;
            $scope.getBasicInfo();
          } else {
            $rootScope.user.is_auto_sharing = response.data.data.is_auto_sharing;
            $rootScope.user.is_public = response.data.data.is_public;
            $cookies.put('user', JSON.stringify($rootScope.user));// set local storage again
          }
        }
      }, function (error) { // check for error
      });
    };

    $scope.updatePassword = function (formInvalid, passwordData) { // change password of the user
      $scope.isUpdateValid = true;
      if (formInvalid) {
        return;
      }
      var data = {
        'old_password': $scope.passwordData.oldPassword,
        'new_password': $scope.passwordData.newPassword
      };
      userProfileService.changePassword(data, params).then(function (response) {
        if (response) {
          if (response.data.code === '401') { // unauthorised user
            $scope.logout();// unauthorised user
            $state.go('signin');
            return false;
          } else if (response.data.code === '422') {
            $scope.errorOldPassword = response.data.message;
            $scope.validation.oldPasswordErr = true;
          } else if (response.data.code === '400') {
            $scope.errorPwdLength = response.data.message;
            $scope.validation.pwdLengthErr = true;
          } else {
            $scope.showPopup('#passwordChangeModal');
            $scope.isUpdateDirtyReset = true;
            $scope.passwordData = {};
            $scope.form.passwordForm.$pristine = true;
          }
        }
      }, function (error) { // check for error
        $scope.showPopup('#serverErrModal');
      });
    };

    $scope.broadcastToGetCardsList = function () { // fetch cards list
      $rootScope.$broadcast('card-saved');
    };

    $scope.broadcastToDeleteSavedCard = function () { // delete saved card
      $rootScope.$broadcast('delete-card');
    };

    $scope.applyForAmbassador = function () { // apply for becoming an ambassador
      userRegisterService.isEmailVerified($scope.user.token).then(function (res) {
        if (res.data && res.data.code === CONST_HELPER.API_RESPONSE.OK) {
          $scope.user.is_email_verified = 1;
          $('.alert-success').css('display', 'none');
          $scope.setUserDetails($scope.user, 'second', true);

          userProfileService.becomeAmbassador(params).then(function (response) {
            if (response) {
              if (response.data.code === '401') { // unauthorised user
                $scope.logout();// unauthorised user
                $state.go('signin');
                return false;
              } else if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
                $scope.showPopup('#applyInfluencer');
                $scope.ambassadorEnabled = false;
                $('#ambassador').css('pointer-events', 'none');
              } else if (response.data.code === '422') {
                $scope.errorAlreadyApplied = response.data.message;
                $scope.ambassadorEnabled = false;
                $('#ambassador').css('pointer-events', 'none');
              }
            }
          }, function (error) { // check for error
            $scope.showPopup('#serverErrModal');
          });
        } else {
          $scope.hideLoader();
          $rootScope.emailSent = false;
          $('.alert-success').css('display', 'block');
        }
      });
    };

    // get ambassador total credits
    $scope.getAmbassadorCredits = function (page) {
      $scope.showLoader();
      // text to be shared while referrals
      $scope.socialShareMsg = 'Join me on Heli';
      // set data for total credits
      var data = { token: $scope.user.token, limit: 10, page: page };
      userProfileService.getTotalCredits($scope.user.role_id, data).then(function (response) {
        // if successful
        if (response.data.code === CONST_HELPER.API_RESPONSE.OK) {
          // set credit_history data
          $scope.creditHistory = response.data.data.credit_history;

          $rootScope.user = Object.assign(response.data.data, $rootScope.user);
          rootScopeService.setValue('user', $rootScope.user);
          // set credits data
          $scope.credits = response.data.data.credits;
          $scope.creditData = response.data.data.credit_history;
          $scope.totalViewItems = response.data.count; // set pagination count
          $rootScope.user.commissions = response.data.data.commissions;
          $cookies.put('user', JSON.stringify($rootScope.user));// set local storage again
          $scope.user.commissions = response.data.data.commissions;
          // set percentage or dollar calculate for commissions
          if ($rootScope.user.commissions.referral_type === '1') {
            $scope.inviteRate = '$' + $rootScope.user.commissions.referral_value;
          } else {
            $scope.inviteRate = $rootScope.user.commissions.referral_value + '%';
          }

        } else {
          $scope.credits.available_credits = 0;
          $scope.credits.earned_credits = 0;
          $scope.credits.total_joined = 0;
        }
        $scope.hideLoader();
      }, function (error) { // check for errors
        errScenario();
      });
    };

    $rootScope.getPaginatedCredits = function() { // use for watch current page value changes
      if ($scope.creditData && $scope.credits.currentPage) { // watch if user on credit and referral history page
        $scope.getAmbassadorCredits($scope.credits.currentPage); // call  function for set paginagtion
      }
    };

    $scope.chartOption = 'credits';// set default option for chart and graph
    $scope.barOption = 'credits';// set default option for bar graph
    $scope.hasBarData = true;// show bar canvas

    $scope.lineChart = [];// set modal for line chart
    $scope.barChart = []; // set modal for bar chart
    $scope.pieChart = [];

    initializeLineChart(); // intialize chart options
    initializeBarChart();// intialize adventure graph option
    initializePieChart(); // initialize google analytics chart

    function initializeLineChart () { // intialize chart options
      $scope.lineChart.lineOptions = {
        elements: {
          line: {
            tension: 0
          }
        },
        scales: {
          xAxes: [{
            ticks: {
              labelOffset: 0,
              padding: 20,
              autoSkip: false,
              min: 0
            },
            gridLines: {
            // drawTicks : false
            }
          }],
          yAxes: [{
            ticks: {
              labelOffset: 20,
              padding: 10,
              autoSkip: false,
              min: 0
            },
            gridLines: {
              drawTicks: false
            }
            // ,
            // scaleLabel: {
            //     display: true,
            //     labelString: '1k = 1000'
            // }
          }]
        }
      };
      $scope.lineChart.datasetOverride = [{ backgroundColor: [] }];// set color options for graph and points on line
      $scope.lineChart.datasetOverride[0].backgroundColor = 'rgba(67, 133, 245,0.2)';
      $scope.lineChart.datasetOverride[0].borderColor = 'rgba(67, 133, 245, 0.7)';
      $scope.lineChart.datasetOverride[0].pointBorderColor = 'rgba(67, 133, 245, 0.7)';
      $scope.lineChart.datasetOverride[0].pointBackgroundColor = 'rgb(255,255,255)';
      $scope.lineChart.datasetOverride[0].pointRadius = 4;
    }

    function initializeBarChart () { // initialize bar graph for adventures listing
      $scope.barChart.colors = ['rgba(63,186,250,0.5)', 'rgba(255,99,132,0.5)', 'rgba(255,159,64,0.5)', 'rgba(184, 147, 255, 0.4)', 'rgba(109, 235, 235, 0.4)', 'rgba(255,64,163,0.5)', 'rgba(64,223,86,0.5)', 'rgba(240,219,44,0.5)', 'rgba(199,111,241,0.5)', 'rgba(49,105,217,0.5)'];
      $scope.barChart.borderColors = ['rgba(63,186,250,0.7)', 'rgba(255,99,132,0.7)', 'rgba(255,159,64,0.7)', 'rgba(184,147,255,0.7)', 'rgba(109,235,235,0.7)', 'rgba(255,64,163,0.7)', 'rgba(64,223,86,0.7)', 'rgba(240,219,44,0.7)', 'rgba(199,111,241,0.7)', 'rgba(49,105,217,0.7)'];
      // Create a place to hold the background colours.
      $scope.barChart.datasetOverride = [{ backgroundColor: [] }];
      // Assign the colours to the pre-populated array.
      $scope.barChart.datasetOverride[0].backgroundColor = $scope.barChart.colors;
      $scope.barChart.datasetOverride[0].borderColor = $scope.barChart.borderColors;
      $scope.barChart.options = {
        scales: {
          xAxes: [{
            ticks: {
              min: 0,
              callback: function (label, index, labels) {
                return parseFloat(label).toFixed(2);
              }
            }
          }],
          yAxes: [{
            ticks: {
              padding: 10
            },
            barPercentage: 0.6,
            gridLines: {
              drawTicks: false
            }
          }]
        }
      };
    }
    function initializePieChart () { // initialize pie chart options
      $scope.pieChart.colors = ['rgba(63,186,250,0.7)', 'rgba(255,99,132,0.7)', 'rgba(255,159,64,0.7)', 'rgba(184, 147, 255, 0.7)', 'rgba(109, 235, 235, 0.7)', 'rgba(255,64,163,0.7)', 'rgba(64,223,86,0.5)', 'rgba(240,219,44,0.5)', 'rgba(199,111,241,0.5)', 'rgba(49,105,217,0.5)'];
      $scope.pieChart.datasetOverride = [{ backgroundColor: [] }];
      // Assign the colours to the pre-populated array.
      $scope.pieChart.datasetOverride[0].backgroundColor = $scope.pieChart.colors;
      $scope.pieChart.datasetOverride[0].borderColor = 'rgb(255,255,255)';
    }

    $scope.getAnalyticsData = function (from) { // fetch overall stats data
      if ($scope.user.role_id === 4) { // if an influencer only then hit the api
        $scope.showLoader();
        if (from === 'firstLoad') {
          $scope.toDate = '';
          $scope.fromDate = '';
          getLastMonthDate();
        }
        var data = { fromDate: $scope.fromDate, toDate: $scope.toDate, token: $scope.user.token };
        userProfileService.getAnalyticsResult(data).then(function (response) { // fetch result from the service
          if (response && response.data.code === CONST_HELPER.API_RESPONSE.OK) { // success response
            $scope.grid = response.data.grid;// grid element
            $scope.categoriesData = response.data.line_graph;
            setLineChart($scope.categoriesData, 'credits');
            $scope.adventuresData = response.data.bar_graph;
            setBarChart($scope.adventuresData, 'credits');
            if($scope.grid && $scope.categoriesData){
              setCSVAnalyticsList();
            }
            $scope.hideLoader();
          } else {
            $scope.hideLoader();
            errScenario();
          }
        }, function (error) {
          $scope.hideLoader();
          if (!error.data || !error.data.code || String(error.data.code) !== CONST_HELPER.API_RESPONSE.NOT_FOUND) {
            errScenario();
          }
        });
        userProfileService.getTrafficAnalytics(data).then(function (response) { // fetch result from the service
          if (response && response.data.code === CONST_HELPER.API_RESPONSE.OK) { // success response
            setPieChart(response.data.data);
            $scope.trafficPieChartData = response.data.data;
            if (response.data.data && response.data.data.referral_value.length > 0) {
              $scope.totalTraficCount = eval(response.data.data.referral_value.join('+'));
            }
            if($scope.grid && $scope.categoriesData){
              setCSVAnalyticsList();
            }
          } else {
            errScenario();
          }
        }, function (error) {
          $scope.hideLoader();
          if (!error.data || !error.data.code || String(error.data.code) !== CONST_HELPER.API_RESPONSE.NOT_FOUND) {
            errScenario();
          }
        });
      }
    };

    function setPieChart (data) {
      if (data && data.referral_type && data.referral_type.length > 0) {
        data.referral_type[0] = 'Direct';
        $scope.pieChart.labels = data.referral_type;
        $scope.pieChart.data = [data.referral_value];
      }
    }

    function errScenario () { // err case
      $scope.hideLoader();
      $scope.showPopup('#serverErrModal');
    }

    function setLineChart (data, option) { // set line chart for option
      $scope.lineChart.labels = data.date;
      $scope.setLineChartData(option, data);// set data for y axis according to option selected
    }

    $scope.setLineChartData = function (option, data) { // populate graph according to selected option
      $scope.chartOption = option;
      if (option !== 'credits') {
        $scope.lineChart.lineOptions.scales.yAxes[0].ticks.callback = function (label, index, labels) {
          if (label % 1 === 0) { return label; }
        };
      }
      switch (option) { // change the  graph view according to selected option
        case 'credits' :
          $scope.lineChart.lineOptions.scales.yAxes[0].ticks.callback = function (label, index, labels) {
            return '$' + $filter('number')(label, 2);
          };
          lineAxesData(data.credits, 'Credits');
          break;
        case 'purchases' :
          lineAxesData(data.orders, 'Purchases');
          break;
        case 'views' :
          lineAxesData(data.views, 'Views');
          break;
        case 'likes' :
          lineAxesData(data.likes, 'Likes');
          break;
        case 'shares' :
          lineAxesData(data.shares, 'Shares');
          break;
        case 'radar' :
          lineAxesData(data.radar, 'Add to Radar');
          break;
      }
    };

    $scope.setBarChartData = function (option, data) {
      $scope.barOption = option;
      $scope.barChart.options.scales.xAxes[0].ticks.callback = function (label, index, labels) {
        if ($scope.barOption === 'credits') {
          return '$' + $filter('number')(label, 2);
        } else {
          if (label % 1 === 0) {
            return label;
          }
        }
      };
      switch (option) { // change the  graph view according to selected option
        case 'credits' :
          barPercentageSet(data.credit_package);
          barAxesData(data.credit_package, data.credits);
          break;
        case 'purchases' :
          barPercentageSet(data.order_package);
          barAxesData(data.order_package, data.orders);
          break;
        case 'views' :
          barPercentageSet(data.view_package);
          barAxesData(data.view_package, data.views);
          break;
        case 'likes' :
          barPercentageSet(data.like_package);
          barAxesData(data.like_package, data.likes);
          break;
        case 'shares' :
          barPercentageSet(data.share_package);
          barAxesData(data.share_package, data.shares);
          break;
        case 'radar' :
          barPercentageSet(data.radar_package);
          barAxesData(data.radar_package, data.radars);
          break;
      }
    };

    function setBarChart (data, option) { // set bar chart for option
      $scope.barChart.labels = data.date;
      $scope.setBarChartData(option, data);// set data for y axis according to option selected
    }

    $scope.setisCustomFileShowHide = function (isShow) {
      if (isShow) {
        angular.element(document.querySelector('.analytics-dropdown')).toggle();
      } else {
        angular.element(document.querySelector('.analytics-dropdown')).css('display', 'none');
      }
      $rootScope.isCustomCalendarClick = true; // to handle document click to close the custom filter dropdown
    };

    $timeout(function () {
      $scope.setisCustomFileShowHide(false);
    }, 100);

    function getLastMonthDate () { // get last month date
      $scope.displayFromdate = moment().subtract(29, 'days').format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
      $scope.fromDate = moment().subtract(29, 'days').format(CONST_HELPER.analytics_filter.DATE_FORMAT);
      $scope.displayTodate = moment().format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
      $scope.toDate = moment().format(CONST_HELPER.analytics_filter.DATE_FORMAT); // this variable
      $timeout(function () { // hide date picker
        $scope.setisCustomFileShowHide(false);
      }, 100);
    }

    function barPercentageSet (arr) {
      if (arr.length > 3) {
        $scope.hasBarData = true;
        $scope.barChart.options.scales.yAxes[0].barPercentage = 0.6;
      } else {
        $scope.hasBarData = true;
        $scope.barChart.options.scales.yAxes[0].barPercentage = 0.2;
        if (arr.length === 0) {
          $scope.hasBarData = false;
        }
      }
    }

    function lineAxesData (data, series) { // set data on line axes
      $scope.lineChart.data = [data];
      $scope.lineChart.series = [series];
    }

    function barAxesData (packageData, value) { // set data on line axes
      var i = 0;
      $scope.barChart.labels = [];
      while (i < packageData.length) { // set length limit for ellipses
        $scope.barChart.labels[i] = (packageData[i].length > 25) ? packageData[i].substr(0, 25) + '...' : packageData[i];
        i++;
      }

      $scope.barChart.data = [value];
      $scope.barChart.options.tooltips = {// set custom tootlips values
        enabled: true,
        mode: 'single',
        callbacks: {
          label: function (tooltipItem, data) {
            var label = packageData[tooltipItem.index];
            var datasetLabel = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return label + ': ' + datasetLabel;
          },
          title: function (tooltipItem, data) {
            return packageData[tooltipItem.index];
          }
        }
      };
    }
    $scope.isCustomCalendarFilter = false; // whether to show Complete custom filter dropdown
    $scope.isFromToCalendar = false; // whether to show specific From and To
    $scope.getFullCsvList = [];

    function refrestSelectPicker () {
      $scope.chartOption = 'credits'; // for mobile view
      $scope.barOption = 'credits';// set default option for bar graph
      // to select the sales tab as active on both the graph
      angular.element('.to-set-credit').find('li').removeClass('active');
      angular.element('.to-set-credit').find('li:first').addClass('active');
      $timeout(function () {
        angular.element('.influencer-line-chart-dropdown').selectpicker('refresh');// initiate dropdowns
        angular.element('.influencer-bar-chart-dropdown').selectpicker('refresh');// initiate dropdowns
      }, 10);
    }

    refrestSelectPicker();

    function setSpecificFromToValues (e) {
      var specific_to_date = angular.element(document.querySelector('#specific-to-date'));
      var specific_from_date = angular.element(document.querySelector('#specific-from-date'));
      if ($scope.isSpecificFromSelected) {
        // $scope.isSpecificFromSelected is true means From specific textbox value is been selected from the emebeded calendar
        $scope.toDate = moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT);
        $scope.fromDate = $scope.fromDateTemp; // update the tempororay value into real fromDate variable
        // Specific from Date is lesser than Specific To Date
        if (moment($scope.fromDate, CONST_HELPER.analytics_filter.DATE_FORMAT).isBefore(moment($scope.toDate))) {
          specific_to_date.val(moment(e.date).format('MMM D YYYY'));
        } else {
        // Selected Specific To Date is lesser than From.So, Selected date will become Specific From date and already Selected From date will be To date
          $scope.toDate = $scope.fromDate;
          specific_to_date.val(specific_from_date.val());
          specific_from_date.val(moment(e.date).format('MMM D YYYY'));
          $scope.fromDate = moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT);
        }
        $scope.isSpecificFromSelected = false; // now again if calendar will be clicked then Specific From date textbox will be selected
        

        $scope.setisCustomFileShowHide(false); // hide the popup when To date is selected

        $scope.displayFromdate = moment($scope.fromDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
        $scope.displayTodate = moment($scope.toDate).format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
        refrestSelectPicker();
      } else {
        specific_from_date.val(moment(e.date).format('MMM D YYYY'));
        // fromDateTemp is used so in case if user only clicks for From date then actual variable shouldn't get updated
        $scope.fromDateTemp = moment(e.date).format(CONST_HELPER.analytics_filter.DATE_FORMAT);
        specific_to_date.val('');
        $scope.isSpecificFromSelected = true;
      }
    }

    $scope.downLoadPDF = function () {
      $scope.showLoader();
      var doc = new jsPDF();
      var pdfFormatList = [{ // for Analytics Text
        param: '',
        type: 'text',
        right: 10,
        top: 10,
        text: 'Analytics'
      }, { // for Horizontal line
        param: '',
        type: 'line',
        x1: 5,
        x2: 205,
        y1: 14,
        y2: 14
      }, { // for first filtered data
        param: 'row-first-pdf',
        type: 'image',
        right: 5,
        top: 20,
        width: 200,
        height: 15
      }, { // for second filtered data
        param: 'row-second-pdf',
        type: 'image',
        right: 5,
        top: 50, // difference of 10 between eache lement
        width: 200,
        height: 15
      }, { // for first chart
        param: 'row-third-pdf',
        type: 'image',
        right: 5,
        top: 80,
        width: 200,
        height: 130
      }, { // for second chart
        param: 'row-fourth-pdf',
        type: 'image',
        right: 5,
        top: 10,
        width: 200,
        height: 150
      }];
      generatePDF_file(0, doc, pdfFormatList, function (doc) {
        doc.save('Report.pdf');
      });
    };

    function generatePDF_file (index, doc, pdfFormatList, cb) {
      if (index === pdfFormatList.length) {
        $scope.hideLoader();
        cb(doc);
        return;
      }
      if (pdfFormatList[index].param === 'row-fourth-pdf') { doc.addPage(); } // last chart will be on second page of PDF

      generatePDF(doc, pdfFormatList[index], function (doc) {
        generatePDF_file(index + 1, doc, pdfFormatList, cb);
      });
    }

    function generatePDF (doc, pdfFormat, cb) {
      switch (pdfFormat.type) {
        case 'line':
          doc.line(pdfFormat.x1, pdfFormat.y1, pdfFormat.x2, pdfFormat.y2); // x1 , y1, x2, y2  draw horizontal line
          cb(doc);
          break;
        case 'text':
          doc.text(pdfFormat.right, pdfFormat.top, pdfFormat.text);
          cb(doc);
          break;
        case 'image':
          getCanvasImage(pdfFormat.param, function (imageData) {
            doc.addImage(imageData, 'PNG', pdfFormat.right, pdfFormat.top, pdfFormat.width, pdfFormat.height); // data,format,right side , top side , width , height
            cb(doc);
          });
          break;
      }
    }

    function getCanvasImage (param, cb) {
      html2canvas(document.getElementsByClassName(param), {
        onrendered: function (canvas) {
          var data = canvas.toDataURL();
          cb(data);
        }
      });
    }

    function setCSVAnalyticsList () { // create csv file for operator analyticslist
      $scope.getFullCsvList = [];
      $scope.getFullCsvList.push(
        {
          analytics_report: 'Analytics Report',
          fromDate: $scope.fromDate,
          to: 'To',
          toDate: $scope.toDate
        },
        {},
        {
          stats: 'Stats'
        },
        {
          total_credits: 'Total Credits',
          total_purchases: 'Total Purchases',
          total_joined: 'Total Joined',
          total_views: 'Total Views',
          total_likes: 'Total Likes',
          total_shares: 'Total Shares',
          add_to_radar: 'Add To Radar'
        },
        {
          total_credits: Number($scope.grid.total_credits).toFixed(2),
          total_purchases: $scope.grid.total_purchases,
          total_joined: $scope.grid.total_joined,
          total_views: $scope.grid.total_views,
          total_likes: $scope.grid.total_likes,
          total_shares: $scope.grid.total_shares,
          add_to_radar: $scope.grid.add_to_radar
        },
        {},
        {
          line_graph: 'Line Graph:'
        },
        {
          date: 'Date',
          credit: 'Credits',
          order: 'Orders',
          views: 'Views',
          likes: 'Likes',
          share: 'Shares',
          add_to_radar: 'Add To Radar'
        }
      );

      for (var i = 0; i < $scope.categoriesData.date.length; i++) {
        $scope.getFullCsvList.push(
          {
            date: $scope.categoriesData.date[i],
            credit: Number($scope.categoriesData.credits[i]).toFixed(2),
            order: $scope.categoriesData.orders[i],
            views: $scope.categoriesData.views[i],
            likes: $scope.categoriesData.likes[i],
            share: $scope.categoriesData.shares[i],
            add_to_radar: $scope.categoriesData.radar[i]
          });
      }

      $scope.getFullCsvList.push({}, {
        most_popular_adventures: 'Most Popular Adventures:'
      });

      $scope.getFullCsvList.push(genericFunForAnalyticsCsv($scope.adventuresData.credit_package, 'adventure', 'Adventure:'));
      $scope.getFullCsvList.push(genericFunForAnalyticsCsv($scope.adventuresData.credits, 'credit', 'Credits:'));
      $scope.getFullCsvList.push({}, genericFunForAnalyticsCsv($scope.adventuresData.order_package, 'adventure', 'Adventure:'));
      $scope.getFullCsvList.push(genericFunForAnalyticsCsv($scope.adventuresData.orders, 'purchases', 'Purchases:'));
      $scope.getFullCsvList.push({}, genericFunForAnalyticsCsv($scope.adventuresData.view_package, 'adventure', 'Adventure:'));
      $scope.getFullCsvList.push(genericFunForAnalyticsCsv($scope.adventuresData.views, 'view', 'Views:'));
      $scope.getFullCsvList.push({}, genericFunForAnalyticsCsv($scope.adventuresData.like_package, 'adventure', 'Adventure:'));
      $scope.getFullCsvList.push(genericFunForAnalyticsCsv($scope.adventuresData.likes, 'like', 'Likes:'));
      $scope.getFullCsvList.push({}, genericFunForAnalyticsCsv($scope.adventuresData.share_package, 'adventure', 'Adventure:'));
      $scope.getFullCsvList.push(genericFunForAnalyticsCsv($scope.adventuresData.shares, 'share', 'Shares:'));
      $scope.getFullCsvList.push({}, genericFunForAnalyticsCsv($scope.adventuresData.radar_package, 'adventure', 'Adventure:'));
      $scope.getFullCsvList.push(genericFunForAnalyticsCsv($scope.adventuresData.radars, 'radars', 'Add To Radar:'));
      $scope.getFullCsvList.push(genericFunForAnalyticsCsv($scope.trafficPieChartData ? $scope.trafficPieChartData.referral_type : [], 'referral_type', 'Referral Type:'));
      $scope.getFullCsvList.push(genericFunForAnalyticsCsv($scope.trafficPieChartData ? $scope.trafficPieChartData.referral_value : [], 'referral_value', 'Value:'));
    }

    function genericFunForAnalyticsCsv (list, base_key, head_Text) { // generic function for create csv file in case of most populer adventures
      var obj = {};
      obj[base_key + 0] = head_Text;
      for (var i = 0; i < list.length; i++) {
        obj[base_key + (i + 1)] = list[i];
      }
      return obj;
    }

    $scope.clickEvntSpecificDates = function () { // prevent the close of dropdown on click of From and To textbox
      $rootScope.isCustomCalendarClick = true; // to handle document click to close the custom filter dropdown
    };

    $scope.setFromToDate = function (param, event) {
      switch (param) {
        case '7days' :
          $scope.displayFromdate = moment().subtract(6, 'days').format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
          $scope.fromDate = moment().subtract(6, 'days').format(CONST_HELPER.analytics_filter.DATE_FORMAT);
          $scope.setisCustomFileShowHide(false);
          $scope.isFromToCalendar = false;
          break;
        case '30days' :
          $scope.displayFromdate = moment().subtract(29, 'days').format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);

          $scope.fromDate = moment().subtract(29, 'days').format(CONST_HELPER.analytics_filter.DATE_FORMAT);
          $scope.setisCustomFileShowHide(false);
          $scope.isFromToCalendar = false;
          break;
        case '12months' :
          $scope.displayFromdate = moment().subtract(11, 'months').format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
          $scope.fromDate = moment().subtract(11, 'months').format(CONST_HELPER.analytics_filter.DATE_FORMAT);
          $scope.setisCustomFileShowHide(false);
          $scope.isFromToCalendar = false;
          break;
      }
      angular.element(document.querySelector('.fa')).removeClass('fa'); // remove the class from all the elements
      angular.element(document.querySelector('.fa-check')).removeClass('fa-check'); // remove the class from all the elements
      event.target.getElementsByTagName('i')[0].classList.add('fa'); // add the class on the triggered element
      event.target.getElementsByTagName('i')[0].classList.add('fa-check'); // add the class on the triggered element
      $scope.displayTodate = moment().format(CONST_HELPER.analytics_filter.DATE_FORMAT_DISPLAY);
      $scope.toDate = moment().format(CONST_HELPER.analytics_filter.DATE_FORMAT); // this variable
      refrestSelectPicker();
    };

    $scope.setSpecifciDates = function (event) {
      $scope.isFromToCalendar = true;
      $scope.displaySpecificFrom = moment().format('MMM D YYYY');
      $scope.displaySpecificTo = moment().format('MMM D YYYY');
      $rootScope.isCustomCalendarClick = true; // to handle document click to close the custom filter dropdown
      angular.element(document.querySelector('.fa')).removeClass('fa'); // remove the class from all the elements
      angular.element(document.querySelector('.fa-check')).removeClass('fa-check'); // remove the class from all the elements
      // event.target.getElementsByTagName("i")[0].classList.add("fa"); //add the class on the triggered element
      angular.element(event.target).find('i').addClass('fa');
      // event.target.getElementsByTagName("i")[0].classList.add("fa-check"); //add the class on the triggered element
      angular.element(event.target).find('i').addClass('fa-check');
    };

    $('[data-toggle="tooltip"]').tooltip();

    // connect to instagram...
    $scope.connectInstagram = function () {
      instagramServices.authenticate(function (accessToken) {
        if (accessToken.length > 0) {
        // get the user instagram profile...
          $scope.showLoader();
          instagramServices.getInstaProfile(accessToken, function (data) {
            if (data.hasOwnProperty('username')) {
              $scope.instaProfile = data;
              console.log($scope.instaProfile);
              var dataObj = {
                'instagram_username': data.username,
                'instagram_access_token': accessToken
              };
              userProfileService.connectInstagram(dataObj, $scope.user.token).then(function (res) {
                $scope.consumerProfileInfo = res.data.data; // update consumer profile data
                $scope.hideLoader();
              });
            } else {
              $scope.hideLoader();
              $scope.showPopup('#serverErrModal');
            }
          });
        }
      });
    };
    // disconnect user instagram account.
    $scope.disconnectInstagram = function () {
      $scope.showLoader();
      var data = {
        'instagram_username': '',
        'instagram_access_token': ''
      };
      userProfileService.connectInstagram(data, $scope.user.token).then(function (res) {
        $scope.consumerProfileInfo = res.data.data; // update consumer profile data
        $scope.hideLoader();
      });
    };

    // Redirecting user from payment page to add bank account page
    if ($rootScope.redirectPaymentTab) {
      $timeout(function () {
        $scope.broadcastToGetCardsList();
      });
    }
  }]);
