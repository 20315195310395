import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from "angular";
import { helper } from "../helper";

/**
 * @ngdoc service
 * @name TaxesService
 * @description
 * # taxesService
 * Service in the heliApp.
 */

@Injectable()

export class TaxesService {
  private CONST_HELPER;

  constructor() {
    this.CONST_HELPER = helper;
  }
  /**
   * Returns price of tax
   * @param config {Object} Properties:
   *    tax {Object}
   *    packagePrice {number}
   *    isDeposit {boolean}
   *    isCollectPercentageTaxesOnTheDeposit {boolean} Default = true
   *    personsCount {number}
   *    duration {number}
   *    userCountryId  {number}
   * @returns {number}
   */
  calculateTaxPrice = (config) => {
    let price = 0.0;

    // Check tax type according user's country: Domestic or International
    if (
      (
        (config.tax.country_id === config.userCountryId)
        &&
        (config.tax.type !== this.CONST_HELPER.OPERATOR_TAXES.TYPE.DOMESTIC.ID)
      )
      ||
      (
        (config.tax.country_id !== config.userCountryId)
        &&
        (config.tax.type !== this.CONST_HELPER.OPERATOR_TAXES.TYPE.INTERNATIONAL.ID)
      )
    ) {
      return price;
    }

    if (
      // Deposit payment
      config.isDeposit
      &&
      (
        // Amount taxes should not be included without any conditions
        (config.tax.calculation_type === this.CONST_HELPER.OPERATOR_TAXES.CALCULATION_TYPE.AMOUNT.ID)
        ||
        // Percentage taxes should not be included if according flag is not set
        (
          (config.tax.calculation_type === this.CONST_HELPER.OPERATOR_TAXES.CALCULATION_TYPE.PERCENTAGE.ID)
          &&
          !config.isCollectPercentageTaxesOnTheDeposit
        )
      )
    ) {
      return price;
    }

    // Process calculation type of tax
    if (config.tax.calculation_type === this.CONST_HELPER.OPERATOR_TAXES.CALCULATION_TYPE.PERCENTAGE.ID) {
      price += config.packagePrice * config.tax.value / 100;
    } else if (config.tax.calculation_type === this.CONST_HELPER.OPERATOR_TAXES.CALCULATION_TYPE.AMOUNT.ID) {
      price += config.tax.value;
    }

    if (config.tax.is_flat) {
      // Taxes of this type applied only once to whole booking slot price
      price *= 1;
    } else {
      if (config.tax.is_per_person) {
        // Taxes of this type applied to booking slot price for each person
        price *= config.personsCount;
      }
      if (config.tax.is_per_night_day) {
        // Taxes of this type applied to booking slot price for each day or night
        price *= config.duration;
      }
    }

    return price;
  };

  /**
   * Calculate summary price of list of taxes
   * @param config {Object} Properties:
   *    taxes {Array}
   *    packagePrice {number}
   *    isDeposit {boolean} Default = false
   *    isCollectPercentageTaxesOnTheDeposit {boolean} Default = true
   *    personsCount {number}
   *    duration {number}
   *    userCountryId  {number}
   * @returns {number}
   */
  calculateTaxesSummaryPrice = (config) => {
    let price = 0.0;
    if (config.taxes.length > 0) {
      for (let i = 0; i < config.taxes.length; ++i) {
        price += this.calculateTaxPrice({
          tax: config.taxes[i],
          packagePrice: config.packagePrice,
          isDeposit: config.isDeposit,
          isCollectPercentageTaxesOnTheDeposit: config.isCollectPercentageTaxesOnTheDeposit,
          personsCount: config.personsCount,
          duration: config.duration,
          userCountryId: config.userCountryId
        });
      }
    }

    return price;
  };
}

angular.module('heliApp').service('taxesService', downgradeInjectable(TaxesService))
