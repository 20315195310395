import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { WebService } from './webService';

/**
 * @ngdoc service
 * @name OperatorService
 * @description
 * # operatorService
 * Service in the heliApp.
 */

@Injectable()

export class OperatorService {

  constructor(
    @Inject(WebService) private webService: WebService
  ) { }

  sendReq(method) {
    return new Promise((resolve, reject) => {
      method.then((response) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })/* asynchronously complete request */
  }

  operatorRegistration(data) { // service to register a operator
    return this.sendReq(this.webService.operatorRegistration(data));
  }

  getCountry(data) { /* Get country list for Operator Signup in operatorSignupCtrl */
    return this.sendReq(this.webService.getCountry(data));
  }

  loginOperator(data) { /* Post method for Operator Signin in operatorSignInCtrl */
    return this.sendReq(this.webService.loginOperator(data));
  }

  checkValidToken(data) { /* Post method for Check vaild token  for Operator Signin in operatorSignInCtrl */
    return this.sendReq(this.webService.checkValidToken(data));
  }

  setOperatorPassword(data) { /* Post method for Set Password for Operator in operatorSignInCtrl */
    return this.sendReq(this.webService.setOperatorPassword(data));
  }

  changeOperatorPass(data) { // service to change pass
    return this.sendReq(this.webService.changeOperatorPass(data));
  }

}

angular.module('heliApp').service('operatorService', downgradeInjectable(OperatorService));
