'use strict';
/**
 * @ngdoc function
 * @name angular.module('heliApp').controller:userListingCtrl
 * @description
 * # userListingCtrl
 * Controller of the heliApp
 */

// eslint-disable-next-line no-undef
angular.module('heliApp').controller(
  'userListingCtrl',
  [
    '$scope',
    '$state',
    '$timeout',
    '$rootScope',
    'sharedService',
    'userListingService',
    '$stateParams',
    '$location',
    function ($scope, $state, $timeout, $rootScope, sharedService, userListingService, $stateParams, $location) {
      $scope.searchText = {};
      $scope.searchText.text = $state.params.search ? $state.params.search : '';
      $scope.userList = [];
      $scope.noRecordFound = false;
      $scope.isSearchTextFocused = false;
      // $scope.searchDefaultMsg = false;
      $scope.defaultErrMsg = true;
      var listLimit = 24;
      if ($rootScope.previousState && $rootScope.previousState.name === 'myprofile') {
        $state.params.back = $state.params.page > 1 ? 1 : 0;
        $location.search($state.params);
      }
      var back = $state.params.back !== 0 ? $state.params.back : 0 || $state.params.page > 1 ? 1 : 0;
      var getListCalled = false;

      $scope.getUserList = function (query) { // get operator list for displaing operator
        $scope.showLoader();
        getListCalled = true;
        // $scope.searchText = $state.params.search; //display text in search box
        if (query === undefined) {
        //   var search_param = ($state.params.search) ? $state.params.search : ''
          // var key_name = 'page-'+(Number($state.params.page) + 1)+'search-'+search_param;
          // if(!$rootScope.user_scrollbarId){
          //   $rootScope.user_scrollbarId = {};
          // }
          // $rootScope.user_scrollbarId[key_name] = '#operator'+$scope.userList[$scope.userList.length - 1].id;
          // to handle the state of each page in case of load more
          // $state.go("user-listing",{page : Number($state.params.page) + 1, search : $state.params.search})
          // return false;
        } else {
          if (query !== $state.params.search && query) { // to handle search text functionality
            $scope.userList = [];
            $state.params.search = query;
            $state.params.page = 1;
            $state.params.back = 0;
            $location.search($state.params);
            // $state.go("user-listing",{page : 1, search : query})
            // return false;
          } else {
            $state.params.page = 1;
            $location.search($state.params);
          }
        }

        var requestObject = {
          'page': $state.params.page,
          'keyword': ($state.params.search) ? $state.params.search : '',
          'back': back,
          'limit': listLimit
          // "search":  tempSearchValue ?  tempSearchValue : query || '',
        };
        userListingService.getUserList(requestObject, $scope.user.token).then(function (response) { // call opearot listing api
          if (response.data.code === '200') {
            $scope.defaultErrMsg = false;
            $scope.noRecordFound = false;

            $scope.userList = $scope.userList.concat(response.data.data);
            $scope.userListCount = response.data.count;
            // $scope.searchDefaultMsg = true;
            // more operator Shown on operator list Page
            $scope.isLoadMoreButton = ((response.data.count - $scope.userList.length) > 0);
            $scope.resCount = response.data.count;
            // $timeout(function(){
            // var search_param = ($state.params.search) ? $state.params.search : ''
            // var key_name = 'page-'+$state.params.page+'search-'+search_param;
            // if($rootScope.user_scrollbarId){
            //  if(angular.element($rootScope.user_scrollbarId[key_name]).length){
            //    angular.element("html, body").animate({ scrollTop: angular.element($rootScope.user_scrollbarId[key_name]).offset().top }, 1000);
            //  }
            // }
            // },100)
            $scope.hideLoader();
          } else if (response.data.code === '404') {
            $scope.isLoadMoreButton = false;
            $scope.userList = [];
            $scope.userListCount = 0;
            $scope.noRecordFound = true;
            $scope.hideLoader();
          }
        }, function () {
          $scope.hideLoader();
          $scope.showPopup('#serverErrModal');
        });
      };
      $timeout(function () {
        var query = $state.params.search;
        if (query && !getListCalled) {
          $scope.getUserList(query);
        }
      }, 500);
      // if($state.params.search) {
      //   $location.search($state.params);     // update the url is sorting is applied
      //   // $scope.getUserList($state.params.search);
      // }

      $scope.searchUser = function (query) {
        // if(query.length >= 2) {
        //   $scope.getUserList(query);     // update the url is sorting is applied
        // $scope.userList = [];
        // }
        if (query.length >= 2) {
          $scope.userList = [];
          $state.params.back = 0;
          $state.params.search = query; // display text in search box
          $location.search($state.params); // update the url is sorting is applied
        }
      };

      $scope.followUser = function (userId, index, followUser) { // to follow operator
        $scope.showLoader();
        var data = {
          'follower_id': userId,
          'token': $scope.user.token
        };
        if (followUser) { // user wants to follow influencer
          sharedService.followUser(data).then(function (res) {
            switch (res.data.code) {
              case '200' :
                $scope.userList[index].is_follow = followUser;
                break;
              default :
                $scope.hideLoader();
                $scope.showPopup('#serverErrModal');
                break;
            }
            $scope.hideLoader();
          }, function () {
            $scope.hideLoader(); $scope.showPopup('#serverErrModal');
          });
        } else { // user wants to unfollow influencer
          sharedService.unFollowUser(data).then(function (res) {
            switch (res.data.code) {
              case '200' :
                $scope.userList[index].is_follow = followUser;
                break;
              default :
                $scope.hideLoader();
                $scope.showPopup('#serverErrModal');
                break;
            }
            $scope.hideLoader();
          }, function () {
            $scope.hideLoader();
            $scope.showPopup('#serverErrModal');
          });
        }
      };

      $scope.loadMore = function () {
        back = 0;
        $state.params.page = Number($state.params.page) + 1;
        // eslint-disable-next-line no-self-assign
        $state.params.search = $state.params.search; // display text in search box
        $location.search($state.params); // update the url is sorting is applied
      };

      $rootScope.pageChanged = function (data) {
        if (data === 'pageIncreased') {
          back = 0;
          $scope.getUserList();
        } else if (data === 'pageDecreased') {
          $scope.userList = $scope.userList.slice(0, $rootScope.currentPage * listLimit);
          $scope.isLoadMoreButton = (($scope.resCount - $scope.userList.length) > 0);
          if ($scope.userList.length === 0) {
            $scope.getUserList();
          }
        } else {
          back = 0;
          var query = $state.params.search;
          if (query || getListCalled) {
            $scope.searchText.text = query;
            $scope.getUserList(query);
          }
        }
      };
    }
  ]
);
