import { Inject, Injectable } from "@angular/core";
import { downgradeInjectable } from "@angular/upgrade/static";
import * as angular from "angular";
import { CookieService } from "ngx-cookie-service";
import { environment } from "../environment";
import { WebService } from "./webService";

/**
 * @ngdoc service
 * @name UserAdventureDetailService
 * @description
 * # userAdventureDetailService
 * Service in the heliApp.
 */

@Injectable()
export class UserAdventureDetailService {
  private REQUEST_URL;

  constructor(
    @Inject(WebService) private webService: WebService,
    @Inject(CookieService) private cookies: CookieService
  ) {
    this.REQUEST_URL = environment.REQUEST_URL;
  }

  sendReq(method) {
    return new Promise((resolve, reject) => {
      method.then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    }); /* asynchronously complete request */
  }

  postReq(url, data, token?) {
    let req = {
      method: "POST",
      url: this.REQUEST_URL + url,
      data: data,
      headers: {
        token: token,
      },
    };
    return req;
  }

  getReq(url, token?) {
    let req = {
      method: "GET",
      url: this.REQUEST_URL + url,
      headers: {
        token: token,
      },
    };
    return req;
  }

  putReq(url, data, token) {
    let req = {
      method: "PUT",
      url: this.REQUEST_URL + url,
      data: data,
      headers: {
        token: token,
      },
    };
    return req;
  }

  getAdventureDetails(adventure) {
    return this.sendReq(this.webService.fetchAdventureDetails(adventure));
  }

  increaseHits(adventure) {
    return this.sendReq(this.webService.increaseAdventureHits(adventure));
  }

  addToUserRadar(packageData) {
    return this.sendReq(this.webService.addToUserRadarList(packageData));
  }

  removeFromUserRadar(packageData) {
    return this.sendReq(this.webService.removeFromUserRadarList(packageData));
  }

  userRadarList(user) {
    return this.sendReq(this.webService.getUserRadarList(user));
  }

  saveFBAccessToken(data) {
    // save user fb access token to DB
    return this.sendReq(this.webService.saveFBAccessToken(data));
  }

  getFBAccessToken(data) {
    // get the saved token from db
    return this.sendReq(this.webService.getFBAccessToken(data));
  }

  getPackageSlotAddons(slot_id) {
    if (!slot_id.currency_code) {
      slot_id.currency_code = "USD";
    }
    return this.sendReq(this.webService.getPackageSlotAddons(slot_id));
  }

  addToCart(data, token) {
    return this.sendReq(this.webService.addToCart(data, token));
  }

  getCartItemslist(cart_id, token, options) {
    return this.sendReq(this.webService.getCartList(cart_id, token, options));
  }

  getCreditsBalance(token) {
    return this.sendReq(this.webService.getCreditsBalance(token));
  }

  deleteCartItem(item, token) {
    return this.sendReq(this.webService.deleteCartItem(item, token));
  }

  sendForms(item) {
    return this.sendReq(this.webService.sendFormsGuest(item));
  }

  generateCartSessionId() {
    // generate unique cart session id...
    let unique_id =
      this.s4() +
      this.s4() +
      "-" +
      this.s4() +
      "-" +
      this.s4() +
      this.s4() +
      this.s4();

    let now = new Date();
    // this will set the expiration to 12 months
    let exp = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
    this.cookies.set("cart_session_id", unique_id, {
      expires: exp,
    }); //change $cookies on cookiesService

    return unique_id;
  }

  s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  getCartlistCount(cart_id, token) {
    let req = {
      method: "GET",
      url: this.REQUEST_URL + "Carts/cartCount?cart_id=" + cart_id,
      headers: {
        token: token,
      },
    };
    return this.webService.httpReq(req);
  }

  applyCoupon(couponData, token) {
    let req = this.postReq("Carts/applyPromoCode", couponData, token);
    return this.webService.httpReq(req);
  }

  validateCredits(creditsData, token) {
    return this.sendReq(this.webService.validateCredits(creditsData, token));
  }

  /**
   * @ngdoc method
   * @name applyCredits
   *
   * @description
   * Applies credits to the user's cart.
   *
   * @param {object} creditsData - The data containing the amount of credits to apply.
   * @param {string} token - The authentication token for the user.
   * @return {Promise<any>} A promise that resolves with the response from the server.
   */
  applyCredits(creditsData, token) {
    return this.sendReq(this.webService.applyCredits(creditsData, token));
  }

  /**
   * @ngdoc method
   * @name removeCredits
   *
   * @description
   * This method call API to remove credits from cart
   *
   * @param {object} creditsData - The data to be sent to the server
   * @param {string} token - The token for authorization
   * @return {Promise<any>} The response from the server
   */
  removeCredits(creditsData, token) {
    return this.sendReq(this.webService.removeCredits(creditsData, token));
  }

  proceedToCheckout(cartData, token) {
    let req = this.postReq("Carts/cartCheckout", cartData, token);
    return this.webService.httpReq(req);
  }

  removeCoupon(couponData, token) {
    let req = this.postReq("carts/removePromoCode", couponData, token);
    return this.webService.httpReq(req);
  }

  releaseSeats(cartData, token) {
    let req = this.postReq("Carts/releaseCart", cartData, token);
    return this.webService.httpReq(req);
  }

  myCurrentOrderList(order_id, token) {
    let url = "carts/myCurrentOrder?order_id=" + order_id;
    let req = this.getReq(url, token);
    return this.webService.httpReq(req);
  }

  updateTotalAmount(slots, token) {
    let req = this.postReq("carts/updateTotalAmount", slots, token);
    return this.webService.httpReq(req);
  }

  autoShareOnHeli(orderDetail, token) {
    let req = this.postReq("shares/my-order-auto-sharing", orderDetail, token);
    return this.webService.httpReq(req);
  }

  getTaxAmount(cartDetail, token) {
    let req = this.postReq("taxes/calculateTax", cartDetail, token);
    return this.webService.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name likePackage
   *
   * @description
   * To like package
   **/
  likePackage(data) {
    let req = this.postReq(
      "social-activities/package-like-feeds",
      data,
      data.token
    );
    return this.webService.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name getPackageSlotDetail
   *
   * @description
   * To get package detail for remaining payment
   **/
  getPackageSlotDetail(slot_id, token) {
    let url = "bookings/details?booking_slot_id=" + slot_id;
    let req = this.getReq(url, token);
    return this.webService.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name convertCurrency
   *
   * @description
   * To get package detail for remaining payment
   **/
  convertCurrency(amount, from, to) {
    let url =
      "Currencies/getUsdRate?amount=" + amount + "&from=" + from + "&to=" + to;
    let req = this.getReq(url);
    return this.webService.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name getIPAddress
   *
   * @description
   * To get IP Address of user.
   **/
  getIPAddress() {
    let url = "https://freegeoip.net/json/";
    let req = {
      method: "GET",
      url: url,
    };
    return this.webService.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name getCurrencyCode
   *
   * @description
   * To get currency code using ip address
   **/
  getCurrencyCode(ip, CC) {
    // let url="http://www.geoplugin.net/json.gp?ip="+ip;
    let url =
      "Currencies/getCurrencyCode?country_code=" + CC + "&is_multiple=" + 0;
    let req = this.getReq(url);
    return this.webService.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name getCurrency
   *
   * @description
   * To get supported currency
   **/
  getCurrency() {
    let url = "masters/currencies";
    let req = this.getReq(url);
    return this.webService.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name convertCartCurrency
   *
   * @description
   * To convert package total to selected currency.
   **/
  convertCartCurrency(data) {
    let req = this.postReq("Currencies/saveConvertCart", data);
    return this.webService.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name getSiteInfo
   *
   * @description
   * get operator websites info
   **/
  getSiteInfo(token) {
    let req = this.getReq("operator-sites/site-type", token);
    return this.webService.httpReq(req);
  }

  /**
   * @ngdoc method
   * @name getAllCountry
   *
   * @description
   * To get supported currency
   **/
  getAllCountry() {
    let url = "masters/all-country";
    let req = this.getReq(url);
    return this.webService.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name getFontsFromGoogle
   *
   * @description
   * To get supported currency
   **/
  // getAllGoogleFonts() {
  //     let req = this.getFontsData();
  //     return this.webService.httpReq(req);
  // }
  /**
   * @ngdoc method
   * @name updateGuestConsumerDetails
   *
   * @description
   * To update guest details by consumer
   **/
  updateGuestConsumerDetails(data, token) {
    let req = this.putReq("Guests/editGuestConsumer", data, token);
    return this.webService.httpReq(req);
  }
  /**
   * @ngdoc method
   * @name checkUserEmail
   *
   * @description
   * check user email exist or not...
   **/
  checkUserEmail(data) {
    let req = this.postReq("users/guest-sign-up", data);
    return this.webService.httpReq(req);
  }
}

angular
  .module("heliApp")
  .service(
    "userAdventureDetailService",
    downgradeInjectable(UserAdventureDetailService)
  );
